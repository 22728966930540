import React from "react";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../../styles/container";
import { H2, TextRegular } from "../../../styles/text";
import { MessageFull } from "../../../types";
import { GroupBulletinBoardMessage } from "../Components/GroupBulletinBoardMessage";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { COLORS } from "../../../styles/colors";
import { UseQueryResult } from "react-query";
import { getBorderRadius } from "../../../utils";
import { SecondaryLeftHeader } from "../../../components/text/SecondaryLeftHeader";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";

type GroupBulletinBoardProps = {
  pinnedMessagesQueryResult: UseQueryResult<MessageFull[] | undefined>;
  isOrganizer: boolean;
  showImage: (imageUri: string) => void;
};

export const GroupBulletinBoard = (props: GroupBulletinBoardProps) => {
  const { pinnedMessagesQueryResult, isOrganizer, showImage } = props;
  const colors = COLORS["light"];
  // const userInfo = useContext(userContext);
  // do query for pinned messages

  return (
    <div
      style={{
        marginLeft: ITEM_HORIZONTAL_SPACING,
        marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <HorizontalLeftAlignedContainer
        style={{
          borderTopStyle: "solid",
          borderTopColor: colors.LIGHT_GRAY,
          borderTopWidth: 1,
          paddingTop: ITEM_VERTICAL_SPACING,
        }}
      >
        <SecondaryLeftHeader>Bulletin Board</SecondaryLeftHeader>
      </HorizontalLeftAlignedContainer>
      <ItemVerticalSpace />
      {pinnedMessagesQueryResult.data &&
        pinnedMessagesQueryResult.data.length === 0 && (
          <HorizontalContainer>
            <HorizontalLeftAlignedContainer
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 16,
                paddingBottom: 16,
                backgroundColor: colors.PALE_GRAY,
                borderWidth: 1.75,
                borderRadius: getBorderRadius("small", "rounded rect"),
                flex: 1,
              }}
            >
              <TextRegular style={{ color: colors.SECONDARY_TEXT }}>
                Messages pinned by your organizers will appear here!
              </TextRegular>
            </HorizontalLeftAlignedContainer>
          </HorizontalContainer>
        )}
      {pinnedMessagesQueryResult.data &&
        pinnedMessagesQueryResult.data.length > 0 &&
        pinnedMessagesQueryResult.data.map(
          (message: MessageFull, index: number) => (
            <GroupBulletinBoardMessage
              key={index}
              message={message}
              isOrganizer={isOrganizer}
              refetch={pinnedMessagesQueryResult.refetch}
              showImage={showImage}
            />
          )
        )}
    </div>
  );
};
