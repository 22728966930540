import React from "react";
import { SizedContainer } from "../../styles/container";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export type ProfileImageProps = {
  imgSrc: string;
  width: number;
  height: number;
  borderRadius: number;
};
export const ProfileImage = (props: ProfileImageProps) => {
  const navigation = useNavigation();
  const { imgSrc, height, width, borderRadius } = props;
  return (
    <>
      {navigation.state === "loading" ? (
        <Skeleton
          height={height}
          width={width}
          circle={borderRadius > height / 3 ? true : false}
        />
      ) : (
        <SizedContainer
          width={width}
          height={height}
          style={{
            borderRadius: borderRadius,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={imgSrc}
            alt="profile pic"
            style={{
              // maxWidth: width,
              // maxHeight: height,
              width: width,
              height: height,
              objectFit: "cover",
            }}
          />
        </SizedContainer>
      )}
    </>
  );
};
