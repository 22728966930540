import React, { useContext, useEffect, useState } from "react";
import { EventFull } from "../../../types";
import { ListSpinner } from "../../../components/general/ListSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { UseInfiniteQueryResult } from "react-query";
import { EventCard } from "../../../features/EventCard/EventCard";
import { userContext } from "../../../UserContext";
import { ReactComponent as EmptyEvents } from "../../../assets/images/empty-state/empty-events.svg";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";

type DiscoverEventsListSectionProps = {
  discoverEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
};

export const DiscoverEventsListSection = (
  props: DiscoverEventsListSectionProps
) => {
  const userInfo = useContext(userContext);
  const currentUserId = userInfo.id;
  const { discoverEventsQueryResult } = props;
  const [items, setItems] = useState<EventFull[] | undefined>(undefined);

  const haveRSVPd = (eventDetails: EventFull) => {
    return (
      eventDetails.attendeeList.includes(currentUserId) ||
      eventDetails.waitList.includes(currentUserId)
    );
  };
  const hasMore = () => {
    const lastPage = discoverEventsQueryResult.data?.pages.slice(-1);
    return (
      !!discoverEventsQueryResult.hasNextPage &&
      !discoverEventsQueryResult.isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreEvents = () => {
    if (hasMore()) {
      discoverEventsQueryResult.fetchNextPage();
    }
  };

  useEffect(() => {
    if (discoverEventsQueryResult.isSuccess && discoverEventsQueryResult.data) {
      setItems(
        discoverEventsQueryResult.data.pages
          .map((page) => (page ? page : []))
          .flat()
      );
    } else {
      setItems([]);
    }
  }, [discoverEventsQueryResult]);

  return (
    <>
      {discoverEventsQueryResult.isSuccess && items && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreEvents}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
        >
          {items.length <= 0 && (
            <EmptyLayout
              title="No events found"
              subTitle="Check your filters to ensure they match your intended search!"
            >
              <EmptyEvents />
            </EmptyLayout>
          )}
          {items.map((item, index) => (
            <EventCard key={index} eventDetails={item} hasRSVP={false} />
          ))}
          {discoverEventsQueryResult.hasNextPage &&
            discoverEventsQueryResult.isFetchingNextPage && <ListSpinner />}
        </InfiniteScroll>
      )}
    </>
  );
};
