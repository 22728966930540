import styled from "styled-components";
import { DARK_PRIMARY_TEXT_COLOR } from "./colors";

const BERKSHIRE_SWASH_REGULAR = "BerkshireSwash_400Regular";

type TextProps = {
  color?: string;
};

export const USE_SMALL_TEXT = false;

export const LogoText = styled.h1<TextProps>`
  color: ${(p: TextProps) => (p.color ? p.color : DARK_PRIMARY_TEXT_COLOR)};
  font-size: ${USE_SMALL_TEXT ? 20 : 22}px;
  font-family: ${BERKSHIRE_SWASH_REGULAR};
`;

export const H1 = styled.h1<TextProps>`
  ${(p: TextProps) => `color: ${p.color ? p.color : DARK_PRIMARY_TEXT_COLOR};`}
  margin: 0px;
`;

export const H2 = styled.h2<TextProps>`
  ${(p: TextProps) => `color: ${p.color ? p.color : DARK_PRIMARY_TEXT_COLOR};`}
`;

export const TextSmall = styled.p<TextProps>`
  ${(p: TextProps) => `color: ${p.color ? p.color : DARK_PRIMARY_TEXT_COLOR};`}
  font-size: 12px;
`;

export const TextRegular = styled.p<TextProps>`
  ${(p: TextProps) => `color: ${p.color ? p.color : DARK_PRIMARY_TEXT_COLOR};`};
`;

export const TextRegularNW = styled.p<TextProps>`
  ${(p: TextProps) => `color: ${p.color ? p.color : DARK_PRIMARY_TEXT_COLOR};`}
  whiteSpace: "nowrap";
`;
type EmojiProps = {
  size: "small" | "regular" | "large";
};

export const Emoji = styled.span<EmojiProps>`
  font-size: ${(p: EmojiProps) =>
    p.size === "small" ? 15 : p.size === "regular" ? 36 : 48}px;
`;

export const ALL_EMOJIS = [
  "😁",
  "😆",
  "😅",
  "😂",
  "😇",
  "☺️",
  "😊",
  "🙂",
  "🙃",
  "😉",
  "😌",
  "😍",
  "😘",
  "😗",
  "😋",
  "😜",
  "😛",
  "🤑",
  "🤗",
  "🤓",
  "😎",
  "😏",
  "😒",
  "😞",
  "😕",
  "😖",
  "😫",
  "😤",
  "😡",
  "😶",
  "😐",
  "😑",
  "😲",
  "😵",
  "😳",
  "😱",
  "😨",
  "😢",
  "😓",
  "😪",
  "😴",
  "🙄",
  "🤔",
  "😬",
  "🤐",
  "😷",
  "🤒",
  "🤕",
  "😈",
  "👿",
  "👹",
  "💩",
  "👻",
  "💀",
  "👽",
  "👾",
  "🤖",
  "🎃",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "👐",
  "🙌",
  "👏",
  "🙏",
  "👊",
  "✊",
  "✌️",
  "🤘",
  "👌",
  "👈",
  "👉",
  "👆",
  "👇",
  "☝️",
  "✋",
  "🖐",
  "🖖",
  "👋",
  "💪",
  "✍️",
  "💅",
  "🖖",
  "💄",
  "👄",
  "👅",
  "👂",
  "👃",
  "👣",
  "👁",
  "👀",
  "👗",
  "👠",
  "👞",
  "👟",
  "👒",
  "🎩",
  "🎓",
  "👑",
  "⛑",
  "🎒",
  "👓",
  "🕶",
  "☂️",
  "🐶",
  "🐱",
  "🐭",
  "🐰",
  "🐻",
  "🐼",
  "🐨",
  "🐯",
  "🦁",
  "🐮",
  "🐷",
  "🐸",
  "🐵",
  "🙊",
  "🙉",
  "🙊",
  "🐔",
  "🐧",
  "🐤",
  "🐣",
  "🐝",
  "🐛",
  "🐌",
  "🐚",
  "🐞",
  "🕸",
  "🐢",
  "🐍",
  "🦂",
  "🦀",
  "🐙",
  "🐠",
  "🐡",
  "🐬",
  "🐋",
  "🐊",
  "🐆",
  "🐅",
  "🐃",
  "🐂",
  "🐫",
  "🐘",
  "🐎",
  "🐖",
  "🐕",
  "🐈",
  "🦃",
  "🕊",
  "🐾",
  "🐲",
  "🌵",
  "🎄",
  "🌲",
  "🌳",
  "🌴",
  "🌱",
  "🌿",
  "☘️",
  "🍀",
  "🎍",
  "🎋",
  "🍃",
  "🍂",
  "🍁",
  "🍄",
  "🌾",
  "💐",
  "🌹",
  "🥀",
  "🌻",
  "🌸",
  "🌺",
  "🌎",
  "🌍",
  "🌏",
  "🌚",
  "🌝",
  "🌞",
  "🌜",
  "🌙",
  "💫",
  "⭐️",
  "✨",
  "⚡️",
  "🔥",
  "💥",
  "☄️",
  "☀️",
  "🌤",
  "⛅️",
  "🌈",
  "☁️",
  "🌧",
  "⛄️",
  "❄️",
  "🌬",
  "💨",
  "🌪",
  "🌫",
  "🌊",
  "💧",
  "☔️",
  "🍏",
  "🍎",
  "🍐",
  "🍊",
  "🍋",
  "🍌",
  "🍉",
  "🍇",
  "🍓",
  "🍈",
  "🍒",
  "🍑",
  "🍍",
  "🍅",
  "🍆",
  "🌽",
  "🌶",
  "🍠",
  "🌰",
  "🍯",
  "🍞",
  "🧀",
  "🍳",
  "🍤",
  "🍗",
  "🍕",
  "🌭",
  "🍔",
  "🍟",
  "🌮",
  "🌯",
  "🍝",
  "🍜",
  "🍣",
  "🍱",
  "🍛",
  "🍚",
  "🍙",
  "🍢",
  "🍦",
  "🍰",
  "🍮",
  "🍭",
  "🍫",
  "🍿",
  "🍩",
  "🍪",
  "🍼",
  "🍵",
  "🍶",
  "🍺",
  "🍷",
  "🍸",
  "🍹",
  "🍾",
  "🍴",
  "🍽",
  "⚽️",
  "🏀",
  "🏈",
  "⚾️",
  "🎾",
  "🏐",
  "🏉",
  "🎱",
  "🏓",
  "🏸",
  "🏒",
  "🏑",
  "🏏",
  "⛳️",
  "🏹",
  "🎣",
  "⛸",
  "🎿",
  "⛷",
  "🏂",
  "🏋",
  "⛹️",
  "🏌",
  "🏄",
  "🏊",
  "🚣",
  "🏇",
  "🚴",
  "🎬",
  "🎼",
  "🎹",
  "🎷",
  "🎺",
  "🎸",
  "🎻",
  "🎲",
  "🎯",
  "🎳",
  "🎮",
  "🏁",
  "🏆",
  "🚕",
  "🚎",
  "🏎",
  "🚓",
  "🚑",
  "🚛",
  "🚲",
  "🏍",
  "🚀",
  "🛰",
  "💺",
  "⛵️",
  "⚓️",
  "🚧",
  "⛽️",
  "🚥",
  "🗺",
  "🗿",
  "🗽",
  "⛲️",
  "🗼",
  "🏰",
  "🏯",
  "🏟",
  "🎢",
  "🎠",
  "🏖",
  "🗻",
  "🌋",
  "🏜",
  "⛺️",
  "🛤",
  "🛣",
  "🏗",
  "🏭",
  "🏠",
  "🏢",
  "🏛",
  "⛪️",
  "🕌",
  "🕍",
  "🕋",
  "⛩",
  "🗾",
  "🎑",
  "🏞",
  "🌅",
  "🌄",
  "🌠",
  "🎇",
  "🎆",
  "🌇",
  "🌆",
  "🏙",
  "🌃",
  "🌌",
  "🌉",
  "🌁",
  "🎨",
  "📕",
  "⌚️",
  "📱",
  "💽",
  "📼",
  "📷",
  "📹",
  "🎥",
  "🎞",
  "📠",
  "📺",
  "📻",
  "🎙",
  "⏰",
  "🕰",
  "⌛️",
  "⏳",
  "🔋",
  "🔌",
  "💡",
  "🔦",
  "🕯",
  "🛢",
  "💸",
  "💰",
  "💎",
  "⚖️",
  "⚒",
  "💣",
  "🏺",
  "🔮",
  "💈",
  "⚗️",
  "🔭",
  "🔬",
  "🕳",
  "💊",
  "🌡",
  "🚽",
  "🚰",
  "🚿",
  "🔑",
  "🚪",
  "🛋",
  "🛏",
  "🖼",
  "🛍",
  "🎁",
  "🎈",
  "🎏",
  "🎀",
  "🎉",
  "🎎",
  "🏮",
  "🎐",
  "✉️",
  "📪",
  "📮",
  "📜",
  "📄",
  "📊",
  "📇",
  "🗳",
  "🗄",
  "📋",
  "📘",
  "📚",
  "🔗",
  "📌",
  "🖊",
  "🖌",
  "🖍",
  "✏️",
  "🔍",
  "🔓",
  "❤️",
  "💛",
  "💚",
  "💙",
  "💜",
  "💔",
  "💕",
  "✴️",
  "🆚",
  "❌",
  "⭕️",
  "⛔️",
  "📛",
  "💯",
  "💢",
  "♨️",
  "💬",
  "💭",
  "♠️",
  "♣️",
  "♥️",
  "♦️",
  "🃏",
];
