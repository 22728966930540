import React from "react";
import { QueryClient } from "react-query";
import { ComposeGroupsScreenLayout } from "./ComposeGroupsScreenLayout";
import { fdGetBoolean, fdGetImage, fdGetString } from "../../utils/formUtils";
import {
  GroupFull,
  GroupsMessageCreate,
  defaultGroupDetails,
} from "../../types";
import {
  sendGroupsMessage,
  uploadImageGroupsMessageFile,
} from "../../hooks/queryMessages";
import { redirect, useActionData } from "react-router-dom";
import { useUsersGroups, usersGroupsQuery } from "../../hooks/queryMemberships";
import { ScreenContainer } from "../../features/ScreenContainer";
import { useContext } from "react";
import { userContext } from "../../UserContext";
import { ComposeErrors } from "../ComposeScreen/ComposeScreen";
import { logSentryError } from "../../utils/sentryUtil";
import { useGroups } from "../../hooks/queryGroups";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";

export type ComposeGroupMessageParams = {};

export type ComposeGroupErrors = {
  recipients?: string;
  generic?: string;
  ok?: boolean;
};

const validateMessageData = (data: GroupsMessageCreate) => {
  const err: ComposeGroupErrors = {};

  if (data.groupIds.length <= 0) err.recipients = "No recipients selected";

  return err;
};

// Get the data necessary to create a DM from our formData
const extractComposeData = (data: FormData) => {
  const groupsJSON = fdGetString(data, "recipients");
  const pinned = fdGetBoolean(data, "pinned");
  const groups: GroupFull[] = groupsJSON
    ? JSON.parse(groupsJSON)
    : defaultGroupDetails;

  const groupIds: string[] = [];
  if (groups) {
    groups.forEach((group) => groupIds.push(group.id));
  }
  return {
    groupIds: groupIds,
    pinned: pinned,
    userId: fdGetString(data, "userid"),
    messageText: fdGetString(data, "messagetext").trim(),
  } as GroupsMessageCreate;
};

// our loader, for compose we don't do any queries
// so we don't need to do anything?
// maybe pre-load user's friends?
export const composeGroupsMessageLoader = async (
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = usersGroupsQuery(true, currentUserId, 100, false, "owner");
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

// our action, which takes care of actually
// posting the new message to the database
export const composeGroupsMessageAction = async (
  params: ComposeGroupMessageParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const defaultErrorMessage = "There was an error sending this message.";

  try {
    // for group and event messages, we just need to add a message,
    // so get the necessary data
    const composeMessageData = extractComposeData(data);
    const messageImage = await fdGetImage(data, "messageimage");

    const validateResult = validateMessageData(composeMessageData);
    if (Object.keys(validateResult).length) {
      return {
        ok: false,
        generic: defaultErrorMessage,
        ...validateResult,
      };
    }
    // send the message
    const result = await sendGroupsMessage(composeMessageData);
    if (messageImage)
      await uploadImageGroupsMessageFile(result.ids, messageImage);

    // invalidate the message list, the thread summary, and the destination's lastmessage item
    queryClient.invalidateQueries(["messages", "group"]);
    queryClient.invalidateQueries(["groupThreads", currentUserId]);

    // redirect to the correct destination's messages page
    return redirect("/inbox");
  } catch (e: any) {
    logSentryError("Error sending a multi-group message.", e, {
      tags: {
        userId: currentUserId,
        screen: "ComposeGroupsScreen",
        function: "composeMessageLoader",
      },
      level: "error",
    });
    return { generic: defaultErrorMessage, ok: false };
  }
};

export const ComposeGroupsScreen = () => {
  const userInfo = useContext(userContext);
  const errors = useActionData() as ComposeErrors;

  const currentUserId = userInfo.id;
  const groupsQueryResult = useUsersGroups(
    userInfo.adminLevel <= 90,
    currentUserId,
    100,
    false,
    "owner"
  );
  const adminGroupsQueryResult = useGroups(
    userInfo.adminLevel > 90,
    userInfo.latitude ?? defaultLatitude,
    userInfo.longitude ?? defaultLongitude,
    20000,
    undefined,
    undefined,
    undefined,
    undefined,
    userInfo.adminLevel > 90
  );

  return (
    <>
      <ScreenContainer horizontalPadding={0}>
        <ComposeGroupsScreenLayout
          groupsQueryResult={
            userInfo.adminLevel > 90
              ? adminGroupsQueryResult
              : groupsQueryResult
          }
          errors={errors}
        />
      </ScreenContainer>
    </>
  );
};
