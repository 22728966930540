import { useNavigation } from "react-router-dom";
import { DateFilterButton } from "../../../features/filters/DateFilterbutton";
import { LocationFilterButton } from "../../../features/filters/LocationFilterButton";
import { FilterContainer } from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { DiscoverEventsFilterInfo } from "../EventsScreen";
import Skeleton from "react-loading-skeleton";
import { getBorderRadius } from "../../../utils";
import { SkillFilterButton } from "../../../features/filters/SkillFilterButton";
import { GroupsFilterButton } from "../../../features/filters/GroupsFilterbutton";
import { CourtTypeFilterButton } from "../../../features/filters/CourtTypeFilterButton";
import { CourtType, EventTypeFilter } from "../../../types/enums";
import { EventTypeFilterButton } from "../../../features/filters/EventTypeFilterButton";

type DiscoverEventsFilterSectionProps = {
  filterInfo?: DiscoverEventsFilterInfo;
};

export const DiscoverEventsFilterSection = (
  props: DiscoverEventsFilterSectionProps
) => {
  const navigation = useNavigation();
  const { filterInfo } = props;
  return (
    <>
      <FilterContainer
        style={{
          paddingTop: ITEM_VERTICAL_SPACING,
          paddingBottom: LINE_VERTICAL_SPACING,
        }}
      >
        {filterInfo ? (
          <DateFilterButton
            date={filterInfo.dateFilter}
            setDate={filterInfo.setDateFilter}
            defaultDate={"Upcoming"}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        {filterInfo ? (
          <LocationFilterButton
            latitudeFilter={filterInfo.latitudeFilter}
            longitudeFilter={filterInfo.longitudeFilter}
            mileageFilter={filterInfo.mileageFilter}
            address={filterInfo.address}
            displayAddress={filterInfo.displayAddress}
            setLatitudeFilter={filterInfo.setLatitudeFilter}
            setLongitudeFilter={filterInfo.setLongitudeFilter}
            setMileageFilter={filterInfo.setMileageFilter}
            setAddress={filterInfo.setAddress}
            setDisplayAddress={filterInfo.setDisplayAddress}
            defaultLocation={filterInfo.userLocation}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        {filterInfo ? (
          <SkillFilterButton
            value={filterInfo.skillFilter}
            setValue={filterInfo.setSkillFilter}
            defaultValue={2.0}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />

        {filterInfo ? (
          <GroupsFilterButton
            group={filterInfo.groupFilter}
            setGroup={filterInfo.setGroupFilter}
            defaultGroup={undefined}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />

        {filterInfo ? (
          <CourtTypeFilterButton
            value={filterInfo.courtTypeFilter}
            setValue={filterInfo.setCourtTypeFilter}
            defaultValue={CourtType.Any}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />

        {filterInfo ? (
          <EventTypeFilterButton
            value={filterInfo.eventTypeFilter}
            setValue={filterInfo.setEventTypeFilter}
            defaultValue={EventTypeFilter.Any}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
      </FilterContainer>
    </>
  );
};
