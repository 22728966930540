import React from "react";
import { redirect, useActionData, useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { ForgotPasswordScreenLayout } from "./ForgotPasswordScreenLayout";
import { useEffect } from "react";
import { logSentryError } from "../../utils/sentryUtil";

export type ForgotPasswordErrors = {
  ok?: boolean;
  newPasswordRequired?: boolean;
  email?: string;
  other?: string;
  sentToEmail?: string;
};

const validateForgotPasswordData = (email: string) => {
  const err: ForgotPasswordErrors = {};

  if (!email) err.email = "Please enter your email address";

  return err;
};

export const forgotPasswordAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  const data = await request.formData();
  const email = fdGetString(data, "email");

  const validateResult = validateForgotPasswordData(email);
  if (Object.keys(validateResult).length) {
    return { ok: false, ...validateResult } as ForgotPasswordErrors;
  }

  try {
    await Auth.forgotPassword(email);
    return { ok: true, newPasswordRequired: true, sentToEmail: email };
  } catch (e: any) {
    if (e.code === "UserNotFoundException") {
      return {
        ok: false,
        email:
          "Error: Email not found.  Please check your spelling or create a new account.",
      };
    } else {
      logSentryError("Error resetting password", e, {
        tags: {
          userId: "none",
          screen: "ForgotPasswordScreen",
          function: "forgotPasswordAction",
        },
        level: "error",
      });
      return { ok: false, other: "Error: " + e.code };
    }
  }
};

export const forgotPasswordLoader = async (currentUserId: string) => {
  if (!!currentUserId) return redirect("/events");
  return { ok: true };
};

export const ForgotPasswordScreen = () => {
  const forgotPasswordErrors = useActionData() as ForgotPasswordErrors;
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !!forgotPasswordErrors?.newPasswordRequired &&
      !!forgotPasswordErrors?.sentToEmail
    ) {
      navigate("/newPassword", {
        state: { email: forgotPasswordErrors.sentToEmail },
      });
    }

    document.title = "Forgotten Password -- Picklers";
  }, [navigate, forgotPasswordErrors]);

  return <ForgotPasswordScreenLayout errors={forgotPasswordErrors} />;
};
