import React, { useRef, useState } from "react";
import { UseQueryResult } from "react-query";
import { Form, useNavigate } from "react-router-dom";
import { ImagePicker } from "../../components/image/ImagePicker";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../components/inputs/TitledDropdown";
import { TitledInput } from "../../components/inputs/TitledInput";
import { blankProfileImageURL } from "../../constants/defaultImageURLs";
import { TitledLocationPicker } from "../../features/TitledLocationPicker/TitledLocationPicker";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { ProfileFull } from "../../types";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";
import { Location } from "../../features/TitledLocationPicker/LocationPicker";
import { EditActionBar } from "../../features/actionBars/EditActionBar";
import { TextRegular } from "../../styles/text";
import { StickyActionBar } from "../../components/StickyActionBar";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { ProfileErrors } from "./EditProfileScreen";

type EditProfileScreenLayoutProps = {
  profileQueryResult: UseQueryResult<ProfileFull>;
  errors?: ProfileErrors;
};

export const EditProfileScreenLayout = (
  props: EditProfileScreenLayoutProps
) => {
  const { profileQueryResult, errors } = props;
  const { data, isSuccess } = profileQueryResult;
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const skillItems = [
    { label: "Don't know, or prefer to keep it private", value: "N/A" },
    { label: "2.5 -- Beginner or Learning", value: "2.5" },
    { label: "3.0 -- Lower Intermediate", value: "3.0" },
    { label: "3.5 -- Higher Intermediate", value: "3.5" },
    { label: "4.0 -- Advanced", value: "4.0" },
    { label: "4.5 -- Higher Advanced", value: "4.5" },
    { label: "5.0 -- Expert/Professional", value: "5.0" },
  ];
  const [firstName, setFirstName] = useState(data ? data.firstName : "");
  const [lastName, setLastName] = useState(data ? data.lastName : "");
  const defaultLocation: Location = {
    address: data ? data.address : "",
    displayAddress: data ? data.displayAddress : "",
    lat: data ? data.latitude : defaultLatitude,
    lng: data ? data.longitude : defaultLongitude,
  };
  const [selectedImgSrc, setSelectedImageSrc] = useState(
    data ? data.imageUri : ""
  );
  const [selectedSkillLevel, setSelectedSkillLevel] =
    useState<DropdownValueType>(data ? data.skillLevel : "N/A");
  const [selectedLocation, setSelectedLocation] = useState(defaultLocation);

  return (
    <>
      {isSuccess && (
        <>
          {errors?.generic && (
            <HorizontalContainer>
              <TextRegular color="red">{errors?.generic}</TextRegular>
            </HorizontalContainer>
          )}
          <Form
            action={"/profile/edit/" + data.id}
            method="POST"
            encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
          >
            <StickyActionBar>
              <EditActionBar
                onCancel={() => {
                  navigate(-1);
                }}
                onSave={() => {
                  if (buttonRef.current) buttonRef.current.click();
                }}
              />
            </StickyActionBar>
            {/* <SectionVerticalSpace /> */}
            <div
              style={{
                paddingLeft: ITEM_HORIZONTAL_SPACING,
                paddingRight: ITEM_HORIZONTAL_SPACING,
              }}
            >
              <PrimaryLeftHeader>Edit your profile</PrimaryLeftHeader>

              <SectionVerticalSpace />

              <HorizontalLeftAlignedContainer
                style={{
                  marginBottom: SECTION_VERTICAL_SPACING,
                }}
              >
                <ImagePicker
                  imgSrc={
                    selectedImgSrc ? selectedImgSrc : blankProfileImageURL
                  }
                  formDataName="profileimage"
                  setImgSrc={setSelectedImageSrc}
                  width={200}
                  height={200}
                  borderRadius={100}
                />
              </HorizontalLeftAlignedContainer>
              <HorizontalContainer
                style={{
                  marginBottom: ITEM_VERTICAL_SPACING,
                }}
              >
                <TitledInput
                  title="FIRST NAME"
                  formDataName="firstname"
                  value={firstName}
                  setText={setFirstName}
                  maxLength={64}
                  required={true}
                  editable={true}
                  placeholderText={"Your first name"}
                />
              </HorizontalContainer>
              {errors?.firstName && (
                <HorizontalLeftAlignedContainer>
                  <TextRegular color="red">{errors?.firstName}</TextRegular>
                </HorizontalLeftAlignedContainer>
              )}

              <HorizontalContainer
                style={{
                  marginBottom: ITEM_VERTICAL_SPACING,
                }}
              >
                <TitledInput
                  title="LAST NAME"
                  formDataName="lastname"
                  value={lastName}
                  setText={setLastName}
                  maxLength={64}
                  required={true}
                  editable={true}
                  placeholderText={"Your last name"}
                />
              </HorizontalContainer>
              {errors?.lastName && (
                <HorizontalLeftAlignedContainer>
                  <TextRegular color="red">{errors?.lastName}</TextRegular>
                </HorizontalLeftAlignedContainer>
              )}
              <HorizontalContainer
                style={{
                  marginBottom: ITEM_VERTICAL_SPACING,
                }}
              >
                <TitledLocationPicker
                  title="LOCATION"
                  location={selectedLocation}
                  setLocation={setSelectedLocation}
                  showDisplayAddress={true}
                  citiesOnly={true}
                  prefix="profile"
                  required={false}
                />
              </HorizontalContainer>
              <HorizontalContainer>
                <TitledDropdown
                  title="SKILL LEVEL"
                  formDataName="skilllevel"
                  value={selectedSkillLevel}
                  setValue={setSelectedSkillLevel}
                  items={skillItems}
                  placeholder="Estimate your skill level"
                />
              </HorizontalContainer>
              <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
                <button
                  ref={buttonRef}
                  type="submit"
                  name="Save"
                  style={{ display: "none" }}
                >
                  Save
                </button>
              </HorizontalContainer>
            </div>
          </Form>
        </>
      )}
    </>
  );
};
