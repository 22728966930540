import React, { Dispatch, SetStateAction } from "react";
import { COLORS } from "../../styles/colors";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { Emoji, TextRegular, TextSmall } from "../../styles/text";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Icon } from "../../styles/icons";
import IonIcon from "@reacticons/ionicons";

type TitledCheckboxProps = {
  formDataName: string;
  isChecked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  text: string;
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  emoji?: string;
};

export const TitledCheckbox = (props: TitledCheckboxProps) => {
  const { isChecked, formDataName, onChange, text, iconName, emoji } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const handleChange = () => {
    onChange(!isChecked);
  };

  return (
    <HorizontalSpaceBetweenContainer
      style={{
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      {navigation.state === "loading" ? (
        <Skeleton height={24} count={0.1} />
      ) : (
        <HorizontalSpaceBetweenContainer style={{ flex: 1 }}>
          <HorizontalLeftAlignedContainer>
            {iconName && (
              <HorizontalContainer style={{ width: 25 }}>
                <Icon
                  name={iconName}
                  size={16}
                  color={colors.PRIMARY_TEXT_COLOR}
                />
              </HorizontalContainer>
            )}
            {emoji && (
              <HorizontalContainer style={{ width: 25 }}>
                <Emoji size="small" color="black">
                  {emoji}
                </Emoji>
              </HorizontalContainer>
            )}
            <TextRegular
              style={{ paddingLeft: LINE_HORIZONTAL_SPACING }}
              color={colors.PRIMARY_TEXT_COLOR}
            >
              {text}
            </TextRegular>
          </HorizontalLeftAlignedContainer>
          <HorizontalLeftAlignedContainer>
            <input
              type="checkbox"
              name={formDataName}
              checked={isChecked}
              onChange={handleChange}
              style={{ marginLeft: 5, width: 12 }}
            />
          </HorizontalLeftAlignedContainer>
        </HorizontalSpaceBetweenContainer>
      )}
    </HorizontalSpaceBetweenContainer>
  );
};
