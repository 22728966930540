import React, { useEffect, useState } from "react";
import { UseInfiniteQueryResult } from "react-query";

import { GroupFull } from "../../../types";
import { GroupCard } from "../Components/GroupCard";
import { ListSpinner } from "../../../components/general/ListSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { goToGroupDetails } from "../../../utils/navUtil";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyGroups } from "../../../assets/images/empty-state/empty-groups.svg";
import { SIDE_PADDING } from "../../../styles/spacing";

type GroupListSectionProps = {
  groupsQueryResult: UseInfiniteQueryResult<GroupFull[]>;
};

export const GroupsListSection = (props: GroupListSectionProps) => {
  const { groupsQueryResult } = props;
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    groupsQueryResult;
  const navigate = useNavigate();

  const [items, setItems] = useState<GroupFull[] | undefined>(undefined);

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreGroups = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (data) {
      setItems(data.pages.map((page) => (page ? page : [])).flat());
    } else {
      setItems([]);
    }
  }, [data]);

  return (
    <>
      {items && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreGroups}
          hasMore={!!hasMore()}
          loader={<h4>Loading...</h4>}
          style={{ marginLeft: SIDE_PADDING, marginRight: SIDE_PADDING }}
        >
          {items.length <= 0 && (
            <EmptyLayout
              title="No Groups found"
              subTitle="Check your filters to ensure they match your intended search!"
            >
              <EmptyGroups />
            </EmptyLayout>
          )}
          {items.map((item, index) => (
            <GroupCard
              key={index}
              showLabels={false}
              groupInfo={item}
              onPress={() => goToGroupDetails(navigate, item.id)}
            />
          ))}
          {hasNextPage && isFetchingNextPage && <ListSpinner />}
        </InfiniteScroll>
      )}
    </>
  );
};
