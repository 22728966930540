import React, { useContext, useEffect, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { redirect, useActionData, useParams } from "react-router-dom";
import { userContext } from "../../UserContext";
import {
  groupDetailsQuery,
  patchGroup,
  saveNewGroup,
  setOrganizers,
  uploadGroupImageFile,
} from "../../hooks/queryGroups";
import {
  GroupAccessValue,
  GroupCreate,
  GroupFull,
  GroupUpdate,
  GroupUpdateAble,
  PeopleData,
  defaultGroupDetails,
} from "../../types";
import {
  fdGetByEnum,
  fdGetImage,
  fdGetNumber,
  fdGetString,
  ifDifferentEnum,
  ifDifferentNumber,
  ifDifferentString,
} from "../../utils/formUtils";
import { EditGroupFormLayout } from "./EditGroupFormLayout";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  groupMembersAllQuery,
  useGroupMembersAll,
} from "../../hooks/queryMemberships";
import { logSentryError } from "../../utils/sentryUtil";

export type EditGroupParams = {
  editTask?: string;
  groupId?: string;
};

export type GroupErrors = {
  generic?: string;
  name?: string;
  code?: string;
  address?: string;
  latlng?: string;
  ok?: boolean;
};

export const peopleDataArraysAreEqual = (a: PeopleData[], b: PeopleData[]) => {
  return (
    a.length === b.length &&
    a.every((pd, index) => pd.userId === b[index].userId)
  );
};

const generateUpdateData = (gd: GroupUpdateAble, od: GroupFull) => {
  return {
    name: ifDifferentString(gd.name, od.name),
    code: ifDifferentString(gd.code, od.code),
    description: ifDifferentString(gd.description, od.description),
    address: ifDifferentString(gd.address, od.address),
    displayAddress: ifDifferentString(gd.displayAddress, od.displayAddress),
    latitude: ifDifferentNumber(gd.latitude, od.latitude),
    longitude: ifDifferentNumber(gd.longitude, od.longitude),
    access: ifDifferentEnum<GroupAccessValue>(gd.access, od.access),
    minSkill: ifDifferentNumber(gd.minSkill, od.minSkill),
    maxSkill: ifDifferentNumber(gd.maxSkill, od.maxSkill),
  } as GroupUpdate;
};
const extractNewGroupData = (data: FormData) => {
  return {
    name: fdGetString(data, "groupname"),
    code: fdGetString(data, "groupcode"),
    description: fdGetString(data, "groupdescription"),
    address: fdGetString(data, "groupaddress"),
    displayAddress: fdGetString(data, "groupdisplayaddress"),
    latitude: fdGetNumber(data, "grouplatitude"),
    longitude: fdGetNumber(data, "grouplongitude"),
    access: fdGetByEnum<GroupAccessValue>(data, "groupaccess"),
    minSkill: fdGetNumber(data, "skillmin"),
    maxSkill: fdGetNumber(data, "skillmax"),
  } as GroupUpdateAble;
};

const validateGroupInfo = (groupInfo: GroupUpdateAble) => {
  const err: GroupErrors = {};

  if (groupInfo?.name && groupInfo.name.length < 3) {
    err.name = "Group name must be at least 3 characters.";
  }
  if (groupInfo?.code && groupInfo.code.length < 3) {
    err.code = "Group code must be at least 3 characters long";
  }
  if (groupInfo?.address && groupInfo.address.length <= 0) {
    err.address = "You must select a valid location for this group";
  }
  if (
    Math.abs(groupInfo.latitude) > 90 ||
    Math.abs(groupInfo.longitude) > 180
  ) {
    err.latlng = "Bad address data";
  }
  return err;
};

export const editGroupLoader = async (
  params: EditGroupParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    if (params.editTask === "edit" && !!params?.groupId) {
      const query = groupDetailsQuery(currentUserId, params?.groupId);

      queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query));

      const query2 = groupMembersAllQuery(true, params?.groupId);

      queryClient.getQueryData(query2.queryKey) ??
        (await queryClient.fetchQuery(query2));
    }
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const editGroupAction = async (
  params: EditGroupParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const ovresult = fdGetString(data, "originalvalues");
  const defaultErrorMessage = "There was an error updating your group";

  const originalGroupData: GroupFull = ovresult
    ? JSON.parse(ovresult)
    : undefined;
  let groupId = originalGroupData ? originalGroupData.id : "";

  const groupData = extractNewGroupData(data);
  // const ownerId = fdGetString(data, "ownerid");

  const assistantsJSON = fdGetString(data, "assistants");
  const assistants = assistantsJSON ? JSON.parse(assistantsJSON) : undefined;

  // process image
  const newGroupImage = await fdGetImage(data, "groupimage");

  const validateResult = validateGroupInfo(groupData);
  if (Object.keys(validateResult).length) {
    return { ok: false, generic: defaultErrorMessage, ...validateResult };
  }

  try {
    if (!groupId) {
      const createGroupData: GroupCreate = {
        ownerId: currentUserId,
        ...groupData,
      };
      const result = await saveNewGroup(createGroupData);
      groupId = result.id;
    } else {
      // collect only the changed group data
      const updateGroupData = generateUpdateData(groupData, originalGroupData);
      const hasUpdates = !Object.values(updateGroupData).every(
        (el) => el === undefined
      );
      // only patch the group if there are updates to it
      if (hasUpdates) {
        await patchGroup(groupId, updateGroupData);
      }
    }

    // update the group image, if one was selected
    if (newGroupImage && groupId) {
      await uploadGroupImageFile(groupId, newGroupImage);
    }

    // update the organizers list if anything has changed
    if (groupId && assistants) {
      if (!peopleDataArraysAreEqual(assistants, originalGroupData.assistants)) {
        await setOrganizers(groupId, assistants);
        queryClient.invalidateQueries(["memberships-group-all", groupId]);
        queryClient.invalidateQueries(["memberships-group", groupId]);
      }
    }

    // invalidate overall groups query, so our changes show up on groups tab
    queryClient.invalidateQueries(["groups"]);

    // invalidate this group's query so our group details screen updates
    queryClient.invalidateQueries(["group", groupId]);

    // invalidate current user's memberships, so our changes show up in my groups
    queryClient.invalidateQueries(["memberships-user"]);

    return redirect("/group/view/" + groupId);
  } catch (e: any) {
    if (e.code === "NotSupportedError") {
      return {
        ok: false,
        generic:
          "Your browser does not support this type of file upload.  Check your settings, upgrade your browser, or use the mobile app to upload this image.",
      };
    } else {
      logSentryError("Error Saving Group Details.", e, {
        tags: {
          userId: currentUserId,
          groupId: params?.groupId,
          screen: "EditGroupScreen",
          function: "editGroupAction",
        },
        level: "error",
      });
    }
    return { generic: defaultErrorMessage, ok: false };
  }
};

export const EditGroupScreen = () => {
  const currentUserInfo = useContext(userContext);
  const params = useParams();
  const errors = useActionData() as GroupErrors;

  const initialGroupDetails: GroupFull = {
    ...defaultGroupDetails,
    ownerId: currentUserInfo.id,
    latitude: currentUserInfo.latitude,
    longitude: currentUserInfo.longitude,
    address: currentUserInfo.address,
    displayAddress: currentUserInfo.displayAddress,
  };

  const [groupDetails, setGroupDetails] = useState(initialGroupDetails);
  const [title, setTitle] = useState("Create Group");
  const { data, isSuccess } = useQuery(
    groupDetailsQuery(currentUserInfo.id, params.groupId)
  );

  const membersQueryResult = useGroupMembersAll(true, params.groupId ?? "");

  useEffect(() => {
    if (isSuccess && data) {
      setGroupDetails(data);
      setTitle("Edit Group");
      const title = data.name ?? "New";
      document.title = "Edit Group: " + title;
    }
  }, [isSuccess, data]);

  return (
    <>
      {groupDetails && (
        <ScreenContainer horizontalPadding={0}>
          <EditGroupFormLayout
            groupDetails={groupDetails}
            membersQueryResult={membersQueryResult}
            title={title}
            errors={errors}
          />
        </ScreenContainer>
      )}
    </>
  );
};
