import React, { useState } from "react";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { HorizontalRightAlignedContainer } from "../../../styles/container";
import { LINE_HORIZONTAL_SPACING } from "../../../styles/spacing";
import { Icon } from "../../../styles/icons";
import { useNavigation } from "react-router-dom";
import { AttendeeFull, EventFull, GroupFull, PeopleData } from "../../../types";
import Drawer from "react-modern-drawer";
import { MemberPicker } from "../../../features/TitledMemberPicker/MemberPicker";
import { UseInfiniteQueryResult } from "react-query";
import { NavButton } from "../../../components/buttons/NavButton";
import { AttendeePickerList } from "./AttendeePickerList";

type AttendeePickerProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
};

export const AttendeePicker = (props: AttendeePickerProps) => {
  const { eventDetails, groupDetails } = props;

  const [showMemberPicker, setShowMemberPicker] = useState(false);
  const navigation = useNavigation();

  const toggleMemberPickerDrawer = () => {
    if (navigation.state !== "loading")
      if (showMemberPicker) {
        setShowMemberPicker(false);
      } else {
        setShowMemberPicker(true);
      }
  };

  return (
    <>
      <Drawer
        open={showMemberPicker}
        onClose={() => toggleMemberPickerDrawer()}
        direction="right"
        lockBackgroundScroll={true}
        size={500}
        zIndex={2000}
      >
        <AttendeePickerList
          groupId={groupDetails.id}
          eventId={eventDetails.id}
          goingList={eventDetails.attendeeList}
          notgoingList={eventDetails.notgoingList}
          waitList={eventDetails.waitList}
          closePicker={() => toggleMemberPickerDrawer()}
        />
      </Drawer>
      <HorizontalRightAlignedContainer
        style={{ marginRight: LINE_HORIZONTAL_SPACING }}
      >
        <NavButton
          iconName="add"
          size={24}
          onClick={() => toggleMemberPickerDrawer()}
          loading={navigation.state === "loading"}
        />
      </HorizontalRightAlignedContainer>
    </>
  );
};
