import { useRef } from "react";
import {
  DARK_PRIMARY_TEXT_COLOR,
  LIGHT_PRIMARY_TEXT,
} from "../../styles/colors";
import { getBorderRadius } from "../../utils";
import { TextRegular } from "../../styles/text";

type SubmitButtonProps = {
  loading: boolean;
  name?: string;
  title: string;
};

export const SubmitButton = (props: SubmitButtonProps) => {
  const { loading, name, title } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      {/* Visible button that can only click once */}
      <button
        type="button"
        name={name ? name : "submitbutton"}
        value={name ? name : "submitbutton"}
        disabled={loading}
        onClick={() => {
          if (buttonRef.current) buttonRef.current.click();
        }}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 8,
          paddingBottom: 8,
          width: "100%",
          color: LIGHT_PRIMARY_TEXT,
          backgroundColor: DARK_PRIMARY_TEXT_COLOR,
          borderStyle: "none",
          borderRadius: getBorderRadius("small", "rounded rect"),
        }}
      >
        {/* {loading ? ( */}
        {/* <BeatLoader color={LIGHT_PRIMARY_TEXT} loading={loading} size={14} /> */}
        {/* ) : ( */}
        <TextRegular color={LIGHT_PRIMARY_TEXT}>{title}</TextRegular>
        {/* )} */}
      </button>
      {/* Hidden button that submits the actual form, clicked programatically */}
      <button
        ref={buttonRef}
        name="hiddenbutton"
        type="submit"
        style={{ display: "none" }}
      >
        {title}
      </button>
    </>
  );
};
