import React from "react";
import IonIcon from "@reacticons/ionicons";
import { COLORS } from "./colors";

// export const HomeIcon = () => <FontAwesome name="home" size={24} color="black" />

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */

type IconProps = {
  name: React.ComponentProps<typeof IonIcon>["name"];
  color?: string;
  size?: number;
};

export const Icon = (props: IconProps) => {
  const colors = COLORS["light"];
  return (
    <IonIcon
      style={{
        fontSize: props.size ? props.size : 14,
        color: props.color ? props.color : colors.PRIMARY_TEXT_COLOR,
      }}
      name={props.name}
    />
  );
};
