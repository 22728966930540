import React, { useContext } from "react";
import {
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ScreenContainer } from "../../features/ScreenContainer";
import { Auth } from "aws-amplify";
import { HorizontalContainer } from "../../styles/container";
import { H2, TextRegular } from "../../styles/text";
import { SecondaryButton } from "../../components/buttons/SecondaryButton";
import { SECTION_VERTICAL_SPACING } from "../../styles/spacing";
import { useEffect } from "react";
import { goToLogin } from "../../utils/navUtil";
import { COLORS } from "../../styles/colors";
import { logSentryError } from "../../utils/sentryUtil";
import { userContext } from "../../UserContext";

export type LoggingInParams = {
  thirdParty: string;
};

export type LoggingInErrors = {
  ok: boolean;
  error?: string;
};

export const loggingInLoader = async (currentUserId: string, error: string) => {
  if (!!currentUserId) return redirect("/events");
  if (!!error)
    return {
      ok: false,
      error: error,
    } as LoggingInErrors;
  return { ok: true } as LoggingInErrors;
};

export const LoggingInScreen = () => {
  const params = useParams();
  const errors = useLoaderData() as LoggingInErrors;
  const colors = COLORS["light"];
  const thirdParty = params?.thirdParty;
  const title = "Logging You In";
  const errorTitle = "There was a problem.";
  let message = "Please wait";
  if (errors && !!errors.error) {
    message =
      "Unable to load your account data.  Reload the page to try again.";
  } else if (thirdParty === "google")
    message = message + " while Google logs you in.";
  else if (thirdParty === "apple")
    message = message + " while Apple logs you in.";
  else message = message + " while we load your account data";
  const cancel = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      logSentryError("Error canceling login with Amplify!", e, {
        tags: {
          userId: "none",
          screen: "LoggingInScreen",
          function: "cancel",
        },
        level: "error",
      });
    }
    // goToLogin(navigate);
  };
  useEffect(() => {
    document.title = "Logging You In";
  }, []);

  return (
    <ScreenContainer>
      <div>
        <HorizontalContainer style={{ marginTop: 200 }}>
          <H2>{errors.error ? errorTitle : title}</H2>
        </HorizontalContainer>
        <HorizontalContainer style={{ marginTop: SECTION_VERTICAL_SPACING }}>
          <TextRegular>{message}</TextRegular>
        </HorizontalContainer>
        {errors?.error && (
          <HorizontalContainer style={{ marginTop: SECTION_VERTICAL_SPACING }}>
            <TextRegular color={colors.PRIMARY_RED}>{errors.error}</TextRegular>
          </HorizontalContainer>
        )}
        <HorizontalContainer style={{ marginTop: SECTION_VERTICAL_SPACING }}>
          <SecondaryButton onClick={() => cancel()} title="Never Mind" />
        </HorizontalContainer>
      </div>
    </ScreenContainer>
  );
};
