import React from "react";
import { COLORS } from "../../styles/colors";
import { TextRegular } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import "./SocialLoginButton.css";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";

type SocialLoginButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  logoUri: string;
};

export const SocialLoginButton = (props: SocialLoginButtonProps) => {
  const { title, onClick, disabled, logoUri } = props;
  const colors = COLORS["light"];
  return (
    <button
      className="social-login-button"
      onClick={onClick}
      disabled={disabled}
    >
      <HorizontalLeftAlignedContainer>
        <img src={logoUri} height={20} width={20} />
        <TextRegular style={{ marginLeft: ITEM_HORIZONTAL_SPACING }}>
          {title}
        </TextRegular>
      </HorizontalLeftAlignedContainer>
    </button>
  );
};
