import React from "react";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { ScreenContainer } from "../../features/ScreenContainer";
import { H1, TextRegular } from "../../styles/text";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { Link } from "react-router-dom";
import { Divider } from "../../styles/divider";

export const AboutScreen = () => {
  return (
    <ScreenContainer>
      <HorizontalLeftAlignedContainer
        style={{
          marginTop: SECTION_VERTICAL_SPACING,
          marginBottom: SECTION_VERTICAL_SPACING,
        }}
      >
        <H1>About Picklers</H1>
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer
        style={{ marginBottom: ITEM_VERTICAL_SPACING }}
      >
        <TextRegular>
          Picklers is a platform that enables pickleball players to create and
          find events within their community. Whether it is rec play, drill
          sessions, private lessons, holiday parties, or community efforts to
          encourage towns and cities to build more pickleball facilities,
          Picklers can help you make things happen! Our mission is to help the
          people who love pickleball to self-organize and build vibrant
          communities. Together we can enable more people to discover and enjoy
          this wonderful game.
        </TextRegular>
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer>
        <TextRegular>
          If you have any questions, see our&nbsp;
          <Link to="/profile/faq">FAQ</Link> or contact us at&nbsp;
          <a href="mailto:support@picklers.net">support@picklers.net</a>
        </TextRegular>
      </HorizontalLeftAlignedContainer>
      <Divider style={{ marginTop: SECTION_VERTICAL_SPACING }} />
      <HorizontalLeftAlignedContainer
        style={{ marginTop: ITEM_VERTICAL_SPACING }}
      >
        <TextRegular>
          <Link to="/profile/termsofservice">Terms of Service</Link>
          &nbsp;|&nbsp;
          <Link to="/profile/privacypolicy">Privacy Policy</Link>
        </TextRegular>
      </HorizontalLeftAlignedContainer>

      <HorizontalLeftAlignedContainer
        style={{ marginTop: ITEM_VERTICAL_SPACING }}
      >
        <TextRegular>&copy; 2023 Hopewell Opportunity Fund</TextRegular>
      </HorizontalLeftAlignedContainer>
    </ScreenContainer>
  );
};
