import React, { useContext } from "react";
import { RSVPStatusLabel } from "../../styles/labels";
import { EventStatus } from "../../types/enums";
import {
  isInThePast,
  isInTheFuture,
  opensAt,
  formatDateTime,
  getDateInXHours,
} from "../../utils";
import { EventFull } from "../../types";
import { userContext } from "../../UserContext";

type RSVPStatusSectionProps = {
  eventDetails: EventFull;
  hasRSVP: boolean;
};

export const RSVPStatusSection = (props: RSVPStatusSectionProps) => {
  const { eventDetails, hasRSVP } = props;
  const userInfo = useContext(userContext);
  const currentUserId = userInfo ? userInfo.id : "";
  const going = eventDetails.attendeeList
    ? eventDetails.attendeeList.includes(currentUserId)
    : false;
  const waitlistPosition = eventDetails.waitList
    ? eventDetails.waitList.indexOf(currentUserId) + 1
    : 0;
  const canGo = eventDetails.attendeeList.length < eventDetails.capacity;
  const eventDate = new Date(eventDetails.date);
  const eventEnd = getDateInXHours(eventDate, eventDetails.duration);
  const rsvpDate = new Date(eventDetails.rsvpDate);
  const noWaitlist = eventDetails.attendeeList.length < eventDetails.capacity;

  return (
    <div style={{ alignSelf: "flex-start" }}>
      {eventDetails.status === EventStatus.Canceled ? (
        // if the event is canceled, that label overrides all others
        <RSVPStatusLabel status="canceled" text="Canceled" />
      ) : isInThePast(eventEnd) ? (
        <RSVPStatusLabel
          status="past"
          text={`${eventDetails.attendeeList.length} Went`}
        />
      ) : isInThePast(eventDate) ? (
        // The event has started, but is not yet done - list as ongoing,
        // make color reflect whether user is going or can go
        <>
          {going || canGo ? (
            <RSVPStatusLabel status="going" text={`Ongoing`} />
          ) : (
            <RSVPStatusLabel status="waitlist" text={`Ongoing`} />
          )}
        </>
      ) : hasRSVP ? (
        // The event has not yet started and is not canceled, and is in 'my events'
        // Therefore, we are either going or waitlisted
        <div>
          {going ? (
            <RSVPStatusLabel
              status="going"
              text={`${eventDetails.attendeeList.length} Going`}
            />
          ) : (
            <RSVPStatusLabel
              status="waitlist"
              text={`Waitlist #${waitlistPosition}`}
            />
          )}
        </div>
      ) : (
        // The event has not yet started and is not canceled, and is in 'Discover'
        //
        <div>
          {isInTheFuture(rsvpDate) ? (
            // can't join yet
            <RSVPStatusLabel
              status="rsvp not open"
              text={`${opensAt(rsvpDate)}`}
            />
          ) : going || noWaitlist ? (
            // already on going list or is not full so we can go
            <RSVPStatusLabel
              status={"cango"}
              text={
                eventDetails.attendeeList.length + "/" + eventDetails.capacity
              }
            />
          ) : eventDetails.waitList.length == 0 ? (
            // not going, and will be first on the waitlist
            // show full but yellow in color
            <RSVPStatusLabel
              status={"full"}
              text={
                eventDetails.attendeeList.length + "/" + eventDetails.capacity
              }
            />
          ) : (
            // there is a waitlist
            <RSVPStatusLabel
              status="waitlist"
              text={`${eventDetails.waitList.length.toString()} Waiting`}
            />
          )}
        </div>
      )}
    </div>
  );
};
