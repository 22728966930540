import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import "./ImageOverlay.css";

type ImageOverlayProps = {
  imageUri: string;
  setImageUri: Dispatch<SetStateAction<string>>;
};
export const ImageOverlay = (props: ImageOverlayProps) => {
  const { imageUri, setImageUri } = props;

  const closeImageViewer = () => {
    setImageUri("");
  };

  //   useEffect(() => {
  //     setShowImageViewer(!!imageUri);
  //   }, [imageUri]);

  return (
    <>
      {!!imageUri && (
        <div className="image-overlay">
          <ImageViewer
            src={[imageUri]}
            currentIndex={0}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </div>
      )}
    </>
  );
};
