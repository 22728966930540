import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { NavButton } from "../../components/buttons/NavButton";
import { COLORS } from "../../styles/colors";
import {
  HorizontalSpaceBetweenContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { LINE_VERTICAL_SPACING } from "../../styles/spacing";
import Skeleton from "react-loading-skeleton";

type DetailsActionButtonBarProps = {
  backButtonTitle: string;
};
export const DetailsActionButtonBar = (
  props: React.PropsWithChildren<DetailsActionButtonBarProps>
) => {
  const { backButtonTitle } = props;
  const colors = COLORS["light"];
  const navigate = useNavigate();
  const navigation = useNavigation();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <HorizontalSpaceBetweenContainer
        style={{
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: colors.LIGHT_GRAY,
          padding: LINE_VERTICAL_SPACING,
        }}
      >
        <HorizontalLeftAlignedContainer>
          <NavButton
            iconName="chevron-back-outline"
            size={22}
            title={backButtonTitle}
            onClick={goBack}
            disabled={navigation.state !== "idle"}
            loading={navigation.state === "loading"}
          />
        </HorizontalLeftAlignedContainer>
        <HorizontalRightAlignedContainer>
          {props.children}
        </HorizontalRightAlignedContainer>
      </HorizontalSpaceBetweenContainer>
    </>
  );
};
