import React, { SetStateAction, useState } from "react";
import Drawer from "react-modern-drawer";
import IonIcon from "@reacticons/ionicons";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { Dispatch } from "react";

type ButtonDrawerProps = {
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  title: string;
  color?: string;
  backgroundColor?: string;
};

/*
 * A button which opens its children in a side drawer
 */
export const ButtonDrawer = (
  props: React.PropsWithChildren<ButtonDrawerProps>
) => {
  const { iconName, title, color, backgroundColor } = props;
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    document.body.style.overflow = !showDrawer ? "hidden" : "unset";

    setShowDrawer((isOpen) => {
      return !isOpen;
    });
  };

  return (
    <>
      <Drawer
        open={showDrawer}
        onClose={() => toggleDrawer()}
        direction="right"
        size={500}
        style={{ padding: 20 }}
        zIndex={2000}
      >
        {props.children}
      </Drawer>
      <SecondaryButton onClick={toggleDrawer} title={title} type="button" />
    </>
  );
};
