import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
} from "react";
import { useUsersGroups } from "../../hooks/queryMemberships";
import { GroupFull } from "../../types";
import TouchableOpacity from "../../components/general/TouchableOpacity";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { userContext } from "../../UserContext";
import { EditActionBar } from "../actionBars/EditActionBar";
import { GroupSelectionItem } from "./GroupSelectionItem";
import { UseInfiniteQueryResult } from "react-query";
import { useWindowHeight } from "@react-hook/window-size";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyLayout } from "../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyGroups } from "../../assets/images/empty-state/empty-groups.svg";
import { ListSpinner } from "../../components/general/ListSpinner";

type GroupsPickerProps = {
  groupsQueryResult: UseInfiniteQueryResult<GroupFull[]>;
  isOpen: boolean;
  closePicker: () => void;
  pickedGroups: GroupFull[];
  setPickedGroups: Dispatch<SetStateAction<GroupFull[]>>;
};

export const GroupsPicker = (props: GroupsPickerProps) => {
  const {
    groupsQueryResult,
    isOpen,
    closePicker,
    pickedGroups,
    setPickedGroups,
  } = props;
  const userInfo = useContext(userContext);
  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    groupsQueryResult;
  const windowHeight = useWindowHeight();
  const [checkedState, setCheckedState] = useState<boolean[]>([]);
  const [visibleState, setVisibleState] = useState<boolean[]>([]);
  const [suggestionItems, setSuggestionItems] = useState<GroupFull[]>([]);

  const [searchText, setSearchText] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const regex = new RegExp(e.target.value, "ig");

    const updatedVisibleState: boolean[] = [];

    suggestionItems.forEach((suggestion) => {
      const result = suggestion.name.match(regex);
      updatedVisibleState.push(!!result);
    });
    setVisibleState(updatedVisibleState);
  };

  const handleGroupSelection = (
    selectedSuggestion: GroupFull,
    selectedIndex: number
  ) => {
    const updatedCheckedState = checkedState.map((checked, index) =>
      index === selectedIndex ? !checked : checked
    );
    setCheckedState(updatedCheckedState);
  };

  const onCancel = () => {
    const newVisibleState: boolean[] = new Array(suggestionItems.length).fill(
      true
    );
    setVisibleState(newVisibleState);
    closePicker();
  };

  const onContinue = () => {
    const newPickedList: GroupFull[] = [];
    suggestionItems.forEach((suggestion, index) => {
      if (checkedState[index]) {
        newPickedList.push({ ...suggestion });
      } else {
      }
    });
    setPickedGroups(newPickedList);
    closePicker();
  };

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreGroups = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isSuccess && data && data.pages) {
      const preparedItems: GroupFull[] = [];
      const newCheckedState: boolean[] = [];
      const newVisibleState: boolean[] = [];
      data.pages.forEach((groupList: GroupFull[] | undefined) => {
        if (groupList) {
          groupList.forEach((group) => {
            const selectedInitially = pickedGroups.find(
              (item: GroupFull) => item.id === group.id
            );

            preparedItems.push({ ...group });
            newCheckedState.push(!!selectedInitially);
            newVisibleState.push(true);
          });
        }
      });
      setCheckedState(newCheckedState);
      setVisibleState(newVisibleState);
      setSuggestionItems(preparedItems);
    }
  }, [data, pickedGroups]);

  useEffect(() => {
    if (isOpen) {
      setSearchText("");
      const newCheckedState: boolean[] = [];
      const newVisibleState: boolean[] = [];
      suggestionItems.forEach((suggestion) => {
        const selectedInitially = pickedGroups.find(
          (item: GroupFull) => item.id === suggestion.id
        );

        newCheckedState.push(!!selectedInitially);
        newVisibleState.push(true);
      });
      setCheckedState(newCheckedState);
      setVisibleState(newVisibleState);
    }
  }, [isOpen, pickedGroups, suggestionItems]);

  const renderSuggestionItem = (item: GroupFull, index: number) => {
    return (
      <HorizontalLeftAlignedContainer
        key={index}
        style={{
          width: "100%",
          marginRight: ITEM_HORIZONTAL_SPACING,
          marginBottom: LINE_VERTICAL_SPACING,
          display: visibleState[index] === false ? "none" : undefined,
        }}
      >
        <TouchableOpacity
          style={{
            flex: 1,
            overflow: "hidden",
            borderStyle: "none",
            backgroundColor: "white",
          }}
          onClick={() => handleGroupSelection(item, index)}
        >
          <HorizontalLeftAlignedContainer
            style={{ paddingBottom: LINE_HORIZONTAL_SPACING }}
          >
            <input
              type="checkbox"
              checked={checkedState[index]}
              onChange={() => handleGroupSelection(item, index)}
              style={{ width: 24 }}
            />
            <GroupSelectionItem group={item} isPicked={checkedState[index]} />
          </HorizontalLeftAlignedContainer>
        </TouchableOpacity>
      </HorizontalLeftAlignedContainer>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
        height: windowHeight - 170,
      }}
    >
      <EditActionBar onCancel={onCancel} onSave={onContinue} />
      <HorizontalLeftAlignedContainer
        style={{
          marginBottom: ITEM_VERTICAL_SPACING,
          display: "flex",
        }}
      >
        <input
          type="text"
          value={searchText}
          placeholder="Type any part of the group name."
          onChange={onChange}
          maxLength={35}
          style={{ width: "100%", marginRight: 25 }}
        />
      </HorizontalLeftAlignedContainer>
      {/* <div
        id="scrollableDiv"
        style={{ height: windowHeight - 170, overflowY: "auto" }}
      >
        {isSuccess && data && (
          <div style={{ flexDirection: "row" }}>
            {suggestionItems.map((suggestion: GroupFull, index: number) => {
              return renderSuggestionItem(suggestion, index);
            })}
          </div>
        )}
      </div> */}
      <InfiniteScroll
        dataLength={suggestionItems.length} //This is important field to render the next data
        next={fetchMoreGroups}
        hasMore={!!hasMore()}
        loader={<h4>Loading...</h4>}
        height={windowHeight - 170}
      >
        {suggestionItems.length <= 0 && (
          <EmptyLayout
            title="No Groups found"
            subTitle="You can only send multi-group messages to groups that you own!"
          >
            <EmptyGroups />
          </EmptyLayout>
        )}
        <div style={{ flexDirection: "row" }}>
          {suggestionItems.map((suggestion: GroupFull, index: number) => {
            return renderSuggestionItem(suggestion, index);
          })}
        </div>
        {hasNextPage && isFetchingNextPage && <ListSpinner />}
      </InfiniteScroll>
    </div>
  );
};
