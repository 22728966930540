import React, { useContext } from "react";
import { useNavigation } from "react-router-dom";
import { ChatThreadsSection } from "../Sections/ChatThreadsSection";
import { useWindowHeight } from "@react-hook/window-size";
import { ThreadFull, defaultThreadDetails } from "../../../types";
import { UseQueryResult } from "react-query";

export type ChatsActivityTabProps = {
  dmThreadsResult: UseQueryResult<ThreadFull[]>;
};

export const ChatsTab = (props: ChatsActivityTabProps) => {
  const { dmThreadsResult } = props;
  const { data, isSuccess } = dmThreadsResult;
  const navigation = useNavigation();
  return (
    <>
      <ChatThreadsSection
        chatThreadsData={
          navigation.state !== "loading"
            ? data
            : [
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
              ]
        }
        chatThreadsDataIsSuccess={isSuccess}
      />
    </>
  );
};
