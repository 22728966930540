import React, { Dispatch, SetStateAction, useContext } from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { H2, TextRegular } from "../../styles/text";
// import { COLORS } from "../styles/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { EditTitle } from "./EditTitle";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import "./TitledDatePicker.css";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import moment from "moment-timezone";
import { userContext } from "../../UserContext";
import { defaultTimeZone } from "../../constants/defaultLocation";

export type TitledDateTimePickerProps = {
  title: string;
  formDataName: string;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  validateDate: (date: Date) => Date;
  required?: boolean;
};

// interface Props {
//   onClick: () => void;
//   value: ReactNode;
// }
export type Ref = HTMLButtonElement;

export const TitledDateTimePicker = (props: TitledDateTimePickerProps) => {
  const { title, formDataName, date, setDate, validateDate } = props;
  const navigation = useNavigation();
  const userInfo = useContext(userContext);

  // const colors = COLORS["light"];
  const handleChange = (date: Date) => {
    const validatedDate = validateDate(date);
    setDate(validatedDate);
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <EditTitle title={title} required={false} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <HorizontalLeftAlignedContainer style={{ marginRight: 6 }}>
          <DatePicker
            selected={date}
            onChange={handleChange}
            dateFormat="yyyy/MM/dd hh:mm aa zz"
            showTimeSelect
            timeFormat="hh:mm aa"
            timeIntervals={15}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HorizontalLeftAlignedContainer
                style={{ flex: 1, backgroundColor: "white", padding: 12 }}
              >
                <H2>
                  {date.toLocaleString("default", {
                    month: "long",
                    year: "numeric",
                  })}
                </H2>

                <HorizontalRightAlignedContainer style={{ flex: 1 }}>
                  <button
                    type="button"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>

                  <button
                    type="button"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </HorizontalRightAlignedContainer>
              </HorizontalLeftAlignedContainer>
            )}
          />
        </HorizontalLeftAlignedContainer>
      )}
      <input
        type="hidden"
        name={formDataName}
        value={date.getTime().toString()}
      />
    </div>
  );
};
