import React, { useContext, useEffect, useState } from "react";
import { H2, TextRegular, TextSmall } from "../../styles/text";
import { useRevisionHistory } from "../../hooks/queryEditLog";
import { COLORS } from "../../styles/colors";
import { getCardSize, getDateOrTimeAgo } from "../../utils";
import { EditLogFull } from "../../types/editlogTypes";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../styles/container";
import {
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { blankProfileImageURL } from "../../constants/defaultImageURLs";
import { ProfileImage } from "../../components/image/ProfileImage";
import InfiniteScroll from "react-infinite-scroll-component";
import { userContext } from "../../UserContext";

type RevisionHistoryProps = {
  title: string;
  targetType: string;
  targetId: string;
};

export const RevisionHistory = (props: RevisionHistoryProps) => {
  const { title, targetType, targetId } = props;
  const imgSize = getCardSize("tiny");
  const colors = COLORS["light"];
  const [historyData, setHistoryData] = useState<EditLogFull[]>([]);
  // const userInfo = useContext(userContext);

  const {
    data,
    isSuccess,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useRevisionHistory(targetType, targetId);

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreRevisions = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  const getChangedFields = (fields: string[]) => {
    if (!fields || fields.length === 0) return "nothing";
    return fields.join(", ").toLowerCase();
  };

  useEffect(() => {
    if (data) {
      setHistoryData(data.pages.map((page) => (page ? page : [])).flat());
    }
  }, [data]);

  return (
    <>
      <HorizontalContainer
        style={{
          marginTop: SECTION_VERTICAL_SPACING,
          marginBottom: SECTION_VERTICAL_SPACING,
        }}
      >
        <H2>{title}</H2>
      </HorizontalContainer>
      {isLoading && <TextRegular>Loading</TextRegular>}
      {isError && <TextRegular>Error loading revisions</TextRegular>}
      {isSuccess && (
        <div id="scrollableDiv" style={{ height: "100%", overflow: "auto" }}>
          <InfiniteScroll
            dataLength={historyData.length} //This is important field to render the next data
            next={fetchMoreRevisions}
            hasMore={hasMore()}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {historyData.map((historyItem: EditLogFull, index) => {
              const imageUri = !!historyItem.imageUri
                ? historyItem.imageUri
                : blankProfileImageURL;
              return (
                <HorizontalSpaceBetweenContainer
                  key={index}
                  style={{ alignItems: "flex-start" }}
                >
                  <HorizontalLeftAlignedContainer
                    style={{
                      alignItems: "center",
                      paddingLeft: ITEM_VERTICAL_SPACING,
                      marginBottom: ITEM_VERTICAL_SPACING,
                      width: 220,
                    }}
                  >
                    <SizedContainer width={imgSize} height={imgSize}>
                      <ProfileImage
                        width={imgSize}
                        height={imgSize}
                        borderRadius={imgSize / 2}
                        imgSrc={imageUri}
                      />
                    </SizedContainer>
                    <div style={{ paddingLeft: LINE_HORIZONTAL_SPACING }}>
                      <TextRegular>
                        {historyItem.firstName} {historyItem.lastName}
                      </TextRegular>
                      <TextSmall color={colors.PRIMARY_TEXT_COLOR}>
                        {getDateOrTimeAgo(
                          new Date(historyItem.editDate)
                          // userInfo.timeZone
                        )}
                        {/* {getDateOrTimeAgo(
                          new Date(historyItem.editDate),
                          userInfo.timeZone
                        )} */}
                      </TextSmall>
                    </div>
                  </HorizontalLeftAlignedContainer>
                  <HorizontalRightAlignedContainer
                    style={{
                      paddingRight: ITEM_VERTICAL_SPACING,
                    }}
                  >
                    <TextRegular>
                      Updated venue {getChangedFields(historyItem.fields)}
                    </TextRegular>
                  </HorizontalRightAlignedContainer>
                </HorizontalSpaceBetweenContainer>
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
