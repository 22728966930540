import IonIcon from "@reacticons/ionicons";
import { HorizontalLeftAlignedContainer } from "../../styles/container";

import { COLORS } from "../../styles/colors";
import { Icon } from "../../styles/icons";
import "./IconButton.css";

type IconButtonProps = {
  iconName: React.ComponentProps<typeof IonIcon>["name"];
  onClick: () => void;
  color?: string;
  size?: number;
  disabled?: boolean;
};

export const IconButton = (props: IconButtonProps) => {
  const { iconName, onClick, color, size, disabled } = props;
  const colors = COLORS["light"];
  return (
    <button
      className={"icon-button"}
      onClick={() => onClick()}
      disabled={disabled}
    >
      <HorizontalLeftAlignedContainer>
        <Icon
          name={iconName}
          size={size ? size : 24}
          color={color ? color : colors.PRIMARY_TEXT_COLOR}
        />
      </HorizontalLeftAlignedContainer>
    </button>
  );
};
