import React from "react";
import { useNavigation } from "react-router-dom";
import { NavButton } from "../../components/buttons/NavButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { COLORS } from "../../styles/colors";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";

type EditActionBarProps = {
  onCancel: () => void;
  onSave: () => void;
  buttonType?: "button" | "submit" | "reset";
};

export const EditActionBar = (props: EditActionBarProps) => {
  const { onCancel, onSave, buttonType } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const isBusy = navigation.state !== "idle";

  return (
    <HorizontalSpaceBetweenContainer
      style={{
        flex: 1,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: colors.LIGHT_GRAY,
        marginBottom: SECTION_VERTICAL_SPACING,
      }}
    >
      <HorizontalLeftAlignedContainer>
        <NavButton
          iconName={"close"}
          onClick={() => {
            if (!isBusy) onCancel();
          }}
          title="Cancel"
          loading={navigation.state === "loading"}
        />
      </HorizontalLeftAlignedContainer>
      <HorizontalRightAlignedContainer>
        {isBusy && (
          <BeatLoader color={colors.PRIMARY_BRAND} loading={true} size={14} />
        )}
        <PrimaryButton
          title="Save"
          onClick={onSave}
          disabled={isBusy}
          loading={navigation.state === "loading"}
          type={buttonType}
        />
      </HorizontalRightAlignedContainer>
    </HorizontalSpaceBetweenContainer>
  );
};
