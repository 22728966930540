import React, { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { COLORS } from "../../../styles/colors";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../../styles/container";
import { TextRegular, TextSmall } from "../../../styles/text";
import { MessageFull } from "../../../types";
import { getBorderRadius, getDateOrTimeAgo } from "../../../utils";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { Icon } from "../../../styles/icons";
import { isPDFUri } from "../../../utils/pdfUtil";
import { isImageUri } from "../../../utils/imageSelection";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { pdfPlaceholder } from "../../../constants/defaultImageURLs";
import { PushpinFilled } from "@ant-design/icons";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { useFetcher } from "react-router-dom";
import "./GroupBulletinBoardMessage.css";
import { userContext } from "../../../UserContext";

type GroupBulletinBoardMessageProps = {
  message: MessageFull;
  isOrganizer: boolean;
  refetch: () => void;
  showImage: (imageUri: string) => void;
};

export const GroupBulletinBoardMessage = (
  props: GroupBulletinBoardMessageProps
) => {
  const { message, isOrganizer, refetch, showImage } = props;
  const colors = COLORS["light"];
  const fetcher = useFetcher();
  // const userInfo = useContext(userContext);
  const getDisplayText = (messageText: string, imageUri?: string) => {
    if (messageText) return <TextRegular>{messageText}</TextRegular>;
    else if (imageUri && isPDFUri(imageUri))
      return (
        <TextRegular color={colors.SECONDARY_TEXT}>
          PDF file attached
        </TextRegular>
      );
    else if (imageUri && isImageUri(imageUri))
      return (
        <TextRegular color={colors.SECONDARY_TEXT}>Image attached</TextRegular>
      );
    else return "";
  };

  const handleUnPin = (message: MessageFull) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Un-pin this message?"}
            content={
              "This message will be removed from this Group's Bulletin Board."
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  messageid: message.id,
                  pinned: false.toString(),
                },
                {
                  method: "POST",
                  action: "unpin",
                }
              );
              refetch();
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  return (
    <div
      style={{
        flex: 1,
        borderStyle: "solid",
        borderColor: colors.LIGHT_GRAY,
        borderWidth: 1,
        borderRadius: getBorderRadius("medium", "rounded rect"),
        marginBottom: ITEM_VERTICAL_SPACING,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
      }}
    >
      <HorizontalSpaceBetweenContainer style={{ alignItems: "flex-start" }}>
        {getDisplayText(message.messageText, message.messageImageUri)}
        {message.messageImageUri && (
          <SizedContainer
            width={32}
            height={32}
            style={{ marginBottom: LINE_VERTICAL_SPACING }}
          >
            {isPDFUri(message.messageImageUri) ? (
              <>
                <a
                  href={message.messageImageUri}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={pdfPlaceholder}
                    alt="inline pic"
                    style={{
                      height: 36,
                      width: 36,
                      borderRadius: getBorderRadius("small", "rounded rect"),
                      overflow: "hidden",
                    }}
                  />
                </a>
              </>
            ) : (
              <button
                type="button"
                style={{
                  padding: 0,
                  boxShadow: "none",
                  margin: 0,
                  borderStyle: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  showImage(message.messageImageUri ?? "");
                }}
              >
                <img
                  src={message.messageImageUri}
                  alt="inline pic"
                  style={{
                    height: 36,
                    width: 36,
                    borderRadius: getBorderRadius("tiny", "rounded rect"),
                    overflow: "hidden",
                  }}
                />
              </button>
            )}
          </SizedContainer>
        )}
      </HorizontalSpaceBetweenContainer>
      <HorizontalSpaceBetweenContainer
        style={{
          // backgroundColor: "palegreen"
          marginTop: LINE_VERTICAL_SPACING,
        }}
      >
        <HorizontalLeftAlignedContainer>
          <TextSmall color={colors.SECONDARY_TEXT}>
            {message.firstName} {message.lastName}
          </TextSmall>
          <div
            style={{
              marginRight: LINE_HORIZONTAL_SPACING,
              marginLeft: LINE_HORIZONTAL_SPACING,
              paddingBottom: 6,
            }}
          >
            <Icon name="ellipse" size={4} color={colors.PRIMARY_TEXT_COLOR} />
          </div>
          <TextSmall color={colors.SECONDARY_TEXT} style={{ marginRight: 6 }}>
            {getDateOrTimeAgo(new Date(message.timePosted))}
            {/* {getDateOrTimeAgo(new Date(message.timePosted), userInfo.timeZone)} */}
          </TextSmall>
        </HorizontalLeftAlignedContainer>
        {isOrganizer && (
          <button
            onClick={() => handleUnPin(message)}
            className="pushpin-button"
          >
            <PushpinFilled style={{ color: colors.PRIMARY_RED }} />
          </button>
        )}
      </HorizontalSpaceBetweenContainer>
    </div>
  );
};
