import IonIcon from "@reacticons/ionicons";
import { Icon } from "../../styles/icons";
import { Emoji, TextRegular, TextRegularNW } from "../../styles/text";
import TouchableOpacity from "../general/TouchableOpacity";
import { COLORS } from "../../styles/colors";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

type GeneralAttributeProps = {
  text: string;
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  emoji?: string;
  textDecoration?: string;
  textColor?: string;
};

/*
 * Text with an optional icon on the left and optional onClick.  For use in small horizontal lists
 * of attributes separated by a dot
 */
export const GeneralAttribute = (props: GeneralAttributeProps) => {
  const { text, textColor, iconName, emoji, textDecoration } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
      }}
    >
      {iconName && (
        <div style={{ marginRight: 4, width: 25 }}>
          {navigation.state === "loading" ? (
            <Skeleton height={20} width={16} />
          ) : (
            <Icon
              name={iconName}
              size={16}
              color={textColor ? textColor : colors.PRIMARY_TEXT_COLOR}
            />
          )}
        </div>
      )}
      {emoji && (
        <div style={{ marginRight: 4, marginTop: -3, width: 25 }}>
          {navigation.state === "loading" ? (
            <Skeleton height={20} width={16} />
          ) : (
            <Emoji size="small">{emoji}</Emoji>
          )}
        </div>
      )}
      {navigation.state === "loading" ? (
        <Skeleton height={20} width={text.length * 14} />
      ) : (
        <TextRegularNW
          style={{
            textDecoration: textDecoration ? textDecoration : "none",
            color: textColor ? textColor : colors.PRIMARY_TEXT_COLOR,
            textAlign: "left",
          }}
        >
          {text}
        </TextRegularNW>
      )}
    </div>
  );
};
