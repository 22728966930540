import React, { useState } from "react";
import { ForgotPasswordErrors } from "./ForgotPasswordScreen";
import { HorizontalContainer } from "../../styles/container";
import { ItemVerticalSpacer } from "../../styles/divider";
import { H1, TextRegular } from "../../styles/text";
import { Form, useNavigation } from "react-router-dom";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { ActionBar } from "../../features/actionBars/ActionBar";
import { BeatLoader } from "react-spinners";
import { COLORS } from "../../styles/colors";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { StickyActionBar } from "../../components/StickyActionBar";

type ForgotPasswordScreenLayoutProps = {
  errors: ForgotPasswordErrors;
};

export const ForgotPasswordScreenLayout = (
  props: ForgotPasswordScreenLayoutProps
) => {
  const { errors } = props;
  const [email, setEmail] = useState("");
  const navigation = useNavigation();
  const colors = COLORS["light"];

  const isBusy = () => {
    return navigation.state !== "idle";
  };

  return (
    <ScreenContainer horizontalPadding={0}>
      <div
        style={{
          flex: 1,
        }}
      >
        <StickyActionBar>
          <DetailsActionButtonBar backButtonTitle="Back" />
        </StickyActionBar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form
          action="/forgotPassword"
          method="POST"
          style={{
            width: 400,
          }}
        >
          <HorizontalContainer
            style={{
              marginBottom: SECTION_VERTICAL_SPACING,
            }}
          >
            <H1>Reset Password</H1>
          </HorizontalContainer>
          {errors?.email && (
            <HorizontalContainer>
              <TextRegular color="red">{errors.email}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalContainer>
            <input
              type="text"
              name="email"
              value={email}
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              style={{
                paddingLeft: LINE_HORIZONTAL_SPACING,
                paddingRight: LINE_HORIZONTAL_SPACING,
              }}
            />
          </HorizontalContainer>
          <ItemVerticalSpacer />
          <HorizontalContainer>
            <SubmitButton loading={isBusy()} title="Send" />
          </HorizontalContainer>
          {isBusy() && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <BeatLoader
                color={colors.PRIMARY_BRAND}
                loading={true}
                size={14}
              />
            </HorizontalContainer>
          )}
        </Form>
      </div>
    </ScreenContainer>
  );
};
