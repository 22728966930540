import React from "react";
import { GroupFull } from "../../types";
import { PickerButton } from "../../components/buttons/PickerButton";

type GroupsPickerCardProps = {
  group: GroupFull;
  onPress: () => void;
};
export const GroupsPickerCard = (props: GroupsPickerCardProps) => {
  return <PickerButton onClick={props.onPress} title={props.group.name} />;
};
