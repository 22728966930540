import styled from "styled-components";
import { LIGHT_GRAY } from "./colors";
import { ITEM_SPACE_BETWEEN_PADDING } from "./spacing";

// export const MessageDivider = styled.view`
//   width: 100%;
//   height: 1px;
//   margin-vertical: 8px;
// `;

export const ItemDivider = styled.div`
  width: 100%;
  height: 1px;
  margin-vertical: ${ITEM_SPACE_BETWEEN_PADDING}px;
  background-color: ${LIGHT_GRAY};
`;

export const Divider = styled.div`
  flex-direction: row;
  flex: 1;
  height: 1px;
  background-color: ${LIGHT_GRAY};
`;

export const ItemVerticalSpacer = styled.div`
  flex: 1;
  flex-direction: row;
  height: 18px;
`;
