import axios from "axios";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import { BACKEND_URL } from "../env";
import {
  EventCreate,
  EventFull,
  EventRepeat,
  EventUpdate,
  ImageUploadData,
  PeopleData,
} from "../types";
import { addQueryItem } from "../utils";

export const fetchEvent = async (eventId?: string) => {
  const { data } = await axios.get(BACKEND_URL + "events/" + eventId);
  return data;
};

export const eventDetailsQuery = (enabled: boolean, eventId?: string) => ({
  queryKey: ["event", eventId],
  queryFn: async () => fetchEvent(eventId),
  enabled: enabled,
});

// export const useEvent = (eventId?: string) =>
//   useQuery(["events", eventId], () => fetchEvent(eventId), {
//     enabled: !!eventId,
//   });

export const useEvent = (
  enabled: boolean,
  eventId: string
): UseQueryResult<EventFull> => useQuery(eventDetailsQuery(enabled, eventId));

const fetchEvents = async (
  page: number,
  groupId: string | undefined,
  venueId: string | undefined,
  date: string | undefined,
  lat: number | undefined,
  long: number | undefined,
  mileage: number | undefined,
  skillLevel: number | undefined,
  courtType: string | undefined,
  eventType: string | undefined,
  userId: string | undefined
): Promise<EventFull[] | undefined> => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  if (groupId && groupId.length > 0 && groupId !== "Any") {
    queryParamString = addQueryItem(queryParamString, "groupId", groupId);
  }
  if (venueId && venueId.length > 0 && venueId !== "Any") {
    queryParamString = addQueryItem(queryParamString, "venueId", venueId);
  }
  if (
    lat &&
    lat > -90 &&
    lat < 90 &&
    long &&
    long > -180 &&
    long < 180 &&
    mileage &&
    mileage > 0
  ) {
    queryParamString = addQueryItem(
      queryParamString,
      "distance",
      mileage.toString()
    );
    queryParamString = addQueryItem(queryParamString, "lat", lat.toString());
    queryParamString = addQueryItem(queryParamString, "long", long.toString());
  }
  if (date) {
    queryParamString = addQueryItem(queryParamString, "date", date.toString());
  }
  if (courtType && courtType !== "Any") {
    queryParamString = addQueryItem(
      queryParamString,
      "courtType",
      courtType.toString()
    );
  }
  if (eventType && eventType !== "Any") {
    queryParamString = addQueryItem(
      queryParamString,
      "eventType",
      eventType.toString()
    );
  }
  if (skillLevel && skillLevel > 0) {
    queryParamString = addQueryItem(
      queryParamString,
      "skillLevel",
      skillLevel.toString()
    );
  }
  if (userId) {
    queryParamString = addQueryItem(queryParamString, "userId", userId);
  }

  const { data } = await axios.get(BACKEND_URL + "events/" + queryParamString);
  return data;
};

export const eventsQuery = (
  isEnabled: boolean,
  groupId?: string,
  venueId?: string,
  date?: string,
  lat?: number,
  long?: number,
  mileage?: number,
  skillLevel?: number,
  courtType?: string,
  eventType?: string,
  userId?: string
) => ({
  queryKey: [
    "events",
    groupId,
    venueId,
    lat,
    long,
    mileage,
    date,
    courtType,
    eventType,
    skillLevel,
    userId,
  ],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchEvents(
      pageParam,
      groupId,
      venueId,
      date,
      lat,
      long,
      mileage,
      skillLevel,
      courtType,
      eventType,
      userId
    );
  },
  enabled: isEnabled,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: EventFull[] | undefined,
    pages: (EventFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useEvents = (
  isEnabled: boolean,
  groupId?: string,
  venueId?: string,
  date?: string,
  lat?: number,
  long?: number,
  mileage?: number,
  skillLevel?: number,
  courtType?: string,
  eventType?: string,
  userId?: string
): UseInfiniteQueryResult<EventFull[]> =>
  useInfiniteQuery(
    eventsQuery(
      isEnabled,
      groupId,
      venueId,
      date,
      lat,
      long,
      mileage,
      skillLevel,
      courtType,
      eventType,
      userId
    )
  );

const fetchUsersConflicts = async (userId: string) => {
  const { data } = await axios.get(BACKEND_URL + "events/conflicts/" + userId);
  console.log("fetchUsersConflicts: ", data);
  return data;
};

export const usersConflictsQuery = (isEnabled: boolean, userId: string) => ({
  queryKey: ["conflicts-user", userId],
  queryFn: async () => {
    return fetchUsersConflicts(userId);
  },
  enabled: isEnabled,
});

export const useUsersConflicts = (
  isEnabled: boolean,
  userId: string
): UseQueryResult<string[]> => useQuery(usersConflictsQuery(isEnabled, userId));

export const saveNewEvent = async (eventData: EventCreate) => {
  const { data } = await axios.post(BACKEND_URL + "events/", eventData);
  return data;
};

export const saveRepeatedEvent = async (repeatInfo: EventRepeat) => {
  const listsData = {
    dates: JSON.stringify(repeatInfo.dates),
    rsvpOpensDates: JSON.stringify(repeatInfo.rsvpOpensDates),
  };

  const { data } = await axios.post(
    BACKEND_URL + "events/repeatnew2/" + repeatInfo.eventId,
    listsData
  );
  return data;
};

export const patchEvent = async (eventId: string, eventData: EventUpdate) => {
  await axios.patch(BACKEND_URL + "events/" + eventId, eventData);
  return true;
};

export const setHostsAndEarlyRSVPs = async (
  eventId: string,
  hosts: PeopleData[],
  earlyRSVPs: PeopleData[]
) => {
  const listsData = {
    hosts: JSON.stringify(hosts),
    earlyRSVPs: JSON.stringify(earlyRSVPs),
  };
  await axios.patch(BACKEND_URL + "events/hosts/" + eventId, listsData);
  return true;
};

export const setEventStatus = async (
  eventId: string,
  status: string,
  doRecurring?: boolean
) => {
  const statusData = {
    status: status,
  };
  let queryParamString = "";
  if (doRecurring !== undefined)
    queryParamString = addQueryItem(
      queryParamString,
      "doRecurring",
      doRecurring ? "true" : "false"
    );

  await axios.patch(
    BACKEND_URL + "events/" + eventId + queryParamString,
    statusData
  );
  return true;
};

export const cleanupEvent = async () => {
  const cleanupData = {
    doAll: true,
  };
  await axios.patch(BACKEND_URL + "events/cleanevent/1", cleanupData);
};

export const uploadEventImage = async (
  eventId: string,
  imageUploadData: ImageUploadData
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageUploadData as unknown as File);
  let result;
  result = await axios.post(
    BACKEND_URL + "events/" + eventId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadEventImageFile = async (
  eventId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "events/" + eventId + "/upload",
    formData,
    postHeaders
  );
  return result;
};
