import { DateFilterButton } from "../../../features/filters/DateFilterbutton";
import { EventTypeFilterButton } from "../../../features/filters/EventTypeFilterButton";
import { SkillFilterButton } from "../../../features/filters/SkillFilterButton";
import { FilterContainer } from "../../../styles/container";
import { ITEM_HORIZONTAL_SPACING } from "../../../styles/spacing";
import { EventTypeFilter } from "../../../types/enums";
import { getBorderRadius } from "../../../utils";
import { GroupEventsFilterInfo } from "../GroupDetailsScreen";
import Skeleton from "react-loading-skeleton";

type GroupEventsFiltersProps = {
  groupEventsFilterInfo?: GroupEventsFilterInfo;
  isLoading: boolean;
};

export const GroupEventsFilters = (props: GroupEventsFiltersProps) => {
  const { groupEventsFilterInfo } = props;
  return (
    <FilterContainer>
      {groupEventsFilterInfo ? (
        <DateFilterButton
          date={groupEventsFilterInfo.dateFilter}
          setDate={groupEventsFilterInfo.setDateFilter}
          defaultDate={"Upcoming"}
        />
      ) : (
        <div style={{ width: 150 }}>
          <Skeleton
            height={32}
            borderRadius={getBorderRadius("large", "rounded rect")}
          />
        </div>
      )}
      <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
      {groupEventsFilterInfo ? (
        <EventTypeFilterButton
          value={groupEventsFilterInfo.eventTypeFilter}
          setValue={groupEventsFilterInfo.setEventTypeFilter}
          defaultValue={EventTypeFilter.Any}
        />
      ) : (
        <div style={{ width: 150 }}>
          <Skeleton
            height={32}
            borderRadius={getBorderRadius("large", "rounded rect")}
          />
        </div>
      )}
      <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
      {groupEventsFilterInfo ? (
        <SkillFilterButton
          value={groupEventsFilterInfo.skillFilter}
          setValue={groupEventsFilterInfo.setSkillFilter}
          defaultValue={2.0}
        />
      ) : (
        <div style={{ width: 150 }}>
          <Skeleton
            height={32}
            borderRadius={getBorderRadius("large", "rounded rect")}
          />
        </div>
      )}
    </FilterContainer>
  );
};
