import React from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../styles/spacing";
import { H1, TextRegular } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../buttons/NavButton";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { COLORS } from "../../styles/colors";
import { LineHorizontalSpace } from "../spacing/LineHorizontalSpace";

type CustomAlertUIProps = {
  title: string;
  content: string;
  yesButton?: string;
  noButton?: string;
  yesButtonClick: () => void;
  noButtonClick: () => void;
  onClose: () => void;
};

export const CustomAlertUI = (props: CustomAlertUIProps) => {
  const {
    title,
    content,
    yesButton,
    noButton,
    yesButtonClick,
    noButtonClick,
    onClose,
  } = props;
  const colors = COLORS["light"];
  return (
    <div
      style={{
        width: 300,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: colors.LIGHT_GRAY,
        borderRadius: getBorderRadius("large", "rounded rect"),
      }}
    >
      <HorizontalLeftAlignedContainer
        style={{ marginBottom: ITEM_VERTICAL_SPACING }}
      >
        <NavButton
          iconName="close"
          onClick={() => {
            onClose();
          }}
          padding={0}
        />
      </HorizontalLeftAlignedContainer>
      <div style={{ marginLeft: 3, marginRight: 3 }}>
        <HorizontalLeftAlignedContainer
          style={{ marginBottom: ITEM_VERTICAL_SPACING }}
        >
          <H1>{title ?? "Please Confirm"}</H1>
        </HorizontalLeftAlignedContainer>
        <HorizontalLeftAlignedContainer
          style={{
            marginBottom: ITEM_VERTICAL_SPACING,
            paddingBottom: ITEM_VERTICAL_SPACING,
            // borderBottomStyle: "solid",
            // borderBottomWidth: 1,
            // borderBottomColor: colors.LIGHT_GRAY,
          }}
        >
          <TextRegular>{content}</TextRegular>
        </HorizontalLeftAlignedContainer>
        <HorizontalSpaceBetweenContainer>
          <HorizontalLeftAlignedContainer style={{ flex: 1 }}>
            <SecondaryButton
              onClick={noButtonClick}
              title={noButton ?? "No"}
              type="button"
              flex={1}
            />
          </HorizontalLeftAlignedContainer>
          <LineHorizontalSpace />
          <HorizontalRightAlignedContainer style={{ flex: 1 }}>
            <PrimaryButton
              onClick={yesButtonClick}
              title={yesButton ?? "Yes"}
              type="button"
              flex={1}
            />
          </HorizontalRightAlignedContainer>
        </HorizontalSpaceBetweenContainer>
      </div>
    </div>
  );
};
