import {
  setKey,
  setLanguage,
  setRegion,
  geocode,
  RequestType, 
  OutputFormat
} from "react-geocode";
import { GP_API_KEY } from "../env";
import { logSentryError } from "./sentryUtil";

export const getAddressFromLatLng = async (
    lat: number,
    lng: number
) => {
    setKey(GP_API_KEY ?? "");
    setLanguage("en");
    setRegion("us");
    try {
        const {results} = await geocode(
            RequestType.LATLNG, 
            `${lat},${lng}`, 
            { 
                enable_address_descriptor: true, 
                outputFormat: OutputFormat.JSON
            }
        );
        const { city, state } = results[0].address_components.reduce(
            (acc: any, component: any) => {
                if (component.types.includes("locality"))
                acc.city = component.long_name;
                else if (component.types.includes("administrative_area_level_1"))
                acc.state = component.short_name;
                return acc;
            },
            {}
        );
        return `${city}, ${state}`;
    }
    catch (e: any) {
        logSentryError(
            "Error getting address via geocode",
            e,
            {
                tags: {
                userId: "none",
                screen: "unknown",
                function: "getAddressFromLatLng",
                },
                level: "error",
            }
        );
    }
}
