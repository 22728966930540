import React, { useEffect, useRef, useState } from "react";
import { formatDateTime, getBorderRadius } from "../../utils";
import { COLORS } from "../../styles/colors";
import { AttendeeStatusLabel, RSVPStatusLabel } from "../../styles/labels";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextSmall, H2 } from "../../styles/text";
import { ConflictRecord, EventFull } from "../../types";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { useNavigate, useNavigation } from "react-router-dom";
import { goToEventDetails } from "../../utils/navUtil";
import "./EventCard.css";
import Skeleton from "react-loading-skeleton";
import { blankEventImageURL } from "../../constants/defaultImageURLs";
import { RSVPStatusSection } from "./RSVPStatusSection";
import { Icon } from "../../styles/icons";
import { ConflictFlagLabel } from "./ConflictFlagLabel";
import { LineHorizontalSpace } from "../../components/spacing/LineHorizontalSpace";

type EventCardProps = {
  eventDetails: EventFull;
  hasRSVP: boolean;
  hasConflict?: boolean;
};

export const EventCard = (props: EventCardProps) => {
  const { eventDetails, hasRSVP, hasConflict } = props;
  // const userInfo = useContext(userContext);
  // const currentUserId = userInfo ? userInfo.id : "";
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [height, setHeight] = useState(120);
  const textDivRef = useRef<HTMLDivElement>(null);
  // const going = eventDetails.attendeeList
  //   ? eventDetails.attendeeList.includes(currentUserId)
  //   : false;
  // const waitlistPosition = eventDetails.waitList
  //   ? eventDetails.waitList.indexOf(currentUserId) + 1
  //   : 0;
  // const canGo = eventDetails.attendeeList.length < eventDetails.capacity;
  // const eventDate = new Date(eventDetails.date);
  // const eventEnd = getDateInXHours(eventDate, eventDetails.duration);
  // const rsvpDate = new Date(eventDetails.rsvpDate);
  // const noWaitlist = eventDetails.attendeeList.length < eventDetails.capacity;
  const dateString = eventDetails.date
    ? formatDateTime(new Date(eventDetails.date), true)
    : // ? formatDateTime(new Date(eventDetails.date), true, eventDetails.timeZone)
      "no date";

  const colors = COLORS["light"];

  useEffect(() => {
    if (!textDivRef.current) return;
    setHeight(textDivRef.current.offsetHeight);
  }, []);
  return (
    <div>
      <button
        className="event-card-button"
        style={{ textAlign: "left", position: "relative" }}
        onClick={() => goToEventDetails(navigate, eventDetails.id)}
      >
        {/* {conflictInfo && (
          <HorizontalRightAlignedContainer
            style={{
              position: "absolute",
              top: -8,
              right: "20%",
            }}
          >
            <ConflictFlagLabel color={conflictInfo.active ? "red" : "gray"} />
          </HorizontalRightAlignedContainer>
        )} */}

        <HorizontalLeftAlignedContainer style={{ width: "100%" }}>
          {navigation.state === "loading" ? (
            <Skeleton width={160} height={height} />
          ) : (
            <div
              style={{
                height: height,
                width: 160,
                borderRadius: getBorderRadius("small", "rounded rect"),
                overflow: "hidden",
              }}
            >
              <img
                alt="this event's pic"
                src={
                  !!eventDetails.imageUri
                    ? eventDetails.imageUri
                    : eventDetails.venueImageUri
                    ? eventDetails.venueImageUri
                    : blankEventImageURL
                }
                style={{
                  objectFit: "cover",
                  height: height,
                  width: 160,
                }}
              />
            </div>
          )}
          <div
            style={{
              marginLeft: ITEM_HORIZONTAL_SPACING,
              width: "100%",
            }}
            ref={textDivRef}
          >
            {navigation.state === "loading" ? (
              <Skeleton height={30} width={200} />
            ) : (
              <HorizontalSpaceBetweenContainer
                style={{ paddingRight: LINE_HORIZONTAL_SPACING }}
              >
                <H2>{eventDetails.title}</H2>

                <RSVPStatusSection
                  eventDetails={eventDetails}
                  hasRSVP={hasRSVP}
                />
              </HorizontalSpaceBetweenContainer>
            )}
            {navigation.state === "loading" ? (
              <Skeleton height={24} width={300} />
            ) : (
              <HorizontalLeftAlignedContainer
                style={{ marginTop: LINE_VERTICAL_SPACING }}
              >
                <TextSmall
                  //   numberOfLines={1}
                  //   ellipsizeMode="tail"
                  color={colors.PRIMARY_TEXT_COLOR}
                >
                  {eventDetails.venueNickname} · {eventDetails.groupName}
                </TextSmall>
              </HorizontalLeftAlignedContainer>
            )}
            {navigation.state === "loading" ? (
              <Skeleton height={24} width={150} />
            ) : (
              <HorizontalLeftAlignedContainer
                style={{
                  marginTop: LINE_VERTICAL_SPACING,
                  textDecorationLine: hasConflict ? "underline" : undefined,
                  textDecorationStyle: hasConflict ? "dotted" : undefined,
                  textDecorationColor: hasConflict
                    ? colors.PRIMARY_RED
                    : undefined,
                }}
              >
                <TextSmall
                  color={
                    hasConflict ? colors.PRIMARY_RED : colors.SECONDARY_TEXT
                  }
                >
                  {dateString}
                </TextSmall>
                {hasConflict && (
                  <>
                    <LineHorizontalSpace />
                    <ConflictFlagLabel color={colors.PRIMARY_RED} />
                  </>
                )}
              </HorizontalLeftAlignedContainer>
            )}
          </div>
        </HorizontalLeftAlignedContainer>
      </button>
      <div
        style={{
          height: 1,
          backgroundColor: colors.LIGHT_GRAY,
        }}
      />
    </div>
  );
};
