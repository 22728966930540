import React, { useState } from "react";
import {
  HorizontalContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { MessageSendBar } from "../../components/MessageSendBar/MessageSendBar";
import { useNavigation } from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TitledGroupsPicker } from "../../features/TitledGroupsPicker/TitledGroupsPicker";
import { GroupFull } from "../../types";
import { UseInfiniteQueryResult } from "react-query";
import Skeleton from "react-loading-skeleton";
import { PrimaryHeader } from "../../components/text/PrimaryHeader";
import { NormalText } from "../../components/text/NormalText";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { ComposeGroupErrors } from "./ComposeGroupsScreen";
import { StickyActionBar } from "../../components/StickyActionBar";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";

type ComposeGroupsScreenLayoutParams = {
  groupsQueryResult: UseInfiniteQueryResult<GroupFull[]>;
  errors?: ComposeGroupErrors;
};

export const ComposeGroupsScreenLayout = (
  props: ComposeGroupsScreenLayoutParams
) => {
  const { groupsQueryResult, errors } = props;
  const navigation = useNavigation();
  const windowHeight = useWindowHeight();
  const [groups, setGroups] = useState<GroupFull[]>([]);
  const [pinned, setPinned] = useState(false);

  const onPinnedChange = () => {
    setPinned(!pinned);
  };
  return (
    <div
      style={{
        height: windowHeight - LINE_VERTICAL_SPACING,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <StickyActionBar>
          <DetailsActionButtonBar backButtonTitle="Back" />
        </StickyActionBar>

        <SectionVerticalSpace />

        <PrimaryHeader>Compose Multi-Group Message</PrimaryHeader>

        {errors?.generic && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.generic}</TextRegular>
          </HorizontalContainer>
        )}

        <SectionVerticalSpace />

        <TitledGroupsPicker
          groupsQueryResult={groupsQueryResult}
          formDataName="groups"
          groups={groups}
          setGroups={setGroups}
          maximum={20}
        />

        <HorizontalSpaceBetweenContainer
          style={{
            paddingTop: LINE_VERTICAL_SPACING,
            paddingBottom: LINE_VERTICAL_SPACING,
            paddingLeft: ITEM_HORIZONTAL_SPACING,
            paddingRight: ITEM_HORIZONTAL_SPACING,
          }}
        >
          <NormalText>Pin message to each group's bulletin board.</NormalText>

          {navigation.state === "loading" ? (
            <Skeleton height={24} width={24} />
          ) : (
            <input
              type="checkbox"
              name="pinned"
              onChange={() => onPinnedChange()}
              checked={pinned}
              style={{ width: 21 }}
            />
          )}
        </HorizontalSpaceBetweenContainer>
      </div>
      <div>
        <HorizontalContainer style={{ width: "100%" }}>
          <MessageSendBar
            target={"/composegroupsmessage"}
            messageType="groups"
            destinationId=""
            canSendMessage={true}
            recipients={groups}
            pinned={pinned}
          />
        </HorizontalContainer>
      </div>
    </div>
  );
};
