import React from "react";
import { TextRegular } from "../../styles/text";

export const ListSpinner = () => {
  return (
    <>
      <TextRegular color="blue">loading...</TextRegular>
    </>
  );
};
