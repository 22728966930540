import React, { Dispatch, SetStateAction, useState } from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { PeopleData } from "../../types";
import Drawer from "react-modern-drawer";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { COLORS } from "../../styles/colors";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../../components/buttons/NavButton";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { EditTitle } from "../../components/inputs/EditTitle";
import { UseQueryResult } from "react-query/types/react";
import { PeoplePicker } from "../PeoplePicker/PeoplePicker";
import { PeoplePickerCard } from "../PeoplePicker/PeoplePickerCard";

export type TitledMemberPickerProps = {
  membersQueryResult: UseQueryResult<PeopleData[]>;
  title: string;
  formDataName: string;
  members: PeopleData[];
  setMembers: Dispatch<SetStateAction<PeopleData[]>>;
  maximum: number;
  ineligible: string[];
};

export const TitledMemberPicker = (props: TitledMemberPickerProps) => {
  const {
    membersQueryResult,
    title,
    formDataName,
    members,
    setMembers,
    maximum,
    ineligible,
  } = props;
  const [showMemberPicker, setShowMemberPicker] = useState(false);
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const toggleMemberPickerDrawer = () => {
    if (navigation.state !== "loading")
      setShowMemberPicker((isOpen) => {
        return !isOpen;
      });
  };

  const removeMember = (userId: string) => {
    const newRecipients = members.filter((member) => userId !== member.userId);
    setMembers(newRecipients);
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <Drawer
        open={showMemberPicker}
        onClose={() => toggleMemberPickerDrawer()}
        direction="right"
        lockBackgroundScroll={true}
        size={500}
        zIndex={2000}
      >
        <PeoplePicker
          peopleQueryResult={membersQueryResult}
          isOpen={showMemberPicker}
          closePicker={() => setShowMemberPicker(false)}
          pickedPeople={members}
          setPickedPeople={setMembers}
          ineligible={ineligible}
          buttonType="button"
          maximum={maximum}
        />
      </Drawer>
      {/* <HorizontalLeftAlignedContainer style={{ marginBottom: 8 }}>
        <TextSmall color={colors.SECONDARY_TEXT}>{title}</TextSmall>
      </HorizontalLeftAlignedContainer> */}
      <EditTitle title={title} required={false} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <HorizontalSpaceBetweenContainer
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: colors.LIGHT_GRAY,
            borderRadius: getBorderRadius("small", "rounded rect"),
            flex: 1,
            paddingLeft: LINE_HORIZONTAL_SPACING,
            paddingTop: LINE_HORIZONTAL_SPACING,
            paddingRight: LINE_HORIZONTAL_SPACING,
          }}
        >
          <HorizontalLeftAlignedContainer
            style={{ minHeight: 28, flexWrap: "wrap" }}
          >
            {members.map((item, index) => (
              <PeoplePickerCard
                key={index}
                person={item}
                onPress={() => removeMember(item.userId)}
              />
            ))}
          </HorizontalLeftAlignedContainer>
          <div style={{ paddingBottom: LINE_HORIZONTAL_SPACING }}>
            <NavButton
              iconName="add"
              onClick={() => setShowMemberPicker(true)}
              color={colors.SECONDARY_TEXT}
              size={16}
              // loading={navigation.state === "loading"}
            />
          </div>
          <input
            type="hidden"
            name={formDataName}
            value={JSON.stringify(members)}
          />
        </HorizontalSpaceBetweenContainer>
      )}
    </div>
  );
};
