// import React from "react";
import styled from "styled-components";

import {
  HEADER_VERTICAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "./spacing";
import {
  LIGHT_GRAY,
  MEDIUM_GRAY,
  PALE_GREEN,
  PRIMARY_RED,
  WHITE,
} from "./colors";

type ImageCardProps = {
  width: number;
  height: number;
};

// type DotProps = {
//   color: string;
// };

type ScreenContainerProps = {
  backgroundColor?: string;
  horizontalPadding?: number;
};

// const WIDTH = window.innerWidth;
const HEIGHT = window.innerHeight;

// export const DropShadowContainer = styled.view`
//   shadow-color: ${DARK_PRIMARY_TEXT_COLOR};
//   shadow-offset: 0px 0px;
//   shadow-opacity: 0.5;
//   shadow-radius: 10;
// `;

export const AuthScreenContainer = styled.section<ScreenContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  background-color: ${(p: ScreenContainerProps) =>
    p.backgroundColor ? p.backgroundColor : WHITE};
`;

export const MainScreenColumnLayout = styled.section<ScreenContainerProps>``;

export const MainScreenContainer = styled.section<ScreenContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: ${(p: ScreenContainerProps) =>
    p.backgroundColor ? p.backgroundColor : WHITE};
`;

export const SubScreenContainer = styled.section<ScreenContainerProps>`
  padding: 0px 0px 0px 0px;
  flex: 8;
  max-width: 100%;
  min-width: 520px;
  border-left: 1px solid ${LIGHT_GRAY};
  border-right: 1px solid ${LIGHT_GRAY};
  padding-left: ${(p: ScreenContainerProps) =>
    p.horizontalPadding !== undefined
      ? p.horizontalPadding
      : SECTION_HORIZONTAL_SPACING}px;
  padding-right: ${(p: ScreenContainerProps) =>
    p.horizontalPadding !== undefined
      ? p.horizontalPadding
      : SECTION_HORIZONTAL_SPACING}px;
  background-color: ${(p: ScreenContainerProps) =>
    p.backgroundColor ? p.backgroundColor : WHITE};
`;

type FilterContainerProps = {
  backgroundColor?: string;
};

export const FilterContainer = styled.section<FilterContainerProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding-vertical: 5px;
  padding-horizontal: 5px;
  background-color: ${(p: ScreenContainerProps) =>
    p.backgroundColor ? p.backgroundColor : WHITE};
`;

type MainListContainerProps = {
  backgroundColor?: string;
};

export const MainListContainer = styled.section<MainListContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
  padding-horizontal: 10px;
  background-color: ${(p: ScreenContainerProps) =>
    p.backgroundColor ? p.backgroundColor : WHITE};
`;

export const HorizontalContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SizedContainer = styled.div<ImageCardProps>`
  width: ${(p: ImageCardProps) => (p.width ? p.width : 64)}px;
  height: ${(p: ImageCardProps) => (p.height ? p.height : 64)}px;
  min-width: ${(p: ImageCardProps) => (p.width ? p.width : 64)}px;
`;

export const HorizontalLeftAlignedContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
`;

export const HorizontalRightAlignedContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const HorizontalSpaceBetweenContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// export const SectionContainer = styled.view`
//   padding-vertical: ${SECTION_PADDING}px;
// `;

// export const ItemContainer = styled.view`
//   padding-horizontal: ${ITEM_INTERNAL_PADDING}px;
//   padding-vertical: ${ITEM_INTERNAL_PADDING}px;
// `;

// export const SizedContainer = styled.view<ImageCardProps>`
//   width: ${(p: ImageCardProps) => (p.width ? p.width : 64)}px;
//   height: ${(p: ImageCardProps) => (p.height ? p.height : 64)}px;
// `;

// export const ImageCard = styled.imagebackground`
//   justify-content: flex-end;
//   background-color: ${LIGHT_GRAY};
//   width: 100%;
//   height: 100%;
// `;

// export const ProfileImage = styled.ImageBackground`
//   justify-content: flex-end;
//   border-radius: 200px;
//   width: 100%;
//   height: 100%;
// background-color: ${LIGHT_GRAY};
// `;

// export const SmallProfileImage = styled.ImageBackground`
//   justify-content: flex-end;
//   border-radius: 200px;
//   width: 30px;
//   height: 30px;
// `;

// export const SurfaceContainer = styled.view`
//   background-color: ${WHITE};
//   border-radius: 8px;
//   padding-vertical: 16px;
//   padding-horizontal: 20px;
//   border-color: ${LIGHT_GRAY};
//   border-width: 1px;
// `;
