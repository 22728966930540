import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Modal from "react-modal";
import {
  HorizontalContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2, TextRegular } from "../../styles/text";
import "react-day-picker/dist/style.css";
import { FilterButton } from "../../components/buttons/FilterButton";
import { GroupFull } from "../../types";
import { useUsersGroups } from "../../hooks/queryMemberships";
import { userContext } from "../../UserContext";
import TouchableOpacity from "../../components/general/TouchableOpacity";
import { blankGroupImageURL } from "../../constants/defaultImageURLs";
import InfiniteScroll from "react-infinite-scroll-component";
import { COLORS, LIGHT_GRAY } from "../../styles/colors";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { TextButton } from "../../components/buttons/TextButton";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { FilterToggleButton } from "../../components/buttons/FilterToggleButton";
import { getBorderRadius } from "../../utils";
import { customStyles } from "./constants";

type GroupsFilterButtonProps = {
  group: GroupFull | undefined;
  setGroup: Dispatch<SetStateAction<GroupFull | undefined>>;
  defaultGroup: GroupFull | undefined;
  disabled?: boolean;
};

export const GroupsFilterButton = (props: GroupsFilterButtonProps) => {
  const colors = COLORS["light"];
  const { group, setGroup, defaultGroup, disabled } = props;
  const [localGroup, setLocalGroup] = useState<GroupFull | undefined>(group);
  const defaultGroupsList: (GroupFull | undefined)[] = [undefined];
  const [groupList, setGroupList] = useState<(GroupFull | undefined)[]>([
    undefined,
  ]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useUsersGroups(true, currentUserId, 10, false, undefined);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleGroupClick = (selectedGroup: GroupFull | undefined) => {
    setLocalGroup(selectedGroup);
  };

  const handleSelect = () => {
    setGroup(localGroup);
    closeModal();
    // clear location?
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleReset = () => {
    setLocalGroup(defaultGroup);
  };

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreGroups = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (data) {
      const newGroupsList = data.pages.map((page) => (page ? page : [])).flat();
      const finalGroupsList = defaultGroupsList.concat(newGroupsList);
      setGroupList(finalGroupsList);
    }
  }, [data]);
  return (
    <>
      <FilterButton
        title={group ? group.name : "Group"}
        onClick={handleClick}
        active={!!group}
        iconName="people-outline"
        disabled={disabled}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{
              marginBottom: SECTION_VERTICAL_SPACING,
            }}
          >
            <H2>Group</H2>
            <TextButton
              title="Reset"
              onClick={() => handleReset()}
              color={colors.PRIMARY_BRAND}
            />
          </HorizontalSpaceBetweenContainer>

          {/* <HorizontalSpaceBetweenContainer
            style={{ paddingRight: 16, paddingLeft: 16 }}
          > */}
          <InfiniteScroll
            dataLength={groupList.length} //This is important field to render the next data
            next={fetchMoreGroups}
            hasMore={hasMore()}
            loader={<h4>Loading...</h4>}
            style={{ maxHeight: 300, width: 360, marginBottom: 30 }}
          >
            {groupList.map((groupItem, index) => {
              return (
                <div key={index}>
                  {/* <HorizontalLeftAlignedContainer> */}
                  {groupItem ? (
                    <TouchableOpacity
                      onClick={() => handleGroupClick(groupItem)}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        borderStyle: "none",
                        marginBottom: LINE_VERTICAL_SPACING,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <HorizontalSpaceBetweenContainer
                        style={{
                          flex: 1,
                          backgroundColor:
                            localGroup && groupItem.id === localGroup.id
                              ? colors.PALE_GRAY
                              : "white",
                          // borderStyle: "solid",
                          // borderColor: colors.LIGHT_GRAY,
                          // borderWidth: 1,
                          borderRadius: getBorderRadius(
                            "medium",
                            "rounded rect"
                          ),
                          overflow: "hidden",
                          paddingLeft: ITEM_HORIZONTAL_SPACING,
                          paddingRight: ITEM_HORIZONTAL_SPACING,
                          paddingTop: LINE_VERTICAL_SPACING,
                          paddingBottom: LINE_VERTICAL_SPACING,
                        }}
                      >
                        <TextRegular
                          style={{
                            textAlign: "left",
                            marginRight: ITEM_HORIZONTAL_SPACING,
                          }}
                        >
                          {groupItem.name}
                        </TextRegular>
                        <img
                          src={
                            groupItem.imageUri && groupItem.imageUri !== ""
                              ? groupItem.imageUri
                              : blankGroupImageURL
                          }
                          alt=""
                          style={{
                            width: 25,
                            height: 25,
                            objectFit: "cover",
                            paddingRight: 8,
                          }}
                        />
                      </HorizontalSpaceBetweenContainer>
                    </TouchableOpacity>
                  ) : (
                    <div style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
                      <FilterToggleButton
                        title="Any"
                        onClick={() => handleGroupClick(groupItem)}
                        selected={!localGroup}
                      />
                    </div>
                  )}
                  {/* </HorizontalLeftAlignedContainer> */}
                </div>
              );
            })}
          </InfiniteScroll>
          {/* </HorizontalSpaceBetweenContainer> */}
          <HorizontalContainer>
            <PrimaryButton
              flex={1}
              onClick={handleSelect}
              type="button"
              title="Apply"
            />
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
