import React from "react";
import { EventFull } from "../../../types";
import { ITEM_HORIZONTAL_SPACING } from "../../../styles/spacing";
import { ExpandableDescription } from "../../GroupDetails/Components/ExpandableDescription";

type EventDetailsDescriptionProps = {
  eventDetails: EventFull;
};
export const EventDetailsDescription = (
  props: EventDetailsDescriptionProps
) => {
  const { eventDetails } = props;

  return (
    <>
      <div
        style={{
          marginLeft: ITEM_HORIZONTAL_SPACING,
          marginRight: ITEM_HORIZONTAL_SPACING,
        }}
      >
        <ExpandableDescription text={eventDetails?.description} />
      </div>
    </>
  );
};
