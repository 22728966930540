import React, { useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import { ClickableRow } from "../../components/rows/ClickableRow";
import { ProfileImage } from "../../components/image/ProfileImage";
import { blankProfileImageURL } from "../../constants/defaultImageURLs";
import {
  HorizontalSpaceBetweenContainer,
  HorizontalLeftAlignedContainer,
  HorizontalContainer,
} from "../../styles/container";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { useContext } from "react";
import { userContext } from "../../UserContext";
import { ProfileFull } from "../../types";
import { useNavigate, useNavigation } from "react-router-dom";
import { goToEditProfile } from "../../utils/navUtil";
import { H2, TextRegular } from "../../styles/text";
import { SecondaryButton } from "../../components/buttons/SecondaryButton";
import { COLORS } from "../../styles/colors";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../components/confirmDialogs/CustomAlertUI";
import Skeleton from "react-loading-skeleton";
import { isSocialLogin } from "../../utils/authenticationUtil";
import { GeneralAttribute } from "../../components/attributes/GeneralAttribute";
import { SectionHorizontalSpace } from "../../components/spacing/SectionHorizontalSpace";
import { SecondaryLeftHeader } from "../../components/text/SecondaryLeftHeader";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { LineVerticalSpace } from "../../components/spacing/LineVerticalSpace";
import { ProfileErrors } from "./ProfileScreen";

type ProfileScreenLayoutProps = {
  profileQueryResult: UseQueryResult<ProfileFull>;
  doLogout: () => void;
  doDeleteAccount: () => void;
  doChangePassword: () => void;
  doSentryTestError: () => void;
  errors?: ProfileErrors;
};

export const ProfileScreenLayout = (props: ProfileScreenLayoutProps) => {
  const {
    profileQueryResult,
    doLogout,
    doDeleteAccount,
    doChangePassword,
    doSentryTestError,
    errors,
  } = props;
  const { data, isSuccess } = profileQueryResult;
  const [allowChangePassword, setAllowChangePassword] = useState(true);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const currentUserInfo = useContext(userContext);
  const isThisUser = data && data.id === currentUserInfo.id;
  const colors = COLORS["light"];
  const handleDeleteAccount = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={
              "Are you sure you wish to delete this account?  This action cannot be undone!!"
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              doDeleteAccount();
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  useEffect(() => {
    const checkIsSocial = async () => {
      const isSocial = await isSocialLogin();
      setAllowChangePassword(!isSocial);
    };
    checkIsSocial();
  }, []);

  return (
    <>
      {isSuccess && (
        <HorizontalSpaceBetweenContainer
          style={{
            paddingTop: SECTION_VERTICAL_SPACING,
            alignItems: "flex-start",
          }}
        >
          {errors?.generic && (
            <HorizontalContainer>
              <TextRegular color="red">{errors?.generic}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalLeftAlignedContainer
            style={{
              paddingBottom: SECTION_VERTICAL_SPACING,
              // borderBottomStyle: "solid",
              // borderBottomWidth: 1,
              // borderBottomColor: colors.LIGHT_GRAY,
            }}
          >
            <ProfileImage
              imgSrc={data.imageUri ? data.imageUri : blankProfileImageURL}
              width={136}
              height={136}
              borderRadius={100}
            />
            <SectionHorizontalSpace />
            <div>
              <SecondaryLeftHeader>
                {data.firstName} {data.lastName}
              </SecondaryLeftHeader>

              <ItemVerticalSpace />

              <div>
                {data.id === currentUserInfo.id && (
                  <GeneralAttribute
                    iconName="mail-outline"
                    text={data.email}
                    textColor={colors.SECONDARY_TEXT}
                  />
                )}
                <LineVerticalSpace />
                {navigation.state === "loading" ? (
                  <Skeleton height={20} width={120} />
                ) : (
                  <GeneralAttribute
                    iconName="location-outline"
                    text={data.displayAddress}
                    textColor={colors.SECONDARY_TEXT}
                  />
                )}
                <LineVerticalSpace />
                {navigation.state === "loading" ? (
                  <Skeleton height={20} width={120} />
                ) : (
                  <GeneralAttribute
                    iconName="flash-outline"
                    text={
                      data.skillLevel !== "N/A"
                        ? data.skillLevel + " Skill level"
                        : "Not specified"
                    }
                    textColor={colors.SECONDARY_TEXT}
                  />
                )}
              </div>
            </div>
          </HorizontalLeftAlignedContainer>

          {isThisUser && (
            <SecondaryButton
              title="Edit Profile"
              onClick={() => goToEditProfile(navigate, currentUserInfo.id)}
              type="button"
              loading={navigation.state === "loading"}
            />
          )}
        </HorizontalSpaceBetweenContainer>
      )}
      {isThisUser && (
        <>
          <HorizontalLeftAlignedContainer
            style={{
              marginTop: SECTION_VERTICAL_SPACING,
              paddingBottom: ITEM_VERTICAL_SPACING,
            }}
          >
            <H2>Account Management</H2>
          </HorizontalLeftAlignedContainer>
          {allowChangePassword && (
            <ClickableRow
              iconName="swap-horizontal-outline"
              title={"Change Password"}
              onPress={() => doChangePassword()}
              color={colors.PRIMARY_TEXT_COLOR}
            />
          )}
          <ClickableRow
            iconName="log-out-outline"
            title={"Log Out"}
            onPress={() => doLogout()}
            color={colors.PRIMARY_TEXT_COLOR}
          />
          {currentUserInfo.adminLevel > 90 && (
            <ClickableRow
              iconName="warning-outline"
              title={"Send Sentry Test Error"}
              onPress={() => doSentryTestError()}
              color={colors.PRIMARY_TEXT_COLOR}
            />
          )}
          <ClickableRow
            iconName="trash-outline"
            title={"Delete Account"}
            onPress={() => handleDeleteAccount()}
            color={colors.PRIMARY_TEXT_COLOR}
          />
        </>
      )}
    </>
  );
};
