import React, { useContext, useRef } from "react";
import { VenueFull, defaultVenueDetails } from "../../types";
import { Form, useNavigate } from "react-router-dom";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { EditVenueAttributesSection } from "./sections/EditVenueAttributesSection";
import { EditVenueContactDetailsSection } from "./sections/EditVenueContactDetailsSection";
import { EditVenueGeneralInfoSection } from "./sections/EditVenueGeneralInfoSection";
import { userContext } from "../../UserContext";
import { VenueErrors } from "./EditVenueScreen";
import { H1, TextRegular } from "../../styles/text";
import { EditActionBar } from "../../features/actionBars/EditActionBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";

type EditVenueLayoutProps = {
  venueDetails: VenueFull;
  title: string;
  errors?: VenueErrors;
};

export const EditVenueLayout = (props: EditVenueLayoutProps) => {
  const { venueDetails, title, errors } = props;
  const userInfo = useContext(userContext);
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const currentUserId = userInfo ? userInfo.id : "";
  const isAdmin = userInfo.adminLevel > 80;

  const originalVenueData = venueDetails
    ? JSON.stringify(venueDetails)
    : undefined;

  return (
    <div
      style={{
        paddingRight: SIDE_PADDING,
        paddingLeft: SIDE_PADDING,
      }}
    >
      <Form
        action={
          venueDetails.id ? "/venues/edit/" + venueDetails.id : "/venues/create"
        }
        method="POST"
        encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
      >
        <StickyActionBar>
          <EditActionBar
            onCancel={() => {
              navigate(-1);
            }}
            onSave={() => {
              if (buttonRef.current) buttonRef.current.click();
            }}
          />
        </StickyActionBar>
        {errors?.generic && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.generic}</TextRegular>
          </HorizontalContainer>
        )}
        <PrimaryLeftHeader>{title}</PrimaryLeftHeader>

        <SectionVerticalSpace />

        <EditVenueGeneralInfoSection
          limitEdits={!isAdmin && !!venueDetails.id}
          venueDetails={venueDetails}
          errors={errors}
        />
        <ItemVerticalSpace />

        <EditVenueAttributesSection
          venueDetails={venueDetails}
          errors={errors}
        />

        <ItemVerticalSpace />

        <EditVenueContactDetailsSection venueDetails={venueDetails} />

        <ItemVerticalSpace />
        {/* These items are not editable by the user  */}
        <input type="hidden" name="lasteditedby" value={currentUserId} />
        <input
          type="hidden"
          name="venueowner"
          value={venueDetails ? venueDetails.owner : defaultVenueDetails.owner}
        />
        <input
          type="hidden"
          name="venuetier"
          value={venueDetails ? venueDetails.tier : defaultVenueDetails.tier}
        />
        <input
          type="hidden"
          name="venueverificationstatus"
          value={
            venueDetails
              ? venueDetails.verificationStatus.toString()
              : defaultVenueDetails.verificationStatus.toString()
          }
        />
        <input type="hidden" name="originalvalues" value={originalVenueData} />
        <br />
      </Form>
    </div>
  );
};
