export const SIDE_PADDING = 24; // 2x unit height
export const SECTION_PADDING = 12; // unit height
export const ITEM_SPACE_BETWEEN_PADDING = 16; // 1.5x unit height
export const ITEM_INTERNAL_PADDING = 12; // unit height


// 4 pt. system: use multiples of 4 always
export const LINE_VERTICAL_SPACING = 8;
export const LINE_HORIZONTAL_SPACING = 8;

export const ITEM_VERTICAL_SPACING = 16
export const ITEM_HORIZONTAL_SPACING = 16;

export const SECTION_VERTICAL_SPACING = 24;
export const SECTION_HORIZONTAL_SPACING = 24;

export const HEADER_VERTICAL_SPACING = 24;

export const PAGE_BOTTOM_PADDING = 120