import { useContext, useEffect } from "react";
import { userContext } from "../../UserContext";
import {
  redirect,
  useActionData,
  useFetcher,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  deleteProfile,
  profileQuery,
  useProfile,
} from "../../hooks/queryProfiles";

import { QueryClient } from "react-query";
import { ProfileScreenLayout } from "./ProfileScreenLayout";
import { fdGetString } from "../../utils/formUtils";
import { Auth } from "aws-amplify";
import { deleteCurrentUser } from "../../utils/authenticationUtil";
import { ScreenContainer } from "../../features/ScreenContainer";
import { goToChangePassword } from "../../utils/navUtil";
import { logSentryError } from "../../utils/sentryUtil";

export type ProfileParams = {
  userId: string;
};

export type ProfileErrors = {
  generic?: string;
  ok: boolean;
};
export const profileAction = async (
  params: ProfileParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const userId = params.userId;
  const contextAction = fdGetString(data, "profileAction");
  const defaultErrorMessage =
    "There was an error performing the action '" +
    contextAction +
    "' on this profile.";
  try {
    if (userId === currentUserId) {
      switch (contextAction) {
        case "logout":
          await queryClient.invalidateQueries();
          await Auth.signOut();
          break;
        case "deleteAccount":
          // first delete from our database
          await deleteProfile(userId);
          // now delete from amplify (if user/pass) and log out
          await deleteCurrentUser();
          break;
        case "testsentry":
          throw new Error("Oops!");
        default:
          break;
      }
    }
  } catch (e: any) {
    logSentryError(
      "Error handling profile context action '" + contextAction + "'.",
      e,
      {
        tags: {
          userId: currentUserId,
          screen: "ProfileScreen",
          function: "profileAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }
  return { ok: true };
};

export const profileLoader = async (
  params: ProfileParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = profileQuery(currentUserId, params?.userId ?? currentUserId);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const ProfileScreen = () => {
  const params = useParams();
  const currentUserInfo = useContext(userContext);
  const userInfo = useContext(userContext);
  const errors = useActionData() as ProfileErrors;

  const profileQueryResult = useProfile(
    userInfo.id,
    params.userId ? params.userId : userInfo.id
  );
  const navigate = useNavigate();
  const fetcher = useFetcher();

  const doChangePassword = () => {
    goToChangePassword(navigate);
  };

  const doLogout = () => {
    fetcher.submit(
      {
        profileAction: "logout",
      },
      { method: "POST", action: "/profile/" + currentUserInfo.id }
    );
  };

  const doSentryTestError = () => {
    fetcher.submit(
      {
        profileAction: "testsentry",
      },
      { method: "POST", action: "/profile/" + currentUserInfo.id }
    );
  };

  const doDeleteAccount = () => {
    fetcher.submit(
      {
        profileAction: "deleteAccount",
      },
      { method: "POST", action: "/profile/" + currentUserInfo.id }
    );
  };

  useEffect(() => {
    if (profileQueryResult.isSuccess && profileQueryResult.data) {
      document.title =
        "User Profile: " +
        profileQueryResult.data.firstName +
        " " +
        profileQueryResult.data.lastName;
    }
  }, [profileQueryResult]);

  return (
    <ScreenContainer>
      <ProfileScreenLayout
        profileQueryResult={profileQueryResult}
        doLogout={doLogout}
        doChangePassword={doChangePassword}
        doDeleteAccount={doDeleteAccount}
        doSentryTestError={doSentryTestError}
        errors={errors}
      />
    </ScreenContainer>
  );
};
