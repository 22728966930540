import React, { useEffect, useState } from "react";
import { EventFull } from "../../../types";
import InfiniteScroll from "react-infinite-scroll-component";
import { EventCard } from "../../../features/EventCard/EventCard";
import { SIDE_PADDING } from "../../../styles/spacing";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyEvents } from "../../../assets/images/empty-state/empty-events.svg";
import { UseInfiniteQueryResult } from "react-query";
import { SecondaryLeftHeader } from "../../../components/text/SecondaryLeftHeader";
import { LineVerticalSpace } from "../../../components/spacing/LineVerticalSpace";

type VenueEventsSectionProps = {
  eventsQueryResult: UseInfiniteQueryResult<EventFull[] | undefined>;
};

export const VenueEventsSection = (props: VenueEventsSectionProps) => {
  const { eventsQueryResult } = props;
  const [events, setEvents] = useState<EventFull[]>([]);
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    eventsQueryResult;

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreEvents = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (data) {
      setEvents(data.pages.map((page) => (page ? page : [])).flat());
    }
  }, [data]);

  return (
    <>
      <div
        style={{
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <SecondaryLeftHeader>
          Upcoming Events for this Venue
        </SecondaryLeftHeader>
        <LineVerticalSpace border={true} />

        <InfiniteScroll
          dataLength={events.length} //This is important field to render the next data
          next={fetchMoreEvents}
          hasMore={!!hasMore()}
          loader={<h4>Loading...</h4>}
        >
          {events.length <= 0 && (
            <EmptyLayout title="No upcoming events found." subTitle="">
              <EmptyEvents />
            </EmptyLayout>
          )}
          {/** existing events */}
          {events.map((eventDetails, index) => (
            <EventCard
              key={index}
              eventDetails={eventDetails}
              hasRSVP={false}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};
