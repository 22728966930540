export const blankGroupImageURL =
  "https://picklersnet-defaultimages.s3.amazonaws.com/blankGroupImageURL.png";
export const blankVenueImageURL =
  "https://picklersnet-defaultimages.s3.amazonaws.com/blankVenueImageURL.png";
export const blankEventImageURL =
  "https://picklersnet-defaultimages.s3.amazonaws.com/blankEventImageURL.png";
export const blankProfileImageURL =
  "https://picklersnet-defaultimages.s3.amazonaws.com/blankProfileImageURL.png";
export const pdfPlaceholder =
  "https://picklersnet-defaultimages.s3.amazonaws.com/pdfIcon.png";
