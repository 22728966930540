import React, { useEffect } from "react";

import { Auth } from "aws-amplify";
import { redirect, useActionData, useNavigate } from "react-router-dom";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { CreateAccountScreenLayout } from "./CreateAccountScreenLayout";
import { accountAlreadyExists } from "../../utils/authenticationUtil";
import { logSentryError } from "../../utils/sentryUtil";

export type CreateAccountErrors = {
  ok?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  password2?: string;
  other?: string;
  confirmAccountNeeded?: boolean;
  newPassword?: string;
  newEmail?: string;
  network?: string;
};

const validateCreateAccountData = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  password2: string
) => {
  const err: CreateAccountErrors = {};

  if (!firstName) err.firstName = "Please enter a first name!";
  if (!lastName) err.lastName = "Please enter a last name!";
  if (!email) {
    err.email = "Please enter your email address!";
  } else {
    const result = await accountAlreadyExists(email);
    if (result === "ERR_NETWORK") {
      err.network = "Unable to connect, please try again later";
    } else if (result) err.email = result;
  }
  if (!password) err.password = "Please enter your password!";
  if (!password2) err.password2 = "Please re-type your password!";
  if (password !== password2)
    err.password2 = "Passwords do not match!  Please try again!";

  return err;
};

export const createAccountAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  const data = await request.formData();
  const firstName = fdGetString(data, "firstname");
  const lastName = fdGetString(data, "lastname");
  const emailAddress = fdGetString(data, "emailaddress");
  const password = fdGetString(data, "password");
  const password2 = fdGetString(data, "password2");

  const validateResult = await validateCreateAccountData(
    firstName,
    lastName,
    emailAddress,
    password,
    password2
  );

  if (Object.keys(validateResult).length) {
    return { ok: false, ...validateResult } as CreateAccountErrors;
  }

  try {
    await Auth.signUp({
      username: emailAddress, // for user/pass signups, the username will be our email address
      password: password,
      attributes: { given_name: firstName, family_name: lastName },
    });
    return {
      ok: true,
      confirmAccountNeeded: true,
      newEmail: emailAddress,
      newPassword: password,
    };
  } catch (error: any) {
    switch (error.code) {
      case "UsernameExistsException":
        return {
          ok: false,
          email:
            "An account with this email address already exists.  Please log in or use the forgotten password link.",
        };
      case "InvalidPasswordException":
        // password was not sufficient
        return {
          ok: false,
          password:
            "Passwords must be at least 8 characters and contain at least one each of uppercase, lowercase, numeric, and symbolic characters.",
        };
      case "InvalidParameterException":
        // The email was not of a valid form
        return {
          ok: false,
          email: "Please enter a valid email address.",
        };
      default:
        // Something else bad happened, alert them and log it
        logSentryError("Error Creating Account", error, {
          tags: {
            userId: "none",
            screen: "CreateAccountScreen",
            function: "createAccountAction",
          },
          level: "error",
        });
        return {
          ok: false,
          other: "Unknown error, please try again.",
        };
    }
  }
};

export const createAccountLoader = async (currentUserId: string) => {
  if (!!currentUserId) return redirect("/events");
  return { ok: true };
};

export const CreateAccountScreen = () => {
  const navigate = useNavigate();
  const errors = useActionData() as CreateAccountErrors;

  useEffect(() => {
    if (
      errors?.confirmAccountNeeded &&
      errors?.newEmail &&
      errors?.newPassword
    ) {
      navigate("/confirmAccount", {
        state: { email: errors?.newEmail, password: errors?.newPassword },
      });
    }

    document.title = "Create Account -- Picklers";
  }, [errors, navigate]);

  return <CreateAccountScreenLayout errors={errors} />;
};
