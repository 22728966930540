import { ReportTargetType } from "../../types";
import { DropDownItem } from "../../types/dropdownTypes";

const reportTopicSpam: DropDownItem = {
  label: "Spam or inappropriate content.",
  value: "Spam or inappropriate content.",
};
const reportTopicLanguage: DropDownItem = {
  label: "Inappropriate language or verbal abuse.",
  value: "Inappropriate language or verbal abuse.",
};
const reportTopicVisual: DropDownItem = {
  label: "Inappropriate visual content.",
  value: "Inappropriate visual content.",
};
const reportTopicUnsportsmanlike: DropDownItem = {
  label: "Unsportsmanlike conduct.",
  value: "Unsportsmanlike conduct.",
};
const reportTopicPhysical: DropDownItem = {
  label: "Physical violence or threats.",
  value: "Physical violence or threats.",
};
const reportTopicOther: DropDownItem = {
  label: "Something Else.",
  value: "Something Else.",
};
const reportTopicDuplicateVenue: DropDownItem = {
  label: "Duplicate Venue.",
  value: "Duplicate Venue.",
};
const reportTopicAddress: DropDownItem = {
  label: "Incorrect Address.",
  value: "Incorrect Address.",
};
const reportTopicQuestion: DropDownItem = {
  label: "I have a question.",
  value: "I have a question.",
};
const reportTopicSuggestion = {
  label: "I have a suggestion to make the app better.",
  value: "I have a suggestion to make the app better.",
};
const reportTopicBug = {
  label: "I would like to report a bug, crash, or other problem.",
  value: "I would like to report a bug, crash, or other problem.",
};

export const getReportTopics = (targetType: ReportTargetType) => {
  switch (targetType) {
    case "support":
      return [reportTopicQuestion, reportTopicSuggestion, reportTopicBug];
    case "venue":
      return [
        reportTopicSpam,
        reportTopicDuplicateVenue,
        reportTopicAddress,
        reportTopicOther,
      ];
    case "message":
      return [
        reportTopicSpam,
        reportTopicLanguage,
        reportTopicPhysical,
        reportTopicOther,
      ];
    case "membership":
    case "attendee":
    case "profile":
    case "group":
    case "event":
    default:
      return [
        reportTopicSpam,
        reportTopicLanguage,
        reportTopicUnsportsmanlike,
        reportTopicPhysical,
        reportTopicOther,
      ];
  }
};
