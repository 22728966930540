import React from "react";

type StickyActionBarProps = {
  zIndex?: number;
  backgroundColor?: string;
};

export const StickyActionBar = (
  props: React.PropsWithChildren<StickyActionBarProps>
) => {
  const { zIndex, backgroundColor } = props;
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: zIndex ? zIndex : 1000,
        backgroundColor: backgroundColor ? backgroundColor : "white",
      }}
    >
      {props.children}
    </div>
  );
};
