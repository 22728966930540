import React from "react";
import { HorizontalContainer } from "../../styles/container";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import { NewPasswordErrors } from "./NewPasswordScreen";
import { useState } from "react";
import { Form, useNavigation } from "react-router-dom";
import { COLORS } from "../../styles/colors";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";
import { TitledInput } from "../../components/inputs/TitledInput";

type NewPasswordScreenLayoutProps = {
  errors: NewPasswordErrors;
  email: string;
};

export const NewPasswordScreenLayout = (
  props: NewPasswordScreenLayoutProps
) => {
  const { errors, email } = props;
  const colors = COLORS["light"];
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [emailAddress, setEmailAddress] = useState(email);
  const navigation = useNavigation();

  const isBusy = () => {
    return navigation.state !== "idle";
  };

  return (
    <ScreenContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form action="/newPassword" method="POST" style={{ width: "100%" }}>
          <HorizontalContainer>
            <H1 color={colors.PRIMARY_TEXT_COLOR}>Set a new password</H1>
          </HorizontalContainer>
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TextRegular>
              A confirmation code has been sent to your email address.
            </TextRegular>
          </HorizontalContainer>
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Email"
              formDataName={"email"}
              value={emailAddress}
              setText={setEmailAddress}
              editable={!email}
              placeholderText="Your email address"
              maxLength={16}
              password={false}
              autoComplete="email"
            />
          </HorizontalContainer>
          {errors?.email && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.email}</TextSmall>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Code"
              formDataName={"code"}
              value={code}
              setText={setCode}
              editable={true}
              placeholderText="Enter the confirmation code that was sent to you."
              maxLength={16}
              password={false}
              autoComplete="one-time-code"
            />
          </HorizontalContainer>
          {errors?.code && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.code}</TextSmall>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="New Password"
              formDataName={"password"}
              value={password}
              setText={setPassword}
              editable={true}
              placeholderText="Enter your new password"
              maxLength={16}
              password={true}
              autoComplete="new-password"
            />
          </HorizontalContainer>
          {errors?.password && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.password}</TextSmall>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Re-type Password"
              formDataName={"password2"}
              value={password2}
              setText={setPassword2}
              editable={true}
              placeholderText="Retype your new password"
              maxLength={16}
              password={true}
            />
          </HorizontalContainer>
          {errors?.password2 && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.password2}</TextSmall>
            </HorizontalContainer>
          )}
          {errors?.other && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.other}</TextSmall>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <SubmitButton loading={isBusy()} title="Set New Password" />
          </HorizontalContainer>
          {isBusy() && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <BeatLoader
                color={colors.PRIMARY_BRAND}
                loading={true}
                size={14}
              />
            </HorizontalContainer>
          )}
        </Form>
      </div>
    </ScreenContainer>
  );
};
