import axios from "axios";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import { BACKEND_URL } from "../env";
import { ImageUploadData, PeopleData } from "../types";
import { GroupCreate, GroupFull, GroupUpdate } from "../types";
import { addQueryItem } from "../utils";

export const fetchGroup = async (groupId?: string) => {
  const { data } = await axios.get(BACKEND_URL + "groups/" + groupId);
  return data;
};

export const groupDetailsQuery = (currentUserId: string, groupId?: string) => ({
  queryKey: ["group", groupId],
  queryFn: async () => fetchGroup(groupId),
  enabled: !!currentUserId && !!groupId,
});

export const useGroup = (
  currentUserId: string,
  groupId?: string
): UseQueryResult<GroupFull> =>
  useQuery(groupDetailsQuery(currentUserId, groupId));

const fetchGroups = async (
  page: number,
  ownerId: string | undefined,
  lat: number | undefined,
  long: number | undefined,
  distance: number | undefined,
  skillLevel: number | undefined,
  access: string | undefined,
  code: string | undefined,
  isAdmin: boolean | undefined
): Promise<GroupFull[] | undefined> => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  if (ownerId && ownerId.length > 0) {
    queryParamString = addQueryItem(queryParamString, "owner", ownerId);
  }
  if (distance && distance > 0)
    queryParamString = addQueryItem(
      queryParamString,
      "distance",
      distance.toString()
    );
  if (lat && long) {
    queryParamString = addQueryItem(queryParamString, "lat", lat.toString());
    queryParamString = addQueryItem(queryParamString, "long", long.toString());
  }
  if (skillLevel && skillLevel > 0) {
    queryParamString = addQueryItem(
      queryParamString,
      "skillLevel",
      skillLevel.toString()
    );
  }
  if (access && access.length > 0) {
    queryParamString = addQueryItem(queryParamString, "access", access);
  }
  if (code && code.length > 0) {
    queryParamString = addQueryItem(queryParamString, "code", code);
  }
  if (isAdmin) {
    queryParamString = addQueryItem(
      queryParamString,
      "isAdmin",
      isAdmin.toString()
    );
  }

  const { data } = await axios.get(BACKEND_URL + "groups/" + queryParamString);
  return data;
};

export const groupsQuery = (
  enabled: boolean,
  latitude: number,
  longitude: number,
  mileage: number,
  ownerId?: string,
  skillLevel?: number,
  access?: string,
  code?: string,
  isAdmin?: boolean
) => ({
  queryKey: [
    "groups",
    ownerId,
    latitude,
    longitude,
    mileage,
    skillLevel,
    access,
    code,
    isAdmin,
  ],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchGroups(
      pageParam,
      ownerId,
      latitude,
      longitude,
      mileage,
      skillLevel,
      access,
      code,
      isAdmin
    );
  },
  enabled: enabled,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: GroupFull[] | undefined,
    pages: (GroupFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useGroups = (
  enabled: boolean,
  latitude: number,
  longitude: number,
  mileage: number,
  ownerId?: string,
  skillLevel?: number,
  access?: string,
  code?: string,
  isAdmin?: boolean
): UseInfiniteQueryResult<GroupFull[]> =>
  useInfiniteQuery(
    groupsQuery(
      enabled,
      latitude,
      longitude,
      mileage,
      ownerId,
      skillLevel,
      access,
      code,
      isAdmin
    )
  );

export const saveNewGroup = async (groupData: GroupCreate) => {
  const { data } = await axios.post(BACKEND_URL + "groups/", groupData);
  return data;
};

export const patchGroup = async (groupId: string, groupData: GroupUpdate) => {
  const { data } = await axios.patch(
    BACKEND_URL + "groups/" + groupId,
    groupData
  );
  return data;
};

export const setOrganizers = async (
  groupId: string,
  assistants: PeopleData[]
) => {
  const assistantData = {
    assistants: JSON.stringify(assistants),
  };
  await axios.patch(
    BACKEND_URL + "groups/setassistants/" + groupId,
    assistantData
  );
  return;
};

// export const deleteGroup = async (groupId: string) => {
//   await axios.delete(BACKEND_URL + "groups/" + groupId);
//   return;
// };

export const setGroupStatus = async (groupId: string, status: string) => {
  const statusData = {
    status: status,
  };
  await axios.patch(BACKEND_URL + "groups/status/" + groupId, statusData);
  return true;
};

export const uploadGroupImage = async (
  groupId: string,
  imageData: ImageUploadData
) => {
  let postHeaders = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  let formData = new FormData();
  formData.append("file", imageData as unknown as File);
  let result;

  result = await axios.post(
    BACKEND_URL + "groups/" + groupId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadGroupImageFile = async (
  groupId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "groups/" + groupId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const cleanupGroupMembers = async () => {
  const data = { doAll: true };
  await axios.patch(BACKEND_URL + "groups/cleanmemberships/1", data);
};
