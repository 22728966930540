import React, { useEffect, useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { ImagePicker } from "../../components/image/ImagePicker";
import { SkillRangeDropDowns } from "../../components/inputs/SkillRangeDropdowns";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../components/inputs/TitledDropdown";
import { TitledInput } from "../../components/inputs/TitledInput";
import { TitledLocationPicker } from "../../features/TitledLocationPicker/TitledLocationPicker";
import { TitledMemberPicker } from "../../features/TitledMemberPicker/TitledMemberPicker";
import { TitledTextarea } from "../../components/inputs/TitledTextarea";
import { blankGroupImageURL } from "../../constants/defaultImageURLs";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
// import { userContext } from "../../UserContext";
import { GroupFull, PeopleData, defaultGroupDetails } from "../../types";
import { Location } from "../../features/TitledLocationPicker/LocationPicker";
import { GroupAccessDropDownItem } from "../../types/dropdownTypes";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";
import { Access } from "../../types/enums";
import { enumValues } from "../../types/utils";
import { GroupErrors } from "./EditGroupScreen";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import { EditActionBar } from "../../features/actionBars/EditActionBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { getBorderRadius } from "../../utils";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { UseQueryResult } from "react-query";

type EditGroupFormLayoutProps = {
  groupDetails: GroupFull;
  membersQueryResult: UseQueryResult<PeopleData[]>;
  title: string;
  errors?: GroupErrors;
};

export const EditGroupFormLayout = (props: EditGroupFormLayoutProps) => {
  const { groupDetails, membersQueryResult, title, errors } = props;
  // const currentUserInfo = useContext(userContext);
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [selectedImageSrc, setSelectedImageSrc] = useState(
    groupDetails ? groupDetails.imageUri : ""
  );
  const [groupName, setGroupName] = useState(
    groupDetails ? groupDetails.name : defaultGroupDetails.name
  );
  const [groupCode, setGroupCode] = useState(
    groupDetails ? groupDetails.code : defaultGroupDetails.code
  );
  const [groupDescription, setGroupDescription] = useState(
    groupDetails ? groupDetails.description : defaultGroupDetails.description
  );
  const initialLocation: Location = {
    address: groupDetails ? groupDetails.address : defaultGroupDetails.address,
    displayAddress: groupDetails
      ? groupDetails.displayAddress
      : defaultGroupDetails.address,
    lat: groupDetails ? groupDetails.latitude : defaultLatitude,
    lng: groupDetails ? groupDetails.longitude : defaultLongitude,
  };
  const [selectedLocation, setSelectedLocation] = useState(initialLocation);
  const [groupAccessValue, setGroupAccessValue] = useState<DropdownValueType>(
    groupDetails ? groupDetails.access : defaultGroupDetails.access
  );
  const accessItems = enumValues(Access).map((value) => {
    return { label: value, value: value } as GroupAccessDropDownItem;
  });
  const [skillMinValue, setSkillMinValue] = useState<number>(
    groupDetails ? groupDetails.minSkill : defaultGroupDetails.minSkill
  );
  const [skillMaxValue, setSkillMaxValue] = useState<number>(
    groupDetails ? groupDetails.maxSkill : defaultGroupDetails.maxSkill
  );

  const [organizersList, setOrganizersList] = useState<PeopleData[]>(
    groupDetails ? groupDetails.assistants : []
  );

  const originalGroupData = groupDetails
    ? JSON.stringify(groupDetails)
    : undefined;
  const removeOrganizer = (userId: string) => {
    const updatedOrganizersList = organizersList.filter(
      (organizer) => organizer.userId !== userId
    );
    setOrganizersList(updatedOrganizersList);
  };

  useEffect(() => {
    setGroupName(groupDetails.name);
    setGroupCode(groupDetails.code);
    setGroupDescription(groupDetails.description);
    const updatedLocation: Location = {
      address: groupDetails
        ? groupDetails.address
        : defaultGroupDetails.address,
      displayAddress: groupDetails
        ? groupDetails.displayAddress
        : defaultGroupDetails.address,
      lat: groupDetails ? groupDetails.latitude : defaultLatitude,
      lng: groupDetails ? groupDetails.longitude : defaultLongitude,
    };
    setSelectedLocation(updatedLocation);
    setGroupAccessValue(groupDetails.access);
    setSkillMinValue(groupDetails.minSkill);
    setSkillMaxValue(groupDetails.maxSkill);
    setSelectedImageSrc(groupDetails.imageUri);
    setOrganizersList(groupDetails.assistants);
  }, [groupDetails]);

  return (
    <>
      {errors?.generic && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.generic}</TextRegular>
        </HorizontalContainer>
      )}
      <Form
        action={
          groupDetails.id
            ? "/groups/edit/" + groupDetails.id
            : "/groups/create/"
        }
        method="POST"
        encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
      >
        <StickyActionBar>
          <EditActionBar
            onCancel={() => {
              navigate(-1);
            }}
            onSave={() => {
              if (buttonRef.current) buttonRef.current.click();
            }}
          />
        </StickyActionBar>

        {/* the actual submission button is not displayed */}
        <button
          ref={buttonRef}
          type="submit"
          name="Save"
          style={{ display: "none" }}
        >
          Save
        </button>

        {/* save our original group data so we know what's changed when editing */}
        {/* <input type="hidden" name="ownerid" value={groupDetails.ownerId} /> */}
        <input type="hidden" name="originalvalues" value={originalGroupData} />

        <div
          style={{
            paddingRight: SIDE_PADDING,
            paddingLeft: SIDE_PADDING,
          }}
        >
          <PrimaryLeftHeader>{title}</PrimaryLeftHeader>

          <SectionVerticalSpace />
          {/* 
            Group Image Picker
          */}
          <HorizontalLeftAlignedContainer>
            <ImagePicker
              imgSrc={selectedImageSrc ? selectedImageSrc : blankGroupImageURL}
              formDataName="groupimage"
              setImgSrc={setSelectedImageSrc}
              width={350}
              height={250}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </HorizontalLeftAlignedContainer>
          {/*
            Group Name
          */}
          <HorizontalContainer>
            <TitledInput
              title="NAME"
              formDataName="groupname"
              value={groupName}
              setText={setGroupName}
              maxLength={64}
              required={true}
              placeholderText={"Name of your group"}
              editable={true}
            />
          </HorizontalContainer>
          {errors?.name && (
            <HorizontalContainer>
              <TextSmall color="red">{errors?.name}</TextSmall>
            </HorizontalContainer>
          )}
          <ItemVerticalSpace />
          {/* 
            Group Code
          */}
          <HorizontalContainer>
            <TitledInput
              title="GROUP CODE"
              formDataName="groupcode"
              value={groupCode}
              setText={setGroupCode}
              maxLength={64}
              required={true}
              placeholderText={"Unique <12 chars"}
              editable={true}
            />
          </HorizontalContainer>
          {errors?.code && (
            <HorizontalContainer>
              <TextSmall color="red">{errors?.code}</TextSmall>
            </HorizontalContainer>
          )}
          <ItemVerticalSpace />

          {/* 
           Group Location
           */}
          <HorizontalContainer>
            <TitledLocationPicker
              title="GROUP LOCATION"
              location={selectedLocation}
              setLocation={setSelectedLocation}
              showDisplayAddress={true}
              citiesOnly={true}
              prefix="group"
              required={true}
            />
          </HorizontalContainer>
          {errors?.address && (
            <HorizontalContainer>
              <TextSmall color="red">{errors?.address}</TextSmall>
            </HorizontalContainer>
          )}
          <ItemVerticalSpace />

          {/*
            Organizers
          */}
          {!!groupDetails.id && ( // can't set organizers if no members yet, only on edit group
            <HorizontalContainer>
              <TitledMemberPicker
                membersQueryResult={membersQueryResult}
                title="ORGANIZERS"
                formDataName="assistants"
                members={organizersList}
                setMembers={setOrganizersList}
                maximum={15}
                ineligible={[groupDetails.ownerId]}
              />
            </HorizontalContainer>
          )}
          {errors?.latlng && (
            <HorizontalContainer>
              <TextSmall color="red">{errors?.latlng}</TextSmall>
            </HorizontalContainer>
          )}
          <ItemVerticalSpace />

          <HorizontalSpaceBetweenContainer
            style={{ marginBottom: ITEM_VERTICAL_SPACING }}
          >
            {/*
            Public, Private, or Hidden
            */}
            <HorizontalContainer>
              <TitledDropdown
                title="GROUP ACCESS"
                formDataName="groupaccess"
                value={groupAccessValue}
                setValue={setGroupAccessValue}
                items={accessItems}
                placeholder="Estimate your skill level"
              />
            </HorizontalContainer>
            <HorizontalContainer style={{ width: ITEM_HORIZONTAL_SPACING }} />
            {/*
            Skill Level
            */}
            <HorizontalContainer>
              <SkillRangeDropDowns
                title="SKILL LEVELS"
                formDataMinName="skillmin"
                formDataMaxName="skillmax"
                skillMinValue={skillMinValue}
                skillMaxValue={skillMaxValue}
                setSkillMinValue={setSkillMinValue}
                setSkillMaxValue={setSkillMaxValue}
              />
            </HorizontalContainer>
          </HorizontalSpaceBetweenContainer>
          <ItemVerticalSpace />

          {/*
            Group Description
          */}
          <HorizontalContainer>
            <TitledTextarea
              title="ABOUT THIS GROUP"
              formDataName="groupdescription"
              value={groupDescription}
              setText={setGroupDescription}
              maxLength={9999}
              required={false}
              placeholderText={"Enter details about this group"}
            />
          </HorizontalContainer>
          <ItemVerticalSpace />
        </div>
      </Form>
    </>
  );
};
