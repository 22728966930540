import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  EventTypeValue,
  GroupAccessValue,
  VenueAccessValue,
} from "../../types";
import {
  DropDownItem,
  EventTypeDropDownItem,
  GroupAccessDropDownItem,
  NumericalDropDownItem,
} from "../../types/dropdownTypes";
import { COLORS } from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import Select from "react-select";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import { EditTitle } from "./EditTitle";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { getBorderRadius } from "../../utils";

type GeneralDropdownType =
  | DropDownItem
  | NumericalDropDownItem
  | EventTypeDropDownItem
  | GroupAccessDropDownItem;

export type DropdownValueType =
  | string
  | number
  | EventTypeValue
  | GroupAccessValue
  | VenueAccessValue;

export type TitledDropdownProps = {
  title: string;
  formDataName: string;
  value: DropdownValueType;
  setValue: Dispatch<SetStateAction<DropdownValueType>>;
  items: GeneralDropdownType[];
  placeholder?: string;
  required?: boolean;
};

export const TitledDropdown = (props: TitledDropdownProps) => {
  const { title, formDataName, value, setValue, items, placeholder, required } =
    props;
  const colors = COLORS["light"];
  const [selectedOption, setSelectedOption] = useState(items[0]);
  const navigation = useNavigation();

  useEffect(() => {
    const initialValue = items.find((item) => item.value === value);
    if (initialValue) setSelectedOption(initialValue);
  }, [value]);

  const handleChange = (selectedOption: any) => {
    setValue(selectedOption.value);
    setSelectedOption(selectedOption);
  };
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <EditTitle title={title} required={required} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <HorizontalLeftAlignedContainer>
          <Select
            options={items}
            onChange={handleChange}
            value={selectedOption}
            placeholder={placeholder}
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                flex: 1,
                fontFamily: "Poppins",
                fontSize: 14,
              }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: colors.LIGHT_GRAY,
                borderRadius: getBorderRadius("small", "rounded rect"),
              }),
            }}
          />
          <input type="hidden" name={formDataName} value={value} />
        </HorizontalLeftAlignedContainer>
      )}
    </div>
  );
};
