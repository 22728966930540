import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { userContext } from "../../UserContext";
import { EventFull, GroupFull, MemberFull, MessageFull } from "../../types";
import { GroupGeneralInfoSection } from "./Sections/GroupGeneralInfoSection";
import { userIsOrganizer } from "../../utils";
import { GroupDetailsActionButtons } from "./Sections/GroupDetailsActionButtons";
import { UseInfiniteQueryResult, UseQueryResult } from "react-query";
import { GroupBulletinBoard } from "./Sections/GroupBulletinBoard";
import { StickyActionBar } from "../../components/StickyActionBar";
import { GroupStatusBanner } from "./Sections/GroupStatusBanner";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import {
  GroupDetailsErrors,
  GroupEventsFilterInfo,
} from "./GroupDetailsScreen";
import { HorizontalContainer } from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { ImageOverlay } from "../../components/image/ImageOverlay";

type GroupDetailsLayoutProps = {
  groupQueryResult: UseQueryResult<GroupFull | undefined>;
  membershipQueryResult: UseQueryResult<MemberFull | undefined>;
  pinnedMessagesQueryResult: UseQueryResult<MessageFull[] | undefined>;
  membersQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  applicantsQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  groupEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
  groupEventsFilterInfo?: GroupEventsFilterInfo;
  canViewDetails: boolean;
  errors?: GroupDetailsErrors;
};

export const GroupDetailsLayout = (props: GroupDetailsLayoutProps) => {
  const {
    groupQueryResult,
    membershipQueryResult,
    pinnedMessagesQueryResult,
    membersQueryResult,
    applicantsQueryResult,
    groupEventsQueryResult,
    groupEventsFilterInfo,
    canViewDetails,
    errors,
  } = props;
  const { data: groupDetails, isSuccess: groupIsSuccess } = groupQueryResult;
  const { data: memberData, isSuccess: memberIsSuccess } =
    membershipQueryResult;
  const currentUserInfo = useContext(userContext);
  const isOwner = currentUserInfo.id === groupDetails?.ownerId;
  const isOrganizer = userIsOrganizer(currentUserInfo.id, groupDetails);
  const isAdmin = currentUserInfo.adminLevel > 90;
  const [overlayImageUri, setOverlayImageUri] = useState("");
  const showImage = (imageUri: string) => {
    setOverlayImageUri(imageUri);
  };

  return groupIsSuccess && memberIsSuccess && groupDetails ? (
    <>
      <StickyActionBar>
        <GroupDetailsActionButtons
          canEdit={isOwner || isAdmin}
          canChangeStatus={isOwner || isAdmin}
          canShare={!!memberData && memberData.status !== "applied"}
          groupDetails={groupDetails}
        />
      </StickyActionBar>
      <GroupStatusBanner groupStatus={groupDetails.status} />
      {errors?.generic && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.generic}</TextRegular>
        </HorizontalContainer>
      )}
      <SectionVerticalSpace />
      <GroupGeneralInfoSection
        groupDetails={groupDetails}
        memberData={memberData}
        membersQueryResult={membersQueryResult}
        applicantsQueryResult={applicantsQueryResult}
        groupEventsQueryResult={groupEventsQueryResult}
        groupEventsFilterInfo={groupEventsFilterInfo}
        canViewDetails={canViewDetails}
        onImagePress={showImage}
      />
      {(isAdmin || (memberData && memberData.status !== "applied")) && (
        <>
          <GroupBulletinBoard
            pinnedMessagesQueryResult={pinnedMessagesQueryResult}
            isOrganizer={isOrganizer}
            showImage={showImage}
          />
          <SectionVerticalSpace />
        </>
      )}
      <ImageOverlay
        imageUri={overlayImageUri}
        setImageUri={setOverlayImageUri}
      />
    </>
  ) : (
    <></>
  );
};
