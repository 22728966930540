import React from "react";
import { QueryClient } from "react-query";
import { Route } from "react-router-dom";
import {
  ComposeScreen,
  composeMessageLoader,
  composeMessageAction,
} from "../screens/ComposeScreen/ComposeScreen";
import { InboxError } from "../screens/Inbox/InboxError";
import { InboxScreen, inboxLoader } from "../screens/Inbox/InboxScreen";
import {
  MessageHistoryParams,
  MessageHistoryScreen,
  messageHistoryAction,
  messageHistoryDeleteAction,
  messageHistoryLoader,
  messageHistoryUpdateAction,
} from "../screens/MessageHistoryScreen/MessageHistoryScreen";
import { MessageHistoryError } from "../screens/MessageHistoryScreen/MessageHistoryError";
import { ComposeError } from "../screens/ComposeScreen/ComposeError";
import {
  ComposeGroupsScreen,
  composeGroupsMessageAction,
  composeGroupsMessageLoader,
} from "../screens/ComposeGroupsScreen/ComposeGroupsScreen";
import { ComposeGroupsError } from "../screens/ComposeGroupsScreen/ComposeGroupsError";

type InboxRoutesProps = {
  currentUserId: string;
  queryClient: QueryClient;
};
export const useInboxRoutes = (props: InboxRoutesProps) => {
  const { currentUserId, queryClient } = props;
  return (
    <>
      <Route
        path="inbox"
        Component={InboxScreen}
        errorElement={<InboxError />}
        loader={({ request }) => {
          return inboxLoader(request, queryClient, currentUserId);
        }}
      />
      <Route
        path="messagehistory/:messageType/:destinationId"
        Component={MessageHistoryScreen}
        errorElement={<MessageHistoryError />}
        loader={({ params, request }) => {
          return messageHistoryLoader(
            params,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={messageHistoryAction(queryClient)}
      />
      <Route
        path="messagehistory/:messageType/:destinationId/update"
        action={({ params, request }) => {
          return messageHistoryUpdateAction(
            params as MessageHistoryParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="messagehistory/:messageType/:destinationId/delete"
        action={({ params, request }) => {
          return messageHistoryDeleteAction(
            params as MessageHistoryParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="composemessage/:messageType/:destinationId?"
        Component={ComposeScreen}
        errorElement={<ComposeError />}
        loader={({ params, request }) => {
          return composeMessageLoader(request, queryClient, currentUserId);
        }}
        action={({ params, request }) =>
          composeMessageAction(params, request, queryClient, currentUserId)
        }
      />
      <Route
        path="composegroupsmessage"
        Component={ComposeGroupsScreen}
        errorElement={<ComposeGroupsError />}
        loader={({ params, request }) => {
          return composeGroupsMessageLoader(
            request,
            queryClient,
            currentUserId
          );
        }}
        action={({ params, request }) =>
          composeGroupsMessageAction(
            params,
            request,
            queryClient,
            currentUserId
          )
        }
      />
    </>
  );
};
