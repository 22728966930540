import React, { useContext, useEffect, useState } from "react";
import { UseInfiniteQueryResult } from "react-query";
import { ConflictRecord, EventFull } from "../../../types";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSpinner } from "../../../components/general/ListSpinner";
import { EventCard } from "../../../features/EventCard/EventCard";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyEvents } from "../../../assets/images/empty-state/empty-events.svg";
import { useUsersConflicts } from "../../../hooks/queryEvents";
import { userContext } from "../../../UserContext";
import { eventsOverlap } from "../../../utils";

type MyEventListSectionProps = {
  useUsersRSVPsResult: UseInfiniteQueryResult<EventFull[]>;
};

export const MyEventListSection = (props: MyEventListSectionProps) => {
  const { useUsersRSVPsResult } = props;
  const [items, setItems] = useState<EventFull[] | undefined>(undefined);
  const userInfo = useContext(userContext);
  const [conflictData, setConflictData] = useState<string[]>([]);

  // const { data: conflictData, isSuccess } = useUsersConflicts(
  //   !!userInfo.id,
  //   userInfo.id
  // );

  const hasMore = () => {
    const lastPage = useUsersRSVPsResult.data?.pages.slice(-1);
    return (
      !!useUsersRSVPsResult.hasNextPage &&
      !useUsersRSVPsResult.isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreEvents = () => {
    if (hasMore()) {
      useUsersRSVPsResult.fetchNextPage();
    }
  };

  useEffect(() => {
    let items: EventFull[] = [];
    const newList: string[] = [];

    if (useUsersRSVPsResult.isSuccess && useUsersRSVPsResult.data) {
      items = useUsersRSVPsResult.data.pages
        .map((page) => (page ? page : []))
        .flat();
      // isolate only the active events
      // const activeItems = items.filter((item) => item.status === "active");
      // iterate looking for conflicts between adjacent events
      // These events are already in order based on starting date/time
      for (let i = 0; i < items.length; i++) {
        for (let j = i + 1; j < Math.min(i + 5, items.length); j++) {
          if (eventsOverlap(items[i], items[j])) {
            newList.push(items[i].id);
            newList.push(items[j].id);
          }
        }
      }
    }
    setItems(items);
    setConflictData(newList);
  }, [useUsersRSVPsResult]);

  // useEffect(() => {
  //   const newList: string[] = [];
  //   if (items) {
  //     // isolate only the active events
  //     const activeItems = items.filter((item) => item.status === "active");
  //     // iterate looking for conflicts between adjacent events
  //     // These events are already in order based on starting date/time
  //     for (let i = 0; i < items.length; i++) {
  //       for (let j = i; j < Math.min(4, items.length); j++) {
  //         if (eventsOverlap(items[i], items[j])) {
  //           newList.push(items[i].id);
  //           newList.push(items[j].id);
  //         }
  //       }
  //     }
  //   }
  //   setConflictData(newList);
  // }, items);

  return (
    <>
      {items && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreEvents}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
        >
          {items.length <= 0 && (
            <EmptyLayout
              title="No events found"
              subTitle="Check your filters to ensure they match your intended search!"
            >
              <EmptyEvents />
            </EmptyLayout>
          )}
          {items.map((item, index) => (
            <EventCard
              key={index}
              eventDetails={item}
              hasRSVP={true}
              hasConflict={conflictData?.includes(item.id)}
            />
          ))}
          {useUsersRSVPsResult.hasNextPage &&
            useUsersRSVPsResult.isFetchingNextPage && <ListSpinner />}
        </InfiniteScroll>
      )}
    </>
  );
};
