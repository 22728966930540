import React, { Dispatch, SetStateAction, useContext } from "react";
import { COLORS } from "../../../styles/colors";
import { MessageFull } from "../../../types";
import { extractPDFFilenameFromURL, isPDFUri } from "../../../utils/pdfUtil";
import { getBorderRadius, getDateOrTimeAgo } from "../../../utils";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { TextRegular, TextSmall } from "../../../styles/text";
import { layout } from "../../../styles/layout";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { isImageUri } from "../../../utils/imageSelection";
import { PdfMessageItem } from "../../../components/image/PdfMessageItem";
import { useFetcher, useNavigate, useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "./MyMessageCard.css";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { SmallText } from "../../../components/text/SmallText";
import { userContext } from "../../../UserContext";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { Icon } from "../../../styles/icons";
import { LineHorizontalSpace } from "../../../components/spacing/LineHorizontalSpace";
import { ItemHorizontalSpace } from "../../../components/spacing/ItemHorizontalSpace";

type MyMessageCardProps = {
  key: number;
  messageDetails: MessageFull;
  setSelectedImage: Dispatch<SetStateAction<string>>;
};

export const MyMessageCard = (props: MyMessageCardProps) => {
  const { messageDetails, setSelectedImage } = props;
  const colors = COLORS["light"];
  const navigate = useNavigate();
  const navigation = useNavigation();
  const userInfo = useContext(userContext);
  const fetcher = useFetcher();

  const re = new RegExp(/\s+?\[groupInvite groupId=\"(.+)\"\]/);
  let result = messageDetails.messageText.match(re);
  let inviteGroupId = "";
  let bareMessageText = messageDetails.messageText;
  if (result && result[1]) {
    inviteGroupId = result[1];
    bareMessageText = messageDetails.messageText.replace(re, "").trim();
  }
  const handleInviteGroupClick = () => {
    navigate("/group/view/" + inviteGroupId);
  };
  const pdfFilename = extractPDFFilenameFromURL(messageDetails.messageImageUri);
  const borderRadius = getBorderRadius("large", "rounded rect");

  const handleDeleteMessage = (message: MessageFull) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Delete this message?"}
            content={"This cannot be undone."}
            yesButton="Delete"
            noButton="Never Mind"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  messageid: message.id,
                },
                {
                  method: "POST",
                  action: "delete",
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  return (
    <div
      className="my-message-card"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
        paddingTop: 8,
        marginBottom: 8,
        position: "relative",
      }}
    >
      <div style={{ alignItems: "flex-end", maxWidth: "67%" }}>
        <HorizontalRightAlignedContainer
          className="name-date"
          style={{ height: 14 }}
        >
          <HorizontalContainer
            className="my-message-actions-container"
            style={{
              marginTop: 10,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: colors.LIGHT_GRAY,
              borderRadius: getBorderRadius("tiny", "rounded rect"),
              width: 80,
              backgroundColor: colors.WHITE,
              overflow: "hidden",
              padding: 4,
              zIndex: 100,
            }}
          >
            <button
              onClick={() => handleDeleteMessage(messageDetails)}
              style={{
                paddingInline: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                margin: 0,
                borderStyle: "none",
                backgroundColor: "transparent",
              }}
            >
              <HorizontalContainer
                className="my-message-actions-container"
                style={{ width: 70 }}
              >
                <Icon
                  name="trash-outline"
                  size={16}
                  color={colors.SECONDARY_TEXT}
                />
                <LineHorizontalSpace />
                <SmallText>Delete</SmallText>
              </HorizontalContainer>
            </button>
          </HorizontalContainer>
        </HorizontalRightAlignedContainer>
        <HorizontalRightAlignedContainer
          style={{ marginTop: LINE_VERTICAL_SPACING }}
        >
          {navigation.state === "loading" ? (
            <Skeleton
              width={300}
              height={80}
              borderRadius={getBorderRadius("medium", "rounded rect")}
              style={{
                borderBottomRightRadius: 0,
              }}
            />
          ) : (
            <>
              {messageDetails.messageText && (
                <HorizontalRightAlignedContainer
                  style={{
                    paddingTop: LINE_VERTICAL_SPACING,
                    paddingBottom: LINE_VERTICAL_SPACING,
                    paddingLeft: ITEM_HORIZONTAL_SPACING,
                    paddingRight: ITEM_HORIZONTAL_SPACING,
                    maxWidth: (layout.window.width * 2) / 3,
                    backgroundColor: colors.PALE_GRAY,
                    borderRadius: getBorderRadius("medium", "rounded rect"),
                    borderBottomRightRadius: 0,
                  }}
                >
                  <div>
                    <TextRegular style={{ textAlign: "left" }}>
                      {bareMessageText}
                    </TextRegular>
                    {!!inviteGroupId && (
                      <HorizontalContainer
                        style={{ marginTop: ITEM_VERTICAL_SPACING }}
                      >
                        <div>
                          <PrimaryButton
                            title="View Group to Apply"
                            onClick={handleInviteGroupClick}
                          />
                        </div>
                      </HorizontalContainer>
                    )}
                  </div>
                </HorizontalRightAlignedContainer>
              )}
            </>
          )}
        </HorizontalRightAlignedContainer>
        {isPDFUri(messageDetails.messageImageUri) && (
          <a
            href={messageDetails.messageImageUri}
            target="_blank"
            rel="noreferrer"
          >
            <HorizontalLeftAlignedContainer
              style={{
                maxWidth: 400,
                borderBottomRightRadius: 0,
                overflow: "hidden",
                marginTop: LINE_VERTICAL_SPACING,
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: colors.PALE_GRAY,
                borderRadius: borderRadius,
              }}
            >
              <PdfMessageItem
                backgroundColor={colors.PALE_GRAY}
                pdfFilename={pdfFilename}
              />
            </HorizontalLeftAlignedContainer>
          </a>
        )}
        {isImageUri(messageDetails.messageImageUri) && (
          <TouchableOpacity
            onClick={() =>
              setSelectedImage(messageDetails.messageImageUri ?? "")
            }
            style={{
              borderStyle: "none",
              backgroundColor: "transparent",
            }}
          >
            <img
              alt="something sent in this message"
              src={messageDetails.messageImageUri}
              style={{
                marginTop: LINE_VERTICAL_SPACING,
                backgroundColor: colors.PALE_GRAY,
                borderColor: colors.PALE_GRAY,
                borderRadius: borderRadius,
                overflow: "hidden",
                borderBottomRightRadius: 0,
                maxWidth: 300,
                maxHeight: 300,
              }}
            />
          </TouchableOpacity>
        )}
      </div>
      <HorizontalContainer
        className="message-date"
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          paddingTop: 8,
          paddingBottom: 8,
          // backgroundColor: "pink",
        }}
      >
        <TextSmall color={colors.SECONDARY_TEXT}>
          {getDateOrTimeAgo(new Date(messageDetails.timePosted))}
        </TextSmall>
      </HorizontalContainer>
    </div>
  );
};
