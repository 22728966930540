import React from "react";
import { QueryClient } from "react-query";
import { Route, redirect } from "react-router-dom";
import {
  ChangePasswordScreen,
  changePasswordAction,
  changePasswordLoader,
} from "../screens/Login/ChangePasswordScreen";
import {
  ConfirmAccountScreen,
  confirmAccountAction,
  confirmAccountLoader,
} from "../screens/Login/ConfirmAccountScreen";
import {
  CreateAccountScreen,
  createAccountAction,
  createAccountLoader,
} from "../screens/Login/CreateAccountScreen";
import {
  ForceNewPasswordScreen,
  forceNewPasswordAction,
  forceNewPasswordLoader,
} from "../screens/Login/ForceNewPasswordScreen";
import {
  ForgotPasswordScreen,
  forgotPasswordAction,
  forgotPasswordLoader,
} from "../screens/Login/ForgotPasswordScreen";
import {
  LoginScreen,
  loginAction,
  loginLoader,
} from "../screens/Login/LoginScreen";
import {
  NewPasswordScreen,
  newPasswordAction,
  newPasswordLoader,
} from "../screens/Login/NewPasswordScreen";
import { CognitoUser } from "@aws-amplify/auth";
import {
  LoggingInScreen,
  loggingInLoader,
} from "../screens/Login/LoggingInScreen";
import { LoginError } from "../screens/Login/LoginError";
import { LogoutScreen } from "../screens/Login/LogoutScreen";

type LoginRoutesProps = {
  currentUserId: string;
  queryClient: QueryClient;
  user: CognitoUser | undefined | null;
  error: string;
  userNoData: boolean;
};
export const useLoginRoutes = (props: LoginRoutesProps) => {
  const { currentUserId, queryClient, user, error, userNoData } = props;
  return (
    <>
      {/* 
        Basic login
      */}
      <Route
        path="/"
        Component={LoginScreen}
        errorElement={<LoginError />}
        loader={({ request }) => {
          if (userNoData) return redirect("/loggingIn/");
          return loginLoader(request, currentUserId);
        }}
        action={({ params, request }) => {
          return loginAction(request, queryClient);
        }}
      />
      {/* 
        Creates a new user/password account
      */}
      <Route
        path="createAccount"
        Component={CreateAccountScreen}
        errorElement={<LoginError />}
        loader={() => {
          return createAccountLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return createAccountAction(request, queryClient);
        }}
      />
      {/* 
        Sends the user a code they can use to reset their password
      */}
      <Route
        path="forgotPassword"
        Component={ForgotPasswordScreen}
        loader={() => {
          return forgotPasswordLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return forgotPasswordAction(request, queryClient);
        }}
      />
      {/*
        This only occurs via admin fiat from the AWS Amplify dashboard
      */}
      <Route
        path="forceNewPassword"
        Component={ForceNewPasswordScreen}
        errorElement={<LoginError />}
        loader={() => {
          return forceNewPasswordLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return forceNewPasswordAction(request, queryClient);
        }}
      />
      {/*
        This occurs after a forgot password request, which sends
        the user a code.
      */}
      <Route
        path="newPassword"
        Component={NewPasswordScreen}
        errorElement={<LoginError />}
        loader={() => {
          return newPasswordLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return newPasswordAction(request, queryClient);
        }}
      />
      {/* 
        The user/password account must be confirmed via a code sent to their email 
      */}
      <Route
        path="confirmAccount"
        Component={ConfirmAccountScreen}
        errorElement={<LoginError />}
        loader={() => {
          return confirmAccountLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return confirmAccountAction(request, queryClient);
        }}
      />
      {/* 
        This one is different because it is only for logged in users!
        It is accessed via the user profile and requires the 'user'
        entry from our usercontext to work.
       */}
      <Route
        path="changePassword"
        Component={ChangePasswordScreen}
        errorElement={<LoginError />}
        loader={() => {
          return changePasswordLoader(currentUserId);
        }}
        action={({ params, request }) => {
          return changePasswordAction(
            request,
            queryClient,
            user,
            currentUserId
          );
        }}
      />
      <Route
        path="loggingIn/:message?"
        Component={LoggingInScreen}
        errorElement={<LoginError />}
        loader={() => {
          if (!user) return redirect("/login");
          return loggingInLoader(currentUserId, error);
        }}
      />
      <Route
        path="logout"
        Component={LogoutScreen}
        loader={() => {
          if (!user) return redirect("/login");
          return { ok: true };
        }}
      />
    </>
  );
};
