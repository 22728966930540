import React from "react";
import { QueryClient } from "react-query";
import { Route } from "react-router-dom";
import { AttendeesError } from "../screens/AttendeeScreen/AttendeesError";
import {
  AttendeesScreen,
  attendeesLoader,
  AttendeesParams,
  attendeesContextAction,
} from "../screens/AttendeeScreen/AttendeesScreen";
import { EventDetailsError } from "../screens/EventDetails/EventDetailsError";
import {
  EventDetailsScreen,
  eventDetailsLoader,
  EventDetailsParams,
  eventContextAction,
  eventDetailsAction,
} from "../screens/EventDetails/EventDetailsScreen";
import { EditEventError } from "../screens/EventEdit/EditEventError";
import {
  EditEventScreen,
  editEventLoader,
  EditEventParams,
  saveEventAction,
} from "../screens/EventEdit/EditEventScreen";
import { EventsError } from "../screens/Events/EventsError";
import { EventsScreen, eventsLoader } from "../screens/Events/EventsScreen";
import {
  RepeatEventParams,
  RepeatEventScreen,
  repeatEventAction,
  repeatEventLoader,
} from "../screens/EventRepeat/EventRepeatScreen";
import { RepeatEventError } from "../screens/EventRepeat/EventRepeatError";

type EventRoutesProps = {
  latitude: number;
  longitude: number;
  currentUserId: string;
  queryClient: QueryClient;
};
export const useEventRoutes = (props: EventRoutesProps) => {
  const { queryClient, latitude, longitude, currentUserId } = props;
  return (
    <>
      <Route
        index
        path="events"
        Component={EventsScreen}
        errorElement={<EventsError />}
        loader={({ request }) => {
          return eventsLoader(
            queryClient,
            request,
            latitude,
            longitude,
            currentUserId
          );
        }}
      />
      <Route
        path="event/view/:eventId"
        Component={EventDetailsScreen}
        errorElement={<EventDetailsError />}
        loader={({ params, request }) => {
          return eventDetailsLoader(
            params as EventDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={({ params, request }) => {
          return eventDetailsAction(
            params as EventDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="event/view/:eventId/eventContextAction"
        action={({ params, request }) => {
          return eventContextAction(
            params as EventDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="attendees/:groupId/:eventId"
        Component={AttendeesScreen}
        errorElement={<AttendeesError />}
        loader={({ params, request }) => {
          return attendeesLoader(
            params as AttendeesParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="attendees/:groupId/:eventId/attendeeContextAction/"
        action={({ params, request }) => {
          return attendeesContextAction(
            params as AttendeesParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="events/:editTask/:groupId/:eventId?"
        Component={EditEventScreen}
        errorElement={<EditEventError />}
        loader={({ params, request }) => {
          return editEventLoader(
            params as EditEventParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={saveEventAction(queryClient, currentUserId)}
      />
      <Route
        path="event/repeat/:eventId?"
        Component={RepeatEventScreen}
        errorElement={<RepeatEventError />}
        loader={({ params, request }) => {
          return repeatEventLoader(
            params as RepeatEventParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={({ params, request }) => {
          return repeatEventAction(
            params as RepeatEventParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
    </>
  );
};
