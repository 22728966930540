import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";

type NormalImageProps = {
  imageUri: string;
  alt: string;
  height: number | string;
  width: number | string;
  borderRadius?: number;
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
};

export const NormalImage = (props: NormalImageProps) => {
  const { imageUri, alt, height, width, borderRadius, objectFit } = props;
  const navigation = useNavigation();
  return (
    <>
      {navigation.state === "loading" ? (
        <div style={{ flex: 1 }}>
          <Skeleton height={height} width={width} borderRadius={borderRadius} />
        </div>
      ) : (
        <img
          alt={alt}
          src={imageUri}
          style={{
            height: height,
            width: width,
            objectFit: objectFit,
            borderRadius: borderRadius,
          }}
        />
      )}
    </>
  );
};
