import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { H2 } from "../../styles/text";
import { Auth } from "aws-amplify";
import { logSentryError } from "../../utils/sentryUtil";
import { userContext } from "../../UserContext";

export const LogoutScreen = () => {
  const userInfo = useContext(userContext);
  useEffect(() => {
    const logout = async () => {
      try {
        await Auth.signOut();
      } catch (e) {
        logSentryError("Error logging out user with Amplify!", e, {
          tags: {
            userId: userInfo.id,
            screen: "LogoutScreen",
            function: "logout",
          },
          level: "error",
        });
      }
    };
    logout();
  }, []);

  return <H2>Logging Out</H2>;
};
