import React from "react";
import { COLORS, DARK_PRIMARY_TEXT_COLOR } from "../../styles/colors";
import { TextSmall } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import "./PrimaryButton.css";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";

type PrimaryButtonProps = {
  title: string;
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
  flex?: number;
  disabled?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    title,
    onClick,
    color,
    backgroundColor,
    flex,
    disabled,
    loading,
    type,
  } = props;
  const colors = COLORS["light"];
  return (
    <button
      className={"primary-button"}
      onClick={!loading ? onClick : undefined}
      style={{
        flex: flex,
        backgroundColor: loading
          ? "transparent"
          : backgroundColor
          ? backgroundColor
          : DARK_PRIMARY_TEXT_COLOR,
        borderStyle: "none",
        borderRadius: getBorderRadius("small", "rounded rect"),
      }}
      disabled={disabled || loading}
      type={type ? type : undefined}
    >
      {loading ? (
        <Skeleton height={32} width={12 * title.length} />
      ) : (
        <TextSmall color={color ? color : colors.WHITE}>
          {loading ? "Please wait" : title}
        </TextSmall>
      )}
    </button>
  );
};
