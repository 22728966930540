import React, { useContext, useEffect, useState } from "react";
import { QueryClient, useQuery } from "react-query";
import { redirect, useActionData, useParams } from "react-router-dom";
import { userContext } from "../../UserContext";
import {
  patchVenue,
  saveNewVenue,
  uploadVenueImageFile,
  venueDetailsQuery,
} from "../../hooks/queryVenues";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";
import { VenueAccess, VenueTier } from "../../types/enums";
import {
  VenueCreate,
  VenueFull,
  VenueUpdate,
  defaultVenueDetails,
} from "../../types";
import {
  fdGetBoolean,
  fdGetByEnum,
  fdGetChecked,
  fdGetImage,
  fdGetInteger,
  fdGetNumber,
  fdGetString,
  ifDifferentBoolean,
  ifDifferentEnum,
  ifDifferentNumber,
  ifDifferentString,
} from "../../utils/formUtils";
import { EditVenueLayout } from "./EditVenueLayout";
import { ScreenContainer } from "../../features/ScreenContainer";
import { logSentryError } from "../../utils/sentryUtil";

export type EditVenueParams = {
  editTask?: string;
  venueId?: string;
};

export type VenueErrors = {
  generic?: string;
  name?: string;
  nickName?: string;
  address?: string;
  displayAddress?: string;
  latlng?: string;
  sharedCourts?: string;
  dedicatedCourts?: string;
  ok?: boolean;
};

const validateVenueInfo = (venueInfo: VenueCreate) => {
  const err: VenueErrors = {};

  if (venueInfo?.name && venueInfo.name.length < 3) {
    err.name = "Venue name must be at least 3 characters.";
  }
  if (
    (venueInfo?.nickname && venueInfo.nickname.length < 3) ||
    venueInfo.nickname.length > 20
  ) {
    err.nickName = "Venue Nickname must be 3-20 characters long";
  }
  if (
    (venueInfo?.address && venueInfo.address.length <= 0) ||
    (venueInfo?.displayAddress && venueInfo.displayAddress.length <= 0)
  ) {
    err.address = "You must select a location for this venue";
  }
  if (
    Math.abs(venueInfo.latitude) > 90 ||
    Math.abs(venueInfo.longitude) > 180
  ) {
    err.latlng = "Bad address data";
  }

  if (venueInfo.dedicatedCourts < 0) {
    err.dedicatedCourts = "Dedicated courts must be a non-negative integer.";
  }
  if (venueInfo.dedicatedCourts < 0) {
    err.sharedCourts = "Shared courts must be a non-negative integer.";
  }
  return err;
};

const generateUpdateData = (vd: VenueCreate, od: VenueFull) => {
  return {
    name: ifDifferentString(vd.name, od.name),
    nickname: ifDifferentString(vd.nickname, od.nickname),
    address: ifDifferentString(vd.address, od.address),
    displayAddress: ifDifferentString(vd.displayAddress, od.displayAddress),
    latitude: ifDifferentNumber(vd.latitude, od.latitude),
    longitude: ifDifferentNumber(vd.longitude, od.longitude),
    description: ifDifferentString(vd.description, od.description),
    contactPhone: ifDifferentString(vd.contactPhone, od.contactPhone),
    contactEmail: ifDifferentString(vd.contactEmail, od.contactEmail),
    contactName: ifDifferentString(vd.contactName, od.contactName),
    numIndoorCourts: ifDifferentNumber(vd.numIndoorCourts, od.numIndoorCourts),
    numOutdoorCourts: ifDifferentNumber(
      vd.numOutdoorCourts,
      od.numOutdoorCourts
    ),
    parking: ifDifferentBoolean(vd.parking, od.parking),
    restrooms: ifDifferentBoolean(vd.restrooms, od.restrooms),
    permanentLines: ifDifferentBoolean(vd.permanentLines, od.permanentLines),
    permanentNets: ifDifferentBoolean(vd.permanentNets, od.permanentNets),
    outdoorLighting: ifDifferentBoolean(vd.outdoorLighting, od.outdoorLighting),
    access: ifDifferentEnum<VenueAccess>(vd.access, od.access),
    openHours: ifDifferentString(vd.openHours, od.openHours),
    website: ifDifferentString(vd.website, od.website),
    verificationStatus: ifDifferentBoolean(
      vd.verificationStatus,
      od.verificationStatus
    ),
    tier: ifDifferentEnum<VenueTier>(vd.tier, od.tier),
    lastEditedBy: vd.lastEditedBy,
  } as VenueUpdate;
};

const extractNewVenueData = (data: FormData) => {
  return {
    name: fdGetString(data, "venuename"),
    nickname: fdGetString(data, "venuenickname"),
    address: fdGetString(data, "venueaddress"),
    displayAddress: fdGetString(data, "venuedisplayaddress"),
    latitude: fdGetNumber(data, "venuelatitude"),
    longitude: fdGetNumber(data, "venuelongitude"),
    description: fdGetString(data, "venuedescription"),
    contactPhone: fdGetString(data, "venuecontactphone"),
    contactEmail: fdGetString(data, "venuecontactemail"),
    contactName: fdGetString(data, "venuecontactname"),
    numIndoorCourts: fdGetInteger(data, "venuenumindoorcourts"),
    numOutdoorCourts: fdGetInteger(data, "venuenumoutdoorcourts"),
    parking: fdGetChecked(data, "venueparking"),
    restrooms: fdGetChecked(data, "venuerestrooms"),
    permanentLines: fdGetChecked(data, "venuepermanentlines"),
    permanentNets: fdGetChecked(data, "venuepermanentnets"),
    outdoorLighting: fdGetChecked(data, "venueoutdoorlighting"),
    access: fdGetByEnum<VenueAccess>(data, "venueaccessvalue"),
    openHours: fdGetString(data, "venueopenhours"),
    website: fdGetString(data, "venuewebsite"),
    verificationStatus: fdGetBoolean(data, "venueverificationstatus"),
    tier: fdGetByEnum<VenueTier>(data, "venuetier"),
    owner: fdGetString(data, "venueowner"),
    lastEditedBy: fdGetString(data, "lasteditedby"),
  } as VenueCreate;
};

export const editVenueLoader = async (
  params: EditVenueParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  //await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    if (params.editTask === "edit") {
      const query = venueDetailsQuery(currentUserId, params?.venueId);

      queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query));
    }
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const editVenueAction =
  (queryClient: QueryClient, currentUserId: string) =>
  async ({
    params,
    request,
  }: {
    params: EditVenueParams;
    request: Request;
  }) => {
    let venueId = params.venueId;
    const defaultErrorMessage = "There was an error updating this event.";

    try {
      const data = await request.formData();

      const ovresult = fdGetString(data, "originalvalues");
      const originalVenueData: VenueFull = ovresult
        ? JSON.parse(ovresult)
        : undefined;

      const venueData = extractNewVenueData(data);

      const validateResult = validateVenueInfo(venueData);
      if (Object.keys(validateResult).length) {
        return { ok: false, generic: defaultErrorMessage, ...validateResult };
      }

      const newVenueImage = await fdGetImage(data, "venueimage");

      if (!venueId) {
        // create new venue, and save its id
        const result = await saveNewVenue(venueData);
        venueId = result.id;
      } else {
        // update this venue if anything has been changed other than the lastEditedBy record
        const updateVenueData = generateUpdateData(
          venueData,
          originalVenueData
        );

        const hasUpdates = !Object.values(updateVenueData).every(
          (el) => el === undefined || el === venueData.lastEditedBy
        );
        if (hasUpdates) {
          await patchVenue(venueId, updateVenueData);
        }
      }
      // now save the image, if any
      if (newVenueImage && venueId) {
        await uploadVenueImageFile(
          venueId,
          venueData.lastEditedBy,
          newVenueImage
        );
      }
      // invalidate venues list
      queryClient.invalidateQueries(["venues"]);
      queryClient.invalidateQueries(["events"]);

      // invalidate this particular venue query
      if (venueId) queryClient.invalidateQueries(["venue", venueId]);

      // return to this venue's screen
      return redirect("/venue/view/" + venueId);
    } catch (e: any) {
      // maybe catch some errors here that need better handling?
      if (e.code === "NotSupportedError") {
        return {
          ok: false,
          generic:
            "Your browser does not support this type of file upload.  Check your settings, upgrade your browser, or use the mobile app to upload this image.",
        };
      }
      logSentryError("Error saving venue details.", e, {
        tags: {
          userId: currentUserId,
          venueId: params?.venueId,
          screen: "EditVenueScreen",
          function: "editVenueAction",
        },
        level: "error",
      });
      return { ok: false, generic: defaultErrorMessage };
    }
  };

export const EditVenueScreen = () => {
  const { venueId } = useParams();
  const seedVenueDetails = defaultVenueDetails;
  const userInfo = useContext(userContext);
  const errors = useActionData() as VenueErrors;

  seedVenueDetails.address = userInfo.address ? userInfo.address : "";
  seedVenueDetails.displayAddress = userInfo.displayAddress
    ? userInfo.displayAddress
    : "";
  seedVenueDetails.latitude = userInfo.latitude
    ? userInfo.latitude
    : defaultLatitude;
  seedVenueDetails.longitude = userInfo.longitude
    ? userInfo.longitude
    : defaultLongitude;
  const [venueDetails, setVenueDetails] = useState(seedVenueDetails);

  const venueQueryResult = useQuery(venueDetailsQuery(userInfo.id, venueId));

  useEffect(() => {
    if (venueQueryResult.isSuccess && venueQueryResult.data) {
      setVenueDetails(venueQueryResult.data);
    }
  }, [venueQueryResult]);

  useEffect(() => {
    if (venueQueryResult.isSuccess && venueQueryResult.data) {
      const title = venueDetails.name ?? "Create Venue";
      document.title = "Edit Venue: " + title;
    }
  }, [venueQueryResult, venueDetails]);

  return (
    <ScreenContainer horizontalPadding={0}>
      <EditVenueLayout
        venueDetails={venueDetails}
        title={venueId ? "Edit Venue" : "Create Venue"}
        errors={errors}
      />
    </ScreenContainer>
  );
};
