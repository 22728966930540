
export default function getSkillRange(minSkill: number, maxSkill: number) {
    let skillString = "Any"
    if (maxSkill === 10 && minSkill !== 0) {
      skillString=minSkill.toFixed(1) + "+";
    }
    else if (maxSkill !== 10 && minSkill === 0) {
      skillString=maxSkill.toFixed(1) + "-";
    }
    else if (maxSkill !== 10 && minSkill !== 0 && minSkill === maxSkill) {
      skillString=minSkill.toFixed(1);
    }
    else if (maxSkill !== 10 && minSkill !== 0) {
      skillString=minSkill.toFixed(1) + "-" + maxSkill.toFixed(1);
    }

    return skillString;
}