import { useState } from "react";

import { H1 } from "../../styles/text";
import { GroupsFilterInfo } from "./GroupsScreen";
import { GroupsListSection } from "./Sections/GroupsListSection";
import { UseInfiniteQueryResult } from "react-query";
import { GroupFull } from "../../types";
import { GroupsFilterSection } from "./Sections/GroupsFilterSection";
import { goToCreateGroup } from "../../utils/navUtil";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  HEADER_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_PADDING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { NavButton } from "../../components/buttons/NavButton";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { KeywordFilterButton } from "../../features/filters/KeywordFilterButton";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { LineHorizontalSpace } from "../../components/spacing/LineHorizontalSpace";

type GroupsScreenLayoutProps = {
  groupsQueryResult: UseInfiniteQueryResult<GroupFull[]>;
  filterInfo?: GroupsFilterInfo;
};

export const GroupsScreenLayout = (props: GroupsScreenLayoutProps) => {
  const navigate = useNavigate();
  const { groupsQueryResult, filterInfo } = props;
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const navigation = useNavigation();

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          paddingTop: HEADER_VERTICAL_SPACING,
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <PrimaryLeftHeader>Groups</PrimaryLeftHeader>
          <HorizontalRightAlignedContainer
            style={{ flex: 1, marginLeft: SECTION_HORIZONTAL_SPACING }}
          >
            {!filterInfo?.keywordFilter && !searchIsOpen && (
              <>
                <NavButton
                  iconName="add"
                  // title="Create"
                  onClick={() => goToCreateGroup(navigate)}
                  disabled={!filterInfo}
                  loading={navigation.state === "loading"}
                />
                <LineHorizontalSpace />
              </>
            )}
            {filterInfo && (
              <KeywordFilterButton
                isOpen={searchIsOpen}
                setIsOpen={setSearchIsOpen}
                value={filterInfo.keywordFilter}
                setValue={filterInfo.setKeywordFilter}
              />
            )}
          </HorizontalRightAlignedContainer>
        </div>

        {!searchIsOpen ? (
          <HorizontalLeftAlignedContainer style={{ width: "100%" }}>
            <GroupsFilterSection filterInfo={filterInfo} />
          </HorizontalLeftAlignedContainer>
        ) : (
          <div style={{ height: SECTION_PADDING }}></div>
        )}
      </div>
      <GroupsListSection groupsQueryResult={groupsQueryResult} />
    </>
  );
};
