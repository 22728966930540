import React from "react";
import { QueryClient } from "react-query";
import { Route } from "react-router-dom";
import { ProfileError } from "../screens/Profile/ProfileError";
import {
  ProfileScreen,
  profileLoader,
  ProfileParams,
  profileAction,
} from "../screens/Profile/ProfileScreen";
import { EditProfileError } from "../screens/ProfileEdit/EditProfileError";
import {
  EditProfileScreen,
  editProfileLoader,
  EditProfileParams,
  editProfileAction,
} from "../screens/ProfileEdit/EditProfileScreen";

type ProfileRoutesProps = {
  queryClient: QueryClient;
  currentUserId: string;
};
export const useProfileRoutes = (props: ProfileRoutesProps) => {
  const { queryClient, currentUserId } = props;
  return (
    <>
      <Route
        path="profile/:userId?"
        Component={ProfileScreen}
        errorElement={<ProfileError />}
        action={({ params, request }) => {
          return profileAction(
            params as ProfileParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        loader={({ params, request }) => {
          return profileLoader(
            params as ProfileParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="profile/edit/:userId"
        Component={EditProfileScreen}
        errorElement={<EditProfileError />}
        loader={({ params, request }) => {
          return editProfileLoader(
            params as EditProfileParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={editProfileAction(queryClient)}
      />
    </>
  );
};
