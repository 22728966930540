import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  SECTION_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  HEADER_VERTICAL_SPACING,
  ITEM_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { H1 } from "../../styles/text";
import { DiscoverEventsTab } from "./Tabs/DiscoverEventsTab";
import { MyEventsTab } from "./Tabs/MyEventsTab";
import { MyGroupsTab } from "./Tabs/MyGroupsTab";
import { useState, useRef, useEffect } from "react";
import { EventFull, GroupFull } from "../../types";
import { DiscoverEventsFilterInfo, MyEventsFilterInfo } from "./EventsScreen";
import { UseInfiniteQueryResult } from "react-query";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { NormalText } from "../../components/text/NormalText";
import { useNavigation } from "react-router-dom";

type EventsScreenLayoutProps = {
  tabIndex: number;
  onTabSelect: (tabIndex: number) => void;
  myEventsFilterInfo?: MyEventsFilterInfo;
  discoverEventsFilterInfo?: DiscoverEventsFilterInfo;
  discoverEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
  useUsersRSVPsResult: UseInfiniteQueryResult<EventFull[]>;
  useUsersGroupsResult: UseInfiniteQueryResult<GroupFull[]>;
};

export const EventsScreenLayout = (props: EventsScreenLayoutProps) => {
  const {
    tabIndex,
    onTabSelect,
    myEventsFilterInfo,
    discoverEventsFilterInfo,
    discoverEventsQueryResult,
    useUsersRSVPsResult,
    useUsersGroupsResult,
  } = props;
  const [height, setHeight] = useState(500);
  const divRef = useRef<HTMLDivElement>(null);
  const navigation = useNavigation();
  useEffect(() => {
    if (!divRef.current) return;
    setHeight(divRef.current.offsetHeight);
  }, []);

  return (
    <div
      style={{
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <Tabs
        selectedTabClassName={
          navigation.state === "loading" ? "skeleton-tab-selected" : undefined
        }
        selectedIndex={tabIndex}
        onSelect={onTabSelect}
      >
        <div
          ref={divRef}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            paddingTop: HEADER_VERTICAL_SPACING,
          }}
        >
          <PrimaryLeftHeader>Home</PrimaryLeftHeader>
          <div style={{ height: SECTION_VERTICAL_SPACING }} />
          <TabList>
            <Tab>
              <NormalText width={100}>Discover Events</NormalText>
            </Tab>
            <Tab>
              <NormalText width={100}>My Events</NormalText>
            </Tab>
            <Tab>
              <NormalText width={100}>My Groups</NormalText>
            </Tab>
          </TabList>
        </div>
        <TabPanel>
          <DiscoverEventsTab
            isSelected={tabIndex === 0}
            tabHeight={height}
            filterInfo={discoverEventsFilterInfo}
            discoverEventsQueryResult={discoverEventsQueryResult}
          />
        </TabPanel>
        <TabPanel>
          <MyEventsTab
            isSelected={tabIndex === 1}
            tabHeight={height}
            filterInfo={myEventsFilterInfo}
            useUsersRSVPsResult={useUsersRSVPsResult}
          />
        </TabPanel>
        <TabPanel>
          <MyGroupsTab
            isSelected={tabIndex === 2}
            tabHeight={height}
            useUsersGroupsResult={useUsersGroupsResult}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};
