import axios from "axios";
import { BACKEND_URL } from "../env";
import { ProfileCreate, ProfileFull } from "../types";
import { Auth } from "aws-amplify";
import { fetchUsernameByEmail, patchProfile, saveNewProfile } from "../hooks/queryProfiles";
import { defaultDisplayAddress, defaultLatitude, defaultLongitude, defaultTimeZone } from "../constants/defaultLocation";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { userContextType } from "../UserContext";
import { ThreadLastReadContextType, getThreadLastReadData } from "../screens/Inbox/Components/ThreadLastReadContext";
import { getAddressFromLatLng } from "./geocodeUtil";
import { guessTimeZone } from "./dateUtil";
import { logSentryError } from "./sentryUtil";

export const getUserDataByUsername = async (username: string) => {
  try {
    /* Checking for this username in our database. */
    const { data } = await axios.get(
      BACKEND_URL + "users/username/" + username
    );
    return data;
  } catch (e: any) {
    if (e.response.status === 404) {
    // we didn't find it, but we'll try other means, don't throw an exception
      return undefined;
    } else {
      // anything else we rethrow so we can catch it later
      throw e;
    }
  }
};

export const getUserDataByEmail = async (
    email: string
) => {
  try {
    /*
      * We didn't find this username, but maybe they logged in via another 
      * social login or with user/pass earlier.  So search with the provided email
      * for their existing username and data
      */
    const { data } = await axios.get(BACKEND_URL + "users/email/" + email);
    // return our userdata
    return data;
  } 
  catch (e: any) {
    if (e.response?.status === 404) {
      // we didn't find it, but we'll try other means, don't throw an exception
      return undefined;
    } else {
      // rethrow whatever this is so we can catch it later
      throw e;
    }
  }
};

const getLongAndLat = () => {
    return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
    );
}

const addProfileLocation = async (userId: string, userInfo: ProfileFull) => {
  const newUserInfo: ProfileFull = {
    id: userInfo.id,
    username: userInfo.username,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    skillLevel: userInfo.skillLevel,
    imageUri: userInfo.imageUri,
    adminLevel: userInfo.adminLevel,
    address: defaultDisplayAddress,
    displayAddress: defaultDisplayAddress,
    latitude: defaultLatitude,
    longitude: defaultLongitude
  }
  try {
    if ("geolocation" in navigator) {
      const result = await navigator.permissions
        .query({ name: "geolocation" });

      if (result.state === "granted") {
        const position = await getLongAndLat() as GeolocationPosition;
        newUserInfo.latitude = position.coords.latitude;
        newUserInfo.longitude = position.coords.longitude;
        const address = await getAddressFromLatLng(newUserInfo.latitude, newUserInfo.longitude);
        newUserInfo.displayAddress = address ? address : "Unknown";
        newUserInfo.address = newUserInfo.displayAddress;
        await patchProfile(userId, newUserInfo);
      }
      else if (result.state === "prompt") {
        console.log(result.state);
      } else if (result.state === "denied") {
        alert("Warning: Unable to detect current location.  Please set your default location in your profile.")
      }
    } else {
      // console.log("no permission");
    }

  }
  catch (e: any) {
    alert("Warning: Unable to detect current location.  Please set your default location in your profile.");
    // logSentryError("Error getting user's current location", e, {
    //   tags: {
    //     userId: "",
    //     screen: "Login",
    //     function: "addProfileLocation",
    //   },
    //   extra: {},
    //   level: "error",
    // });
  }
  return newUserInfo;
}

export const getUserDataByEmailAndUpdate = async (
    email: string,
    username: string
) => {
  try {
    /*
      * We didn't find this username.  Maybe it's a newly created account,
      * since for Google and others, the username wasn't provided when we
      * create the profile in SocialSignInSetupScreen
      * So we try to find it by email
      */
    const { data } = await axios.get(BACKEND_URL + "users/email/" + email);

    /*
      * We found it, so now we have to update the username in our db and move on
      */
    await axios.patch(BACKEND_URL + "users/" + data.id, {
      username: username,
      email: null,
      firstName: null,
      lastName: null,
      imageUri: null,
      skillLevel: null,
    });
    // return our userdata
    return data;
  } catch (e: any) {
    if (e.response.status === 404) {
      // we didn't find it, but we'll try other means, don't throw an exception
      return undefined;
    } else {
      // rethrow whatever this is so we can catch it later
      throw e;
    }
  }
};


export const completeProfile = async (username: string, email: string, firstName: string, lastName: string) => {
  const userInfo: ProfileCreate = {
    username: username,
    email: email,
    firstName: firstName,
    lastName: lastName,
    skillLevel: "N/A",
    address: "",
    displayAddress: "",
    latitude: defaultLatitude,
    longitude: defaultLongitude
  };
  try {
    const data = await saveNewProfile(userInfo);
    const data2 = await addProfileLocation(data.id, data)
    return data2;
  } catch (e) {
    // rethrow whatever this is so we can catch it later
    throw e;
  }
};

export const getUserData = async (username: string, email: string, firstName: string, lastName: string) => {
  let data = await getUserDataByEmail(email);
  // If we didn't find it, but we do have names stored in
  // Amplify's attributes then it must be a new user/password account.
  // So complete the profile with data from Amplify and use it.
  // this must be done only when the user has been authenticated by Amplify 
  // so that we have a JWT token
  if (!data && email && firstName && lastName) {
      // we have everything we need to create the new profile, so do it and populate our userData
      data = await completeProfile(username, email, firstName, lastName);
  }
  // return whatever we found
  return data;
}

export const accountAlreadyExists = async (email: string) => {
  try {
    // look for an account in our db with this email address
    const data = await fetchUsernameByEmail(email);
  
    if (!data) {
      // if it's not there, great! Nothing to do
      return undefined;
    } else {
      // we found a conflict.  notify the user appropriately so they can take appropriate action
      if (data.username && data.username.startsWith("google_")) {
        return "This email address is already associated with a Google account, please use Google Sign In instead or use a different email address."
      } else if (data.username && data.username.startsWith("facebook_")) {
        return "This email address is already associated with a Facebook account, please use Facebook Sign In or use a different email address."
      } else if (data.username && data.username.startsWith("signinwithapple_")) {
        return "This email address is already associated with an Apple account, please use Apple Sign In or use a different email address."
      } else {
        return "This email address is already associated with an account.  Please use the recover password option or use a different email address."
      }
    }
  } catch (e: any) {
    // nothing should be thrown here, but if it is then re-throw it to catch later
    if (e.code === "ERR_NETWORK") {
      return "ERR_NETWORK";
    }
    throw e;
  }
}

export const isSocialLogin = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  if (currentUser && currentUser.username && (currentUser.username.startsWith("google") ||
      currentUser.username.startsWith("facebook") ||
      currentUser.username.startsWith("signinwithapple"))  ) {
    return true;  
    }
  else {
    return false;
  }
}

export const handleSocialSignIn = async (provider: string, screen: string) => {
  let cognitoProvider;
  switch (provider) {
    case "facebook":
      cognitoProvider = CognitoHostedUIIdentityProvider.Facebook;
      break;
    case "apple":
      cognitoProvider = CognitoHostedUIIdentityProvider.Apple;
      break;
    case "google":
    default: 
      cognitoProvider = CognitoHostedUIIdentityProvider.Google;
      break;
  }
  try {
    await Auth.federatedSignIn({
      provider: cognitoProvider,
    });
    } catch (e: any) {
      // console.log("Error" + e.code);
      logSentryError("Error doing " + provider + " federated signin", e, {
        tags: {
          userId: "",
          screen: screen,
          function: "handleSocialSignIn",
        },
        level: "error",
      });
    }
  };

export const deleteCurrentUser = async () => {
  // get currently authenticated user
  const currentUser = await Auth.currentAuthenticatedUser();
  if (currentUser) {
    // we don't need to (and maybe can't) delete a social login record from amplify, so just sign out
    if (currentUser.username.startsWith("google_") ||
        currentUser.username.startsWith("facebook_") ||
        currentUser.username.startsWith("signinwithapple_")) {
        //For social SocialSignInSetupScreen, just sign out
        await Auth.signOut();
      }
    // it's a user/pass account, so delete it from Amplify
    else {
      await Auth.deleteUser();
    }
  }
}

export const setupAuthorizedUserContexts = async (
  userData: ProfileFull, 
  currentUserContext: userContextType,
  currentThreadLastReadContext: ThreadLastReadContextType
) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    if (currentUserContext && currentUserContext.switchUser) {
      currentUserContext.switchUser(
        userData.id,
        userData.username,
        userData.firstName,
        userData.lastName,
        userData.imageUri,
        userData.adminLevel,
        userData.address,
        userData.displayAddress,
        userData.latitude ? userData.latitude : defaultLatitude,
        userData.longitude ? userData.longitude: defaultLongitude,
        userData && userData.timeZone ? userData.timeZone : guessTimeZone(),
        currentUser,
        currentUserContext.hasUnread
      );
    }
      else {
      throw new Error("Unable to switch user in currentUserContext!")
    }
  }
  catch (e: any) {
    logSentryError(
      "Error setting up user context.",
      e,
      {
        tags: {
          userId: userData.id,
          screen: "unknown",
          function: "setupAuthorizedUserContexts",
        },
        level: "error",
      }
    );
    if (currentUserContext && currentUserContext.switchUser) {
      currentUserContext.switchUser(
        "",
        "",
        "",
        "",
        "",
        0,
        "",
        "",
        defaultLatitude,
        defaultLongitude,
        defaultTimeZone,
        null,
        false
      );
    }
  }

    // set up our threadLastReadContext
  getThreadLastReadData(userData.id).then((threadLastReadData) => {
    if (
      threadLastReadData &&
      currentThreadLastReadContext &&
      currentThreadLastReadContext.setLastReadData
    ) {
      currentThreadLastReadContext.setLastReadData(threadLastReadData);
    }
  });

}
