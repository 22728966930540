import IonIcon from "@reacticons/ionicons";
import { COLORS, DARK_PRIMARY_TEXT_COLOR } from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { Icon } from "../../styles/icons";
import "./OverlayButton.css";
import { getBorderRadius } from "../../utils";

type OverlayButtonProps = {
  iconName: React.ComponentProps<typeof IonIcon>["name"];
  onClick: () => void;
  color?: string;
};

export const OverlayButton = (props: OverlayButtonProps) => {
  const { iconName, onClick, color } = props;
  const colors = COLORS["light"];
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <button
      className="nav-button"
      onClick={handleClick}
      style={{
        padding: 0,
        borderRadius: getBorderRadius("tiny", "circle"),
      }}
    >
      <HorizontalLeftAlignedContainer
        style={{
          padding: 2,
          backgroundColor: colors.LIGHT_GRAY,
          borderRadius: getBorderRadius("tiny", "circle"),
        }}
      >
        <Icon
          name={iconName}
          color={color ? color : DARK_PRIMARY_TEXT_COLOR}
          size={18}
        />
      </HorizontalLeftAlignedContainer>
    </button>
  );
};
