import React, { useEffect, useState } from "react";
import { EventFull, PeopleData } from "../../../types";
import { HorizontalContainer } from "../../../styles/container";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";
import { TitledMemberPicker } from "../../../features/TitledMemberPicker/TitledMemberPicker";
import { TitledSwitch } from "../../../components/inputs/TitledSwitch";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { UseQueryResult } from "react-query";
import { TitledInput } from "../../../components/inputs/TitledInput";
import { NumericalDropDownItem } from "../../../types/dropdownTypes";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../../components/inputs/TitledDropdown";

type EditEventAdvancedProps = {
  eventDetails: EventFull;
  membersQueryResult: UseQueryResult<PeopleData[]>;
};

export const EditEventAdvanced = (props: EditEventAdvancedProps) => {
  const { eventDetails, membersQueryResult } = props;
  const [eventSmartWaitlist, setEventSmartWaitlist] = useState(
    eventDetails.smartWaitlist ? eventDetails.smartWaitlist : false
  );
  const [eventEarlyRSVPs, setEventEarlyRSVPs] = useState<PeopleData[]>(
    eventDetails.earlyRSVPs ? eventDetails.earlyRSVPs : []
  );
  const [eventEarlyBirdWindow, setEventEarlyBirdWindow] =
    useState<DropdownValueType>(eventDetails.earlyBirdWindow);
  const [earlyBirdWindowItems] = useState<NumericalDropDownItem[]>([
    { label: "Immediately", value: 0 },
    { label: "1 day", value: 1 },
    { label: "2 days", value: 2 },
    { label: "3 days", value: 3 },
    { label: "4 days", value: 4 },
    { label: "5 days", value: 5 },
    { label: "6 days", value: 6 },
    { label: "1 week", value: 7 },
    { label: "1 month", value: 30 },
  ]);

  const removeEarlyRSVP = (userId: string) => {
    const updatedEarlyRSVPs = eventEarlyRSVPs.filter(
      (member) => member.userId !== userId
    );
    setEventEarlyRSVPs(updatedEarlyRSVPs);
  };
  useEffect(() => {
    setEventSmartWaitlist(eventDetails.smartWaitlist);
    setEventEarlyRSVPs(eventDetails.earlyRSVPs);
    setEventEarlyBirdWindow(eventDetails.earlyBirdWindow);
  }, [eventDetails]);
  return (
    <>
      <HorizontalContainer>
        <TitledMemberPicker
          membersQueryResult={membersQueryResult}
          title="EARLY BIRDS"
          formDataName="eventearlybirds"
          members={eventEarlyRSVPs}
          setMembers={setEventEarlyRSVPs}
          maximum={300}
          ineligible={[]}
        />
      </HorizontalContainer>
      <ItemVerticalSpace />
      <HorizontalContainer>
        <TitledDropdown
          title="EARLY BIRD WINDOW"
          formDataName="eventearlybirdwindow"
          value={eventEarlyBirdWindow}
          setValue={setEventEarlyBirdWindow}
          items={earlyBirdWindowItems}
        />
      </HorizontalContainer>
      <ItemVerticalSpace />
      <HorizontalContainer>
        <TitledSwitch
          title="OPTIMIZE FOR DOUBLES PLAY"
          formDataName="eventsmartwaitlist"
          description="Turn this setting on to automatically manage attendees to prevent
            group sizes of 7 or 11. The 7th or 11th RSVP will remain on the
            waitlist until an additional player RSVPs."
          value={eventSmartWaitlist}
          setValue={setEventSmartWaitlist}
        />
      </HorizontalContainer>
    </>
  );
};
