import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { SECTION_VERTICAL_SPACING } from "../../styles/spacing";
import { H1 } from "../../styles/text";
import { useNavigation } from "react-router-dom";

type FancyTitleProps = {
  width?: number;
};

export const FancyTitle = (props: PropsWithChildren<FancyTitleProps>) => {
  const { width, children } = props;
  const navigation = useNavigation();
  const skeletonWidth = width
    ? width
    : children
    ? children.toString().length * 20
    : 250;
  return (
    <>
      <HorizontalLeftAlignedContainer>
        {navigation.state === "loading" ? (
          <Skeleton height={70} width={skeletonWidth} />
        ) : (
          <p
            style={{
              display: "block",
              fontFamily: "Berkshire Swash",
              fontSize: 36,
            }}
          >
            {children}
          </p>
        )}
      </HorizontalLeftAlignedContainer>
    </>
  );
};
