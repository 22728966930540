import React, { useRef, useState } from "react";
import { EventFull, GroupFull, PeopleData, VenueFull } from "../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { Form, useNavigate } from "react-router-dom";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { EditEventAdvanced } from "./sections/EditEventAdvanced";
import { EditEventGeneralInfo } from "./sections/EditEventGeneralInfo";
import { EditEventLogistics } from "./sections/EditEventLogistics";
import { EventErrors } from "./EditEventScreen";
import { H1, TextRegular } from "../../styles/text";
import { EditActionBar } from "../../features/actionBars/EditActionBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { UseQueryResult } from "react-query";

type EditEventLayoutProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
  membersQueryResult: UseQueryResult<PeopleData[]>;
  title: string;
  errors?: EventErrors;
};

export const EditEventLayout = (props: EditEventLayoutProps) => {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { eventDetails, groupDetails, membersQueryResult, title, errors } =
    props;
  const [selectedVenue, setSelectedVenue] = useState<VenueFull>({
    id: eventDetails.venueId,
    nickname: eventDetails.venueNickname,
    address: eventDetails.venueAddress,
    displayAddress: eventDetails.venueDisplayAddress,
    imageUri: eventDetails.venueImageUri,
    latitude: eventDetails.latitude,
    longitude: eventDetails.longitude,
  } as VenueFull);

  const originalEventData = eventDetails
    ? JSON.stringify(eventDetails)
    : undefined;

  return (
    <>
      <Form
        action={
          eventDetails.id
            ? "/events/edit/" + eventDetails.groupId + "/" + eventDetails.id
            : "/events/create/" + eventDetails.groupId
        }
        method="POST"
        encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
      >
        <StickyActionBar>
          <EditActionBar
            onCancel={() => {
              navigate(-1);
            }}
            onSave={() => {
              if (buttonRef.current) buttonRef.current.click();
            }}
          />
        </StickyActionBar>
        {errors?.generic && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.generic}</TextRegular>
          </HorizontalContainer>
        )}
        <HorizontalLeftAlignedContainer
          style={{
            paddingLeft: SIDE_PADDING,
          }}
        >
          <PrimaryLeftHeader>{title}</PrimaryLeftHeader>
        </HorizontalLeftAlignedContainer>
        <SectionVerticalSpace />
        <input
          type="hidden"
          name="groupId"
          value={eventDetails.groupId ? eventDetails.groupId : ""}
        />
        <input type="hidden" name="imageuri" value={eventDetails.imageUri} />
        <input type="hidden" name="originalvalues" value={originalEventData} />
        <input type="hidden" name="eventemoji" value="😀" />
        <div
          style={{
            paddingRight: SIDE_PADDING,
            paddingLeft: SIDE_PADDING,
          }}
        >
          <ItemVerticalSpace />
          <EditEventGeneralInfo
            eventDetails={eventDetails}
            selectedVenue={selectedVenue}
            errors={errors}
          />

          <ItemVerticalSpace />

          <EditEventLogistics
            eventDetails={eventDetails}
            groupDetails={groupDetails}
            membersQueryResult={membersQueryResult}
            selectedVenue={selectedVenue}
            setSelectedVenue={setSelectedVenue}
            errors={errors}
          />

          <ItemVerticalSpace />

          <EditEventAdvanced
            eventDetails={eventDetails}
            membersQueryResult={membersQueryResult}
          />
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <br />
            <button
              ref={buttonRef}
              type="submit"
              name="Save"
              style={{ display: "none" }}
            >
              Save
            </button>
          </HorizontalContainer>
        </div>
      </Form>
      <SectionVerticalSpace />
    </>
  );
};
