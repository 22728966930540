import React from "react";
import { EventFull } from "../../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { H1, TextRegular } from "../../../styles/text";
import { blankEventImageURL } from "../../../constants/defaultImageURLs";
import { COLORS } from "../../../styles/colors";
import {
  formatDateTime,
  formatDateTimeInterval,
  isInThePast,
} from "../../../utils";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { EventDetailsRSVPSection } from "./EventDetailsRSVPSection";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { NormalImage } from "../../../components/NormalImage";
import { PrimaryHeader } from "../../../components/text/PrimaryHeader";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { NormalText } from "../../../components/text/NormalText";
import { PrimaryLeftHeader } from "../../../components/text/PrimaryLeftHeader";
import { ItemHorizontalSpace } from "../../../components/spacing/ItemHorizontalSpace";
import { LineHorizontalSpace } from "../../../components/spacing/LineHorizontalSpace";

type EventDetailsTitleImageProps = {
  eventDetails: EventFull;
};
export const EventDetailsTitleImage = (props: EventDetailsTitleImageProps) => {
  const { eventDetails } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const eventIsInFuture = !isInThePast(new Date(eventDetails.date));
  const eventImageUri =
    eventDetails && eventDetails.imageUri
      ? eventDetails.imageUri
      : eventDetails.venueImageUri
      ? eventDetails.venueImageUri
      : blankEventImageURL;

  return (
    <>
      <HorizontalContainer
        style={{
          flex: 1,
        }}
      >
        <NormalImage
          imageUri={eventImageUri}
          alt={"This event's pic"}
          height={200}
          width="100%"
          objectFit="cover"
        />
      </HorizontalContainer>
      <HorizontalSpaceBetweenContainer
        style={{
          marginTop: SECTION_VERTICAL_SPACING,
          marginRight: ITEM_HORIZONTAL_SPACING,
          marginLeft: ITEM_HORIZONTAL_SPACING,
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: colors.LIGHT_GRAY,
          paddingBottom: SECTION_VERTICAL_SPACING,
          alignItems: "flex-end",
        }}
      >
        <div>
          <PrimaryLeftHeader>{eventDetails.title}</PrimaryLeftHeader>
          <ItemVerticalSpace />

          <HorizontalLeftAlignedContainer>
            <NormalText fontWeight={500}>Event Date: </NormalText>
            <div style={{ width: 16 }} />
            <NormalText>
              {formatDateTimeInterval(
                new Date(eventDetails.date),
                eventDetails.duration,
                true
                // eventDetails.timeZone
              )}
            </NormalText>
          </HorizontalLeftAlignedContainer>

          <ItemVerticalSpace />
          <HorizontalLeftAlignedContainer>
            <NormalText fontWeight={500}>RSVP Opens: </NormalText>
            <div style={{ width: 6 }} />
            <NormalText>
              {formatDateTime(
                new Date(eventDetails.rsvpDate),
                true
                // eventDetails.timeZone
              )}
            </NormalText>
          </HorizontalLeftAlignedContainer>
        </div>
        {navigation.state !== "loading" && eventIsInFuture && (
          <HorizontalRightAlignedContainer
            style={{ marginLeft: SECTION_HORIZONTAL_SPACING }}
          >
            <EventDetailsRSVPSection eventDetails={eventDetails} />
          </HorizontalRightAlignedContainer>
        )}
      </HorizontalSpaceBetweenContainer>
    </>
  );
};
