import React, { useContext } from "react";
import {
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { TextRegular } from "../../../styles/text";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";
import { userContext } from "../../../UserContext";
import { GroupFull, MemberFull } from "../../../types";
import {
  addQueryItem,
  formatDate,
  isOlderThanAWeek,
  memberIsOrganizer,
  userIsOrganizer,
} from "../../../utils";
import { PersonListItem } from "../../../components/people/PersonListItem";
import { goToProfile, goToReport } from "../../../utils/navUtil";
import { useFetcher, useNavigate } from "react-router-dom";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";

type MemberListItemProps = {
  groupDetails: GroupFull;
  currentMemberData: MemberFull | undefined;
  itemMemberData: MemberFull;
  togglePeopleDrawer: () => void;
};
export const MemberListItem = (props: MemberListItemProps) => {
  const { groupDetails, itemMemberData, togglePeopleDrawer } = props;
  const userInfo = useContext(userContext);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const joinedDate = new Date(itemMemberData.joinedDate);
  const isNew = !isOlderThanAWeek(joinedDate) ? " (New)" : "";
  const joinedText = "Joined on " + formatDate(joinedDate, true) + isNew;
  const byOwner = userInfo.id === groupDetails.ownerId;
  const byAdmin = userInfo.adminLevel > 90;
  const byOrganizer = userIsOrganizer(userInfo.id, groupDetails);
  const canRemove = byOwner || byAdmin || byOrganizer;

  const doReport = () => {
    const returnUri = `/group/view/${groupDetails.id}/`;

    const encodedFullName = encodeURIComponent(
      `${itemMemberData.firstName} ${itemMemberData.lastName}`
    );
    let reportQueryString = "";
    reportQueryString = addQueryItem(
      reportQueryString,
      "targetType",
      "membership"
    );
    reportQueryString = addQueryItem(
      reportQueryString,
      "targetId",
      itemMemberData.userId
    );
    reportQueryString = addQueryItem(
      reportQueryString,
      "targetName",
      `${encodedFullName}`
    );
    reportQueryString = addQueryItem(
      reportQueryString,
      "parentName",
      groupDetails.name
    );
    reportQueryString = addQueryItem(reportQueryString, "returnUri", returnUri);
    goToReport(navigate, reportQueryString);
  };

  const doMessage = () => {
    const queryString = `?userId=${itemMemberData.userId}&firstName=${itemMemberData.firstName}&lastName=${itemMemberData.lastName}`;
    navigate("/composemessage/dm/" + queryString);
  };

  const doRemoveMember = () => {
    togglePeopleDrawer();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={`Are you sure you want to remove ${itemMemberData.firstName} ${itemMemberData.lastName} from this group?`}
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "remove", auxId: itemMemberData.id },
                { method: "POST", action: "memberContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const doLeaveGroup = () => {
    togglePeopleDrawer();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={`Are you sure you want to leave this group?`}
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "leave", auxId: itemMemberData.id },
                { method: "POST", action: "memberContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const isOrganizer = memberIsOrganizer(itemMemberData);
  const isOwner = itemMemberData.userId === groupDetails.ownerId;

  const role = isOwner ? "Leader" : isOrganizer ? "Organizer" : "";
  const canBeRemoved = !isOwner && !isOrganizer;
  const canLeave = !isOwner && itemMemberData.userId === userInfo.id;
  const canMessage = userInfo.id !== itemMemberData.userId;

  const memberActionButtons: ContextMenuItem[] = [];
  if (canRemove && canBeRemoved) {
    memberActionButtons.push({
      label: <TextRegular>Remove This Member</TextRegular>,
      value: "remove",
      action: doRemoveMember,
    });
  }
  if (canLeave) {
    memberActionButtons.push({
      label: <TextRegular>Leave Group</TextRegular>,
      value: "leave",
      action: doLeaveGroup,
    });
  }

  if (canMessage) {
    memberActionButtons.push({
      label: <TextRegular>Message This Member</TextRegular>,
      value: "message",
      action: doMessage,
    });
  }
  memberActionButtons.push({
    label: <TextRegular>Report This Member</TextRegular>,
    value: "report",
    action: doReport,
  });

  return (
    <HorizontalSpaceBetweenContainer
      style={{
        paddingTop: ITEM_VERTICAL_SPACING,
      }}
    >
      <PersonListItem
        firstName={itemMemberData.firstName}
        lastName={itemMemberData.lastName}
        imageUri={itemMemberData.imageUri}
        thumbnailSize={32}
        role={role}
        subtext={joinedText}
        onClick={() => goToProfile(navigate, itemMemberData.userId)}
      />

      <HorizontalRightAlignedContainer>
        <NewCustomContextMenu contextMenuItems={memberActionButtons} />
      </HorizontalRightAlignedContainer>
    </HorizontalSpaceBetweenContainer>
  );
};
