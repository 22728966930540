import React from "react";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import { Form, useNavigate, useNavigation } from "react-router-dom";
import { useEffect, useState } from "react";
import { COLORS } from "../../styles/colors";
import { handleSocialSignIn } from "../../utils/authenticationUtil";
import { CreateAccountErrors } from "./CreateAccountScreen";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { SocialLoginButton } from "../../components/buttons/SocialLoginButton";
import appleLogo from "../../assets/images/apple-icon.png";
import googleLogo from "../../assets/images/google-icon.png";
import { TitledInput } from "../../components/inputs/TitledInput";
import { StickyActionBar } from "../../components/StickyActionBar";
import { goToLoggingIn } from "../../utils/navUtil";

type CreateAccountScreenLayoutProps = {
  errors?: CreateAccountErrors;
};

export const CreateAccountScreenLayout = (
  props: CreateAccountScreenLayoutProps
) => {
  const { errors } = props;

  const colors = COLORS["light"];
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();

  const isBusy = () => {
    return navigation.state !== "idle" || loading;
  };
  const doSocialSignin = async (provider: string) => {
    if (loading) {
      return null;
    }
    setLoading(true);
    handleSocialSignIn(provider, "CreateAccountScreen");
    goToLoggingIn(navigate, provider);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
  }, [errors]);

  return (
    <ScreenContainer horizontalPadding={0}>
      <div
        style={{
          flex: 1,
        }}
      >
        <StickyActionBar>
          <DetailsActionButtonBar backButtonTitle="Back" />
        </StickyActionBar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form
          action="/createAccount"
          method="POST"
          style={{
            width: "100%",
          }}
        >
          <HorizontalLeftAlignedContainer
            style={{ marginBottom: SECTION_VERTICAL_SPACING }}
          >
            <H1>Create Account</H1>
          </HorizontalLeftAlignedContainer>

          {errors?.network && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextRegular color="red">{errors.network}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalContainer
            style={{
              marginTop: ITEM_VERTICAL_SPACING,
            }}
          >
            <TitledInput
              title="Email address"
              formDataName={"emailaddress"}
              value={emailAddress}
              setText={setEmailAddress}
              required={true}
              editable={true}
              placeholderText="Enter email address"
              maxLength={64}
              autoComplete="email"
            />
          </HorizontalContainer>
          {errors?.email && (
            <HorizontalContainer
              style={{ marginBottom: ITEM_VERTICAL_SPACING }}
            >
              <TextRegular color="red">{errors.email}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="First name"
              formDataName={"firstname"}
              value={firstName}
              setText={setFirstName}
              required={true}
              editable={true}
              placeholderText="First Name"
              maxLength={16}
              autoComplete="given-name"
            />
          </HorizontalContainer>
          {errors?.firstName && (
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.firstName}</TextSmall>
            </HorizontalLeftAlignedContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Last name"
              formDataName={"lastname"}
              value={lastName}
              setText={setLastName}
              required={true}
              editable={true}
              placeholderText="Last Name"
              maxLength={16}
              autoComplete="family-name"
            />
          </HorizontalContainer>
          {errors?.lastName && (
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.lastName}</TextSmall>
            </HorizontalLeftAlignedContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Password"
              formDataName={"password"}
              value={password}
              setText={setPassword}
              required={true}
              editable={true}
              placeholderText="Enter a password"
              maxLength={16}
              password={true}
              autoComplete="new-password"
            />
          </HorizontalContainer>
          {errors?.password && (
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.password}</TextSmall>
            </HorizontalLeftAlignedContainer>
          )}

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Retype Password"
              formDataName={"password2"}
              value={password2}
              setText={setPassword2}
              required={true}
              editable={true}
              placeholderText="Retype password"
              maxLength={16}
              password={true}
            />
          </HorizontalContainer>
          {errors?.password2 && (
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.password2}</TextSmall>
            </HorizontalLeftAlignedContainer>
          )}

          {errors?.other && (
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.other}</TextSmall>
            </HorizontalLeftAlignedContainer>
          )}
          <HorizontalContainer
            style={{
              marginTop: SECTION_VERTICAL_SPACING,
              marginBottom: SECTION_VERTICAL_SPACING,
            }}
          >
            <SubmitButton loading={isBusy()} title="Register" />
          </HorizontalContainer>
        </Form>
        <HorizontalContainer>
          <TextRegular color={colors.SECONDARY_TEXT}> – OR –</TextRegular>
        </HorizontalContainer>
        <HorizontalSpaceBetweenContainer
          style={{
            marginTop: SECTION_VERTICAL_SPACING,
            width: "100%",
          }}
        >
          <SocialLoginButton
            onClick={() => doSocialSignin("google")}
            title="Continue with Google"
            disabled={false}
            logoUri={googleLogo}
          />
          <div style={{ width: LINE_HORIZONTAL_SPACING }} />
          <SocialLoginButton
            onClick={() => doSocialSignin("apple")}
            title="Continue with Apple"
            disabled={false}
            logoUri={appleLogo}
          />
        </HorizontalSpaceBetweenContainer>
        {isBusy() && (
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <BeatLoader color={colors.PRIMARY_BRAND} loading={true} size={14} />
          </HorizontalContainer>
        )}
      </div>
    </ScreenContainer>
  );
};
