import React from "react";
import { useNavigate } from "react-router-dom";
import { TextRegular } from "../../../styles/text";
import { ThreadFull } from "../../../types";
import { ThreadSummaryCard } from "../Components/ThreadSummaryCard";
import { goToMessageHistory } from "../../../utils/navUtil";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyMessages } from "../../../assets/images/empty-state/empty-messages.svg";

type GroupThreadsSectionProps = {
  groupThreadsData: ThreadFull[] | undefined;
  groupThreadsDataIsSuccess: boolean;
};

export const GroupThreadsSection = (props: GroupThreadsSectionProps) => {
  const { groupThreadsDataIsSuccess, groupThreadsData } = props;
  const navigate = useNavigate();
  const handlePress = (id: string, title: string) => {
    goToMessageHistory(navigate, "group", id, title);
  };

  return (
    <>
      {groupThreadsDataIsSuccess &&
        (!groupThreadsData || groupThreadsData.length <= 0) && (
          <EmptyLayout title="No threads yet" subTitle="">
            <EmptyMessages />
          </EmptyLayout>
        )}
      {groupThreadsDataIsSuccess &&
        groupThreadsData &&
        groupThreadsData.length > 0 && (
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              overflowY: "auto",
              overflowX: "hidden",
              paddingTop: ITEM_VERTICAL_SPACING,
            }}
          >
            {groupThreadsData.map((threadDetails, index) => (
              <ThreadSummaryCard
                key={index}
                threadDetails={threadDetails}
                onPress={() =>
                  handlePress(threadDetails.destinationId, threadDetails.title)
                }
                cardShape={"rounded rect"}
                messageType={"group"}
              />
            ))}
          </div>
        )}
    </>
  );
};
