import React, { useContext, useEffect, useState } from "react";
import { useParams, redirect, useActionData } from "react-router-dom";
import { userContext } from "../../UserContext";
import {
  eventDetailsQuery,
  patchEvent,
  saveNewEvent,
  setHostsAndEarlyRSVPs,
  uploadEventImageFile,
} from "../../hooks/queryEvents";
import { EditEventLayout } from "./EditEventLayout";
import {
  EventCreate,
  EventFull,
  EventTypeValue,
  EventUpdate,
  EventUpdateAble,
  PeopleData,
  defaultEventDetails,
} from "../../types";
import {
  fdGetString,
  fdGetNumber,
  fdGetByEnum,
  fdGetImage,
  fdGetBoolean,
  ifDifferentBoolean,
  ifDifferentString,
  ifDifferentNumber,
  ifDifferentEnum,
  fdGetInteger,
} from "../../utils/formUtils";
import { QueryClient, useQuery } from "react-query";
import { groupDetailsQuery } from "../../hooks/queryGroups";
import { ALL_EMOJIS } from "../../styles/text";
import { EventStatus } from "../../types/enums";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  groupMembersAllQuery,
  useGroupMembersAll,
} from "../../hooks/queryMemberships";
import { logSentryError } from "../../utils/sentryUtil";

export type EditEventParams = {
  editTask?: string;
  groupId?: string;
  eventId?: string;
};

export type EventErrors = {
  generic?: string;
  title?: string;
  capacity?: string;
  venue?: string;
  ok?: boolean;
};

export const peopleDataArraysAreEqual = (a: PeopleData[], b: PeopleData[]) => {
  return (
    a.length === b.length &&
    a.every((pd, index) => pd.userId === b[index].userId)
  );
};

const generateUpdateData = (ge: EventUpdateAble, oe: EventFull) => {
  return {
    title: ifDifferentString(ge.title, oe.title),
    description: ifDifferentString(ge.description, oe.description),
    emoji: ifDifferentString(ge.emoji, oe.emoji),
    date: ifDifferentNumber(ge.date, oe.date),
    rsvpDate: ifDifferentNumber(ge.rsvpDate, oe.rsvpDate),
    duration: ifDifferentNumber(ge.duration, oe.duration),
    minSkillLevel: ifDifferentNumber(ge.minSkillLevel, oe.minSkillLevel),
    maxSkillLevel: ifDifferentNumber(ge.maxSkillLevel, oe.maxSkillLevel),
    eventType: ifDifferentEnum<EventTypeValue>(ge.eventType, oe.eventType),
    capacity: ifDifferentNumber(ge.capacity, oe.capacity),
    venueId: ifDifferentString(ge.venueId, oe.venueId),
    smartWaitlist: ifDifferentBoolean(ge.smartWaitlist, oe.smartWaitlist),
    earlyBirdWindow: ifDifferentNumber(ge.earlyBirdWindow, oe.earlyBirdWindow),
  } as EventUpdate;
};
const extractNewEventData = (data: FormData) => {
  return {
    title: fdGetString(data, "eventtitle"),
    description: fdGetString(data, "eventdescription"),
    emoji: fdGetString(data, "eventemoji"),
    date: fdGetInteger(data, "eventdate"),
    rsvpDate: fdGetInteger(data, "eventrsvpdate"),
    duration: fdGetNumber(data, "eventduration"),
    minSkillLevel: fdGetNumber(data, "eventskillmin"),
    maxSkillLevel: fdGetNumber(data, "eventskillmax"),
    eventType: fdGetByEnum<EventTypeValue>(data, "eventtypevalue"),
    capacity: fdGetInteger(data, "eventcapacity"),
    venueId: fdGetString(data, "eventvenueid"),
    smartWaitlist: fdGetBoolean(data, "eventsmartwaitlist"),
    imageUri: fdGetString(data, "imageuri"),
    earlyBirdWindow: fdGetNumber(data, "eventearlybirdwindow"),
  } as EventUpdateAble;
};

const validateEventInfo = (eventInfo: EventUpdate) => {
  const err: EventErrors = {};

  if (eventInfo?.title && eventInfo.title.length < 3) {
    err.title = "Event title must be at least 3 characters.";
  }
  if (eventInfo.capacity && eventInfo.capacity < 0) {
    err.capacity = "Event capacity must be a non-negative integer.";
  }
  if (!eventInfo.venueId) {
    err.venue = "You must specify a venue";
  }
  // check dates again?
  return err;
};

export const editEventLoader = async (
  params: EditEventParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  // throw new Error(
  //   "This is only a test.  If this were a real error, this would be a real message"
  // );
  if (currentUserId && params?.groupId) {
    if (params.editTask === "edit") {
      const query1 = eventDetailsQuery(
        !!currentUserId && !!params?.eventId,
        params?.eventId
      );

      queryClient.getQueryData(query1.queryKey) ??
        (await queryClient.fetchQuery(query1));
    }

    const query2 = groupDetailsQuery(currentUserId, params.groupId);

    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchQuery(query2));

    const query3 = groupMembersAllQuery(true, params?.groupId);

    queryClient.getQueryData(query3.queryKey) ??
      (await queryClient.fetchQuery(query3));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { ok: true };
};

export const saveEventAction =
  (queryClient: QueryClient, currentUserId: string) =>
  async ({
    params,
    request,
  }: {
    params: EditEventParams;
    request: Request;
  }) => {
    let eventId = params.eventId;
    const defaultErrorMessage = "There was an error updating this event.";

    try {
      const data = await request.formData();
      const originalEventData: EventFull = JSON.parse(
        fdGetString(data, "originalvalues")
      );

      const eventData = extractNewEventData(data);
      const groupId = fdGetString(data, "groupId");

      const hostsJSON = fdGetString(data, "eventhosts");
      const hosts = JSON.parse(hostsJSON);

      const earlyBirdsJSON = fdGetString(data, "eventearlybirds");
      const earlyBirds = JSON.parse(earlyBirdsJSON);

      // process image
      const newEventImage = await fdGetImage(data, "eventimage");

      const validateResult = validateEventInfo(eventData);
      if (Object.keys(validateResult).length) {
        return { ok: false, generic: defaultErrorMessage, ...validateResult };
      }

      if (!eventId) {
        const createEventData: EventCreate = {
          ...eventData,
          groupId: groupId,
        };
        const result = await saveNewEvent(createEventData);
        eventId = result.id;

        // update the hosts and early birds lists
        if (hosts && earlyBirds && eventId) {
          await setHostsAndEarlyRSVPs(eventId, hosts, earlyBirds);
        }
      } else {
        // collect only the changed event data
        const updateEventData = generateUpdateData(
          eventData,
          originalEventData
        );
        const hasUpdates = !Object.values(updateEventData).every(
          (el) => el === undefined
        );
        // only patch the event if there are updates to it
        if (hasUpdates) {
          await patchEvent(eventId, updateEventData);
        }
      }

      // update the group image, if one was selected
      if (newEventImage && eventId) {
        await uploadEventImageFile(eventId, newEventImage);
      }

      // update the hosts and early birds lists
      if (hosts && earlyBirds && eventId) {
        if (
          !peopleDataArraysAreEqual(hosts, originalEventData.hosts) ||
          !peopleDataArraysAreEqual(earlyBirds, originalEventData.earlyRSVPs)
        ) {
          await setHostsAndEarlyRSVPs(eventId, hosts, earlyBirds);
        }
      }

      // invalidate overall groups query, so our changes show up on groups tab
      queryClient.invalidateQueries(["events"]);

      // invalidate this group's query so our group details screen updates
      queryClient.invalidateQueries(["event", eventId]);

      // invalidate current user's memberships, so our changes show up in my groups
      queryClient.invalidateQueries(["rsvps"]);

      return redirect("/event/view/" + eventId);
    } catch (e: any) {
      if (e.code === "NotSupportedError") {
        return {
          ok: false,
          generic:
            "Your browser does not support this type of file upload.  Check your settings, upgrade your browser, or use the mobile app to upload this image.",
        };
      }
      logSentryError("Error Saving Group Details.", e, {
        tags: {
          userId: currentUserId,
          eventId: params?.eventId,
          groupId: params?.groupId,
          screen: "EditEventScreen",
          function: "saveEventAction",
        },
        level: "error",
      });
      return { generic: defaultErrorMessage, ok: false };
    }
  };

export const EditEventScreen = () => {
  const params = useParams();
  const userInfo = useContext(userContext);
  const eventId = params && params.eventId ? params.eventId : "";
  const groupId = params && params.groupId ? params.groupId : "";
  const editTask = params && params.editTask ? params.editTask : "create";
  const errors = useActionData() as EventErrors;

  const seedEventDetails = defaultEventDetails;
  seedEventDetails.date = new Date().getTime() + 24 * 60 * 60 * 1000;
  seedEventDetails.rsvpDate = new Date().getTime() + 1 * 60 * 60 * 1000;
  seedEventDetails.groupId = groupId;
  seedEventDetails.emoji =
    ALL_EMOJIS[Math.floor(Math.random() * ALL_EMOJIS.length)];
  const [eventDetails, setEventDetails] = useState<EventFull>(seedEventDetails);

  const { data: eventData, isSuccess: eventIsSuccess } = useQuery(
    eventDetailsQuery(!!userInfo.id && !!eventId, eventId)
  );

  const { data: groupDetails } = useQuery(
    groupDetailsQuery(userInfo.id, groupId)
  );
  const membersQueryResult = useGroupMembersAll(true, params.groupId ?? "");

  useEffect(() => {
    if (eventData) {
      switch (editTask) {
        case "duplicate":
          const duplicatedEventDetails: EventFull = eventData;
          const now = new Date().getTime();
          duplicatedEventDetails.id = "";
          duplicatedEventDetails.date =
            eventData.date + 7 * 24 * 60 * 60 * 1000;
          if (duplicatedEventDetails.date < now)
            duplicatedEventDetails.date = now + 24 * 60 * 60 * 1000;
          duplicatedEventDetails.rsvpDate =
            eventData.rsvpDate + 7 * 24 * 60 * 60 * 1000;
          if (duplicatedEventDetails.rsvpDate < now)
            duplicatedEventDetails.rsvpDate = now + 24 * 60 * 60 * 1000;
          duplicatedEventDetails.status = EventStatus.Active;
          duplicatedEventDetails.peoplePics = [];
          duplicatedEventDetails.attendeeList = [];
          duplicatedEventDetails.waitList = [];
          duplicatedEventDetails.notgoingList = [];
          duplicatedEventDetails.imageUri = eventData.imageUri;
          setEventDetails(duplicatedEventDetails);
          break;
        case "edit":
          setEventDetails(eventData);
          break;
      }
    }
  }, [eventData, editTask]);
  useEffect(() => {
    if (eventIsSuccess && eventData) {
      setEventDetails(eventData);
    }
  }, [eventIsSuccess, eventData]);

  useEffect(() => {
    if (eventDetails) {
      const title = eventDetails.title ?? "New";
      document.title = "Edit Event: " + title;
    }
  }, [eventDetails]);

  return (
    <>
      {groupDetails && (
        <ScreenContainer horizontalPadding={0}>
          <EditEventLayout
            eventDetails={eventDetails}
            groupDetails={groupDetails}
            membersQueryResult={membersQueryResult}
            title={params.eventId ? "Edit Event" : "Create Event"}
            errors={errors}
          />
        </ScreenContainer>
      )}
    </>
  );
};
