import { useQuery } from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import { ImageUploadData, ProfileCreate, ProfileUpdate } from "../types";

export const saveNewProfile = async (profileInfo: ProfileCreate) => {
  const { data } = await axios.post(BACKEND_URL + "users/", profileInfo);
  return data;
};

export const patchProfile = async (userId: string, userInfo: ProfileUpdate) => {
  await axios.patch(BACKEND_URL + "users/" + userId, userInfo);
  return;
};

export const patchProfileLocation = async (
  userId: string,
  latitude: number,
  longitude: number,
  address: string,
  displayAddress: string
) => {
  if (!userId || userId.length === 0) return;
  const locationData = {
    latitude: latitude,
    longitude: longitude,
    address: address,
    displayAddress: displayAddress,
  };
  await axios.patch(BACKEND_URL + "users/" + userId, locationData);
  return;
};

export const addPushToken = async (userId: string, pushToken: string) => {
  if (!userId || userId.length <= 0 || !pushToken || pushToken.length <= 0)
    return;
  const data = {
    pushToken: pushToken,
  };
  await axios.patch(BACKEND_URL + "users/register/" + userId, data);
  return;
};

export const removePushToken = async (userId: string, pushToken: string) => {
  if (!userId || userId.length <= 0 || !pushToken || pushToken.length <= 0)
    return;
  const data = {
    pushToken: pushToken,
  };
  await axios.patch(BACKEND_URL + "users/deregister/" + userId, data);
  return;
};

export const uploadProfileImage = async (
  userId: string,
  imageUploadData: ImageUploadData
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageUploadData as unknown as File);
  let result;
  result = await axios.post(
    BACKEND_URL + "users/" + userId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadProfileImageFile = async (
  userId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "users/" + userId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

const fetchProfile = async (userId: string | undefined) => {
  const { data } = await axios.get(BACKEND_URL + "users/" + userId);
  return data;
};

export const profileQuery = (
  currentUserId: string,
  userId: string | undefined
) => ({
  queryKey: ["users", userId],
  queryFn: async () => fetchProfile(userId),
  enabled: !!currentUserId && !!userId,
});

export const useProfile = (currentUserId: string, userId: string) =>
  useQuery(profileQuery(currentUserId, userId));

export const deleteProfile = async (userId: string) => {
  // remove all user information from this account
  const result = await axios.delete(BACKEND_URL + "users/" + userId);

  return result;
};

// need to export this fetch for non-react-query usage during login process
export const fetchUsernameByEmail = async (email: string) => {
  const { data } = await axios.get(BACKEND_URL + "users/email/" + email);
  return data;
};

export const profileByEmailQuery = (email: string) => ({
  queryKey: ["users", email],
  queryFn: async () => fetchUsernameByEmail(email),
});

export const useProfileByEmail = (email: string) =>
  useQuery(profileByEmailQuery(email));
