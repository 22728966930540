/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bb2a5d08-2f40-4584-9adb-f264c02aa37a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GkVCvD3kk",
    "aws_user_pools_web_client_id": "7rghkjtke688p4pgp4fnc00paj",
    "oauth": {
        "domain": "ix10giszufwg-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://192.168.1.176:19000/--/,exp://192.168.86.28:19000/--/,picklers-staging://,http://localhost:3000/,https://crystal.picklers.net/",
        "redirectSignOut": "exp://192.168.1.176:19000/--/,exp://192.168.86.28:19000/--/,picklers-staging://,http://localhost:3000/,https://crystal.picklers.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
