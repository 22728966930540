import react from "react";
import { DiscoverEventsListSection } from "../Sections/DiscoverEventsListSection";
import { EventFull } from "../../../types";
import { DiscoverEventsFilterSection } from "../Sections/DiscoverEventsFilterSection";
import { DiscoverEventsFilterInfo } from "../EventsScreen";
import { UseInfiniteQueryResult } from "react-query";

export type DiscoverEventsTabProps = {
  isSelected: boolean;
  tabHeight: number;
  filterInfo?: DiscoverEventsFilterInfo;
  discoverEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
};

export const DiscoverEventsTab = (props: DiscoverEventsTabProps) => {
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: props.tabHeight,
          zIndex: 1000,
          marginTop: -16,
        }}
      >
        <DiscoverEventsFilterSection filterInfo={props.filterInfo} />
      </div>

      <DiscoverEventsListSection
        discoverEventsQueryResult={props.discoverEventsQueryResult}
      />
    </>
  );
};
