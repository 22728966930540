import React, { useState, useEffect, useRef } from "react";
import { useGroupMembersAll } from "../../../hooks/queryMemberships";
import { MemberFull, PeopleData, ProfileFull } from "../../../types";
import { useWindowHeight } from "@react-hook/window-size";
import { HorizontalLeftAlignedContainer } from "../../../styles/container";
import { MemberSelectionItem } from "../../../components/people/MemberPickerListItem";
import { H2, TextRegular } from "../../../styles/text";
import {
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { useFetcher } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { useProfileByEmail } from "../../../hooks/queryProfiles";

type AttendeePickerListProps = {
  groupId: string;
  eventId: string;
  goingList: string[];
  notgoingList: string[];
  waitList: string[];
  closePicker: () => void;
};

export const AttendeePickerList = (props: AttendeePickerListProps) => {
  const { groupId, goingList, notgoingList, waitList, closePicker } = props;

  const { data, isSuccess, isLoading, isError } = useGroupMembersAll(
    true,
    groupId
  );

  const [suggestionItems, setSuggestionItems] = useState<MemberFull[]>([]);
  const [memberSearchText, setMemberSearchText] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(500);
  const fetcher = useFetcher();

  const windowHeight = useWindowHeight();

  const { data: guest1 } = useProfileByEmail("guest1@picklers.net");
  const { data: guest2 } = useProfileByEmail("guest2@picklers.net");

  const getGuestData = (guestData: ProfileFull) => {
    const newGuest: PeopleData = {
      userId: guestData.id,
      firstName: guestData.firstName,
      lastName: guestData.lastName,
      imageUri: guestData.imageUri,
    };
    return newGuest;
  };

  const addAttendee = (userId: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Add Attendee?"}
            content={`Change this attendee's status to going?`}
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  contextAction: "addAttendee",
                  auxId: userId,
                  status: "going",
                },
                {
                  method: "POST",
                  action: "attendeeContextAction",
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberSearchText(e.target.value);
    if (!data) return;
    if (e.target.value.length > 0) {
      const regex = new RegExp(e.target.value, "ig");
      const filteredList = data?.filter((suggestion: PeopleData) =>
        suggestion.firstName
          ? (suggestion.firstName + " " + suggestion.lastName).match(regex)
          : false
      );
      setSuggestionItems(filteredList ? filteredList : []);
    } else {
      setSuggestionItems(data);
    }
  };

  const handleMemberSelection = (
    userId: string,
    firstName: string,
    lastName: string,
    imageUri: string
  ) => {
    console.log("handlememberselection");
    if (
      goingList.includes(userId) ||
      notgoingList.includes(userId) ||
      waitList.includes(userId)
    ) {
      // shouldn't happen
      return;
    } else {
      console.log("add attendee: ", firstName, lastName, userId);
      addAttendee(userId);
    }

    closePicker();
  };

  useEffect(() => {
    if (data && guest1 && guest2) {
      const guest1Item = getGuestData(guest1);
      const guest2Item = getGuestData(guest2);
      setSuggestionItems([guest1Item, guest2Item, ...data]);
    }
  }, [data, guest1, guest2]);

  useEffect(() => {
    if (!divRef.current) return;
    setHeight(windowHeight - divRef.current.offsetHeight - 55);
  }, [windowHeight, divRef]);

  const renderSuggestionItem = (item: MemberFull, index: number) => {
    const pickedMemberTitle = goingList.includes(item.userId)
      ? "Already going"
      : waitList.includes(item.userId)
      ? "Already on the waitlist"
      : notgoingList.includes(item.userId)
      ? "Already not going"
      : undefined;

    return (
      <HorizontalLeftAlignedContainer
        key={index}
        style={{
          width: "100%",
          marginRight: 25,
          paddingBottom: LINE_VERTICAL_SPACING,
        }}
      >
        {isLoading && <TextRegular>Loading</TextRegular>}
        {isError && <TextRegular>Error</TextRegular>}
        <button
          style={{
            flex: 1,
            borderStyle: "none",
            backgroundColor: "white",
          }}
          onClick={() =>
            handleMemberSelection(
              item.userId,
              item.firstName,
              item.lastName,
              item.imageUri
            )
          }
        >
          <MemberSelectionItem
            firstName={item.firstName}
            lastName={item.lastName}
            imageUri={item.imageUri}
            isPicked={!!pickedMemberTitle}
            pickedLabel={pickedMemberTitle}
          />
        </button>
      </HorizontalLeftAlignedContainer>
    );
  };

  return (
    <div
      style={{
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
      }}
    >
      <div ref={divRef}>
        <H2>Select Member</H2>
        <HorizontalLeftAlignedContainer
          style={{
            marginTop: ITEM_VERTICAL_SPACING,
            marginBottom: ITEM_VERTICAL_SPACING,
            width: "100%",
          }}
        >
          <input
            type="text"
            value={memberSearchText}
            placeholder="Type any part of the name."
            onChange={onChange}
            maxLength={35}
            style={{ width: "100%", marginRight: 25 }}
          />
        </HorizontalLeftAlignedContainer>
      </div>
      <div id="scrollableDiv" style={{ height: height, overflow: "auto" }}>
        {isSuccess && data && (
          <div style={{ flexDirection: "row" }}>
            {suggestionItems.map((suggestion: MemberFull, index: number) => {
              return renderSuggestionItem(suggestion, index);
            })}
          </div>
        )}
      </div>
    </div>
  );
};
