import React from "react";
import Select, { SingleValue } from "react-select";
import { Icon } from "../../styles/icons";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { COLORS, LIGHT_GRAY, PALE_GRAY, WHITE } from "../../styles/colors";
import {
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export type ContextMenuItem = {
  label: JSX.Element;
  value: string;
  action: () => void;
};
type NewCustomContextMenuProps = {
  contextMenuItems: ContextMenuItem[];
};

export const NewCustomContextMenu = (props: NewCustomContextMenuProps) => {
  const { contextMenuItems } = props;
  const navigation = useNavigation();
  const colors = COLORS["light"];
  const handleSelection = (
    selectedOption: SingleValue<{ label: string | JSX.Element; value: string }>
  ) => {
    if (selectedOption) {
      const selectedMenuItem = contextMenuItems.find(
        (item) => item.value === selectedOption.value
      );
      if (selectedMenuItem) {
        selectedMenuItem.action();
      }
    }
  };
  return (
    <>
      <HorizontalRightAlignedContainer style={{ flex: 1 }}>
        {navigation.state === "loading" ? (
          <Skeleton height={24} width={24} />
        ) : (
          <Select
            isDisabled={false}
            options={contextMenuItems}
            value={{
              label: (
                <div className="customcontextmenu-icon">
                  <Icon
                    name="ellipsis-horizontal"
                    size={16}
                    color={colors.SECONDARY_TEXT}
                  />
                </div>
              ),
              value: " ",
            }}
            onChange={handleSelection}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: (baseStyles, state) => ({
                "&:hover": {
                  backgroundColor: PALE_GRAY,
                  borderRadius: 8,
                  cursor: "pointer",
                },
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 8,
                paddingLeft: 8,
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,
                padding: 6,
                borderRadius: 8,
                color: "transparent",
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                width: 200,
                position: "absolute",
                right: 10,
                padding: 0,
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                padding: 12,
                backgroundColor: state.isSelected ? PALE_GRAY : WHITE,
                "&:hover": {
                  backgroundColor: PALE_GRAY,
                  cursor: "pointer",
                },
              }),
            }}
          />
        )}
      </HorizontalRightAlignedContainer>
    </>
  );
};
