// import { FontAwesome } from "@expo/vector-icons";
// import * as SplashScreen from "expo-splash-screen";
import { useEffect, useState } from "react";
// import * as Font from "expo-font";
// import {
//   Poppins_400Regular,
//   Poppins_500Medium,
//   Poppins_600SemiBold,
//   Poppins_700Bold,
// } from "@expo-google-fonts/poppins";
// import {
//   OleoScript_400Regular,
// } from '@expo-google-fonts/oleo-script';
// import { Inter_600SemiBold, Inter_700Bold } from "@expo-google-fonts/inter";
// import { BerkshireSwash_400Regular } from '@expo-google-fonts/berkshire-swash';
import {Auth} from 'aws-amplify';
import axios from "axios";
import { CognitoUser } from "amazon-cognito-identity-js";
import { axiosRequestInterceptor } from "./tokenRefresher";
import { ProfileFull } from "../types";
import { getUserData } from "../utils/authenticationUtil";
// import { ProfileFull } from "../types";
// import { getUserData, getUserDataByEmailAndUpdate, getUserDataByUsername } from "../utils/authenticationUtil";
// import { logSentryError } from "../utils/sentryUtil";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [authUser, setAuthUser] = useState<CognitoUser | null>(null);
  const [userData, setUserData] = useState<ProfileFull | null>(null);
//   const [pushToken, setPushToken] = useState("");
  const preparePriorUserData = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});
      setAuthUser(authUser);
      if (authUser !== null) {
        axios.interceptors.request.use(axiosRequestInterceptor, (e) =>
          Promise.reject(e)
        );
        try {
          // find our userdata in the database if we can
          const data = await getUserData(
            authUser.username, 
            authUser.attributes.email, 
            authUser.attributes.given_name, 
            authUser.attributes.family_name
          );
          if (data)
            setUserData(data)
        } catch (e: any) {
          // Something else bad happened, log it but pass through, 
          // userData will be found later instead
          // logSentryError("Error retrieving user information!", e, {
          //   tags: {
          //     userId: "",
          //     screen: "useCachedResources",
          //     function: "preparePriorUserData",
          //   },
          //   extra: {
          //     username: authUser.username,
          //     email: authUser.attributes.email,
          //     firstName: authUser.attributes.given_name,
          //     lastName: authUser.attributes.family_name,
          //   },
          //   level: "error",
          // });
        }

        // // Since we found our userData, make sure we also have our push token
        // if (userData) {
        //   const newPushToken = await getPushNotificationsAsync(
        //     userData.id
        //   );
        //   setPushToken(newPushToken);
        // }  
      }  
    } catch(e) {
      setAuthUser(null);
    }
    // finally {
    //     console.log("loading complete 1")

    //     setLoadingComplete(true);
    // }
  }

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
//         SplashScreen.preventAutoHideAsync();

//         // Load fonts
        // await Font.loadAsync({
        //   ...FontAwesome.font,
        //   Poppins_400Regular,
        //   Poppins_500Medium,
        //   Poppins_600SemiBold,
        //   Poppins_700Bold,
        //   OleoScript_400Regular,
        //   Inter_600SemiBold,
        //   Inter_700Bold,
        //   BerkshireSwash_400Regular
        // });

        await preparePriorUserData()
      } catch (e) {
        console.log("error in loadResourcesAndDataAsync", e)
//         // We might want to provide this error information to an error reporting service
//         logSentryError("Error loading fonts.", e, {
//             userId: "",
//             screen: "",
//             function: "loadResourcesAndDataAsync",
//           },
//           extra: {
//           },
//           level: "error",
        // });
      } finally {
        setLoadingComplete(true);
        // SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return { isLoadingComplete, authUser, userData };
}
