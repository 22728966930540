import React, { useContext, useState } from "react";
import { VenueFull } from "../../../types";
import { TextRegular } from "../../../styles/text";
import { userContext } from "../../../UserContext";
import { DetailsActionButtonBar } from "../../../features/actionBars/DetailsActionButtonBar";
import { ShareButton } from "../../../features/ShareButton";
import { REDIRECT_URL } from "../../../env";
import { useFetcher, useNavigate } from "react-router-dom";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import { goToEditVenue, goToReport } from "../../../utils/navUtil";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { ContextHistoryDrawer } from "../../../features/CustomContextMenu/ContextHistoryDrawer";

type VenueDetailsActionButtonProps = {
  venueDetails: VenueFull;
};
export const VenueDetailsActionButtons = (
  props: VenueDetailsActionButtonProps
) => {
  const { venueDetails } = props;
  const userInfo = useContext(userContext);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const shareUri = `${REDIRECT_URL}venue/view/${venueDetails.id}`;
  const [showHistoryDrawer, setShowHistoryDrawer] = useState(false);

  const byAdmin = userInfo.adminLevel >= 80;
  const canDeactivate = byAdmin;
  const canReactivate = byAdmin;
  const canEdit = true;
  const canBeDeactivated = venueDetails.status === "active";
  const canBeReactivated = venueDetails.status === "inactive";
  const canBeEdited = venueDetails.status === "active";
  const returnUri = `/venue/view/${venueDetails.id}/`;

  const venueActionButtons: ContextMenuItem[] = [];

  const doEditVenue = () => {
    goToEditVenue(navigate, venueDetails.id);
  };

  const doReportVenue = () => {
    const reportQueryString = `?targetType=venue&targetId=${venueDetails.id}&targetName=${venueDetails.name}&returnUri=${returnUri}`;
    goToReport(navigate, reportQueryString);
  };

  const doRevisionHistory = () => {
    setShowHistoryDrawer(true);
  };
  const deactivateVenue = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Deactivate this venue?"}
            content={"Are you sure you want to deactivate this venue"}
            yesButton="Deactivate"
            noButton="Cancel"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "deactivate", lastEditedBy: userInfo.id },
                { method: "POST", action: "venueContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const reactivateVenue = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Reactivate this venue?"}
            content={"Are you sure you want to reactivate this venue?"}
            yesButton="Reactivate"
            noButton="Cancel"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "reactivate", lastEditedBy: userInfo.id },
                { method: "POST", action: "venueContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  if (canEdit && canBeEdited) {
    venueActionButtons.push({
      label: <TextRegular>Edit This Venue</TextRegular>,
      value: "edit",
      action: doEditVenue,
    });
  }

  if (canDeactivate && canBeDeactivated) {
    venueActionButtons.push({
      label: <TextRegular>Deactivate This Venue</TextRegular>,
      value: "deactivate",
      action: deactivateVenue,
    });
  }

  if (canReactivate && canBeReactivated) {
    venueActionButtons.push({
      label: <TextRegular>Reactivate This Venue</TextRegular>,
      value: "reactivate",
      action: reactivateVenue,
    });
  }
  venueActionButtons.push({
    label: <TextRegular>Report This Venue</TextRegular>,
    value: "report",
    action: doReportVenue,
  });

  venueActionButtons.push({
    label: <TextRegular>View Edit History</TextRegular>,
    value: "history",
    action: doRevisionHistory,
  });

  // { label: <TextRegular>View Edit History</TextRegular>, value: "history" },

  return (
    <DetailsActionButtonBar backButtonTitle="Back">
      <ShareButton
        shareDialogTitle={"Share This Venue"}
        shareTitle={`${venueDetails.name} on Picklers`}
        shareMessage={`I'd like to share "${venueDetails.name}" with you on Picklers!`}
        shareUrl={shareUri}
      />
      <ContextHistoryDrawer
        targetType="venue"
        targetId={venueDetails.id}
        showDrawer={showHistoryDrawer}
        setShowDrawer={setShowHistoryDrawer}
      />
      <NewCustomContextMenu contextMenuItems={venueActionButtons} />
    </DetailsActionButtonBar>
  );
};
