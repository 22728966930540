import { UseInfiniteQueryResult, UseQueryResult } from "react-query";
import {
  AttendeeFull,
  EventFull,
  GroupFull,
  MemberFull,
  MessageFull,
  PeopleData,
  ProfileFull,
  ThreadFull,
  VenueFull,
  defaultAttendee,
  defaultEventDetails,
  defaultGroupDetails,
  defaultMemberDetails,
  defaultMessageDetails,
  defaultProfileData,
  defaultThreadDetails,
  defaultVenueDetails,
} from "../../types";

export const groupsQueryResult: UseInfiniteQueryResult<GroupFull[]> = {
  data: {
    pages: [
      [
        defaultGroupDetails,
        defaultGroupDetails,
        defaultGroupDetails,
        defaultGroupDetails,
        defaultGroupDetails,
        defaultGroupDetails,
      ],
    ],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const eventsQueryResult: UseInfiniteQueryResult<EventFull[]> = {
  data: {
    pages: [[defaultEventDetails, defaultEventDetails, defaultEventDetails]],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const venuesQueryResult: UseInfiniteQueryResult<VenueFull[]> = {
  data: {
    pages: [
      [
        defaultVenueDetails,
        defaultVenueDetails,
        defaultVenueDetails,
        defaultVenueDetails,
        defaultVenueDetails,
        defaultVenueDetails,
      ],
    ],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const messagesQueryResult: UseInfiniteQueryResult<MessageFull[]> = {
  data: {
    pages: [[defaultMessageDetails, defaultMessageDetails]],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const profileQueryResult: UseQueryResult<ProfileFull> = {
  data: defaultProfileData,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const eventQueryResult: UseQueryResult<EventFull> = {
  data: defaultEventDetails,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const venueQueryResult: UseQueryResult<VenueFull> = {
  data: defaultVenueDetails,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const groupQueryResult: UseQueryResult<GroupFull> = {
  data: defaultGroupDetails,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const membershipQueryResult: UseQueryResult<MemberFull> = {
  data: defaultMemberDetails,
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const attendeesQueryResult: UseInfiniteQueryResult<AttendeeFull[]> = {
  data: {
    pages: [[defaultAttendee, defaultAttendee, defaultAttendee]],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const membersQueryResult: UseInfiniteQueryResult<MemberFull[]> = {
  data: {
    pages: [[defaultMemberDetails, defaultMemberDetails, defaultMemberDetails]],
    pageParams: [],
  },
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  fetchNextPage: () => {
    return new Promise((resolve, reject) => {});
  },
  fetchPreviousPage: () => {
    return new Promise((resolve, reject) => {});
  },
  isFetchingNextPage: false,
  isFetchingPreviousPage: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};
export const pinnedMessagesQueryResult: UseQueryResult<MessageFull[]> = {
  data: [defaultMessageDetails, defaultMessageDetails],
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const threadsQueryResult: UseQueryResult<ThreadFull[]> = {
  data: [defaultThreadDetails, defaultThreadDetails],
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};

export const peopleQueryResult: UseQueryResult<PeopleData[]> = {
  data: [defaultMemberDetails, defaultMemberDetails],
  error: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: true,
  status: "success",
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  errorUpdateCount: 0,
  isFetched: false,
  isFetchedAfterMount: false,
  isFetching: false,
  isPlaceholderData: false,
  isPreviousData: false,
  isRefetching: false,
  isStale: false,
  refetch: () => {
    return new Promise((resolve, reject) => {});
  },
  remove: () => {
    return new Promise((resolve, reject) => {});
  },
};
