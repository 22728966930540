import React from "react";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import { COLORS } from "../../styles/colors";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_SPACE_BETWEEN_PADDING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { ProfileImage } from "../image/ProfileImage";
import { InitialsProfilePic } from "../image/InitialsProfilePic";
import { getBorderRadius } from "../../utils";
import TouchableOpacity from "../general/TouchableOpacity";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { NormalText } from "../text/NormalText";
import { SmallText } from "../text/SmallText";

type PersonListItemProps = {
  firstName: string;
  lastName: string;
  imageUri?: string;
  role?: string;
  subtext?: string;
  thumbnailSize: number;
  onClick?: () => void;
};

export const PersonListItem = (
  props: React.PropsWithChildren<PersonListItemProps>
) => {
  const {
    firstName,
    imageUri,
    lastName,
    role,
    subtext,
    thumbnailSize,
    onClick,
  } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  return (
    <HorizontalLeftAlignedContainer>
      {navigation.state === "loading" ? (
        <Skeleton circle={true} height={thumbnailSize} width={thumbnailSize} />
      ) : (
        <TouchableOpacity
          onClick={onClick}
          style={{ borderStyle: "none", backgroundColor: colors.WHITE }}
        >
          <SizedContainer width={thumbnailSize} height={thumbnailSize}>
            {!!imageUri ? (
              <ProfileImage
                width={thumbnailSize}
                height={thumbnailSize}
                imgSrc={imageUri}
                borderRadius={thumbnailSize / 2}
              />
            ) : (
              <InitialsProfilePic
                firstName={firstName}
                lastName={lastName}
                fontSize={14}
                borderRadius={getBorderRadius("extra large", "rounded rect")}
              />
            )}
          </SizedContainer>
        </TouchableOpacity>
      )}
      <div style={{ width: LINE_HORIZONTAL_SPACING }} />
      <div>
        <HorizontalLeftAlignedContainer style={{ marginBottom: 4 }}>
          <NormalText color={colors.PRIMARY_TEXT_COLOR} fontWeight={500}>
            {firstName} {lastName}
          </NormalText>

          {role && (
            <NormalText color={colors.PRIMARY_TEXT_COLOR}>
              &nbsp;({role})
            </NormalText>
          )}
        </HorizontalLeftAlignedContainer>
        {subtext && (
          <HorizontalLeftAlignedContainer>
            <SmallText color={colors.SECONDARY_TEXT}>{subtext}</SmallText>
          </HorizontalLeftAlignedContainer>
        )}
        {props.children}
      </div>
    </HorizontalLeftAlignedContainer>
  );
};
