import react from "react";
import { MyEventListSection } from "../Sections/MyEventListSection";
import { EventFull } from "../../../types";
import { MyEventsFilterSection } from "../Sections/MyEventsFilterSection";
import { MyEventsFilterInfo } from "../EventsScreen";
import { UseInfiniteQueryResult } from "react-query";

export type MyEventsTabProps = {
  isSelected: boolean;
  tabHeight: number;
  filterInfo?: MyEventsFilterInfo;
  useUsersRSVPsResult: UseInfiniteQueryResult<EventFull[]>;
};

export const MyEventsTab = (props: MyEventsTabProps) => {
  return (
    <>
      <div
        style={{
          position: "sticky",
          top: props.tabHeight,
          zIndex: 1000,
          marginTop: -16,
        }}
      >
        <MyEventsFilterSection filterInfo={props.filterInfo} />
      </div>

      <MyEventListSection useUsersRSVPsResult={props.useUsersRSVPsResult} />
    </>
  );
};
