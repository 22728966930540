import React, { Dispatch, SetStateAction, useRef } from "react";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { ITEM_VERTICAL_SPACING } from "../../styles/spacing";
import { ProfileImage } from "./ProfileImage";
import Resizer from "react-image-file-resizer";
import "./ImagePicker.css";
import { OverlayButton } from "../buttons/OverlayButton";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { Icon } from "../../styles/icons";

export type ImagePickerProps = {
  imgSrc: string;
  formDataName: string;
  setImgSrc: Dispatch<SetStateAction<string>>;
  width: number;
  height: number;
  borderRadius: number;
};

export const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      600,
      "PNG",
      85,
      0,
      (uri: any) => {
        resolve(uri);
      },
      "file"
    );
  });

export const ImagePicker = (props: ImagePickerProps) => {
  const { imgSrc, formDataName, setImgSrc, height, width, borderRadius } =
    props;
  const inputRef = useRef<HTMLInputElement>(null);

  // if we have a newly chosen image, set it to display
  // so the user can see their new choice before submitting
  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const objUrl = URL.createObjectURL(file);
      setImgSrc(objUrl);
    }
  };

  return (
    <HorizontalLeftAlignedContainer
      style={{ marginBottom: ITEM_VERTICAL_SPACING }}
    >
      <div className="file-area" style={{ width: width, height: height }}>
        <input
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={onImageChange}
          name={formDataName}
        />
        <ProfileImage
          imgSrc={imgSrc}
          width={width}
          height={height}
          borderRadius={borderRadius}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // width: "100%",
            // height: "90%",
            display: "flex",
            justifyContent: "center",
            // alignItems: "flex-end",
            alignItems: "center",
          }}
        >
          <SecondaryButton
            title="Edit"
            onClick={() => {
              if (inputRef.current) inputRef.current.click();
            }}
            type="button"
          />
        </div>
      </div>
    </HorizontalLeftAlignedContainer>
  );
};
