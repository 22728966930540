import { EventType, EventStatus } from "../types/enums";
import { PeopleData, defaultPeopleData } from "./commonTypes";
import { GroupAccessValue } from "./groupTypes";

export type EventTypeValue = `${EventType}`;
export type EventStatusType = `${EventStatus}`;

export type ConflictRecord = {
  eventId: string;
  active: boolean;
};

export interface EventLastMessage {
  userId: string;
  firstName: string;
  lastName: string;
  imageUri: string;
  messageText: string;
  timePosted: number;
}
/* things we can update for any existing event */
export interface EventUpdateAble {
  title: string;
  description: string;
  emoji: string;
  date: number;
  rsvpDate: number;
  earlyBirdWindow: number;
  duration: number;
  minSkillLevel: number;
  maxSkillLevel: number;
  eventType: EventTypeValue;
  capacity: number;
  smartWaitlist: boolean;
  venueId: string;
  imageUri: string;
}

export interface EventRepeat {
  eventId: string;
  dates: number[];
  rsvpOpensDates: number[];
  // rsvpOpens: number;
  // rsvpOpensTime: string;
  // fixedRsvpOpens: number;
}

export interface EventUpdate extends Partial<EventUpdateAble> {}

/* additionaldata required to create an event in the DB that can't be updated */
export interface EventCreate extends EventUpdateAble {
  groupId: string;
}

/* includes data added/managed by the DB that we can access for any event */
/*
 * EventFull should match ReturnableEvent from the backend
 */
export interface EventFull extends EventCreate {
  id: string;
  originalEventId?: string;
  groupName: string;
  groupOwner: string;
  groupLocation: string;
  groupDisplayAddress: string;
  groupAccess: GroupAccessValue;
  venueNickname: string;
  venueAddress: string;
  venueDisplayAddress: string;
  venueImageUri: string;
  latitude: number;
  longitude: number;
  status: EventStatusType;
  hosts: PeopleData[];
  earlyRSVPs: PeopleData[];
  attendeeList: string[];
  peoplePics: string[];
  peopleInfo: PeopleData[];
  waitList: string[];
  notgoingList: string[];
  timeZone?: string;
  conflictFlag?: boolean;
  lastMessage?: EventLastMessage;
}

/* an empty default event structure for convenience */
export const defaultEventDetails: EventFull = {
  id: "",
  status: EventStatus.Active,
  title: "",
  description: "",
  imageUri: "",
  emoji: "😀",
  date: Date.now(),
  rsvpDate: Date.now(),
  duration: 2,
  earlyBirdWindow: 0,
  venueId: "",
  venueNickname: "",
  venueAddress: "",
  venueDisplayAddress: "",
  venueImageUri: "",
  attendeeList: [],
  peoplePics: [],
  peopleInfo: [],
  waitList: [],
  notgoingList: [],
  capacity: 20,
  smartWaitlist: false,
  minSkillLevel: 0,
  maxSkillLevel: 10,
  eventType: EventType.Rec_Play,
  hosts: [],
  earlyRSVPs: [],
  groupId: "0",
  groupName: "",
  groupOwner: "0",
  groupLocation: "",
  groupDisplayAddress: "",
  groupAccess: "Public",
  latitude: 0,
  longitude: 0,
  timeZone: "America/New_York",
};
