import { COLORS } from "../../styles/colors";
import { TextSmall } from "../../styles/text";
import "./PrimaryButton.css";

type TextButtonProps = {
  title: string;
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
  flex?: number;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
};

export const TextButton = (props: TextButtonProps) => {
  const { title, onClick, color, backgroundColor, flex, disabled, type } =
    props;
  const colors = COLORS["light"];
  return (
    <button
      className={"text-button"}
      onClick={onClick}
      style={{
        flex: flex,
        backgroundColor: backgroundColor ? backgroundColor : colors.WHITE,
        borderStyle: "none",
      }}
      disabled={disabled}
      type={type ? type : undefined}
    >
      <TextSmall color={color ? color : colors.PRIMARY_TEXT_COLOR}>
        {title}
      </TextSmall>
    </button>
  );
};
