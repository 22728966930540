import React, { useState } from "react";
import Modal from "react-modal";
import { NavButton } from "../components/buttons/NavButton";
import { H2, TextRegular } from "../styles/text";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../styles/container";
import {
  EmailIcon,
  EmailShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { COLORS } from "../styles/colors";
import { getBorderRadius } from "../utils";
import { Icon } from "../styles/icons";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../styles/spacing";
import { FACEBOOK_APPID } from "../env";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigation } from "react-router-dom";

type ShareButtonProps = {
  shareDialogTitle: string;
  shareTitle: string;
  shareMessage: string;
  shareUrl: string;
};

export const ShareButton = (props: ShareButtonProps) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { shareDialogTitle, shareTitle, shareMessage, shareUrl } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-25%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };

  return (
    <>
      <NavButton
        iconName="share-outline"
        onClick={() => handleClick()}
        loading={navigation.state === "loading"}
      />

      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Share Modal"
          style={customStyles}
          appElement={document.getElementById("root") as HTMLElement}
        >
          <div
            style={{
              flex: 1,
              marginTop: ITEM_VERTICAL_SPACING,
              marginLeft: ITEM_HORIZONTAL_SPACING,
              marginRight: ITEM_HORIZONTAL_SPACING,
              marginBottom: ITEM_VERTICAL_SPACING,
              zIndex: 100,
            }}
          >
            <HorizontalLeftAlignedContainer
              style={{ marginBottom: ITEM_VERTICAL_SPACING }}
            >
              <NavButton
                iconName="close"
                onClick={() => {
                  closeModal();
                }}
                loading={navigation.state === "loading"}
              />
            </HorizontalLeftAlignedContainer>
            <HorizontalLeftAlignedContainer
              style={{ marginBottom: ITEM_VERTICAL_SPACING }}
            >
              <H2>{shareDialogTitle}</H2>
            </HorizontalLeftAlignedContainer>
            <div>
              <HorizontalSpaceBetweenContainer
                style={{ marginBottom: ITEM_VERTICAL_SPACING }}
              >
                <HorizontalLeftAlignedContainer style={{ width: 150 }}>
                  <EmailShareButton
                    subject={shareTitle}
                    body={shareMessage}
                    url={shareUrl}
                    style={{ flex: 1 }}
                  >
                    <HorizontalLeftAlignedContainer
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        borderRadius: getBorderRadius("small", "rounded rect"),
                        borderStyle: "solid",
                        borderColor: colors.LIGHT_GRAY,
                      }}
                    >
                      <EmailIcon
                        size={24}
                        style={{ marginRight: ITEM_HORIZONTAL_SPACING }}
                      />
                      <TextRegular>Email</TextRegular>
                    </HorizontalLeftAlignedContainer>
                  </EmailShareButton>
                </HorizontalLeftAlignedContainer>
                <HorizontalContainer
                  style={{ width: ITEM_HORIZONTAL_SPACING }}
                />
                <HorizontalLeftAlignedContainer style={{ width: 150 }}>
                  <FacebookMessengerShareButton
                    url={shareUrl}
                    appId={FACEBOOK_APPID ?? ""}
                    style={{ flex: 1 }}
                  >
                    <HorizontalLeftAlignedContainer
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        borderRadius: getBorderRadius("small", "rounded rect"),
                        borderStyle: "solid",
                        borderColor: colors.LIGHT_GRAY,
                      }}
                    >
                      <FacebookMessengerIcon
                        size={24}
                        style={{ marginRight: ITEM_HORIZONTAL_SPACING }}
                      />
                      <TextRegular>Messenger</TextRegular>
                    </HorizontalLeftAlignedContainer>
                  </FacebookMessengerShareButton>
                </HorizontalLeftAlignedContainer>
              </HorizontalSpaceBetweenContainer>

              <HorizontalSpaceBetweenContainer
                style={{
                  marginBottom: SECTION_VERTICAL_SPACING,
                }}
              >
                <HorizontalLeftAlignedContainer style={{ width: 150, flex: 1 }}>
                  <WhatsappShareButton
                    title={shareMessage}
                    url={shareUrl}
                    style={{ flex: 1 }}
                  >
                    <HorizontalLeftAlignedContainer
                      style={{
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        borderRadius: getBorderRadius("small", "rounded rect"),
                        borderStyle: "solid",
                        borderColor: colors.LIGHT_GRAY,
                      }}
                    >
                      <WhatsappIcon
                        size={24}
                        style={{ marginRight: ITEM_HORIZONTAL_SPACING }}
                      />
                      <TextRegular>WhatsApp</TextRegular>
                    </HorizontalLeftAlignedContainer>
                  </WhatsappShareButton>
                </HorizontalLeftAlignedContainer>
                <HorizontalContainer
                  style={{ width: ITEM_HORIZONTAL_SPACING }}
                />
                <HorizontalLeftAlignedContainer style={{ width: 150, flex: 1 }}>
                  <CopyToClipboard
                    text={shareUrl}
                    onCopy={() => alert("copied")}
                  >
                    <HorizontalLeftAlignedContainer
                      style={{
                        flex: 1,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        borderRadius: getBorderRadius("small", "rounded rect"),
                        borderStyle: "solid",
                        borderColor: colors.LIGHT_GRAY,
                      }}
                    >
                      <Icon name="link" size={24} />

                      <TextRegular
                        style={{ marginLeft: ITEM_HORIZONTAL_SPACING }}
                      >
                        Copy Link
                      </TextRegular>
                      {/* <TextRegular style={{ marginLeft: ITEM_HORIZONTAL_SPACING }}>
                      Copy Link
                    </TextRegular> */}
                    </HorizontalLeftAlignedContainer>
                  </CopyToClipboard>
                </HorizontalLeftAlignedContainer>
              </HorizontalSpaceBetweenContainer>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
