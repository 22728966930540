import React from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { NavButton } from "../../components/buttons/NavButton";
import { TextButton } from "../../components/buttons/TextButton";
import { COLORS } from "../../styles/colors";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";

type ActionBarProps = {
  backTitle?: string;
  title?: string;
  disabled?: boolean;
};

export const ActionBar = (props: ActionBarProps) => {
  const { backTitle, title, disabled } = props;
  const colors = COLORS["light"];
  const navigate = useNavigate();
  const navigation = useNavigation();
  const goBack = () => {
    if (!disabled) navigate(-1);
  };
  return (
    <HorizontalSpaceBetweenContainer
      style={{
        flex: 1,
        display: "flex",
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: colors.LIGHT_GRAY,
        marginBottom: SECTION_VERTICAL_SPACING,
      }}
    >
      <HorizontalLeftAlignedContainer style={{ flex: 1 }}>
        <NavButton
          iconName={"chevron-back-outline"}
          onClick={goBack}
          disabled={disabled}
          loading={navigation.state === "loading"}
        />
        <TextButton
          title={backTitle ? backTitle : "Back"}
          onClick={goBack}
          type="button"
        />
      </HorizontalLeftAlignedContainer>
      {title && (
        <HorizontalContainer style={{ flex: 4 }}>
          <TextRegular>{title}</TextRegular>
        </HorizontalContainer>
      )}
      <HorizontalRightAlignedContainer style={{ flex: 1 }} />
    </HorizontalSpaceBetweenContainer>
  );
};
