import React from "react";
import { LINE_VERTICAL_SPACING } from "../../styles/spacing";
import { COLORS } from "../../styles/colors";

type LineVerticalSpaceProps = {
  border?: boolean;
};

export const LineVerticalSpace = (props: LineVerticalSpaceProps) => {
  const colors = COLORS["light"];
  return (
    <div
      style={{
        height: LINE_VERTICAL_SPACING,
        borderBottomStyle: props.border ? "solid" : "none",
        borderBottomColor: colors.LIGHT_GRAY,
        borderBottomWidth: 1,
      }}
    />
  );
};
