import IonIcon from "@reacticons/ionicons";
import {
  DARK_PRIMARY_TEXT_COLOR,
  DARK_SECONDARY_TEXT,
} from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { Icon } from "../../styles/icons";
import "./NavButton.css";
import { TextRegular } from "../../styles/text";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

type NavButtonProps = {
  iconName: React.ComponentProps<typeof IonIcon>["name"];
  onClick: () => void;
  title?: string;
  color?: string;
  size?: number;
  disabled?: boolean;
  loading?: boolean;
  padding?: number;
  backgroundColor?: string;
};

export const NavButton = (props: NavButtonProps) => {
  const {
    iconName,
    onClick,
    title,
    color,
    size,
    disabled,
    loading,
    padding,
    backgroundColor,
  } = props;
  const iconSize = size ?? 22;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };
  return (
    <>
      <button
        className="nav-button"
        onClick={handleClick}
        disabled={disabled}
        type="button"
        style={{ padding: padding, backgroundColor: backgroundColor }}
      >
        <HorizontalLeftAlignedContainer>
          {loading ? (
            <Skeleton height={iconSize} width={iconSize} />
          ) : (
            <Icon
              name={iconName}
              size={iconSize}
              color={color ? color : DARK_SECONDARY_TEXT}
            />
          )}
          {title && (
            <>
              <div style={{ width: 4 }} />
              {loading ? (
                <Skeleton height={iconSize} width={14 * title.length} />
              ) : (
                <TextRegular
                  style={{ color: color ? color : DARK_SECONDARY_TEXT }}
                >
                  {title}
                </TextRegular>
              )}
            </>
          )}
        </HorizontalLeftAlignedContainer>
      </button>
    </>
  );
};
