import { COLORS } from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { Icon } from "../../styles/icons";
import {
  LINE_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { TextRegular, TextSmall } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import "./PickerButton.css";

type PickerButtonProps = {
  title: string;
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
};

export const PickerButton = (props: PickerButtonProps) => {
  const { title, onClick, color, backgroundColor } = props;
  const colors = COLORS["light"];
  let truncatedTitle = title;
  if (truncatedTitle.length > 30)
    truncatedTitle = title.substring(0, 28) + "...";
  return (
    <button
      className={"member-button"}
      onClick={onClick}
      style={{
        borderRadius: getBorderRadius("tiny", "rounded rect"),
        border: "none",
        paddingTop: 4,
        paddingBottom: 4,
        marginRight: LINE_HORIZONTAL_SPACING,
        marginBottom: LINE_VERTICAL_SPACING,
        backgroundColor: backgroundColor,
        textAlign: "left",
      }}
      type="button"
    >
      <HorizontalLeftAlignedContainer>
        <TextSmall
          color={color ? color : colors.PRIMARY_TEXT_COLOR}
          style={{ marginRight: LINE_VERTICAL_SPACING }}
        >
          {truncatedTitle}
        </TextSmall>
        <Icon name="close-outline" size={16} />
      </HorizontalLeftAlignedContainer>
    </button>
  );
};
