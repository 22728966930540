import React from "react";
import { ScreenContainer } from "../../features/ScreenContainer";
import { HEADER_VERTICAL_SPACING } from "../../styles/spacing";

export const TermsOfServiceScreen = () => {
  return (
    <ScreenContainer>
      <div style={{ marginTop: HEADER_VERTICAL_SPACING }} />
      <h2>Terms Of Service</h2>
      <p>Last updated June 30, 2023</p>

      <p>
        Welcome to Picklers! Please read these Terms of Service (“Terms”)
        carefully before using our mobile application, website, software, and
        services (collectively, the “Services” or “Platform”) operated by
        Hopewell Opportunity Fund LLC (&quot;us&quot;, &quot;we&quot;, or
        &quot;our&quot;), as they contain important information about your legal
        rights, remedies and obligations. By accessing or using our Services,
        you agree to comply with and be bound by these Terms, as applicable to
        you.
      </p>

      <b>1.</b>
      <b>Our Services</b>

      <p>
        We provide an online Platform that allows users to schedule and manage
        pickleball events, including creating events, player sign-up, waitlist
        management, and event reminders. We use the terms “you” and “your” to
        mean any person using our Platform, and any organization or person using
        the Platform on an organization’s behalf.
      </p>
      <p>
        We try hard to make sure that our Platform is always available and
        working, but we cannot guarantee it will be.
      </p>
      <p>
        We are continually improving our Platform. This means that we may modify
        or discontinue portions or all of our Platform with or without notice
        and without liability to you or any third party.
      </p>

      <b>2.</b>
      <b>Your Account </b>

      <p>
        <b>Eligibility. </b>Our Platform is available to anyone who is at least
        18 years old. Additional eligibility requirements for a particular
        portion of our Platform may be set by any member who has the ability to
        moderate or manage that portion of our Platform. For example, the
        eligibility requirements for a group or event may be set by the
        organizers of that group.
      </p>

      <p>
        <b>Modification, Suspension, and Termination of Your Account. </b>We may
        modify, suspend, or terminate your account or access to the Platform if,
        in our sole discretion, we determine that you have violated this
        Agreement, including any of the policies or guidelines that are part of
        this Agreement, that it is in the best interest of the Picklers
        community, or to protect our brand or Platform.
      </p>

      <p>
        <b>Account Information and Security.</b>&nbsp;To use certain features of
        our Platform, you must create a user account. You agree to provide
        accurate and complete information when creating your account, and to
        keep your account information up-to-date. You are responsible for
        maintaining the confidentiality of your account login credentials and
        for all activities that occur under your account.
      </p>

      <p>
        <b>3. </b>
        <b>Your Content &amp; Privacy</b>
      </p>

      <p>
        Our Platform allow you to post, upload, or otherwise submit content,
        including but not limited to, text, photos, and videos (collectively,
        “User Content”). You retain all rights in, and are solely responsible
        for, the User Content you submit. By submitting User Content to our
        Platform, you grant us a non-exclusive, royalty-free, transferable,
        sublicensable, worldwide license to use, display, reproduce, modify,
        distribute, and create derivative works based upon the User Content in
        connection with our Platform. Our Privacy Policy explains how we collect
        and use information you provide about yourself and the information that
        may be associated with you, and explains the limited ways we may share
        this information.
      </p>
      <p>
        We require that you follow our policies and guidelines when using our
        Platform. We have no responsibility for Content that anyone posts to our
        Platform.
      </p>

      <p>
        <b>4. </b>
        <b>Your Use of Our Platform</b>
      </p>

      <p>
        By using our Platform, you agree to comply with all applicable laws,
        rules, and regulations. You may not use our Platform to:
      </p>

      <p>
        1. Post or transmit any content that is unlawful, threatening, abusive,
        libelous, defamatory, obscene, vulgar, pornographic, profane, or
        indecent.
      </p>

      <p>
        2. Post or transmit any content that infringes upon the rights of any
        third party, including but not limited to, intellectual property rights,
        privacy rights, and publicity rights.
      </p>

      <p>
        3. Impersonate any person or entity, or falsely state or otherwise
        misrepresent your affiliation with any person or entity.
      </p>

      <p>
        4. Post or transmit any unsolicited or unauthorized advertising,
        promotional materials, junk mail, spam, chain letters, or any other form
        of solicitation.
      </p>

      <p>
        5. Interfere with or disrupt our Services or servers or networks
        connected to our Services.
      </p>

      <p>
        6. Host, store, reproduce, modify, publish, publicly display, publicly
        perform, distribute, or create derivative works of the Platform, or any
        portion of the Platform.
      </p>

      <p>7. Remove or alter the proprietary notices on the Platform.</p>

      <p>
        8. Reverse engineer, disassemble, decompile, or attempt to discover the
        source code or structure, sequence, and organization of the Platform.
      </p>

      <p>
        9. Rent, lease, resell, distribute, or use the Platform for commercial
        purposes that are not contemplated by this Agreement.
      </p>

      <p>
        You also agree that you will not use the Platform to solicit or collect:
      </p>

      <p>
        1. Personal data from others except as necessary for the administration
        of or participation in a group or event.
      </p>

      <p>
        2. Sensitive personal data, as defined or treated as such under
        applicable law (including, by way of example only, health information
        and social security numbers and other government identifiers).
      </p>

      <b>5.</b>
      <b>Intellectual Property</b>

      <p>
        Our Services and all content and materials included on or within the
        Services, including but not limited to, software, text, graphics, logos,
        images, videos, and audio files, are the property of Hopewell
        Opportunity Fund LLC or our licensors and are protected by intellectual
        property laws. You may not copy, reproduce, modify, distribute,
        transmit, display, perform, or create derivative works based upon our
        Services or any content or materials included on or within our Services,
        without our prior written consent.
      </p>

      <b>6.</b>
      <b>Disclaimer of Warranties</b>

      <p>
        Our Services are provided “as is” and without warranties of any kind,
        whether express or implied. To the fullest extent permitted by
        applicable law, we disclaim all warranties, including but not limited
        to, warranties of merchantability, fitness for a particular purpose,
        title, non-infringement, and warranties arising from course of dealing,
        usage, or trade practice.
      </p>

      <b>7.</b>
      <b>Limitation of Liability</b>

      <p>
        To the full extent permitted by applicable law, you agree that in no
        event shall we be liable for any direct, indirect, incidental, special,
        or consequential damages, including but not limited to, damages for loss
        of profits, goodwill, use, data, or other intangible losses arising out
        of or in connection with our Services or these terms, whether or not
        such damages were foreseeable.
      </p>

      <b>8.</b>
      <b>Dispute Resolution</b>

      <p>
        If you have a dispute with us, you agree to try to work it out directly
        with us first. If we can't work it out, with limited exceptions, you
        must submit any dispute with us to a neutral arbitrator instead of
        taking the claim to a court of law. Claims can only be brought
        individually, and not as part of a class action. We have no obligation
        to get involved with any disputes you have with other members, although
        we may try to facilitate a resolution.
      </p>

      <b>9.</b>
      <b>This Agreement</b>

      <p>
        <b>Revisions to this Agreement</b>. We may modify this Agreement from
        time to time. When we do, we will provide notice to you by publishing
        the most current version and revising the date at the top of this page.
        By continuing to use the Platform after any changes come into effect,
        you agree to the revised Agreement. If you do not wish to accept the
        revised Agreement, you can close your account.
      </p>

      <p>
        <b>Third Party Services</b>. When the Platform uses third party services
        to provide certain features and services for our members, our members
        may be required to comply with the terms of service that apply to these
        features and services. For example, some areas of our Platform feature
        mapping services provided by Google Maps. When you use these mapping
        services on the Platform, you agree to be bound by the Google Maps
        Additional Terms of Service.
      </p>

      <p>
        <b>Entire Agreement</b>. These Terms make up our entire agreement with
        you unless we also enter into a separate written agreement. Except as
        otherwise stated in these Terms, these Terms are the entire agreement
        between you and us and govern your use of the Services, superseding any
        prior or contemporaneous agreements, proposals, discussions, or
        communications between you and us on the subject matter of these Terms,
        other than any written agreement for Services between you and us
        relating to a specified event or events.
      </p>
    </ScreenContainer>
  );
};
