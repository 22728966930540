export type MessageType = "group" | "event" | "dm";

export interface MessageCreate {
  // destination info
  messageType: MessageType;
  destinationId: string; // groupId, eventId, or directmessageId
  // message sender info
  userId: string;
  // message contents
  messageText: string;
}

export interface GroupsMessageCreate {
  // destination info
  groupIds: string[]; // groupId, eventId, or directmessageId
  // message sender info
  userId: string;
  // message contents
  messageText: string;
  pinned: boolean;
}

export interface MessageUpdate {
  id: string;
  messageText?: string;
  pinned?: boolean;
}

export interface MessageFull extends MessageCreate {
  // auto-managed on the server
  id: string;
  firstName: string;
  lastName: string;
  imageUri: string;
  messageImageUri?: string;
  messageImageKey?: string;
  timePosted: number;
  pinned: boolean;
}

export interface ThreadCreate {
  // list of userids involved in this DM thread
  userIds: string[];
  // the title of the thread
  title: string;
  /* These contain the initial message on this thread */
  userId: string;
  messageText: string;
}

/*
 * ThreadFull should match ReturnableDMThread from the backend
 */
export interface ThreadFull {
  destinationId: string;
  title: string;
  userIds: string[];
  /* These are convenience fields for the last message posted */
  messageText: string;
  firstName: string;
  lastName: string;
  timePosted: number;
  imageUri: string;
}

export const defaultMessageDetails: MessageFull = {
  id: "",
  messageType: "dm",
  destinationId: "",
  userId: "",
  messageText: "",
  firstName: "",
  lastName: "",
  imageUri: "",
  timePosted: new Date().getTime(),
  pinned: false,
};

export const defaultThreadDetails: ThreadFull = {
  destinationId: "",
  title: "",
  userIds: [],
  messageText: "",
  firstName: "",
  lastName: "",
  timePosted: 0,
  imageUri: "",
};
