import { isRouteErrorResponse } from "react-router-dom";

export const getErrorMessageFromError = (error: any) => {
  let errorMessage = "";
  
  if (isRouteErrorResponse(error)) {
    errorMessage = error.error?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }
  
  return errorMessage;
}