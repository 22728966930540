import React from "react";
import { ITEM_VERTICAL_SPACING } from "../../styles/spacing";
import { COLORS } from "../../styles/colors";

type ItemVerticalSpaceProps = {
  border?: boolean;
};

export const ItemVerticalSpace = (props: ItemVerticalSpaceProps) => {
  const colors = COLORS["light"];
  return (
    <div
      style={{
        height: ITEM_VERTICAL_SPACING,
        borderBottomStyle: props.border ? "solid" : "none",
        borderBottomColor: colors.LIGHT_GRAY,
        borderBottomWidth: 1,
      }}
    />
  );
};
