import { createRef, useEffect, useState } from "react";
import { UseInfiniteQueryResult } from "react-query";
import { VenueFull } from "../../../types";
import { VenueCard } from "../components/VenueCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSpinner } from "../../../components/general/ListSpinner";
import { useNavigate, useNavigation } from "react-router-dom";
import { goToVenueDetails } from "../../../utils/navUtil";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyVenues } from "../../../assets/images/empty-state/empty-general.svg";
import { useWindowWidth } from "@react-hook/window-size";
import { SECTION_HORIZONTAL_SPACING } from "../../../styles/spacing";

type VenuesListSectionProps = {
  venueQueryResult: UseInfiniteQueryResult<VenueFull[]>;
};

export const VenuesListSection = (props: VenuesListSectionProps) => {
  const { venueQueryResult } = props;
  const navigate = useNavigate();
  const [items, setItems] = useState<VenueFull[] | undefined>(undefined);
  const [cardSize, setCardSize] = useState(800);
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const numCols = 3;
  const divRef = createRef<HTMLDivElement>();
  const windowWidth = useWindowWidth();

  const hasMore = () => {
    const lastPage = venueQueryResult.data?.pages.slice(-1);
    return (
      !!venueQueryResult.hasNextPage &&
      !venueQueryResult.isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreVenues = () => {
    if (hasMore()) {
      venueQueryResult.fetchNextPage();
    }
  };

  useEffect(() => {
    if (venueQueryResult.data) {
      setItems(
        venueQueryResult.data.pages.map((page) => (page ? page : [])).flat()
      );
    } else {
      setItems([]);
    }
  }, [venueQueryResult.data]);

  useEffect(() => {
    if (!divRef.current) return;
    const newWidth = Math.floor(
      (divRef.current?.offsetWidth - 4 * SECTION_HORIZONTAL_SPACING - 10) / 3
    );
    setCardSize(newWidth);
  }, [windowWidth, divRef]);

  return (
    <>
      {venueQueryResult.isSuccess && items && (
        <div ref={divRef}>
          <InfiniteScroll
            dataLength={items.length} //This is important field to render the next data
            next={fetchMoreVenues}
            hasMore={!!hasMore()}
            loader={<h4>Loading...</h4>}
          >
            {items.length <= 0 && (
              <EmptyLayout
                title="No venues found"
                subTitle="Check your filters to ensure they match your intended search!"
              >
                <EmptyVenues />
              </EmptyLayout>
            )}
            {items.map((item, index) => (
              <VenueCard
                key={index}
                venueDetails={item}
                onClick={() => goToVenueDetails(navigate, item.id)}
                addPaddingLeft={
                  (index - 1) % numCols === 0 || (index - 1) % numCols === 1
                }
                addTopPadding={index > 2}
                cardSize={cardSize}
                loading={loading}
              />
            ))}
            {venueQueryResult.hasNextPage &&
              venueQueryResult.isFetchingNextPage && <ListSpinner />}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
