import React from "react";
import { Route } from "react-router-dom";
import { QueryClient } from "react-query";
import { VenuesError } from "../screens/Venues/VenuesError";
import { VenuesScreen, venuesLoader } from "../screens/Venues/VenuesScreen";
import { VenueDetailsError } from "../screens/VenueDetails/VenueDetailsError";
import {
  VenueDetailsScreen,
  venueDetailsLoader,
  VenueDetailsParams,
  venueContextAction,
} from "../screens/VenueDetails/VenueDetailsScreen";
import { EditVenueError } from "../screens/VenueEdit/EditVenueError";
import {
  EditVenueScreen,
  editVenueLoader,
  EditVenueParams,
  editVenueAction,
} from "../screens/VenueEdit/EditVenueScreen";

type VenueRoutesProps = {
  latitude: number;
  longitude: number;
  currentUserId: string;
  queryClient: QueryClient;
};
export const useVenueRoutes = (props: VenueRoutesProps) => {
  const { currentUserId, queryClient, latitude, longitude } = props;
  return (
    <>
      <Route
        path="venues"
        Component={VenuesScreen}
        errorElement={<VenuesError />}
        loader={({ request }: { request: Request }) => {
          return venuesLoader(
            queryClient,
            request,
            currentUserId,
            latitude,
            longitude
          );
        }}
      />
      <Route
        path="venue/view/:venueId"
        Component={VenueDetailsScreen}
        errorElement={<VenueDetailsError />}
        loader={({ params, request }) => {
          return venueDetailsLoader(
            params as VenueDetailsParams,
            request,
            queryClient,
            currentUserId,
            latitude,
            longitude
          );
        }}
      />
      <Route
        path="venue/view/:venueId/venueContextAction"
        action={({ params, request }) => {
          return venueContextAction(
            params as VenueDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="venues/:taskId/:venueId?"
        Component={EditVenueScreen}
        errorElement={<EditVenueError />}
        loader={({ params, request }) => {
          return editVenueLoader(
            params as EditVenueParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={editVenueAction(queryClient, currentUserId)}
      />
    </>
  );
};
