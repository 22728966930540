import { HorizontalContainer } from "../../styles/container";
import { ITEM_VERTICAL_SPACING } from "../../styles/spacing";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import { ScreenContainer } from "../../features/ScreenContainer";

type ErrorLayoutProps = {
  overallTitle: string;
  title?: string;
  subTitle?: string;
};

export const ErrorLayout = (
  props: React.PropsWithChildren<ErrorLayoutProps>
) => {
  return (
    <ScreenContainer>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <H1>{props.overallTitle}</H1>
        </div>
      </div>
      <HorizontalContainer
        style={{
          height: 300,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </HorizontalContainer>
      <HorizontalContainer
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: ITEM_VERTICAL_SPACING,
        }}
      >
        <TextRegular>
          {props.title ? props.title : "Error loading data"}
        </TextRegular>
      </HorizontalContainer>
      <HorizontalContainer
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextSmall>
          {props.subTitle
            ? props.subTitle
            : "Your internet connection may have been interrupted. Please try again!"}
        </TextSmall>
      </HorizontalContainer>
    </ScreenContainer>
  );
};
