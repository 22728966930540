import React, { useEffect, useState } from "react";
import { Form, useNavigation } from "react-router-dom";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { COLORS } from "../../styles/colors";
import { HorizontalContainer } from "../../styles/container";
import { H1, TextRegular } from "../../styles/text";
import { ChangePasswordErrors } from "./ChangePasswordScreen";
import { ItemVerticalSpacer } from "../../styles/divider";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  HEADER_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { TitledInput } from "../../components/inputs/TitledInput";

type ChangePasswordScreenLayoutProps = {
  errors: ChangePasswordErrors;
};

export const ChangePasswordScreenLayout = (
  props: ChangePasswordScreenLayoutProps
) => {
  const { errors } = props;
  const [layoutErrors, setLayoutErrors] = useState(errors);
  const colors = COLORS["light"];

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const navigation = useNavigation();

  const isBusy = () => {
    return navigation.state !== "idle";
  };

  useEffect(() => {
    if (errors) {
      setLayoutErrors(errors);
      console.log("layoutErrors: ", errors);
    }
  }, [errors]);
  return (
    <ScreenContainer horizontalPadding={0}>
      <div
        style={{
          flex: 1,
        }}
      >
        <StickyActionBar>
          <DetailsActionButtonBar backButtonTitle="Back" />
        </StickyActionBar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form
          action="/changePassword"
          method="POST"
          style={{
            width: 400,
          }}
        >
          <HorizontalContainer
            style={{
              marginBottom: SECTION_VERTICAL_SPACING,
            }}
          >
            <H1 color={colors.PRIMARY_TEXT_COLOR}>Change your password</H1>
          </HorizontalContainer>

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Old Password"
              formDataName={"oldpassword"}
              value={oldPassword}
              setText={setOldPassword}
              required={true}
              editable={true}
              placeholderText="Your old password"
              maxLength={16}
              password={true}
              autoComplete="current-password"
            />
          </HorizontalContainer>
          {layoutErrors?.oldPassword && (
            <HorizontalContainer>
              <TextRegular color="red">{layoutErrors?.oldPassword}</TextRegular>
            </HorizontalContainer>
          )}

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Password"
              formDataName={"password"}
              value={password}
              setText={setPassword}
              required={true}
              editable={true}
              placeholderText="Enter new password"
              maxLength={16}
              password={true}
              autoComplete="new-password"
            />
          </HorizontalContainer>
          {layoutErrors?.password && (
            <HorizontalContainer>
              <TextRegular color="red">{layoutErrors?.password}</TextRegular>
            </HorizontalContainer>
          )}

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Retype Password"
              formDataName={"password2"}
              value={password2}
              setText={setPassword2}
              required={true}
              editable={true}
              placeholderText="Retype new password"
              maxLength={16}
              password={true}
            />
          </HorizontalContainer>
          {layoutErrors?.password2 && (
            <HorizontalContainer>
              <TextRegular color="red">{layoutErrors?.password2}</TextRegular>
            </HorizontalContainer>
          )}

          {layoutErrors?.other && (
            <HorizontalContainer>
              <TextRegular color="red">{layoutErrors?.other}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <SubmitButton loading={isBusy()} title="Change Password" />
          </HorizontalContainer>
          {isBusy() && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <BeatLoader
                color={colors.PRIMARY_BRAND}
                loading={true}
                size={14}
              />
            </HorizontalContainer>
          )}
        </Form>
      </div>
    </ScreenContainer>
  );
};
