import React, { Dispatch, SetStateAction, useState } from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { GroupFull } from "../../types";
import Drawer from "react-modern-drawer";
import { GroupsPickerCard } from "./GroupsPickerCard";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { COLORS } from "../../styles/colors";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../../components/buttons/NavButton";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { GroupsPicker } from "./GroupsPicker";
import { UseInfiniteQueryResult } from "react-query";
import { NormalText } from "../../components/text/NormalText";
import { ItemHorizontalSpace } from "../../components/spacing/ItemHorizontalSpace";

export type TitledGroupsPickerProps = {
  groupsQueryResult: UseInfiniteQueryResult<GroupFull[]>;
  groups: GroupFull[];
  setGroups: Dispatch<SetStateAction<GroupFull[]>>;
  formDataName?: string;
  maximum?: number;
};

export const TitledGroupsPicker = (props: TitledGroupsPickerProps) => {
  const { groupsQueryResult, groups, setGroups } = props;
  const [showGroupsPicker, setShowGroupsPicker] = useState(false);
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const toggleGroupsPickerDrawer = () => {
    if (navigation.state !== "loading")
      setShowGroupsPicker((isOpen) => {
        return !isOpen;
      });
  };

  const onRemoveGroup = (groupToRemove: GroupFull) => {
    const newGroups = groups.filter((group) => group.id !== groupToRemove.id);
    setGroups(newGroups);
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        // paddingLeft: SIDE_PADDING,
        // paddingRight: SIDE_PADDING,
      }}
    >
      <Drawer
        open={showGroupsPicker}
        onClose={() => toggleGroupsPickerDrawer()}
        direction="right"
        lockBackgroundScroll={true}
        size={500}
        zIndex={2000}
      >
        <GroupsPicker
          groupsQueryResult={groupsQueryResult}
          isOpen={showGroupsPicker}
          closePicker={() => setShowGroupsPicker(false)}
          pickedGroups={groups}
          setPickedGroups={setGroups}
        />
      </Drawer>

      <HorizontalSpaceBetweenContainer
        style={{
          borderTopColor: colors.LIGHT_GRAY,
          borderBottomColor: colors.LIGHT_GRAY,
          borderBottomWidth: 1,
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderBottomStyle: "solid",
          // borderRadius: getBorderRadius("small", "rounded rect"),
          flex: 1,
          paddingLeft: ITEM_HORIZONTAL_SPACING,
          paddingRight: ITEM_HORIZONTAL_SPACING,
          paddingTop: LINE_HORIZONTAL_SPACING,
        }}
      >
        <HorizontalLeftAlignedContainer
          style={{ minHeight: 28, display: "flex", alignItems: "flex-start" }}
        >
          {/* {navigation.state === "loading" ? (
            <Skeleton height={20} width={40} />
          ) : (
            <TextRegular
              color={colors.PRIMARY_TEXT_COLOR}
              style={{ paddingTop: 2, marginBottom: 10 }}
            >
              To:
            </TextRegular>
          )} */}
          <NormalText color={colors.PRIMARY_TEXT_COLOR}>To:</NormalText>
          {/* <div style={{ width: ITEM_HORIZONTAL_SPACING }} /> */}
          <ItemHorizontalSpace />

          {navigation.state === "loading" ? (
            <Skeleton height={24} width={300} style={{ marginBottom: 10 }} />
          ) : (
            <HorizontalLeftAlignedContainer
              style={{ flex: 1, flexWrap: "wrap" }}
            >
              {groups.map((item, index) => (
                <>
                  <GroupsPickerCard
                    key={index}
                    group={item}
                    onPress={() => onRemoveGroup(item)}
                  />
                </>
              ))}
            </HorizontalLeftAlignedContainer>
          )}
        </HorizontalLeftAlignedContainer>

        <div style={{ marginTop: 2, marginBottom: 10 }}>
          <NavButton
            iconName="add"
            onClick={() => setShowGroupsPicker(true)}
            color={colors.PRIMARY_TEXT_COLOR}
            size={16}
            loading={navigation.state === "loading"}
          />
        </div>
      </HorizontalSpaceBetweenContainer>
    </div>
  );
};
