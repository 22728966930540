import React, { useEffect, useRef, useState } from "react";
import { EventFull } from "../../../types";
import { H2, TextRegular } from "../../../styles/text";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { useEvents } from "../../../hooks/queryEvents";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { EventCard } from "../../../features/EventCard/EventCard";
import { useWindowHeight } from "@react-hook/window-size";
import { COLORS } from "../../../styles/colors";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyEvents } from "../../../assets/images/empty-state/empty-events.svg";
import { goToCreateEvent } from "../../../utils/navUtil";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { GroupEventsFilters } from "../Components/GroupEventsFilters";
import { GroupEventsFilterInfo } from "../GroupDetailsScreen";
import { UseInfiniteQueryResult } from "react-query";

type GroupEventsSectionProps = {
  groupId: string;
  isOrganizer: boolean;
  groupEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
  groupEventsFilterInfo?: GroupEventsFilterInfo;
};

export const GroupEventsSection = (props: GroupEventsSectionProps) => {
  const {
    groupId,
    isOrganizer,
    groupEventsQueryResult,
    groupEventsFilterInfo,
  } = props;
  const {
    data,
    isSuccess,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = groupEventsQueryResult;
  const [events, setEvents] = useState<EventFull[]>([]);
  const [titleHeight, setTitleHeight] = useState(50);
  const colors = COLORS["light"];
  const navigate = useNavigate();
  const windowHeight = useWindowHeight();
  const titleRef = useRef<HTMLDivElement>(null);

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreEvents = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setEvents(data.pages.map((page) => (page ? page : [])).flat());
    }
  }, [isSuccess, data]);

  const handleClick = () => {
    goToCreateEvent(navigate, groupId);
  };
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;
    setTitleHeight(divRef.current.offsetHeight);
  }, []);

  return (
    <>
      <div ref={titleRef}>
        <HorizontalSpaceBetweenContainer
          style={{
            marginBottom: 8,
            paddingBottom: 8,
            borderBottomWidth: 1,
            borderBottomColor: colors.LIGHT_GRAY,
            borderBottomStyle: "solid",
          }}
        >
          <HorizontalLeftAlignedContainer>
            <H2>Group Events</H2>
          </HorizontalLeftAlignedContainer>
          {isOrganizer && (
            <HorizontalRightAlignedContainer>
              <SecondaryButton title="Create New Event" onClick={handleClick} />
            </HorizontalRightAlignedContainer>
          )}
        </HorizontalSpaceBetweenContainer>
        <GroupEventsFilters
          groupEventsFilterInfo={groupEventsFilterInfo}
          isLoading={isLoading}
        />
      </div>
      {isLoading && <TextRegular>Loading</TextRegular>}
      {isError && <TextRegular>Error</TextRegular>}
      {isSuccess && (
        <InfiniteScroll
          dataLength={events.length} //This is important field to render the next data
          next={fetchMoreEvents}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
          height={windowHeight - titleHeight - 68}
        >
          {events.length <= 0 && (
            <EmptyLayout title="No upcoming events found." subTitle="">
              <EmptyEvents />
            </EmptyLayout>
          )}
          {/** existing events */}
          {events.map((eventDetails, index) => (
            <EventCard
              key={index}
              eventDetails={eventDetails}
              hasRSVP={false}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
