export enum EventTime {
  Upcoming = "Upcoming",
  Past = "Past",
}

export enum CourtType {
  Any = "Any",
  Indoor = "Indoor",
  Outdoor = "Outdoor",
}

export enum EventType {
  Rec_Play = "Rec Play",
  Lessons = "Lessons",
  Drills = "Drills",
  Clinic = "Clinic",
  League = "League",
  Tournament = "Tournament",
  Party = "Party",
  Other = "Other",
  // Exclude Any when defining an event
  // Any = "Any"
}
export enum EventTypeFilter {
  Any = "Any",
  Rec_Play = "Rec Play",
  Lessons = "Lessons",
  Drills = "Drills",
  Clinic = "Clinic",
  League = "League",
  Tournament = "Tournament",
  Party = "Party",
  Other = "Other",
  // Allow Any when filtering events by EventType
}

export enum Access {
  Public = "Public",
  Private = "Private",
  Hidden = "Hidden",
  // Exclude Any when defining a group
  // Any = "Any"
}

export enum AccessFilter {
  Any = "Any",
  Public = "Public",
  Private = "Private",
  // can never filter for hidden groups
  // Allow Any when filtering events by Access
}

export enum VenueAccess {
  Public = "Public",
  Private = "Private",
}

export enum EventStatus {
  Active = "active",
  Canceled = "canceled",
  Deleted = "deleted",
  None = "",
}

export enum GroupStatus {
  Active = "active",
  Inactive = "inactive",
  Deleted = "deleted",
  Any = "",
}

export enum GroupTier {
  Free = "basic",
  Premium = "premium",
  Platinum = "platinum",
}

export enum VenueStatus {
  Active = "active",
  Inactive = "inactive",
  Any = "",
}

export enum VenueTier {
  Free = "basic",
  Premium = "premium",
}

export enum VerificationStatusFilter {
  Any = "Any",
  Verified = "Verified",
  Unverified = "Unverified",
}
