import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { GA_PROPERTY_ID } from "./env";
import ReactGA from "react-ga4";
import { userContext } from "./UserContext";
import { PicklersNavbar } from "./features/PicklersNavbar/PicklersNavbar";
import { SupportSidebar } from "./features/SupportSidebar";
import { MainScreenContainer } from "./styles/container";

type AppOutletProps = {
  error?: string;
};
export const AppOutlet = (props: AppOutletProps) => {
  let location = useLocation();
  const currentUser = useContext(userContext);
  const currentUserId = currentUser.id;

  useEffect(() => {
    // Google Analytics
    if (GA_PROPERTY_ID) {
      try {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
        });
      } catch (e) {
        // not sure what to do here
      }
    }
  }, [location]);

  return (
    <MainScreenContainer>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "sticky",
            flex: 1,
          }}
        >
          <PicklersNavbar userLoggedIn={!!currentUserId} error={props.error} />
        </div>

        <Outlet />
        <div
          style={{
            position: "sticky",
          }}
        >
          <SupportSidebar />
        </div>
      </div>
    </MainScreenContainer>
  );
};
