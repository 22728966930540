import React from "react";
import { COLORS } from "../../styles/colors";
import TouchableOpacity from "../general/TouchableOpacity";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../styles/spacing";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextRegular, TextSmall } from "../../styles/text";
import { Icon } from "../../styles/icons";
import IonIcon from "@reacticons/ionicons";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { NewActivityIndicator } from "../general/NewActivityIndicator";
import { ItemVerticalSpace } from "../spacing/ItemVerticalSpace";
import { NormalText } from "../text/NormalText";
import { ItemHorizontalSpace } from "../spacing/ItemHorizontalSpace";

type ClickableRowProps = {
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  title: string;
  subTitle?: string;
  notice?: string;
  onPress: () => void;
  color?: string;
};

export const ClickableRow = (props: ClickableRowProps) => {
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const onClick = () => {
    if (navigation.state !== "loading") onPress();
  };
  const { title, iconName, notice, onPress, color } = props;
  return (
    <HorizontalLeftAlignedContainer
      style={{
        flex: 1,
        width: "100%",
        borderTopWidth: 1,
        borderTopColor: colors.LIGHT_GRAY,
        borderTopStyle: "solid",
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
      }}
    >
      <TouchableOpacity
        onClick={navigation.state !== "loading" ? onClick : undefined}
        style={{
          width: "100%",
          backgroundColor: colors.WHITE,
          borderWidth: 0,
        }}
      >
        <HorizontalSpaceBetweenContainer>
          <HorizontalLeftAlignedContainer>
            {iconName && navigation.state !== "loading" && (
              <>
                <Icon
                  name={iconName}
                  size={24}
                  color={color ? color : colors.PRIMARY_TEXT_COLOR}
                />
                <ItemHorizontalSpace />
              </>
            )}
            <NormalText color={color ? color : colors.PRIMARY_TEXT_COLOR}>
              {title}
            </NormalText>
          </HorizontalLeftAlignedContainer>
          <HorizontalRightAlignedContainer>
            {notice && (
              <div>
                <NewActivityIndicator />
              </div>
            )}
            <Icon name="chevron-forward" color={colors.SECONDARY_TEXT} />
          </HorizontalRightAlignedContainer>
        </HorizontalSpaceBetweenContainer>
      </TouchableOpacity>
    </HorizontalLeftAlignedContainer>
  );
};
