import React, { useContext, useEffect } from "react";
import { redirect, useActionData, useNavigate } from "react-router-dom";

import { userContext } from "../../UserContext";
import {
  patchProfile,
  profileQuery,
  uploadProfileImageFile,
} from "../../hooks/queryProfiles";

import { ProfileUpdate } from "../../types";

import { QueryClient, useQuery } from "react-query";

import { fdGetImage, fdGetNumber, fdGetString } from "../../utils/formUtils";
import { EditProfileScreenLayout } from "./EditProfileScreenLayout";
import { ScreenContainer } from "../../features/ScreenContainer";
import { goToProfile } from "../../utils/navUtil";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";
import { guessTimeZone } from "../../utils";
import { logSentryError } from "../../utils/sentryUtil";

export type ProfileErrors = {
  generic?: string;
  firstName?: string;
  lastName?: string;
  returnToProfile?: boolean;
  ok?: boolean;
};

const validateProfileInfo = (profileData: ProfileUpdate) => {
  const err: ProfileErrors = {};

  if (profileData.firstName && profileData.firstName.length < 1) {
    err.firstName = "First name must have at least 1 character";
  }
  if (profileData.lastName && profileData.lastName.length < 1) {
    err.lastName = "Last name must have at least 1 character";
  }
  return err;
};

export type EditProfileParams = {
  userId?: string;
};

const extractNewProfileData = (data: FormData) => {
  return {
    firstName: fdGetString(data, "firstname"),
    lastName: fdGetString(data, "lastname"),
    skillLevel: fdGetString(data, "skilllevel"),
    address: fdGetString(data, "profileaddress"),
    displayAddress: fdGetString(data, "profiledisplayaddress"),
    latitude: fdGetNumber(data, "profilelatitude"),
    longitude: fdGetNumber(data, "profilelongitude"),
  } as ProfileUpdate;
};

export const editProfileLoader = async (
  params: EditProfileParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    if (currentUserId === params.userId) {
      const query = profileQuery(currentUserId, params.userId);
      queryClient.getQueryData(query.queryKey) ??
        (await queryClient.fetchQuery(query));
    }
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const editProfileAction =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: {
    params: EditProfileParams;
    request: Request;
  }) => {
    if (!params.userId) return { ok: false };
    const defaultErrorMessage =
      "There was an error updating your user profile!";
    try {
      const actionData = await request.formData();
      const profileData = extractNewProfileData(actionData);
      const newProfileImage = await fdGetImage(actionData, "profileimage");

      const validateResult = validateProfileInfo(profileData);
      if (Object.keys(validateResult).length) {
        return { ok: false, generic: defaultErrorMessage, ...validateResult };
      }
      // update this profile
      await patchProfile(params.userId, profileData);
      // update the image if one was selected
      if (newProfileImage) {
        await uploadProfileImageFile(params.userId, newProfileImage);
      }
      // update our user context?

      // invalidate our users query so our profile page updates
      await queryClient.invalidateQueries(["users"]);

      // go back to our profile screen
      // return redirect("/profile/" + params.userId);
      return { ok: true, returnToProfile: true };
    } catch (e: any) {
      if (e.code === "NotSupportedError") {
        return {
          ok: false,
          generic:
            "Your browser does not support this type of file upload.  Check your settings, upgrade your browser, or use the mobile app to upload this image.",
        };
      }
      logSentryError("Error sending a group or event message.", e, {
        tags: {
          userId: params.userId,
          screen: "EditProfileScreen",
          function: "editProfileAction",
        },
        level: "error",
      });
      return { ok: false, generic: defaultErrorMessage };
    }
  };

export const EditProfileScreen = () => {
  const errors = useActionData() as ProfileErrors;
  const navigate = useNavigate();
  const currentUserInfo = useContext(userContext);
  const profileQueryResult = useQuery(
    profileQuery(currentUserInfo.id, currentUserInfo.id)
  );

  useEffect(() => {
    if (profileQueryResult.isSuccess && profileQueryResult.data) {
      if (errors && errors.returnToProfile) {
        // we completed our profile edit, save new profile data
        // to user context and return to profile page
        if (currentUserInfo.switchUser && currentUserInfo.user) {
          currentUserInfo.switchUser(
            currentUserInfo.id,
            currentUserInfo.username,
            profileQueryResult.data.firstName,
            profileQueryResult.data.lastName,
            profileQueryResult.data.imageUri,
            currentUserInfo.adminLevel,
            profileQueryResult.data.address,
            profileQueryResult.data.displayAddress,
            profileQueryResult.data.latitude
              ? profileQueryResult.data.latitude
              : defaultLatitude,
            profileQueryResult.data.longitude
              ? profileQueryResult.data.longitude
              : defaultLongitude,
            profileQueryResult && profileQueryResult.data.timeZone
              ? profileQueryResult.data.timeZone
              : guessTimeZone(),
            currentUserInfo.user,
            currentUserInfo.hasUnread
          );
        }
        goToProfile(navigate, currentUserInfo.id, true);
      } else {
        // we are here to edit, set the document title
        document.title =
          "Edit User Profile: " +
          profileQueryResult.data.firstName +
          " " +
          profileQueryResult.data.lastName;
      }
    }
  }, [profileQueryResult]);

  return (
    <ScreenContainer horizontalPadding={0}>
      <EditProfileScreenLayout
        profileQueryResult={profileQueryResult}
        errors={errors}
      />
      ;
    </ScreenContainer>
  );
};
