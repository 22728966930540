import { useNavigate } from "react-router-dom";
import { PersonListItem } from "../../../components/people/PersonListItem";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import {
  HorizontalSpaceBetweenContainer,
  HorizontalRightAlignedContainer,
} from "../../../styles/container";
import { TextRegular } from "../../../styles/text";
import { PeopleData } from "../../../types";
import { goToReport } from "../../../utils/navUtil";
import { userContext } from "../../../UserContext";
import { useContext } from "react";

type HostListItemProps = {
  host: PeopleData;
  groupId: string;
  eventId: string;
};

export const HostListItem = (props: HostListItemProps) => {
  const { host, groupId, eventId } = props;
  const userInfo = useContext(userContext);
  const currentUserId = userInfo.id;
  const hostActionButtons: ContextMenuItem[] = [];
  const navigate = useNavigate();
  const doMessage = () => {
    const queryString = `?userId=${host.userId}&firstName=${host.firstName}&lastName=${host.lastName}`;
    navigate("/composemessage/dm/" + queryString);
  };
  const doReport = () => {
    const returnUri = `/event/view/${groupId}/${eventId}/`;

    const encodedFullName = encodeURIComponent(
      `${host.firstName} ${host.lastName}`
    );
    const reportQueryString = `?targetType=attendee&targetId=${host.userId}&targetName=${encodedFullName}&returnUri=${returnUri}`;
    goToReport(navigate, reportQueryString);
  };

  if (host.userId !== currentUserId) {
    hostActionButtons.push({
      label: <TextRegular>Message This Host</TextRegular>,
      value: "message",
      action: doMessage,
    });
  }

  hostActionButtons.push({
    label: <TextRegular>Report This Host</TextRegular>,
    value: "report",
    action: doReport,
  });

  return (
    <HorizontalSpaceBetweenContainer>
      <PersonListItem
        firstName={host.firstName}
        lastName={host.lastName}
        imageUri={host.imageUri}
        thumbnailSize={32}
        role={""}
      />
      <HorizontalRightAlignedContainer>
        <NewCustomContextMenu contextMenuItems={hostActionButtons} />
      </HorizontalRightAlignedContainer>
    </HorizontalSpaceBetweenContainer>
  );
};
