export const DARK_PRIMARY_TEXT_COLOR = "#18042C";
export const DARK_SECONDARY_TEXT = "#78787A";
export const LIGHT_PRIMARY_TEXT = "#FFFFFF";
export const LIGHT_SECONDARY_TEXT = "#FFFFFFAA";

export const PRIMARY_BRAND = "#3DA2FF";
export const PALE_BRAND = "#EEF6FE";

export const PALE_GRAY = "#F4F4F4";
export const LIGHT_GRAY = "#ECECEC";
export const MEDIUM_GRAY = "#BCBCBE";

const SURFACE = "#F8F8F8";

const PRIMARY_GREEN = "#25CB61";
export const PALE_GREEN = "#E7F4E5";
export const DARK_GREEN = "#17773D";

const PRIMARY_YELLOW = "#FBC334";
export const PALE_YELLOW = "#FFF4CF";
export const DARK_YELLOW = "#CF8D0D";

export const PRIMARY_RED = "#E82424";
export const PALE_RED = "#FFE7E7";
export const DARK_RED = "#A40303";

export const WHITE = "#FFFFFF";
export const TRANSPARENT = "#FFFFFF00";

export const COLORS = {
  light: {
    PRIMARY_TEXT_COLOR: DARK_PRIMARY_TEXT_COLOR,
    SECONDARY_TEXT: DARK_SECONDARY_TEXT,
    CONTRAST_PRIMARY_TEXT: LIGHT_PRIMARY_TEXT,
    CONTRAST_SECONDARY_TEXT: LIGHT_SECONDARY_TEXT,
    PRIMARY_BRAND: PRIMARY_BRAND,
    PALE_BRAND: PALE_BRAND,
    PALE_GRAY: PALE_GRAY,
    SURFACE: SURFACE,
    LIGHT_GRAY: LIGHT_GRAY,
    MEDIUM_GRAY: MEDIUM_GRAY,
    PRIMARY_GREEN: PRIMARY_GREEN,
    PRIMARY_YELLOW: PRIMARY_YELLOW,
    PRIMARY_RED: PRIMARY_RED,
    WHITE: WHITE,
    BACKGROUND: WHITE,
    TRANSPARENT: TRANSPARENT,
  },
  dark: {
    PRIMARY_TEXT_COLOR: DARK_PRIMARY_TEXT_COLOR,
    SECONDARY_TEXT: DARK_SECONDARY_TEXT,
    CONTRAST_PRIMARY_TEXT: LIGHT_PRIMARY_TEXT,
    CONTRAST_SECONDARY_TEXT: LIGHT_SECONDARY_TEXT,
    PRIMARY_BRAND: PRIMARY_BRAND,
    PALE_BRAND: PALE_BRAND,
    PALE_GRAY: PALE_GRAY,
    SURFACE: SURFACE,
    LIGHT_GRAY: LIGHT_GRAY,
    MEDIUM_GRAY: MEDIUM_GRAY,
    PRIMARY_GREEN: PRIMARY_GREEN,
    PRIMARY_YELLOW: PRIMARY_YELLOW,
    PRIMARY_RED: PRIMARY_RED,
    WHITE: WHITE,
    BACKGROUND: WHITE,
    TRANSPARENT: TRANSPARENT,
  },
};
