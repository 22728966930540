import React from "react";
import { ProfileImage } from "../../components/image/ProfileImage";
import TouchableOpacity from "../../components/general/TouchableOpacity";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { getCardSize } from "../../utils";
import { blankVenueImageURL } from "../../constants/defaultImageURLs";

type VenuePickerListItemProps = {
  name: string;
  imageUri: string;
  borderRadius: number;
  handleSelection: () => void;
};
export const VenuePickerListItem = (props: VenuePickerListItemProps) => {
  const thumbnailSize = getCardSize("tiny");

  return (
    <TouchableOpacity
      onClick={() => props.handleSelection()}
      style={{
        flex: 1,
        marginRight: 40,
        marginBottom: 5,
        borderStyle: "none",
        backgroundColor: "transparent",
      }}
    >
      <HorizontalLeftAlignedContainer>
        <SizedContainer width={thumbnailSize} height={thumbnailSize}>
          <ProfileImage
            width={thumbnailSize}
            height={thumbnailSize}
            borderRadius={thumbnailSize / 4}
            imgSrc={props.imageUri ? props.imageUri : blankVenueImageURL}
          />
        </SizedContainer>
        <TextRegular style={{ marginLeft: LINE_HORIZONTAL_SPACING }}>
          {props.name}
        </TextRegular>
      </HorizontalLeftAlignedContainer>
    </TouchableOpacity>
  );
};
