import { useNavigate } from "react-router-dom";
import { PeoplePicArray } from "../../../features/PeoplePicArray";
import { COLORS } from "../../../styles/colors";
import { HorizontalContainer } from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { H2 } from "../../../styles/text";
import { EventFull, GroupFull } from "../../../types";
import { getBorderRadius } from "../../../utils";
import { goToAttendeesScreen } from "../../../utils/navUtil";
import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyPeople } from "../../../assets/images/empty-state/empty-groups.svg";
import { HostListItem } from "../components/HostListItem";

type EventDetailsPeopleProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
  showAttendees: boolean;
};

export const EventDetailsPeople = (props: EventDetailsPeopleProps) => {
  const { eventDetails, groupDetails, showAttendees } = props;
  const navigate = useNavigate();

  const colors = COLORS["light"];
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer((isOpen) => {
      return !isOpen;
    });
  };

  return (
    <HorizontalContainer
      style={{
        display: "flex",
      }}
    >
      <Drawer
        open={showDrawer}
        onClose={() => toggleDrawer()}
        direction="right"
        size={500}
        style={{ padding: 20 }}
        zIndex={2000}
      >
        <HorizontalContainer style={{ marginBottom: SECTION_VERTICAL_SPACING }}>
          <H2>Hosts</H2>
        </HorizontalContainer>
        {eventDetails.hosts.length <= 0 && (
          <EmptyLayout title="No hosts assigned!" subTitle="">
            <EmptyPeople />
          </EmptyLayout>
        )}
        {eventDetails.hosts.map((host, index) => (
          <div key={index}>
            <HostListItem
              host={host}
              groupId={groupDetails.id}
              eventId={eventDetails.id}
            />
            <div style={{ height: ITEM_VERTICAL_SPACING }} />
          </div>
        ))}
      </Drawer>

      <button
        onClick={() => toggleDrawer()}
        className="card-button"
        style={{
          flex: 0.5,
          padding: ITEM_HORIZONTAL_SPACING,
          marginLeft: ITEM_HORIZONTAL_SPACING,
          marginRight: ITEM_HORIZONTAL_SPACING,
        }}
      >
        <PeoplePicArray
          title="Hosts"
          flex={0.5}
          peopleInfo={eventDetails.hosts}
          showCount={false}
          showPics={true}
          count={eventDetails.hosts.length}
          displayLimit={6}
          limit={eventDetails.capacity}
        />
      </button>

      <button
        onClick={() => {
          if (showAttendees)
            goToAttendeesScreen(
              navigate,
              eventDetails.groupId,
              eventDetails.id
            );
        }}
        className="card-button"
        style={{
          flex: 1,
          padding: ITEM_HORIZONTAL_SPACING,
          marginLeft: ITEM_HORIZONTAL_SPACING,
          marginRight: ITEM_HORIZONTAL_SPACING,
        }}
      >
        <PeoplePicArray
          title="Attendees"
          flex={0.5}
          peopleInfo={eventDetails.peopleInfo}
          showCount={true}
          showPics={showAttendees}
          count={showAttendees ? eventDetails.attendeeList.length : 0}
          displayLimit={15}
          limit={eventDetails.capacity}
        />
      </button>
    </HorizontalContainer>
  );
};
