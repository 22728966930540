import React from "react";
import { QueryClient } from "react-query";
import { Route } from "react-router-dom";
import { GroupDetailsError } from "../screens/GroupDetails/GroupDetailsError";
import {
  GroupDetailsScreen,
  groupDetailsLoader,
  GroupDetailsParams,
  groupContextAction,
  memberContextAction,
  memberJoinAction,
  groupDetailsAction,
  deletePhotoAction,
  unpinAction,
} from "../screens/GroupDetails/GroupDetailsScreen";
// import {
//   GroupDiscussionScreen,
//   groupDiscussionLoader,
//   GroupDiscussionParams,
// } from "../screens/GroupDiscussion/GroupDiscussionScreen";
import { EditGroupError } from "../screens/GroupEdit/EditGroupError";
import {
  EditGroupScreen,
  editGroupLoader,
  EditGroupParams,
  editGroupAction,
} from "../screens/GroupEdit/EditGroupScreen";
import { GroupsError } from "../screens/Groups/GroupsError";
import { GroupsScreen, groupsLoader } from "../screens/Groups/GroupsScreen";

type GroupRoutesProps = {
  latitude: number;
  longitude: number;
  currentUserId: string;
  queryClient: QueryClient;
};
export const useGroupRoutes = (props: GroupRoutesProps) => {
  const { queryClient, currentUserId, latitude, longitude } = props;
  return (
    <>
      <Route
        path="groups"
        Component={GroupsScreen}
        errorElement={<GroupsError />}
        loader={({ params, request }) => {
          return groupsLoader(
            request,
            queryClient,
            currentUserId,
            latitude,
            longitude
          );
        }}
      />
      <Route
        path="group/view/:groupId"
        Component={GroupDetailsScreen}
        errorElement={<GroupDetailsError />}
        loader={({ params, request }) => {
          return groupDetailsLoader(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={({ params, request }) => {
          return groupDetailsAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="group/view/:groupId/deletePhotoAction"
        action={({ params, request }) => {
          return deletePhotoAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="group/view/:groupId/groupContextAction"
        action={({ params, request }) => {
          return groupContextAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="group/view/:groupId/memberContextAction"
        action={({ params, request }) => {
          return memberContextAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="group/view/:groupId/memberJoinAction"
        action={({ params, request }) => {
          return memberJoinAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="group/view/:groupId/unpin"
        action={({ params, request }) => {
          return unpinAction(
            params as GroupDetailsParams,
            request,
            queryClient,
            currentUserId
          );
        }}
      />
      <Route
        path="groups/:editTask/:groupId?"
        Component={EditGroupScreen}
        errorElement={<EditGroupError />}
        loader={({ params, request }) => {
          return editGroupLoader(
            params as EditGroupParams,
            request,
            queryClient,
            currentUserId
          );
        }}
        action={({ params, request }) => {
          return editGroupAction(params, request, queryClient, currentUserId);
        }}
      />
    </>
  );
};
