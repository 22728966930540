import React from "react";
import { HorizontalContainer } from "../../styles/container";
import { H1, TextRegular } from "../../styles/text";
import { ForceNewPasswordErrors } from "./ForceNewPasswordScreen";
import { useState } from "react";
import { COLORS } from "../../styles/colors";
import { Form, useNavigation } from "react-router-dom";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { ActionBar } from "../../features/actionBars/ActionBar";
import { BeatLoader } from "react-spinners";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { TitledInput } from "../../components/inputs/TitledInput";

type ForceNewPasswordScreenLayoutProps = {
  errors: ForceNewPasswordErrors;
  userJSON: string;
};

export const ForceNewPasswordScreenLayout = (
  props: ForceNewPasswordScreenLayoutProps
) => {
  const { errors, userJSON } = props;
  const navigation = useNavigation();

  const colors = COLORS["light"];

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const isBusy = () => {
    return navigation.state !== "idle";
  };

  return (
    <ScreenContainer horizontalPadding={0}>
      <div
        style={{
          flex: 1,
        }}
      >
        <StickyActionBar>
          <DetailsActionButtonBar backButtonTitle="Back" />
        </StickyActionBar>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form action="/forceNewPassword" method="POST" style={{ width: 500 }}>
          <HorizontalContainer
            style={{
              marginTop: SECTION_VERTICAL_SPACING,
              marginBottom: SECTION_VERTICAL_SPACING,
            }}
          >
            <H1>New Password Required!</H1>
          </HorizontalContainer>

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            {/* <input
              type="password"
              name={password}
              value={password}
              placeholder="New Password (Requires u/l case, number, symbol)"
              onChange={(e) => setPassword(e.target.value)}
              style={{
                paddingLeft: LINE_HORIZONTAL_SPACING,
                paddingRight: LINE_HORIZONTAL_SPACING,
              }}
            /> */}
            <TitledInput
              title="Password"
              formDataName={"password"}
              value={password}
              setText={setPassword}
              required={true}
              editable={true}
              placeholderText="New Password (Requires u/l case, number, symbol)"
              maxLength={16}
              password={true}
              autoComplete="new-password"
            />
          </HorizontalContainer>
          {errors?.password && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextRegular color="red">{errors.password}</TextRegular>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Retype Password"
              formDataName={"password2"}
              value={password2}
              setText={setPassword2}
              required={true}
              editable={true}
              placeholderText="Retype new password"
              maxLength={16}
              password={true}
            />
          </HorizontalContainer>
          {errors?.password2 && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextRegular color="red">{errors.password2}</TextRegular>
            </HorizontalContainer>
          )}
          <input type="hidden" name="userJSON" value={userJSON} />
          <HorizontalContainer>
            <SubmitButton loading={isBusy()} title="Submit" />
          </HorizontalContainer>
          {errors?.other && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextRegular color="red">{errors.other}</TextRegular>
            </HorizontalContainer>
          )}
          {isBusy() && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <BeatLoader
                color={colors.PRIMARY_BRAND}
                loading={true}
                size={14}
              />
            </HorizontalContainer>
          )}
        </Form>
      </div>
    </ScreenContainer>
  );
};
