import { QueryClient, useQuery } from "react-query";
import { eventDetailsQuery, saveRepeatedEvent } from "../../hooks/queryEvents";
import { fdGetNumber, fdGetString } from "../../utils/formUtils";
import { EventRepeat } from "../../types";
import { redirect, useActionData, useParams } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { userContext } from "../../UserContext";
import { ScreenContainer } from "../../features/ScreenContainer";
import { EventRepeatLayout } from "./EventRepeatLayout";
import { getDateInXDays } from "../../utils";
import { logSentryError } from "../../utils/sentryUtil";

export type RepeatEventParams = {
  eventId?: string;
};

export type RepeatEventErrors = {
  dates?: string;
  rsvpOpens?: string;
  fixedRsvpOpens?: string;
  generic?: string;
  ok?: boolean;
};

const extractRepeatEventData = (data: FormData) => {
  const startDateNum = fdGetNumber(data, "startdate");
  const endDateNum = fdGetNumber(data, "enddate");
  const frequency = fdGetNumber(data, "frequency");

  const startDate = new Date(startDateNum);
  const endDate = new Date(endDateNum);

  const rsvpOpens = fdGetNumber(data, "rsvpopens");
  const rsvpOpensTime = fdGetString(data, "rsvpopenstime");
  const fixedRsvpOpensDate = fdGetNumber(data, "fixedrsvpopens");

  const dates: number[] = [];
  const rsvpOpensDates: number[] = [];
  let newDate = new Date(startDate);
  let newRsvpOpensDate =
    rsvpOpens < 0 ? new Date(fixedRsvpOpensDate) : new Date();
  if (rsvpOpens > 0) {
    newRsvpOpensDate = getDateInXDays(newDate, -rsvpOpens);
    if (rsvpOpensTime) {
      const arr = rsvpOpensTime.split(":");
      newRsvpOpensDate.setHours(parseInt(arr[0]), parseInt(arr[1]));
    }
  }
  do {
    dates.push(newDate.getTime());
    rsvpOpensDates.push(newRsvpOpensDate.getTime());

    newDate = getDateInXDays(newDate, frequency);
    if (rsvpOpens > 0)
      newRsvpOpensDate = getDateInXDays(newRsvpOpensDate, frequency);
  } while (newDate < endDate);
  return {
    eventId: fdGetString(data, "eventid"),
    dates: dates,
    rsvpOpensDates: rsvpOpensDates,
  } as EventRepeat;
};

const validateRepeatEventData = (eventRepeatData: EventRepeat) => {
  const err: RepeatEventErrors = {};

  if (eventRepeatData.dates.length < 1) {
    err.dates = "No dates selected.";
  }
  if (eventRepeatData.dates.length !== eventRepeatData.rsvpOpensDates.length) {
    err.rsvpOpens = "Bad RSVP Opens Dates.";
  }
  if (eventRepeatData.dates[0] < eventRepeatData.rsvpOpensDates[0]) {
    err.dates = "Start date is after RSVP Open date";
  }
  return err;
};

export const repeatEventLoader = async (
  params: RepeatEventParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query1 = eventDetailsQuery(
      !!currentUserId && !!params?.eventId,
      params?.eventId
    );

    queryClient.getQueryData(query1.queryKey) ??
      (await queryClient.fetchQuery(query1));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }

  return { status: "ok" };
};

export const repeatEventAction = async (
  params: RepeatEventParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const eventId = params && params.eventId ? params.eventId : "";
  const data = await request.formData();
  const defaultErrorMessage =
    "There was an error setting up this repeating event.";

  try {
    const repeatEventData = extractRepeatEventData(data);
    const groupId = fdGetString(data, "groupid");
    const validateResult = validateRepeatEventData(repeatEventData);
    if (Object.keys(validateResult).length) {
      return { ok: false, generic: defaultErrorMessage, ...validateResult };
    }
    await saveRepeatedEvent(repeatEventData);
    queryClient.invalidateQueries(["events"]);
    return redirect("/group/view/" + groupId);
  } catch (e: any) {
    logSentryError("Error Saving Recurring Event.", e, {
      tags: {
        userId: currentUserId,
        eventId: params?.eventId,
        screen: "RepeatEventScreen",
        function: "repeatEventAction",
      },
      level: "error",
    });
    return { generic: defaultErrorMessage, ok: false };
  }
};

export const RepeatEventScreen = () => {
  const params = useParams();
  const userInfo = useContext(userContext);
  const eventId = params && params.eventId ? params.eventId : "";
  const errors = useActionData() as RepeatEventErrors;
  const { data: eventDetails } = useQuery(
    eventDetailsQuery(!!userInfo.id && !!eventId, eventId)
  );

  useEffect(() => {
    if (eventDetails)
      document.title = "Set Up Recurring Event: " + eventDetails.title;
  }, [eventDetails]);

  return (
    <>
      <ScreenContainer horizontalPadding={0}>
        <EventRepeatLayout eventDetails={eventDetails} errors={errors} />
      </ScreenContainer>
    </>
  );
};
