import React, { useContext } from "react";
import { userContext } from "../../../UserContext";
import { useGroupThreads } from "../../../hooks/queryMessages";
import { GroupThreadsSection } from "../Sections/GroupThreadsSection";
import { useWindowHeight } from "@react-hook/window-size";
import { HEADER_VERTICAL_SPACING } from "../../../styles/spacing";
import { ThreadFull, defaultThreadDetails } from "../../../types";
import { useNavigation } from "react-router-dom";
import { UseQueryResult } from "react-query";

export type GroupActivityTabProps = {
  groupThreadsResult: UseQueryResult<ThreadFull[]>;
};
export const GroupActivityTab = (props: GroupActivityTabProps) => {
  const { groupThreadsResult } = props;
  const navigation = useNavigation();

  const { data, isSuccess } = groupThreadsResult;

  return (
    <>
      <GroupThreadsSection
        groupThreadsData={
          navigation.state !== "loading"
            ? data
            : [
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
              ]
        }
        groupThreadsDataIsSuccess={isSuccess}
      />
    </>
  );
};
