import React, { useContext, useEffect } from "react";
import { useSearchParams, useActionData, redirect } from "react-router-dom";
import { QueryClient } from "react-query";
import { ReportCreate } from "../../types";

import { fdGetInteger, fdGetString } from "../../utils/formUtils";
import { saveNewReport } from "../../hooks/queryReports";
import { ReportIssueScreenLayout } from "./ReportIssueScreenLayout";
import { userContext } from "../../UserContext";
import { ScreenContainer } from "../../features/ScreenContainer";
import { logSentryError } from "../../utils/sentryUtil";

export type ReportIssueParams = {
  // userId?: string;
};
export type ReportErrors = {
  reportTopic?: string;
  reportDetails?: string;
  generic?: string;
  ok?: boolean;
};

const extractNewReportData = (data: FormData, userId: string) => {
  return {
    userId: userId,
    targetId: fdGetString(data, "targetid"),
    targetType: fdGetString(data, "targettype"),
    targetName: fdGetString(data, "targetname"),
    reportTopic: fdGetString(data, "reporttopic"),
    reportDetails: fdGetString(data, "reportdetails"),
    parentName: fdGetString(data, "parentname"),
    targetDate: fdGetInteger(data, "targetdate"),
  } as ReportCreate;
};

const validateReportData = (reportData: ReportCreate) => {
  const err: ReportErrors = {};

  if (!reportData.reportTopic) {
    err.reportTopic = "Please specify a topic.";
  }
  if (!reportData.reportDetails) {
    err.reportDetails = "Please give a detailed description.";
  }
  return err;
};

export const saveReportLoader = async (
  request: Request,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (!currentUserId) {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const saveReportAction =
  (queryClient: QueryClient, currentUserId: string) =>
  async ({
    params,
    request,
  }: {
    params: ReportIssueParams;
    request: Request;
  }) => {
    const data = await request.formData();
    // let userId = params.userId;
    const defaultErrorMessage = "There was an error saving this report.";
    try {
      const userId = fdGetString(data, "userid");
      const returnUri = fdGetString(data, "returnuri");
      const reportData = extractNewReportData(data, userId);
      const validateResult = validateReportData(reportData);
      if (Object.keys(validateResult).length) {
        return { ok: false, generic: defaultErrorMessage, ...validateResult };
      }

      await saveNewReport(reportData);

      queryClient.invalidateQueries([
        "reports",
        reportData.targetType as string,
        reportData.targetId,
      ]);
      alert("Thank you for contacting us! ");
      return redirect(returnUri ? decodeURI(returnUri) : "/");
    } catch (e) {
      logSentryError("Error saving an issue report.", e, {
        tags: {
          userId: currentUserId,
          screen: "ReportIssueScreen",
          function: "saveReportAction",
        },
        level: "error",
      });
      return { ok: false, generic: defaultErrorMessage };
    }
  };

export const ReportIssueScreen = () => {
  const [queryParams] = useSearchParams();
  const userInfo = useContext(userContext);
  const errors = useActionData() as ReportErrors;
  const targetType = queryParams.get("targetType");
  const targetId = queryParams.get("targetId");
  const targetName = queryParams.get("targetName");
  const parentName = queryParams.get("parentName");
  const targetDateParam = queryParams.get("targetDate");
  const returnUri = queryParams.get("returnUri");
  const targetDateNum = targetDateParam ? parseInt(targetDateParam) : NaN;
  const targetDate = isNaN(targetDateNum) ? null : targetDateNum;

  useEffect(() => {
    document.title = "Report Issue: " + targetType;
  });

  return (
    <ScreenContainer>
      <ReportIssueScreenLayout
        targetId={targetId ?? userInfo.id}
        targetType={targetType ?? "support"}
        targetName={
          targetName ? decodeURIComponent(targetName) : "General Inquiry"
        }
        targetDate={targetDate ?? undefined}
        parentName={parentName ? decodeURIComponent(parentName) : undefined}
        errors={errors}
        returnUri={returnUri ?? undefined}
      />
    </ScreenContainer>
  );
};
