import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import {
  GroupFull,
  MemberCreate,
  MemberFull,
  MemberUpdate,
  PeopleData,
} from "../types";
import { addQueryItem } from "../utils";

export const findMembership = async (groupId: string, userId: string) => {
  const { data } = await axios.get(
    BACKEND_URL + "memberships/group/" + groupId + "/user/" + userId
  );
  return data;
};

export const membershipQuery = (
  enabled: boolean,
  groupId: string,
  userId: string
) => ({
  queryKey: ["memberships", groupId, userId],
  queryFn: async () => findMembership(groupId, userId),
  enabled: enabled,
});
export const useMembership = (
  enabled: boolean,
  groupId: string,
  userId: string
): UseQueryResult<MemberFull> =>
  useQuery(membershipQuery(enabled, groupId, userId));

const fetchGroupMembersLimited = async (
  page: number,
  groupId?: string,
  status?: string
) => {
  if (!groupId) return [];

  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  if (status) {
    queryParamString = addQueryItem(queryParamString, "status", status);
  }

  const { data } = await axios.get(
    BACKEND_URL + "memberships/membersbygroup/" + groupId + queryParamString
  );
  return data;
};

export const groupMembersLimitedQuery = (
  isEnabled: boolean,
  groupId?: string,
  status?: string
) => ({
  queryKey: ["memberships-group", groupId, status],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchGroupMembersLimited(pageParam, groupId, status);
  },
  enabled: isEnabled,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: GroupFull[] | undefined,
    pages: (GroupFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useGroupMembersLimited = (
  isEnabled: boolean,
  groupId?: string,
  status?: string
): UseInfiniteQueryResult<MemberFull[]> =>
  useInfiniteQuery(groupMembersLimitedQuery(isEnabled, groupId, status));

// export const useGroupMembersLimited = (
//   isEnabled: boolean,
//   groupId?: string,
//   status?: string
// ): UseInfiniteQueryResult<MemberFull[]> =>
//   useInfiniteQuery(
//     ["memberships-group", groupId, status],
//     ({ pageParam = 1 }) => fetchGroupMembersLimited(pageParam, groupId, status),
//     {
//       getNextPageParam: (lastPage, pages) => {
//         const nextPage = pages.length + 1;
//         return nextPage;
//       },
//       enabled: isEnabled,
//     }
//   );

const fetchGroupMembersAll = async (groupId: string, status?: string) => {
  let queryParamString = "";
  if (status) {
    queryParamString = addQueryItem(queryParamString, "status", status);
  }

  const { data } = await axios.get(
    BACKEND_URL + "memberships/allmembersbygroup/" + groupId + queryParamString
  );
  return data;
};

export const groupMembersAllQuery = (
  isEnabled: boolean,
  groupId: string,
  status?: string
) => ({
  queryKey: ["memberships-group-all", groupId, status],
  queryFn: async () => fetchGroupMembersAll(groupId, status),
  enabled: isEnabled,
});

export const useGroupMembersAll = (
  isEnabled: boolean,
  groupId: string,
  status?: string
) => useQuery(groupMembersAllQuery(isEnabled, groupId, status));

const fetchUsersGroups = async (
  page: number,
  limit: number,
  userId: string,
  sortByStatus: boolean,
  status?: string
) => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", limit.toString());
  queryParamString = addQueryItem(
    queryParamString,
    "sortByStatus",
    sortByStatus ? "true" : "false"
  );
  if (status)
    queryParamString = addQueryItem(queryParamString, "status", status);
  const { data } = await axios.get(
    BACKEND_URL + "memberships/groupsbymember/" + userId + queryParamString
  );
  return data;
};

export const usersGroupsQuery = (
  isEnabled: boolean,
  userId: string,
  limit: number,
  sortByStatus: boolean,
  status?: string
) => ({
  queryKey: ["memberships-user", userId, status, sortByStatus],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchUsersGroups(pageParam, limit, userId, sortByStatus, status);
  },
  enabled: isEnabled,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: GroupFull[] | undefined,
    pages: (GroupFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useUsersGroups = (
  isEnabled: boolean,
  userId: string,
  limit: number,
  sortByStatus: boolean,
  status?: string
): UseInfiniteQueryResult<GroupFull[]> =>
  useInfiniteQuery(
    usersGroupsQuery(isEnabled, userId, limit, sortByStatus, status)
  );

const fetchUsersFriends = async (userId: string) => {
  const { data } = await axios.get(
    BACKEND_URL + "memberships/usersfriends/" + userId
  );
  return data;
};

export const usersFriendsQuery = (userId: string) => ({
  queryKey: ["friends", userId],
  queryFn: async () => {
    return fetchUsersFriends(userId);
  },
  staleTime: 5 * (60 * 1000),
});
export const useUsersFriends = (userId: string): UseQueryResult<PeopleData[]> =>
  useQuery(usersFriendsQuery(userId));

export const postGroupMembership = async (membershipData: MemberCreate) => {
  await axios.post(BACKEND_URL + "memberships/", membershipData);
  return;
};

export const processGroupMembership = async (membershipData: MemberUpdate) => {
  if (membershipData.memberId)
    await axios.patch(
      BACKEND_URL + "memberships/" + membershipData.memberId,
      membershipData
    );
  return;
};

export const deleteGroupMembership = async (membershipId: string) => {
  await axios.delete(BACKEND_URL + "memberships/" + membershipId);
  return;
};
