import React, { useContext, useEffect } from "react";
import { redirect, useActionData, useParams } from "react-router-dom";
import { eventDetailsQuery, useEvent } from "../../hooks/queryEvents";
import { groupDetailsQuery, useGroup } from "../../hooks/queryGroups";
import { AttendeesScreenLayout } from "./AttendeesScreenLayout";
import { fdGetBoolean, fdGetString } from "../../utils/formUtils";
import {
  addRSVP,
  rsvpsQuery,
  setAttendedFlag,
  setAttendeeStatus,
  setPaidFlag,
  useRSVPs,
} from "../../hooks/queryAttendees";
import { QueryClient } from "react-query";
import { userContext } from "../../UserContext";
import { ScreenContainer } from "../../features/ScreenContainer";
import { AttendeeCreate } from "../../types";
import { logSentryError } from "../../utils/sentryUtil";

export type AttendeeErrors = {
  generic?: string;
  message?: string;
  ok?: boolean;
};

export type AttendeesParams = {
  eventId: string;
  groupId: string;
};

export const attendeesLoader = async (
  params: AttendeesParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query1 = eventDetailsQuery(
      !!currentUserId && !!params?.eventId,
      params?.eventId
    );
    queryClient.getQueryData(query1.queryKey) ??
      (await queryClient.fetchQuery(query1));

    const query2 = groupDetailsQuery(currentUserId, params?.groupId);
    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchQuery(query2));

    const query3 = rsvpsQuery(params?.eventId, "going");
    queryClient.getQueryData(query3.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query3));

    const query4 = rsvpsQuery(params?.eventId, "waitlist");
    queryClient.getQueryData(query4.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query4));

    const query5 = rsvpsQuery(params?.eventId, "notgoing");
    queryClient.getQueryData(query5.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query5));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const attendeesContextAction = async (
  params: AttendeesParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const { eventId } = params;
  const defaultErrorMessage =
    "There was an error modifying this person's RSVP information.";

  try {
    const data = await request.formData();
    const contextAction = fdGetString(data, "contextAction");
    const contextValue = fdGetBoolean(data, "contextValue");
    const rsvpId = fdGetString(data, "auxId");

    switch (contextAction) {
      case "moveToGoing":
        await setAttendeeStatus({ attendeeId: rsvpId, status: "going" });
        break;
      case "moveToWaitlist":
        await setAttendeeStatus({ attendeeId: rsvpId, status: "waitlist" });
        break;
      case "moveToNotGoing":
        await setAttendeeStatus({ attendeeId: rsvpId, status: "notgoing" });
        break;
      case "setPaid":
        await setPaidFlag(rsvpId, contextValue);
        break;
      case "setAttended":
        await setAttendedFlag(rsvpId, contextValue);
        break;
      case "addAttendee":
        const attendeeCreate: AttendeeCreate = {
          userId: rsvpId,
          eventId: eventId,
        };
        await addRSVP(attendeeCreate);
        break;
      default:
        console.log("unknown contextAction: ", contextAction);
        break;
    }
    await queryClient.invalidateQueries(["rsvps"]);
    await queryClient.invalidateQueries({ queryKey: ["event", eventId] });
    await queryClient.invalidateQueries(["attendees", eventId]);
    await queryClient.invalidateQueries(["dmThreads"]);
    await queryClient.invalidateQueries(["messages", "dm"]);

    return { ok: true };
  } catch (e: any) {
    logSentryError("Error modifying attendee info.", e, {
      tags: {
        userId: currentUserId,
        eventId: params?.eventId,
        groupId: params?.groupId,
        screen: "AttendeesScreen",
        function: "attendeesContextAction",
      },
      level: "error",
    });
    return { ok: false, generic: defaultErrorMessage };
  }
};

export const AttendeesScreen = () => {
  const params = useParams();
  const errors = useActionData() as AttendeeErrors;

  // const actionData: any = useActionData();
  const userInfo = useContext(userContext);
  const eventId = params && params.eventId ? params.eventId : "";
  const groupId = params && params.groupId ? params.groupId : "";

  // these queries have already fired in the loader, just getting the result here
  const eventQueryResult = useEvent(!!userInfo.id && !!eventId, eventId);
  const groupQueryResult = useGroup(userInfo.id, groupId);
  const goingQueryResult = useRSVPs(eventId, "going");
  const notGoingQueryResult = useRSVPs(eventId, "notgoing");
  const waitlistQueryResult = useRSVPs(eventId, "waitlist");

  useEffect(() => {
    if (eventQueryResult.data) {
      document.title = "Attendees: " + eventQueryResult.data.title;
    }
  }, [eventQueryResult]);

  return (
    <>
      <ScreenContainer horizontalPadding={0}>
        {groupQueryResult.isSuccess && eventQueryResult.isSuccess && (
          <AttendeesScreenLayout
            groupQueryResult={groupQueryResult}
            eventQueryResult={eventQueryResult}
            goingQueryResult={goingQueryResult}
            notGoingQueryResult={notGoingQueryResult}
            waitlistQueryResult={waitlistQueryResult}
            errors={errors}
          />
        )}
      </ScreenContainer>
    </>
  );
};
