import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EventFull, VenueFull } from "../../../types";
import { EventType } from "../../../types/enums";
import { enumValues } from "../../../types/utils";
import { ImagePicker } from "../../../components/image/ImagePicker";
import { SkillRangeDropDowns } from "../../../components/inputs/SkillRangeDropdowns";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../../components/inputs/TitledDropdown";
import { TitledInput } from "../../../components/inputs/TitledInput";
import { blankEventImageURL } from "../../../constants/defaultImageURLs";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { TextRegular } from "../../../styles/text";
import { TitledTextarea } from "../../../components/inputs/TitledTextarea";
import { TitledEmojiPicker } from "../../../features/TitledEmojiPicker/TitledEmojiPicker";
import { EventErrors } from "../EditEventScreen";

type EditEventGeneralInfoProps = {
  eventDetails: EventFull;
  selectedVenue: VenueFull;
  errors?: EventErrors;
};

export const EditEventGeneralInfo = (props: EditEventGeneralInfoProps) => {
  const { eventDetails, selectedVenue, errors } = props;
  const [eventName, setEventName] = useState(eventDetails.title);
  const [eventEmoji, setEventEmoji] = useState(eventDetails.emoji);
  const [eventAbout, setEventAbout] = useState(eventDetails.description);

  const [eventSkillMin, setEventSkillMin] = useState(
    eventDetails.minSkillLevel
  );
  const [eventSkillMax, setEventSkillMax] = useState(
    eventDetails.maxSkillLevel
  );
  const [eventImage, setEventImage] = useState(eventDetails.imageUri);

  // Event Type
  const [eventTypeValue, setEventTypeValue] = useState<DropdownValueType>(
    eventDetails.eventType
  );

  const eventTypeFilterValues = enumValues(EventType);
  const eventTypeItems = eventTypeFilterValues.map((value) => {
    return { label: value, value: value };
  });

  useEffect(() => {
    setEventName(eventDetails.title);
    setEventEmoji(eventDetails.emoji);
    setEventAbout(eventDetails.description);
    setEventSkillMin(eventDetails.minSkillLevel);
    setEventSkillMax(eventDetails.maxSkillLevel);
    setEventImage(eventDetails.imageUri);
    setEventTypeValue(eventDetails.eventType);
  }, [eventDetails]);

  return (
    <>
      <HorizontalLeftAlignedContainer>
        <ImagePicker
          imgSrc={
            eventImage
              ? eventImage
              : selectedVenue && selectedVenue.imageUri
              ? selectedVenue.imageUri
              : eventDetails.venueImageUri
              ? eventDetails.venueImageUri
              : blankEventImageURL
          }
          formDataName="eventimage"
          setImgSrc={setEventImage}
          width={300}
          height={225}
          borderRadius={0}
        />
      </HorizontalLeftAlignedContainer>
      {/* 
      <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
        <TitledEmojiPicker
          title="EMOJI"
          formDataName="eventemoji"
          emoji={eventEmoji}
          setEmoji={setEventEmoji}
        />
      </HorizontalContainer> */}

      <HorizontalLeftAlignedContainer
        style={{
          marginTop: ITEM_VERTICAL_SPACING,
        }}
      >
        <TitledInput
          value={eventName}
          formDataName="eventtitle"
          title="TITLE"
          setText={setEventName}
          placeholderText="Name your event"
          maxLength={128}
          required={true}
          editable={true}
        />
      </HorizontalLeftAlignedContainer>
      {errors?.title && (
        <HorizontalLeftAlignedContainer>
          <TextRegular color="red">{errors?.title}</TextRegular>
        </HorizontalLeftAlignedContainer>
      )}

      <HorizontalSpaceBetweenContainer>
        <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
          <TitledDropdown
            title="EVENT TYPE"
            formDataName="eventtypevalue"
            value={eventTypeValue}
            setValue={setEventTypeValue}
            items={eventTypeItems}
          />
        </HorizontalContainer>
        <HorizontalContainer style={{ width: ITEM_HORIZONTAL_SPACING }} />
        <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
          <SkillRangeDropDowns
            title="SKILL LEVELS"
            formDataMinName="eventskillmin"
            formDataMaxName="eventskillmax"
            skillMinValue={eventSkillMin}
            skillMaxValue={eventSkillMax}
            setSkillMinValue={setEventSkillMin}
            setSkillMaxValue={setEventSkillMax}
          />
        </HorizontalContainer>
      </HorizontalSpaceBetweenContainer>
      <HorizontalLeftAlignedContainer
        style={{ paddingTop: ITEM_VERTICAL_SPACING }}
      >
        <TitledTextarea
          value={eventAbout}
          formDataName="eventdescription"
          title="DESCRIPTION"
          setText={setEventAbout}
          placeholderText="Event details your attendees should know."
          maxLength={10000}
        />
      </HorizontalLeftAlignedContainer>
    </>
  );
};
