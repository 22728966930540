import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { FilterButton } from "../../components/buttons/FilterButton";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { NavButton } from "../../components/buttons/NavButton";
import { COLORS } from "../../styles/colors";
import { useNavigation } from "react-router-dom";

type KeywordFilterButtonProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

export const KeywordFilterButton = (props: KeywordFilterButtonProps) => {
  const { setValue, isOpen, setIsOpen } = props;
  const [localValue, setLocalValue] = useState("");
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const handleClick = () => {
    if (!isOpen) {
      setValue("");
      setLocalValue("");
      setIsOpen(true);
    } else {
      if (localValue) {
        setValue(localValue);
      } else {
        setValue("");
        setIsOpen(false);
      }
    }
  };

  const handleCancel = () => {
    setValue("");
    setLocalValue("");
    setIsOpen(false);
  };

  return (
    <HorizontalRightAlignedContainer
      // style={{ marginRight: ITEM_HORIZONTAL_SPACING }}
      style={{ flex: isOpen ? 1 : 0 }}
    >
      {isOpen && (
        <HorizontalRightAlignedContainer style={{ flex: 1 }}>
          <NavButton
            onClick={handleCancel}
            iconName="chevron-forward"
            loading={navigation.state === "loading"}
          />
          <input
            type="text"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={{
              marginLeft: LINE_HORIZONTAL_SPACING,
              marginRight: LINE_HORIZONTAL_SPACING,
              outline: "none",
              paddingLeft: LINE_HORIZONTAL_SPACING,
              paddingRight: LINE_HORIZONTAL_SPACING,
              borderColor: colors.LIGHT_GRAY,
            }}
            maxLength={64}
          />
        </HorizontalRightAlignedContainer>
      )}
      <NavButton
        onClick={handleClick}
        iconName="search"
        loading={navigation.state === "loading"}
      />
    </HorizontalRightAlignedContainer>
  );
};
