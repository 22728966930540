import React, { useRef } from "react";
import { GroupFull, PhotoFull } from "../../../types";
import { usePhotos } from "../../../hooks/queryPhotos";
import { H2, TextRegular } from "../../../styles/text";
import {
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../../styles/container";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import {
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { NavButton } from "../../../components/buttons/NavButton";
import { Form, useFetcher, useSubmit } from "react-router-dom";
import { COLORS } from "../../../styles/colors";
import { OverlayButton } from "../../../components/buttons/OverlayButton";
import { getBorderRadius } from "../../../utils";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyPhotos } from "../../../assets/images/empty-state/empty-general.svg";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";

type GroupPhotosSectionProps = {
  groupDetails: GroupFull;
  onImagePress: (imageUri: string) => void;
  isOrganizer: boolean;
};

export const GroupPhotosSection = (props: GroupPhotosSectionProps) => {
  const { groupDetails, onImagePress, isOrganizer } = props;
  const { data, isSuccess, isLoading, isError } = usePhotos(
    !!groupDetails.id,
    groupDetails.id
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const photoSubmit = useSubmit();
  const fetcher = useFetcher();
  const colors = COLORS["light"];
  const onFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    photoSubmit(event.currentTarget);
  };
  const doPhotoAdd = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const doPhotoDelete = (photoId: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Delete this photo?"}
            content={
              "Do you really want to delete this photo from this group's photo album?"
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { photoid: photoId, action: "delete" },
                {
                  method: "POST",
                  action: "deletePhotoAction",
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  return (
    <>
      <HorizontalSpaceBetweenContainer>
        <H2>Group Photos</H2>
        {isOrganizer && (
          <Form
            method="POST"
            action={"/group/view/" + groupDetails.id}
            encType="multipart/form-data"
            onChange={onFormChange}
          >
            <NavButton iconName="add" onClick={doPhotoAdd} />
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              // onChange={onImageChange}
              name="photo"
              style={{ display: "none" }}
            />
          </Form>
        )}
      </HorizontalSpaceBetweenContainer>
      {isLoading && <TextRegular>Loading Photos</TextRegular>}
      {isError && <TextRegular>Error Loading Photos</TextRegular>}
      {isSuccess && data && (
        <div>
          {data.length <= 0 && (
            <EmptyLayout title="No photos yet." subTitle="">
              <EmptyPhotos />
            </EmptyLayout>
          )}
          {data.map((photo: PhotoFull, index: number) => {
            return (
              <TouchableOpacity
                key={index}
                onClick={() => onImagePress(photo.imageUri)}
                style={{ backgroundColor: colors.WHITE, borderStyle: "none" }}
              >
                <SizedContainer
                  width={100}
                  height={100}
                  style={{
                    marginTop: LINE_VERTICAL_SPACING,
                    marginRight: LINE_HORIZONTAL_SPACING,
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      alignSelf: "center",
                      backgroundImage: `url(${photo.imageUri})`,
                      backgroundSize: "cover",
                      position: "relative",
                      borderRadius: getBorderRadius("small", "rounded rect"),
                    }}
                  >
                    {isOrganizer && (
                      <div style={{ position: "absolute", top: -9, right: -9 }}>
                        <OverlayButton
                          iconName="close"
                          onClick={() => doPhotoDelete(photo.id)}
                          color={colors.SECONDARY_TEXT}
                        />
                      </div>
                    )}
                  </div>
                </SizedContainer>
              </TouchableOpacity>
            );
          })}
        </div>
      )}
    </>
  );
};
