import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../UserContext";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { COLORS } from "../../styles/colors";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import logo from "../../assets/images/picklers_mobile_logo.png";
import { Icon } from "../../styles/icons";
import { TextRegular } from "../../styles/text";
import IonIcon from "@reacticons/ionicons";
import { NewMessageIndicator } from "../../components/general/NewMessageIndicator";
import { ProfileImage } from "../../components/image/ProfileImage";
import { NewActivityIndicator } from "../../components/general/NewActivityIndicator";
import { Auth } from "@aws-amplify/auth";

type NavbarProps = {
  userLoggedIn: boolean;
  error?: string;
};
export const PicklersNavbar = (props: NavbarProps) => {
  const colors = COLORS["light"];
  const userInfo = useContext(userContext);
  const currentUserId = userInfo ? userInfo.id : "";
  // const [loggedIn, setLoggedIn] = useState(!!currentUserId);
  const [haveUser, setHaveUser] = useState(false);
  const loggedIn = !!currentUserId;
  const location = useLocation();
  // const handleLogout = () => {
  //   setLoggedIn(false);
  //   Auth.signOut();
  //   queryClient.resetQueries();
  // };

  const [width, setWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  function getSize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", getSize);
    if (window.innerWidth < 1000) setCollapsed(true);
    else setCollapsed(false);

    return () => window.removeEventListener("resize", getSize);
  }, [window.innerWidth]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setHaveUser(!!user);
      } catch (e) {
        setHaveUser(false);
      }
    };

    getUser();
  }, [currentUserId, props.error]);

  return (
    <div
      style={{
        flexDirection: "column",
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        position: "sticky",
        top: 0,
        // backgroundColor: "blue",
        flex: 1,
        height: "100vh",
      }}
    >
      <Link to="/events">
        <img
          alt="Picklers' logo"
          src={logo}
          style={{
            marginTop: HEADER_VERTICAL_SPACING,
            height: 36,
            width: 36,
            borderRadius: getBorderRadius("small", "rounded rect"),
            marginBottom: SECTION_VERTICAL_SPACING,
            marginLeft: SECTION_HORIZONTAL_SPACING,
          }}
        />
      </Link>
      <Sidebar
        collapsed={collapsed}
        // width="100%"
        width="180px"
        // collapsedWidth="80px"
        backgroundColor={colors.BACKGROUND}
        rootStyles={{
          borderWidth: 0,
        }}
      >
        {loggedIn ? (
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  "&:hover": {
                    backgroundColor: colors.PALE_GRAY,
                    borderRadius: getBorderRadius("medium", "rounded rect"),
                    color: `${colors.PRIMARY_TEXT_COLOR} !important`,
                    // fontWeight: 500,
                  },
                  "&:focus": {
                    outline: "none",
                  },
                  marginBottom: LINE_VERTICAL_SPACING,
                  color: active
                    ? colors.PRIMARY_TEXT_COLOR
                    : colors.SECONDARY_TEXT,
                };
              },
              label: ({ level, active, disabled }) => {
                return {
                  fontFamily: "Poppins",
                  fontSize: 14,
                  fontWeight: active ? 500 : undefined,
                  color: colors.PRIMARY_TEXT_COLOR,
                };
              },
            }}
            renderExpandIcon={(params) => <></>}
          >
            <MenuItem
              active={
                location.pathname === "/events" || location.pathname === "/"
              }
              component={<Link to="/events" />}
              icon={<Icon name="home-outline" size={22} />}
            >
              Home
            </MenuItem>

            <MenuItem
              active={location.pathname === "/groups"}
              component={<Link to="/groups" />}
              icon={<Icon name="people-outline" size={22} />}
            >
              Groups
            </MenuItem>

            <MenuItem
              active={location.pathname === "/venues"}
              component={<Link to="/venues" />}
              icon={<Icon name="map-outline" size={22} />}
            >
              Venues
            </MenuItem>

            <MenuItem
              active={location.pathname === "/inbox"}
              component={<Link to="/inbox" />}
              icon={<Icon name="mail-outline" size={22} />}
              suffix={userInfo.hasUnread && <NewMessageIndicator />}
            >
              Inbox
            </MenuItem>

            <div
              style={{
                height: 1,
                backgroundColor: colors.LIGHT_GRAY,
                marginBottom: LINE_VERTICAL_SPACING,
                marginRight: ITEM_HORIZONTAL_SPACING,
                marginLeft: ITEM_HORIZONTAL_SPACING,
              }}
            />

            <MenuItem
              icon={
                userInfo.imageUri ? (
                  <ProfileImage
                    imgSrc={userInfo.imageUri}
                    width={22}
                    height={22}
                    borderRadius={50}
                  />
                ) : (
                  <Icon name="person-outline" size={22} />
                )
              }
              active={location.pathname.includes("/profile")}
              component={<Link to={"/profile/" + currentUserId} />}
            >
              Account
            </MenuItem>
            <SubMenu label="More" icon={<Icon name="menu-outline" size={24} />}>
              <MenuItem
                active={location.pathname.includes("/about")}
                component={<Link to={"/about"} />}
              >
                About
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/report")}
                component={<Link to={"/report"} />}
              >
                Contact Us
              </MenuItem>
            </SubMenu>
          </Menu>
        ) : (
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  "&:hover": {
                    backgroundColor: colors.PALE_GRAY,
                    borderRadius: getBorderRadius("medium", "rounded rect"),
                    color: `${colors.PRIMARY_TEXT_COLOR} !important`,
                  },
                  "&:focus": {
                    outline: "none",
                  },
                  marginBottom: LINE_VERTICAL_SPACING,
                  color: active
                    ? colors.PRIMARY_TEXT_COLOR
                    : colors.SECONDARY_TEXT,
                  paddingLeft: 24,
                  paddingRight: 24,
                };
              },
              label: ({ level, active, disabled }) => {
                return {
                  fontFamily: "Poppins",
                  fontSize: 14,
                  color: colors.PRIMARY_TEXT_COLOR,
                  fontWeight: active ? 500 : undefined,
                };
              },
            }}
            renderExpandIcon={(params) => <></>}
          >
            {!haveUser ? (
              <MenuItem
                active={location.pathname === "/"}
                component={<Link to="/" />}
                // icon={<Icon name="log-in-outline" size={22} />}
              >
                Log In
              </MenuItem>
            ) : (
              <MenuItem component={<Link to="/logout" />}>Log Out</MenuItem>
            )}
            <MenuItem
              active={location.pathname.includes("/about")}
              component={<Link to={"/about"} />}
              // icon={<Icon name="information-circle-outline" size={22} />}
            >
              About
            </MenuItem>
          </Menu>
        )}
      </Sidebar>
    </div>
  );
};
