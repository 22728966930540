import React from "react";
import { ScreenContainer } from "../../features/ScreenContainer";
import { HEADER_VERTICAL_SPACING } from "../../styles/spacing";

export const PrivacyPolicyScreen = () => {
  return (
    <ScreenContainer>
      <div style={{ marginTop: HEADER_VERTICAL_SPACING }} />

      <h2>Privacy Policy</h2>
      <p>Last updated June 30, 2023</p>

      <p>
        Hopewell Opportunity Fund LLC (&quot;us&quot;, &quot;we&quot;, or
        &quot;our&quot;) operates our “Platform”, which includes the website
        <a href="http://www.picklers.net">www.picklers.net</a>, mobile
        application (Picklers), or services we offer (the &quot;Service&quot;)
        for you, the User (&quot;you&quot;, &quot;your&quot; or
        &quot;User&quot;). This page informs you of our policies regarding the
        collection, use and disclosure of personal information when you use our
        Service. We will not use or share your information with anyone except as
        described in this Privacy Policy.
      </p>

      <p>
        <b>1. Information Collection and Use</b>
      </p>

      <p>
        <b>1.1 Information You Provide to Us</b>
      </p>

      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to, your name, username, password, email address, postal
        address, phone number; payment and commercial information, such as
        payment method data and purchases, if any; demographic information and
        any other personal information that you choose to provide, such as
        photos, gender, date of birth (&quot;Personal Information&quot;). We
        collect this information for the purpose of providing the Service,
        identifying you in our system, communicating with you, responding to
        your requests and inquiries, servicing your account, and improving our
        services.
      </p>

      <p>
        By using the Service, you agree to the collection and use of information
        in accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms of Service.
      </p>

      <p>
        <b>1.2 Information We Collect </b>
      </p>

      <p>
        <b>1. Log Data</b>: We collect log information about your use of the
        Platform, including, but not limited to, the type of browser that you
        use, the type of mobile device you use, your IP address; the time,
        duration, and frequency of your access; and Platform pages viewed. In
        addition, we reserve the right to use third party services to collect,
        monitor and analyze this information, measure traffic and usage trends
        in order to increase our Service's functionality and responsiveness for
        the User.
      </p>

      <p>
        <b>2. Location Information</b>: In order to make the Service more robust
        with the features our Service provides to the User, we may use and store
        information about your location depending on the permissions you have
        set on your device. You may enable or disable your location services
        when you use our Service at anytime, by your choice in your mobile
        device settings.
      </p>

      <p>
        <b>3. Cookies</b>: We reserve the right to use Cookies, web beacons, and
        pixels to collect information in order to improve our services for you.
        You may instruct your browser to refuse all cookies, to indicate when a
        cookie is being sent to your device, or to block cookies.
      </p>

      <p>
        <b>4. Social Media Services</b>: If you register or log into your
        account through a third-party social media service, we will have access
        to some of your third-party account information from that service,
        including identifiers, such as your name and other information in that
        account, and used for purposes such as improving your Meetup
        recommendations. We may also have access to third-party account
        information if you use the Platform feature to share a link.
      </p>

      <p>
        <b>5. Member Content</b>: We may receive information about you when you
        or another member uploads photos or posts other content to the Platform.
      </p>

      <p>
        <b>6. Service Providers</b>: We may employ the services of third party
        companies and individuals to facilitate our Service, to provide the
        Service on our behalf, to perform Service-related services, and to
        assist us in analyzing how our Service is used. These third parties may
        require access to your Personal Information to perform specific tasks on
        our behalf, and these third parties are obligated not to disclose or use
        your information for any other purpose.
      </p>

      <p>
        <b>1.3 Use of Information </b>
      </p>

      <p>
        <b>1. Operating our Platform</b>: We may use information about you
        described above to maintain and improve our Platform. We may display
        information about you, including, but not limited to, your participation
        in events, your group messages, and your photos posted on our Platform.
      </p>

      <p>
        <b>2. Communicating with you</b>: We may use your Personal Information
        to contact you and provide you information that may be of interest to
        you.
      </p>

      <p>
        <b>3. Advertising and Other Uses</b>: We may use information about you
        for various other business purposes, including, but not limited to,
        provide content, features, or sponsorships that match member profiles or
        interests; and deliver more relevant advertising to you on and off the
        Platform.
      </p>

      <p>
        <b>2. Sharing of Information</b>
      </p>

      <p>
        We do not share information about you except as described in this
        Privacy Policy. We may disclose personal information about you,
        including:
      </p>

      <p>
        1. to members or social media services, depending on how you engage with
        the Platform. Information that is public on our platform is accessible
        to others both on and off the Platform.
      </p>

      <p>
        2. to our third-party service providers who help us to operate or
        improve our Platform.
      </p>

      <p>
        3. when we work with third parties for analytics and to deliver targeted
        advertising to you on and off the Platform.
      </p>

      <p>
        4. to authorities to comply with legal obligations or requests and to
        protect and defend our rights and property.
      </p>

      <p>
        5. to an affiliate or successor entity or otherwise as part of a
        corporate transaction.
      </p>

      <p>6. among our businesses and affiliated companies.</p>

      <p>7. when you direct us to share your information. </p>

      <p>
        <b>3. Additional Information</b>
      </p>

      <p>
        <b>1. Legal Jurisdiction</b>: Hopewell Opportunity Fund LLC is based in
        and governed by U.S. law and we will be processing, transferring, and
        storing information in the U.S.
      </p>

      <p>
        <b>2. Data Security</b>: The security of your Personal Information is
        important to us, and we strive to implement and maintain security
        procedures and practices that are reasonable for our Service. Please be
        aware that no method of transmission is 100% secure. By using our
        Service, you agree that you understand and accept the risks associated
        with the security of all Personal Information that you transmit and that
        we collect from you.
      </p>

      <p>
        <b>3. Policy Scope</b>: This Privacy Policy does not apply to
        information that you have provided or made accessible to third parties,
        such as other members, including group leaders, organizers and network
        administrators, and others with whom you may share information about
        yourself. Members, including group leaders, organizers and network
        administrators, are directly responsible for complying with all
        requirements of applicable privacy laws in connection with the
        information that they obtain and process for the purposes of managing
        their contacts, organizing and communicating with groups, or
        administering networks.
      </p>

      <p>
        <b>4. Revisions To This Policy</b>: We may modify this Privacy Policy
        from time to time. When we do, we will publish the most current version
        on our website and mobile application. This Privacy Policy is effective
        as of the date identified under the heading &quot;Privacy Policy&quot;,
        and it will remain in effect except with respect to any changes in its
        provisions in the future, which will be in effect immediately after
        being posted by us. You agree to accept the modifications and you give
        your consent to abide by and be bound by the modified Privacy Policy in
        effect at that time.
      </p>

      <p>
        <b>5. Business Transaction</b>: If Hopewell Opportunity Fund LLC chooses
        to engage in a merger, acquisition or asset sale, your Personal
        Information may be transferred as a business asset. In such cases, we
        will provide notice.
      </p>

      <p>
        <b>6. Children’s Privacy</b>: Our Service is provided for the use of
        person’s age thirteen years or older who have permission to download and
        access our Service. If you are a parent or guardian of a child under the
        age of 13 and you learn that your child has downloaded and provided us
        with Personal Information, you may contact us to disable or terminate
        the account.
      </p>

      <p>
        <b>7. International Transfer</b>: Your information, including your
        Personal Information, may be transferred to and maintained on computers
        located outside of your state, province, country or other governmental
        jurisdiction where the data protection laws may differ than those from
        your jurisdiction. If you are located outside United States, your agree
        that, if you use of our Service and provide your personal information to
        us, you consent to that information being transferred, including
        Personal Information, to us in the United States and for us to process
        it here. Your consent to this Privacy Policy followed by your submission
        of such information represents your full acceptance and agreement to the
        transfer of this information.
      </p>

      <p>
        <b>Contact Us</b>
      </p>

      <p>
        If you have any questions, you can contact us at
        <a href="mailto:support@picklers.net">support@picklers.net</a>
      </p>
    </ScreenContainer>
  );
};
