import React, { Dispatch, SetStateAction, useState } from "react";
import Modal from "react-modal";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2 } from "../../styles/text";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { EventTime } from "../../types/enums";
import { formatDateFilter, getBorderRadius } from "../../utils";
import { FilterToggleButton } from "../../components/buttons/FilterToggleButton";
import { FilterButton } from "../../components/buttons/FilterButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { COLORS } from "../../styles/colors";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextButton } from "../../components/buttons/TextButton";
import "./DateFilterButton.css";
import "react-datepicker/dist/react-datepicker.css";
import { customStyles } from "./constants";

type DateFilterButtonProps = {
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  defaultDate: string;
};

export const DateFilterButton = (props: DateFilterButtonProps) => {
  const { date, setDate, defaultDate } = props;
  const [localDate, setLocalDate] = useState(date);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [datePicked, setDatePicked] = useState<Date | undefined>();
  const colors = COLORS["light"];
  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     borderRadius: getBorderRadius("medium", "rounded rect"),
  //   },
  //   overlay: {
  //     zIndex: 2000,
  //   },
  // };

  const onDateChange = (date?: Date | null) => {
    if (date) {
      setDatePicked(date);
      setLocalDate(formatDateFilter(date));
    } else {
      setDatePicked(undefined);
      setLocalDate("Upcoming");
    }
  };

  const pastPicked = () => {
    setLocalDate(EventTime.Past);
    setDatePicked(undefined);
  };

  const upcomingPicked = () => {
    setDatePicked(undefined);
    setLocalDate(EventTime.Upcoming);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleSelect = () => {
    setDate(localDate);
    closeModal();
    // clear location?
  };

  const handleReset = () => {
    setDatePicked(undefined);
    setLocalDate(defaultDate);
  };

  return (
    <>
      <FilterButton
        title={date}
        onClick={handleClick}
        active={true}
        iconName="today-outline"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
            paddingTop: ITEM_VERTICAL_SPACING,
            paddingLeft: ITEM_HORIZONTAL_SPACING,
            paddingRight: ITEM_HORIZONTAL_SPACING,
            paddingBottom: ITEM_VERTICAL_SPACING,
          }}
        >
          <HorizontalSpaceBetweenContainer style={{ marginBottom: 24 }}>
            <H2>Date</H2>
            <HorizontalRightAlignedContainer>
              <TextButton
                title="Reset"
                onClick={() => handleReset()}
                color={colors.PRIMARY_BRAND}
              />
            </HorizontalRightAlignedContainer>
          </HorizontalSpaceBetweenContainer>

          <HorizontalLeftAlignedContainer>
            <FilterToggleButton
              title="Upcoming"
              onClick={() => upcomingPicked()}
              selected={localDate === "Upcoming"}
            />
            <div style={{ width: 16 }} />
            <FilterToggleButton
              title="Past"
              onClick={() => pastPicked()}
              selected={localDate === "Past"}
            />
          </HorizontalLeftAlignedContainer>

          <DayPicker
            selected={datePicked}
            mode="single"
            onSelect={onDateChange}
          />
          {/* <DatePicker
            selected={datePicked}
            onChange={onDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="MMMM d, yyyy h:mm aa"
          /> */}
          <HorizontalContainer>
            <PrimaryButton onClick={handleSelect} title="Apply" flex={1} />
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
