import React from "react";
import IonIcon from "@reacticons/ionicons";
import styled from "styled-components";
import {
  LIGHT_GRAY,
  DARK_PRIMARY_TEXT_COLOR,
  PRIMARY_BRAND,
  DARK_SECONDARY_TEXT,
  LIGHT_SECONDARY_TEXT,
  PALE_GRAY,
  PALE_GREEN,
  DARK_GREEN,
  DARK_RED,
  PALE_RED,
} from "./colors";
import { TextSmall } from "./text";

import { LINE_HORIZONTAL_SPACING } from "./spacing";
import "./label.css";
import { Icon } from "./icons";

type LabelType = "brand" | "primary" | "secondary" | "light";

type LabelContainerProps = {
  backgroundColor: string;
  size: "small" | "regular";
};

type LabelProps = {
  title?: string;
  labelType: LabelType;
  background: boolean;
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  onPress?: () => void;
};

const LabelContainer = styled.div<LabelContainerProps>`
  ${(p: LabelContainerProps) => `background-color: ${p.backgroundColor};`}
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  ${(p: LabelContainerProps) =>
    p.backgroundColor === "transparent" ? "" : "padding-vertical: 6px"}
  ${(p: LabelContainerProps) =>
    p.backgroundColor === "transparent" ? "" : "padding-horizontal: 6px"}
`;

export const GreenLabelContainer = styled.div<LabelContainerProps>`
  background-color: ${PALE_GREEN};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 6px;
  flex-direction: row;
  display: flex;
`;

export const RedLabelContainer = styled.div<LabelContainerProps>`
  background-color: ${PALE_RED};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const GrayLabelContainer = styled.div<LabelContainerProps>`
  background-color: ${PALE_GRAY};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const getTextColor = (labelType: LabelType) =>
  labelType === "brand"
    ? PRIMARY_BRAND
    : labelType === "primary"
    ? DARK_PRIMARY_TEXT_COLOR
    : labelType === "secondary"
    ? DARK_SECONDARY_TEXT
    : LIGHT_SECONDARY_TEXT;
const getBGColor = (labelType: LabelType) =>
  labelType === "brand"
    ? PALE_GRAY
    : labelType === "primary"
    ? LIGHT_GRAY
    : labelType === "secondary"
    ? LIGHT_GRAY
    : "transparent";

export const Label = (props: LabelProps) => (
  <LabelContainer
    size={"regular"}
    backgroundColor={
      props.background ? getBGColor(props.labelType) : "transparent"
    }
    // onPress={props.onPress}
  >
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      {props.iconName && (
        <IonIcon name={props.iconName} color={getTextColor(props.labelType)} />
      )}
      {props.title && (
        <TextSmall
          color={getTextColor(props.labelType)}
          style={{ marginLeft: LINE_HORIZONTAL_SPACING }}
        >
          {props.title}
        </TextSmall>
      )}
    </div>
  </LabelContainer>
);

type RSVPStatusLabelProps = {
  status:
    | "past"
    | "going"
    | "cango"
    | "full"
    | "waitlist"
    | "canceled"
    | "rsvp not open";
  text?: string;
};

export const RSVPStatusLabel = (props: RSVPStatusLabelProps) => {
  return (
    <>
      {props.status === "past" ? (
        <div className="rsvp-label rsvp-label-purple">
          <Icon name="people-outline" size={14} color="#490F93" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : props.status === "going" ? (
        <div className="rsvp-label rsvp-label-green">
          <Icon name="checkmark-circle-outline" size={14} color="#17773d" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : props.status === "cango" ? (
        <div className="rsvp-label rsvp-label-green">
          <Icon name="people-outline" size={14} color="#17773d" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : props.status === "full" ? (
        <div className="rsvp-label rsvp-label-yellow">
          <Icon name="people-outline" size={14} color="#cf8d0d" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : props.status === "waitlist" ? (
        <div className="rsvp-label rsvp-label-yellow">
          <Icon name="list" size={14} color="#cf8d0d" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : props.status === "canceled" ? (
        <div className="rsvp-label rsvp-label-red">
          <Icon name="close-circle-outline" size={14} color="#a40303" />
          <p className="rsvp-label-text">{props.text}</p>
        </div>
      ) : (
        <div className="rsvp-label rsvp-label-purple">
          <Icon name="hourglass-outline" size={14} color="#490F93" />
          {props.text && <p className="rsvp-label-text">{props.text}</p>}
        </div>
      )}
    </>
  );
};

type AttendeeStatusLabelProps = {
  type: "payment" | "attendance" | "conflict";
  actioned: boolean;
};

export const AttendeeStatusLabel = (props: AttendeeStatusLabelProps) =>
  props.type === "conflict" ? (
    <RedLabelContainer size="regular" backgroundColor="transparent">
      <div style={{ marginLeft: 5 }} />
      <Icon name="flag-outline" color={DARK_RED} />
      <TextSmall style={{ marginLeft: 5, marginRight: 5 }} color={DARK_RED}>
        conflict
      </TextSmall>
    </RedLabelContainer>
  ) : props.actioned ? (
    <GreenLabelContainer size="regular" backgroundColor="transparent">
      <input
        type="checkbox"
        checked={true}
        style={{ marginLeft: 5, width: 12 }}
      />
      <TextSmall style={{ marginLeft: 2, marginRight: 5 }} color={DARK_GREEN}>
        {props.type === "attendance" ? "attended" : "paid"}
      </TextSmall>
    </GreenLabelContainer>
  ) : (
    <GrayLabelContainer size="regular" backgroundColor="transparent">
      <input
        type="checkbox"
        checked={false}
        style={{ marginLeft: 5, width: 12 }}
      />
      {/* <Icon name="square-outline" color={DARK_RED} /> */}
      <TextSmall
        style={{ marginLeft: 2, marginRight: 5 }}
        color={DARK_SECONDARY_TEXT}
      >
        {props.type === "attendance" ? "attended" : "paid"}
      </TextSmall>
    </GrayLabelContainer>
  );
