import React from "react";
import { Icon } from "../../styles/icons";
import { COLORS } from "../../styles/colors";

type NewMessageIndicatorProps = {
  color?: string;
  size?: number;
};

export const NewMessageIndicator = (props: NewMessageIndicatorProps) => {
  const colors = COLORS["light"];
  return (
    <Icon
      name="ellipse"
      color={props.color ?? colors.PRIMARY_BRAND}
      size={props.size ?? 11}
    />
  );
};
