import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useContext,
  useMemo,
  useRef,
} from "react";
import { PeopleData } from "../../types";
import TouchableOpacity from "../../components/general/TouchableOpacity";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { userContext } from "../../UserContext";
import { MemberSelectionItem } from "../../components/people/MemberPickerListItem";
import { EditActionBar } from "../actionBars/EditActionBar";
import { UseQueryResult } from "react-query";
import { useWindowHeight } from "@react-hook/window-size";
import { TextRegular } from "../../styles/text";
import { COLORS } from "../../styles/colors";

const alphabetizePeopleDataArray = (people: PeopleData[]) => {
  people.sort((a, b) => {
    if (a.lastName > b.lastName) {
      return 1;
    } else if (a.lastName === b.lastName) {
      if (a.firstName > a.lastName) return 1;
      else return -1;
    } else {
      return -1;
    }
  });
};

type PeoplePickerProps = {
  peopleQueryResult: UseQueryResult<PeopleData[]>;
  isOpen: boolean;
  closePicker: () => void;
  pickedPeople: PeopleData[];
  setPickedPeople: Dispatch<SetStateAction<PeopleData[]>>;
  ineligible?: string[];
  appendCurrentUser?: boolean;
  buttonType?: "button" | "submit" | "reset";
  maximum: number;
};

export const PeoplePicker = (props: PeoplePickerProps) => {
  const {
    peopleQueryResult,
    isOpen,
    closePicker,
    pickedPeople,
    setPickedPeople,
    ineligible,
    appendCurrentUser,
    buttonType,
    maximum,
  } = props;
  const { isSuccess, data } = peopleQueryResult;

  const userInfo = useContext(userContext);
  const colors = COLORS["light"];
  const ignoreItems = useMemo(() => {
    return ineligible ? ineligible : [];
  }, [ineligible]);

  const [checkedState, setCheckedState] = useState<boolean[]>([]);
  const [visibleState, setVisibleState] = useState<boolean[]>([]);
  const [suggestionItems, setSuggestionItems] = useState<PeopleData[]>([]);
  const [memberSearchText, setMemberSearchText] = useState("");
  const [height, setHeight] = useState(500);
  const [numChecked, setNumChecked] = useState(0);

  const divRef = useRef<HTMLDivElement>(null);
  const windowHeight = useWindowHeight();

  const thisUser: PeopleData = {
    userId: userInfo.id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    imageUri: userInfo.imageUri,
    membershipId: "",
  };

  // when search text is entered, iterate through the suggested items
  // and make the ineligible invisible
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMemberSearchText(e.target.value);
    if (!data) return;
    const updatedVisibleState: boolean[] = [];
    const regex = new RegExp(e.target.value, "ig");

    suggestionItems.forEach((suggestion) => {
      const result = (suggestion.firstName + " " + suggestion.lastName).match(
        regex
      );
      updatedVisibleState.push(
        !!result && !ignoreItems.includes(suggestion.userId)
      );
    });
    setVisibleState(updatedVisibleState);
  };

  // when someone is clicked, check or uncheck their box appropriately
  const handleRecipientSelection = (
    selectedPerson: PeopleData,
    selectedIndex: number
  ) => {
    const updatedCheckedState = checkedState.map((checked, index) =>
      index === selectedIndex ? !checked : checked
    );
    setCheckedState(updatedCheckedState);
    if (updatedCheckedState[selectedIndex]) setNumChecked(numChecked + 1);
    else setNumChecked(numChecked - 1);
  };

  // the user is canceling without saving, clean up and close the picker drawer
  const onCancel = () => {
    const newVisibleState: boolean[] = new Array(suggestionItems.length).fill(
      true
    );
    setVisibleState(newVisibleState);
    closePicker();
  };

  // save the currently selected people via the state function provided
  // and close the picker
  const onContinue = () => {
    const newPickedList: PeopleData[] = appendCurrentUser ? [thisUser] : [];
    suggestionItems.forEach((suggestion, index) => {
      if (checkedState[index]) {
        newPickedList.push({ ...suggestion, membershipId: suggestion.id });
      } else {
      }
    });
    setPickedPeople(newPickedList);
    closePicker();
  };

  // when our data is good, create and alphabetize the suggestion list
  useEffect(() => {
    if (isSuccess && data) {
      const alphabetizedData = data;
      alphabetizePeopleDataArray(alphabetizedData);
      setSuggestionItems(alphabetizedData);
    } else {
      setSuggestionItems([]);
    }
  }, [data, isSuccess]);

  // when the drawer opens and we have our suggestion list,
  // create parallel checked and visible states arrays
  useEffect(() => {
    if (isOpen) {
      let newNumChecked = 0;
      setMemberSearchText("");
      const newCheckedState: boolean[] = [];
      const newVisibleState: boolean[] = [];
      suggestionItems.forEach((suggestion) => {
        const selectedInitially = pickedPeople.find(
          (item: PeopleData) => item.userId === suggestion.userId
        );

        newCheckedState.push(!!selectedInitially);
        newVisibleState.push(!ignoreItems.includes(suggestion.userId));
        if (!!selectedInitially) newNumChecked++;
      });
      setCheckedState(newCheckedState);
      setVisibleState(newVisibleState);
      setNumChecked(newNumChecked);
    }
  }, [isOpen, ignoreItems, pickedPeople, suggestionItems]);

  // render each suggestion item with a checkbox
  const renderSuggestionItem = (item: PeopleData, index: number) => {
    return (
      <HorizontalLeftAlignedContainer
        key={index}
        style={{
          width: "100%",
          marginRight: ITEM_HORIZONTAL_SPACING,
          marginBottom: LINE_VERTICAL_SPACING,
          display: visibleState[index] === false ? "none" : undefined,
        }}
      >
        <TouchableOpacity
          style={{
            flex: 1,
            overflow: "hidden",
            borderStyle: "none",
            backgroundColor: "white",
          }}
          onClick={() => {
            if (numChecked < maximum || checkedState[index])
              handleRecipientSelection(item, index);
          }}
        >
          <HorizontalLeftAlignedContainer
            style={{ paddingBottom: LINE_HORIZONTAL_SPACING }}
          >
            <input
              type="checkbox"
              checked={checkedState[index]}
              onChange={() => {
                if (numChecked < maximum || checkedState[index])
                  handleRecipientSelection(item, index);
              }}
              style={{ width: 24 }}
              disabled={numChecked >= maximum && !checkedState[index]}
            />
            <MemberSelectionItem
              firstName={item.firstName}
              lastName={item.lastName}
              imageUri={item.imageUri}
              isPicked={false}
            />
          </HorizontalLeftAlignedContainer>
        </TouchableOpacity>
      </HorizontalLeftAlignedContainer>
    );
  };
  // remember the height of the header once it is ready
  useEffect(() => {
    if (!divRef.current) return;
    setHeight(divRef.current.offsetHeight);
  }, []);

  return (
    <div
      style={{
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 20,
      }}
    >
      <div ref={divRef}>
        <EditActionBar
          onCancel={onCancel}
          onSave={onContinue}
          buttonType={buttonType}
        />

        {numChecked >= maximum && (
          <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
            <TextRegular color={colors.PRIMARY_RED}>
              You have selected the maximum number allowed
            </TextRegular>
          </HorizontalContainer>
        )}
        <HorizontalLeftAlignedContainer
          style={{ marginBottom: ITEM_VERTICAL_SPACING, width: "100%" }}
        >
          <input
            type="text"
            value={memberSearchText}
            placeholder="Type any part of the name."
            onChange={onChange}
            maxLength={35}
            style={{ width: "100%", marginRight: 25 }}
          />
        </HorizontalLeftAlignedContainer>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: windowHeight - height - 55,
          overflow: "auto",
        }}
      >
        {isSuccess && data && suggestionItems && (
          <div style={{ flexDirection: "row" }}>
            {suggestionItems.map((suggestion: PeopleData, index: number) => {
              return renderSuggestionItem(suggestion, index);
            })}
          </div>
        )}
      </div>
    </div>
  );
};
