import React from "react";
import { COLORS } from "../../styles/colors";
import { TextRegular } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import "./SecondaryButton.css";
import Skeleton from "react-loading-skeleton";

type SecondaryButtonProps = {
  title: string;
  onClick: () => void;
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  flex?: number;
  type?: "button" | "submit" | "reset" | undefined;
};

export const SecondaryButton = (props: SecondaryButtonProps) => {
  const { title, onClick, color, disabled, loading, type, flex } = props;
  const colors = COLORS["light"];
  return (
    <button
      className="secondary-button"
      onClick={!loading ? onClick : undefined}
      disabled={disabled}
      type={type}
      style={{ flex: flex }}
    >
      {loading ? (
        <Skeleton height={32} width={12 * title.length} />
      ) : (
        <TextRegular color={color ? color : colors.SECONDARY_TEXT}>
          {title}
        </TextRegular>
      )}
    </button>
  );
};
