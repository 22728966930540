import React, { useEffect, useState } from "react";
// import GoogleMapReact from "google-map-react";
// import AutoComplete from "react-google-autocomplete";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import { Loader } from "@googlemaps/js-api-loader";

import "./LocationPicker.css";
import { GP_API_KEY } from "../../env";
import { H2, TextRegular } from "../../styles/text";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { getStaticMapUri } from "../../utils/mapUtils";
import {
  getBorderRadius,
  getDisplayAddressFromGooglePlacesData,
} from "../../utils";
import { ITEM_VERTICAL_SPACING } from "../../styles/spacing";
import { EditActionBar } from "../actionBars/EditActionBar";
import { StickyActionBar } from "../../components/StickyActionBar";

const gmapLibraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];

export type Location = {
  address: string;
  displayAddress: string;
  lat: number;
  lng: number;
};

// const getSignature = (unsignedURLFrag: string) => {
//   let decodedKey = "";
//   if (GP_SIGNING_SECRET) {
//     decodedKey = atob(GP_SIGNING_SECRET);
//     console.log("decodedKey: ", decodedKey);
//   }
//   console.log("signing this url fragment: ", unsignedURLFrag);
//     const signature = hmacsha1(decodedKey, unsignedURLFrag);
//   console.log("signature", signature);
//   return btoa(signature);
// };

// const getStaticMapUri = (location: Location): string => {
//   const uriBase = "https://maps.googleapis.com";
//   const uriPath = "/maps/api/staticmap";

//   let queryParamString = "";
//   if (location) {
//     const latlng = location.lat + "," + location.lng;
//     queryParamString = addQueryItem2(queryParamString, "center", latlng);
//     // queryParamString = addQueryItem2(queryParamString, "markers", latlng);
//   }
//   queryParamString = addQueryItem2(queryParamString, "size", "400x400");
//   queryParamString = addQueryItem2(queryParamString, "zoom", "14");

//   if (GP_API_KEY)
//     queryParamString = addQueryItem2(
//       queryParamString,
//       "key",
//       "AIzaSyDBuIJsR9EE9VgM7wWEaf_THLk9J4J0fj0"
//     );

//   const unsignedUri = uriBase + uriPath + queryParamString;

//   // let signedUri;
//   // if (GP_SIGNING_SECRET) signedUri = signGPUri(unsignedUri, GP_SIGNING_SECRET);
//   // else signedUri = unsignedUri;
//   // const unsignedURLFrag = uriPath + queryParamString;
//   // const signature = getSignature(unsignedURLFrag);
//   // queryParamString = addQueryItem2(queryParamString, "signature", signature);

//   return unsignedUri;
// };

// export const getStaticMapUriByLatLong = (lat: number, lng: number): string => {
//   const uriBase = "https://maps.googleapis.com";
//   const uriPath = "/maps/api/staticmap";

//   let queryParamString = "";
//   const latlng = lat + "," + lng;
//   queryParamString = addQueryItem2(queryParamString, "center", latlng);
//   queryParamString = addQueryItem2(queryParamString, "size", "400x400");
//   queryParamString = addQueryItem2(queryParamString, "zoom", "14");

//   if (GP_API_KEY)
//     queryParamString = addQueryItem2(
//       queryParamString,
//       "key",
//       "AIzaSyDBuIJsR9EE9VgM7wWEaf_THLk9J4J0fj0"
//     );

//   const unsignedUri = uriBase + uriPath + queryParamString;

//   return unsignedUri;
// };

type LocationPickerProps = {
  show: boolean;
  onClose: () => void;
  location: Location;
  setLocation: (location: Location) => void;
  citiesOnly: boolean;
};

export const LocationPicker = (props: LocationPickerProps) => {
  const { location, setLocation, citiesOnly } = props;
  // const [location, setLocation] = useState(props.location);
  const [staticMapUri, setStaticMapUri] = useState("");
  const { isLoaded: gmapsLoaded } = useJsApiLoader({
    googleMapsApiKey: GP_API_KEY ? GP_API_KEY : "",
    libraries: gmapLibraries,
  });
  const [autoCompleteItem, setAutCompleteItem] =
    useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    setStaticMapUri(getStaticMapUri(props.location));
    return;
  }, [props.location]);

  if (!props.show) {
    return null;
  }

  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutCompleteItem(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autoCompleteItem !== null) {
      const placesResult = autoCompleteItem.getPlace();
      const newLocation = location;
      newLocation.address = placesResult.formatted_address
        ? placesResult.formatted_address
        : "";
      newLocation.displayAddress =
        placesResult && placesResult.address_components
          ? getDisplayAddressFromGooglePlacesData(
              placesResult.address_components
            )
          : placesResult.formatted_address ?? "";
      newLocation.lat = placesResult.geometry?.location?.lat()
        ? placesResult.geometry?.location?.lat()
        : location.lat;
      newLocation.lng = placesResult.geometry?.location?.lng()
        ? placesResult.geometry?.location?.lng()
        : location.lng;
      setLocation(newLocation);

      setStaticMapUri(getStaticMapUri(props.location));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const handleCancel = () => {
    props.onClose();
    setLocation(props.location);
  };
  const handleSelect = () => {
    props.setLocation(location);
    props.onClose();
    // clear location?
  };

  return (
    <>
      <StickyActionBar>
        <EditActionBar onCancel={handleCancel} onSave={handleSelect} />
      </StickyActionBar>
      <div
        style={{
          width: 360,
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <HorizontalLeftAlignedContainer>
          <H2>Enter a location</H2>
        </HorizontalLeftAlignedContainer>
        <div style={{ height: ITEM_VERTICAL_SPACING }} />
        <HorizontalContainer style={{ marginBottom: 25 }}>
          {gmapsLoaded ? (
            <Autocomplete
              onLoad={onAutoCompleteLoad}
              onPlaceChanged={onPlaceChanged}
              // options={{
              //   types: citiesOnly
              //     ? [
              //         "sublocality",
              //         "locality",
              //         "administrative_area_level_1",
              //         "neighborhood",
              //         // "administrative_area_level_2",
              //         // "administrative_area_level_3",
              //         // "postal_code",
              //         // "country",
              //       ]
              //     : [],
              // }}
            >
              <input
                type="text"
                placeholder={props.location.displayAddress}
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: 360,
                  // height: `32px`,
                  padding: `0 12px`,
                  borderRadius: getBorderRadius("small", "rounded rect"),
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  // position: "absolute",
                  // left: "50%",
                  // marginLeft: "-120px",
                }}
              />
            </Autocomplete>
          ) : (
            <TextRegular>Loading Google Maps</TextRegular>
          )}
        </HorizontalContainer>
        <HorizontalContainer>
          <img
            src={staticMapUri}
            alt="Map of currently selected location"
            height={360}
            width={360}
          />
        </HorizontalContainer>
      </div>
    </>
  );
};
