import React from "react";
import { VenueFull } from "../../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../../styles/container";
import { blankVenueImageURL } from "../../../constants/defaultImageURLs";
import { COLORS, PRIMARY_BRAND } from "../../../styles/colors";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import { H1, TextRegular } from "../../../styles/text";
import { Icon } from "../../../styles/icons";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GeneralAttribute } from "../../../components/attributes/GeneralAttribute";
import { ClickableAttribute } from "../../../components/attributes/ClickableAttribute";
import { PrimaryLeftHeader } from "../../../components/text/PrimaryLeftHeader";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { LineVerticalSpace } from "../../../components/spacing/LineVerticalSpace";

type VenueDetailsImageTitleProps = {
  venueDetails: VenueFull;
  canViewDetails?: boolean;
};

export const VenueDetailsImageTitle = (props: VenueDetailsImageTitleProps) => {
  const { venueDetails } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const venueImageUri =
    venueDetails && venueDetails.imageUri
      ? venueDetails.imageUri
      : blankVenueImageURL;

  return (
    <div
      style={{
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
      }}
    >
      <HorizontalLeftAlignedContainer>
        <PrimaryLeftHeader>{props.venueDetails.nickname}</PrimaryLeftHeader>
        {venueDetails.verificationStatus && (
          <>
            <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
            <Icon name="shield-checkmark" color={PRIMARY_BRAND} size={24} />
          </>
        )}
      </HorizontalLeftAlignedContainer>

      <LineVerticalSpace />
      <HorizontalLeftAlignedContainer>
        {navigation.state === "loading" ? (
          <Skeleton height={24} width={300} />
        ) : (
          <>
            <GeneralAttribute text={props.venueDetails.name} />

            <p className="dot-separator">·</p>

            <ClickableAttribute
              text={props.venueDetails.address}
              onClick={() => {
                window.open(
                  "https://www.google.com/maps/search/?api=1&query=" +
                    encodeURIComponent(props.venueDetails.address),
                  "_blank"
                );
              }}
              // iconName="location"
            />
          </>
        )}
      </HorizontalLeftAlignedContainer>
    </div>
  );
};
