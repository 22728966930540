import React from "react";
import { COLORS } from "../../styles/colors";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { GroupFull } from "../../types";
import { getBorderRadius, getCardSize } from "../../utils";
import { ProfileImage } from "../../components/image/ProfileImage";
import { blankGroupImageURL } from "../../constants/defaultImageURLs";

type GroupSelectionItemProps = {
  group: GroupFull;
  isPicked: boolean;
};

export const GroupSelectionItem = (props: GroupSelectionItemProps) => {
  const thumbnailSize = 32;
  const colors = COLORS["light"];
  return (
    <>
      <HorizontalLeftAlignedContainer
        style={{
          flex: 1,
          textAlign: "left",
          paddingLeft: LINE_HORIZONTAL_SPACING,
        }}
      >
        <SizedContainer width={thumbnailSize} height={thumbnailSize}>
          <ProfileImage
            width={thumbnailSize}
            height={thumbnailSize}
            borderRadius={getBorderRadius("small", "rounded rect")}
            imgSrc={props.group.imageUri ?? blankGroupImageURL}
          />
        </SizedContainer>
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        <HorizontalLeftAlignedContainer style={{ flex: 1 }}>
          <TextRegular
            color={
              props.isPicked ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT_COLOR
            }
          >
            {props.group.name}
          </TextRegular>
        </HorizontalLeftAlignedContainer>
      </HorizontalLeftAlignedContainer>
    </>
  );
};
