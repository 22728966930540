import React, { useContext, useEffect, useState } from "react";
import {
  AttendeeFull,
  EventFull,
  GroupFull,
  defaultAttendee,
} from "../../../types";
import { userIsHost, userIsOrganizer } from "../../../utils";
import { userContext } from "../../../UserContext";
import { useRSVPs } from "../../../hooks/queryAttendees";
import { AttendeesListItem } from "./AttendeesListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyPeople } from "../../../assets/images/empty-state/empty-groups.svg";
import { useNavigation } from "react-router-dom";
import { UseInfiniteQueryResult } from "react-query";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../../styles/spacing";

type AttendeesListParams = {
  attendeeQueryResult: UseInfiniteQueryResult<AttendeeFull[]>;
  eventDetails: EventFull;
  groupDetails: GroupFull;
  birdsEyeViewOn: boolean;
  conflictData?: AttendeeFull[];
  infiniteScrollHeight: number;
};

export const AttendeesList = (props: AttendeesListParams) => {
  const {
    attendeeQueryResult,
    eventDetails,
    groupDetails,
    birdsEyeViewOn,
    conflictData,
    infiniteScrollHeight,
  } = props;
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;
  const isAdmin = currentUserInfo.adminLevel > 90;
  const [rsvpData, setRsvpData] = useState<AttendeeFull[] | undefined>(
    undefined
  );

  const isHostOrHigher =
    userIsHost(currentUserId, props.eventDetails, props.groupDetails) ||
    userIsOrganizer(currentUserId, props.groupDetails) ||
    isAdmin;

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    attendeeQueryResult;

  const hasMore = () => {
    const lastPage = attendeeQueryResult.data?.pages.slice(-1);
    return (
      !!attendeeQueryResult.hasNextPage &&
      !attendeeQueryResult.isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreAttendees = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (data && data.pages) {
      setRsvpData(data.pages.map((page) => (page ? page : [])).flat());
    } else {
      setRsvpData([]);
    }
  }, [data]);
  return (
    <>
      {isSuccess && rsvpData && (
        <InfiniteScroll
          dataLength={rsvpData.length} //This is important field to render the next data
          next={fetchMoreAttendees}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
          // height={infiniteScrollHeight}
          style={{
            paddingTop: SECTION_VERTICAL_SPACING,
            paddingBottom: 180,
          }}
        >
          {rsvpData.length <= 0 && (
            <EmptyLayout title="None yet." subTitle=" ">
              <EmptyPeople />
            </EmptyLayout>
          )}
          {rsvpData.map((attendee: AttendeeFull, index) => {
            let conflictType = "gray";
            if (conflictData && conflictData.length > 0) {
              let goingConflictIndex = conflictData.findIndex(
                (otherAttendee) => {
                  return (
                    attendee.userId === otherAttendee.userId &&
                    otherAttendee.status === "going"
                  );
                }
              );
              if (goingConflictIndex >= 0) conflictType = "red";
            }
            return (
              <div key={index}>
                <AttendeesListItem
                  attendee={attendee}
                  eventDetails={eventDetails}
                  groupDetails={groupDetails}
                  birdsEyeViewOn={birdsEyeViewOn}
                  conflictType={conflictType}
                  canModifyRsvp={isHostOrHigher}
                  canMessage={currentUserId !== attendee.userId}
                />
              </div>
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );
};
