import React, { useState } from "react";
import { redirect, useActionData, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { NewPasswordScreenLayout } from "./NewPasswordScreenLayout";
import { useEffect } from "react";
import { logSentryError } from "../../utils/sentryUtil";

export type NewPasswordErrors = {
  ok?: boolean;
  password?: string;
  password2?: string;
  code?: string;
  other?: string;
  email?: string;
};

const validateNewPasswordData = (
  password: string,
  password2: string,
  code: string,
  email: string
) => {
  const err: NewPasswordErrors = {};

  if (!email) err.email = "Missing email address!";
  if (!code) err.code = "Please enter the code!";
  if (!password) err.password = "Please enter your password!";
  if (!password2) err.password2 = "Please re-type your password!";
  if (password !== password2)
    err.password2 = "Passwords do not match!  Please try again!";

  return err as NewPasswordErrors;
};

export const newPasswordAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  const data = await request.formData();

  try {
    const email = fdGetString(data, "email");
    const code = fdGetString(data, "code");
    const password = fdGetString(data, "password");
    const password2 = fdGetString(data, "password2");

    const validateResult = validateNewPasswordData(
      password,
      password2,
      code,
      email
    );
    if (Object.keys(validateResult).length) {
      return { ok: false, ...validateResult } as NewPasswordErrors;
    }

    await Auth.forgotPasswordSubmit(email, code, password);
    return redirect("/");
  } catch (e: any) {
    if (e.code === "InvalidPasswordException") {
      return {
        ok: false,
        password:
          "Passwords must be at least 8 characters and contain at least one each of uppercase, lowercase, numeric, and symbolic characters.",
      };
    } else if (e.code === "CodeMismatchException") {
      return {
        ok: false,
        code: "The code does not match.  Please recheck your email!",
      };
    } else {
      logSentryError("Error changing password!", e, {
        tags: {
          userId: "none",
          screen: "NewPasswordScreen",
          function: "newPasswordAction",
        },
        level: "error",
      });
      return { ok: false, other: "Error" + e.code };
    }
  }
};

export const newPasswordLoader = async (currentUserId: string) => {
  if (!!currentUserId) return redirect("/events");
  return { ok: true };
};

export const NewPasswordScreen = () => {
  const location = useLocation();
  const { state } = location;
  const errors = useActionData() as NewPasswordErrors;
  const email = state?.email ?? "";
  const [emailSpecified] = useState(email);

  useEffect(() => {
    document.title = "New Password -- Picklers";
  }, []);

  return <NewPasswordScreenLayout errors={errors} email={emailSpecified} />;
};
