import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { PeopleData } from "../../types";
import Drawer from "react-modern-drawer";
import { userContext } from "../../UserContext";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { COLORS } from "../../styles/colors";
import { NavButton } from "../../components/buttons/NavButton";
import { UseQueryResult } from "react-query";
import { PeoplePicker } from "../PeoplePicker/PeoplePicker";
import { PeoplePickerCard } from "../PeoplePicker/PeoplePickerCard";

export type TitledRecipientPickerProps = {
  friendsQueryResult: UseQueryResult<PeopleData[]>;
  formDataName: string;
  recipients: PeopleData[];
  setRecipients: Dispatch<SetStateAction<PeopleData[]>>;
  maximum: number;
};

export const TitledRecipientPicker = (props: TitledRecipientPickerProps) => {
  const userInfo = useContext(userContext);
  const { recipients, setRecipients, friendsQueryResult } = props;
  const navigation = useNavigation();
  const colors = COLORS["light"];
  const [showRecipientPicker, setShowRecipientPicker] = useState(false);

  const toggleRecipientPickerDrawer = () => {
    if (navigation.state !== "loading")
      setShowRecipientPicker((isOpen) => {
        return !isOpen;
      });
  };

  const removeRecipient = (userId: string) => {
    const newRecipients = recipients.filter(
      (recipient) => userId !== recipient.userId
    );
    setRecipients(newRecipients);
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        // paddingLeft: SIDE_PADDING,
        // paddingRight: SIDE_PADDING,
      }}
    >
      <Drawer
        open={showRecipientPicker}
        onClose={() => toggleRecipientPickerDrawer()}
        direction="right"
        lockBackgroundScroll={true}
        size={500}
        zIndex={2000}
      >
        <PeoplePicker
          peopleQueryResult={friendsQueryResult}
          isOpen={showRecipientPicker}
          closePicker={() => setShowRecipientPicker(false)}
          pickedPeople={recipients}
          setPickedPeople={setRecipients}
          appendCurrentUser={true}
          buttonType="button"
          maximum={99}
        />
      </Drawer>

      <HorizontalSpaceBetweenContainer
        style={{
          borderTopColor: colors.LIGHT_GRAY,
          borderBottomColor: colors.LIGHT_GRAY,
          borderBottomWidth: 1,
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderBottomStyle: "solid",
          paddingTop: LINE_HORIZONTAL_SPACING,
          // paddingBottom: 4,
          paddingLeft: ITEM_HORIZONTAL_SPACING,
          paddingRight: ITEM_HORIZONTAL_SPACING,
          // borderRadius: getBorderRadius("small", "rounded rect"),
        }}
      >
        <HorizontalLeftAlignedContainer
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          {navigation.state === "loading" ? (
            <Skeleton height={20} width={40} />
          ) : (
            <TextRegular
              color={colors.PRIMARY_TEXT_COLOR}
              style={{ paddingTop: 2, marginBottom: 10 }}
            >
              To:
            </TextRegular>
          )}

          <div style={{ width: ITEM_HORIZONTAL_SPACING }} />

          {navigation.state === "loading" ? (
            <Skeleton height={24} width={300} style={{ marginBottom: 10 }} />
          ) : (
            <HorizontalLeftAlignedContainer style={{ flexWrap: "wrap" }}>
              {recipients.map((item, index) => {
                if (item.userId !== userInfo.id) {
                  return (
                    <PeoplePickerCard
                      key={index}
                      person={item}
                      onPress={() => removeRecipient(item.userId)}
                    />
                  );
                } else {
                  return <div key={index} />;
                }
              })}
            </HorizontalLeftAlignedContainer>
          )}
        </HorizontalLeftAlignedContainer>

        <div style={{ marginTop: 2, marginBottom: 10 }}>
          <NavButton
            iconName="add"
            onClick={() => setShowRecipientPicker(true)}
            color={colors.PRIMARY_TEXT_COLOR}
            size={16}
            loading={navigation.state === "loading"}
          />
        </div>
      </HorizontalSpaceBetweenContainer>
    </div>
  );
};
