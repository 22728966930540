import React, { useEffect, useState } from "react";
import { AttendeeFull, EventFull, GroupFull } from "../../../types";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { useFetcher, useNavigate, useNavigation } from "react-router-dom";
import {
  LINE_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../../styles/spacing";
import {
  canShowConflicts,
  canTakeAttendance,
  formatDateTime,
  getUserTitle,
  isInThePast,
} from "../../../utils";
import { TextRegular, TextSmall } from "../../../styles/text";
import { COLORS } from "../../../styles/colors";
import { AttendeeStatusLabel } from "../../../styles/labels";
import { goToProfile, goToReport } from "../../../utils/navUtil";
import { PersonListItem } from "../../../components/people/PersonListItem";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import Skeleton from "react-loading-skeleton";

type AttendeesListItemProps = {
  attendee: AttendeeFull;
  eventDetails: EventFull;
  groupDetails: GroupFull;
  conflictType?: string;
  birdsEyeViewOn: boolean;
  canModifyRsvp: boolean;
  canMessage: boolean;
};

export const AttendeesListItem = (props: AttendeesListItemProps) => {
  const {
    attendee,
    eventDetails,
    groupDetails,
    canModifyRsvp,
    conflictType,
    canMessage,
  } = props;
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [paidFlag, setPaidFlag] = useState(attendee.paidFlag);
  const [attendedFlag, setAttendedFlag] = useState(attendee.attendedFlag);
  const colors = COLORS["light"];

  const visibleDate =
    isInThePast(new Date(props.eventDetails.rsvpDate)) && // RSVPs are open
    attendee.signupDate < eventDetails.rsvpDate // this is an earlybird signup
      ? new Date(props.eventDetails.rsvpDate) // use the rsvpDate so earlybird signup date is hidden
      : new Date(props.attendee.signupDate); // before the rsvpDate only earlybirds & up can see this anyway
  const togglePaid = () => {
    setPaidFlag(!paidFlag);
    fetcher.submit(
      {
        contextAction: "setPaid",
        contextValue: (!paidFlag).toString(),
        auxId: attendee.id ? attendee.id : "",
      },
      { method: "POST", action: "attendeeContextAction" }
    );
  };

  const toggleAttendance = () => {
    setAttendedFlag(!attendedFlag);
    fetcher.submit(
      {
        contextAction: "setAttended",
        contextValue: (!attendedFlag).toString(),
        auxId: attendee.id ? attendee.id : "",
      },
      { method: "POST", action: "attendeeContextAction" }
    );
  };

  const doReport = () => {
    const returnUri = `/attendees/${groupDetails.id}/${eventDetails.id}/`;

    const encodedFullName = encodeURIComponent(
      `${attendee.firstName} ${attendee.lastName}`
    );
    const reportQueryString = `?targetType=attendee&targetId=${attendee.id}&targetName=${encodedFullName}&returnUri=${returnUri}`;
    goToReport(navigate, reportQueryString);
  };

  const doMessage = () => {
    const queryString = `?userId=${attendee.userId}&firstName=${attendee.firstName}&lastName=${attendee.lastName}`;
    navigate("/composemessage/dm/" + queryString);
  };
  const attendeeActionButtons: ContextMenuItem[] = [];

  const doMoveToWaitlist = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={
              "Do you really want to move " +
              attendee.firstName +
              "" +
              attendee.lastName +
              "  to the waitlist?"
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "moveToWaitlist", auxId: attendee.id },
                { method: "POST", action: "attendeeContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  const doMoveToNotGoing = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={
              "Do you really want to move " +
              attendee.firstName +
              "" +
              attendee.lastName +
              "  to 'Not Going'?"
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "moveToNotGoing", auxId: attendee.id },
                { method: "POST", action: "attendeeContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const doMoveToGoing = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Please Confirm"}
            content={
              "Do you really want to promote " +
              attendee.firstName +
              "" +
              attendee.lastName +
              " to 'Going'?"
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "moveToGoing", auxId: attendee.id },
                { method: "POST", action: "attendeeContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  if (canModifyRsvp && attendee.status !== "waitlist") {
    attendeeActionButtons.push({
      label: <TextRegular>Move to Waitlist</TextRegular>,
      value: "moveToWaitlist",
      action: doMoveToWaitlist,
    });
  }
  if (canModifyRsvp && attendee.status !== "notgoing") {
    attendeeActionButtons.push({
      label: <TextRegular>Move to Not Going</TextRegular>,
      value: "moveToNotGoing",
      action: doMoveToNotGoing,
    });
  }
  if (canModifyRsvp && attendee.status !== "going") {
    attendeeActionButtons.push({
      label: <TextRegular>Move to Going</TextRegular>,
      value: "moveToGoing",
      action: doMoveToGoing,
    });
  }
  if (canMessage) {
    attendeeActionButtons.push({
      label: <TextRegular>Message This Attendee</TextRegular>,
      value: "message",
      action: doMessage,
    });
  }
  attendeeActionButtons.push({
    label: <TextRegular>Report This Attendee</TextRegular>,
    value: "report",
    action: doReport,
  });

  useEffect(() => {
    setPaidFlag(attendee.paidFlag);
    setAttendedFlag(attendee.attendedFlag);
  }, [attendee]);

  return (
    <HorizontalSpaceBetweenContainer
      style={{
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: colors.LIGHT_GRAY,
      }}
    >
      <PersonListItem
        firstName={props.attendee.firstName}
        lastName={props.attendee.lastName}
        imageUri={props.attendee.imageUri}
        thumbnailSize={44}
        role={getUserTitle(
          props.attendee.userId,
          props.eventDetails,
          props.groupDetails
        )}
        onClick={() => goToProfile(navigate, props.attendee.userId)}
      >
        {navigation.state === "loading" ? (
          <Skeleton height={20} width={100} count={1} />
        ) : !props.birdsEyeViewOn ? (
          <TextSmall color={colors.SECONDARY_TEXT}>
            {formatDateTime(new Date(visibleDate))}
            {/* {formatDateTime(new Date(visibleDate), false, attendee.timeZone)} */}
          </TextSmall>
        ) : (
          <HorizontalLeftAlignedContainer>
            <TouchableOpacity
              onClick={togglePaid}
              style={{
                borderStyle: "none",
                backgroundColor: "transparent",
              }}
            >
              <AttendeeStatusLabel type="payment" actioned={paidFlag} />
            </TouchableOpacity>
            {eventDetails.date &&
              canTakeAttendance(new Date(props.eventDetails.date)) && (
                <div style={{ marginLeft: LINE_HORIZONTAL_SPACING }}>
                  <TouchableOpacity
                    onClick={() => toggleAttendance()}
                    style={{
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <AttendeeStatusLabel
                      type="attendance"
                      actioned={attendedFlag}
                    />
                  </TouchableOpacity>
                </div>
              )}
            {eventDetails.date &&
              canShowConflicts(
                new Date(eventDetails.date),
                eventDetails.duration
              ) &&
              conflictType === "red" && (
                <AttendeeStatusLabel type="conflict" actioned={true} />
              )}
          </HorizontalLeftAlignedContainer>
        )}
      </PersonListItem>

      <HorizontalRightAlignedContainer>
        <NewCustomContextMenu contextMenuItems={attendeeActionButtons} />
      </HorizontalRightAlignedContainer>
    </HorizontalSpaceBetweenContainer>
  );
};
