import React from "react";
import { useNavigate } from "react-router-dom";
import { TextRegular } from "../../../styles/text";
import { ThreadFull } from "../../../types";
import { ThreadSummaryCard } from "../Components/ThreadSummaryCard";
import { goToMessageHistory } from "../../../utils/navUtil";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyMessages } from "../../../assets/images/empty-state/empty-messages.svg";

type ChatThreadsSectionProps = {
  chatThreadsData: ThreadFull[] | undefined;
  chatThreadsDataIsSuccess: boolean;
};

export const ChatThreadsSection = (props: ChatThreadsSectionProps) => {
  const { chatThreadsDataIsSuccess, chatThreadsData } = props;
  const navigate = useNavigate();
  const handlePress = (id: string, title: string) => {
    goToMessageHistory(navigate, "dm", id, title);
  };
  return (
    <>
      {chatThreadsDataIsSuccess &&
        (!chatThreadsData || chatThreadsData.length <= 0) && (
          <EmptyLayout title="No threads yet" subTitle="">
            <EmptyMessages />
          </EmptyLayout>
        )}
      {chatThreadsDataIsSuccess &&
        chatThreadsData &&
        chatThreadsData.length > 0 && (
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              overflowY: "auto",
              overflowX: "hidden",
              paddingTop: ITEM_VERTICAL_SPACING,
            }}
          >
            {chatThreadsData.map((threadDetails, index) => (
              <ThreadSummaryCard
                key={index}
                threadDetails={threadDetails}
                onPress={() =>
                  handlePress(threadDetails.destinationId, threadDetails.title)
                }
                cardShape={"rounded rect"}
                messageType={"dm"}
              />
            ))}
          </div>
        )}
    </>
  );
};
