import React from "react";
import { MyGroupListSection } from "../Sections/MyGroupListSection";
import { useWindowHeight } from "@react-hook/window-size";
import { HEADER_VERTICAL_SPACING } from "../../../styles/spacing";
import { UseInfiniteQueryResult } from "react-query";
import { GroupFull } from "../../../types";

export type MyGroupTabProps = {
  isSelected: boolean;
  tabHeight: number;
  useUsersGroupsResult: UseInfiniteQueryResult<GroupFull[]>;
};

export const MyGroupsTab = (props: MyGroupTabProps) => {
  const windowHeight = useWindowHeight();
  // const [height, setHeight] = useState(500);
  // const divRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (!divRef.current) return;
  //   setHeight(divRef.current.offsetHeight);
  // }, []);

  return (
    <MyGroupListSection
      useUsersGroupsResult={props.useUsersGroupsResult}
      infiniteScrollHeight={
        windowHeight - HEADER_VERTICAL_SPACING - props.tabHeight - 16
      }
    />
  );
};
