import React from "react";
import {
  redirect,
  useActionData,
  useLoaderData,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { CognitoUser } from "@aws-amplify/auth";
import { LoginScreenLayout } from "./LoginScreenLayout";
import { useEffect, useState } from "react";
import { logSentryError } from "../../utils/sentryUtil";

export type LoginErrors = {
  ok?: boolean;
  newPasswordRequired?: boolean;
  confirmEmailRequired?: boolean;
  forceNewPassword?: boolean;
  email?: string;
  password?: string;
  other?: string;
  user?: CognitoUser;
};

const validateLoginData = (email: string, password: string) => {
  const err: LoginErrors = {};

  if (!email) err.email = "Please enter your email address";
  if (!password) err.password = "Please enter your password";

  return err;
};

export const loginAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  // const navigate = useNavigate();
  const data = await request.formData();
  const emailAddress = fdGetString(data, "emailaddress");
  const password = fdGetString(data, "password");

  const validateResult = validateLoginData(emailAddress, password);
  if (Object.keys(validateResult).length) {
    return { ok: false, ...validateResult } as LoginErrors;
  }

  try {
    const user = await Auth.signIn(emailAddress, password);
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      return { ok: false, forceNewPassword: true, user: user } as LoginErrors;
    }
    return redirect("/loggingIn/");
    // return { ok: true } as LoginErrors;
  } catch (e: any) {
    switch (e.code) {
      // The email/password are correct, but the account has not yet been confirmed
      // so navigate to the ConfirmEmailScreen
      case "UserNotConfirmedException":
        return { ok: false, confirmEmailRequired: true } as LoginErrors;

      case "PasswordResetRequiredException":
        // email/password are correct but Amplify is requiring a new password
        return {
          ok: false,
          newPasswordRequired: true,
        } as LoginErrors;

      case "UserNotFoundException":
        return {
          ok: false,
          email:
            "User not found!  Please check your spelling or create a new account.",
        };

      case "NotAuthorizedException":
        return {
          ok: false,
          password: "Password incorrect.  Please check your spelling.",
        } as LoginErrors;

      default:
        logSentryError("Error logging in user with Amplify!", e, {
          tags: {
            userId: "none",
            screen: "LoginScreen",
            function: "loginAction",
          },
          level: "error",
        });
        return {
          ok: false,
          other: e.code ? e.code : "Unknown error, please try again.",
        } as LoginErrors;
    }
  }
};

export const loginLoader = async (request: Request, currentUserId: string) => {
  if (!!currentUserId) {
    const url = new URL(request.url);
    const returnUrl = url.searchParams.get("returnUrl");
    return redirect(returnUrl ?? "/events");
  }

  return { ok: true } as LoginErrors;
};

export const LoginScreen = () => {
  const errors = useActionData() as LoginErrors;
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  if (errors?.newPasswordRequired) {
    // this feels wrong, I dont' think they have a code??  Not sure
    // this workflow happens
    navigate("/newPassword", { state: { email: emailAddress } });
  }

  if (errors?.confirmEmailRequired) {
    navigate("/confirmAccount", {
      state: { email: emailAddress, password: password },
    });
  }

  if (errors?.forceNewPassword && errors.user) {
    navigate("/forceNewPassword", { state: { user: errors.user } });
  }

  useEffect(() => {
    document.title = "Login -- Picklers";
  }, []);

  return (
    <LoginScreenLayout
      errors={errors}
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      password={password}
      setPassword={setPassword}
    />
  );
};
