import { UseInfiniteQueryResult } from "react-query";
import { MemberFull } from "../../../types";
import { H2, TextRegular } from "../../../styles/text";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ApplicantListItem } from "../Components/ApplicantListItem";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyApplicants } from "../../../assets/images/empty-state/empty-groups.svg";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";

type ReviewApplicantsSectionProps = {
  applicantsData: UseInfiniteQueryResult<MemberFull[]>;
};

export const ReviewApplicantsSection = (
  props: ReviewApplicantsSectionProps
) => {
  const { applicantsData } = props;
  const {
    data,
    isSuccess,
    isError,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = applicantsData;
  const [members, setMembers] = useState<MemberFull[]>([]);

  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreMembers = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (isSuccess && data) {
      setMembers(data.pages.map((page) => (page ? page : [])).flat());
    }
  }, [data, isSuccess]);

  return (
    <>
      <H2> Review Applicants</H2>
      <div
        id="applicantList"
        style={{
          height: 500,
          width: "100%",
          overflow: "auto",
          marginTop: ITEM_VERTICAL_SPACING,
        }}
      >
        {isLoading && <TextRegular>Loading applicants</TextRegular>}
        {isError && <TextRegular>Error while loading applicants</TextRegular>}
        <InfiniteScroll
          style={{ paddingBottom: 200 }}
          dataLength={members.length} //This is important field to render the next data
          next={fetchMoreMembers}
          hasMore={hasMore()}
          loader={<h4>Loading...</h4>}
          scrollableTarget="applicantList"
        >
          {members.length <= 0 && (
            <EmptyLayout
              title="All applications have been processed."
              subTitle=""
            >
              <EmptyApplicants />
            </EmptyLayout>
          )}
          {/** existing members */}
          {members.map((item, index) => (
            <ApplicantListItem key={index} memberData={item} />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};
