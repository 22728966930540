import { HorizontalContainer } from "../../styles/container";
import { ITEM_VERTICAL_SPACING } from "../../styles/spacing";
import { TextRegular, TextSmall } from "../../styles/text";

type EmptyLayoutProps = {
  title?: string;
  subTitle?: string;
};

export const EmptyLayout = (
  props: React.PropsWithChildren<EmptyLayoutProps>
) => {
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 72,
        // backgroundColor: "pink",
      }}
    >
      <HorizontalContainer
        style={{
          height: 300,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </HorizontalContainer>
      <HorizontalContainer
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: ITEM_VERTICAL_SPACING,
        }}
      >
        <TextRegular>
          {props.title ? props.title : "Error loading data"}
        </TextRegular>
      </HorizontalContainer>
      <HorizontalContainer
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextSmall>
          {props.subTitle
            ? props.subTitle
            : "If you expected to find something, check your filters."}
        </TextSmall>
      </HorizontalContainer>
    </div>
  );
};
