import React, { useState } from "react";
import { COLORS } from "../../styles/colors";
import { ConfirmAccountErrors } from "./ConfirmAccountScreen";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { Form, useNavigation } from "react-router-dom";
import { ItemVerticalSpacer } from "../../styles/divider";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { TitledInput } from "../../components/inputs/TitledInput";

type ConfirmAccountScreenLayoutProps = {
  errors: ConfirmAccountErrors;
  email: string;
  password: string;
};

export const ConfirmAccountScreenLayout = (
  props: ConfirmAccountScreenLayoutProps
) => {
  const { errors, email, password } = props;

  const colors = COLORS["light"];

  const [confirmationCode, setConfirmationCode] = useState("");
  const [emailAddress, setEmailAddress] = useState(email);
  const navigation = useNavigation();

  const isBusy = () => {
    return navigation.state !== "idle";
  };

  return (
    <ScreenContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: HEADER_VERTICAL_SPACING,
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 100,
        }}
      >
        <Form action="/confirmAccount" method="POST" style={{ width: "100%" }}>
          <HorizontalLeftAlignedContainer
            style={{ marginBottom: SECTION_VERTICAL_SPACING }}
          >
            <H1 color={colors.PRIMARY_TEXT_COLOR}>Confirm your Email</H1>
          </HorizontalLeftAlignedContainer>
          <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
            <TextRegular>
              Your confirmation code should have been sent to your email. If you
              don't see it within 10 minutes, check your spam folder.
            </TextRegular>
          </HorizontalContainer>

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Email address"
              formDataName={"email"}
              value={emailAddress}
              setText={setEmailAddress}
              required={true}
              editable={!email}
              placeholderText="Enter email address"
              maxLength={64}
              autoComplete="email"
            />
          </HorizontalContainer>
          {errors?.email && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.email}</TextSmall>
            </HorizontalContainer>
          )}
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TitledInput
              title="Code"
              formDataName={"code"}
              value={confirmationCode}
              setText={setConfirmationCode}
              required={true}
              editable={true}
              placeholderText="Enter confirmation code (from your email)"
              maxLength={64}
              autoComplete="one-time-code"
            />
          </HorizontalContainer>
          {errors?.code && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.code}</TextSmall>
            </HorizontalContainer>
          )}
          {errors?.codeMismatch && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.codeMismatch}</TextSmall>
            </HorizontalContainer>
          )}
          {errors?.codeExpired && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.codeExpired}</TextSmall>
            </HorizontalContainer>
          )}
          {errors?.codeNotAuthorized && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.codeNotAuthorized}</TextSmall>
            </HorizontalContainer>
          )}
          {errors?.userNotFound && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TextSmall color="red">{errors.userNotFound}</TextSmall>
            </HorizontalContainer>
          )}
          <ItemVerticalSpacer />
          {errors?.other && (
            <HorizontalContainer
              style={{ marginBottom: ITEM_VERTICAL_SPACING }}
            >
              <TextSmall color="red">{errors.other}</TextSmall>
            </HorizontalContainer>
          )}
          <input type="hidden" name="email" value={emailAddress} />
          <input type="hidden" name="password" value={password} />
          <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
            <SubmitButton loading={isBusy()} title={"Confirm"} />
          </HorizontalContainer>
        </Form>
        <Form action="/confirmAccount" method="POST" style={{ width: "100%" }}>
          <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
            <input type="hidden" name="email" value={emailAddress} />
            <input type="hidden" name="password" value={password} />
            <input type="hidden" name="resend" value="resend" />
            <SubmitButton loading={isBusy()} title={"Re-send Code"} />
          </HorizontalContainer>
        </Form>
        {isBusy() && (
          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <BeatLoader color={colors.PRIMARY_BRAND} loading={true} size={14} />
          </HorizontalContainer>
        )}
      </div>
    </ScreenContainer>
  );
};
