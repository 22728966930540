import React, { useContext } from "react";
import { QueryClient } from "react-query";
import { ComposeScreenLayout } from "./ComposeScreenLayout";
import { fdGetImage, fdGetString } from "../../utils/formUtils";
import { MessageCreate, PeopleData, ThreadCreate } from "../../types";
import {
  createDMThread,
  sendMessage,
  uploadMessageImageFile,
} from "../../hooks/queryMessages";
import {
  redirect,
  useActionData,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useUsersFriends,
  usersFriendsQuery,
} from "../../hooks/queryMemberships";
import { ScreenContainer } from "../../features/ScreenContainer";
import { userContext } from "../../UserContext";
import { logSentryError } from "../../utils/sentryUtil";

export type ComposeMessageParams = {
  messageType?: string;
  destinationId?: string;
};

export type ComposeErrors = {
  recipients?: string;
  content?: string;
  generic?: string;
  ok?: boolean;
};

const validateComposeData = (data: ThreadCreate, image: File | null) => {
  const err: ComposeErrors = {};

  if (data.userIds.length <= 0) err.recipients = "No recipients selected";

  if (!data.messageText && !image)
    err.content = "A message or an image must be provided";

  return err;
};

const validateReplyData = (data: MessageCreate, image: File | null) => {
  const err: ComposeErrors = {};

  if (!data.messageText && !image)
    err.content = "A message or an image must be provided";

  return err;
};

// Get the data necessary to create a DM from our formData
const extractComposeData = (data: FormData) => {
  const recipientsJSON = fdGetString(data, "recipients");
  const recipients: PeopleData[] = JSON.parse(recipientsJSON);

  const userIds: string[] = [];
  let title = "";
  recipients.forEach((recipient) => {
    userIds.push(recipient.userId);
    if (title) title += ", ";
    title = title + recipient.firstName;
  });
  return {
    userIds: userIds,
    title: title,
    userId: fdGetString(data, "userid"),
    messageText: fdGetString(data, "messagetext").trim(),
  } as ThreadCreate;
};

// Get the data necessary to create a Group or Event message
// from our formData and our params
const extractReplyData = (
  data: FormData,
  messageType: string,
  destinationId: string
) => {
  return {
    messageType: messageType,
    destinationId: destinationId,
    userId: fdGetString(data, "userid"),
    messageText: fdGetString(data, "messagetext").trim(),
  } as MessageCreate;
};

// our loader, for compose we don't do any queries
// so we don't need to do anything?
// maybe pre-load user's friends?
export const composeMessageLoader = async (
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  if (currentUserId) {
    const query = usersFriendsQuery(currentUserId);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

// our action, which takes care of actually
// posting the new message to the database
export const composeMessageAction = async (
  params: ComposeMessageParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const messageType = params.messageType ? params.messageType : "dm";
  const destinationId = params.destinationId ? params.destinationId : "";
  const data = await request.formData();
  const defaultErrorMessage = "There was an error sending this message.";

  if (messageType === "dm") {
    try {
      // this is a direct message, we may need to create it first
      const threadData = extractComposeData(data);
      const messageImage = await fdGetImage(data, "messageimage");

      const validateResult = validateComposeData(threadData, messageImage);
      if (Object.keys(validateResult).length) {
        return {
          ok: false,
          generic: defaultErrorMessage,
          ...validateResult,
        };
      }
      const result = await createDMThread(threadData);
      // whether we created the thread or found it and added to it, the id is returned here
      const threadId = result.id;
      const messageId = result.messageId;
      if (messageImage) await uploadMessageImageFile(messageId, messageImage);

      // invalidate the query that sets up the Inbox dm tab
      queryClient.invalidateQueries(["dmThreads", threadData.userId]);
      // if necessary, update the messagehistory query
      if (destinationId)
        queryClient.invalidateQueries(["messages", messageType, destinationId]);

      // should always redirect to the new or existing DM messagehistory page
      return redirect("/messagehistory/dm/" + threadId);
    } catch (e: any) {
      logSentryError("Error sending a direct message.", e, {
        tags: {
          userId: currentUserId,
          screen: "ComposeScreen",
          function: "composeMessageLoader",
        },
        level: "error",
      });
      return { generic: defaultErrorMessage, ok: false };
    }
  } else {
    try {
      // for group and event messages, we just need to add a message,
      // so get the necessary data
      const messageData = extractReplyData(data, messageType, destinationId);
      const messageImage = await fdGetImage(data, "messageimage");
      const validateResult = validateReplyData(messageData, messageImage);
      if (Object.keys(validateResult).length) {
        return {
          ok: false,
          generic: defaultErrorMessage,
          ...validateResult,
        };
      }
      // send the message
      const result = await sendMessage(messageData);
      if (messageImage) await uploadMessageImageFile(result.id, messageImage);

      // invalidate the message list, the thread summary, and the destination's lastmessage item
      queryClient.invalidateQueries([["messages", messageType, destinationId]]);
      queryClient.invalidateQueries([
        messageType + "Threads",
        messageData.userId,
      ]);
      queryClient.invalidateQueries([messageType, destinationId]);

      // redirect to the correct destination's messages page
      if (messageType === "group")
        return redirect("/group/discussion/" + destinationId);
      else return redirect("/event/comments/" + destinationId);
    } catch (e: any) {
      logSentryError("Error sending a group or event message.", e, {
        tags: {
          userId: currentUserId,
          groupId:
            params?.messageType == "group" ? params?.destinationId : undefined,
          eventId:
            params?.messageType == "event" ? params?.destinationId : undefined,
          dmThreadId:
            params?.messageType == "dm" ? params?.destinationId : undefined,
          screen: "ComposeScreen",
          function: "composeMessageLoader",
        },
        level: "error",
      });
      return { generic: defaultErrorMessage, ok: false };
    }
  }
};

export const ComposeScreen = () => {
  const params = useParams();
  const errors = useActionData() as ComposeErrors;

  const [searchParams] = useSearchParams();
  const userInfo = useContext(userContext);
  const destinationId =
    params && params.destinationId ? params.destinationId : "";
  const messageType = params && params.messageType ? params.messageType : "dm";
  const recipient: PeopleData = {
    userId: searchParams.get("userId") ?? "",
    firstName: searchParams.get("firstName") ?? "",
    lastName: searchParams.get("lastName") ?? "",
    imageUri: searchParams.get("") ?? "",
  };

  const friendsQueryResult = useUsersFriends(userInfo.id);

  return (
    <>
      <ScreenContainer horizontalPadding={0}>
        <ComposeScreenLayout
          friendsQueryResult={friendsQueryResult}
          messageType={messageType}
          destinationId={destinationId}
          recipient={recipient}
          errors={errors}
        />
      </ScreenContainer>
    </>
  );
};
