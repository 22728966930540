import React, { useContext, useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { userContext } from "../../UserContext";

import { QueryClient } from "react-query";
import { useVenues, venuesQuery } from "../../hooks/queryVenues";
import { LocationFilterData } from "../../types/locationTypes";
import { getDefaultLocationData } from "../../features/filters/constants";
import { CourtType, VerificationStatusFilter } from "../../types/enums";
import { VenuesScreenLayout } from "./VenuesScreenLayout";
import { ScreenContainer } from "../../features/ScreenContainer";

export type VenuesFilterInfo = {
  latitudeFilter: number;
  setLatitudeFilter: React.Dispatch<React.SetStateAction<number>>;
  longitudeFilter: number;
  setLongitudeFilter: React.Dispatch<React.SetStateAction<number>>;
  mileageFilter: number;
  setMileageFilter: React.Dispatch<React.SetStateAction<number>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  displayAddress: string;
  setDisplayAddress: React.Dispatch<React.SetStateAction<string>>;
  userLocation: LocationFilterData;
  courtTypeFilter: CourtType;
  setCourtTypeFilter: React.Dispatch<React.SetStateAction<CourtType>>;
  lightingFilter: string;
  setLightingFilter: React.Dispatch<React.SetStateAction<string>>;
  verificationStatusFilter: VerificationStatusFilter;
  setVerificationStatusFilter: React.Dispatch<
    React.SetStateAction<VerificationStatusFilter>
  >;
};

export const venuesLoader = async (
  queryClient: QueryClient,
  request: Request,
  currentUserId: string,
  latitude: number,
  longitude: number
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    // the initial loader state uses the default filters using user's default location
    const query = venuesQuery(currentUserId, latitude, longitude, 60);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const VenuesScreen = () => {
  const userInfo = useContext(userContext);
  const userLocation: LocationFilterData = getDefaultLocationData(userInfo);
  const [latitudeFilter, setLatitudeFilter] = useState(userLocation.lat);
  const [longitudeFilter, setLongitudeFilter] = useState(userLocation.long);
  const [mileageFilter, setMileageFilter] = useState(userLocation.mileage);
  const [address, setAddress] = useState(userLocation.addressText);
  const [displayAddress, setDisplayAddress] = useState(
    userLocation.displayAddress
  );
  const [courtTypeFilter, setCourtTypeFilter] = useState<CourtType>(
    CourtType.Any
  );
  const [lightingFilter, setLightingFilter] = useState<string>("Any");
  const [verificationStatusFilter, setVerificationStatusFilter] = useState(
    VerificationStatusFilter.Any
  );

  const venuesQueryResult = useVenues(
    userInfo.id,
    latitudeFilter,
    longitudeFilter,
    mileageFilter,
    courtTypeFilter === CourtType.Any ? undefined : courtTypeFilter,
    verificationStatusFilter === VerificationStatusFilter.Any
      ? undefined
      : verificationStatusFilter,
    lightingFilter === "Any" ? undefined : lightingFilter
  );

  useEffect(() => {
    document.title = "Venues -- Picklers";
  }, []);

  return (
    <ScreenContainer>
      <VenuesScreenLayout
        venuesQueryResult={venuesQueryResult}
        filterInfo={{
          latitudeFilter: latitudeFilter,
          setLatitudeFilter: setLatitudeFilter,
          longitudeFilter: longitudeFilter,
          setLongitudeFilter: setLongitudeFilter,
          mileageFilter: mileageFilter,
          setMileageFilter: setMileageFilter,
          address: address,
          setAddress: setAddress,
          displayAddress: displayAddress,
          setDisplayAddress: setDisplayAddress,
          userLocation: userLocation,
          courtTypeFilter: courtTypeFilter,
          setCourtTypeFilter: setCourtTypeFilter,
          lightingFilter: lightingFilter,
          setLightingFilter: setLightingFilter,
          verificationStatusFilter: verificationStatusFilter,
          setVerificationStatusFilter: setVerificationStatusFilter,
        }}
      />
    </ScreenContainer>
  );
};
