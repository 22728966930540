import React, { useEffect, useState } from "react";
import { ImagePicker } from "../../../components/image/ImagePicker";
import { blankVenueImageURL } from "../../../constants/defaultImageURLs";
import { HorizontalLeftAlignedContainer } from "../../../styles/container";
import { TitledInput } from "../../../components/inputs/TitledInput";
import { TitledLocationPicker } from "../../../features/TitledLocationPicker/TitledLocationPicker";
import { TitledTextarea } from "../../../components/inputs/TitledTextarea";
import { Location } from "../../../features/TitledLocationPicker/LocationPicker";
import { VenueFull, defaultVenueDetails } from "../../../types";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../../constants/defaultLocation";
import { TextRegular } from "../../../styles/text";
import { VenueErrors } from "../EditVenueScreen";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";

type EditVenueGeneralInfoProps = {
  limitEdits: boolean;
  venueDetails: VenueFull;
  errors?: VenueErrors;
};

export const EditVenueGeneralInfoSection = (
  props: EditVenueGeneralInfoProps
) => {
  const { limitEdits, venueDetails, errors } = props;

  const [venueName, setVenueName] = useState(
    venueDetails ? venueDetails.name : ""
  );

  const [venueImage, setVenueImage] = useState(
    venueDetails ? venueDetails.imageUri : ""
  );

  const [venueNickname, setVenueNickname] = useState(
    venueDetails ? venueDetails.nickname : ""
  );

  const [venueDescription, setVenueDescription] = useState(
    venueDetails ? venueDetails.description : ""
  );

  const initialLocation: Location = {
    address: venueDetails ? venueDetails.address : defaultVenueDetails.address,
    displayAddress: venueDetails
      ? venueDetails.displayAddress
      : defaultVenueDetails.address,
    lat: venueDetails ? venueDetails.latitude : defaultLatitude,
    lng: venueDetails ? venueDetails.longitude : defaultLongitude,
  };
  const [venueLocation, setVenueLocation] = useState(initialLocation);

  useEffect(() => {
    setVenueName(venueDetails.name);
    setVenueNickname(venueDetails.nickname);
    setVenueImage(venueDetails.imageUri);
    setVenueDescription(venueDetails.description);
    const updatedLocation: Location = {
      address: venueDetails
        ? venueDetails.address
        : defaultVenueDetails.address,
      displayAddress: venueDetails
        ? venueDetails.displayAddress
        : defaultVenueDetails.address,
      lat: venueDetails ? venueDetails.latitude : defaultLatitude,
      lng: venueDetails ? venueDetails.longitude : defaultLongitude,
    };
    setVenueLocation(updatedLocation);
  }, [venueDetails]);

  return (
    <>
      <HorizontalLeftAlignedContainer>
        <ImagePicker
          imgSrc={venueImage ? venueImage : blankVenueImageURL}
          formDataName="venueimage"
          setImgSrc={setVenueImage}
          width={300}
          height={200}
          borderRadius={0}
        />
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueName}
          formDataName="venuename"
          title="NAME"
          setText={setVenueName}
          placeholderText="Type the full name of the venue"
          maxLength={128}
          required={true}
          editable={!limitEdits}
        />
      </HorizontalLeftAlignedContainer>
      {errors?.name && (
        <HorizontalLeftAlignedContainer>
          <TextRegular color="red">{errors?.name}</TextRegular>
        </HorizontalLeftAlignedContainer>
      )}
      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueNickname}
          formDataName="venuenickname"
          title="NICKNAME"
          setText={setVenueNickname}
          placeholderText="Enter a shorter display name. (3-20 characters)"
          maxLength={20}
          required={true}
          editable={!limitEdits}
        />
      </HorizontalLeftAlignedContainer>
      {errors?.nickName && (
        <HorizontalLeftAlignedContainer>
          <TextRegular color="red">{errors?.nickName}</TextRegular>
        </HorizontalLeftAlignedContainer>
      )}
      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledLocationPicker
          title="LOCATION"
          location={venueLocation}
          setLocation={setVenueLocation}
          prefix="venue"
          required={true}
          disabled={limitEdits}
        />
      </HorizontalLeftAlignedContainer>
      {errors?.address && (
        <HorizontalLeftAlignedContainer>
          <TextRegular color="red">{errors?.address}</TextRegular>
        </HorizontalLeftAlignedContainer>
      )}
      {errors?.latlng && (
        <HorizontalLeftAlignedContainer>
          <TextRegular color="red">{errors?.latlng}</TextRegular>
        </HorizontalLeftAlignedContainer>
      )}

      <ItemVerticalSpace />
      <HorizontalLeftAlignedContainer>
        <TitledTextarea
          value={venueDescription}
          formDataName="venuedescription"
          title="ABOUT THIS VENUE"
          setText={setVenueDescription}
          placeholderText="Enter details about this venue"
          maxLength={10000}
        />
      </HorizontalLeftAlignedContainer>
    </>
  );
};
