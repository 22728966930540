import React, { Dispatch, SetStateAction, useState } from "react";
import { COLORS } from "../../styles/colors";
import { HorizontalSpaceBetweenContainer } from "../../styles/container";
import { TextRegular, TextSmall } from "../../styles/text";
import Drawer from "react-modern-drawer";
import { GroupFull, VenueFull } from "../../types";
import { VenuePicker } from "./VenuePicker";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../../components/buttons/NavButton";
import { EditTitle } from "../../components/inputs/EditTitle";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import TouchableOpacity from "../../components/general/TouchableOpacity";

export type TitledVenuePickerProps = {
  title: string;
  formDataName: string;
  venue: VenueFull;
  setVenue: Dispatch<SetStateAction<VenueFull>>;
  groupDetails: GroupFull;
  required?: boolean;
};

export const TitledVenuePicker = (props: TitledVenuePickerProps) => {
  const { title, formDataName, venue, setVenue, groupDetails, required } =
    props;
  const colors = COLORS["light"];
  const [showVenuePicker, setShowVenuePicker] = useState(false);
  const navigation = useNavigation();

  const toggleVenuePickerDrawer = () => {
    if (navigation.state !== "loading")
      setShowVenuePicker((isOpen) => {
        return !isOpen;
      });
  };

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
        display: "flex",
      }}
    >
      <Drawer
        open={showVenuePicker}
        onClose={() => toggleVenuePickerDrawer()}
        direction="right"
        className="venue-picker"
        size={500}
        lockBackgroundScroll={true}
        zIndex={2000}
      >
        <VenuePicker
          groupDetails={groupDetails}
          closePicker={() => setShowVenuePicker(false)}
          setVenue={setVenue}
        />
      </Drawer>
      <EditTitle title={title} required={required} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <TouchableOpacity
          onClick={() => setShowVenuePicker(true)}
          style={{
            flex: 1,
            padding: 0,
            margin: 0,
            borderStyle: "none",
            backgroundColor: "transparent",
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: colors.LIGHT_GRAY,
              borderRadius: getBorderRadius("small", "rounded rect"),
              flex: 1,
              paddingLeft: 4,
              paddingTop: 7,
              paddingBottom: 7,
              paddingRight: 4,
            }}
          >
            <TextRegular>
              {venue.name ? venue.name : venue.nickname}
            </TextRegular>
            <NavButton
              iconName="location"
              onClick={() => setShowVenuePicker(true)}
              color={colors.SECONDARY_TEXT}
              size={16}
              // loading={navigation.state === "loading"}
            />
            <input type="hidden" name={formDataName} value={venue.id} />
          </HorizontalSpaceBetweenContainer>
        </TouchableOpacity>
        //         <HorizontalSpaceBetweenContainer
        //   style={{
        //     borderStyle: "solid",
        //     borderWidth: 1,
        //     borderColor: colors.LIGHT_GRAY,
        //     borderRadius: getBorderRadius("tiny", "rounded rect"),
        //     flex: 1,
        //     paddingLeft: 4,
        //     paddingTop: 4,
        //     paddingBottom: 4,
        //     paddingRight: 4,
        //   }}
        // >
        //   <TextRegular>{venue.name ? venue.name : venue.nickname}</TextRegular>
        //   <NavButton
        //     iconName="location"
        //     onClick={() => setShowVenuePicker(true)}
        //     color={colors.SECONDARY_TEXT}
        //     size={16}
        //   />
        //   <input type="hidden" name={formDataName} value={venue.id} />
        // </HorizontalSpaceBetweenContainer>
      )}
    </div>
  );
};
