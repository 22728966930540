import * as Sentry from "@sentry/react";

const APP_VARIANT = process.env.REACT_APP_APP_VARIANT;

type ContextTags = {
    userId: string,
    screen: string,
    username?: string,
    groupId?: string,
    eventId?: string,
    venueId?: string,
    dmThreadId?: string,
    messageId?: string,
    section?: string,
    function?: string,
}

type CaptureContext = {
    tags?: ContextTags,
    // extra?: ContextExtras,
    level: "warning" | "error"
}

export const logSentryError = (errorMessage: string, error: any, context: CaptureContext) => {
    if (APP_VARIANT === "development") {
        console.log("SENTRY:" + errorMessage, error, context)
    }
    else {
        Sentry.withScope((scope) => {
            if (context.tags) scope.setTags(context.tags);
            scope.setTag("errorMessage", errorMessage);
            scope.setLevel(context.level);
            Sentry.captureException(error);
        })
    }
}