import React from "react";
import { COLORS } from "../../styles/colors";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import { ProfileImage } from "../image/ProfileImage";
import { TextRegular } from "../../styles/text";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { getBorderRadius, getCardSize } from "../../utils";
import { InitialsProfilePic } from "../image/InitialsProfilePic";
import "./MemberPickerListItem.css";

type MemberSelectionItemProps = {
  firstName: string;
  lastName: string;
  imageUri: string;
  isPicked: boolean;
  pickedLabel?: string;
};

export const MemberSelectionItem = (props: MemberSelectionItemProps) => {
  const thumbnailSize = 32;
  const { firstName, lastName, imageUri, isPicked, pickedLabel } = props;
  let memberLabel = pickedLabel ? "(" + pickedLabel + ")" : "";
  const colors = COLORS["light"];
  return (
    <>
      <HorizontalLeftAlignedContainer
        className="member-picker-item"
        style={{ flex: 1, paddingLeft: LINE_HORIZONTAL_SPACING }}
      >
        <SizedContainer width={thumbnailSize} height={thumbnailSize}>
          {!!imageUri ? (
            <ProfileImage
              width={thumbnailSize}
              height={thumbnailSize}
              borderRadius={thumbnailSize / 2}
              imgSrc={imageUri}
            />
          ) : (
            <InitialsProfilePic
              firstName={firstName}
              lastName={lastName}
              fontSize={12}
              borderRadius={getBorderRadius("extra large", "rounded rect")}
            />
          )}
        </SizedContainer>
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        <TextRegular
          color={isPicked ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT_COLOR}
        >
          {firstName} {lastName} {memberLabel}
        </TextRegular>
      </HorizontalLeftAlignedContainer>
    </>
  );
};
