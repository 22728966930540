import {Buffer} from "buffer"
var CryptoJS = require("crypto-js");

export const addQueryItem = (queryString: string, key: string, value: string | null) : string => {
    let newQueryString = "";
    if (key && key.length > 0) {
        if (queryString.length <= 0) {
            newQueryString = "?" + key;

        }
        else {
            newQueryString = queryString + "&" + key;
        }
        if (value) {
            newQueryString = newQueryString + "=" + encodeURIComponent(value);
        } 
    }
    return newQueryString;
}
export const addQueryItem2 = (queryString: string, key: string, value: string | null) : string => {
    let newQueryString = "";
    if (key && key.length > 0) {
        if (queryString.length <= 0) {
            newQueryString = "?" + key;

        }
        else {
            newQueryString = queryString + "&" + key;
        }
        if (value) {
            newQueryString = newQueryString + "=" + value;
        } 
    }
    return newQueryString;
}

const url = require('url');

/**
 * Convert from 'web safe' base64 to true base64.
 *
 * @param  {string} safeEncodedString The code you want to translate
 *                                    from a web safe form.
 * @return {string}
 */
function removeWebSafe(safeEncodedString: string) {
  return safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');
}

/**
 * Convert from true base64 to 'web safe' base64
 *
 * @param  {string} encodedString The code you want to translate to a
 *                                web safe form.
 * @return {string}
 */
function makeWebSafe(encodedString: string) {
  return encodedString.replace(/\+/g, '-').replace(/\//g, '_');
}

/**
 * Takes a base64 code and decodes it.
 *
 * @param  {string} code The encoded data.
 * @return {string}
 */
function decodeBase64Hash(code: string) {
  return Buffer.from(code, 'base64');
}

/**
 * Takes a key and signs the data with it.
 *
 * @param  {string} key  Your unique secret key.
 * @param  {string} data The url to sign.
 * @return {string}
 */
function encodeBase64Hash(key: string, data: string) {
    const hash = CryptoJS.HmacSHA1(data, key);
    // const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA1, key);
    // hmac.update(data);
    // const hash = hmac.finalize();
    return hash;
}

/**
 * Sign a URL using a secret key.
 *
 * @param  {string} path   The url you want to sign.
 * @param  {string} secret Your unique secret key.
 * @return {string}
 */
export function signGPUri(path: string, secret: string) {
  const uri = url.parse(path);
  const safeSecret = decodeBase64Hash(removeWebSafe(secret));
  const hashedSignature = makeWebSafe(encodeBase64Hash(safeSecret.toString(), uri.path));
  return url.format(uri) + '&signature=' + hashedSignature;
}