import React, { useContext, useEffect, useState } from "react";
import { HorizontalContainer } from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { userContext } from "../../UserContext";
import { PeopleData } from "../../types";
import { TitledRecipientPicker } from "../../features/TitledRecipientPicker/TitledRecipientPicker";
import { MessageSendBar } from "../../components/MessageSendBar/MessageSendBar";
import { useNavigation } from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import {
  HEADER_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { UseQueryResult } from "react-query";
import Skeleton from "react-loading-skeleton";
import { PrimaryHeader } from "../../components/text/PrimaryHeader";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { ComposeErrors } from "./ComposeScreen";
import { StickyActionBar } from "../../components/StickyActionBar";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";

type ComposeScreenLayoutParams = {
  messageType: string;
  destinationId: string;
  recipient: PeopleData;
  friendsQueryResult: UseQueryResult<PeopleData[]>;
  errors?: ComposeErrors;
};

export const ComposeScreenLayout = (params: ComposeScreenLayoutParams) => {
  const userInfo = useContext(userContext);
  const { messageType, destinationId, recipient, friendsQueryResult, errors } =
    params;
  const navigation = useNavigation();
  const windowHeight = useWindowHeight();
  const thisUser: PeopleData = {
    userId: userInfo.id,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    imageUri: userInfo.imageUri,
  };
  const [recipients, setRecipients] = useState(
    recipient && recipient.userId ? [recipient, thisUser] : [thisUser]
  );

  // useEffect(() => {
  //   if (recipient.userId) {
  //     setRecipients([...recipients, recipient]);
  //   }
  // }, [recipient, recipients]);

  return (
    <div
      style={{
        height: windowHeight - LINE_VERTICAL_SPACING,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <StickyActionBar>
        <DetailsActionButtonBar backButtonTitle="Back" />
      </StickyActionBar>
      <SectionVerticalSpace />
      <PrimaryHeader>Compose New Message</PrimaryHeader>
      {errors?.generic && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.generic}</TextRegular>
        </HorizontalContainer>
      )}

      <SectionVerticalSpace />

      {!destinationId ? (
        <TitledRecipientPicker
          friendsQueryResult={friendsQueryResult}
          formDataName="recipients"
          recipients={recipients}
          setRecipients={setRecipients}
          maximum={20}
        />
      ) : (
        <>
          {navigation.state === "loading" ? (
            <Skeleton height={20} width={150} />
          ) : (
            <TextRegular>
              {messageType === "group"
                ? "Group Discussion Board"
                : "Event Comments Section"}
            </TextRegular>
          )}
        </>
      )}
      <HorizontalContainer style={{ width: "100%" }}>
        <MessageSendBar
          target={"/composemessage/" + messageType + "/"}
          messageType={messageType}
          destinationId={destinationId}
          canSendMessage={true}
          recipients={recipients}
        />
      </HorizontalContainer>
    </div>
  );
};
