import React, { useContext } from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Hub } from "aws-amplify";
import axios from "axios";

import { axiosRequestInterceptor } from "./hooks/tokenRefresher";
import {
  getUserData,
  setupAuthorizedUserContexts,
} from "./utils/authenticationUtil";
import { ProfileFull } from "./types";
import { userContext } from "./UserContext";
import { useQueryClient } from "react-query";
import {
  defaultLatitude,
  defaultLongitude,
  defaultTimeZone,
} from "./constants/defaultLocation";

import {
  threadHasNewMessage,
  threadLastReadContext,
} from "./screens/Inbox/Components/ThreadLastReadContext";

import { useEventRoutes } from "./routes/EventRoutes";
import { useProfileRoutes } from "./routes/ProfileRoutes";
import { useInboxRoutes } from "./routes/InboxRoutes";
import { useLoginRoutes } from "./routes/LoginRoutes";
import { useOtherRoutes } from "./routes/OtherRoutes";
import { useGroupRoutes } from "./routes/GroupRoutes";
import { useVenueRoutes } from "./routes/VenueRoutes";
import { AppOutlet } from "./AppOutlet";
import {
  useDMThreads,
  useEventThreads,
  useGroupThreads,
} from "./hooks/queryMessages";
import {
  LoginScreen,
  loginAction,
  loginLoader,
} from "./screens/Login/LoginScreen";
import { H1, TextRegular } from "./styles/text";
import { HorizontalLeftAlignedContainer } from "./styles/container";
import { guessTimeZone } from "./utils";
import { logSentryError } from "./utils/sentryUtil";

type PicklersRouterProps = {
  priorAuthUser: CognitoUser | null;
  priorUserData: ProfileFull | null;
};

export const PicklersRouter = (props: PicklersRouterProps) => {
  const queryClient = useQueryClient();
  const currentUserContext = useContext(userContext);
  const currentThreadLastReadContext = useContext(threadLastReadContext);
  const currentUserId = currentUserContext?.id;
  const [user, setUser] = React.useState<CognitoUser | null | undefined>(
    undefined
  );
  const [error, setError] = React.useState("");

  const { data: dmData, isSuccess: dmIsSuccess } = useDMThreads(
    !!currentUserId,
    currentUserId
  );
  const { data: gmData, isSuccess: gmIsSuccess } = useGroupThreads(
    !!currentUserId,
    currentUserId
  );
  const { data: emData, isSuccess: emIsSuccess } = useEventThreads(
    !!currentUserId,
    currentUserId
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppOutlet error={error} />}>
        {useLoginRoutes({
          currentUserId: currentUserId,
          queryClient: queryClient,
          user: user,
          error: error,
          userNoData: !!user && !currentUserId,
        })}
        {useEventRoutes({
          queryClient: queryClient,
          currentUserId: currentUserId,
          latitude: currentUserContext.latitude ?? defaultLatitude,
          longitude: currentUserContext.longitude ?? defaultLongitude,
        })}
        {useGroupRoutes({
          queryClient: queryClient,
          currentUserId: currentUserId,
          latitude: currentUserContext.latitude ?? defaultLatitude,
          longitude: currentUserContext.longitude ?? defaultLongitude,
        })}
        {useInboxRoutes({
          currentUserId: currentUserId,
          queryClient: queryClient,
        })}
        {useOtherRoutes({
          queryClient: queryClient,
          currentUserId: currentUserId,
        })}
        {useProfileRoutes({
          queryClient: queryClient,
          currentUserId: currentUserId,
        })}
        {useVenueRoutes({
          queryClient: queryClient,
          currentUserId: currentUserId,
          latitude: currentUserContext.latitude ?? defaultLatitude,
          longitude: currentUserContext.longitude ?? defaultLongitude,
        })}
        <Route path="*" element={<Navigate to={"/events"} replace />} />
      </Route>
    )
  );

  const prepareUser = async (
    priorAuthUser: CognitoUser | null,
    priorUserData: ProfileFull | null
  ) => {
    try {
      // get our authenticated user
      let authUser;
      if (priorAuthUser) {
        authUser = priorAuthUser;
      } else {
        authUser = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
      }
      setUser(authUser);
      /* set up our JWT token with axios so that we can talk to MongoDB securely */
      axios.interceptors.request.use(axiosRequestInterceptor, (e) =>
        Promise.reject(e)
      );

      /* Find our user's profile data */
      let userData;
      if (priorUserData) {
        /* If we have prior data, just use that */
        userData = priorUserData;
      } else {
        // find our userdata in MongoDB, or if necessary create it from what we have if we can
        try {
          userData = await getUserData(
            authUser.username,
            authUser.attributes.email,
            authUser.attributes.given_name,
            authUser.attributes.family_name
          );
        } catch (e: any) {
          if (e.code === "ERR_NETWORK") {
            // we don't need to log this one, it will be handled via the appropriate error screen
            setError(e.code + ": " + e.message);
          } else {
            // anything else we should log until we decide what to do with it
            logSentryError("Error retrieving user information!", e, {
              tags: {
                userId: "",
                screen: "RootNavigator",
                function: "prepareUser",
                username: authUser.username,
              },
              level: "error",
            });
          }
          return;
        }
      }
      if (currentUserContext) {
        await setupAuthorizedUserContexts(
          userData,
          currentUserContext,
          currentThreadLastReadContext
        );
      } else {
        throw new Error("Unable to set up currentUserContext");
      }
    } catch (e) {
      // no user has been authenticated, maybe reset userContext too?
      setUser(null);
      if (currentUserContext && currentUserContext.switchUser) {
        currentUserContext.switchUser(
          "",
          "",
          "",
          "",
          "",
          0,
          "",
          "",
          defaultLatitude,
          defaultLongitude,
          defaultTimeZone,
          null,
          false
        );
      }
      // logSentryError("Error setting up authenticated user!", e, {
      //   tags: {
      //     userId: "",
      //     screen: "RootNavigator",
      //     function: "prepareUser",
      //   },
      //   level: "error",
      // });
      // throw e;
    }
  };

  /* This useEffect checks for new Inbox messages so we can set up the blue dot appropriately */
  React.useEffect(() => {
    // only do this if all thread queries are done successfully and we have our last read context
    if (
      currentThreadLastReadContext &&
      dmIsSuccess &&
      gmIsSuccess &&
      emIsSuccess
    ) {
      let isNew = false; // it's false unless we find something

      if (
        threadHasNewMessage(
          dmData,
          currentThreadLastReadContext.dmThreadLastRead
        )
      ) {
        isNew = true;
      }

      if (
        !isNew && // don't even bother to check group messages if we found one already
        threadHasNewMessage(
          gmData,
          currentThreadLastReadContext.groupThreadLastRead
        )
      ) {
        isNew = true;
      }

      if (
        !isNew && // don't even bother to check event messages if we found one already
        threadHasNewMessage(
          emData,
          currentThreadLastReadContext.eventThreadLastRead
        )
      ) {
        isNew = true;
      }

      // now set our blue dot context flag to the result of our search
      if (
        currentUserContext &&
        currentUserContext.switchUser &&
        currentUserContext.user
      ) {
        if (isNew !== currentUserContext.hasUnread) {
          currentUserContext.switchUser(
            currentUserContext.id,
            currentUserContext.username,
            currentUserContext.firstName,
            currentUserContext.lastName,
            currentUserContext.imageUri,
            currentUserContext.adminLevel,
            currentUserContext.address,
            currentUserContext.displayAddress,
            currentUserContext.latitude,
            currentUserContext.longitude,
            currentUserContext.timeZone ?? guessTimeZone(),
            currentUserContext.user,
            isNew
          );
        }
      }
    }
  }, [dmData, gmData, emData, currentThreadLastReadContext]);

  React.useEffect(() => {
    prepareUser(props.priorAuthUser, props.priorUserData);
  }, [props.priorAuthUser, props.priorUserData]);

  React.useEffect(() => {
    const listener = (data: any) => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        setError("");
        prepareUser(null, null);
      } else if (data.payload.event === "signIn_failure") {
        // alert(
        //   "Error: There was a problem while attempting to log in.  Please try again!"
        // );
        // prepareUser(null, null);
      }
    };
    const unsubscribe = Hub.listen("auth", listener);
    return () => {
      unsubscribe();
    };
  }, []);

  // React.useEffect(() => {
  //   // Google Analytics
  //   if (GA_PROPERTY_ID) {
  //     ReactGA.send({
  //       hitType: "pageview",
  //       page: location.pathname,
  //     });
  //   }
  // }, [location]);

  return <RouterProvider router={router} />;
};
