import React, { useContext, useEffect, useRef, useState } from "react";
import { GroupFull, MemberFull } from "../../../types";
import { H2, TextRegular, TextSmall } from "../../../styles/text";
import { useGroupMembersLimited } from "../../../hooks/queryMemberships";
import { MemberListItem } from "../Components/MemberListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClickableDrawerRow } from "../../../components/rows/ClickableDrawerRow";
import { ReviewApplicantsSection } from "./ReviewApplicantsSection";
import { useWindowHeight } from "@react-hook/window-size";
import { UseInfiniteQueryResult } from "react-query";
import { memberIsOrganizer, memberIsOwner } from "../../../utils/userChecks";
import { userContext } from "../../../UserContext";

type GroupPeopleSectionProps = {
  groupDetails: GroupFull;
  memberData: MemberFull | undefined;
  membersQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  applicantsQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  showReviewApplicantsDrawer: boolean;
  toggleReviewApplicantsDrawer: () => void;
  togglePeopleDrawer: () => void;
};

export const GroupPeopleSection = (props: GroupPeopleSectionProps) => {
  const {
    groupDetails,
    memberData,
    membersQueryResult,
    applicantsQueryResult,
    showReviewApplicantsDrawer,
    toggleReviewApplicantsDrawer,
    togglePeopleDrawer,
  } = props;
  const groupId = groupDetails ? groupDetails.id : "";
  const userInfo = useContext(userContext);
  const [members, setMembers] = useState<MemberFull[]>([]);
  const [height, setHeight] = useState(500);
  const divRef = useRef<HTMLDivElement>(null);
  const windowHeight = useWindowHeight();
  const isAdmin = userInfo.adminLevel > 90;
  const {
    data: membersData,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = membersQueryResult;

  const isOrganizer =
    memberIsOrganizer(memberData) || memberIsOwner(memberData);
  const showNewApplicants =
    (isAdmin || isOrganizer) &&
    applicantsQueryResult.isSuccess &&
    !!applicantsQueryResult.data &&
    applicantsQueryResult.data.pages[0].length > 0;

  const hasMore = () => {
    const lastPage = membersData?.pages.slice(-1);
    return (
      !!membersQueryResult.hasNextPage &&
      !membersQueryResult.isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreMembers = () => {
    if (hasMore()) {
      membersQueryResult.fetchNextPage();
    }
  };
  useEffect(() => {
    if (isSuccess && membersData) {
      setMembers(membersData.pages.map((page) => (page ? page : [])).flat());
    }
  }, [membersData, isSuccess]);

  useEffect(() => {
    if (!divRef.current) return;
    setHeight(windowHeight - divRef.current.offsetHeight - 40);
  }, [windowHeight, divRef]);

  return (
    <>
      <div ref={divRef}>
        <H2>People</H2>
      </div>
      {showNewApplicants && (
        <ClickableDrawerRow
          title="There are new applicants"
          subTitle="Review Now!"
          showDrawer={showReviewApplicantsDrawer}
          toggleDrawer={toggleReviewApplicantsDrawer}
        >
          <ReviewApplicantsSection applicantsData={applicantsQueryResult} />
        </ClickableDrawerRow>
      )}

      <InfiniteScroll
        dataLength={members.length} //This is important field to render the next data
        next={fetchMoreMembers}
        hasMore={hasMore()}
        loader={<h4>Loading...</h4>}
        height={height}
        scrollableTarget="membersList"
      >
        {/** existing members */}
        {members.map((item, index) => (
          <MemberListItem
            key={index}
            groupDetails={groupDetails}
            currentMemberData={memberData}
            itemMemberData={item}
            togglePeopleDrawer={togglePeopleDrawer}
          />
        ))}
      </InfiniteScroll>
    </>
  );
};
