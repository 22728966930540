import React from "react";
import { ScreenContainer } from "../../features/ScreenContainer";
import { HEADER_VERTICAL_SPACING } from "../../styles/spacing";

export const FAQScreen = () => {
  return (
    <ScreenContainer>
      <div style={{ marginTop: HEADER_VERTICAL_SPACING }} />

      <h2>Frequently Asked Questions</h2>
      <h3> Account Management</h3>
      <ul>
        <li>
          <b>Profile Settings</b>
          <ol>
            <li>Select “Account” from the navigation menu.</li>
            <li>Tap the “Edit Profile” button in the upper right corner.</li>
            <li>Tap “Edit” to choose a profile picture.</li>
            <li>Fill out the form and press “Save”.</li>
          </ol>
        </li>
      </ul>
      <h3>Groups</h3>
      <ul>
        <li style={{ paddingBottom: 15 }}>
          <b>Creating a Group</b>
          <ol>
            <li>Select “Groups” from the navigation menu.</li>
            <li>Tap the + icon in the upper right corner.</li>
            <li>Fill out the form and press “Save”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Joining a Group</b>
          <ol>
            <li>Select “Groups” from the navigation menu.</li>
            <li>
              Tap the search icon in the upper right corner and type in either
              the group name or code.
            </li>
            <li>Select the group you wish to join.</li>
            <li>Tap the “Join Group” button.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Leaders, Organizers & Members</b>
          <ol>
            <li>
              A leader is the creator of a group; and can edit the group
              settings, as well as create and edit events for a group.
            </li>
            <li>An organizer can create and edit events for a group.</li>
            <li>A member is the participant of a group.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Adding an Organizer (Leaders Only)</b>
          <ol>
            <li>Select “Groups” from the navigation menu.</li>
            <li>Find and open your Group.</li>
            <li>Tap on the “...” in the top right corner.</li>
            <li>Select “Edit group”.</li>
            <li>
              Tap the + icon under Group Organizers and make your selection.
            </li>
            <li>Press Save.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Removing a Member (Leader and Organizers)</b>
          <ol>
            <li>Find and open your Group.</li>
            <li>Tap the People panel.</li>
            <li>
              Within the list of members, select “...” next to a member’s name.
            </li>
            <li>Tap “Remove This Member”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Editing a Group (Leaders Only)</b>
          <ol>
            <li>Find and open your Group.</li>
            <li>Tap on the “...” in the top right corner.</li>
            <li>Select “Edit group”</li>
            <li>Edit the form and press “Save”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Deactivating a Group (Leaders Only)</b>
          <ol>
            <li>Find and open your Group.</li>
            <li>Tap on the “...” in the top right corner.</li>
            <li>Select Deactivate This Group”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Reactivating a Group (Leaders Only)</b>
          <ol>
            <li>Find and open the Group that you wish to reactivate.</li>
            <li>Tap on the “...” in the top right corner.</li>
            <li>Select “Reactivate This Group”</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Deleting a Deactivated Group (Leaders Only)</b>
          <ol>
            <li>Find and open your deactivated Group.</li>
            <li>Tap on the “...” in the top right corner.</li>
            <li>Select “Delete This Group”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>
            Accepting Members into a Private or Hidden Group (Leader and
            Organizers)
          </b>
          <ol>
            <li>Find and open your Group.</li>
            <li>Tap the New button in the People panel.</li>
            <li>
              Tap “There are new applicants”, and then either accept or reject.
            </li>
          </ol>
        </li>
      </ul>
      <h3>Events</h3>
      <ul>
        <li style={{ paddingBottom: 15 }}>
          <b>Creating an Event (Leader and Organizers)</b>
          <ol>
            <li>Find and open your Group.</li>
            <li>Tap the Events panel.</li>
            <li>Tap the “Create New Event” icon in the top right corner.</li>
            <li>Fill out the details and press “Save”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Editing an Event (Leader and Organizers)</b>
          <ol>
            <li>Find and open the Event you wish to edit.</li>
            <li>Select “...” on the top right of the page.</li>
            <li>Select “Edit This Event”.</li>
            <li>Make your changes and press “Save”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Duplicating an Event (Leader and Organizers)</b>
          <ol>
            <li>Find and open the Event you wish to duplicate.</li>
            <li>Select “...” on the top right of the page.</li>
            <li>Select “Duplicate This Event”.</li>
            <li>Make necessary changes and press Save.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Set Up Recurring Event (Leader and Organizers)</b>
          <ol>
            <li>Find and open the Event you wish to recur.</li>
            <li>Select “...” on the top right of the page.</li>
            <li>Select “Set Up Recurring Event”.</li>
            <li>Choose Dates and Frequency and press Save.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>
            Moving a Member to Waitlist/Not Going (Leader, Organizers and Hosts)
          </b>
          <ol>
            <li>Find and open the Event.</li>
            <li>Tap “Attendees”.</li>
            <li>Find the member that you want to move.</li>
            <li>Tap the “...” to the right of the member’s name.</li>
            <li>Select “Move to Waitlist” or “Move to Not Going”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Attendance & Payment (Leader, Organizers, and Hosts)</b>
          <ol>
            <li>Find and open the Event.</li>
            <li>Tap “Attendees”.</li>
            <li>Tap the eyeball icon on top right.</li>
            <li>Select who has paid and who has attended.</li>
          </ol>
        </li>
      </ul>
      <h3>Venues</h3>
      <ul>
        <li style={{ paddingBottom: 15 }}>
          <b>Creating a Venue</b>
          <ol>
            <li>Select the Venues button on the bottom of the homepage.</li>
            <li>Select the + on the top right of the page.</li>
            <li>Fill out the form and tap “Save”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Editing a Venue</b>
          <ol>
            <li>Find and open the Venue that you want to edit.</li>
            <li>Click on “...” on the top right.</li>
            <li> Select “Edit This Venue”.</li>
            <li>Make your changes and tap Save.</li>
            <li>
              FYI, only admin can edit name, nickname & location. If you need to
              make changes to these, please contact us at support@picklers.net.
            </li>
          </ol>
        </li>
      </ul>
      <h3>Inbox</h3>
      <ul>
        <li style={{ paddingBottom: 15 }}>
          <b>Sending a Message to a Member</b>
          <ol>
            <li>Select “Inbox” from the navigation menu.</li>
            <li>Select the “+” compose icon on the top right.</li>
            <li>Tap the + icon to find the member(s) you want to message.</li>
            <li>Write your message.</li>
            <li>
              Optionally include an image or PDF by selecting the camera gallery
              icon.
            </li>
            <li>Click “Send”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Commenting on a Specific Event</b>
          <ol>
            <li>Find and open the Event.</li>
            <li>Tap the Comments panel.</li>
            <li>Tap the + icon on the top right.</li>
            <li>Write your message.</li>
            <li>
              Optionally include an image by selecting the camera gallery icon.
            </li>
            <li>Click “Send”.</li>
          </ol>
        </li>
        <li style={{ paddingBottom: 15 }}>
          <b>Participating in a Group’s Discussion</b>
          <ol>
            <li>Find and open the Group.</li>
            <li>Tap the Discussions panel.</li>
            <li>Write your message.</li>
            <li>
              Optionally include an image or PDF by selecting the camera gallery
              icon.
            </li>
            <li>Click “Send”.</li>
          </ol>
        </li>
      </ul>
    </ScreenContainer>
  );
};
