import React from "react";
import {
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { COLORS } from "../../../styles/colors";
import { MemberFull } from "../../../types";
import { Icon } from "../../../styles/icons";
import { PersonListItem } from "../../../components/people/PersonListItem";
import { goToProfile } from "../../../utils/navUtil";
import { useFetcher, useNavigate } from "react-router-dom";
import { IconButton } from "../../../components/buttons/IconButton";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../../styles/spacing";

type ApplicantListItemProps = {
  memberData: MemberFull;
};
export const ApplicantListItem = (props: ApplicantListItemProps) => {
  const { memberData } = props;
  const colors = COLORS["light"];
  const navigate = useNavigate();
  const fetcher = useFetcher();

  const doAccept = () => {
    fetcher.submit(
      { contextAction: "accept", auxId: memberData.id },
      { method: "POST", action: "memberContextAction" }
    );
  };

  const doReject = () => {
    fetcher.submit(
      { contextAction: "reject", auxId: memberData.id },
      { method: "POST", action: "memberContextAction" }
    );
  };

  return (
    <HorizontalSpaceBetweenContainer>
      <PersonListItem
        firstName={memberData.firstName}
        lastName={memberData.lastName}
        imageUri={memberData.imageUri}
        thumbnailSize={32}
        onClick={() => goToProfile(navigate, memberData.userId)}
      />

      <HorizontalRightAlignedContainer>
        <IconButton
          iconName="checkmark-outline"
          onClick={doAccept}
          color={colors.PRIMARY_GREEN}
        />
        {/* <div style={{ width: LINE_HORIZONTAL_SPACING }} /> */}
        <IconButton
          iconName="close-outline"
          onClick={doReject}
          color={colors.PRIMARY_RED}
        />
      </HorizontalRightAlignedContainer>
    </HorizontalSpaceBetweenContainer>
  );
};
