import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { H2 } from "../../styles/text";
import { useNavigation } from "react-router-dom";

type SecondaryLeftHeaderProps = {
  width?: number;
};

export const SecondaryLeftHeader = (
  props: PropsWithChildren<SecondaryLeftHeaderProps>
) => {
  const { width, children } = props;
  const navigation = useNavigation();
  const skeletonWidth = width
    ? width
    : children
    ? children.toString().length * 16
    : 250;
  return (
    <>
      <HorizontalLeftAlignedContainer>
        {navigation.state === "loading" ? (
          <Skeleton height={24} width={skeletonWidth} />
        ) : (
          <H2>{children}</H2>
        )}
      </HorizontalLeftAlignedContainer>
    </>
  );
};
