import React from "react";

import { COLORS, DARK_PRIMARY_TEXT_COLOR } from "../../styles/colors";

import { TextSmall } from "../../styles/text";

import TouchableOpacity from "../general/TouchableOpacity";
import { getBorderRadius } from "../../utils";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";

type FilterToggleButtonProps = {
  title: string;
  onClick: () => void;
  selected: boolean;
};
export const FilterToggleButton = (props: FilterToggleButtonProps) => {
  const colors = COLORS["light"];
  return (
    <>
      <TouchableOpacity
        onClick={props.onClick}
        style={{
          borderRadius: getBorderRadius("large", "rounded rect"),
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: ITEM_HORIZONTAL_SPACING,
          paddingTop: LINE_VERTICAL_SPACING,
          paddingBottom: LINE_VERTICAL_SPACING,
          paddingRight: ITEM_HORIZONTAL_SPACING,
          borderWidth: 1,
          borderStyle: props.selected ? "none" : "solid",
          borderColor: colors.LIGHT_GRAY,
          backgroundColor: props.selected ? colors.LIGHT_GRAY : "white",
          marginRight: LINE_HORIZONTAL_SPACING,
        }}
      >
        <TextSmall
          color={
            props.selected ? DARK_PRIMARY_TEXT_COLOR : DARK_PRIMARY_TEXT_COLOR
          }
        >
          {props.title}
        </TextSmall>
      </TouchableOpacity>
    </>
  );
};
