import React from "react";
import { VenueStatus } from "../../../types/enums";
import { Banner } from "../../../components/rows/Banner";

type VenueStatusBannerProps = {
  venueStatus: VenueStatus;
};

export const VenueStatusBanner = (props: VenueStatusBannerProps) => {
  const isDeactivated = () => {
    if (props.venueStatus === VenueStatus.Inactive) return true;
    else return false;
  };

  return (
    <div>
      {isDeactivated() && (
        <Banner
          title="This venue is deactivated"
          iconName="warning-outline"
          type="warning"
          borderRadius={0}
        />
      )}
    </div>
  );
};
