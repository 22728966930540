import { useInfiniteQuery, useQuery } from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import { ReportCreate, ReportFull, ReportTargetType } from "../types";
import { addQueryItem } from "../utils/queryStringUtil";

export const saveNewReport = async (reportData: ReportCreate) => {
  const { data } = await axios.post(BACKEND_URL + "reports/", reportData);
  return data;
};

const fetchReport = async (reportId: string) => {
  const { data } = await axios.get(BACKEND_URL + "reports/" + reportId);
  return data;
};
export const useReport = (reportId: string) =>
  useQuery(["reports", reportId], () => fetchReport(reportId), {
    enabled: reportId !== undefined && reportId.length > 0,
  });

const fetchReports = async (
  page: number,
  targetType: ReportTargetType,
  targetId: string
): Promise<ReportFull[] | undefined> => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  const { data } = await axios.get(
    BACKEND_URL + "reports/" + targetType + "/" + targetId + queryParamString
  );
  return data;
};
export const useReports = (targetType: ReportTargetType, targetId: string) =>
  useInfiniteQuery(
    ["reports", targetType, targetId],
    ({ pageParam = 1 }) => fetchReports(pageParam, targetType, targetId),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length + 1;
        return nextPage;
      },
    }
  );
