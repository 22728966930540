import React from "react";
import { VenueFull } from "../../../types";
import IonIcon from "@reacticons/ionicons";
import { COLORS } from "../../../styles/colors";
import { Access, VenueAccess } from "../../../types/enums";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import { TextRegular } from "../../../styles/text";
import { Label } from "../../../styles/labels";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../../styles/container";
import { getStaticMapUriByLatLong } from "../../../utils/mapUtils";
import { getBorderRadius } from "../../../utils";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Icon } from "../../../styles/icons";
import { blankVenueImageURL } from "../../../constants/defaultImageURLs";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { ExpandableDescription } from "../../GroupDetails/Components/ExpandableDescription";
import { GeneralAttribute } from "../../../components/attributes/GeneralAttribute";
import { NormalImage } from "../../../components/NormalImage";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";

type VenueDetailsInfoSectionProps = {
  venueDetails: VenueFull;
  onImagePress: (imageUri: string) => void;
};
interface IVenueDetails {
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  emoji?: string;
  text: string;
}
export type Location = {
  address: string;
  displayAddress: string;
  lat: number;
  lng: number;
};

export const VenueDetailsInfoSection = (
  props: VenueDetailsInfoSectionProps
) => {
  const { venueDetails, onImagePress } = props;
  const venueImageUri =
    venueDetails && venueDetails.imageUri
      ? venueDetails.imageUri
      : blankVenueImageURL;

  // const goToGoogleMaps = async (address: string) => {
  //   const url =
  //     "https://www.google.com/maps/search/?api=1&query=" +
  //     encodeURIComponent(address);
  //   // await Linking.openURL(url);
  //   alert("go to url: " + url);
  // };
  const mapImageUri = getStaticMapUriByLatLong(
    venueDetails.latitude,
    venueDetails.longitude
  );
  const colors = COLORS["light"];
  const navigation = useNavigation();

  let detailsData: IVenueDetails[] = [];
  if (props.venueDetails.contactName)
    detailsData.push({
      iconName: "person-outline",
      text: props.venueDetails.contactName,
    });
  if (props.venueDetails.contactPhone)
    detailsData.push({
      iconName: "call-outline",
      text: props.venueDetails.contactPhone,
    });
  if (props.venueDetails.contactEmail)
    detailsData.push({
      iconName: "mail-outline",
      text: props.venueDetails.contactEmail,
    });
  if (props.venueDetails.numIndoorCourts > 0)
    detailsData.push({
      iconName: "business-outline",
      text: props.venueDetails.numIndoorCourts.toString() + " Indoor Courts",
    });
  if (props.venueDetails.numOutdoorCourts > 0)
    detailsData.push({
      iconName: "partly-sunny-outline",
      text: props.venueDetails.numOutdoorCourts.toString() + " Outdoor Courts",
    });
  if (props.venueDetails.parking)
    detailsData.push({ iconName: "car-outline", text: "Parking" });
  if (props.venueDetails.restrooms)
    detailsData.push({
      /*iconName: "male-female-outline",*/ emoji: "🚻🚽🧻",
      text: "Restrooms",
    });
  if (props.venueDetails.permanentLines)
    detailsData.push({ iconName: "brush-outline", text: "Permanent Lines" });
  if (props.venueDetails.permanentNets)
    detailsData.push({ iconName: "globe-outline", text: "Permanent Nets" });
  if (props.venueDetails.outdoorLighting)
    detailsData.push({ iconName: "bulb-outline", text: "Dusk Lighting" });
  if (props.venueDetails.access === VenueAccess.Private) {
    detailsData.push({ iconName: "key-outline", text: "Private facility" });
  } else {
    detailsData.push({ iconName: "key-outline", text: "Public facility" });
  }
  if (props.venueDetails.openHours !== "")
    detailsData.push({
      iconName: "time-outline",
      text: props.venueDetails.openHours,
    });

  return (
    <div
      style={{
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
      }}
    >
      <HorizontalSpaceBetweenContainer
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          height: 300,
        }}
      >
        <TouchableOpacity
          onClick={
            navigation.state !== "loading"
              ? () => onImagePress(venueImageUri)
              : undefined
          }
          style={{
            backgroundColor: colors.WHITE,
            borderStyle: "none",
            height: 300,
            width: "100%",
          }}
        >
          <NormalImage
            imageUri={venueImageUri}
            alt="This pickleball venue"
            height={300}
            width="100%"
            objectFit="cover"
            borderRadius={getBorderRadius("medium", "rounded rect")}
          />
        </TouchableOpacity>

        <div style={{ width: SECTION_HORIZONTAL_SPACING }} />

        {navigation.state === "loading" ? (
          <Skeleton
            height={300}
            width={300}
            borderRadius={getBorderRadius("medium", "rounded rect")}
          />
        ) : (
          <SizedContainer width={300} height={300}>
            <a
              href={
                "https://www.google.com/maps/search/?api=1&query=" +
                encodeURIComponent(venueDetails.address)
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Map of this venue's location"
                src={mapImageUri}
                width={300}
                height={300}
                style={{
                  borderRadius: getBorderRadius("large", "rounded rect"),
                }}
              />
            </a>
          </SizedContainer>
        )}
      </HorizontalSpaceBetweenContainer>

      <ItemVerticalSpace />
      {/* {navigation.state === "loading" ? (
        <div
          style={{
            paddingRight: SECTION_HORIZONTAL_SPACING,
            flex: 1,
          }}
        >
          <Skeleton height={24} count={2.5} />
        </div>
      ) : ( */}
      <>
        {venueDetails.description && (
          <div
            style={{
              marginTop: ITEM_VERTICAL_SPACING,
              marginBottom: ITEM_VERTICAL_SPACING,
            }}
          >
            <ExpandableDescription text={venueDetails.description} />
          </div>
        )}
      </>

      <ItemVerticalSpace />
      {/* )} */}

      <div
        style={{
          backgroundColor: colors.SURFACE,
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 16,
          paddingLeft: 16,
          borderRadius: getBorderRadius("medium", "rounded rect"),
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        {detailsData.map((item, index) => (
          <div
            key={index}
            style={{
              marginTop: 4,
              marginBottom: 4,
              width: "33%",
            }}
          >
            {navigation.state === "loading" ? (
              <Skeleton height={24} count={0.5} />
            ) : (
              <HorizontalLeftAlignedContainer>
                <GeneralAttribute
                  // iconName={item.iconName}
                  // emoji={item.emoji}
                  text={item.text}
                  textColor={colors.SECONDARY_TEXT}
                />
              </HorizontalLeftAlignedContainer>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
