import React, { Dispatch, SetStateAction, useContext } from "react";
import { COLORS } from "../../../styles/colors";
import { MessageFull } from "../../../types";
import { extractPDFFilenameFromURL, isPDFUri } from "../../../utils/pdfUtil";
import { getBorderRadius, getDateOrTimeAgo } from "../../../utils";
import {
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../../styles/container";
import { InitialsProfilePic } from "../../../components/image/InitialsProfilePic";
import { TextRegular, TextSmall } from "../../../styles/text";
import { layout } from "../../../styles/layout";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { isImageUri } from "../../../utils/imageSelection";
import { PdfMessageItem } from "../../../components/image/PdfMessageItem";
import { useNavigate, useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Icon } from "../../../styles/icons";
import "./OtherMessageCard.css";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { userContext } from "../../../UserContext";

type OtherMessageCardProps = {
  key: number;
  messageDetails: MessageFull;
  setSelectedImage: Dispatch<SetStateAction<string>>;
};

export const OtherMessageCard = (props: OtherMessageCardProps) => {
  const { messageDetails, setSelectedImage } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const navigate = useNavigate();
  const userInfo = useContext(userContext);

  const pdfFilename = extractPDFFilenameFromURL(messageDetails.messageImageUri);
  const re = new RegExp(/\s+?\[groupInvite groupId=\"(.+)\"\]/);
  let result = messageDetails.messageText.match(re);
  let inviteGroupId = "";
  let bareMessageText = messageDetails.messageText;
  if (result && result[1]) {
    inviteGroupId = result[1];
    bareMessageText = messageDetails.messageText.replace(re, "").trim();
  }
  const handleInviteGroupClick = () => {
    navigate("/group/view/" + inviteGroupId);
  };

  const borderRadius = getBorderRadius("large", "rounded rect");

  return (
    <div className="other-message-card" style={{ position: "relative" }}>
      <HorizontalLeftAlignedContainer
        // className="other-message-card"
        style={{
          alignItems: "flex-start",
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        {navigation.state === "loading" ? (
          <Skeleton width={32} height={32} circle={true} />
        ) : (
          <SizedContainer width={32} height={32}>
            {messageDetails.imageUri ? (
              <img
                src={messageDetails.imageUri}
                width={32}
                height={32}
                alt="the person sending this message"
                style={{ borderRadius: 30 }}
              />
            ) : (
              <InitialsProfilePic
                firstName={messageDetails.firstName}
                lastName={messageDetails.lastName}
                fontSize={14}
                borderRadius={borderRadius}
              />
            )}
          </SizedContainer>
        )}
        <div
          style={{
            marginLeft: LINE_HORIZONTAL_SPACING,
            flex: 1,
          }}
        >
          {navigation.state === "loading" ? (
            <Skeleton width={100} height={18} />
          ) : (
            <HorizontalLeftAlignedContainer className="name-date">
              <TextSmall
                color={colors.SECONDARY_TEXT}
                style={{ paddingRight: 6 }}
              >
                {messageDetails.firstName}
              </TextSmall>
              {/* <HorizontalLeftAlignedContainer className="message-date">
                <Icon name="ellipse" size={4} />
                <TextSmall
                  color={colors.SECONDARY_TEXT}
                  style={{
                    paddingLeft: 6,
                  }}
                >
                  {getDateOrTimeAgo(new Date(messageDetails.timePosted))}
                </TextSmall>
              </HorizontalLeftAlignedContainer> */}
            </HorizontalLeftAlignedContainer>
          )}
          <HorizontalLeftAlignedContainer
            style={{ paddingTop: LINE_VERTICAL_SPACING }}
          >
            {navigation.state === "loading" ? (
              // <HorizontalLeftAlignedContainer
              <Skeleton
                width={300}
                height={80}
                borderRadius={getBorderRadius("medium", "rounded rect")}
                style={{
                  borderBottomLeftRadius: 0,
                }}
              />
            ) : (
              <div style={{ alignItems: "flex-start" }}>
                {messageDetails.messageText && (
                  <HorizontalLeftAlignedContainer
                    style={{
                      paddingTop: LINE_VERTICAL_SPACING,
                      paddingBottom: LINE_VERTICAL_SPACING,
                      paddingLeft: ITEM_VERTICAL_SPACING,
                      paddingRight: ITEM_VERTICAL_SPACING,
                      maxWidth: "60%",
                      borderColor: colors.LIGHT_GRAY,
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: getBorderRadius("medium", "rounded rect"),
                      borderBottomLeftRadius: 0,
                    }}
                  >
                    <div>
                      <TextRegular>{bareMessageText}</TextRegular>
                      {!!inviteGroupId && (
                        <HorizontalContainer
                          style={{ marginTop: ITEM_VERTICAL_SPACING }}
                        >
                          <div>
                            <PrimaryButton
                              title="View Group to Apply"
                              onClick={handleInviteGroupClick}
                            />
                          </div>
                        </HorizontalContainer>
                      )}
                    </div>
                  </HorizontalLeftAlignedContainer>
                )}

                {isPDFUri(messageDetails.messageImageUri) && (
                  <a
                    href={messageDetails.messageImageUri}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <HorizontalLeftAlignedContainer
                      style={{
                        maxWidth: 400,
                        borderColor: colors.LIGHT_GRAY,
                        borderWidth: 1,
                        borderRadius: borderRadius,
                        borderBottomLeftRadius: 0,
                        overflow: "hidden",
                        marginTop: LINE_VERTICAL_SPACING,
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <PdfMessageItem
                        pdfFilename={pdfFilename}
                        backgroundColor={colors.PALE_BRAND}
                      />
                    </HorizontalLeftAlignedContainer>
                  </a>
                )}

                {isImageUri(messageDetails.messageImageUri) && (
                  <TouchableOpacity
                    onClick={() =>
                      setSelectedImage(messageDetails.messageImageUri ?? "")
                    }
                    style={{
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      alt="something sent in this message"
                      src={messageDetails.messageImageUri}
                      style={{
                        backgroundColor: colors.PALE_GRAY,
                        borderColor: colors.PALE_GRAY,
                        borderWidth: 1,
                        borderRadius: borderRadius,
                        overflow: "hidden",
                        marginTop: LINE_VERTICAL_SPACING,
                        borderBottomLeftRadius: 0,
                        maxWidth: 300,
                        maxHeight: 300,
                      }}
                    />
                  </TouchableOpacity>
                )}
              </div>
            )}
          </HorizontalLeftAlignedContainer>
        </div>
      </HorizontalLeftAlignedContainer>
      <HorizontalContainer
        className="message-date"
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          paddingTop: 8,
          paddingBottom: 8,
          // backgroundColor: "pink",
        }}
      >
        <TextSmall color={colors.SECONDARY_TEXT}>
          {getDateOrTimeAgo(new Date(messageDetails.timePosted))}
        </TextSmall>
      </HorizontalContainer>
    </div>
  );
};
