import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";
import { GroupFull, VenueFull } from "../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { Location } from "../TitledLocationPicker/LocationPicker";
import { useAllVenues } from "../../hooks/queryVenues";
import { VenuePickerListItem } from "./VenuePickerListItem";
import { H1, TextRegular } from "../../styles/text";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { goToCreateVenue } from "../../utils/navUtil";
import { useWindowHeight } from "@react-hook/window-size";
import { COLORS } from "../../styles/colors";

type VenuePickerProps = {
  groupDetails: GroupFull; // need group's location to seed our picker's search locus
  setVenue: Dispatch<SetStateAction<VenueFull>>;
  closePicker: () => void;
};

export const VenuePicker = (props: VenuePickerProps) => {
  const { groupDetails, closePicker, setVenue } = props;
  const navigate = useNavigate();
  const windowHeight = useWindowHeight();
  const [height, setHeight] = useState(500);
  const headerRef = useRef<HTMLDivElement>(null);
  const colors = COLORS["light"];
  const defaultLocationData: Location = {
    lat: groupDetails.latitude,
    lng: groupDetails.longitude,
    address: groupDetails.address,
    displayAddress: groupDetails.displayAddress,
  };
  const selectedLocationData = defaultLocationData;

  const [venueSearchText, setVenueSearchText] = useState("");
  const { data, isSuccess, isLoading, isError } = useAllVenues(
    selectedLocationData.lat,
    selectedLocationData.lng,
    150
  );

  const [suggestionItems, setSuggestionItems] = useState<
    VenueFull[] | undefined
  >(data);

  const doCreateVenue = () => {
    goToCreateVenue(navigate);
  };

  const handleSelection = (venue: VenueFull) => {
    // set venue stuff
    setVenue(venue);
    closePicker();
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(e.target.value, "ig");
    if (isSuccess) {
      const filteredList = data?.filter((suggestion: VenueFull) =>
        suggestion.name ? suggestion.name.match(regex) : null
      );
      setSuggestionItems(filteredList);
      setVenueSearchText(e.target.value);
    }
  };

  useEffect(() => {
    if (data) {
      setSuggestionItems(data);
    }
  }, [data]);

  useEffect(() => {
    if (!headerRef.current) return;
    setHeight(headerRef.current.offsetHeight);
  }, []);

  return (
    <div
      style={{
        // width: "100%",
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
      }}
    >
      <div ref={headerRef}>
        <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
          <H1>Select a Venue</H1>
        </HorizontalContainer>
        <HorizontalLeftAlignedContainer
          style={{ marginBottom: ITEM_VERTICAL_SPACING, width: "100%" }}
        >
          <input
            type="text"
            value={venueSearchText}
            placeholder="Enter any part of the venue name."
            onChange={handleTextChange}
            maxLength={35}
            style={{ width: "100%", marginRight: 25 }}
          />
        </HorizontalLeftAlignedContainer>
      </div>
      {isLoading && <TextRegular>Loading</TextRegular>}
      {isError && <TextRegular>Error loading venues</TextRegular>}
      {isSuccess && data && suggestionItems && (
        <div
          id="scrollableDiv"
          style={{
            overflow: "auto",
            height: windowHeight - height - HEADER_VERTICAL_SPACING - 96,
          }}
        >
          {suggestionItems.map((suggestion: VenueFull, index) => {
            return (
              <HorizontalLeftAlignedContainer
                key={index}
                style={{ marginBottom: ITEM_VERTICAL_SPACING }}
              >
                <VenuePickerListItem
                  name={suggestion.name}
                  borderRadius={5}
                  imageUri={suggestion.imageUri}
                  handleSelection={() => handleSelection(suggestion)}
                />
              </HorizontalLeftAlignedContainer>
            );
          })}
        </div>
      )}
      {/* <HorizontalContainer style={{ marginTop: SECTION_VERTICAL_SPACING }}>
        <PrimaryButton
          title="Create Venue"
          onClick={doCreateVenue}
          type="button"
        />
      </HorizontalContainer> */}
      <HorizontalLeftAlignedContainer
        style={{ marginTop: 16, marginLeft: 32, marginRight: 32 }}
      >
        <TextRegular color={colors.MEDIUM_GRAY}>
          * If your venue is not on this list, you can go to the Venues section
          and create it.
        </TextRegular>
      </HorizontalLeftAlignedContainer>
    </div>
  );
};
