import { NavigateFunction, createSearchParams } from "react-router-dom";

// profile screens
export const goToProfile = (navigate: NavigateFunction, userId: string, replace?: boolean) => {
    if (!!userId) navigate("/profile/" + userId, {replace: true});
};
export const goToAbout = (navigate: NavigateFunction) => {
    navigate("/profile/about");
};
export const goToEditProfile = (navigate: NavigateFunction, userId: string) => {
    if (!!userId) navigate("/profile/edit/" + userId);
};

// Group Screens
export const goToGroups = (navigate: NavigateFunction) => {
    navigate("/groups");
}
export const goToCreateGroup = (navigate: NavigateFunction) => {
    navigate("/groups/create");
};
export const goToEditGroup = (navigate: NavigateFunction, groupId: string) => {
    if (!!groupId) navigate("/groups/edit/" + groupId);
};
export const goToGroupDetails = (navigate: NavigateFunction, groupId: string) => {
    if (!!groupId) navigate("/group/view/" + groupId);
};

// Event Screens 
export const goToEvents = (navigate: NavigateFunction) => {
    navigate("/events");
}
export const goToEventDetails = (navigate: NavigateFunction, eventId: string, replace?: boolean) => {
    if (!!eventId) navigate("/event/view/" + eventId, {replace: replace});
};
export const goToEditEvent = (navigate: NavigateFunction, groupId: string, eventId: string) => {
    if (!!groupId && !!eventId) navigate("/events/edit/" + groupId + "/" + eventId);
};
export const goToCreateEvent = (navigate: NavigateFunction, groupId: string) => {
    if (!!groupId) navigate("/events/create/" + groupId);
};
export const goToDuplicateEvent = (navigate: NavigateFunction, groupId: string, eventId: string) => {
    if (!!groupId && !!eventId) navigate("/events/duplicate/" + groupId + "/" + eventId);
};
export const goToRepeatEvent = (navigate: NavigateFunction, eventId: string) => {
    if (!!eventId) navigate("/event/repeat/" + eventId);
}
export const goToAttendeesScreen = (navigate: NavigateFunction, groupId: string, eventId: string) => {
    if (!!groupId && !!eventId) navigate("/attendees/" + groupId + "/" + eventId);
};

// Venue Screens
export const goToVenues = (navigate: NavigateFunction) => {
    navigate("/venues");
}
export const goToCreateVenue = (navigate: NavigateFunction) => {
    navigate("/venues/create");
};
export const goToEditVenue = (navigate: NavigateFunction, venueId: string) => {
    if (!!venueId) navigate("/venues/edit/" + venueId);
};
export const goToVenueDetails = (navigate: NavigateFunction, venueId: string) => {
    if (!!venueId) navigate("/venue/view/" + venueId);
};

// Message Screens
export const goToMessageHistory = (navigate: NavigateFunction, messageType: string, threadId: string, title: string) => {
    if (!!threadId) {
        navigate({
            pathname: "/messagehistory/" + messageType + "/" + threadId, 
            search: createSearchParams({title: title}).toString()
        });
    }
};
export const goToEventComments = (navigate: NavigateFunction, eventId: string, title: string) => {
    if (!!eventId) {
        navigate({
            pathname: "/event/comments/" + eventId, 
            search: createSearchParams({title: title}).toString()
        })
    }
};
export const goToGroupDiscussion = (navigate: NavigateFunction, groupId: string) => {
    if (!!groupId) navigate("group/discussion/" + groupId);
};

// Compose Screen
export const goToComposeDM = (navigate: NavigateFunction) => {
    navigate("/composemessage/dm");
};
export const goToComposeEventMessage = (navigate: NavigateFunction, eventId: string) => {
    if (!!eventId) navigate("/composemessage/event/" + eventId);
};
export const goToComposeGroupsMessage = (navigate: NavigateFunction) => {
    navigate("/composegroupsmessage");
};

// Report Screens
export const goToSupport = (navigate: NavigateFunction, userId: string) => {
    if (!!userId) {
        const targetName = encodeURIComponent("General Inquiry");
        const reportQueryString = `?targetType=support&targetId=${userId}&targetName=${targetName}&returnUri=${"/profile/" + userId}`;
        navigate("/report" + reportQueryString);
    }
};
export const goToReport = (navigate: NavigateFunction, reportQueryString: string) => {
    if (!!reportQueryString) navigate("/report" + reportQueryString);
}

// Account screens
export const goToForgotPassword = (navigate: NavigateFunction) => {
    navigate("/forgotPassword");
};

export const goToSignUp = (navigate: NavigateFunction) => {
    navigate("/createAccount");
};

export const goToLogin = (navigate: NavigateFunction) => {
    navigate("/");
};

export const goToChangePassword = (navigate: NavigateFunction) => {
    navigate("/changePassword")
}

export const goToLoggingIn = (navigate: NavigateFunction, message?: string) => {
    navigate("/loggingIn/" + message ?? "");
}