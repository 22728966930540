import moment from "moment-timezone";
import { EventFull } from "../types";

export const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

export const formatDate = (d: Date, showYear?: boolean, timeZone?: string) => {
    const date = new Date(d);
    if (!date || !date.toLocaleDateString) return "";
    if (date.getFullYear() !== new Date().getFullYear() || showYear)
        return date.toLocaleDateString('en-us', { month: "numeric", weekday: 'short', day: 'numeric', year: "numeric", timeZone: timeZone })
    return date.toLocaleDateString('en-us', { month: "numeric", weekday: 'short', day: 'numeric', timeZone: timeZone })
}

export const formatDateFilter = (d: Date, timeZone?: string) => {
    return d.toLocaleDateString("en-us", {year:"numeric", month: "2-digit", day:"2-digit", timeZone: timeZone})
}

export const formatDateWithoutWeekday = (date: Date, timeZone?: string) => {
    if (!date || !date.toLocaleDateString) return "";
    return date.toLocaleDateString('en-us', { month: "numeric", day: 'numeric', timeZone: timeZone })
}

export const formatTime = (date: Date, timeZone?: string) => {
    return moment(date).tz(timeZone ? timeZone : guessTimeZone()).format("h:mm A z"); 
    // return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit'}).toLocaleLowerCase();  // this doesn't behave right on android
}

export const formatTimeInterval = (date: Date, hours: number, timeZone?: string) => {
    if (!date) return ""
    const fromDate = new Date(date);
    // const toDate = moment(fromDate).add(hours, 'h').toDate();
    const from = moment(fromDate).tz(timeZone ? timeZone : guessTimeZone()).format("h:mm A").replace(":00", "");
    const to = moment(fromDate).tz(timeZone ? timeZone : guessTimeZone()).add(hours, "h").format("h:mm A z").replace(":00", "");
    return `${from} - ${to}`
}

export const getDateOrTimeAgo = (specifiedDate: Date, timeZone?: string) => {
    
    if (!specifiedDate) return "recently";

    const today = new Date();

    if (isDifferentDate(today, specifiedDate)) 
        return formatDate(specifiedDate, false, timeZone);
    else if (today.getHours() !== specifiedDate.getHours()) 
        return formatTime(specifiedDate, timeZone)
    else if (today.getMinutes() !== specifiedDate.getMinutes()) 
        return today.getMinutes() - specifiedDate.getMinutes() + " minutes ago";
    else 
        return "seconds ago";

}

export const getDateOrTimeAhead = (specifiedDate: Date, timeZone?: string) => {
    if (!specifiedDate) return "soon";
    const today = new Date();
    if (isDifferentDate(today, specifiedDate))
        return "on " + formatDate(specifiedDate, false, timeZone) + " at " + formatTime(specifiedDate, timeZone);
    else if (today.getHours() !== specifiedDate.getHours()) 
        return "at " + formatTime(specifiedDate, timeZone);
    else if (today.getMinutes() !== specifiedDate.getMinutes())
        return "in " + (specifiedDate.getMinutes() - today.getMinutes()) + " minutes"
    else {
        return "in a few seconds";
    }
}

export const formatDateTime = (date: Date, showYear?: boolean, timeZone?: string) => {
    const dateObj = new Date(date)
    return formatDate(dateObj, showYear, timeZone) + " @ " + formatTime(dateObj, timeZone); 
}

export const formatDateTimeInterval = (date: Date, hours: number, showYear: boolean, timeZone?: string) => {
    const dateObj = new Date(date);
    return formatDate(dateObj, showYear, timeZone) + " @ " + formatTimeInterval(dateObj, hours, timeZone);
};

export const isBeforeToday = (date: Date) => {
    if (!date) return false;

    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return date < today;
  }

const isSameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear()===d2.getFullYear() && d1.getMonth()===d2.getMonth() && d1.getDate()===d2.getDate()
}

export const canTakeAttendance = (date: Date) => {
    const eventDate = new Date(date)
    const now = new Date()

    // if the event hasn't started yet, allow attendance 30 minutes before the event start time
    if (now < eventDate) {
        const nowPlusThirtyMinutes = now.getTime() + 30 * 60 * 1000
        return eventDate <= new Date(nowPlusThirtyMinutes)
    } 
    // once the event has started, allow attendance to be managed
    else return true;
}

// duration in hours
export const canShowConflicts = (date: Date, duration: number) => {
    const now = new Date()
    const eventStart = new Date(date)
    const eventEnd = new Date(eventStart.getTime() + duration * 60 * 60 * 1000)

    // show conflicts until event ends
    return now <= eventEnd
}

export const isInThePast = (date: Date) => new Date(date) < new Date()

export const isInTheFuture = (date: Date) => {
    return new Date(date) > new Date() 
}

export const isOlderThanAWeek = (date: Date) => {
    const givenDateTime = date.getTime();
    const now = new Date();
    const lastWeekTime = now.getTime() - 7 * 24 * 60 * 60 * 1000;
    return givenDateTime < lastWeekTime;
}

export const getLastWeek = () => {
    const now = new Date();
    const lastWeekTime = now.getTime() - 7 * 24 * 60 * 60 * 1000;
    return new Date(lastWeekTime);
}

export const opensAt = (later: Date) => {
    const n = new Date()
    const l = new Date(later)
    const time_diff = l.getTime() - n.getTime();
    const diff_in_days = Math.round(time_diff / (1000 * 3600 * 24));
    
    return  diff_in_days > 28 ? '' : diff_in_days >= 21 ? '3w' : diff_in_days >= 14 ? '2w' : diff_in_days >= 7 ? '1w' : diff_in_days >= 1 ? `${diff_in_days}d` : formatTime(l)
}


export const isDifferentDate = (date1: Date, date2: Date) => {
    if (!date1 || !date2) return true;
    return date1.getFullYear() !== date2.getFullYear() ||
        date1.getMonth() !== date2.getMonth() ||
        date1.getDate() !== date2.getDate() 
}

export const getDateInXHours = (currentDate: Date, hours: number) => {
    const currentTime = currentDate.getTime();
    const newTime = currentTime + hours*60*60*1000;
    return new Date(newTime);
}

export const getDateInXDays = (currentDate: Date, days: number) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
}

export const guessTimeZone = () => {
    return moment.tz.guess();
}

export const eventsOverlap = (a: EventFull, b: EventFull) => {
    const a_end = a.date + a.duration * 60 * 60 * 1000;
    if (b.date < a_end) return a.status === "active" && b.status ==="active";
    return false;
};


export const dayOfTheYear = (date: Date) => {
  return Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);
}

export const earlyBirdWindowIsOpen = (eventDetails: EventFull) => {
    if (eventDetails.earlyBirdWindow < 1) return true;
    const earlyBirdOpensDate = eventDetails.rsvpDate - eventDetails.earlyBirdWindow * 24 * 60 * 60 * 1000; // move back X days;
    const now = new Date().getTime();
    if (now > earlyBirdOpensDate)
        return true;
    
    return false;
}