import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import {
  GroupsMessageCreate,
  MessageCreate,
  MessageFull,
  MessageUpdate,
  ThreadCreate,
} from "../types";
import { addQueryItem } from "../utils";
import { ImageUploadData } from "../types";

/* get a message based on its message id */
const findMessage = async (messageId: string) => {
  const { data } = await axios.get(BACKEND_URL + "messages/" + messageId);
  return data;
};

export const useMessage = (messageId: string) =>
  useQuery(["messages", messageId], () => findMessage(messageId));

/* get all messages based on messageType and destination thread */
const fetchMessages = async (
  page: number,
  messageType: string | undefined,
  destinationId: string | undefined,
  reverseOrder: boolean
) => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  const { data } = await axios.get(
    BACKEND_URL +
      "messages/" +
      messageType +
      "/" +
      destinationId +
      "/" +
      reverseOrder +
      queryParamString
  );
  return data;
};

export const messagesQuery = (
  enabled: boolean,
  messageType: string | undefined,
  destinationId: string | undefined,
  reverseOrder: boolean
) => ({
  queryKey: ["messages", messageType, destinationId],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchMessages(pageParam, messageType, destinationId, reverseOrder);
  },
  enabled: enabled,
  getNextPageParam: (
    lastPage: MessageFull[] | undefined,
    pages: (MessageFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useMessages = (
  enabled: boolean,
  messageType: string | undefined,
  destinationId: string | undefined,
  reverseOrder: boolean
): UseInfiniteQueryResult<MessageFull[]> =>
  useInfiniteQuery(
    messagesQuery(enabled, messageType, destinationId, reverseOrder)
  );

/* get direct message threads involving userId */

const fetchDMThreads = async (userId: string) => {
  const { data } = await axios.get(BACKEND_URL + "dmthreads/user/" + userId);
  return data;
};

export const dmThreadsQuery = (isEnabled: boolean, userId: string) => ({
  queryKey: ["dmThreads", userId],
  queryFn: async () => fetchDMThreads(userId),
  enabled: !!isEnabled,
});

export const useDMThreads = (isEnabled: boolean, userId: string) =>
  useQuery(dmThreadsQuery(isEnabled, userId));

/* get group message threads for userId's groups */

const fetchGroupThreads = async (userId: string) => {
  const { data } = await axios.get(BACKEND_URL + "groups/threads/" + userId);
  return data;
};

export const groupThreadsQuery = (isEnabled: boolean, userId: string) => ({
  queryKey: ["groupThreads", userId],
  queryFn: async () => fetchGroupThreads(userId),
  enabled: !!isEnabled,
});

export const useGroupThreads = (isEnabled: boolean, userId: string) =>
  useQuery(groupThreadsQuery(isEnabled, userId));

/* get group message threads for userId's RSVP'd events */

const fetchEventThreads = async (userId: string) => {
  const { data } = await axios.get(BACKEND_URL + "events/threads/" + userId);
  return data;
};

export const eventThreadsQuery = (isEnabled: boolean, userId: string) => ({
  queryKey: ["eventThreads", userId],
  queryFn: async () => fetchEventThreads(userId),
  enabled: !!isEnabled,
});

export const useEventThreads = (isEnabled: boolean, userId: string) =>
  useQuery(eventThreadsQuery(isEnabled, userId));

/* create a direct message thread and add initial message */
export const createDMThread = async (threadData: ThreadCreate) => {
  // get dm thread if it exists, else create it.  Either way, insert the message
  const { data } = await axios.post(BACKEND_URL + "dmthreads/", threadData);
  return data;
};

/* respond to any message by messageType and destinationId */
export const sendMessage = async (messageData: MessageCreate) => {
  const { data } = await axios.post(BACKEND_URL + "messages/", messageData);
  return data;
};

export const sendGroupsMessage = async (messageData: GroupsMessageCreate) => {
  const { data } = await axios.post(
    BACKEND_URL + "messages/groups/",
    messageData
  );
  return data;
};

export const sendGroupBotMessage = async (
  messageText: string,
  recipientIds: string[]
) => {
  const messageData = {
    messageText: messageText,
    recipientIds: recipientIds,
  };
  const result = await axios.post(
    BACKEND_URL + "messages/groupbot",
    messageData
  );
  return result;
};

export const uploadImageMessage = async (
  messageId: string,
  imageUploadData: ImageUploadData
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageUploadData as unknown as File);
  let result;
  result = await axios.post(
    BACKEND_URL + "messages/" + messageId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadImageGroupsMessageFile = async (
  messageIds: string[],
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("messageIds", JSON.stringify(messageIds));
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "messages/groups/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadMessageImageFile = async (
  messageId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "messages/" + messageId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

const fetchPinnedMessages = async (
  messageType: string,
  destinationId: string,
  maximum: number
) => {
  const { data } = await axios.get(
    BACKEND_URL +
      "messages/pinned/" +
      messageType +
      "/" +
      destinationId +
      "/" +
      maximum
  );
  return data;
};

export const pinnedMessagesQuery = (
  isEnabled: boolean,
  messageType: string,
  destinationId: string,
  maximum: number
) => ({
  queryKey: ["pinnedmessages", messageType, destinationId],
  queryFn: async () => fetchPinnedMessages(messageType, destinationId, maximum),
  enabled: !!isEnabled,
});

export const usePinnedMessages = (
  isEnabled: boolean,
  messageType: string,
  destinationId: string,
  maximum: number
) =>
  useQuery(pinnedMessagesQuery(isEnabled, messageType, destinationId, maximum));

// update an existing message
export const updateMessage = async (messageData: MessageUpdate) => {
  const { data } = await axios.patch(
    BACKEND_URL + "messages/" + messageData.id,
    messageData
  );
  return data;
};

export const deleteMessage = async (messageId: string) => {
  await axios.delete(BACKEND_URL + "messages/" + messageId);
  return;
};
