import { HorizontalContainer } from "../../styles/container";
import { Icon } from "../../styles/icons";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { TextRegular } from "../../styles/text";

type ConflictFlagLabelProps = {
  color: string;
};
export const ConflictFlagLabel = (props: ConflictFlagLabelProps) => {
  return (
    // <HorizontalContainer
    //   style={{
    //     backgroundColor: "white",
    //     paddingRight: LINE_HORIZONTAL_SPACING,
    //     paddingLeft: LINE_HORIZONTAL_SPACING,
    //     marginTop: -3,
    //   }}
    // >
    <Icon name="warning" color={props.color} size={16} />
    // {/* <TextRegular style={{ marginLeft: 5 }} color={props.color}>
    //   Conflict
    // </TextRegular> */}
    // </HorizontalContainer>
  );
};
