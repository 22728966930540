import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import {
  HorizontalContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  HEADER_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { goToComposeDM, goToComposeGroupsMessage } from "../../utils/navUtil";
import { ChatsTab } from "./Tabs/ChatsTab";
import { EventActivityTab } from "./Tabs/EventActivityTab";
import { GroupActivityTab } from "./Tabs/GroupActivityTab";
import { useNavigate, useNavigation } from "react-router-dom";
import { useRef, useEffect, useState, useContext } from "react";
import { userContext } from "../../UserContext";
import { ThreadFull } from "../../types";
import { UseQueryResult } from "react-query";
import { threadLastReadContext } from "./Components/ThreadLastReadContext";
import { guessTimeZone, isOlderThanAWeek } from "../../utils";
import { NewMessageIndicator } from "../../components/general/NewMessageIndicator";
import { NavButton } from "../../components/buttons/NavButton";
import { COLORS } from "../../styles/colors";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { NormalText } from "../../components/text/NormalText";

type InboxScreenLayoutProps = {
  tabIndex: number;
  onTabSelect: (tabIndex: number) => void;
  dmThreadsResult: UseQueryResult<ThreadFull[]>;
  eventThreadsResult: UseQueryResult<ThreadFull[]>;
  groupThreadsResult: UseQueryResult<ThreadFull[]>;
};

export const InboxScreenLayout = (props: InboxScreenLayoutProps) => {
  const {
    tabIndex,
    onTabSelect,
    dmThreadsResult,
    groupThreadsResult,
    eventThreadsResult,
  } = props;
  const colors = COLORS["light"];
  const userInfo = useContext(userContext);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const titleRef = useRef<HTMLElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const threadLastReadData = useContext(threadLastReadContext);

  const [haveNewDM, setHaveNewDM] = useState(false);
  const [haveNewGroupMessage, setHaveNewGroupMessage] = useState(false);
  const [haveNewEventMessage, setHaveNewEventMessage] = useState(false);

  useEffect(() => {
    const { isSuccess, data } = dmThreadsResult;
    if (isSuccess && data) {
      data.forEach((threadInfo: ThreadFull) => {
        const lastReadItem = threadLastReadData.dmThreadLastRead.find(
          (item) => item.destinationId === threadInfo.destinationId
        );
        if (!lastReadItem || threadInfo.timePosted > lastReadItem.lastReadTime)
          if (
            !haveNewDM &&
            !isOlderThanAWeek(new Date(threadInfo.timePosted))
          ) {
            setHaveNewDM(true);
          }
      });
    }
  }, [dmThreadsResult]);

  useEffect(() => {
    const { isSuccess, data } = groupThreadsResult;
    if (isSuccess && data) {
      data.forEach((threadInfo: ThreadFull) => {
        const lastReadItem = threadLastReadData.groupThreadLastRead.find(
          (item) => item.destinationId === threadInfo.destinationId
        );
        if (!lastReadItem || threadInfo.timePosted > lastReadItem.lastReadTime)
          if (
            !haveNewGroupMessage &&
            !isOlderThanAWeek(new Date(threadInfo.timePosted))
          ) {
            setHaveNewGroupMessage(true);
          }
      });
    }
  }, [groupThreadsResult]);

  useEffect(() => {
    const { isSuccess, data } = eventThreadsResult;
    if (isSuccess && data) {
      data.forEach((threadInfo: ThreadFull) => {
        const lastReadItem = threadLastReadData.eventThreadLastRead.find(
          (item) => item.destinationId === threadInfo.destinationId
        );
        if (!lastReadItem || threadInfo.timePosted > lastReadItem.lastReadTime)
          if (
            !haveNewEventMessage &&
            !isOlderThanAWeek(new Date(threadInfo.timePosted))
          ) {
            setHaveNewEventMessage(true);
          }
      });
    }
  }, [eventThreadsResult]);

  useEffect(() => {
    if (userInfo.switchUser && userInfo.user) {
      const hasNew = haveNewDM || haveNewEventMessage || haveNewGroupMessage;
      if (userInfo.hasUnread != hasNew) {
        userInfo.switchUser(
          userInfo.id,
          userInfo.username,
          userInfo.firstName,
          userInfo.lastName,
          userInfo.imageUri,
          userInfo.adminLevel,
          userInfo.address,
          userInfo.displayAddress,
          userInfo.latitude,
          userInfo.longitude,
          userInfo.timeZone ?? guessTimeZone(),
          userInfo.user,
          hasNew
        );
      }
    }
  }, [haveNewDM, haveNewEventMessage, haveNewGroupMessage]);

  return (
    <>
      <Tabs
        selectedTabClassName={
          navigation.state === "loading" ? "skeleton-tab-selected" : undefined
        }
        selectedIndex={tabIndex}
        onSelect={onTabSelect}
        style={{ flex: 1, paddingRight: 10 }}
      >
        <div
          ref={divRef}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
            paddingTop: HEADER_VERTICAL_SPACING,
            paddingLeft: SIDE_PADDING,
            paddingRight: SIDE_PADDING,
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{ marginBottom: SECTION_VERTICAL_SPACING }}
          >
            <PrimaryLeftHeader>Inbox</PrimaryLeftHeader>
            {tabIndex === 0 && (
              <HorizontalRightAlignedContainer ref={titleRef}>
                <NavButton
                  iconName="add"
                  size={24}
                  onClick={() => goToComposeDM(navigate)}
                  loading={navigation.state === "loading"}
                />
              </HorizontalRightAlignedContainer>
            )}
            {tabIndex === 1 && (
              <HorizontalRightAlignedContainer ref={titleRef}>
                <NavButton
                  iconName="add"
                  size={24}
                  onClick={() => goToComposeGroupsMessage(navigate)}
                  loading={navigation.state === "loading"}
                />
              </HorizontalRightAlignedContainer>
            )}
            {tabIndex === 2 && (
              <HorizontalRightAlignedContainer ref={titleRef}>
                <div style={{ height: 36 }} />
              </HorizontalRightAlignedContainer>
            )}
          </HorizontalSpaceBetweenContainer>
          <TabList
            style={{
              marginLeft: -SIDE_PADDING,
              marginRight: -SIDE_PADDING,
              paddingLeft: SIDE_PADDING,
              paddingRight: SIDE_PADDING,
            }}
          >
            <Tab>
              <HorizontalContainer>
                <NormalText>Chats</NormalText>
                {haveNewDM && (
                  <div
                    style={{
                      marginBottom: -2,
                      marginLeft: LINE_HORIZONTAL_SPACING,
                    }}
                  >
                    <NewMessageIndicator />
                  </div>
                )}
              </HorizontalContainer>
            </Tab>
            <Tab>
              <HorizontalContainer>
                <NormalText>Groups</NormalText>
                {haveNewGroupMessage && (
                  <div
                    style={{
                      marginBottom: -2,
                      marginLeft: LINE_HORIZONTAL_SPACING,
                    }}
                  >
                    <NewMessageIndicator />
                  </div>
                )}
              </HorizontalContainer>
            </Tab>
            <Tab>
              <HorizontalContainer>
                <NormalText>Events</NormalText>
                <div
                  style={{
                    marginBottom: -2,
                    marginLeft: LINE_HORIZONTAL_SPACING,
                  }}
                >
                  <NewMessageIndicator
                    color={
                      !!haveNewEventMessage
                        ? colors.PRIMARY_BRAND
                        : "transparent"
                    }
                  />
                </div>
              </HorizontalContainer>
            </Tab>
          </TabList>
        </div>

        <div
          style={{
            paddingLeft: SIDE_PADDING,
            paddingRight: SIDE_PADDING,
          }}
        >
          <TabPanel>
            <ChatsTab
              // isSelected={tabIndex === 0}
              dmThreadsResult={dmThreadsResult}
            />
          </TabPanel>
          <TabPanel>
            <GroupActivityTab
              // isSelected={tabIndex === 1}
              groupThreadsResult={groupThreadsResult}
            />
          </TabPanel>
          <TabPanel>
            <EventActivityTab
              // isSelected={tabIndex === 2}
              eventThreadsResult={eventThreadsResult}
            />
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};
