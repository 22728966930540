import React, { useContext } from "react";
import { EventFull, GroupFull } from "../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { userContext } from "../../UserContext";
import { EventDetailsTitleImage } from "./sections/EventDetailsTitleImage";
import { EventDetailsInfo } from "./sections/EventDetailsInfo";
import { EventDetailsDescription } from "./sections/EventDetailsDescription";
import {
  createMessageSummary,
  isInThePast,
  userIsAttending,
  userIsEarlyBird,
  userIsHost,
  userIsOrganizer,
  userIsWaitlisted,
} from "../../utils";
import { EventDetailsActionButtons } from "./sections/EventDetailsActionButtons";
import { goToMessageHistory } from "../../utils/navUtil";
import { useNavigate } from "react-router-dom";
import { ClickableRow } from "../../components/rows/ClickableRow";
import { EventDetailsPeople } from "./sections/EventDetailsPeople";
import { COLORS } from "../../styles/colors";
import {
  ITEM_HORIZONTAL_SPACING,
  PAGE_BOTTOM_PADDING,
} from "../../styles/spacing";
import { StickyActionBar } from "../../components/StickyActionBar";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { EventDetailsErrors } from "./EventDetailsScreen";
import { TextRegular } from "../../styles/text";

type EventDetailsLayoutProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
  errors?: EventDetailsErrors;
};
export const EventDetailsLayout = (props: EventDetailsLayoutProps) => {
  const { eventDetails, groupDetails, errors } = props;
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;
  const navigate = useNavigate();
  const colors = COLORS["light"];
  const isOrganizer = userIsOrganizer(currentUserId, groupDetails);
  const isAdmin = currentUserInfo.adminLevel > 90;
  const lastMessageSummary = eventDetails.lastMessage
    ? createMessageSummary(
        eventDetails.lastMessage.firstName,
        eventDetails.lastMessage.messageText,
        40
      )
    : "View event comments";
  const showAttendees =
    isAdmin ||
    isInThePast(new Date(eventDetails.rsvpDate)) ||
    userIsHost(currentUserInfo.id, eventDetails, groupDetails) ||
    userIsOrganizer(currentUserInfo.id, groupDetails) ||
    userIsEarlyBird(eventDetails, currentUserInfo.id) ||
    userIsAttending(currentUserInfo.id, eventDetails) ||
    userIsWaitlisted(currentUserInfo.id, eventDetails);

  return (
    <div style={{ paddingBottom: PAGE_BOTTOM_PADDING }}>
      <StickyActionBar>
        <EventDetailsActionButtons
          eventDetails={eventDetails}
          groupDetails={groupDetails}
          canEdit={isOrganizer || isAdmin}
        />
      </StickyActionBar>
      {errors?.generic && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.generic}</TextRegular>
        </HorizontalContainer>
      )}
      <EventDetailsTitleImage eventDetails={eventDetails} />
      <ItemVerticalSpace />
      <EventDetailsInfo eventDetails={eventDetails} />
      <SectionVerticalSpace />

      <ItemVerticalSpace />

      <EventDetailsPeople
        eventDetails={eventDetails}
        groupDetails={groupDetails}
        showAttendees={showAttendees}
      />

      {eventDetails.description && (
        <>
          <ItemVerticalSpace />
          <EventDetailsDescription eventDetails={eventDetails} />
          <ItemVerticalSpace />
        </>
      )}

      <HorizontalLeftAlignedContainer
        style={{
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: colors.LIGHT_GRAY,
          marginLeft: ITEM_HORIZONTAL_SPACING,
          marginRight: ITEM_HORIZONTAL_SPACING,
        }}
      >
        <ClickableRow
          title="Comments"
          subTitle={lastMessageSummary}
          onPress={() =>
            goToMessageHistory(
              navigate,
              "event",
              eventDetails.id,
              eventDetails.title
            )
          }
        />
      </HorizontalLeftAlignedContainer>
    </div>
  );
};
