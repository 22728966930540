import React, { Dispatch, SetStateAction } from "react";

import Drawer from "react-modern-drawer";
import { RevisionHistory } from "./RevisionHistory";

type ContextHistoryDrawerProps = {
  targetType: string;
  targetId: string;
  showDrawer: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
};

export const ContextHistoryDrawer = (props: ContextHistoryDrawerProps) => {
  const { targetType, targetId, showDrawer, setShowDrawer } = props;

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <Drawer
        open={showDrawer}
        onClose={() => toggleDrawer()}
        direction="right"
        className="venue-picker"
        size={500}
        lockBackgroundScroll={true}
        zIndex={2000}
      >
        <RevisionHistory
          title="Revision History"
          targetType={targetType}
          targetId={targetId}
        />
      </Drawer>
    </>
  );
};
