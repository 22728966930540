import React from "react";
import { GroupFull, GroupStatusType } from "../../../types";
import { GroupStatus } from "../../../types/enums";
import { Banner } from "../../../components/rows/Banner";

type GroupStatusBannerProps = {
  groupStatus: GroupStatusType;
};

export const GroupStatusBanner = (props: GroupStatusBannerProps) => {
  const isDeactivated = () => {
    if (props.groupStatus === GroupStatus.Inactive) return true;
    else return false;
  };
  const isDeleted = () => {
    if (props.groupStatus === GroupStatus.Deleted) return true;
    else return false;
  };

  return (
    <div>
      {isDeactivated() && (
        <Banner
          title="This group is deactivated"
          iconName="warning-outline"
          type="warning"
          borderRadius={0}
        />
      )}
      {isDeleted() && (
        <Banner
          title="This group has been deleted"
          iconName="close-circle-outline"
          type="error"
          borderRadius={0}
        />
      )}
    </div>
  );
};
