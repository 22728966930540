import { useNavigation } from "react-router-dom";
import { COLORS } from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { TextSmall } from "../../styles/text";
import { SmallText } from "../text/SmallText";

type EditTitleProps = {
  title: string;
  required?: boolean;
};

export const EditTitle = (props: EditTitleProps) => {
  const { title, required } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  return (
    <HorizontalLeftAlignedContainer style={{ marginBottom: 8 }}>
      <SmallText color={colors.SECONDARY_TEXT}>{title}</SmallText>
      {required && navigation.state !== "loading" && (
        <TextSmall
          style={{ paddingLeft: 4, marginTop: -4 }}
          color={colors.PRIMARY_RED}
        >
          *
        </TextSmall>
      )}
    </HorizontalLeftAlignedContainer>
  );
};
