import React from "react";
import { SubScreenContainer } from "../styles/container";
import { useNavigation } from "react-router-dom";
import { LoadingSection } from "./loading/LoadingSection";

type ScreenContainerProps = {
  horizontalPadding?: number;
  tabIndex?: number;
};
export const ScreenContainer = (
  props: React.PropsWithChildren<ScreenContainerProps>
) => {
  const { horizontalPadding } = props;
  const navigation = useNavigation();

  return (
    <>
      {navigation.state === "loading" ? (
        <SubScreenContainer horizontalPadding={horizontalPadding}>
          <LoadingSection
            location={navigation.location.pathname}
            tabIndex={props.tabIndex}
          />
        </SubScreenContainer>
      ) : (
        <SubScreenContainer horizontalPadding={horizontalPadding}>
          {props.children}
        </SubScreenContainer>
      )}
    </>
  );
};
