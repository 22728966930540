import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { EventFull, GroupFull, PeopleData, VenueFull } from "../../../types";
import { getDateInXHours } from "../../../utils";
import { TitledMemberPicker } from "../../../features/TitledMemberPicker/TitledMemberPicker";
import { TitledInput } from "../../../components/inputs/TitledInput";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../../components/inputs/TitledDropdown";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { TitledDateTimePicker } from "../../../components/inputs/TitledDateTimePicker";
import { TitledVenuePicker } from "../../../features/TitledVenuePicker/TitledVenuePicker";
import { EventErrors } from "../EditEventScreen";
import { TextRegular } from "../../../styles/text";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { ItemHorizontalSpace } from "../../../components/spacing/ItemHorizontalSpace";
import { UseQueryResult } from "react-query";

type EditEventLogisticsProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
  membersQueryResult: UseQueryResult<PeopleData[]>;
  selectedVenue: VenueFull;
  setSelectedVenue: Dispatch<SetStateAction<VenueFull>>;
  errors?: EventErrors;
};

export const EditEventLogistics = (props: EditEventLogisticsProps) => {
  const {
    eventDetails,
    groupDetails,
    membersQueryResult,
    selectedVenue,
    setSelectedVenue,
    errors,
  } = props;

  const [eventDate, setEventDate] = useState(
    eventDetails.date
      ? new Date(eventDetails.date)
      : getDateInXHours(new Date(), 2)
  );
  const [eventRSVPDate, setEventRSVPDate] = useState(
    eventDetails.rsvpDate
      ? new Date(eventDetails.rsvpDate)
      : getDateInXHours(new Date(), 2)
  );
  const [durationValue, setDurationValue] = useState<DropdownValueType>(
    eventDetails.duration
  );

  const durationItems = [
    { label: "Unspecified", value: 0 },
    { label: "1 Hour", value: 1 },
    { label: "1.5 Hours", value: 1.5 },
    { label: "2 Hours", value: 2 },
    { label: "2.5 Hours", value: 2.5 },
    { label: "3 Hours", value: 3 },
    { label: "3.5 Hours", value: 3.5 },
  ];

  const [eventCapacity, setEventCapacity] = useState(
    eventDetails.capacity.toString()
  );
  const [eventHostsList, setEventHostsList] = useState<PeopleData[]>(
    eventDetails.hosts
  );
  const removeHost = (userId: string) => {
    const updatedHostsList = eventHostsList.filter(
      (host) => host.userId !== userId
    );
    setEventHostsList(updatedHostsList);
  };

  const validateEventDate = (trialEventDate: Date): Date => {
    const today = new Date();
    if (trialEventDate < today) {
      // can't schedule an event in the past
      alert("Error: Your event date must be in the future!");
      trialEventDate = today;
    }
    // Now make sure the rsvp open date is a day before the event date.
    // Don't actually change the event date we are given.
    let latestAllowedRSVPDate = new Date(trialEventDate);
    let suggestedRSVPDate = latestAllowedRSVPDate;
    suggestedRSVPDate.setDate(suggestedRSVPDate.getDate() - 1);
    if (eventRSVPDate > latestAllowedRSVPDate) {
      setEventRSVPDate(suggestedRSVPDate);
    }
    return trialEventDate; // trial date should be unchanged
  };

  const validateRSVPDate = (trialRSVPDate: Date): Date => {
    const today = new Date();
    if (trialRSVPDate < today) {
      // can't open RSVPs in the past
      // just set the open RSVP time to now and return it, no need to throw an alert for this
      //Alert.alert("Error", "Your RSVP date must be in the present or future!");
      trialRSVPDate = today;
    }
    // If RSVPDate after event date, set it to 1 day before.
    const latestAllowedRSVPDate = new Date(eventDate);
    let suggestedRSVPDate = new Date(latestAllowedRSVPDate);
    suggestedRSVPDate.setDate(suggestedRSVPDate.getDate() - 1);

    if (trialRSVPDate > latestAllowedRSVPDate) {
      alert("Error: Your RSVP open date must be before the event starts!");
      return suggestedRSVPDate;
    } else {
      return trialRSVPDate;
    }
  };

  useEffect(() => {
    setEventDate(new Date(eventDetails.date));
    setEventRSVPDate(new Date(eventDetails.rsvpDate));
    setDurationValue(eventDetails.duration);
    setEventCapacity(eventDetails.capacity.toString());
    setEventHostsList(eventDetails.hosts);
    setSelectedVenue({
      id: eventDetails.venueId,
      nickname: eventDetails.venueNickname,
      address: eventDetails.venueAddress,
      displayAddress: eventDetails.venueDisplayAddress,
      imageUri: eventDetails.venueImageUri,
      latitude: eventDetails.latitude,
      longitude: eventDetails.longitude,
    } as VenueFull);
  }, [eventDetails]);

  return (
    <>
      <HorizontalContainer>
        <TitledVenuePicker
          title="VENUE"
          formDataName="eventvenueid"
          venue={selectedVenue}
          setVenue={setSelectedVenue}
          groupDetails={groupDetails}
          required={true}
        />
      </HorizontalContainer>
      <ItemVerticalSpace />
      {errors?.venue && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.venue}</TextRegular>
        </HorizontalContainer>
      )}
      <HorizontalSpaceBetweenContainer>
        <HorizontalContainer>
          <TitledInput
            value={eventCapacity}
            formDataName="eventcapacity"
            title="CAPACITY"
            setText={setEventCapacity}
            placeholderText="Maximum number of attendees"
            maxLength={4}
            editable={true}
          />
        </HorizontalContainer>
        <ItemHorizontalSpace />
        <HorizontalContainer>
          <TitledDropdown
            title="EVENT DURATION"
            formDataName="eventduration"
            value={durationValue}
            setValue={setDurationValue}
            items={durationItems}
          />
        </HorizontalContainer>
      </HorizontalSpaceBetweenContainer>
      {errors?.capacity && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.capacity}</TextRegular>
        </HorizontalContainer>
      )}
      <ItemVerticalSpace />

      <HorizontalContainer>
        <TitledMemberPicker
          membersQueryResult={membersQueryResult}
          title="EVENT HOSTS"
          formDataName="eventhosts"
          members={eventHostsList}
          setMembers={setEventHostsList}
          maximum={6}
          ineligible={[]}
        />
      </HorizontalContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer style={{ marginRight: 11 }}>
        <TitledDateTimePicker
          title="DATE"
          formDataName="eventdate"
          date={eventDate}
          setDate={setEventDate}
          validateDate={validateEventDate}
        />
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer style={{ marginRight: 11 }}>
        <TitledDateTimePicker
          title="RSVP OPEN DATE"
          formDataName="eventrsvpdate"
          date={eventRSVPDate}
          setDate={setEventRSVPDate}
          validateDate={validateRSVPDate}
        />
      </HorizontalLeftAlignedContainer>
    </>
  );
};
