import { addQueryItem2 } from "./queryStringUtil";
import { GP_API_KEY } from "../env";
import { Location } from "../features/TitledLocationPicker/LocationPicker";

export const getStaticMapUri = (location: Location): string => {
  const uriBase = "https://maps.googleapis.com";
  const uriPath = "/maps/api/staticmap";

  let queryParamString = "";
  if (location) {
    const latlng = location.lat + "," + location.lng;
    queryParamString = addQueryItem2(queryParamString, "center", latlng);
    // queryParamString = addQueryItem2(queryParamString, "markers", latlng);
  }
  queryParamString = addQueryItem2(queryParamString, "size", "275x275");
  queryParamString = addQueryItem2(queryParamString, "zoom", "14");

  if (GP_API_KEY)
    queryParamString = addQueryItem2(
      queryParamString,
      "key",
      "AIzaSyDBuIJsR9EE9VgM7wWEaf_THLk9J4J0fj0"
    );

  const unsignedUri = uriBase + uriPath + queryParamString;

  // let signedUri;
  // if (GP_SIGNING_SECRET) signedUri = signGPUri(unsignedUri, GP_SIGNING_SECRET);
  // else signedUri = unsignedUri;
  // const unsignedURLFrag = uriPath + queryParamString;
  // const signature = getSignature(unsignedURLFrag);
  // queryParamString = addQueryItem2(queryParamString, "signature", signature);

  return unsignedUri;
};

export const getStaticMapUriByLatLong = (lat: number, lng: number): string => {
  const uriBase = "https://maps.googleapis.com";
  const uriPath = "/maps/api/staticmap";

  let queryParamString = "";
  const latlng = lat + "," + lng;
  queryParamString = addQueryItem2(queryParamString, "center", latlng);
  queryParamString = addQueryItem2(queryParamString, "size", "400x400");
  queryParamString = addQueryItem2(queryParamString, "zoom", "14");

  if (GP_API_KEY)
    queryParamString = addQueryItem2(
      queryParamString,
      "key",
      "AIzaSyDBuIJsR9EE9VgM7wWEaf_THLk9J4J0fj0"
    );

  const unsignedUri = uriBase + uriPath + queryParamString;

  return unsignedUri;
};
