import React, { useEffect, useState } from "react";
import {
  redirect,
  useActionData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { ConfirmAccountScreenLayout } from "./ConfirmAccountScreenLayout";
import { logSentryError } from "../../utils/sentryUtil";

export type ConfirmAccountErrors = {
  ok?: boolean;
  newPasswordRequired?: boolean;
  code?: string;
  codeMismatch?: string;
  codeExpired?: string;
  codeNotAuthorized?: string;
  userNotFound?: string;
  email?: string;
  password?: string;
  other?: string;
};

const validateCodeData = (code: string, email: string) => {
  const err: ConfirmAccountErrors = {};
  if (!email)
    err.email = "Please specify the email address your code was sent to";
  if (!code)
    err.code = "Please enter the code that was sent to your email address.";
  return err;
};

export const confirmAccountAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  const data = await request.formData();
  const code = fdGetString(data, "code");
  const email = fdGetString(data, "email");
  const password = fdGetString(data, "password");
  const resend = fdGetString(data, "resend");

  if (!!resend) {
    try {
      await Auth.resendSignUp(email);
      return { ok: true, other: "Success: the code was re-sent to your Email" };
    } catch (e: any) {
      logSentryError("Error resending confirmation code Account", e, {
        tags: {
          userId: "none",
          screen: "ConfirmAccountScreen",
          function: "confirmAccountAction",
        },
        level: "error",
      });
      return {
        ok: true,
        code: "There was an error resending your code, please try again!",
      } as ConfirmAccountErrors;
    }
  }

  const validateResult = validateCodeData(code, email);
  if (Object.keys(validateResult).length) {
    return {
      ok: false,
      ...validateResult,
    } as ConfirmAccountErrors;
  }

  try {
    await Auth.confirmSignUp(email, code);
    if (!!password) {
      await Auth.signIn(email, password);
      return { ok: true } as ConfirmAccountErrors;
    } else {
      return redirect("/");
    }
  } catch (e: any) {
    if (e.code === "PasswordResetRequiredException") {
      // code was fine, but Amplify is requiring a password reset
      return { ok: false, newPasswordRequired: true } as ConfirmAccountErrors;
    } else if (e.code === "CodeMismatchException") {
      return {
        ok: false,
        codeMismatch: "Please try again.  The code does not match.",
      } as ConfirmAccountErrors;
    } else if (e.code === "ExpiredCodeException") {
      return {
        ok: false,
        codeExpired: "This code has expired.  Click re-send to try again!",
      } as ConfirmAccountErrors;
    } else if (e.code === "NotAuthorizedException") {
      Auth.signIn(email, password);
      return {
        ok: false,
        codeNotAuthorized: "This account has already been confirmed",
      } as ConfirmAccountErrors;
    } else if (e.code === "UserNotFoundException") {
      return {
        ok: false,
        userNotFound:
          "Could not find this email address, please check your spelling.",
      } as ConfirmAccountErrors;
    } else {
      logSentryError("Error Confirming Account", e, {
        tags: {
          userId: "none",
          screen: "ConfirmAccountScreen",
          function: "confirmAccountAction",
        },
        level: "error",
      });
      return {
        ok: false,
        other: "An error occurred processing your confirmation code: " + e.code,
      } as ConfirmAccountErrors;
    }
  }
};

export const confirmAccountLoader = async (currentUserId: string) => {
  if (!!currentUserId) return redirect("/events");
  return { ok: true };
};

export const ConfirmAccountScreen = () => {
  const navigate = useNavigate();
  const confirmAccountErrors = useActionData() as ConfirmAccountErrors;

  const location = useLocation();

  const { state } = location;
  const email: string = state?.email;
  const password: string = state?.password;

  const [emailSpecified] = useState(email ?? "");
  const [passwordSpecified] = useState(password ?? "");

  if (confirmAccountErrors?.newPasswordRequired) {
    navigate("/newPassword", { state: { email: email } });
  }

  useEffect(() => {
    document.title = "Confirm Account -- Picklers";
  }, []);

  return (
    <ConfirmAccountScreenLayout
      errors={confirmAccountErrors}
      email={emailSpecified}
      password={passwordSpecified}
    />
  );
};
