import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

export type TouchableOpacityProps = ButtonHTMLAttributes<HTMLButtonElement>;

export default function TouchableOpacity({
  className,
  children,
  ...rest
}: TouchableOpacityProps) {
  const [touched, touchedSet] = React.useState(false);
  return (
    <button
      className={classNames(className)}
      style={{ opacity: touched ? 0.5 : 1, transition: "opacity 300ms ease" }}
      onMouseDown={() => touchedSet(true)}
      onMouseUp={() => touchedSet(false)}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
}
