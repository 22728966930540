import { EventFull, GroupFull, MemberFull, PeopleData } from "../types";
// import { userContextType } from "../UserContext";

export const userIsOwner = (userId: string, groupDetails?: GroupFull) => {
    if (groupDetails && userId===groupDetails.ownerId) 
        return true;
    return false;
}
export const userIsOrganizer = (userId: string, groupDetails?: GroupFull) => {
    if (userIsOwner(userId, groupDetails)) {
      return true;
    }
    if (groupDetails && groupDetails.assistants && undefined !== groupDetails.assistants.find((assistant:PeopleData) => {return assistant.userId===userId}))
        {
          return true;
        }
    return false;
}
export const userIsHost = (userId: string, eventDetails?: EventFull, groupDetails?: GroupFull) => {
    if (eventDetails && eventDetails.hosts && undefined !== eventDetails.hosts.find((host:PeopleData) => {return host.userId===userId}))
        return true;
    return false;
}

export const getUserTitle = (userId: string, eventDetails?: EventFull, groupDetails?: GroupFull) => {
  let hostDecoration = "";
  if (userIsHost(userId, eventDetails, groupDetails))
    hostDecoration = "Host";
  return hostDecoration;
}

export const userIsAttending = (userId: string, eventDetails?: EventFull) => {
    if (eventDetails && eventDetails.attendeeList && eventDetails.attendeeList.includes(userId))
        return true;
    return false;
}

export const userIsWaitlisted = (userId: string, eventDetails?: EventFull) => {
    if (eventDetails && eventDetails.attendeeList && eventDetails.waitList.includes(userId))
        return true;
    return false;
}

export const memberIsMember = (memberData?: MemberFull) => {
    if (
      memberData &&
      (memberData.status==="accepted" ||
        memberData.status==="organizer" ||
        memberData.status==="owner")
    ) {
      return true;
    }
    return false;
  };

export const memberIsApplicant = (memberData?: MemberFull) => {
    if (memberData && memberData.status==="applied") return true;
    return false;
  };

export  const memberIsOrganizer = (memberData?: MemberFull) => {
    if (memberData && memberData.status==="organizer") return true;
    return false;
  };

export  const memberIsOwner = (memberData?: MemberFull) => {
    if (memberData && memberData.status==="owner") return true;
    return false;
  };

export const memberIsAtLeastMember = (memberData?: MemberFull) => {
    if (memberData && !memberIsApplicant(memberData)) return true;
    return false;
}

export const userIsEarlyBird = (eventDetails: EventFull, userId: string) => {
  return (undefined !== eventDetails.earlyRSVPs.find((person) => { return person.userId === userId }))
}