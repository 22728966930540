import { UseInfiniteQueryResult, UseQueryResult } from "react-query";
import { MemberFull, MessageFull } from "../../types";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListSpinner } from "../../components/general/ListSpinner";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MyMessageCard } from "./Components/MyMessageCard";
import { HorizontalContainer } from "../../styles/container";
import { userContext } from "../../UserContext";
import { MessageSendBar } from "../../components/MessageSendBar/MessageSendBar";
import { ActionBar } from "../../features/actionBars/ActionBar";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../styles/spacing";
import { useWindowHeight } from "@react-hook/window-size";
import { OtherMessageCard } from "./Components/OtherMessageCard";
import { GroupEventMessageCard } from "./Components/GroupEventMessageCard";
import { EmptyLayout } from "../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyMessages } from "../../assets/images/empty-state/empty-messages.svg";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  excludeSelfFromDMTitle,
  memberIsAtLeastMember,
  memberIsOrganizer,
  memberIsOwner,
} from "../../utils";
import { StickyActionBar } from "../../components/StickyActionBar";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { ImageOverlay } from "../../components/image/ImageOverlay";
import { MessageHistoryErrors } from "./MessageHistoryScreen";

type MessageHistoryLayoutProps = {
  messageType: string;
  destinationId: string;
  threadTitle: string;
  messagesQueryResult: UseInfiniteQueryResult<MessageFull[] | undefined>;
  membershipQueryResult: UseQueryResult<MemberFull | undefined>;
  errors: MessageHistoryErrors;
};

export const MessageHistoryLayout = (props: MessageHistoryLayoutProps) => {
  const {
    messageType,
    destinationId,
    threadTitle,
    messagesQueryResult,
    membershipQueryResult,
    errors,
  } = props;
  const { isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage, data } =
    messagesQueryResult;
  const { data: membershipData } = membershipQueryResult;
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;
  const navigation = useNavigation();

  const title =
    messageType === "dm"
      ? excludeSelfFromDMTitle(threadTitle, currentUserInfo.firstName)
      : threadTitle;
  const [items, setItems] = useState<MessageFull[]>([]);
  const upperDivRef = useRef<HTMLDivElement>(null);
  const lowerDivRef = useRef<HTMLDivElement>(null);
  const [lowerHeight, setLowerHeight] = useState(100);
  const [upperHeight, setUpperHeight] = useState(100);
  const windowHeight = useWindowHeight();
  const [overlayImageUri, setOverlayImageUri] = useState("");
  const canSendMessage =
    messageType !== "group" ||
    memberIsAtLeastMember(membershipData) ||
    currentUserInfo.adminLevel > 90;
  const isOrganizer =
    messageType !== "dm" &&
    (memberIsOwner(membershipData) || memberIsOrganizer(membershipData));

  const hasMore = () => {
    const lastPage = data?.pages?.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreMessages = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (isSuccess && data && data.pages) {
      setItems(data.pages.map((page) => (page ? page : [])).flat());
    } else {
      setItems([]);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (upperDivRef.current) setLowerHeight(upperDivRef.current.offsetHeight);
    if (lowerDivRef.current) setUpperHeight(lowerDivRef.current.offsetHeight);
  }, []);

  return (
    <>
      {isSuccess && data && (
        <>
          <div ref={upperDivRef}>
            <StickyActionBar>
              <ActionBar backTitle="Back" title={title} />
            </StickyActionBar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <InfiniteScroll
              dataLength={items.length} //This is important field to render the next data
              next={fetchMoreMessages}
              hasMore={!!hasMore()}
              loader={<h4>Loading...</h4>}
              inverse={true}
              height={
                windowHeight -
                lowerHeight -
                upperHeight -
                3 * ITEM_VERTICAL_SPACING
              }
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {items.length <= 0 && (
                <EmptyLayout
                  title="No messages yet"
                  subTitle="Start the conversation by entering your message below!"
                >
                  <EmptyMessages />
                </EmptyLayout>
              )}
              {items.map((item, index) => {
                if (messageType === "group" || messageType === "event") {
                  return (
                    <GroupEventMessageCard
                      key={index}
                      messageDetails={item}
                      setSelectedImage={setOverlayImageUri}
                      canPin={messageType === "group" && isOrganizer}
                      canDelete={
                        isOrganizer ||
                        currentUserInfo.adminLevel > 90 ||
                        currentUserId === item.userId
                      }
                    />
                  );
                } else if (
                  item.userId === currentUserId ||
                  (navigation.state === "loading" && index % 2 === 0)
                ) {
                  return (
                    <MyMessageCard
                      key={index}
                      messageDetails={item}
                      setSelectedImage={setOverlayImageUri}
                    />
                  );
                } else {
                  return (
                    <OtherMessageCard
                      key={index}
                      messageDetails={item}
                      setSelectedImage={setOverlayImageUri}
                    />
                  );
                }
              })}
              {hasNextPage && isFetchingNextPage && <ListSpinner />}
            </InfiniteScroll>
          </div>
          <div ref={lowerDivRef}>
            <HorizontalContainer
              style={{ width: "100%", marginTop: ITEM_VERTICAL_SPACING }}
            >
              <MessageSendBar
                target={"/messagehistory/"}
                messageType={messageType}
                destinationId={destinationId}
                canSendMessage={canSendMessage}
                refresh={!!errors?.resetForm}
                title={encodeURIComponent(threadTitle)}
              />
            </HorizontalContainer>
          </div>
        </>
      )}
      <ImageOverlay
        imageUri={overlayImageUri}
        setImageUri={setOverlayImageUri}
      />
    </>
  );
};
