import React from "react";
import { EventFull, GroupFull } from "../../../types";
import { TextRegular } from "../../../styles/text";
import { formatDateTime, isInTheFuture } from "../../../utils";
import { DetailsActionButtonBar } from "../../../features/actionBars/DetailsActionButtonBar";
import { REDIRECT_URL } from "../../../env";
import { ShareButton } from "../../../features/ShareButton";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import { useFetcher, useNavigate } from "react-router-dom";
import {
  goToDuplicateEvent,
  goToEditEvent,
  goToRepeatEvent,
  goToReport,
} from "../../../utils/navUtil";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { CustomRecurringAlertUI } from "../../../components/confirmDialogs/CustomRecurringAlert";

type EventDetailsActionButtonProps = {
  eventDetails: EventFull;
  groupDetails: GroupFull;
  canEdit: boolean;
};
export const EventDetailsActionButtons = (
  props: EventDetailsActionButtonProps
) => {
  const { eventDetails, groupDetails, canEdit } = props;
  const navigate = useNavigate();
  const fetcher = useFetcher();

  const formattedEventDate = formatDateTime(new Date(eventDetails.date));
  // const formattedEventDate = formatDateTime(
  //   new Date(eventDetails.date),
  //   false,
  //   eventDetails.timeZone
  // );
  const shareUri = `${REDIRECT_URL}event/view/${eventDetails.id}`;

  const canBeEdited =
    eventDetails.status === "active" &&
    isInTheFuture(new Date(eventDetails.date));
  const canBeRestored =
    eventDetails.status === "canceled" &&
    groupDetails.status === "active" &&
    isInTheFuture(new Date(eventDetails.date));
  const canBeDuplicated =
    groupDetails.status === "active" && eventDetails.status !== "deleted";
  const canBeRecurred = canBeDuplicated;
  const canBeDeleted = eventDetails.status === "canceled";
  const returnUri = `/event/view/${eventDetails.id}/`;

  const doEditEvent = () => {
    goToEditEvent(navigate, groupDetails.id, eventDetails.id);
  };

  const doReport = () => {
    const reportQueryString = `?targetType=event&targetId=${eventDetails.id}&targetName=${eventDetails.title}&targetDate=${eventDetails.date}&parentName=${eventDetails.groupName}&targetName=${groupDetails.name}&returnUri=${returnUri}`;
    goToReport(navigate, reportQueryString);
  };

  const doDuplicateEvent = () => {
    goToDuplicateEvent(navigate, groupDetails.id, eventDetails.id);
  };

  const doRecurEvent = () => {
    goToRepeatEvent(navigate, eventDetails.id);
  };

  const submitContextEvent = (
    action: string,
    doRecurring: boolean,
    onClose: () => void
  ) => {
    fetcher.submit(
      { contextAction: action, dorecurring: doRecurring.toString() },
      { method: "POST", action: "eventContextAction" }
    );
    onClose();
  };

  const doCancelEvent = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        if (eventDetails.originalEventId) {
          return (
            <CustomRecurringAlertUI
              title={"Cancel recurring event?"}
              content={
                "This event is part of a recurring series.  Do you want to cancel just this event, or cancel this and all later events in this series?"
              }
              yesButtonClick={() => submitContextEvent("cancel", true, onClose)}
              partialButtonClick={() =>
                submitContextEvent("cancel", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        } else {
          return (
            <CustomAlertUI
              title={"Cancel this event?"}
              content={"Are you sure you want to cancel this event?"}
              yesButtonClick={() =>
                submitContextEvent("cancel", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        }
      },
    });
  };

  const doRestoreEvent = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        if (eventDetails.originalEventId) {
          return (
            <CustomRecurringAlertUI
              title={"Restore recurring event?"}
              content={
                "This event is part of a recurring series.  Do you want to restore just this event, or restore this and all later events in this series?"
              }
              yesButtonClick={() =>
                submitContextEvent("restore", true, onClose)
              }
              partialButtonClick={() =>
                submitContextEvent("restore", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        } else {
          return (
            <CustomAlertUI
              title={"Restore this event?"}
              content={"Are you sure you want to restore this event?"}
              yesButtonClick={() =>
                submitContextEvent("restore", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        }
      },
    });
  };

  const doDeleteEvent = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        if (eventDetails.originalEventId) {
          return (
            <CustomRecurringAlertUI
              title={"Delete recurring event?"}
              content={
                "This event is part of a recurring series.  Do you want to delete just this event, or delete this and all later canceled events in this series?"
              }
              yesButtonClick={() => submitContextEvent("delete", true, onClose)}
              partialButtonClick={() =>
                submitContextEvent("delete", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        } else {
          return (
            <CustomAlertUI
              title={"Delete this event?"}
              content={"Are you sure you want to delete this event?"}
              yesButtonClick={() =>
                submitContextEvent("delete", false, onClose)
              }
              noButtonClick={() => onClose()}
              onClose={onClose}
            />
          );
        }
      },
    });
  };

  const eventActionButtons: ContextMenuItem[] = [];

  if (canEdit && canBeDuplicated) {
    eventActionButtons.push({
      label: <TextRegular>Duplicate This Event</TextRegular>,
      value: "duplicate",
      action: doDuplicateEvent,
    });
  }

  if (canEdit && canBeRecurred) {
    eventActionButtons.push({
      label: <TextRegular>Set Up Recurring Event</TextRegular>,
      value: "recur",
      action: doRecurEvent,
    });
  }

  if (canEdit && canBeEdited) {
    eventActionButtons.push({
      label: <TextRegular>Edit This Event</TextRegular>,
      value: "edit",
      action: doEditEvent,
    });
    eventActionButtons.push({
      label: <TextRegular>Cancel This Event</TextRegular>,
      value: "cancel",
      action: doCancelEvent,
    });
  }

  if (canEdit && canBeRestored) {
    eventActionButtons.push({
      label: <TextRegular>Restore This Event</TextRegular>,
      value: "restore",
      action: doRestoreEvent,
    });
  }

  if (canEdit && canBeDeleted) {
    eventActionButtons.push({
      label: <TextRegular>Delete This Event</TextRegular>,
      value: "delete",
      action: doDeleteEvent,
    });
  }

  eventActionButtons.push({
    label: <TextRegular>Report This Event</TextRegular>,
    value: "report",
    action: doReport,
  });

  return (
    <>
      <DetailsActionButtonBar backButtonTitle="Back">
        <ShareButton
          shareDialogTitle={"Share This Event"}
          shareTitle={`${eventDetails.title} on Picklers`}
          shareMessage={`I'd like to share "${eventDetails.title}" on ${formattedEventDate} with you on Picklers!  `}
          shareUrl={shareUri}
        />
        <NewCustomContextMenu contextMenuItems={eventActionButtons} />
      </DetailsActionButtonBar>
    </>
  );
};
