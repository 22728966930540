export const excludeSelfFromDMTitle = (title: string, firstName?: string) => {
    if (!firstName) return title
    else if (title.includes(`, ${firstName}`)) return title.replace(`, ${firstName}`, '')
    else if (title.includes(`${firstName}, `)) return title.replace(`${firstName}, `, '') 
    else if (title.includes(`, ${firstName}, `)) return title.replace(`, ${firstName}, `, '')

    return title
}

export const createMessageSummary = (firstName: string, message: string, maxLength: number) => {
    // this will be the default if the message is empty, which means it was just an image with no text
    let truncatedMessage = "(media)";
    if (message.length > maxLength) {
        //trim the message to the maximum length
        truncatedMessage = message.substring(0, maxLength);

        //re-trim if we are in the middle of a word
        truncatedMessage = truncatedMessage.substring(0, Math.min(truncatedMessage.length, truncatedMessage.lastIndexOf(" "))) + "...";
    }
    else if (message.length > 0) {
        truncatedMessage = message;
    }

    return `${firstName}: ${truncatedMessage}`
}