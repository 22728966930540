import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { COLORS } from "../../styles/colors";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextSmall } from "../../styles/text";
import Select from "react-select";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { EditTitle } from "./EditTitle";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { SmallText } from "../text/SmallText";
import { LineHorizontalSpace } from "../spacing/LineHorizontalSpace";
import { getBorderRadius } from "../../utils";

type SkillRangeInputProps = {
  title: string;
  skillMinValue: number;
  skillMaxValue: number;
  setSkillMinValue: Dispatch<SetStateAction<number>>;
  setSkillMaxValue: Dispatch<SetStateAction<number>>;
  formDataMinName: string;
  formDataMaxName: string;
};
const skillMinItems = [
  { label: "Any", value: 0 },
  { label: "2.5", value: 2.5 },
  { label: "3.0", value: 3.0 },
  { label: "3.5", value: 3.5 },
  { label: "4.0", value: 4.0 },
  { label: "4.5", value: 4.5 },
  { label: "5.0", value: 5.0 },
];
const skillMaxItems = [
  { label: "Any", value: 10 },
  { label: "2.5", value: 2.5 },
  { label: "3.0", value: 3.0 },
  { label: "3.5", value: 3.5 },
  { label: "4.0", value: 4.0 },
  { label: "4.5", value: 4.5 },
  { label: "5.0", value: 5.0 },
];

export const SkillRangeDropDowns = (props: SkillRangeInputProps) => {
  const {
    title,
    skillMinValue,
    skillMaxValue,
    setSkillMinValue,
    setSkillMaxValue,
    formDataMinName,
    formDataMaxName,
  } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const initialSkillMin = skillMinItems.find(
    (item) => item.value === skillMinValue
  );
  const initialSkillMax = skillMaxItems.find(
    (item) => item.value === skillMaxValue
  );
  const [selectedMinOption, setSelectedMinOption] = useState(
    initialSkillMin ? initialSkillMin : skillMinItems[0]
  );
  const [selectedMaxOption, setSelectedMaxOption] = useState(
    initialSkillMax ? initialSkillMax : skillMaxItems[0]
  );

  const validateSkillMax = (minSkillNum: number | null) => {
    if (minSkillNum && minSkillNum !== 0 && skillMaxValue !== 10) {
      if (minSkillNum > skillMaxValue) {
        setSkillMaxValue(minSkillNum);
        const maxOption = skillMaxItems.find((item) => {
          return item.value === minSkillNum;
        });
        if (maxOption) setSelectedMaxOption(maxOption);
      }
    }
  };
  const validateSkillMin = (maxSkillNum: number | null) => {
    if (maxSkillNum && maxSkillNum !== 10 && skillMinValue !== 0) {
      if (skillMinValue > maxSkillNum) {
        setSkillMinValue(maxSkillNum);
        const minOption = skillMinItems.find((item) => {
          return item.value === maxSkillNum;
        });
        if (minOption) setSelectedMinOption(minOption);
      }
    }
  };
  const handleMinChange = (selectedOption: any) => {
    validateSkillMax(selectedOption.value);
    setSkillMinValue(selectedOption.value);
    setSelectedMinOption(selectedOption);
  };
  const handleMaxChange = (selectedOption: any) => {
    validateSkillMin(selectedOption.value);
    setSkillMaxValue(selectedOption.value);
    setSelectedMaxOption(selectedOption);
  };

  useEffect(() => {
    const initialSkillMin = skillMinItems.find(
      (item) => item.value === skillMinValue
    );
    if (initialSkillMin) setSelectedMinOption(initialSkillMin);
  }, [skillMinValue]);

  useEffect(() => {
    const initialSkillMax = skillMaxItems.find(
      (item) => item.value === skillMaxValue
    );
    if (initialSkillMax) setSelectedMaxOption(initialSkillMax);
  }, [skillMaxValue]);

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
      }}
    >
      <EditTitle title={title} required={false} />

      <HorizontalSpaceBetweenContainer>
        {navigation.state === "loading" ? (
          <div style={{ width: "45%" }}>
            <Skeleton height={36} />
          </div>
        ) : (
          <HorizontalLeftAlignedContainer style={{ flex: 1 }}>
            <Select
              options={skillMinItems}
              onChange={handleMinChange}
              value={selectedMinOption}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  flex: 1,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: colors.LIGHT_GRAY,
                  borderRadius: getBorderRadius("small", "rounded rect"),
                }),
              }}
            />
            <input type="hidden" name={formDataMinName} value={skillMinValue} />
          </HorizontalLeftAlignedContainer>
        )}
        <LineHorizontalSpace />
        <SmallText color={colors.SECONDARY_TEXT}>to</SmallText>
        <LineHorizontalSpace />

        {navigation.state === "loading" ? (
          <div style={{ width: "45%" }}>
            <Skeleton height={36} />
          </div>
        ) : (
          <HorizontalRightAlignedContainer style={{ flex: 1 }}>
            <Select
              options={skillMaxItems}
              onChange={handleMaxChange}
              value={selectedMaxOption}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  flex: 1,
                  fontFamily: "Poppins",
                  fontSize: 14,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: colors.LIGHT_GRAY,
                  borderRadius: getBorderRadius("small", "rounded rect"),
                }),
              }}
            />
            <input type="hidden" name={formDataMaxName} value={skillMaxValue} />
          </HorizontalRightAlignedContainer>
        )}
      </HorizontalSpaceBetweenContainer>
    </div>
  );
};
