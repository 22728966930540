import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import axios from "axios";
import {
  AttendeeCreate,
  AttendeeFull,
  AttendeeUpdate,
  EventFull,
} from "../types";
import { addQueryItem } from "../utils/queryStringUtil";
import { BACKEND_URL } from "../env";

// Find RSVP data for given eventId and userId
const findRSVP = async (eventId: string, userId: string) => {
  const { data } = await axios.get(
    BACKEND_URL + "attendees/event/" + eventId + "/user/" + userId
  );
  return data;
};
export const useRSVP = (eventId: string, userId: string) =>
  useQuery(["rsvp", eventId, userId], () => findRSVP(eventId, userId));

const fetchRSVPsByEventId = async (
  page: number,
  eventId?: string,
  status?: string
) => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  queryParamString = addQueryItem(
    queryParamString,
    "status",
    status ? status : "going"
  );
  const { data } = await axios.get(
    BACKEND_URL + "attendees/event/" + eventId + queryParamString
  );
  return data;
};

export const rsvpsQuery = (eventId?: string, status?: string) => ({
  queryKey: ["attendees", eventId, status],
  queryFn: async ({ pageParam = 1 }) =>
    fetchRSVPsByEventId(pageParam, eventId, status),
  enabled: !!eventId && !!status,
  getNextPageParam: (
    lastPage: AttendeeFull[] | undefined,
    pages: (AttendeeFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useRSVPs = (
  eventId?: string,
  status?: string
): UseInfiniteQueryResult<AttendeeFull[]> =>
  useInfiniteQuery(rsvpsQuery(eventId, status));

// Find all RSVP data for a given userId
const fetchUsersRSVPs = async (
  page: number,
  userId: string,
  dateFilter?: string,
  groupId?: string
) => {
  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  if (dateFilter) {
    queryParamString = addQueryItem(queryParamString, "dateFilter", dateFilter);
  }
  if (groupId) {
    queryParamString = addQueryItem(queryParamString, "groupId", groupId);
  }
  const { data } = await axios.get(
    BACKEND_URL + "attendees/user/" + userId + queryParamString
  );
  return data;
};

export const usersRSVPsQuery = (
  isEnabled: boolean,
  userId: string,
  dateFilter?: string,
  groupId?: string
) => ({
  queryKey: ["rsvps", userId, dateFilter, groupId],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchUsersRSVPs(pageParam, userId, dateFilter, groupId);
  },
  enabled: isEnabled,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: EventFull[] | undefined,
    pages: (EventFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useUsersRSVPs = (
  isEnabled: boolean,
  userId: string,
  dateFilter?: string,
  groupId?: string
): UseInfiniteQueryResult<EventFull[]> =>
  useInfiniteQuery(usersRSVPsQuery(isEnabled, userId, dateFilter, groupId));

// const fetchUsersConflicts = async (userId: string, eventId: string) => {
//   const { data } = await axios.get(
//     BACKEND_URL + "attendees/conflicts/" + userId + "/" + eventId
//   );
//   return data;
// };

// export const useUsersConflicts = (userId: string, eventId: string) =>
//   useQuery(["conflicts", eventId, userId], () =>
//     fetchUsersConflicts(userId, eventId)
//   );

const fetchEventsConflicts = async (eventId: string) => {
  const { data } = await axios.get(
    BACKEND_URL + "attendees/conflicts/" + eventId
  );
  return data;
};

export const eventsConflictsQuery = (isEnabled: boolean, eventId: string) => ({
  queryKey: ["conflicts", eventId],
  queryFn: async () => {
    return fetchEventsConflicts(eventId);
  },
  enabled: isEnabled,
});

export const useEventsConflicts = (
  isEnabled: boolean,
  eventId: string
): UseQueryResult<AttendeeFull[]> =>
  useQuery(eventsConflictsQuery(isEnabled, eventId));

// Submit a new RSVP
export const addRSVP = async (newAttendee: AttendeeCreate) => {
  await axios.post(BACKEND_URL + "attendees/", newAttendee);
};

// Update an existing RSVP
export const updateRSVP = async (attendeeUpdate: AttendeeUpdate) => {
  await axios.patch(BACKEND_URL + "attendees/" + attendeeUpdate.attendeeId, {
    status: attendeeUpdate.status,
  });
};

export const setPaidFlag = async (id: string, paidFlag: boolean) => {
  const paidData = {
    paidFlag: paidFlag,
  };
  await axios.patch(BACKEND_URL + "attendees/setpaid/" + id, paidData);
};

export const setAttendedFlag = async (id: string, attendedFlag: boolean) => {
  const attendedData = {
    attendedFlag: attendedFlag,
  };
  await axios.patch(BACKEND_URL + "attendees/setattended/" + id, attendedData);
};

// manually set a user's attendance status (for owner, organizer, host)
export const setAttendeeStatus = async (attendeeUpdate: AttendeeUpdate) => {
  const attendeeStatus = {
    status: attendeeUpdate.status,
  };
  await axios.patch(
    BACKEND_URL + "attendees/setstatus/" + attendeeUpdate.attendeeId,
    attendeeStatus
  );
};

// delete an RSVP by its id
// I am not sure this one gets used since we just change the status to notgoing, but for completeness
export const deleteRSVP = async (id: string) => {
  await axios.delete(BACKEND_URL + "attendees/" + id);
  return;
};
