import { userContextType } from "../../UserContext";
import { defaultLatitude, defaultLongitude, defaultDisplayAddress } from "../../constants/defaultLocation";
import { LIGHT_GRAY } from "../../styles/colors";
import { CourtType, EventTime, EventTypeFilter } from "../../types/enums";
import { LocationFilterData } from "../../types/locationTypes";
import { getBorderRadius } from "../../utils";

export const DEFAULT_TIME = EventTime.Upcoming;
export const DEFAULT_GROUP = {
  groupId: "Any",
  groupName: "Any Group",
};

export const getDefaultLocationData = (
    userInfo: userContextType
): LocationFilterData => {
    return {
        lat: userInfo && userInfo.latitude ? userInfo.latitude : defaultLatitude,
        long: userInfo && userInfo.longitude ? userInfo.longitude : defaultLongitude,
        mileage: 60,
        addressText: userInfo && userInfo.address ? userInfo.address : defaultDisplayAddress,
        displayAddress:
        userInfo && userInfo.displayAddress ? userInfo.displayAddress : defaultDisplayAddress,
    };
};

export const DEFAULT_SKILL_VALUE = 0;
export const DEFAULT_COURT_TYPE = CourtType.Any;
export const DEFAULT_EVENT_TYPE = EventTypeFilter.Any;

export const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: getBorderRadius("large", "rounded rect"),
      borderColor: LIGHT_GRAY,
      boxShadow: "1px 1px 5px rgb(0 0 0 / 0.1)"
    },
    overlay: {
      zIndex: 3000,
    },
  };