import React, { useEffect, useRef, useState } from "react";
import { VenueFull } from "../../../types";
import { HorizontalLeftAlignedContainer } from "../../../styles/container";
import { PRIMARY_BRAND } from "../../../styles/colors";
import { blankVenueImageURL } from "../../../constants/defaultImageURLs";
import {
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { Icon } from "../../../styles/icons";
import "./VenueCard.css";
import Skeleton from "react-loading-skeleton";
import { NormalText } from "../../../components/text/NormalText";
import { SecondaryLeftHeader } from "../../../components/text/SecondaryLeftHeader";
import { NormalImage } from "../../../components/NormalImage";
import { useWindowWidth } from "@react-hook/window-size";

type VenueCardProps = {
  venueDetails: VenueFull;
  onClick: () => void;
  addPaddingLeft: boolean;
  addTopPadding: boolean;
  cardSize: number;
  loading?: boolean;
};

export const VenueCard = (props: VenueCardProps) => {
  const {
    venueDetails,
    onClick,
    addPaddingLeft,
    addTopPadding,
    cardSize,
    loading,
  } = props;
  const divRef = useRef<HTMLDivElement>(null);

  let truncatedNickname = venueDetails.nickname;
  if (venueDetails.nickname.length > 15)
    truncatedNickname = venueDetails.nickname.substring(0, 14) + "...";
  const venueImageUrl = venueDetails.imageUri
    ? venueDetails.imageUri
    : blankVenueImageURL;
  return (
    <>
      <button
        className="venu-card"
        style={{
          marginRight: SECTION_HORIZONTAL_SPACING,
          //marginRight: addPaddingLeft
          // ? SECTION_HORIZONTAL_SPACING
          // : 0,
          marginTop: addTopPadding ? SECTION_VERTICAL_SPACING : 0,
          paddingBottom: SECTION_VERTICAL_SPACING,
          width: cardSize,
        }}
        onClick={onClick}
      >
        <div style={{ width: "100%" }} ref={divRef}>
          {/* {loading ? (
            <Skeleton height={cardSize} />
          ) : (
            <img
              src={venueImageUrl}
              alt="This pickleball venue"
              style={{ width: cardSize, height: cardSize, objectFit: "cover" }}
            />
          )} */}
          <NormalImage
            imageUri={venueImageUrl}
            alt="This pickleball venue"
            width={cardSize}
            height={cardSize}
            objectFit="cover"
          />
          <div style={{ height: LINE_VERTICAL_SPACING }} />
          <HorizontalLeftAlignedContainer>
            <SecondaryLeftHeader>{truncatedNickname}</SecondaryLeftHeader>
            {props.venueDetails.verificationStatus && (
              <div style={{ marginLeft: LINE_HORIZONTAL_SPACING }}>
                <Icon name="shield-checkmark" color={PRIMARY_BRAND} size={16} />
              </div>
            )}
          </HorizontalLeftAlignedContainer>

          <div style={{ height: LINE_VERTICAL_SPACING }} />
          {loading ? (
            <Skeleton height={18} />
          ) : (
            <HorizontalLeftAlignedContainer>
              <NormalText>{props.venueDetails.displayAddress}</NormalText>
              {/* <p className="address">{props.venueDetails.displayAddress}</p> */}
            </HorizontalLeftAlignedContainer>
          )}
        </div>
      </button>
    </>
  );
};
