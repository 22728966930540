import React, { Dispatch, SetStateAction, useState } from "react";
import { CourtType } from "../../types/enums";
import Modal from "react-modal";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2 } from "../../styles/text";
import { FilterToggleButton } from "../../components/buttons/FilterToggleButton";
import { enumValues } from "../../types/utils";
import { FilterButton } from "../../components/buttons/FilterButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { SecondaryButton } from "../../components/buttons/SecondaryButton";
import { TextButton } from "../../components/buttons/TextButton";
import { COLORS } from "../../styles/colors";
import {
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { customStyles } from "./constants";

type CourtTypeFilterButtonProps = {
  value: CourtType;
  setValue: Dispatch<SetStateAction<CourtType>>;
  defaultValue: CourtType;
  disabled?: boolean;
};

export const CourtTypeFilterButton = (props: CourtTypeFilterButtonProps) => {
  const { value, setValue, defaultValue, disabled } = props;
  const colors = COLORS["light"];

  const [localValue, setLocalValue] = useState(defaultValue);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     borderRadius: getBorderRadius("medium", "rounded rect"),
  //   },
  //   overlay: {
  //     zIndex: 1000,
  //   },
  // };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleSelect = () => {
    setValue(localValue);
    closeModal();
    // clear location?
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleReset = () => {
    setLocalValue(defaultValue);
  };

  return (
    <>
      <FilterButton
        title={value === CourtType.Any ? "Court Type" : value}
        onClick={handleClick}
        active={value !== CourtType.Any}
        iconName="partly-sunny-outline"
        disabled={disabled}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <HorizontalSpaceBetweenContainer style={{ marginBottom: 24 }}>
            <H2>Court Type</H2>
            <TextButton
              title="Reset"
              onClick={() => handleReset()}
              color={colors.PRIMARY_BRAND}
            />
          </HorizontalSpaceBetweenContainer>

          <HorizontalLeftAlignedContainer
            style={{ width: 320, paddingBottom: SECTION_VERTICAL_SPACING }}
          >
            {enumValues(CourtType).map((buttonValue, index) => {
              return (
                <FilterToggleButton
                  key={index}
                  title={buttonValue}
                  onClick={() => setLocalValue(buttonValue)}
                  selected={localValue === buttonValue}
                />
              );
            })}
          </HorizontalLeftAlignedContainer>
          <HorizontalContainer>
            <PrimaryButton
              flex={1}
              onClick={handleSelect}
              type="button"
              title="Apply"
            />
            {/* <SecondaryButton flex={1} onClick={handleReset} title="Reset" />
            <SecondaryButton flex={1} onClick={handleCancel} title="Cancel" /> */}
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
