/* data necessary to create an Attendee */

import { PeopleData } from "./commonTypes";

export type AttendeeStatusType = "going" | "waitlist" | "notgoing";

/* all but eventId and userId could be gotten in DB and is maintained there, 
    but is sent from here for convenience because we have it. */
export interface AttendeeCreate {
  userId: string;
  eventId: string;
}
/*
 * AttendeeFull should match ReturnableAttendee from the backend
 */
export interface AttendeeFull extends AttendeeCreate {
  /* These are created on the backend and/or managed by separate queries */
  id: string;
  eventName: string;
  eventDate: number;
  eventDuration: number;
  eventImageUri: string;
  eventEmoji: string;
  groupName: string;
  groupId: string;
  venueNickname: string;
  status: AttendeeStatusType;
  signupDate: number;
  paidFlag: boolean;
  attendedFlag: boolean;
  firstName: string;
  lastName: string;
  imageUri: string;
  timeZone?: string;
}

export type AttendeeUpdate = {
  /* for updating rsvp status mutations */
  attendeeId: string;
  status: AttendeeStatusType;
};

export const defaultAttendee: AttendeeFull = {
  id: "",
  eventName: "",
  eventDate: new Date().getTime(),
  eventDuration: 0,
  eventImageUri: "",
  eventEmoji: "",
  groupName: "",
  groupId: "",
  venueNickname: "",
  status: "going",
  signupDate: new Date().getTime(),
  paidFlag: false,
  attendedFlag: false,
  firstName: "",
  lastName: "",
  imageUri: "",
  userId: "",
  eventId: "",
  timeZone: "America/New_York",
};
