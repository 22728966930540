import { resizeFile } from "../components/image/ImagePicker";

// functions to extract form data in the proper type
export const fdGetString = (actionData: FormData, name: string) => {
    const result = actionData.get(name) as string;
    return result===null ? "" : result;
}

export const fdGetNumber = (actionData: FormData, name: string) => {
    return parseFloat(actionData.get(name) as string);
}
export const fdGetInteger = (actionData: FormData, name: string) => {
    const result = parseInt(actionData.get(name) as string);
    return isNaN(result) ? 0 : result;
}
export const fdGetChecked = (actionData: FormData, name: string) => {
    return (actionData.get(name) as string) === "on";
}

export const fdGetBoolean = (actionData: FormData, name: string) => {
    return (actionData.get(name) as string) === "true";
}

export const fdGetFile = (actionData: FormData, name: string) => {
    return actionData.get(name) as File
}

export const fdGetByEnum = <T,>(actionData: FormData, name: string) => {
    return actionData.get(name) as T;
}

export const fdGetImage = async (actionData: FormData, name: string) => {
    const groupImage = actionData.get(name) as File;
    // if it's a pdf just return it
    if (groupImage.type === "application/pdf")
      return groupImage;

    // otherwise we must resize the image first
    let resizedImage: File | null = null;
    if (groupImage && groupImage.name && groupImage.size > 0)
      resizedImage = (await resizeFile(groupImage)) as File;
    return resizedImage;
}

// functions to return undefined if something hasn't changed
export const ifDifferentString = (updated: string, original: string) => {
  if (updated === original) return undefined;
  else return updated;
};

export const ifDifferentNumber = (updated: number, original: number) => {
  if (Math.abs(updated-original)<0.0000001) return undefined;
  else return updated;
};

export const ifDifferentBoolean = (updated: boolean, original: boolean) => {
  if (updated === original) return undefined;
  else return updated;
};

export const ifDifferentEnum = <T,>(updated: T, original: T) => {
  if (updated === original) return undefined;
  else return updated;
};


