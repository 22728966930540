import React from "react";
import { PeopleData } from "../../types";
import { PickerButton } from "../../components/buttons/PickerButton";

type PeoplePickerCardProps = {
  person: PeopleData;
  onPress: () => void;
};
export const PeoplePickerCard = (props: PeoplePickerCardProps) => {
  return (
    <PickerButton
      onClick={props.onPress}
      title={`${props.person.firstName} ${props.person.lastName}`}
    />
  );
};
