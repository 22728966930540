import React from "react";
import {
  redirect,
  useActionData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { CognitoUser } from "@aws-amplify/auth";
import { ForceNewPasswordScreenLayout } from "./ForceNewPasswordScreenLayout";
import { goToLogin } from "../../utils/navUtil";
import { useEffect } from "react";
import { logSentryError } from "../../utils/sentryUtil";

/* This page may not be necessary anymore.  
I cannot get there via an amplify reset, and am therefore
not sure there is a workflow that uses this path.
Instead, a confirmation code is generated and the NewPasswordScreen
is used. */
export type ForceNewPasswordErrors = {
  ok?: boolean;
  password?: string;
  password2?: string;
  other?: string;
};

const validateForceNewPasswordData = (
  password: string,
  password2: string,
  user: CognitoUser
) => {
  const err: ForceNewPasswordErrors = {};
  if (!password) err.password = "Please enter your password!";
  if (!password2) err.password2 = "Please re-type your password!";
  if (password !== password2)
    err.password2 = "Passwords do not match!  Please try again!";
  if (!user) err.other = "Missing user information!";
  return err;
};

export const forceNewPasswordAction = async (
  request: Request,
  queryClient: QueryClient
) => {
  const data = await request.formData();
  const password = fdGetString(data, "password");
  const password2 = fdGetString(data, "password2");
  const userJSON = fdGetString(data, "userJSON");
  const user: CognitoUser = JSON.parse(userJSON);

  const validateResult = validateForceNewPasswordData(
    password,
    password2,
    user
  );
  if (Object.keys(validateResult).length) {
    return { ok: false, ...validateResult } as ForceNewPasswordErrors;
  }

  try {
    await Auth.completeNewPassword(user, password);
    return redirect("/");
  } catch (e: any) {
    if (e.code === "NotAuthorizedException") {
      return {
        ok: false,
        password:
          "Your code has expired, too much time has elapsed.  Use the Forgot Password Link.",
      };
    } else if (e.code === "InvalidPasswordException") {
      return {
        ok: false,
        password:
          "Passwords must be at least 8 characters and contain at least one each of uppercase, lowercase, numeric, and symbolic characters.",
      };
    } else {
      logSentryError("Error setting new password", e, {
        tags: {
          userId: "none",
          screen: "ForceNewPasswordScreen",
          function: "forceNewPasswordAction",
        },
        level: "error",
      });
      return { ok: false, other: "Error" + e.code };
    }
  }
};

export const forceNewPasswordLoader = async (currentUserId: string) => {
  if (!!currentUserId) return redirect("/events");
  return { ok: true };
};

export const ForceNewPasswordScreen = () => {
  const navigate = useNavigate();
  const errors = useActionData() as ForceNewPasswordErrors;
  const { state } = useLocation();

  const user: CognitoUser = state.user;

  if (!user) {
    goToLogin(navigate);
  }
  const userJSON = JSON.stringify(user);

  useEffect(() => {
    document.title = "New Password Required -- Picklers";
  }, []);

  return <ForceNewPasswordScreenLayout errors={errors} userJSON={userJSON} />;
};
