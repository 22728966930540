import React, { useContext } from "react";
import { userContext } from "../../../UserContext";
import { useEventThreads } from "../../../hooks/queryMessages";
import { EventThreadsSection } from "../Sections/EventThreadsSection";
import { HEADER_VERTICAL_SPACING } from "../../../styles/spacing";
import { useWindowHeight } from "@react-hook/window-size";
import { useNavigation } from "react-router-dom";
import { ThreadFull, defaultThreadDetails } from "../../../types";
import { UseQueryResult } from "react-query";

export type EventActivityTabProps = {
  eventThreadsResult: UseQueryResult<ThreadFull[]>;
};
export const EventActivityTab = (props: EventActivityTabProps) => {
  const { eventThreadsResult } = props;
  const navigation = useNavigation();

  const { data, isSuccess } = eventThreadsResult;

  return (
    <>
      <EventThreadsSection
        eventThreadsData={
          navigation.state !== "loading"
            ? data
            : [
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
                defaultThreadDetails,
              ]
        }
        eventThreadsDataIsSuccess={isSuccess}
      />
    </>
  );
};
