import React from "react";
import { EventFull } from "../../../types";
import { HorizontalLeftAlignedContainer } from "../../../styles/container";
import getSkillRange from "../../../utils/skillUtil";
import { useNavigate, useNavigation } from "react-router-dom";
import { goToGroupDetails, goToVenueDetails } from "../../../utils/navUtil";
import { GeneralAttribute } from "../../../components/attributes/GeneralAttribute";
import { ITEM_HORIZONTAL_SPACING } from "../../../styles/spacing";
import Skeleton from "react-loading-skeleton";
import { ClickableAttribute } from "../../../components/attributes/ClickableAttribute";

type EventDetailsInfoProps = {
  eventDetails: EventFull;
};
export const EventDetailsInfo = (props: EventDetailsInfoProps) => {
  const { eventDetails } = props;
  const navigate = useNavigate();
  const navigation = useNavigation();

  const skillString = getSkillRange(
    eventDetails.minSkillLevel,
    eventDetails.maxSkillLevel
  );
  return (
    <>
      {/* Event Attributes */}
      {navigation.state === "loading" ? (
        <div
          style={{
            marginLeft: ITEM_HORIZONTAL_SPACING,
            marginRight: ITEM_HORIZONTAL_SPACING,
          }}
        >
          <Skeleton height={24} width="50%" />
        </div>
      ) : (
        <HorizontalLeftAlignedContainer
          style={{
            // marginTop: 16,
            marginLeft: ITEM_HORIZONTAL_SPACING,
            marginRight: ITEM_HORIZONTAL_SPACING,
            flexWrap: "wrap",
          }}
        >
          <ClickableAttribute
            text={eventDetails.venueNickname}
            iconName={"location"}
            onClick={() => goToVenueDetails(navigate, eventDetails.venueId)}
          />
          <p className="dot-separator">·</p>

          <GeneralAttribute text={skillString} iconName="flash" />

          <p className="dot-separator">·</p>

          <GeneralAttribute
            text={eventDetails.eventType}
            iconName="apps-outline"
          />

          <p className="dot-separator">·</p>

          <ClickableAttribute
            text={eventDetails.groupName}
            onClick={() => goToGroupDetails(navigate, eventDetails.groupId)}
            iconName="people"
          />
        </HorizontalLeftAlignedContainer>
      )}
    </>
  );
};
