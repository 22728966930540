export const isImageUri = (uri?: string) => {
  if (uri && (uri.endsWith(".png") || uri.endsWith(".PNG"))) {
    return true;
  }
  return false;
}

export const isImageType = (type: string) => {
  if (type == "image/png" || type === "image/jpeg" || type==="image/gif" || type==="image/x-png" || type==="application/image")
    return true;
  else
    return false;
}