import react from "react";
import { TextRegular } from "../../styles/text";
import { Icon } from "../../styles/icons";
import IonIcon from "@reacticons/ionicons";
import { HorizontalContainer } from "../../styles/container";
import { COLORS } from "../../styles/colors";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { getBorderRadius } from "../../utils";

type InfoOvalProps = {
  title: string;
  iconName: React.ComponentProps<typeof IonIcon>["name"];
};

export const InfoOval = (props: InfoOvalProps) => {
  const colors = COLORS["light"];

  return (
    <HorizontalContainer
      style={{
        borderRadius: getBorderRadius("large", "rounded rect"),
        paddingTop: LINE_HORIZONTAL_SPACING,
        paddingBottom: LINE_HORIZONTAL_SPACING,
        backgroundColor: colors.LIGHT_GRAY,
        width: 100,
      }}
    >
      <Icon name={props.iconName} size={16} />

      <TextRegular style={{ marginLeft: LINE_HORIZONTAL_SPACING, width: 30 }}>
        {props.title}
      </TextRegular>
    </HorizontalContainer>
  );
};
