import React from "react";
import ShowMoreText from "react-show-more-text";
import { TextRegular } from "../../../styles/text";
import "./ExpandableDescription.css";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { NormalTextWithNewlines } from "../../../components/text/NormalTextWithNewlines";
import src from "react-select/dist/declarations/src";

type ExpandableDescriptionProps = {
  text: string;
};

export const ExpandableDescription = (props: ExpandableDescriptionProps) => {
  const navigation = useNavigation();
  return (
    <>
      {navigation.state === "loading" ? (
        <Skeleton height={16} count={3} />
      ) : (
        <ShowMoreText
          /* Default options */
          lines={3}
          more="Show more"
          less="Show less"
          anchorClass="show-more-less-clickable"
          expanded={false}
          truncatedEndingComponent={"... "}
        >
          <NormalTextWithNewlines text={props.text} />
          {/* {props.text} */}
        </ShowMoreText>
      )}
    </>
  );
};
