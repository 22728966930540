import React, { useContext, useEffect } from "react";
import { userContext } from "../../UserContext";
import { redirect, useActionData, useParams } from "react-router-dom";
import { eventDetailsQuery, setEventStatus } from "../../hooks/queryEvents";
import { EventDetailsLayout } from "./EventDetailsLayout";
import { groupDetailsQuery } from "../../hooks/queryGroups";
import { QueryClient, useQuery } from "react-query";
import { addRSVP, updateRSVP } from "../../hooks/queryAttendees";
import {
  AttendeeCreate,
  AttendeeStatusType,
  AttendeeUpdate,
  EventFull,
} from "../../types";
import { fdGetBoolean, fdGetString } from "../../utils/formUtils";
import { ScreenContainer } from "../../features/ScreenContainer";
import { logSentryError } from "../../utils/sentryUtil";

export type EventDetailsParams = {
  eventId: string;
};

export type EventDetailsErrors = {
  generic?: string;
  ok?: boolean;
};

export const eventDetailsLoader = async (
  params: EventDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = eventDetailsQuery(
      !!currentUserId && !!params?.eventId,
      params?.eventId
    );
    const eventDetails: EventFull =
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query));

    const query2 = groupDetailsQuery(
      currentUserId,
      eventDetails ? eventDetails.groupId : ""
    );
    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchQuery(query2));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { ok: true };
};

export const eventDetailsAction = async (
  params: EventDetailsParams,
  request: Request,
  queryClient: QueryClient,
  userId: string
) => {
  const data = await request.formData();

  const action = fdGetString(data, "action");
  const defaultErrorMessage =
    "Error while applying the action '" + action + "' to this event";

  const attendeeId = fdGetString(data, "attendeeId");
  const newStatus = fdGetString(data, "status") as AttendeeStatusType;

  try {
    switch (action) {
      case "rsvp":
        const attendeeCreate: AttendeeCreate = {
          userId: userId,
          eventId: params.eventId,
        };
        await addRSVP(attendeeCreate);
        queryClient.invalidateQueries(["event", params.eventId]);
        queryClient.invalidateQueries(["attendees", params.eventId]);
        queryClient.invalidateQueries(["rsvp", params.eventId, userId]);
        queryClient.invalidateQueries(["rsvps", userId]);
        break;
      case "updatersvp":
        const attendeeUpdate: AttendeeUpdate = {
          attendeeId: attendeeId,
          status: newStatus,
        };
        if (attendeeId) {
          await updateRSVP(attendeeUpdate);
          queryClient.invalidateQueries(["event", params.eventId]);
          queryClient.invalidateQueries(["attendees", params.eventId]);
          queryClient.invalidateQueries(["rsvp", params.eventId, userId]);
          queryClient.invalidateQueries(["rsvps", userId]);
        }
        break;
    }
  } catch (error: any) {
    logSentryError(
      "Error applying the action '" + action + "' to this event",
      error,
      {
        tags: {
          userId: "",
          eventId: params?.eventId,
          screen: "EventDetailsScreen",
          function: "eventDetailsAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }

  return { ok: true };
};

export const eventContextAction = async (
  params: EventDetailsParams,
  request: Request,
  queryClient: QueryClient,
  userId: string
) => {
  const data = await request.formData();
  const contextAction = fdGetString(data, "contextAction");
  const doRecurring = fdGetBoolean(data, "dorecurring");
  const defaultErrorMessage =
    "Error while applying the action '" + contextAction + "' to this event";

  try {
    switch (contextAction) {
      case "cancel":
        await setEventStatus(params.eventId, "canceled", doRecurring);
        queryClient.invalidateQueries(["event", params.eventId]);
        queryClient.invalidateQueries(["events"]);
        queryClient.invalidateQueries(["attendees", params.eventId]);
        queryClient.invalidateQueries(["rsvp", params.eventId, userId]);
        queryClient.invalidateQueries(["rsvps", userId]);
        break;
      case "restore":
        await setEventStatus(params.eventId, "active", doRecurring);
        queryClient.invalidateQueries(["event", params.eventId]);
        queryClient.invalidateQueries(["events"]);
        queryClient.invalidateQueries(["rsvps", userId]);
        break;
      case "delete":
        await setEventStatus(params.eventId, "deleted", doRecurring);
        queryClient.invalidateQueries(["event", params.eventId]);
        queryClient.invalidateQueries(["events"]);
        queryClient.invalidateQueries(["rsvps", userId]);
        return redirect("/events");
    }
  } catch (e) {
    logSentryError(
      "Error applying the action '" + contextAction + "' to this event",
      e,
      {
        tags: {
          userId: "",
          screen: "EventDetailsScreen",
          function: "eventContextAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }

  return { ok: true };
};

export const EventDetailsScreen = () => {
  const currentUserInfo = useContext(userContext);
  const errors = useActionData() as EventDetailsErrors;

  const currentUserId = currentUserInfo.id;
  const params = useParams();
  const eventId = params.eventId;

  const { data: eventDetails, isSuccess: eventIsSuccess } = useQuery(
    eventDetailsQuery(!!currentUserId && !!eventId, eventId)
  );

  const { data: groupDetails } = useQuery(
    groupDetailsQuery(currentUserId, eventDetails ? eventDetails.groupId : "")
  );

  useEffect(() => {
    if (eventIsSuccess && eventDetails) {
      document.title = "Event Details: " + eventDetails.title;
    }
  }, [eventIsSuccess, eventDetails]);

  return (
    <ScreenContainer horizontalPadding={0}>
      {eventDetails && groupDetails && (
        <EventDetailsLayout
          eventDetails={eventDetails}
          groupDetails={groupDetails}
          errors={errors}
        />
      )}
    </ScreenContainer>
  );
};
