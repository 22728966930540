export type MembershipStatusType =
  | "applied"
  | "accepted"
  | "organizer"
  | "owner";

export interface MemberUpdate {
  memberId?: string;
  status: MembershipStatusType;
}
export interface MemberCreate extends MemberUpdate {
  groupId: string;
  userId: string;
}

/*
 * MemberFull should match ReturnableMembership from the backend
 */
export interface MemberFull extends MemberCreate {
  id: string;
  joinedDate: Date;
  firstName: string;
  lastName: string;
  imageUri: string;
}

export const defaultMemberDetails: MemberFull = {
  id: "",
  firstName: "John",
  lastName: "Smith",
  imageUri: "",
  groupId: "",
  userId: "",
  status: "accepted",
  joinedDate: new Date(),
};
