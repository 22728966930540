import React from "react";
import { COLORS } from "../../styles/colors";
import { TextRegular } from "../../styles/text";

type InitialsProfilePicProps = {
  firstName: string;
  lastName: string;
  fontSize: number;
  borderRadius: number;
  borderWidth?: number;
  borderColor?: string;
  size?: number;
};

export const InitialsProfilePic = (props: InitialsProfilePicProps) => {
  const firstInitial = props.firstName.charAt(0);
  const lastInitial = props.lastName.charAt(0);
  const initialsString = firstInitial + lastInitial;

  const colors = COLORS["light"];

  return (
    <div
      style={{
        height: props.size ? props.size : 32,
        width: props.size ? props.size : 32,
        backgroundColor: colors.MEDIUM_GRAY,
        borderRadius: props.borderRadius,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: props.borderWidth ? props.borderWidth : undefined,
        borderColor: props.borderColor ? props.borderColor : undefined,
      }}
    >
      {/* <HorizontalLeftAlignedContainer> */}
      <TextRegular
        style={{
          color: colors.CONTRAST_PRIMARY_TEXT,
          fontSize: props.fontSize,
          fontWeight: 500,
        }}
      >
        {initialsString}
      </TextRegular>
      {/* </HorizontalLeftAlignedContainer> */}
    </div>
  );
};
