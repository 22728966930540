

export const getDisplayAddressFromGooglePlacesData = (address_components: {long_name: string, short_name: string, types: string[]}[]) => {
    let displayAddress = "";
    address_components.forEach((component) => {
        if (component && component.types) {
            if (
                component.types.includes("neighborhood") ||
                component.types.includes("sublocality_level_1") ||
                component.types.includes("locality") ||
                component.types.includes("postal_town") ||
                component.types.includes("administrative_area_level_1") ||
                component.types.includes("administrative_area_level_4")
            ) {
                if (component.short_name) {
                    if (displayAddress==="")
                        displayAddress = component.short_name;
                    else
                        displayAddress = displayAddress + ", " + component.short_name;
                }
            }
        }
    });
    return displayAddress;
}