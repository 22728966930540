import { useContext, useState, useEffect } from "react";
import { useFetcher, useNavigate, useNavigation } from "react-router-dom";
import { userContext } from "../../../UserContext";
import { Banner } from "../../../components/rows/Banner";
import { useRSVP } from "../../../hooks/queryAttendees";
import { useMembership } from "../../../hooks/queryMemberships";
import { EventFull, AttendeeFull } from "../../../types";
import { EventStatus } from "../../../types/enums";
import {
  earlyBirdWindowIsOpen,
  getDateOrTimeAhead,
  isInThePast,
  userIsEarlyBird,
} from "../../../utils";
import { goToGroupDetails } from "../../../utils/navUtil";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { HorizontalRightAlignedContainer } from "../../../styles/container";
import { LineHorizontalSpace } from "../../../components/spacing/LineHorizontalSpace";

type EventDetailsRSVPSectionProps = {
  eventDetails: EventFull;
};

export const EventDetailsRSVPSection = (
  props: EventDetailsRSVPSectionProps
) => {
  const { eventDetails } = props;
  const userInfo = useContext(userContext);
  const currentUserId = userInfo ? userInfo.id : "";
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const navigation = useNavigation();

  //   const { addEventToCalendar, deleteEventFromCalendar } = useCalendar(
  //     userInfo.id
  //   );

  const { data: memberData, isSuccess: mIsSuccess } = useMembership(
    navigation.state !== "loading" &&
      !!props.eventDetails.groupId &&
      !!currentUserId,
    props.eventDetails.groupId,
    currentUserId
  );
  const attendeeResult = useRSVP(props.eventDetails.id, currentUserId);

  const [attendee, setAttendee] = useState<AttendeeFull | null>(null);

  const handleNonMember = () => {
    goToGroupDetails(navigate, eventDetails.groupId);
  };
  const handleRSVP = async (existingAttendee: AttendeeFull | null) => {
    // if we are not already an attendee, do the add
    if (!existingAttendee) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <CustomAlertUI
              title="Confirm Attendance"
              content="Do you want to sign up for this event?"
              yesButton="Yes"
              noButton="Never Mind"
              yesButtonClick={() => {
                fetcher.submit(
                  {
                    action: "rsvp",
                    attendeeId: "",
                    status: "going",
                  },
                  {
                    method: "POST",
                    action: `/event/view/${eventDetails.id}`,
                  }
                );
                onClose();
              }}
              noButtonClick={() => {
                onClose();
              }}
              onClose={onClose}
            />
          );
        },
      });
    }
    // otherwise, it's an update and we need to toggle
    else {
      //   let calendarEventId: string | undefined = "";
      let status = "going";
      let warningMessage = "Do you want to sign up for this event?";
      let warningTitle = "Confirm Attendance";
      if (existingAttendee.status !== "notgoing") {
        warningMessage = "Are you sure you want to cancel your RSVP?";
        warningTitle = "Confirm Cancellation";
        status = "notgoing";
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <CustomAlertUI
              title={warningTitle}
              content={warningMessage}
              yesButton="Yes"
              noButton="Never Mind"
              yesButtonClick={() => {
                fetcher.submit(
                  {
                    action: "updatersvp",
                    attendeeId: existingAttendee.id,
                    status: status,
                  },
                  {
                    method: "POST",
                    action: `/event/view/${eventDetails.id}`,
                  }
                );
                onClose();
              }}
              noButtonClick={() => {
                onClose();
              }}
              onClose={onClose}
            />
          );
        },
      });
    }
  };
  useEffect(() => {
    if (attendeeResult.isSuccess) setAttendee(attendeeResult.data);
  }, [attendeeResult]);

  type RSVPButtonType = {
    attendee: AttendeeFull | null;
    isCanceled: boolean;
    memberExists: boolean;
    membershipIsPending: boolean;
    rsvpOpen: boolean;
    earlyBirdWindowOpen: boolean;
    alreadyRSVPd: boolean;
  };
  const RSVPButton = (rsvpProps: RSVPButtonType) => {
    const rsvpOpenDate = props.eventDetails.rsvpDate
      ? new Date(props.eventDetails.rsvpDate)
      : new Date();
    if (rsvpProps.isCanceled) {
      return (
        <Banner
          title="Event canceled"
          iconName="close-circle-outline"
          type="error"
        />
      );
    } else if (rsvpProps.alreadyRSVPd) {
      // whatever else is going on, we must be allowed to cancel an existing RSVP before the rsvp date
      // this must be before rsvp open time checks or membership checks, as these may have changed after
      // someone has RSVP'd to an event
      return (
        <HorizontalRightAlignedContainer>
          <PrimaryButton
            title="Cancel RSVP"
            onClick={() => handleRSVP(attendee)}
          />
          {/* {!rsvpProps.rsvpOpen && (
            <HorizontalRightAlignedContainer>
              <LineHorizontalSpace />
              <Banner
                title={"RSVP Opens " + getDateOrTimeAhead(rsvpOpenDate)}
                type="neutral"
                iconName="hourglass-outline"
              />
            </HorizontalRightAlignedContainer>
          )} */}
        </HorizontalRightAlignedContainer>
        // if rsvp isn't open yet, user is an early bird, show when it actually opens too
      );
    } else if (rsvpProps.membershipIsPending) {
      return (
        <Banner
          title="Group Membership Pending..."
          iconName="ellipsis-horizontal"
          type="warning"
        />
      );
    } else if (!rsvpProps.memberExists) {
      return (
        <PrimaryButton title="Join Group to RSVP" onClick={handleNonMember} />
      );
    } else if (!rsvpProps.rsvpOpen) {
      if (rsvpProps.earlyBirdWindowOpen) {
        return (
          <PrimaryButton
            title="Early RSVP"
            onClick={() => handleRSVP(attendee)}
          />
        );
      } else {
        return (
          <Banner
            title={"RSVP Not Open Yet"}
            // title={"RSVP Opens " + getDateOrTimeAhead(rsvpOpenDate)}
            // title={"RSVP Opens " + getDateOrTimeAhead(rsvpOpenDate, props.eventDetails.timeZone)}
            type="neutral"
            iconName="hourglass-outline"
          />
        );
      }
    } else {
      return (
        <PrimaryButton title="RSVP" onClick={() => handleRSVP(attendee)} />
      );
    }
  };

  const alreadyRSVPd = () => {
    if (attendee != null && attendee.status && attendee.status !== "notgoing") {
      return true;
    } else {
      return false;
    }
  };

  const isRSVPOpen = () => {
    // if the rsvp open date has past, it's open
    if (isInThePast(new Date(eventDetails.rsvpDate))) {
      return true;
    }
    // otherwise it's not open.
    return false;
  };

  const isEarlyBirdWindowOpen = () => {
    // if this user is on the earlybird list, then it's open
    if (
      userIsEarlyBird(eventDetails, userInfo.id) &&
      earlyBirdWindowIsOpen(eventDetails)
    ) {
      return true;
    }

    // otherwise it's not open.
    return false;
  };
  return (
    <RSVPButton
      attendee={attendee}
      isCanceled={
        eventDetails.status === EventStatus.Canceled ||
        eventDetails.status === EventStatus.Deleted
      }
      memberExists={mIsSuccess && memberData && memberData.id.length > 0}
      membershipIsPending={
        mIsSuccess &&
        memberData &&
        memberData.id.length > 0 &&
        memberData.status === "applied"
      }
      rsvpOpen={isRSVPOpen()}
      earlyBirdWindowOpen={isEarlyBirdWindowOpen()}
      alreadyRSVPd={alreadyRSVPd()}
    />
  );
};
