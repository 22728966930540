import { useInfiniteQuery } from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import { addQueryItem } from "../utils";

const fetchRevisionHistory = async (
  page: number,
  targetType: string,
  targetId: string
) => {
  let queryParamString = "";

  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "20");
  const { data } = await axios.get(
    BACKEND_URL + "editlog/" + targetType + "/" + targetId + queryParamString
  );
  return data;
};
export const useRevisionHistory = (targetType: string, targetId: string) =>
  useInfiniteQuery(
    ["revisionhistory", targetType, targetId],
    ({ pageParam = 1 }) =>
      fetchRevisionHistory(pageParam, targetType, targetId),
    {
      getNextPageParam: (lastPage, pages) => {
        const nextPage = pages.length + 1;
        return nextPage;
      },
      enabled: !!targetType && !!targetId,
    }
  );
