import React, { useEffect, useState } from "react";
import { TitledInput } from "../../../components/inputs/TitledInput";
import { HorizontalLeftAlignedContainer } from "../../../styles/container";
import { VenueFull } from "../../../types";
import { ITEM_VERTICAL_SPACING, SIDE_PADDING } from "../../../styles/spacing";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";

type EditVenueContactDetailsProps = {
  venueDetails: VenueFull;
};

export const EditVenueContactDetailsSection = (
  props: EditVenueContactDetailsProps
) => {
  const { venueDetails } = props;

  const [venueContactPhone, setVenueContactPhone] = useState(
    venueDetails ? venueDetails.contactPhone : ""
  );

  const [venueContactEmail, setVenueContactEmail] = useState(
    venueDetails ? venueDetails.contactEmail : ""
  );

  const [venueContactName, setVenueContactName] = useState(
    venueDetails ? venueDetails.contactName : ""
  );

  const [venueWebsite, setVenueWebsite] = useState(
    venueDetails ? venueDetails.website : ""
  );

  useEffect(() => {
    setVenueContactEmail(venueDetails.contactEmail);
    setVenueContactName(venueDetails.contactName);
    setVenueContactPhone(venueDetails.contactPhone);
    setVenueWebsite(venueDetails.website);
  }, [venueDetails]);

  return (
    <div>
      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueContactName}
          formDataName="venuecontactname"
          title="CONTACT PERSON/ORGANIZATION"
          setText={setVenueContactName}
          placeholderText="Person or organization to contact for more info"
          maxLength={128}
          editable={true}
        />
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueContactPhone}
          formDataName="venuecontactphone"
          title="CONTACT NUMBER"
          setText={setVenueContactPhone}
          placeholderText="(xxx) xxx-xxxx"
          maxLength={32}
          editable={true}
        />
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueContactEmail}
          formDataName="venuecontactemail"
          title="CONTACT EMAIL"
          setText={setVenueContactEmail}
          placeholderText="abc@def.com"
          maxLength={60}
          editable={true}
        />
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer>
        <TitledInput
          value={venueWebsite}
          formDataName="venuecontactwebsite"
          title="WEBSITE"
          setText={setVenueWebsite}
          placeholderText="https://www.yourwebsite.com"
          maxLength={1024}
          editable={true}
        />
      </HorizontalLeftAlignedContainer>
    </div>
  );
};
