import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { InitialsProfilePic } from "../components/image/InitialsProfilePic";
import { ProfileImage } from "../components/image/ProfileImage";
import { COLORS } from "../styles/colors";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../styles/spacing";
import { H2, TextRegular } from "../styles/text";
import { PeopleData } from "../types";
import { getBorderRadius } from "../utils";
import { SecondaryLeftHeader } from "../components/text/SecondaryLeftHeader";
import { ItemVerticalSpace } from "../components/spacing/ItemVerticalSpace";

type PeoplePicArrayProps = {
  title: string;
  flex: number;
  peopleInfo: PeopleData[];
  showCount: boolean;
  showPics: boolean;
  displayLimit: number;
  count: number;
  limit?: number;
};

export const PeoplePicArray = (props: PeoplePicArrayProps) => {
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const thumbnailSize = 32;
  const overlapShift = thumbnailSize / 2.75;
  const borderWidth = 2.25;
  const { title, peopleInfo, showCount, showPics, displayLimit, count, limit } =
    props;
  const notShown = count - displayLimit + 1;
  const peopleNotShownItem: PeopleData = {
    firstName: "+",
    lastName: notShown.toString(),
    imageUri: "",
    userId: "",
  };

  // set up subset of people to show based on our list of people
  const peopleToShow: PeopleData[] = [];
  let index = 0;
  if (showPics) {
    peopleInfo.every((person) => {
      if (index < displayLimit) {
        peopleToShow.push(person);
        index++;
        return true; // keep iterating
      } else {
        return false; // we have enough
      }
    });
    // if there were more beyond that, change the last one to +num
    if (count > displayLimit) {
      peopleToShow[displayLimit - 1] = peopleNotShownItem;
    }
  }

  return (
    <>
      <SecondaryLeftHeader>
        {navigation.state !== "loading" && showCount && limit && limit > 0
          ? title + " (" + count + "/" + limit + ")"
          : title}
      </SecondaryLeftHeader>
      {/* <HorizontalLeftAlignedContainer>
        <H2>
          {navigation.state !== "loading" && showCount && limit && limit > 0
            ? title + " (" + count + "/" + limit + ")"
            : title}
        </H2>
      </HorizontalLeftAlignedContainer> */}
      <ItemVerticalSpace />
      {navigation.state === "loading" ? (
        <Skeleton height={30} />
      ) : (
        <HorizontalLeftAlignedContainer
          style={{
            marginLeft: ITEM_HORIZONTAL_SPACING - 3,
            marginRight: ITEM_HORIZONTAL_SPACING,
          }}
        >
          {peopleToShow.length <= 0 && (
            <div
              style={{
                marginLeft: -overlapShift,
                paddingTop: 6,
                paddingBottom: 6,
              }}
            >
              <TextRegular color={colors.SECONDARY_TEXT}>None yet</TextRegular>
            </div>
          )}
          {peopleToShow.map((person, index) => (
            <div
              key={index}
              style={{
                marginLeft: -overlapShift - (index === 0 ? borderWidth : 0),
                zIndex: index,
              }}
            >
              <SizedContainer
                width={thumbnailSize}
                height={thumbnailSize}
                style={{ opacity: 1.0 }}
              >
                {person.imageUri ? (
                  <ProfileImage
                    imgSrc={person.imageUri}
                    borderRadius={getBorderRadius("small", "circle")}
                    width={32}
                    height={32}
                  />
                ) : (
                  <InitialsProfilePic
                    firstName={person.firstName}
                    lastName={person.lastName}
                    fontSize={12}
                    borderWidth={borderWidth}
                    borderColor={colors.BACKGROUND}
                    borderRadius={getBorderRadius(
                      "extra large",
                      "rounded rect"
                    )}
                  />
                )}
              </SizedContainer>
            </div>
          ))}
        </HorizontalLeftAlignedContainer>
      )}
    </>
  );
};
