import React, { Dispatch, SetStateAction, useContext } from "react";
import { MessageFull } from "../../../types";
import { COLORS } from "../../../styles/colors";
import { extractPDFFilenameFromURL, isPDFUri } from "../../../utils/pdfUtil";
import { getBorderRadius, getDateOrTimeAgo } from "../../../utils";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../../styles/container";
import { InitialsProfilePic } from "../../../components/image/InitialsProfilePic";
import {
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import { TextRegular, TextSmall } from "../../../styles/text";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import { PdfMessageItem } from "../../../components/image/PdfMessageItem";
import { isImageUri } from "../../../utils/imageSelection";
import { useFetcher, useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Icon } from "../../../styles/icons";

import { PushpinFilled, PushpinOutlined } from "@ant-design/icons";
import "./GroupEventMessageCard.css";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import { userContext } from "../../../UserContext";
import { LineHorizontalSpace } from "../../../components/spacing/LineHorizontalSpace";

type GroupEventMessageCardProps = {
  key: number;
  messageDetails: MessageFull;
  canPin?: boolean;
  canDelete?: boolean;
  setSelectedImage: Dispatch<SetStateAction<string>>;
};

export const GroupEventMessageCard = (props: GroupEventMessageCardProps) => {
  const { messageDetails, canPin, canDelete, setSelectedImage } = props;
  const colors = COLORS["light"];
  // const [selectedImage, setSelectedImage] = useState("");
  // const [showImageViewer, setShowImageViewer] = useState(false);
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const userInfo = useContext(userContext);

  const pdfFilename = extractPDFFilenameFromURL(messageDetails.messageImageUri);
  const borderRadius = getBorderRadius("large", "rounded rect");

  const handlePin = (message: MessageFull, pinned: boolean) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={pinned ? "Pin this message?" : "Un-pin this message?"}
            content={
              pinned
                ? "This message will be pinned to this Group's Bulletin Board."
                : "This message will be removed from this Group's Bulletin Board."
            }
            yesButton="Yes"
            noButton="No"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  messageid: message.id,
                  pinned: pinned.toString(),
                },
                {
                  method: "POST",
                  action: "update",
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  const handleDeleteMessage = (message: MessageFull) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Delete this message?"}
            content={"This cannot be undone."}
            yesButton="Delete"
            noButton="Never Mind"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  messageid: message.id,
                },
                {
                  method: "POST",
                  action: "delete",
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  return (
    <HorizontalLeftAlignedContainer
      className="group-event-message-card"
      style={{
        alignItems: "flex-start",
        // marginTop: ITEM_VERTICAL_SPACING,
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
        paddingTop: 8,
      }}
    >
      {navigation.state === "loading" ? (
        <Skeleton width={32} height={32} circle={true} />
      ) : (
        <SizedContainer width={32} height={32}>
          {messageDetails.imageUri ? (
            <img
              alt="the person sending this message"
              src={messageDetails.imageUri}
              width={32}
              height={32}
              style={{ borderRadius: 30 }}
            />
          ) : (
            <InitialsProfilePic
              firstName={messageDetails.firstName}
              lastName={messageDetails.lastName}
              fontSize={14}
              borderRadius={borderRadius}
            />
          )}
        </SizedContainer>
      )}
      <div style={{ marginLeft: LINE_HORIZONTAL_SPACING, flex: 1 }}>
        {navigation.state === "loading" ? (
          <Skeleton width={80} height={18} />
        ) : (
          <HorizontalSpaceBetweenContainer className="name-section">
            {/* <HorizontalLeftAlignedContainer>
            </HorizontalLeftAlignedContainer> */}
            <HorizontalLeftAlignedContainer className="name-date">
              <TextSmall
                style={{
                  paddingRight: 6,
                }}
                color={colors.SECONDARY_TEXT}
              >
                {messageDetails.firstName}
              </TextSmall>
              <HorizontalLeftAlignedContainer className="message-date">
                <Icon name="ellipse" size={4} color={colors.SECONDARY_TEXT} />
                <TextSmall
                  color={colors.SECONDARY_TEXT}
                  style={{
                    paddingLeft: 6,
                  }}
                >
                  {getDateOrTimeAgo(new Date(messageDetails.timePosted))}
                </TextSmall>
              </HorizontalLeftAlignedContainer>
            </HorizontalLeftAlignedContainer>
            {(canPin || canDelete) && (
              <HorizontalRightAlignedContainer
                className="group-event-message-actions-container"
                style={{
                  marginTop: -32,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: colors.LIGHT_GRAY,
                  borderRadius: getBorderRadius("tiny", "rounded rect"),
                  backgroundColor: colors.WHITE,
                  overflow: "hidden",
                  padding: 4,
                }}
              >
                {canPin && messageDetails.pinned && (
                  <button
                    onClick={() =>
                      handlePin(messageDetails, !messageDetails.pinned)
                    }
                    style={{
                      paddingInline: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <HorizontalContainer className="group-event-message-action-container">
                      <PushpinFilled style={{ color: colors.PRIMARY_RED }} />
                      <LineHorizontalSpace />
                      <TextSmall>Un-Pin</TextSmall>
                    </HorizontalContainer>
                  </button>
                )}
                {canPin && !messageDetails.pinned && (
                  <button
                    onClick={() =>
                      handlePin(messageDetails, !messageDetails.pinned)
                    }
                    style={{
                      paddingInline: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <HorizontalContainer className="group-event-message-action-container">
                      <PushpinOutlined style={{ color: colors.PRIMARY_RED }} />
                      <LineHorizontalSpace />
                      <TextSmall>Pin</TextSmall>
                    </HorizontalContainer>
                  </button>
                )}
                {canPin && canDelete && !messageDetails.pinned && (
                  <LineHorizontalSpace />
                )}
                {canDelete && !messageDetails.pinned && (
                  <button
                    onClick={() => handleDeleteMessage(messageDetails)}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      boxShadow: "none",
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <HorizontalContainer className="group-event-message-action-container">
                      <Icon
                        name="trash-outline"
                        size={16}
                        color={colors.SECONDARY_TEXT}
                      />
                      <LineHorizontalSpace />
                      <TextSmall>Delete</TextSmall>
                    </HorizontalContainer>
                  </button>
                )}
              </HorizontalRightAlignedContainer>
            )}
          </HorizontalSpaceBetweenContainer>
        )}
        <HorizontalLeftAlignedContainer
          style={{
            paddingTop: LINE_VERTICAL_SPACING,
            paddingBottom: LINE_VERTICAL_SPACING,
            paddingLeft: LINE_HORIZONTAL_SPACING,
          }}
        >
          {navigation.state === "loading" ? (
            <Skeleton
              width={300}
              height={100}
              borderRadius={getBorderRadius("large", "rounded rect")}
              style={{
                borderBottomLeftRadius: 0,
              }}
            />
          ) : (
            <div
              style={{
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              {messageDetails.messageText && (
                <TextRegular>{messageDetails.messageText}</TextRegular>
              )}
              {isPDFUri(messageDetails.messageImageUri) && (
                <a
                  href={messageDetails.messageImageUri}
                  target="_blank"
                  style={{ width: "100%" }}
                  rel="noreferrer"
                >
                  <HorizontalLeftAlignedContainer
                    style={{
                      borderStyle: "solid",
                      borderColor: colors.LIGHT_GRAY,
                      borderWidth: 1,
                      borderRadius: getBorderRadius("small", "rounded rect"),
                      overflow: "hidden",
                      marginTop: LINE_VERTICAL_SPACING,
                      paddingTop: 12,
                      paddingBottom: 12,
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                  >
                    <PdfMessageItem
                      pdfFilename={pdfFilename}
                      backgroundColor={colors.PALE_BRAND}
                    />
                  </HorizontalLeftAlignedContainer>
                </a>
              )}

              {messageDetails.messageImageUri &&
                isImageUri(messageDetails.messageImageUri) && (
                  <TouchableOpacity
                    onClick={() => {
                      setSelectedImage(messageDetails.messageImageUri ?? "");
                    }}
                    style={{
                      borderStyle: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      alt="a pic sent with this message"
                      src={messageDetails.messageImageUri}
                      style={{
                        backgroundColor: colors.PALE_GRAY,
                        borderColor: colors.PALE_GRAY,
                        borderWidth: 1,
                        borderRadius: borderRadius,
                        overflow: "hidden",
                        marginTop: LINE_VERTICAL_SPACING,
                        borderBottomLeftRadius: 0,
                        maxWidth: 300,
                        maxHeight: 300,
                      }}
                    />
                  </TouchableOpacity>
                )}
            </div>
          )}
        </HorizontalLeftAlignedContainer>
      </div>
    </HorizontalLeftAlignedContainer>
  );
};
