import { CognitoUser } from "amazon-cognito-identity-js";
import React from "react";
import {
  defaultLatitude,
  defaultLongitude,
  defaultTimeZone,
} from "./constants/defaultLocation";

export type userContextType = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  imageUri: string;
  adminLevel: number;
  address: string;
  displayAddress: string;
  latitude: number;
  longitude: number;
  timeZone?: string;
  user?: CognitoUser | null;
  hasUnread: boolean;
  switchUser?: (
    id: string,
    username: string,
    firstName: string,
    lastName: string,
    imageUri: string,
    adminLevel: number,
    address: string,
    displayAddress: string,
    latitude: number,
    longitude: number,
    timeZone: string,
    user: CognitoUser | null,
    hasUnread: boolean
  ) => void;
};

const userContext = React.createContext<userContextType>({
  id: "",
  username: "",
  firstName: "",
  lastName: "",
  imageUri: "",
  adminLevel: 0,
  address: "",
  displayAddress: "",
  latitude: defaultLatitude,
  longitude: defaultLongitude,
  timeZone: defaultTimeZone,
  hasUnread: false,
});

export { userContext };
