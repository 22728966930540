import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { LocationFilterData } from "../../types/locationTypes";
import Modal from "react-modal";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { GP_API_KEY } from "../../env";
import { getStaticMapUri } from "../../utils/mapUtils";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2, TextRegular } from "../../styles/text";
import { FilterButton } from "../../components/buttons/FilterButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { COLORS } from "../../styles/colors";
import {
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { TitledCheckbox } from "../../components/inputs/TitledCheckbox";
import { TextButton } from "../../components/buttons/TextButton";
import { getBorderRadius } from "../../utils";
import { customStyles } from "./constants";

type LocationFilterButtonProps = {
  latitudeFilter: number;
  setLatitudeFilter: Dispatch<SetStateAction<number>>;
  longitudeFilter: number;
  setLongitudeFilter: Dispatch<SetStateAction<number>>;
  mileageFilter: number;
  setMileageFilter: Dispatch<SetStateAction<number>>;
  displayAddress: string;
  setDisplayAddress: Dispatch<SetStateAction<string>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  defaultLocation: LocationFilterData;
  disabled?: boolean;
};

const gmapLibraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];

export const LocationFilterButton = (props: LocationFilterButtonProps) => {
  const {
    latitudeFilter,
    longitudeFilter,
    mileageFilter,
    address,
    displayAddress,
    setLatitudeFilter,
    setLongitudeFilter,
    setMileageFilter,
    setAddress,
    setDisplayAddress,
    disabled,
  } = props;
  const [localLocation, setLocalLocation] = useState({
    lat: latitudeFilter,
    long: longitudeFilter,
    mileage: mileageFilter,
    addressText: address,
    displayAddress: displayAddress,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [setDefaultLocation, setSetDefaultLocation] = useState(false);
  const colors = COLORS["light"];
  const inputRef = useRef<HTMLInputElement>(null);
  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     borderRadius: getBorderRadius("medium", "rounded rect"),
  //   },
  //   overlay: {
  //     zIndex: 1000,
  //   },
  // };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleSelect = () => {
    if (setDefaultLocation) {
      // TODO: implement setDefaultLocation
    }
    setLatitudeFilter(localLocation.lat);
    setLongitudeFilter(localLocation.long);
    setMileageFilter(localLocation.mileage);
    setAddress(localLocation.addressText);
    setDisplayAddress(localLocation.displayAddress);
    closeModal();
    // clear location?
  };

  const [staticMapUri, setStaticMapUri] = useState("");
  const { isLoaded: gmapsLoaded } = useJsApiLoader({
    googleMapsApiKey: GP_API_KEY ? GP_API_KEY : "",
    libraries: gmapLibraries,
  });
  const [autoCompleteItem, setAutCompleteItem] =
    useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    setStaticMapUri(
      getStaticMapUri({
        address: localLocation.addressText,
        displayAddress: localLocation.displayAddress,
        lat: localLocation.lat,
        lng: localLocation.long,
      })
    );
    return;
  }, [localLocation]);

  const onAutoCompleteLoad = (autocomplete: any) => {
    setAutCompleteItem(autocomplete);
  };

  const handleReset = () => {
    setLocalLocation({
      lat: latitudeFilter,
      long: longitudeFilter,
      mileage: mileageFilter,
      addressText: address,
      displayAddress: displayAddress,
    });
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const onMileagechanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMileage = parseFloat(e.target.value);
    if (!isNaN(newMileage))
      setLocalLocation({ ...localLocation, mileage: newMileage });
  };

  const onPlaceChanged = () => {
    if (autoCompleteItem !== null) {
      const placesResult = autoCompleteItem.getPlace();
      const newLocation = localLocation;
      newLocation.addressText = placesResult.formatted_address
        ? placesResult.formatted_address
        : "";
      newLocation.displayAddress = placesResult.formatted_address
        ? placesResult.formatted_address
        : "";
      newLocation.lat = placesResult.geometry?.location?.lat()
        ? placesResult.geometry?.location?.lat()
        : localLocation.lat;
      newLocation.long = placesResult.geometry?.location?.lng()
        ? placesResult.geometry?.location?.lng()
        : localLocation.long;
      setLocalLocation(newLocation);
      setStaticMapUri(
        getStaticMapUri({
          address: localLocation.addressText,
          displayAddress: localLocation.displayAddress,
          lat: localLocation.lat,
          lng: localLocation.long,
        })
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <>
      <FilterButton
        title={displayAddress}
        onClick={handleClick}
        active={true}
        iconName="location-outline"
        disabled={disabled}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{ marginBottom: ITEM_VERTICAL_SPACING }}
          >
            <H2>Location</H2>
            <HorizontalRightAlignedContainer>
              <TextButton
                title="Reset"
                onClick={() => handleReset()}
                color={colors.PRIMARY_BRAND}
              />
            </HorizontalRightAlignedContainer>
          </HorizontalSpaceBetweenContainer>
          <HorizontalLeftAlignedContainer>
            <TextRegular style={{ marginRight: 8 }}>Find within</TextRegular>
            <input
              type="text"
              name="mileage"
              value={localLocation.mileage}
              onChange={onMileagechanged}
              size={4}
              maxLength={5}
              style={{
                width: 50,
                marginRight: 8,
                paddingRight: LINE_HORIZONTAL_SPACING,
                paddingLeft: LINE_HORIZONTAL_SPACING,
              }}
            />
            <TextRegular>&nbsp;miles of </TextRegular>
          </HorizontalLeftAlignedContainer>
          <HorizontalContainer
            style={{
              marginTop: ITEM_VERTICAL_SPACING,
              marginBottom: 25,
            }}
          >
            {gmapsLoaded ? (
              <Autocomplete
                onLoad={onAutoCompleteLoad}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={localLocation.displayAddress}
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: 275,
                    padding: `8px 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                  }}
                />
              </Autocomplete>
            ) : (
              <TextRegular>Loading Google Maps</TextRegular>
            )}
          </HorizontalContainer>
          {/* <HorizontalLeftAlignedContainer
            style={{ marginBottom: ITEM_VERTICAL_SPACING }}
          >
            <TitledCheckbox
              formDataName="defaultLocation"
              isChecked={false}
              onChange={setSetDefaultLocation}
              text="Save this as my default location"
            />
          </HorizontalLeftAlignedContainer> */}
          <HorizontalContainer style={{ marginBottom: ITEM_VERTICAL_SPACING }}>
            <img src={staticMapUri} alt="Map of currently selected location" />
          </HorizontalContainer>
          <HorizontalContainer>
            <PrimaryButton
              flex={1}
              onClick={handleSelect}
              type="button"
              title="Apply"
            />
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
