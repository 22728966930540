import { layout } from "../styles/layout";

export type CardSize = "tiny" | "small" | "medium" | "large" | "extra large"
export const getCardSize = (cardSize: CardSize): number => {
    if (cardSize==="tiny") return 28
    else if (cardSize==="small") return 36
    else if (cardSize==="medium") return  Math.min(64, layout.window.height / 12);
    else if (cardSize==="large") return 300
    else return 400
}

export type CardShape = "circle" | "rounded rect"
export const getBorderRadius = (cardSize: CardSize, cardShape: CardShape) => {
    if (cardShape==="circle") return 200
    else if (cardSize==="extra large") return 24
    else if (cardSize==="large") return 20
    else if (cardSize==="medium") return 12
    else if (cardSize==="small") return 8
    else if (cardSize==="tiny") return 4
    return 0
}
