import { useContext, useEffect, useRef, useState } from "react";
import TouchableOpacity from "../general/TouchableOpacity";
import { pdfPlaceholder } from "../../constants/defaultImageURLs";
import { COLORS } from "../../styles/colors";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import { Icon } from "../../styles/icons";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { getCardSize } from "../../utils";
import { Form, useNavigation } from "react-router-dom";
import { isPdfType } from "../../utils/pdfUtil";
import { isImageType } from "../../utils/imageSelection";
import { userContext } from "../../UserContext";
import "./MessageSendBar.css";
import Skeleton from "react-loading-skeleton";
import { PrimaryButton } from "../buttons/PrimaryButton";

type MessageSendBarProps<T> = {
  target: string;
  messageType: string;
  destinationId?: string;
  canSendMessage: boolean;
  recipients?: T[]; // could be a list of people or a list of groups
  pinned?: boolean;
  refresh?: boolean;
  title?: string;
};

/*
 * Input of text with an optional image or pdf attached, submitted via form to
 * any action
 */
export const MessageSendBar = <T,>(props: MessageSendBarProps<T>) => {
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;
  const colors = COLORS["light"];
  const size = getCardSize("small");

  const {
    target,
    messageType,
    destinationId,
    canSendMessage,
    recipients,
    pinned,
    refresh,
    title,
  } = props;
  let formTarget = target;
  if (destinationId)
    formTarget = formTarget + messageType + "/" + destinationId;
  if (title) formTarget = formTarget + "?title=" + title;
  const navigation = useNavigation();
  const [messageImage, setMessageImage] = useState("");
  const [imageType, setImageType] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [disabled, setDisabled] = useState(false);
  const pickerRef = useRef<HTMLInputElement>(null);
  let placeholderMessage = "Type a message";
  if (messageType === "event" || messageType === "group")
    placeholderMessage = "Add a comment";
  if (messageType === "group" && !canSendMessage)
    placeholderMessage = "To add a comment, you must first join the group!";

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageBody(event.target.value);
  };

  const handleSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileUploaded = "";
    if (event.target.files && event.target.files[0]) {
      fileUploaded = URL.createObjectURL(event.target.files[0]);
      setMessageImage(fileUploaded);
      setImageType(event.target.files[0].type);
    }
  };

  const clearSelection = () => {
    setMessageImage("");
    if (pickerRef && pickerRef.current) {
      pickerRef.current.value = "";
      pickerRef.current.files = null;
    }
  };

  const pickImage = () => {
    if (pickerRef && pickerRef.current) pickerRef.current.click();
  };

  const pickDocument = () => {
    if (pickerRef && pickerRef.current) pickerRef.current.click();
  };

  useEffect(() => {
    if (!canSendMessage) {
      setDisabled(true);
    } else if (
      messageType === "dm" &&
      !destinationId &&
      (!recipients || recipients.length <= 1)
    ) {
      // this is a new dm thread, must have a recipient other than the user
      setDisabled(true);
    } else if (
      messageType === "groups" &&
      recipients &&
      recipients.length <= 0
    ) {
      setDisabled(true);
    } else if (!messageBody && !messageImage) {
      // recipients are fine, but we must have something to send
      setDisabled(true);
    } else {
      // ready to send message
      setDisabled(false);
    }
  }, [
    messageType,
    destinationId,
    canSendMessage,
    recipients,
    messageBody,
    messageImage,
  ]);
  useEffect(() => {
    if (refresh && pickerRef && pickerRef.current) {
      pickerRef.current.value = "";
      pickerRef.current.files = null;
    }
  }, [refresh]);

  useEffect(() => {
    // the form has been submitted or we are leaving this page, so clear the message
    setMessageBody("");
    setMessageImage("");
    setImageType("");
  }, [navigation.state]);

  return (
    <Form
      action={formTarget}
      method="POST"
      encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
      style={{
        width: "100%",
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: colors.LIGHT_GRAY,
      }}
    >
      <HorizontalContainer
        style={{
          width: "100%",
        }}
      >
        {!messageImage ? (
          <HorizontalLeftAlignedContainer style={{ marginTop: 6 }}>
            {navigation.state === "loading" ? (
              <Skeleton height={24} width={24} />
            ) : (
              <TouchableOpacity
                onClick={pickImage}
                style={{ backgroundColor: "transparent", borderStyle: "none" }}
              >
                <Icon name="image" color={colors.PRIMARY_BRAND} size={24} />
              </TouchableOpacity>
            )}
            <div style={{ width: 8 }} />
            {navigation.state === "loading" ? (
              <Skeleton height={24} width={24} />
            ) : (
              <TouchableOpacity
                onClick={pickDocument}
                style={{
                  paddingLeft: 8,
                  backgroundColor: "transparent",
                  borderStyle: "none",
                }}
              >
                <Icon name="attach" color={colors.PRIMARY_BRAND} size={24} />
              </TouchableOpacity>
            )}
          </HorizontalLeftAlignedContainer>
        ) : (
          <>
            {navigation.state === "loading" ? (
              <Skeleton height={size} width={2 * size + 8} />
            ) : (
              <TouchableOpacity
                onClick={clearSelection}
                style={{
                  backgroundColor: "transparent",
                  position: "relative",
                  borderWidth: 0,
                  borderStyle: "none",
                  width: size,
                  height: size,
                  paddingInline: 0,
                  paddingBlock: 0,
                  marginTop: 6,
                }}
              >
                <SizedContainer width={size} height={size}>
                  {isPdfType(imageType) && (
                    <img
                      height={size}
                      alt="pdf icon"
                      src={pdfPlaceholder}
                      style={{
                        backgroundColor: colors.WHITE,
                        borderRadius: 0,
                        maxWidth: size,
                        maxHeight: size,
                      }}
                    />
                  )}
                  {isImageType(imageType) && (
                    <img
                      src={messageImage}
                      alt="whatever the user attached"
                      height={size}
                      style={{
                        backgroundColor: colors.WHITE,
                        borderRadius: 0,
                        maxWidth: size,
                        maxHeight: size,
                      }}

                      // style={{
                      //   alignItems: "center",
                      //   justifyContent: "center",
                      //   borderRadius: borderRadius,
                      //   backgroundColor: "white",
                      // }}
                    />
                  )}

                  <div
                    style={{
                      position: "absolute",
                      top: -6,
                      right: -6,
                      backgroundColor: colors.MEDIUM_GRAY,
                      borderRadius: 20,
                      width: 16,
                      height: 16,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon name="close" color={colors.WHITE} />
                  </div>
                </SizedContainer>
              </TouchableOpacity>
            )}
          </>
        )}
        <input
          ref={pickerRef}
          type="file"
          accept="application/pdf, application/image, image/png, image/gif, image/jpeg, image/x-png"
          onChange={handleSelection}
          style={{ display: "none" }}
          name="messageimage"
        />
        {navigation.state === "loading" ? (
          <div
            style={{
              flex: 1,
              marginTop: 4,
              paddingLeft: 12,
              paddingRight: 36,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <Skeleton height={24} />
          </div>
        ) : (
          <input
            className="message-send-bar-input-text"
            type="text"
            name="messagetext"
            value={messageBody}
            placeholder={placeholderMessage}
            onChange={handleMessageChange}
            style={{
              flex: 1,
              marginTop: 4,
              paddingLeft: 12,
              paddingRight: 36,
              paddingTop: 8,
              paddingBottom: 8,
              borderStyle: "none",
              justifyContent: "center",
              outline: "none",
            }}
          />
        )}
        <input type="hidden" name="userid" value={currentUserId} />
        <input type="hidden" name="messagetype" value={messageType} />
        {pinned && (
          <input type="hidden" name="pinned" value={pinned.toString()} />
        )}
        {destinationId && (
          <input type="hidden" name="destinationid" value={destinationId} />
        )}
        {recipients && (
          <input
            type="hidden"
            name="recipients"
            value={JSON.stringify(recipients)}
          />
        )}
        <div style={{ width: LINE_HORIZONTAL_SPACING }} />
        {navigation.state === "loading" ? (
          <Skeleton height={24} width={50} />
        ) : (
          <div style={{ marginTop: LINE_VERTICAL_SPACING }}>
            <PrimaryButton
              title={messageType !== "dm" ? "Post" : "Send"}
              disabled={disabled}
              onClick={() => {}}
            />
          </div>
        )}
      </HorizontalContainer>
    </Form>
  );
};
