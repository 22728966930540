import React, { Dispatch, SetStateAction, useState } from "react";
import { EventTypeFilter } from "../../types/enums";
import Modal from "react-modal";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2 } from "../../styles/text";
import { FilterToggleButton } from "../../components/buttons/FilterToggleButton";
import { FilterButton } from "../../components/buttons/FilterButton";
import { enumValues } from "../../types/utils";
import { COLORS } from "../../styles/colors";
import { TextButton } from "../../components/buttons/TextButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import {
  ITEM_HORIZONTAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { customStyles } from "./constants";

type EventTypeFilterButtonProps = {
  value: EventTypeFilter;
  setValue: Dispatch<SetStateAction<EventTypeFilter>>;
  defaultValue: EventTypeFilter;
  disabled?: boolean;
};

export const EventTypeFilterButton = (props: EventTypeFilterButtonProps) => {
  const { value, setValue, defaultValue, disabled } = props;
  const colors = COLORS["light"];

  const [localValue, setLocalValue] = useState(defaultValue);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     borderRadius: getBorderRadius("medium", "rounded rect"),
  //   },
  //   overlay: {
  //     zIndex: 1000,
  //   },
  // };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleSelect = () => {
    setValue(localValue);
    closeModal();
    // clear location?
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleReset = () => {
    setLocalValue(defaultValue);
  };

  return (
    <>
      <FilterButton
        title={value === EventTypeFilter.Any ? "Event Type" : value}
        onClick={handleClick}
        active={value !== EventTypeFilter.Any}
        iconName="ticket-outline"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <HorizontalSpaceBetweenContainer style={{ marginBottom: 24 }}>
            <H2>Event Type</H2>
            <TextButton
              title="Reset"
              onClick={() => handleReset()}
              color={colors.PRIMARY_BRAND}
            />
          </HorizontalSpaceBetweenContainer>

          <HorizontalLeftAlignedContainer
            style={{
              width: 360,
              marginBottom: SECTION_VERTICAL_SPACING,
              flexWrap: "wrap",
            }}
          >
            {enumValues(EventTypeFilter).map((buttonValue, index) => {
              return (
                <div
                  key={index}
                  style={{
                    paddingBottom: LINE_VERTICAL_SPACING,
                  }}
                >
                  <FilterToggleButton
                    title={buttonValue}
                    onClick={() => setLocalValue(buttonValue)}
                    selected={localValue === buttonValue}
                  />
                </div>
              );
            })}
          </HorizontalLeftAlignedContainer>
          <HorizontalContainer style={{ marginTop: 24 }}>
            <PrimaryButton
              flex={1}
              onClick={handleSelect}
              type="button"
              title="Apply"
            />
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
