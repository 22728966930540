import { GroupsScreenLayout } from "../../screens/Groups/GroupsScreenLayout";
import { TextRegular } from "../../styles/text";
import {
  defaultEventDetails,
  defaultGroupDetails,
  defaultVenueDetails,
} from "../../types";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  attendeesQueryResult,
  eventQueryResult,
  eventsQueryResult,
  groupQueryResult,
  groupsQueryResult,
  membersQueryResult,
  membershipQueryResult,
  messagesQueryResult,
  peopleQueryResult,
  pinnedMessagesQueryResult,
  profileQueryResult,
  threadsQueryResult,
  venueQueryResult,
  venuesQueryResult,
} from "./DummyQueryResults";
import { EventsScreenLayout } from "../../screens/Events/EventsScreenLayout";
import { VenuesScreenLayout } from "../../screens/Venues/VenuesScreenLayout";
import { ProfileScreenLayout } from "../../screens/Profile/ProfileScreenLayout";
import { EditProfileScreenLayout } from "../../screens/ProfileEdit/EditProfileScreenLayout";
import { VenueDetailsLayout } from "../../screens/VenueDetails/VenueDetailsLayout";
import { EditVenueLayout } from "../../screens/VenueEdit/EditVenueLayout";
import { GroupDetailsLayout } from "../../screens/GroupDetails/GroupDetailsLayout";
import { EditGroupFormLayout } from "../../screens/GroupEdit/EditGroupFormLayout";
import { EventDetailsLayout } from "../../screens/EventDetails/EventDetailsLayout";
import { EditEventLayout } from "../../screens/EventEdit/EditEventLayout";
import { MessageHistoryLayout } from "../../screens/MessageHistoryScreen/MessageHistoryLayout";
import { InboxScreenLayout } from "../../screens/Inbox/InboxScreenLayout";
import { ComposeScreenLayout } from "../../screens/ComposeScreen/ComposeScreenLayout";
import { AttendeesScreenLayout } from "../../screens/AttendeeScreen/AttendeesScreenLayout";
import { EventRepeatLayout } from "../../screens/EventRepeat/EventRepeatLayout";
import { ComposeGroupsScreenLayout } from "../../screens/ComposeGroupsScreen/ComposeGroupsScreenLayout";
import { ReportIssueScreenLayout } from "../../screens/ReportIssueScreen/ReportIssueScreenLayout";

type LoadingSectionProps = {
  location: string;
  tabIndex?: number;
};

export const LoadingSection = (props: LoadingSectionProps) => {
  const [selectedImage, setSelectedImage] = useState("");
  const doNothing = () => {};
  const reportRegex = new RegExp("/report");
  const profileRegex = new RegExp("/profile/");
  const editProfileRegex = new RegExp("/profile/edit/");
  const venueRegex = new RegExp("/venue/view/");
  const editVenueRegex = new RegExp("/venues/edit/");
  const createVenueRegex = new RegExp("/venues/create");
  const groupRegex = new RegExp("/group/view/");
  const editGroupRegex = new RegExp("/groups/edit/");
  const createGroupRegex = new RegExp("/groups/create");
  const eventRegex = new RegExp("/event/view/");
  const editEventRegex = new RegExp("/events/edit/");
  const createEventRegex = new RegExp("/events/create");
  const eventsRegex = new RegExp("/events");
  const messageHistoryRegex = new RegExp("/messagehistory/(.+)/");
  const inboxRegex = new RegExp("/inbox");
  const composeRegex = new RegExp("/composemessage/");
  const composeGroupsRegex = new RegExp("/composegroupsmessage");
  const attendeesRegex = new RegExp("/attendees/");
  const eventRepeatRegex = new RegExp("event/repeat");
  const getMessageType = (location: string) => {
    const result = location.match(messageHistoryRegex);
    return result ? result[1] : "dm";
  };
  const image = selectedImage;
  useEffect(() => {
    setSelectedImage(image);
  }, [image]);
  return (
    <>
      {props.location.match(editGroupRegex) ? (
        <EditGroupFormLayout
          groupDetails={defaultGroupDetails}
          membersQueryResult={peopleQueryResult}
          title={"Edit Group"}
        />
      ) : props.location.match(createGroupRegex) ? (
        <EditGroupFormLayout
          groupDetails={defaultGroupDetails}
          membersQueryResult={peopleQueryResult}
          title={"Create Group"}
        />
      ) : props.location.match(groupRegex) ? (
        <GroupDetailsLayout
          groupQueryResult={groupQueryResult}
          membershipQueryResult={membershipQueryResult}
          pinnedMessagesQueryResult={pinnedMessagesQueryResult}
          membersQueryResult={membersQueryResult}
          groupEventsQueryResult={eventsQueryResult}
          applicantsQueryResult={membersQueryResult}
          canViewDetails={true}
        />
      ) : props.location === "/groups" ? (
        <GroupsScreenLayout groupsQueryResult={groupsQueryResult} />
      ) : props.location.match(editEventRegex) ? (
        <EditEventLayout
          eventDetails={defaultEventDetails}
          groupDetails={defaultGroupDetails}
          membersQueryResult={peopleQueryResult}
          title={"Edit Event"}
        />
      ) : props.location.match(createEventRegex) ? (
        <EditEventLayout
          eventDetails={defaultEventDetails}
          groupDetails={defaultGroupDetails}
          membersQueryResult={peopleQueryResult}
          title={"Create Event"}
        />
      ) : props.location.match(eventRegex) ? (
        <EventDetailsLayout
          eventDetails={defaultEventDetails}
          groupDetails={defaultGroupDetails}
        />
      ) : props.location.match(eventsRegex) ? (
        <EventsScreenLayout
          tabIndex={props.tabIndex ?? 0}
          onTabSelect={doNothing}
          discoverEventsQueryResult={eventsQueryResult}
          useUsersRSVPsResult={eventsQueryResult}
          useUsersGroupsResult={groupsQueryResult}
        />
      ) : props.location.match(editVenueRegex) ? (
        <EditVenueLayout
          venueDetails={defaultVenueDetails}
          title={"Edit Venue"}
        />
      ) : props.location.match(createVenueRegex) ? (
        <EditVenueLayout
          venueDetails={defaultVenueDetails}
          title={"Create Venue"}
        />
      ) : props.location.match(venueRegex) ? (
        <VenueDetailsLayout
          venueQueryResult={venueQueryResult}
          eventsQueryResult={eventsQueryResult}
        />
      ) : props.location === "/venues" ? (
        <VenuesScreenLayout venuesQueryResult={venuesQueryResult} />
      ) : props.location.match(editProfileRegex) ? (
        <EditProfileScreenLayout profileQueryResult={profileQueryResult} />
      ) : props.location.match(profileRegex) ? (
        <ProfileScreenLayout
          profileQueryResult={profileQueryResult}
          doLogout={doNothing}
          doChangePassword={doNothing}
          doDeleteAccount={doNothing}
          doSentryTestError={doNothing}
        />
      ) : props.location.match(messageHistoryRegex) ? (
        <MessageHistoryLayout
          messagesQueryResult={messagesQueryResult}
          membershipQueryResult={membershipQueryResult}
          messageType={getMessageType(props.location)}
          destinationId={""}
          threadTitle={""}
          errors={{ ok: true }}
        />
      ) : props.location.match(inboxRegex) ? (
        <InboxScreenLayout
          tabIndex={props.tabIndex ?? 0}
          onTabSelect={doNothing}
          dmThreadsResult={threadsQueryResult}
          groupThreadsResult={threadsQueryResult}
          eventThreadsResult={threadsQueryResult}
        />
      ) : props.location.match(composeRegex) ? (
        <ComposeScreenLayout
          friendsQueryResult={peopleQueryResult}
          messageType="dm"
          destinationId=""
          recipient={{
            userId: "1",
            firstName: "John",
            lastName: "Doe",
            imageUri: "",
          }}
        />
      ) : props.location.match(composeGroupsRegex) ? (
        <ComposeGroupsScreenLayout groupsQueryResult={groupsQueryResult} />
      ) : props.location.match(attendeesRegex) ? (
        <AttendeesScreenLayout
          groupQueryResult={groupQueryResult}
          eventQueryResult={eventQueryResult}
          goingQueryResult={attendeesQueryResult}
          notGoingQueryResult={attendeesQueryResult}
          waitlistQueryResult={attendeesQueryResult}
        />
      ) : props.location.match(eventRepeatRegex) ? (
        <EventRepeatLayout eventDetails={defaultEventDetails} />
      ) : props.location.match(reportRegex) ? (
        <ReportIssueScreenLayout
          errors={{
            reportTopic: undefined,
            reportDetails: undefined,
            ok: true,
          }}
        />
      ) : (
        <TextRegular>Loading {props.location}</TextRegular>
      )}
    </>
  );
};
