import React, { useContext } from "react";
import { InitialsProfilePic } from "../../../components/image/InitialsProfilePic";
import TouchableOpacity from "../../../components/general/TouchableOpacity";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
  SizedContainer,
} from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_SPACE_BETWEEN_PADDING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { H2, TextRegular, TextSmall } from "../../../styles/text";
import { ThreadFull } from "../../../types";
import {
  CardShape,
  createMessageSummary,
  excludeSelfFromDMTitle,
  getBorderRadius,
  getDateOrTimeAgo,
  isOlderThanAWeek,
} from "../../../utils";
import { userContext } from "../../../UserContext";
import { COLORS, DARK_SECONDARY_TEXT } from "../../../styles/colors";
import { Icon } from "../../../styles/icons";
import { ProfileImage } from "../../../components/image/ProfileImage";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
  ThreadLastReadRecord,
  threadLastReadContext,
} from "./ThreadLastReadContext";
import { NewMessageIndicator } from "../../../components/general/NewMessageIndicator";
import {
  blankEventImageURL,
  blankGroupImageURL,
} from "../../../constants/defaultImageURLs";

type ThreadSummaryCardProps = {
  key: number;
  threadDetails: ThreadFull;
  onPress: () => void;
  cardShape: CardShape;
  messageType: string;
};

export const ThreadSummaryCard = (props: ThreadSummaryCardProps) => {
  const userInfo = useContext(userContext);
  const threadLastReadData = useContext(threadLastReadContext);
  const navigation = useNavigation();
  const colors = COLORS["light"];

  const { messageType, threadDetails } = props;
  let modTitle = threadDetails.title;
  if (messageType === "dm") {
    modTitle = excludeSelfFromDMTitle(threadDetails.title, userInfo?.firstName);
  }

  const lastMessageSummary = createMessageSummary(
    threadDetails.firstName,
    threadDetails.messageText,
    75
  );
  let lastReadItem: ThreadLastReadRecord | undefined = undefined;
  switch (props.messageType) {
    case "dm":
      lastReadItem = threadLastReadData?.dmThreadLastRead.find((record) => {
        return record.destinationId == threadDetails.destinationId;
      });
      break;
    case "group":
      lastReadItem = threadLastReadData?.groupThreadLastRead.find((record) => {
        return record.destinationId == threadDetails.destinationId;
      });
      break;
    case "event":
      lastReadItem = threadLastReadData?.eventThreadLastRead.find((record) => {
        return record.destinationId == threadDetails.destinationId;
      });
      break;
  }

  let isNew = false;
  const lastMessageisOld = isOlderThanAWeek(new Date(threadDetails.timePosted));

  if (!lastMessageisOld) {
    if (!lastReadItem) {
      isNew = true;
    } else {
      isNew = lastReadItem.lastReadTime < threadDetails.timePosted;
    }
  }

  return (
    <TouchableOpacity
      onClick={props.onPress}
      style={{
        backgroundColor: "white",
        borderStyle: "none",
        paddingBottom: LINE_VERTICAL_SPACING,
        marginBottom: ITEM_VERTICAL_SPACING,
      }}
    >
      <HorizontalContainer>
        <SizedContainer
          width={44}
          height={44}
          style={{ marginRight: ITEM_SPACE_BETWEEN_PADDING }}
        >
          {navigation.state === "loading" || threadDetails.imageUri ? (
            <ProfileImage
              width={44}
              height={44}
              imgSrc={threadDetails.imageUri}
              borderRadius={
                messageType === "dm"
                  ? 22
                  : getBorderRadius("small", "rounded rect")
              }
            />
          ) : messageType === "group" ? (
            <ProfileImage
              width={44}
              height={44}
              imgSrc={blankGroupImageURL}
              borderRadius={getBorderRadius("small", "rounded rect")}
            />
          ) : messageType === "event" ? (
            <ProfileImage
              width={44}
              height={44}
              imgSrc={blankEventImageURL}
              borderRadius={getBorderRadius("small", "rounded rect")}
            />
          ) : (
            <InitialsProfilePic
              firstName={threadDetails.firstName}
              lastName={threadDetails.lastName}
              fontSize={16}
              borderRadius={
                messageType === "dm"
                  ? 22
                  : getBorderRadius("small", "rounded rect")
              }
              size={44}
            />
          )}
        </SizedContainer>
        <div
          style={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{ flex: 1, paddingBottom: LINE_VERTICAL_SPACING }}
          >
            {navigation.state === "loading" ? (
              <Skeleton height={24} width={200} style={{ marginBottom: 4 }} />
            ) : (
              <H2>{modTitle}</H2>
            )}
            <HorizontalRightAlignedContainer style={{ paddingLeft: 5 }}>
              {navigation.state === "loading" ? (
                <Skeleton height={18} width={100} style={{ marginBottom: 4 }} />
              ) : (
                <TextRegular color={colors.SECONDARY_TEXT}>
                  {getDateOrTimeAgo(new Date(threadDetails.timePosted))}
                  {/* {getDateOrTimeAgo(
                    new Date(threadDetails.timePosted),
                    userInfo.timeZone
                  )} */}
                </TextRegular>
              )}
            </HorizontalRightAlignedContainer>
          </HorizontalSpaceBetweenContainer>
          {navigation.state === "loading" ? (
            <HorizontalLeftAlignedContainer>
              <Skeleton height={18} width={300} />
            </HorizontalLeftAlignedContainer>
          ) : (
            <HorizontalSpaceBetweenContainer style={{ flex: 1 }}>
              <TextRegular
                // numberOfLines={2}
                // ellipsizeMode="tail"
                color={DARK_SECONDARY_TEXT}
                style={{ width: "90%" }}
              >
                {lastMessageSummary}
              </TextRegular>
              {isNew && <NewMessageIndicator />}
            </HorizontalSpaceBetweenContainer>
          )}
        </div>
        {/* <div
          style={{
            position: "absolute",
            left: 8,
          }}
        >
          {isNew && <Icon name="ellipse" color={colors.PRIMARY_BRAND} />}
        </div> */}
      </HorizontalContainer>
    </TouchableOpacity>
  );
};
