import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { redirect, useActionData } from "react-router-dom";

import { CognitoUser } from "@aws-amplify/auth";
import { QueryClient } from "react-query";
import { fdGetString } from "../../utils/formUtils";
import { ChangePasswordScreenLayout } from "./ChangePasswordScreenLayout";
import { logSentryError } from "../../utils/sentryUtil";

export type ChangePasswordErrors = {
  ok?: boolean;
  oldPassword?: string;
  password?: string;
  password2?: string;
  other?: string;
};

const validateChangePasswordErrors = (
  oldPassword: string,
  password: string,
  password2: string,
  user: CognitoUser | undefined | null
) => {
  const err: ChangePasswordErrors = {};
  if (!oldPassword) err.oldPassword = "Please enter your old password!";
  if (!password) err.password = "Please enter your new password!";
  if (!password2) err.password2 = "Please re-type your new password!";
  if (password !== password2)
    err.password2 = "Passwords do not match!  Please try again!";
  if (!user) err.other = "Missing user information!";
  return err;
};

export const changePasswordAction = async (
  request: Request,
  queryClient: QueryClient,
  user: CognitoUser | undefined | null,
  currentUserId: string
) => {
  const data = await request.formData();
  const oldPassword = fdGetString(data, "oldpassword");
  const password = fdGetString(data, "password");
  const password2 = fdGetString(data, "password2");

  const validateResult = validateChangePasswordErrors(
    oldPassword,
    password,
    password2,
    user
  );
  if (Object.keys(validateResult).length) {
    return { ok: false, ...validateResult } as ChangePasswordErrors;
  }

  try {
    await Auth.changePassword(user, oldPassword, password);
    alert("Success!  Your password has been changed!");
    return redirect("/profile");
  } catch (e: any) {
    if (e.code === "InvalidPasswordException") {
      return {
        ok: false,
        password:
          "Passwords must be at least 8 characters and contain at least one each of uppercase, lowercase, numeric, and symbolic characters.",
      };
    } else if (e.code === "NotAuthorizedException") {
      return {
        ok: false,
        oldPassword:
          "Your password does not match our records.  If you don't remember it, log out and use the Forgot Password link.",
      };
    } else if (e.code === "LimitExceededException") {
      return {
        ok: false,
        password: "Too many failed attempts.  Please try again later.",
      };
    } else {
      // Something bad happened.  Alert them and log it.
      logSentryError("Error changing password!", e, {
        tags: {
          userId: currentUserId,
          screen: "ChangePasswordScreen",
          function: "changePasswordAction",
          username: user?.getUsername(),
        },
        level: "error",
      });
      return { ok: false, other: "Error: " + e.code };
    }
  }
};

export const changePasswordLoader = async (currentUserId: string) => {
  if (!currentUserId) return redirect("/");
  return { ok: true };
};

export const ChangePasswordScreen = () => {
  const errors = useActionData() as ChangePasswordErrors;

  useEffect(() => {
    document.title = "Change Password";
  }, []);

  return <ChangePasswordScreenLayout errors={errors} />;
};
