import React, { useEffect, useState } from "react";
import { VenueAccessDropDownItem } from "../../../types/dropdownTypes";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { TitledInput } from "../../../components/inputs/TitledInput";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../../../styles/spacing";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../../components/inputs/TitledDropdown";
import { TitledCheckbox } from "../../../components/inputs/TitledCheckbox";
import { VenueFull } from "../../../types";
import { VenueAccess } from "../../../types/enums";
import { enumValues } from "../../../types/utils";
import { VenueErrors } from "../EditVenueScreen";
import { TextRegular } from "../../../styles/text";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { ItemHorizontalSpace } from "../../../components/spacing/ItemHorizontalSpace";

type EditVenueAttributesProps = {
  venueDetails: VenueFull;
  errors?: VenueErrors;
};

export const EditVenueAttributesSection = (props: EditVenueAttributesProps) => {
  const { venueDetails, errors } = props;

  const [venueOpenHours, setVenueOpenHours] = useState(
    venueDetails ? venueDetails.openHours : ""
  );

  const [venueNumIndoors, setVenueNumIndoors] = useState(
    venueDetails ? venueDetails.numIndoorCourts.toString() : "0"
  );

  const [venueNumOutdoors, setVenueNumOutdoors] = useState(
    venueDetails ? venueDetails.numOutdoorCourts.toString() : "0"
  );

  const [venueParking, setVenueParking] = useState(
    venueDetails ? venueDetails.parking : false
  );

  const [venueRestrooms, setVenueRestrooms] = useState(
    venueDetails ? venueDetails.restrooms : false
  );
  const [venuePermanentNets, setVenuePermanentNets] = useState(
    venueDetails ? venueDetails.permanentNets : false
  );
  const [venuePermanentLines, setVenuePermanentLines] = useState(
    venueDetails ? venueDetails.permanentLines : false
  );
  const [venueLighting, setVenueLighting] = useState(
    venueDetails ? venueDetails.outdoorLighting : false
  );

  // Venue Access
  const [venueAccessValue, setVenueAccessValue] = useState<DropdownValueType>(
    venueDetails ? venueDetails.access : VenueAccess.Public
  );
  const venueAccessItems = enumValues(VenueAccess).map((value) => {
    return { label: value, value: value } as VenueAccessDropDownItem;
  });

  useEffect(() => {
    setVenueOpenHours(venueDetails.openHours);
    setVenueNumIndoors(venueDetails.numIndoorCourts.toString());
    setVenueNumOutdoors(venueDetails.numOutdoorCourts.toString());
    setVenueParking(venueDetails.parking);
    setVenueRestrooms(venueDetails.restrooms);
    setVenuePermanentLines(venueDetails.permanentLines);
    setVenuePermanentNets(venueDetails.permanentNets);
    setVenueLighting(venueDetails.outdoorLighting);
    setVenueAccessValue(venueDetails.access);
  }, [venueDetails]);

  return (
    <div>
      <TitledCheckbox
        // iconName="car-outline"
        formDataName="venueparking"
        isChecked={venueParking}
        onChange={setVenueParking}
        text="Parking Lot"
      />
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <TitledCheckbox
        // emoji="🚻🚽🧻"
        formDataName="venuerestrooms"
        isChecked={venueRestrooms}
        onChange={setVenueRestrooms}
        text="Restrooms"
      />
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <TitledCheckbox
        // iconName="brush-outline"
        formDataName="venuepermanentlines"
        isChecked={venuePermanentLines}
        onChange={setVenuePermanentLines}
        text="Permanent Lines"
      />
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <TitledCheckbox
        // iconName="globe-outline"
        formDataName="venuepermanentnets"
        isChecked={venuePermanentNets}
        onChange={setVenuePermanentNets}
        text="Permanent Nets"
      />
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <TitledCheckbox
        // iconName="bulb-outline"
        formDataName="venueoutdoorlighting"
        isChecked={venueLighting}
        onChange={setVenueLighting}
        text="Dusk Lighting"
      />
      <div style={{ height: ITEM_VERTICAL_SPACING }} />

      <ItemVerticalSpace />

      <HorizontalLeftAlignedContainer
        style={{
          display: "flex",
        }}
      >
        <div style={{ flex: 1 }}>
          <TitledInput
            value={venueNumIndoors}
            formDataName="venuenumindoorcourts"
            title="# OF INDOOR COURTS"
            setText={setVenueNumIndoors}
            placeholderText="0"
            maxLength={3}
            editable={true}
          />
          {errors?.dedicatedCourts && (
            <HorizontalLeftAlignedContainer>
              <TextRegular color="red">{errors.dedicatedCourts}</TextRegular>
            </HorizontalLeftAlignedContainer>
          )}
        </div>
        <ItemHorizontalSpace />
        <div style={{ flex: 1 }}>
          <TitledInput
            value={venueNumOutdoors}
            formDataName="venuenumoutdoorcourts"
            title="# OF OUTDOOR COURTS"
            setText={setVenueNumOutdoors}
            placeholderText="0"
            maxLength={3}
            editable={true}
          />
          {errors?.dedicatedCourts && (
            <TextRegular color="red">{errors.sharedCourts}</TextRegular>
          )}
        </div>
      </HorizontalLeftAlignedContainer>
      <ItemVerticalSpace />
      <HorizontalLeftAlignedContainer
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <TitledInput
            value={venueOpenHours}
            formDataName="venueopenhours"
            title="OPEN HOURS"
            setText={setVenueOpenHours}
            placeholderText="Open hours"
            maxLength={128}
            editable={true}
          />
        </div>
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        <div
          style={{
            flex: 1,
          }}
        >
          <TitledDropdown
            title="VENUE ACCESS"
            value={venueAccessValue}
            formDataName="venueaccessvalue"
            setValue={setVenueAccessValue}
            items={venueAccessItems}
          />
        </div>
      </HorizontalLeftAlignedContainer>
    </div>
  );
};
