import React, { Dispatch, SetStateAction, useState } from "react";
import { EventFull, VenueFull } from "../../types";
import { VenueDetailsImageTitle } from "./Sections/VenueDetailsImageTitle";
import { VenueDetailsInfoSection } from "./Sections/VenueDetailsInfoSection";
import { VenueDetailsActionButtons } from "./Sections/VenueDetailsActionButtons";
import { VenueEventsSection } from "./Sections/VenueEventsSection";
import { UseInfiniteQueryResult, UseQueryResult } from "react-query";
import { StickyActionBar } from "../../components/StickyActionBar";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { VenueDetailsErrors } from "./VenueDetailsScreen";
import { HorizontalContainer } from "../../styles/container";
import { TextRegular } from "../../styles/text";
import { VenueStatusBanner } from "./Components/VenueStatusBanner";
import { ImageOverlay } from "../../components/image/ImageOverlay";

type VenueDetailsLayoutProps = {
  venueQueryResult: UseQueryResult<VenueFull | undefined>;
  eventsQueryResult: UseInfiniteQueryResult<EventFull[] | undefined>;
  errors?: VenueDetailsErrors;
};

export const VenueDetailsLayout = (props: VenueDetailsLayoutProps) => {
  const { venueQueryResult, eventsQueryResult, errors } = props;
  const { data: venueDetails, isSuccess } = venueQueryResult;

  const [overlayImageUri, setOverlayImageUri] = useState("");
  const showImage = (imageUri: string) => {
    setOverlayImageUri(imageUri);
  };

  return (
    <>
      {isSuccess && venueDetails && (
        <>
          <StickyActionBar>
            <VenueDetailsActionButtons venueDetails={venueDetails} />
          </StickyActionBar>
          {errors?.generic && (
            <HorizontalContainer>
              <TextRegular color="red">{errors?.generic}</TextRegular>
            </HorizontalContainer>
          )}
          <VenueStatusBanner venueStatus={venueDetails.status} />

          <SectionVerticalSpace />

          <VenueDetailsImageTitle venueDetails={venueDetails} />

          <SectionVerticalSpace />

          <VenueDetailsInfoSection
            venueDetails={venueDetails}
            onImagePress={showImage}
          />
          <SectionVerticalSpace />

          <VenueEventsSection eventsQueryResult={eventsQueryResult} />
          <ItemVerticalSpace />
        </>
      )}
      <ImageOverlay
        imageUri={overlayImageUri}
        setImageUri={setOverlayImageUri}
      />
    </>
  );
};
