import React from "react";
import { isOlderThanAWeek } from "../../../utils";
import { ThreadFull } from "../../../types";

export type ThreadLastReadRecord = {
  destinationId: string;
  lastReadTime: number;
  clearedTime?: number;
  notifications?: boolean;
};

export type ThreadLastReadContextType = {
  groupThreadLastRead: ThreadLastReadRecord[];
  eventThreadLastRead: ThreadLastReadRecord[];
  dmThreadLastRead: ThreadLastReadRecord[];
  setLastReadData?: (threadLastReadList: ThreadLastReadContextType) => void;
};

export const initialThreadLastReadContext = {
  groupThreadLastRead: [],
  eventThreadLastRead: [],
  dmThreadLastRead: [],
};

const threadLastReadContext = React.createContext<ThreadLastReadContextType>(
  initialThreadLastReadContext
);

export { threadLastReadContext };

export const updateThreadLastReadData = (
  currentUserId: string,
  currentContext: ThreadLastReadContextType,
  messageType: string,
  destinationId: string,
  newLastReadTime: number,
  newClearedTime: number | undefined,
  notifications: boolean
) => {
  let lastReadData;
  switch (messageType) {
    case "event":
      lastReadData = currentContext.eventThreadLastRead;
      break;
    case "group":
      lastReadData = currentContext.groupThreadLastRead;
      break;
    case "dm":
    default:
      lastReadData = currentContext.dmThreadLastRead;
      break;
  }

  // remove existing item, if any
  lastReadData = lastReadData.filter((record) => {
    return record.destinationId !== destinationId;
  });

  // create new one
  const newLastReadItem: ThreadLastReadRecord = {
    destinationId: destinationId,
    lastReadTime: newLastReadTime,
    clearedTime: newClearedTime,
    notifications: notifications,
  };
  // add it to the correct array and save it to our new context
  lastReadData.push(newLastReadItem);

  switch (messageType) {
    case "event":
      currentContext.eventThreadLastRead = lastReadData;
      break;
    case "group":
      currentContext.groupThreadLastRead = lastReadData;
      break;
    case "dm":
    default:
      currentContext.dmThreadLastRead = lastReadData;
      break;
  }

  if (currentContext.setLastReadData)
    currentContext.setLastReadData(currentContext);

  storeThreadLastReadData(currentUserId, currentContext);
};
export const storeThreadLastReadData = async (
  userId: string,
  threadLastReadData: ThreadLastReadContextType
) => {
  try {
    // clean up old values??
    const jsonValue = JSON.stringify(threadLastReadData);
    await localStorage.setItem("threadLastReadData" + userId, jsonValue);
  } catch (e) {
    // logSentryError("Error storing last read data in async storage.", e, {
    //   tags: {
    //     userId: userId,
    //     screen: "",
    //     function: "storeThreadLastReadData",
    //   },
    //   extra: {},
    //   level: "error",
    // });
  }
};

export const getThreadLastReadData = async (userId: string) => {
  try {
    const jsonValue = await localStorage.getItem("threadLastReadData" + userId);
    return jsonValue !== null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // logSentryError("Error getting last read data from async storage.", e, {
    //   tags: {
    //     userId: userId,
    //     screen: "",
    //     function: "getThreadLastReadData",
    //   },
    //   extra: {},
    //   level: "error",
    // });
  }
};

export const findLastReadItem = (
  currentContext: ThreadLastReadContextType,
  messageType: string,
  destinationId: string
) => {
  let lastReadItemList = undefined;
  switch (messageType) {
    case "event":
      lastReadItemList = currentContext.eventThreadLastRead;
      break;
    case "group":
      lastReadItemList = currentContext.groupThreadLastRead;
      break;
    case "dm":
    default:
      lastReadItemList = currentContext.dmThreadLastRead;
      break;
  }
  return lastReadItemList?.find((record) => {
    return record.destinationId === destinationId;
  });
};
export const threadHasNewMessage = (
  threadInfo: ThreadFull[],
  lastReadInfo: ThreadLastReadRecord[]
) => {
  let hasNew = false;
  for (const thread of threadInfo) {
    // don't even check older stuff
    if (isOlderThanAWeek(new Date(thread.timePosted))) break;
    // find lastReadItem associated with this thread
    const lastReadItem = lastReadInfo.find((record) => {
      return record.destinationId === thread.destinationId;
    });
    // if no lastReadItem, then it's new, else check the time
    if (!lastReadItem || lastReadItem.lastReadTime < thread.timePosted)
      hasNew = true;
    break;
  }
  return hasNew;
};

export const getThreadNotifications = (
  destinationId: string,
  lastReadInfo?: ThreadLastReadRecord[]
) => {
  const lastReadItem = lastReadInfo?.find((record) => {
    return record.destinationId === destinationId;
  });
  if (lastReadItem)
    return lastReadItem.notifications === undefined
      ? true
      : lastReadItem.notifications;
  else return true;
};
