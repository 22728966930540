import IonIcon from "@reacticons/ionicons";
import { DARK_PRIMARY_TEXT_COLOR } from "../../styles/colors";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { Icon } from "../../styles/icons";
import {
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextSmall } from "../../styles/text";
import "./FilterButton.css";

type FilterButtonProps = {
  title: string;
  iconName: React.ComponentProps<typeof IonIcon>["name"];
  onClick: () => void;
  active: boolean;
  disabled?: boolean;
};

export const FilterButton = (props: FilterButtonProps) => {
  const { active, iconName, title, onClick, disabled } = props;
  let trimmedTitle = title;
  if (trimmedTitle.length > 30) {
    trimmedTitle = trimmedTitle.substring(0, 27) + "...";
  }
  return (
    <button
      className={active ? "filter-button-active" : "filter-button"}
      onClick={onClick}
      disabled={disabled}
      type="button"
      style={{ marginBottom: LINE_VERTICAL_SPACING }}
    >
      <HorizontalLeftAlignedContainer>
        <Icon name={iconName} size={20} color={DARK_PRIMARY_TEXT_COLOR} />
        {!!title && (
          <>
            <div style={{ paddingRight: LINE_HORIZONTAL_SPACING }} />
            <TextSmall>{trimmedTitle}</TextSmall>
          </>
        )}
      </HorizontalLeftAlignedContainer>
    </button>
  );
};
