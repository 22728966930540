import React from "react";

type NormalTextWithNewlinesProps = {
  text: string;
};

export const NormalTextWithNewlines = (props: NormalTextWithNewlinesProps) => {
  const { text } = props;
  const newText = text.split("\n").map((subStr, index) => {
    return (
      <div key={index}>
        {subStr}
        <br />
      </div>
    );
  });
  return <>{newText}</>;
};
