import IonIcon from "@reacticons/ionicons";
import TouchableOpacity from "../general/TouchableOpacity";
import { GeneralAttribute } from "./GeneralAttribute";
import { useNavigation } from "react-router-dom";

type ClickableAttributeProps = {
  text: string;
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  onClick?: () => void;
};

/*
 * Text with an optional icon on the left and optional onClick.  For use in small horizontal lists
 * of attributes separated by a dot
 */
export const ClickableAttribute = (props: ClickableAttributeProps) => {
  const { text, iconName, onClick } = props;
  const navigation = useNavigation();
  return (
    <>
      <TouchableOpacity
        onClick={navigation.state !== "loading" ? onClick : undefined}
        style={{ backgroundColor: "transparent", borderStyle: "none" }}
      >
        <GeneralAttribute
          text={text}
          iconName={iconName}
          textDecoration="underline"
        />
      </TouchableOpacity>
    </>
  );
};
