import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { blankGroupImageURL } from "../../../constants/defaultImageURLs";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { Emoji, H1, TextRegular } from "../../../styles/text";
import { EventFull, GroupFull, MemberFull } from "../../../types";
import {
  createMessageSummary,
  getBorderRadius,
  userIsOrganizer,
} from "../../../utils";
import getSkillRange from "../../../utils/skillUtil";
import { ClickableDrawerRow } from "../../../components/rows/ClickableDrawerRow";
import { GroupPeopleSection } from "./GroupPeopleSection";
import { userContext } from "../../../UserContext";
import { GroupEventsSection } from "./GroupEventsSection";
import { GroupPhotosSection } from "./GroupPhotosSection";
import { ClickableRow } from "../../../components/rows/ClickableRow";
import { useNavigate, useNavigation } from "react-router-dom";
import { goToMessageHistory } from "../../../utils/navUtil";
import { GeneralAttribute } from "../../../components/attributes/GeneralAttribute";
import { COLORS } from "../../../styles/colors";
import { GroupJoinSection } from "./GroupJoinSection";
import { ButtonDrawer } from "../../../components/image/ButtonDrawer";
import Skeleton from "react-loading-skeleton";
import { ClickableAttribute } from "../../../components/attributes/ClickableAttribute";
import { Access } from "../../../types/enums";
import { UseInfiniteQueryResult } from "react-query";
import { threadLastReadContext } from "../../Inbox/Components/ThreadLastReadContext";
import { ExpandableDescription } from "../Components/ExpandableDescription";
import { PrimaryLeftHeader } from "../../../components/text/PrimaryLeftHeader";
import { ItemVerticalSpace } from "../../../components/spacing/ItemVerticalSpace";
import { SectionVerticalSpace } from "../../../components/spacing/SectionVerticalSpace";
import { NormalImage } from "../../../components/NormalImage";
import { GroupEventsFilterInfo } from "../GroupDetailsScreen";

type GroupGeneralInfoSectionProps = {
  groupDetails: GroupFull;
  memberData: MemberFull | undefined;
  membersQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  applicantsQueryResult: UseInfiniteQueryResult<MemberFull[]>;
  groupEventsQueryResult: UseInfiniteQueryResult<EventFull[]>;
  groupEventsFilterInfo?: GroupEventsFilterInfo;
  canViewDetails?: boolean;
  onImagePress: (imageUri: string) => void;
};

export const GroupGeneralInfoSection = (
  props: GroupGeneralInfoSectionProps
) => {
  const {
    groupDetails,
    memberData,
    membersQueryResult,
    applicantsQueryResult,
    groupEventsQueryResult,
    groupEventsFilterInfo,
    canViewDetails,
    onImagePress,
  } = props;
  const navigation = useNavigation();
  const userInfo = useContext(userContext);
  const isAdmin = userInfo.adminLevel > 90;
  const colors = COLORS["light"];
  const currentUserInfo = useContext(userContext);
  const threadLastReadData = useContext(threadLastReadContext);
  const currentUserId = currentUserInfo.id;
  const navigate = useNavigate();

  const [showPeopleDrawer, setShowPeopleDrawer] = useState(false);
  const [showReviewApplicantsDrawer, setShowReviewApplicantsDrawer] =
    useState(false);
  const [showEventsDrawer, setShowEventsDrawer] = useState(false);

  const togglePeopleDrawer = () => {
    document.body.style.overflow = !showPeopleDrawer ? "hidden" : "unset";
    if (navigation.state !== "loading") {
      if (showEventsDrawer) setShowEventsDrawer(false);
      if (showReviewApplicantsDrawer) setShowReviewApplicantsDrawer(false);
      else setShowPeopleDrawer(!showPeopleDrawer);
    }
  };
  const toggleEventsDrawer = () => {
    document.body.style.overflow = !showEventsDrawer ? "hidden" : "unset";
    if (navigation.state !== "loading") {
      if (showEventsDrawer) groupEventsFilterInfo?.setDateFilter("Upcoming");
      setShowEventsDrawer(!showEventsDrawer);
      if (showPeopleDrawer) setShowPeopleDrawer(false);
      if (showReviewApplicantsDrawer) setShowReviewApplicantsDrawer(false);
    }
  };
  const toggleReviewApplicantsDrawer = () => {
    document.body.style.overflow =
      !showEventsDrawer && !showReviewApplicantsDrawer ? "hidden" : "unset";
    if (navigation.state !== "loading") {
      setShowEventsDrawer(false);
      setShowReviewApplicantsDrawer(!showReviewApplicantsDrawer);
    }
  };

  const isOrganizer = userIsOrganizer(currentUserId, groupDetails);

  const hasNewApplicants =
    applicantsQueryResult.isSuccess &&
    !!applicantsQueryResult.data &&
    applicantsQueryResult.data.pages[0].length > 0;

  const showNewApplicants = (isAdmin || isOrganizer) && hasNewApplicants;

  const groupImageUrl =
    groupDetails && groupDetails.imageUri
      ? groupDetails.imageUri
      : blankGroupImageURL;
  const borderRadius = getBorderRadius("small", "rounded rect");
  const lastMessageSummary = "Group Discussion";

  const lastReadItem = threadLastReadData.groupThreadLastRead.find(
    (item) => item.destinationId === groupDetails.id
  );
  const hasNewMessages = groupDetails.lastMessage
    ? !lastReadItem ||
      groupDetails.lastMessage.timePosted > lastReadItem.lastReadTime
    : false;

  const skillString = getSkillRange(
    groupDetails.minSkill,
    groupDetails.maxSkill
  );

  return (
    <div
      style={{
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      {/* Group Name */}
      <PrimaryLeftHeader>{groupDetails?.name}</PrimaryLeftHeader>
      <SectionVerticalSpace />
      {/* {navigation.state === "loading" ? (
        <div
          style={{
            marginTop: SECTION_VERTICAL_SPACING,
            marginBottom: ITEM_VERTICAL_SPACING,
          }}
        >
          <Skeleton height={30} count={0.2} />
        </div>
      ) : (
        <HorizontalLeftAlignedContainer>
          <H1
            style={{
              marginTop: SECTION_VERTICAL_SPACING,
            }}
          >
            {groupDetails?.name}
          </H1>
        </HorizontalLeftAlignedContainer>
      )} */}

      {navigation.state === "loading" ? (
        <Skeleton height={24} count={0.75} />
      ) : (
        <HorizontalSpaceBetweenContainer>
          {/* Group Attributes */}
          <HorizontalLeftAlignedContainer
            style={{
              flexWrap: "wrap",
            }}
          >
            <GeneralAttribute
              text={groupDetails.access}
              iconName={
                groupDetails.access !== "Hidden"
                  ? groupDetails.access === "Private"
                    ? "lock-closed"
                    : "lock-open"
                  : undefined
              }
              emoji={groupDetails.access === Access.Hidden ? "🛡️" : undefined}
            />
            <p className="dot-separator">·</p>

            <GeneralAttribute
              text={`${skillString} Skill Level`}
              iconName="flash"
            />

            <p className="dot-separator">·</p>

            <GeneralAttribute
              text={`Group Code: ${groupDetails.code}`}
              iconName="copy"
            />

            <p className="dot-separator">·</p>

            <ClickableAttribute
              text={groupDetails.displayAddress}
              onClick={() => {
                window.open(
                  "https://www.google.com/maps/search/?api=1&query=" +
                    encodeURIComponent(groupDetails.address),
                  "_blank"
                );
              }}
              // iconName="location"
            />
          </HorizontalLeftAlignedContainer>

          {/* Join/pending button or disabled/deleted banner */}
          <HorizontalRightAlignedContainer style={{ minWidth: 100 }}>
            <GroupJoinSection
              memberData={memberData}
              groupDetails={groupDetails}
            />
          </HorizontalRightAlignedContainer>
        </HorizontalSpaceBetweenContainer>
      )}

      <ItemVerticalSpace border={true} />
      <ItemVerticalSpace />

      {/* Group Image */}
      <HorizontalLeftAlignedContainer
        style={{ width: "100%", alignItems: "flex-start" }}
      >
        <div
          style={{
            display: "grid",
            // position: "relative",
            marginRight: 16,
          }}
        >
          <div
            style={{
              gridArea: "1/1",
            }}
          >
            <NormalImage
              imageUri={groupImageUrl}
              alt="this group's pic"
              width={350}
              height={250}
              borderRadius={borderRadius}
              objectFit="cover"
            />
          </div>
          <div
            style={{
              gridArea: "1/1",
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: 20,
                right: 20,
              }}
            >
              <ButtonDrawer
                title="View Album"
                color={colors.SECONDARY_TEXT}
                backgroundColor={colors.LIGHT_GRAY}
              >
                <GroupPhotosSection
                  groupDetails={groupDetails}
                  onImagePress={onImagePress}
                  isOrganizer={isOrganizer}
                />
              </ButtonDrawer>
            </div>
          </div>
        </div>
      </HorizontalLeftAlignedContainer>

      <ItemVerticalSpace />
      {navigation.state === "loading" ? (
        <>
          <HorizontalLeftAlignedContainer
            style={{
              display: "flex",
              flex: 1,
              marginTop: ITEM_VERTICAL_SPACING,
              marginBottom: ITEM_VERTICAL_SPACING,
            }}
          >
            <div style={{ flex: 1 }}>
              <Skeleton
                height={24}
                width="100%"
                count={3}
                style={{ marginBottom: 15 }}
              />
            </div>
          </HorizontalLeftAlignedContainer>
          <ItemVerticalSpace />
        </>
      ) : groupDetails.description ? (
        <>
          <div
            style={{
              marginTop: ITEM_VERTICAL_SPACING,
              marginBottom: ITEM_VERTICAL_SPACING,
            }}
          >
            <ExpandableDescription text={groupDetails?.description} />
          </div>
          <ItemVerticalSpace />
        </>
      ) : (
        <></>
      )}

      {canViewDetails && (
        <>
          <ClickableDrawerRow
            iconName="people-outline"
            title={`${groupDetails.memberCount} ${
              groupDetails.memberCount === 1 ? "Person" : "People"
            }`}
            subTitle=""
            notice={showNewApplicants ? "New" : undefined}
            showDrawer={showPeopleDrawer}
            toggleDrawer={togglePeopleDrawer}
          >
            <GroupPeopleSection
              groupDetails={groupDetails}
              memberData={memberData}
              membersQueryResult={membersQueryResult}
              applicantsQueryResult={applicantsQueryResult}
              showReviewApplicantsDrawer={showReviewApplicantsDrawer}
              toggleReviewApplicantsDrawer={toggleReviewApplicantsDrawer}
              togglePeopleDrawer={togglePeopleDrawer}
            />
          </ClickableDrawerRow>

          <ClickableRow
            iconName="chatbox-outline"
            title={lastMessageSummary}
            subTitle=""
            notice={hasNewMessages ? "New" : undefined}
            onPress={() =>
              goToMessageHistory(
                navigate,
                "group",
                groupDetails.id,
                groupDetails.name
              )
            }
          />

          <ClickableDrawerRow
            iconName="calendar-outline"
            title="Upcoming Events"
            subTitle=""
            showDrawer={showEventsDrawer}
            toggleDrawer={toggleEventsDrawer}
          >
            <GroupEventsSection
              groupId={groupDetails.id}
              isOrganizer={isOrganizer}
              groupEventsQueryResult={groupEventsQueryResult}
              groupEventsFilterInfo={groupEventsFilterInfo}
            />
          </ClickableDrawerRow>
        </>
      )}
    </div>
  );
};
