import Skeleton from "react-loading-skeleton";
import { DateFilterButton } from "../../../features/filters/DateFilterbutton";
import { FilterContainer } from "../../../styles/container";
import {
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { MyEventsFilterInfo } from "../EventsScreen";
import { getBorderRadius } from "../../../utils";
import { GroupsFilterButton } from "../../../features/filters/GroupsFilterbutton";

type MyEventsFilterSectionProps = {
  filterInfo?: MyEventsFilterInfo;
};

export const MyEventsFilterSection = (props: MyEventsFilterSectionProps) => {
  const { filterInfo } = props;
  return (
    <FilterContainer
      style={{
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: LINE_VERTICAL_SPACING,
      }}
    >
      {filterInfo ? (
        <DateFilterButton
          date={filterInfo.dateFilter}
          setDate={filterInfo.setDateFilter}
          defaultDate={"Upcoming"}
        />
      ) : (
        <div style={{ width: 150 }}>
          <Skeleton
            height={32}
            borderRadius={getBorderRadius("large", "rounded rect")}
          />
        </div>
      )}
      <div style={{ width: 8 }} />
      {filterInfo ? (
        <GroupsFilterButton
          group={filterInfo.groupFilter}
          setGroup={filterInfo.setGroupFilter}
          defaultGroup={undefined}
        />
      ) : (
        <div style={{ width: 150 }}>
          <Skeleton
            height={32}
            borderRadius={getBorderRadius("large", "rounded rect")}
          />
        </div>
      )}
    </FilterContainer>
  );
};
