import React from "react";
import {
  HorizontalLeftAlignedContainer,
  SizedContainer,
} from "../../styles/container";
import { LINE_HORIZONTAL_SPACING } from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { pdfPlaceholder } from "../../constants/defaultImageURLs";

type PdfMessageItemProps = {
  pdfFilename: string;
  backgroundColor?: string;
};

export const PdfMessageItem = (props: PdfMessageItemProps) => {
  const { pdfFilename, backgroundColor } = props;
  return (
    <HorizontalLeftAlignedContainer>
      <SizedContainer width={36} height={36}>
        <img
          alt="pdf icon"
          src={pdfPlaceholder}
          width={36}
          height={36}
          style={{ backgroundColor: backgroundColor }}
        />
      </SizedContainer>
      <div
        style={{
          marginLeft: LINE_HORIZONTAL_SPACING,
          maxWidth: "100%",
        }}
      >
        <TextRegular>{pdfFilename}</TextRegular>
      </div>
    </HorizontalLeftAlignedContainer>
  );
};
