import React, { useEffect, useRef } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { Form, useNavigate, useNavigation } from "react-router-dom";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H1, TextRegular, TextSmall } from "../../styles/text";
import {
  goToForgotPassword,
  goToLoggingIn,
  goToSignUp,
} from "../../utils/navUtil";
import { LoginErrors } from "./LoginScreen";
import { handleSocialSignIn } from "../../utils/authenticationUtil";
import { SubmitButton } from "../../components/inputs/SubmitButton";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  LINE_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { BeatLoader } from "react-spinners";
import { COLORS } from "../../styles/colors";
import { TextButton } from "../../components/buttons/TextButton";
import { SocialLoginButton } from "../../components/buttons/SocialLoginButton";
import appleLogo from "../../assets/images/apple-icon.png";
import googleLogo from "../../assets/images/google-icon.png";
import { TitledInput } from "../../components/inputs/TitledInput";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { FancyTitle } from "../../components/text/FancyTitle";
import { useWindowHeight } from "@react-hook/window-size";

type LoginScreenLayoutProps = {
  errors: LoginErrors;
  emailAddress: string;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
};

export const LoginScreenLayout = (props: LoginScreenLayoutProps) => {
  const { emailAddress, setEmailAddress, password, setPassword, errors } =
    props;
  const navigate = useNavigate();
  const navigation = useNavigation();
  const colors = COLORS["light"];
  const windowHeight = useWindowHeight();
  const footerDivRef = useRef<HTMLDivElement>(null);
  const [loginHeight, setLoginHeight] = useState(500);
  const [loading, setLoading] = useState(false);

  const isBusy = () => {
    return navigation.state !== "idle" || loading;
  };
  const doSocialSignin = async (provider: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    handleSocialSignIn(provider, "LoginScreen");
    goToLoggingIn(navigate, provider);
    setLoading(false);
  };
  useEffect(() => {
    if (footerDivRef.current) {
      setLoginHeight(
        windowHeight -
          footerDivRef.current?.offsetHeight -
          ITEM_VERTICAL_SPACING
      );
    }
  }, [footerDivRef, windowHeight]);

  return (
    <ScreenContainer>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: loginHeight,
        }}
      >
        <div style={{ width: "80%" }}>
          <Form action="/" method="POST" style={{ width: "100%" }}>
            <HorizontalLeftAlignedContainer
              style={{
                marginTop: HEADER_VERTICAL_SPACING,
                marginBottom: SECTION_VERTICAL_SPACING,
              }}
            >
              <FancyTitle>Picklers</FancyTitle>
            </HorizontalLeftAlignedContainer>
            <HorizontalContainer style={{ paddingTop: ITEM_VERTICAL_SPACING }}>
              <TitledInput
                title="Email"
                formDataName={"emailaddress"}
                value={emailAddress}
                setText={setEmailAddress}
                editable={true}
                placeholderText="Enter email address"
                maxLength={64}
                autoComplete="email"
              />
            </HorizontalContainer>

            {errors?.email && (
              <HorizontalLeftAlignedContainer
                style={{ marginTop: LINE_VERTICAL_SPACING }}
              >
                <TextSmall color="red">{errors.email}</TextSmall>
              </HorizontalLeftAlignedContainer>
            )}
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <TitledInput
                title="Password"
                formDataName={"password"}
                value={password}
                setText={setPassword}
                editable={true}
                placeholderText="Enter password"
                maxLength={16}
                password={true}
                autoComplete="password"
              />
            </HorizontalContainer>
            {errors?.password && (
              <HorizontalLeftAlignedContainer
                style={{ marginTop: LINE_VERTICAL_SPACING }}
              >
                <TextSmall color="red">{errors.password}</TextSmall>
              </HorizontalLeftAlignedContainer>
            )}
            <HorizontalLeftAlignedContainer
              style={{ marginTop: LINE_VERTICAL_SPACING }}
            >
              <TextButton
                onClick={() => goToForgotPassword(navigate)}
                title="Forgot your password?"
                disabled={isBusy()}
                color={colors.PRIMARY_BRAND}
                type="button"
              />
            </HorizontalLeftAlignedContainer>

            {errors?.other && (
              <HorizontalLeftAlignedContainer
                style={{ marginTop: LINE_VERTICAL_SPACING }}
              >
                <TextSmall color="red">{errors.other}</TextSmall>
              </HorizontalLeftAlignedContainer>
            )}
            <HorizontalContainer
              style={{
                marginTop: SECTION_VERTICAL_SPACING + ITEM_VERTICAL_SPACING,
                marginBottom: SECTION_VERTICAL_SPACING,
              }}
            >
              <SubmitButton loading={isBusy()} title="Log In" />
            </HorizontalContainer>
          </Form>
          {isBusy() && (
            <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
              <BeatLoader
                color={colors.PRIMARY_BRAND}
                loading={true}
                size={14}
              />
            </HorizontalContainer>
          )}

          <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
            <TextRegular color={colors.SECONDARY_TEXT}> – OR –</TextRegular>
          </HorizontalContainer>

          <HorizontalSpaceBetweenContainer
            style={{
              marginTop: SECTION_VERTICAL_SPACING,
              width: "100%",
            }}
          >
            <SocialLoginButton
              onClick={() => doSocialSignin("google")}
              title="Continue with Google"
              disabled={isBusy()}
              logoUri={googleLogo}
            />
            <div style={{ width: LINE_HORIZONTAL_SPACING }} />
            <SocialLoginButton
              onClick={() => doSocialSignin("apple")}
              title="Continue with Apple"
              disabled={isBusy()}
              logoUri={appleLogo}
            />
          </HorizontalSpaceBetweenContainer>
          <ItemVerticalSpace />
        </div>
        <div ref={footerDivRef}>
          <HorizontalContainer>
            <TextSmall>Not registered yet?</TextSmall>
            <TextButton
              onClick={() => goToSignUp(navigate)}
              title="Create an account"
              disabled={isBusy()}
              color={colors.PRIMARY_BRAND}
              type="button"
            />
          </HorizontalContainer>
        </div>
      </div>
    </ScreenContainer>
  );
};
