import React, { useRef, useState } from "react";
import { TitledInput } from "../../components/inputs/TitledInput";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
} from "../../styles/container";
import { TextRegular, TextSmall } from "../../styles/text";
import { EventFull } from "../../types";
import { RepeatEventErrors } from "./EventRepeatScreen";
import {
  ITEM_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TitledDateTimePicker } from "../../components/inputs/TitledDateTimePicker";
import { HOUR_IN_MILLISECONDS, guessTimeZone } from "../../utils";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../components/inputs/TitledDropdown";
import { NumericalDropDownItem } from "../../types/dropdownTypes";
import { Form, useNavigate } from "react-router-dom";
import { EditActionBar } from "../../features/actionBars/EditActionBar";
import { StickyActionBar } from "../../components/StickyActionBar";
import { PrimaryHeader } from "../../components/text/PrimaryHeader";
import { SectionVerticalSpace } from "../../components/spacing/SectionVerticalSpace";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import TimePicker from "react-time-picker";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import moment from "moment-timezone";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "./EventRepeat.css";

type EventRepeatLayoutProps = {
  eventDetails: EventFull;
  errors?: RepeatEventErrors;
};

export const EventRepeatLayout = (props: EventRepeatLayoutProps) => {
  const { eventDetails, errors } = props;
  const [title, setTitle] = useState(eventDetails.title);
  const [startTime, setStartTime] = useState<Value>(
    moment(new Date(eventDetails.rsvpDate)).tz(guessTimeZone()).format("HH:mm")
  );
  const [startDate, setStartDate] = useState(
    // set default 1st iteration to 1 week after the event we are replicating
    new Date(eventDetails.date + 7 * 24 * HOUR_IN_MILLISECONDS)
  );
  const [fixedDate, setFixedDate] = useState(
    // set default fixed rsvp opens date to 7 days before event
    new Date(eventDetails.date - 7 * 24 * HOUR_IN_MILLISECONDS)
  );
  const [endDate, setEndDate] = useState(
    // set ending date initially to 91 days ahead.  set it's time ahead by 2 hours so the
    // time is ahead and therefore the specified ending date is still one you can repeat
    // an event on.
    new Date(
      eventDetails.date +
        (3 * 30 + 1) * 24 * HOUR_IN_MILLISECONDS +
        2 * HOUR_IN_MILLISECONDS
    )
  );
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const validateStartDate = (trialEventDate: Date): Date => {
    const now = new Date();
    if (trialEventDate < now) return now;
    if (trialEventDate > endDate) setEndDate(new Date(trialEventDate));
    return trialEventDate; // trial date should be unchanged
  };

  const validateEndDate = (trialEndDate: Date): Date => {
    const now = new Date();
    if (trialEndDate < now) return now;
    if (trialEndDate < startDate) return new Date(startDate);
    return trialEndDate; // trial date should be unchanged
  };

  const validateFixedRsvpDate = (trialRsvpDate: Date): Date => {
    const now = new Date();
    if (trialRsvpDate < now) return now;
    if (trialRsvpDate > startDate) return now;
    return trialRsvpDate; // trial date should be unchanged
  };

  const eventMoment = moment(eventDetails.date);
  const rsvpMoment = moment(eventDetails.rsvpDate);
  const daysBetween = eventMoment.diff(rsvpMoment, "days");
  const [rsvpOpens, setRsvpOpens] = useState<DropdownValueType>(
    daysBetween > 0 && daysBetween < 8 ? daysBetween : -1
  );

  const [rsvpOpensItems] = useState<NumericalDropDownItem[]>([
    { label: "Fixed Date for all recurring events", value: -1 },
    { label: "1 day before event starts", value: 1 },
    { label: "2 days before event starts", value: 2 },
    { label: "3 days before event starts", value: 3 },
    { label: "4 days before event starts", value: 4 },
    { label: "5 days before event starts", value: 5 },
    { label: "6 days before event starts", value: 6 },
    { label: "7 days before event starts", value: 7 },
  ]);

  const [frequency, setFrequency] = useState<DropdownValueType>(7);
  const [frequencyItems] = useState<NumericalDropDownItem[]>([
    { label: "Weekly", value: 7 },
    { label: "Bi-Weekly", value: 14 },
  ]);

  return (
    <>
      <Form
        action={`/event/repeat/${eventDetails.id}`}
        method="POST"
        encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
        style={{
          marginTop: -SECTION_VERTICAL_SPACING,
          paddingLeft: ITEM_HORIZONTAL_SPACING,
          paddingRight: ITEM_HORIZONTAL_SPACING,
        }}
      >
        <StickyActionBar>
          <EditActionBar
            onCancel={() => {
              navigate(-1);
            }}
            onSave={() => {
              if (buttonRef.current) buttonRef.current.click();
            }}
          />
        </StickyActionBar>
        {errors?.generic && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.generic}</TextRegular>
          </HorizontalContainer>
        )}
        {/*
          the actual submission button is not displayed
        */}
        <button
          ref={buttonRef}
          type="submit"
          name="Save"
          style={{ display: "none" }}
        >
          Save
        </button>
        {/* 
          save our eventId so the action knows which one to copy from 
        */}
        <input
          type="hidden"
          name="eventid"
          value={eventDetails.id ? eventDetails.id : ""}
        />
        <input type="hidden" name="groupid" value={eventDetails.groupId} />
        <SectionVerticalSpace />
        <PrimaryHeader>Set Up Recurring Event</PrimaryHeader>
        <SectionVerticalSpace />

        {/* 
            Event Title
        */}
        <HorizontalLeftAlignedContainer>
          <TitledInput
            title="EVENT TITLE"
            formDataName="eventtitle"
            value={title}
            setText={setTitle}
            maxLength={64}
            placeholderText={""}
            editable={false}
          />
        </HorizontalLeftAlignedContainer>

        <ItemVerticalSpace />
        {/* 
            Starting Date
        */}
        <HorizontalLeftAlignedContainer style={{ marginRight: 12 }}>
          <TitledDateTimePicker
            title="START DATE"
            formDataName="startdate"
            date={startDate}
            setDate={setStartDate}
            validateDate={validateStartDate}
          />
        </HorizontalLeftAlignedContainer>

        <ItemVerticalSpace />

        {/* 
            RSVP Open Interval
        */}
        <HorizontalLeftAlignedContainer>
          <TitledDropdown
            title="RSVP OPENS"
            formDataName="rsvpopens"
            value={rsvpOpens}
            setValue={setRsvpOpens}
            items={rsvpOpensItems}
          />
          {typeof rsvpOpens === "number" && rsvpOpens > 0 ? (
            <div style={{ marginLeft: ITEM_HORIZONTAL_SPACING, flex: 1 }}>
              <HorizontalLeftAlignedContainer style={{ marginBottom: 8 }}>
                <TextSmall>TIME</TextSmall>
              </HorizontalLeftAlignedContainer>
              <HorizontalRightAlignedContainer className="picklers-timepicker">
                <TimePicker
                  onChange={(time: Value) => setStartTime(time)}
                  value={startTime}
                  clearIcon={null}
                  disableClock={true}
                />
              </HorizontalRightAlignedContainer>
              <input
                type="hidden"
                name="rsvpopenstime"
                value={startTime?.toString()}
              />
            </div>
          ) : (
            <HorizontalLeftAlignedContainer
              style={{
                marginLeft: ITEM_HORIZONTAL_SPACING,
                flex: 1,
                marginRight: 12,
              }}
            >
              <TitledDateTimePicker
                title="FIXED DATE"
                formDataName="fixedrsvpopens"
                date={fixedDate}
                setDate={setFixedDate}
                validateDate={validateFixedRsvpDate}
              />
            </HorizontalLeftAlignedContainer>
          )}
        </HorizontalLeftAlignedContainer>
        {errors?.rsvpOpens && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.rsvpOpens}</TextRegular>
          </HorizontalContainer>
        )}
        <ItemVerticalSpace />

        {/*
          RSVP Open Fixed Date
          only appears when interval is -1 
        */}

        {/* {typeof rsvpOpens === "number" && rsvpOpens < 0 && (
          <>
            <HorizontalLeftAlignedContainer style={{ marginRight: 12 }}>
              <TitledDateTimePicker
                title="FIXED RSVP OPEN DATE"
                formDataName="fixedrsvpopens"
                date={fixedDate}
                setDate={setFixedDate}
                validateDate={validateEventDate}
              />
            </HorizontalLeftAlignedContainer>
            {errors?.fixedRsvpOpens && (
              <HorizontalContainer>
                <TextRegular color="red">{errors?.fixedRsvpOpens}</TextRegular>
              </HorizontalContainer>
            )}
            <ItemVerticalSpace />
          </>
        )} */}

        {/* 
            Frequency
        */}
        <HorizontalLeftAlignedContainer>
          <TitledDropdown
            title="FREQUENCY"
            formDataName="frequency"
            value={frequency}
            setValue={setFrequency}
            items={frequencyItems}
          />
        </HorizontalLeftAlignedContainer>

        <ItemVerticalSpace />

        {/* 
            Ending Date
        */}
        <HorizontalLeftAlignedContainer style={{ marginRight: 12 }}>
          <TitledDateTimePicker
            title="END DATE"
            formDataName="enddate"
            date={endDate}
            setDate={setEndDate}
            validateDate={validateEndDate}
          />
        </HorizontalLeftAlignedContainer>
        <ItemVerticalSpace />
      </Form>
    </>
  );
};
