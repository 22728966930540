import React, { useContext } from "react";
import { userContext } from "../../../UserContext";
import { Banner } from "../../../components/rows/Banner";
import { COLORS } from "../../../styles/colors";
import { ITEM_VERTICAL_SPACING, SIDE_PADDING } from "../../../styles/spacing";
import { GroupStatus } from "../../../types/enums";
import { memberIsApplicant, memberIsMember } from "../../../utils";
import { GroupFull, MemberFull } from "../../../types";
import { HorizontalContainer } from "../../../styles/container";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useFetcher } from "react-router-dom";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";

type GroupJoinSectionProps = {
  memberData: MemberFull | undefined;
  groupDetails: GroupFull;
};

export const GroupJoinSection = (props: GroupJoinSectionProps) => {
  const { memberData, groupDetails } = props;
  const userInfo = useContext(userContext);
  const currentUserId = userInfo ? userInfo.id : "";
  const colors = COLORS["light"];
  const groupId = props.groupDetails ? props.groupDetails.id : "";
  const groupStatus = props.groupDetails ? props.groupDetails.status : "";
  const fetcher = useFetcher();

  if (!userInfo) return <></>;

  const isActive = () => {
    if (groupStatus === GroupStatus.Active || groupStatus === "") return true;
    else return false;
  };

  const handleJoin = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title="Please Confirm"
            content={`Apply to join '${groupDetails.name}'?`}
            yesButton="Yes"
            noButton="Never Mind"
            yesButtonClick={() => {
              fetcher.submit(
                {
                  userid: currentUserId,
                  status:
                    groupDetails.access !== "Public" ? "applied" : "accepted",
                },
                {
                  method: "POST",
                  action: `/group/view/${groupId}/memberJoinAction`,
                }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  return memberIsMember(memberData) && isActive() ? (
    <></>
  ) : (
    <HorizontalContainer
      style={{
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
        backgroundColor: colors.BACKGROUND,
        borderTopWidth: 1,
        borderTopColor: colors.LIGHT_GRAY,
        flexShrink: 0,
      }}
    >
      {isActive() && !memberIsApplicant(memberData) && (
        <PrimaryButton
          onClick={() => {
            handleJoin();
          }}
          title="Join Group"
        />
      )}
      {isActive() && memberIsApplicant(memberData) && (
        <Banner
          type="neutral"
          title="Membership Pending"
          iconName="hourglass-outline"
        />
      )}
    </HorizontalContainer>
  );
};
