import axios from "axios";
import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from "react-query";
import { BACKEND_URL } from "../env";
import { ImageUploadData, VenueCreate, VenueFull, VenueUpdate } from "../types";
import { CourtType, VenueStatus } from "../types/enums";
import { addQueryItem } from "../utils";

const fetchVenue = async (venueId?: string): Promise<VenueFull | undefined> => {
  const { data } = await axios.get(BACKEND_URL + "venues/" + venueId);
  return data;
};

export const venueDetailsQuery = (currentUserId: string, venueId?: string) => ({
  queryKey: ["venues", venueId],
  queryFn: async () => fetchVenue(venueId),
  enabled: !!currentUserId && !!venueId,
});

export const useVenue = (
  currentUserId: string,
  venueId?: string
): UseQueryResult<VenueFull> =>
  useQuery(venueDetailsQuery(currentUserId, venueId));

const fetchVenues = async (
  page: number,
  lat: number,
  long: number,
  distance: number,
  courtType: string | undefined,
  verificationStatus: string | undefined,
  lighting: string | undefined
): Promise<VenueFull[] | undefined> => {
  if (lat < -90 || lat > 90 || long < -180 || long > 180 || distance <= 0)
    return;

  let queryParamString = "";
  queryParamString = addQueryItem(queryParamString, "page", page.toString());
  queryParamString = addQueryItem(queryParamString, "limit", "10");

  queryParamString = addQueryItem(
    queryParamString,
    "distance",
    distance.toString()
  );
  queryParamString = addQueryItem(queryParamString, "lat", lat.toString());
  queryParamString = addQueryItem(queryParamString, "long", long.toString());

  if (courtType && courtType === CourtType.Outdoor) {
    queryParamString = addQueryItem(queryParamString, "outdoorCourts", "true");
  }
  if (courtType && courtType === CourtType.Indoor) {
    queryParamString = addQueryItem(queryParamString, "indoorCourts", "true");
  }
  if (verificationStatus && verificationStatus === "Verified") {
    queryParamString = addQueryItem(
      queryParamString,
      "verificationStatus",
      "true"
    );
  }
  if (verificationStatus && verificationStatus === "Unverified") {
    queryParamString = addQueryItem(
      queryParamString,
      "verificationStatus",
      "false"
    );
  }
  if (lighting && lighting === "Dusk Lighting") {
    queryParamString = addQueryItem(queryParamString, "lighting", "true");
  }

  const { data } = await axios.get(BACKEND_URL + "venues/" + queryParamString);
  return data;
};

export const venuesQuery = (
  // locationFilter: LocationFilterData,
  currentUserId: string,
  latitude: number,
  longitude: number,
  mileage: number,
  courtType?: string,
  verificationStatus?: string,
  lighting?: string
) => ({
  queryKey: [
    "venues",
    latitude,
    longitude,
    mileage,
    courtType,
    verificationStatus,
    lighting,
  ],
  queryFn: async ({ pageParam = 1 }) => {
    return fetchVenues(
      pageParam,
      latitude,
      longitude,
      mileage,
      courtType,
      verificationStatus,
      lighting
    );
  },
  enabled: !!currentUserId,
  staleTime: 5 * (60 * 1000),
  getNextPageParam: (
    lastPage: VenueFull[] | undefined,
    pages: (VenueFull[] | undefined)[]
  ) => {
    const nextPage = pages.length + 1;
    return nextPage;
  },
});

export const useVenues = (
  currentUserId: string,
  latitude: number,
  longitude: number,
  mileage: number,
  courtType?: string,
  verificationStatus?: string,
  lighting?: string
): UseInfiniteQueryResult<VenueFull[]> =>
  useInfiniteQuery(
    venuesQuery(
      currentUserId,
      latitude,
      longitude,
      mileage,
      courtType,
      verificationStatus,
      lighting
    )
  );

const fetchAllVenues = async (
  lat: number,
  long: number,
  distance: number,
  courtType: string | undefined,
  verificationStatus: string | undefined,
  lighting: string | undefined
): Promise<VenueFull[] | undefined> => {
  let queryParamString = "";

  if (lat === -999 || long === -999) return undefined;

  if (distance && distance > 0)
    queryParamString = addQueryItem(
      queryParamString,
      "distance",
      distance.toString()
    );

  if (lat && long) {
    queryParamString = addQueryItem(queryParamString, "lat", lat.toString());
    queryParamString = addQueryItem(queryParamString, "long", long.toString());
  }

  if (courtType && courtType === CourtType.Outdoor) {
    queryParamString = addQueryItem(queryParamString, "outdoorCourts", "true");
  }

  if (courtType && courtType === CourtType.Indoor) {
    queryParamString = addQueryItem(queryParamString, "indoorCourts", "true");
  }

  if (verificationStatus && verificationStatus === "Verified") {
    queryParamString = addQueryItem(
      queryParamString,
      "verificationStatus",
      "true"
    );
  }

  if (verificationStatus && verificationStatus === "Unverified") {
    queryParamString = addQueryItem(
      queryParamString,
      "verificationStatus",
      "false"
    );
  }

  if (lighting && lighting === "Dusk Lighting") {
    queryParamString = addQueryItem(queryParamString, "lighting", "true");
  }

  const { data } = await axios.get(
    BACKEND_URL + "venues/all/" + queryParamString
  );
  return data;
};

export const useAllVenues = (
  lat: number,
  long: number,
  distance: number,
  courtType?: string,
  verificationStatus?: string,
  lighting?: string
) =>
  useQuery(
    [
      "venues-all",
      lat,
      long,
      distance,
      courtType,
      verificationStatus,
      lighting,
    ],
    () =>
      fetchAllVenues(
        lat,
        long,
        distance,
        courtType,
        verificationStatus,
        lighting
      ),
    {
      enabled: !!lat && !!long,
    }
  );

export const setVenueStatus = async (
  venueId: string,
  status: VenueStatus,
  lastEditedBy: string
) => {
  const statusData = {
    status: status,
    lastEditedBy: lastEditedBy,
  };
  await axios.patch(BACKEND_URL + "venues/status/" + venueId, statusData);
  return true;
};

export const saveNewVenue = async (venueData: VenueCreate) => {
  const { data } = await axios.post(BACKEND_URL + "venues/", venueData);
  return data;
};

export const patchVenue = async (venueId: string, venueData: VenueUpdate) => {
  // Venue creation and update data are the same
  const { data } = await axios.patch(
    BACKEND_URL + "venues/" + venueId,
    venueData
  );
  return data;
};

export const uploadVenueImage = async (
  venueId: string,
  userId: string,
  imageUploadData: ImageUploadData
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageUploadData as unknown as File);
  let result;
  result = await axios.post(
    BACKEND_URL + "venues/" + venueId + "/upload/" + userId,
    formData,
    postHeaders
  );
  return result;
};

export const uploadVenueImageFile = async (
  venueId: string,
  userId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);
  let result;
  result = await axios.post(
    BACKEND_URL + "venues/" + venueId + "/upload/" + userId,
    formData,
    postHeaders
  );
  return result;
};
