import React, { useContext, useEffect, useState } from "react";
import { redirect, useSearchParams } from "react-router-dom";
import { QueryClient } from "react-query";
import {
  dmThreadsQuery,
  eventThreadsQuery,
  groupThreadsQuery,
  useDMThreads,
  useEventThreads,
  useGroupThreads,
} from "../../hooks/queryMessages";
import { ScreenContainer } from "../../features/ScreenContainer";
import { InboxScreenLayout } from "./InboxScreenLayout";
import { userContext } from "../../UserContext";

export const inboxLoader = async (
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const dmsQuery = dmThreadsQuery(true, currentUserId);
    queryClient.getQueryData(dmsQuery.queryKey) ??
      (await queryClient.fetchQuery(dmsQuery));

    const groupMessageQuery = groupThreadsQuery(true, currentUserId);
    queryClient.getQueryData(groupMessageQuery.queryKey) ??
      (await queryClient.fetchQuery(groupMessageQuery));

    const eventMessageQuery = eventThreadsQuery(true, currentUserId);
    queryClient.getQueryData(eventMessageQuery.queryKey) ??
      (await queryClient.fetchQuery(eventMessageQuery));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { ok: true };
};

export const InboxScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = useContext(userContext);
  const currentUserId = userInfo.id;

  const tab = searchParams.get("tab");
  const tabNum = tab ? parseInt(tab) : 0;
  const [tabIndex, setTabIndex] = useState(tabNum);

  const dmThreadsResult = useDMThreads(true, currentUserId);
  const groupThreadsResult = useGroupThreads(true, currentUserId);
  const eventThreadsResult = useEventThreads(true, currentUserId);

  const onTabSelect = (newIndex: number) => {
    setSearchParams((params) => {
      params.set("tab", newIndex.toString());
      return params;
    });
    setTabIndex(newIndex);
  };

  useEffect(() => {
    switch (tabIndex) {
      case 0:
        document.title = "Message History: -- Picklers";
        break;
      case 1:
        document.title = "Group Discussion -- Picklers";
        break;
      case 2:
        document.title = "Event Comments -- Picklers";
        break;
    }
  }, [tabIndex]);

  return (
    <>
      <ScreenContainer horizontalPadding={0}>
        <InboxScreenLayout
          tabIndex={tabIndex}
          onTabSelect={onTabSelect}
          dmThreadsResult={dmThreadsResult}
          eventThreadsResult={eventThreadsResult}
          groupThreadsResult={groupThreadsResult}
        />
      </ScreenContainer>
    </>
  );
};
