import React, { useEffect, useState } from "react";
import { GroupFull } from "../../../types";
import { ListSpinner } from "../../../components/general/ListSpinner";
import { GroupCard } from "../../Groups/Components/GroupCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { UseInfiniteQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { goToGroupDetails } from "../../../utils/navUtil";
import { EmptyLayout } from "../../../components/layouts/EmptyLayout";
import { ReactComponent as EmptyGroups } from "../../../assets/images/empty-state/empty-groups.svg";
import { ITEM_VERTICAL_SPACING } from "../../../styles/spacing";

type MyGroupListSectionProps = {
  useUsersGroupsResult: UseInfiniteQueryResult<GroupFull[]>;
  infiniteScrollHeight: number;
};

export const MyGroupListSection = (props: MyGroupListSectionProps) => {
  const { useUsersGroupsResult, infiniteScrollHeight } = props;
  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useUsersGroupsResult;

  const [items, setItems] = useState<GroupFull[] | undefined>(undefined);
  const navigate = useNavigate();
  const hasMore = () => {
    const lastPage = data?.pages.slice(-1);
    return (
      !!hasNextPage &&
      !isFetchingNextPage &&
      !!lastPage &&
      lastPage[0] &&
      lastPage[0].length > 0
    );
  };

  const fetchMoreGroups = () => {
    if (hasMore()) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      setItems(data.pages.map((page) => (page ? page : [])).flat());
    } else {
      setItems([]);
    }
  }, [isSuccess, data]);

  return (
    <>
      {isSuccess && items && (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreGroups}
          hasMore={!!hasMore()}
          loader={<h4>Loading...</h4>}
          // height={infiniteScrollHeight}
          style={{ paddingTop: ITEM_VERTICAL_SPACING }}
        >
          {items.length <= 0 && (
            <EmptyLayout
              title="No Groups found"
              subTitle="Check your filters to ensure they match your intended search!"
            >
              <EmptyGroups />
            </EmptyLayout>
          )}
          {items.map((item, index) => (
            <GroupCard
              key={index}
              showLabels={true}
              groupInfo={item}
              onPress={() => goToGroupDetails(navigate, item.id)}
            />
          ))}
          {hasNextPage && isFetchingNextPage && <ListSpinner />}
        </InfiniteScroll>
      )}
    </>
  );
};
