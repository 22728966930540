/* items updatable from the mobile app once created */
/* the username is only updated on account creation for user/pass method. */
/* but for social signin it happens in the second step when account is completed and
   it is specified by Amplify such as 'google_153242456456' */
export interface ProfileUpdateable {
  firstName: string;
  lastName: string;
  skillLevel: string;
  address: string;
  displayAddress: string;
  latitude: number;
  longitude: number;
  timeZone?: string;
}
export interface ProfileUpdate extends Partial<ProfileUpdateable> {}

/* for profiles, there is no difference in create and update */
export interface ProfileCreate extends ProfileUpdateable {
  username: string;
  email: string;
}

/*
 * ProfileFull should match ReturnableUser from the backend
 */
export interface ProfileFull extends ProfileCreate {
  id: string;
  imageUri: string;
  adminLevel: number;
}

export const defaultProfileData: ProfileFull = {
  id: "1",
  username: "someone@somewhere.com",
  email: "someone@somewhere.com",
  firstName: "john",
  lastName: "smith",
  skillLevel: "",
  address: "",
  displayAddress: "",
  adminLevel: 0,
  imageUri: "",
  latitude: 0,
  longitude: 0,
  timeZone: "America/New_York",
};
