import { useQuery } from "react-query";
import axios from "axios";
import { BACKEND_URL } from "../env";
import { ImageUploadData } from "../types";

const fetchPhoto = async (photoId: string) => {
  const { data } = await axios.get(BACKEND_URL + "photos/" + photoId);
  return data;
};
export const usePhoto = (photoId: string) =>
  useQuery(["photo"], () => fetchPhoto(photoId));

const fetchPhotos = async (groupId: string) => {
  const { data } = await axios.get(BACKEND_URL + "photos/group/" + groupId);
  return data;
};
export const usePhotos = (isEnabled: boolean, groupId: string) =>
  useQuery(["photos", groupId], () => fetchPhotos(groupId), {
    enabled: isEnabled,
  });

export const deletePhoto = async (photoId: string) => {
  let result = true;
  result = await axios.delete(BACKEND_URL + "photos/" + photoId);
  return result;
};

export const uploadGroupPhoto = async (
  groupId: string,
  imageData: ImageUploadData
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageData as unknown as File);
  let result;
  result = await axios.post(
    BACKEND_URL + "photos/" + groupId + "/upload",
    formData,
    postHeaders
  );
  return result;
};

export const uploadGroupPhotoFile = async (
  groupId: string,
  imageFile: File
) => {
  let postHeaders = { headers: { "Content-Type": "multipart/form-data" } };
  let formData = new FormData();
  formData.append("file", imageFile, imageFile.name);

  let result;
  result = await axios.post(
    BACKEND_URL + "photos/" + groupId + "/upload",
    formData,
    postHeaders
  );
  return result;
};
