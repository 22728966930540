import React, { useContext, useEffect, useState } from "react";
import { useParams, redirect, useActionData } from "react-router-dom";
import { userContext } from "../../UserContext";
import { groupDetailsQuery, setGroupStatus } from "../../hooks/queryGroups";
import {
  deleteGroupMembership,
  groupMembersLimitedQuery,
  membershipQuery,
  postGroupMembership,
  processGroupMembership,
  useGroupMembersLimited,
  useMembership,
} from "../../hooks/queryMemberships";
import { memberIsAtLeastMember, userIsOrganizer } from "../../utils";
import { Access, EventTypeFilter } from "../../types/enums";
import { GroupDetailsLayout } from "./GroupDetailsLayout";
import { QueryClient, useQuery } from "react-query";
import {
  fdGetBoolean,
  fdGetByEnum,
  fdGetImage,
  fdGetString,
} from "../../utils/formUtils";
import { MemberCreate, MembershipStatusType, MessageUpdate } from "../../types";
import { deletePhoto, uploadGroupPhotoFile } from "../../hooks/queryPhotos";
import { ScreenContainer } from "../../features/ScreenContainer";
import {
  pinnedMessagesQuery,
  updateMessage,
  usePinnedMessages,
} from "../../hooks/queryMessages";
import { eventsQuery, useEvents } from "../../hooks/queryEvents";
import { logSentryError } from "../../utils/sentryUtil";

export type GroupDetailsParams = {
  groupId: string;
};

export type GroupDetailsErrors = {
  generic?: string;
  ok?: boolean;
};

export type GroupEventsFilterInfo = {
  dateFilter: string;
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  skillFilter: number;
  setSkillFilter: React.Dispatch<React.SetStateAction<number>>;
  eventTypeFilter: EventTypeFilter;
  setEventTypeFilter: React.Dispatch<React.SetStateAction<EventTypeFilter>>;
};

const extractPinData = (data: FormData) => {
  return {
    id: fdGetString(data, "messageid"),
    pinned: fdGetBoolean(data, "pinned"),
  } as MessageUpdate;
};

export const groupContextAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const contextAction = data.get("contextAction");
  const defaultErrorMessage =
    "Error while applying the action '" + contextAction + "' to this group";
  try {
    switch (contextAction) {
      // case "edit":
      //   return redirect("/groups/edit/" + params.groupId);
      case "deactivate":
        await setGroupStatus(params.groupId, "inactive");
        queryClient.invalidateQueries(["events"]);
        break;
      case "reactivate":
        await setGroupStatus(params.groupId, "active");
        break;
      case "delete":
        await setGroupStatus(params.groupId, "deleted");
        break;
    }
    queryClient.invalidateQueries(["group", params.groupId]);
    queryClient.invalidateQueries(["groups"]);
    queryClient.invalidateQueries(["memberships-user"]);
  } catch (e) {
    logSentryError(
      "Error applying task '" + contextAction + "' to this group",
      e,
      {
        tags: {
          userId: currentUserId,
          screen: "GroupDetailsScreen",
          function: "groupContextAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }

  return { ok: true };
};

export const memberContextAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const contextAction = fdGetString(data, "contextAction");
  const membershipId = fdGetString(data, "auxId");
  const defaultErrorMessage =
    "Error while applying the action '" + contextAction + "' to this member.";
  try {
    switch (contextAction) {
      case "remove":
      case "leave":
        await deleteGroupMembership(membershipId);
        queryClient.invalidateQueries(["group", params.groupId]);
        queryClient.invalidateQueries(["memberships-user"]);
        queryClient.invalidateQueries(["groups"]);
        break;
      case "accept":
        await processGroupMembership({
          memberId: membershipId,
          status: "accepted",
        });
        queryClient.invalidateQueries(["group", params.groupId]);
        queryClient.invalidateQueries(["groups"]);
        break;
      case "reject":
        await deleteGroupMembership(membershipId);
        break;
    }
    queryClient.invalidateQueries(["memberships", params.groupId]);
    queryClient.invalidateQueries(["memberships-group", params.groupId]);
    queryClient.invalidateQueries(["friends", currentUserId]);
  } catch (e) {
    logSentryError(
      "Error applying task '" + contextAction + "' to this member",
      e,
      {
        tags: {
          userId: currentUserId,
          screen: "GroupDetailsScreen",
          function: "memberContextAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }
  return { ok: true };
};

export const deletePhotoAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const photoId = await fdGetString(data, "photoid");
  const action = await fdGetString(data, "action");
  const defaultErrorMessage =
    "Error while applying the action '" + action + "' to this photo.";

  if (!photoId || !action) return { status: "false" };

  try {
    if (action === "delete") await deletePhoto(photoId);
    queryClient.invalidateQueries(["photos", params.groupId]);
  } catch (e) {
    logSentryError("Error deleting a group photo.", e, {
      tags: {
        userId: currentUserId,
        screen: "GroupDetailsScreen",
        function: "deletePhotoAction",
      },
      level: "error",
    });

    return { ok: false, generic: defaultErrorMessage };
  }
  return { ok: true };
};

export const groupDetailsAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const photo = await fdGetImage(data, "photo");
  const defaultErrorMessage =
    "Error while uploading this photo to the group album.";

  if (!photo) return { status: "ok" };
  try {
    await uploadGroupPhotoFile(params.groupId, photo);
    queryClient.invalidateQueries(["photos", params.groupId]);
  } catch (e) {
    logSentryError("Error uploading a group photo.", e, {
      tags: {
        userId: currentUserId,
        groupId: params?.groupId,
        screen: "GroupDetailsScreen",
        function: "groupDetailsAction",
      },
      level: "error",
    });
    return { ok: false, generic: defaultErrorMessage };
  }
  return { ok: true };
};

export const memberJoinAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const defaultErrorMessage = "Error while joining this group.";
  try {
    const userId = fdGetString(data, "userid");
    const status = fdGetByEnum<MembershipStatusType>(data, "status");
    const newMember: MemberCreate = {
      groupId: params.groupId,
      userId,
      status,
    };
    await postGroupMembership(newMember);
    queryClient.invalidateQueries(["memberships", params.groupId, userId]);
    queryClient.invalidateQueries(["memberships-user"]);
    queryClient.invalidateQueries(["memberships-group", params.groupId]);
    queryClient.invalidateQueries(["group", params.groupId]);
    queryClient.invalidateQueries(["groups"]);
    queryClient.invalidateQueries(["friends", userId]);
  } catch (e) {
    logSentryError("Error Joining a Group.", e, {
      tags: {
        userId: currentUserId,
        screen: "GroupDetailsScreen",
        function: "memberJoinAction",
      },
      level: "error",
    });
    return { ok: false, generic: defaultErrorMessage };
  }
  return { ok: true };
};

export const unpinAction = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const messageUpdate = extractPinData(data);
  const defaultErrorMessage =
    "Error while unpinning this message from the bulletin board.";

  if (!params.groupId || !messageUpdate.id) return { ok: false };

  try {
    // console.log("doing messageUpdate");
    await updateMessage(messageUpdate);
    queryClient.invalidateQueries(["messages"]);
    queryClient.invalidateQueries(["pinnedmessages"]);
  } catch (e) {
    logSentryError("Error Pinning a Message.", e, {
      tags: {
        userId: currentUserId,
        screen: "GroupDetailsScreen",
        function: "unpinAction",
      },
      level: "error",
    });
    return { ok: false, generic: defaultErrorMessage };
  }

  return { ok: true };
};
export const groupDetailsLoader = async (
  params: GroupDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  const groupId = params?.groupId;

  if (!!currentUserId && !!groupId) {
    const query = groupDetailsQuery(currentUserId, groupId);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query));

    const query2 = membershipQuery(
      !!groupId && !!currentUserId,
      groupId,
      currentUserId
    );
    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchQuery(query2));

    const query3 = pinnedMessagesQuery(
      !!currentUserId && !!groupId,
      "group",
      groupId,
      5
    );
    queryClient.getQueryData(query3.queryKey) ??
      (await queryClient.fetchQuery(query3));

    const query4 = groupMembersLimitedQuery(
      !!currentUserId && !!groupId,
      groupId
    );
    queryClient.getQueryData(query4.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query4));

    const query5 = groupMembersLimitedQuery(
      !!currentUserId && !!groupId,
      groupId,
      "applied"
    );
    queryClient.getQueryData(query5.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query5));

    const query6 = eventsQuery(
      !!currentUserId && !!groupId,
      groupId,
      undefined,
      "Upcoming"
    );
    queryClient.getQueryData(query6.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query6));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }

  return { ok: true };
};

export const GroupDetailsScreen = () => {
  const currentUserInfo = useContext(userContext);
  const params = useParams();
  const errors = useActionData() as GroupDetailsErrors;

  const groupId = params && params.groupId ? params.groupId : "";
  const currentUserId = currentUserInfo.id;
  const isAdmin = currentUserInfo.adminLevel > 90;
  const [selectedImage, setSelectedImage] = useState("");
  const [eventsDateFilter, setEventsDateFilter] = useState("Upcoming");
  const [skillFilter, setSkillFilter] = useState<number>(2.0);
  const [eventTypeFilter, setEventTypeFilter] = useState<EventTypeFilter>(
    EventTypeFilter.Any
  );
  const groupEventsFilterInfo: GroupEventsFilterInfo = {
    dateFilter: eventsDateFilter,
    setDateFilter: setEventsDateFilter,
    skillFilter: skillFilter,
    setSkillFilter: setSkillFilter,
    eventTypeFilter: eventTypeFilter,
    setEventTypeFilter: setEventTypeFilter,
  };
  // Our main group details data
  const groupQueryResult = useQuery(
    groupDetailsQuery(currentUserInfo.id, params?.groupId)
  );

  // This user's membership data in the group, if any
  const membershipQueryResult = useMembership(
    !!params?.groupId && !!currentUserId,
    groupId,
    currentUserId
  );

  const pinnedMessagesQueryResult = usePinnedMessages(
    !!currentUserId && !!groupId,
    "group",
    groupId,
    5
  );

  // Current applicants to this group
  const membersQueryResult = useGroupMembersLimited(
    !!groupId && !!currentUserId,
    groupId
  );

  // Current applicants to this group
  const applicantsQueryResult = useGroupMembersLimited(
    !!groupId &&
      (userIsOrganizer(currentUserId, groupQueryResult.data) ||
        currentUserInfo.adminLevel > 90),
    groupId,
    "applied"
  );

  const groupEventsQueryResult = useEvents(
    true,
    groupId,
    undefined,
    eventsDateFilter,
    undefined,
    undefined,
    undefined,
    skillFilter > 2 ? skillFilter : 0,
    undefined,
    eventTypeFilter === EventTypeFilter.Any ? undefined : eventTypeFilter,
    undefined
  );

  useEffect(() => {
    if (groupQueryResult.isSuccess && groupQueryResult.data) {
      document.title = "Group Details: " + groupQueryResult.data.name;
    }
  }, [groupQueryResult]);

  const canViewDetails =
    memberIsAtLeastMember(membershipQueryResult.data) ||
    isAdmin ||
    (groupQueryResult.data && groupQueryResult.data.access === Access.Public);

  return (
    <ScreenContainer horizontalPadding={0}>
      <GroupDetailsLayout
        groupQueryResult={groupQueryResult}
        membershipQueryResult={membershipQueryResult}
        pinnedMessagesQueryResult={pinnedMessagesQueryResult}
        membersQueryResult={membersQueryResult}
        applicantsQueryResult={applicantsQueryResult}
        groupEventsQueryResult={groupEventsQueryResult}
        groupEventsFilterInfo={groupEventsFilterInfo}
        canViewDetails={canViewDetails}
        errors={errors}
      />
    </ScreenContainer>
  );
};
