import React, { useEffect, useState } from "react";
// import "./App.css";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { REDIRECT_URL } from "./env";
import useCachedResources from "./hooks/useCachedResources";
import { PicklersRouter } from "./PicklersRouter";
import { CognitoUser } from "@aws-amplify/auth";
import { userContext, userContextType } from "./UserContext";
import { defaultLatitude, defaultLongitude } from "./constants/defaultLocation";
import {
  ThreadLastReadContextType,
  initialThreadLastReadContext,
  threadLastReadContext,
} from "./screens/Inbox/Components/ThreadLastReadContext";
import { H2, TextRegular } from "./styles/text";
import { HorizontalContainer } from "./styles/container";
import { SECTION_VERTICAL_SPACING } from "./styles/spacing";
import { guessTimeZone } from "./utils";

var configUpdate = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: REDIRECT_URL ? REDIRECT_URL : "",
    redirectSignOut: REDIRECT_URL ? REDIRECT_URL : "",
  },
};
Amplify.configure(configUpdate);

function App() {
  const { isLoadingComplete, authUser, userData } = useCachedResources();
  const [ready, setReady] = useState(false);
  const setNewUser = (
    id: string,
    username: string,
    firstName: string,
    lastName: string,
    imageUri: string,
    adminLevel: number,
    address: string,
    displayAddress: string,
    latitude: number,
    longitude: number,
    timeZone: string,
    user: CognitoUser | null,
    hasUnread: boolean
  ) => {
    setUserInfo({
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      imageUri: imageUri,
      adminLevel: adminLevel,
      address: address,
      displayAddress: displayAddress,
      latitude: latitude,
      longitude: longitude,
      timeZone: timeZone,
      user: user,
      hasUnread: hasUnread,
      switchUser: setNewUser,
    });
  };
  const [userInfo, setUserInfo] = useState<userContextType>({
    id: userData ? userData.id : "",
    username: userData ? userData.username : "testusername",
    firstName: userData ? userData.firstName : "Iam",
    lastName: userData ? userData.lastName : "Nobody",
    imageUri: userData ? userData.imageUri : "",
    adminLevel: userData ? userData.adminLevel : 0,
    address: userData ? userData.address : "",
    displayAddress: userData ? userData.displayAddress : "",
    latitude: userData ? userData.latitude : defaultLatitude,
    longitude: userData ? userData.longitude : defaultLongitude,
    timeZone:
      userData && userData.timeZone ? userData.timeZone : guessTimeZone(),
    user: authUser,
    hasUnread: false,
    switchUser: setNewUser,
  });

  // set up threadLastReadContext, which keeps track of the last read message
  // for each message thread involving the current user
  const saveThreadLastReadInfo = (
    threadLastReadInfo: ThreadLastReadContextType
  ) => {
    setThreadLastReadData({
      ...threadLastReadInfo,
      setLastReadData: saveThreadLastReadInfo,
    });
  };
  const [threadLastReadData, setThreadLastReadData] =
    useState<ThreadLastReadContextType>({
      ...initialThreadLastReadContext,
      setLastReadData: saveThreadLastReadInfo,
    });

  useEffect(() => {
    if (isLoadingComplete) {
      if (userData && authUser) {
        setNewUser(
          userData.id,
          userData.username,
          userData.firstName,
          userData.lastName,
          userData.imageUri,
          userData.adminLevel,
          userData.address,
          userData.displayAddress,
          userData.latitude,
          userData.longitude,
          userData.timeZone ?? guessTimeZone(),
          authUser,
          false
        );
      }
      setReady(true);
    }
  }, [isLoadingComplete, authUser, userData]);

  return (
    <>
      <userContext.Provider value={userInfo}>
        <threadLastReadContext.Provider value={threadLastReadData}>
          {ready ? (
            <PicklersRouter priorAuthUser={authUser} priorUserData={userData} />
          ) : (
            <HorizontalContainer style={{ marginTop: 150 }}>
              <div style={{ textAlign: "center" }}>
                <H2 style={{ marginBottom: SECTION_VERTICAL_SPACING }}>
                  Please Wait...
                </H2>
                <TextRegular> Loading account information.</TextRegular>
              </div>
            </HorizontalContainer>
          )}
        </threadLastReadContext.Provider>
      </userContext.Provider>
    </>
  );
}

export default App;
