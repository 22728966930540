const DEV_BACKEND_URL=process.env.REACT_APP_DEV_BACKEND_URL;
const DEV_REDIRECT_URL=process.env.REACT_APP_DEV_REDIRECT_URL;

const PREVIEW_BACKEND_URL=process.env.REACT_APP_PREVIEW_BACKEND_URL;
const PREVIEW_REDIRECT_URL=process.env.REACT_APP_PREVIEW_REDIRECT_URL;

const PRODUCTION_BACKEND_URL=process.env.REACT_APP_PRODUCTION_BACKEND_URL;
const PRODUCTION_REDIRECT_URL=process.env.REACT_APP_PRODUCTION_REDIRECT_URL;

// const APP_VARIANT=process.env.REACT_APP_APP_VARIANT;

export const GA_PROPERTY_ID=process.env.REACT_APP_GA_PROPERTY_ID;
export const FACEBOOK_APPID=process.env.REACT_APP_FACEBOOK_APPID;
export const GP_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const GP_SIGNING_SECRET = process.env.REACT_APP_GOOGLE_PLACES_SIGNING_SECRET;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS_CLIENT_KEY;

// const prodBackendUrl = "https://api2.picklers.net/";
// const previewBackendURL = "https://api.picklers.net/";

// Specify one of these backends in .env
export const BACKEND_URL = PRODUCTION_BACKEND_URL ? PRODUCTION_BACKEND_URL : PREVIEW_BACKEND_URL ? PREVIEW_BACKEND_URL : DEV_BACKEND_URL;
// export const BACKEND_URL = DEV_BACKEND_URL;
// export const BACKEND_URL = prodBackendUrl;
// export const BACKEND_URL = previewBackendURL;
// export const BACKEND_URL = "http://192.168.1.176:3001/";

// this must be set differently by environment for Amplify and for Linking to work
export const REDIRECT_URL = PRODUCTION_REDIRECT_URL ? PRODUCTION_REDIRECT_URL : PREVIEW_REDIRECT_URL ? PREVIEW_REDIRECT_URL : DEV_REDIRECT_URL;
// export const REDIRECT_URL = DEV_REDIRECT_URL;
//export const REDIRECT_URL = prodRedirectUrl;
// export const REDIRECT_URL = previewRedirectURL;
