
export const isPDFUri = (uri?: string) => {
  if (uri && uri.endsWith(".pdf")) {
    return true;
  }
  return false;
}

export const isPdfType = (type: string) => {
  if (type == "application/pdf")
    return true;
  else
    return false;
}

export const extractPDFFilenameFromURL = (url?: string) => {
  
  if (isPDFUri(url)) {
    // Remove the uuid string in front
      const re =
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-(.*)/;
      const result = url?.match(re);
      if (result && result[1]) {
        // uridecode the result
        const filename = decodeURI(result[1]);
        return filename;
      }
  }

  // for anything that doesn't look right, return Unknown.pdf
  return "Unknown.pdf"; 
}

export const handlePDFClick = async (url: string | undefined) => {
    if (!!url) window.open(url,'_blank', 'rel=noopener noreferrer')
}