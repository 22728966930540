import React, { useContext, useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import { userContext } from "../../UserContext";
import { QueryClient } from "react-query";
import { LocationFilterData } from "../../types/locationTypes";
import { getDefaultLocationData } from "../../features/filters/constants";
import { AccessFilter } from "../../types/enums";
import { groupsQuery, useGroups } from "../../hooks/queryGroups";
import { GroupsScreenLayout } from "./GroupsScreenLayout";
import { ScreenContainer } from "../../features/ScreenContainer";
import { Dispatch } from "react";
import { SetStateAction } from "react";
import {
  defaultLatitude,
  defaultLongitude,
} from "../../constants/defaultLocation";

export type GroupsFilterInfo = {
  latitudeFilter: number;
  setLatitudeFilter: Dispatch<SetStateAction<number>>;
  longitudeFilter: number;
  setLongitudeFilter: Dispatch<SetStateAction<number>>;
  mileageFilter: number;
  setMileageFilter: Dispatch<SetStateAction<number>>;
  address: string;
  setAddress: Dispatch<SetStateAction<string>>;
  displayAddress: string;
  setDisplayAddress: Dispatch<SetStateAction<string>>;
  skillFilter: number;
  setSkillFilter: Dispatch<SetStateAction<number>>;
  accessFilter: AccessFilter;
  setAccessFilter: Dispatch<SetStateAction<AccessFilter>>;
  userLocation: LocationFilterData;
  keywordFilter: string;
  setKeywordFilter: Dispatch<SetStateAction<string>>;
};

export const groupsLoader = async (
  request: Request,
  queryClient: QueryClient,
  currentUserId: string,
  latitude: number,
  longitude: number
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = groupsQuery(!!currentUserId, latitude, longitude, 60);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }
  return { status: "ok" };
};

export const GroupsScreen = () => {
  const userInfo = useContext(userContext);
  const userLocation: LocationFilterData = getDefaultLocationData(userInfo);

  const [latitudeFilter, setLatitudeFilter] = useState(userLocation.lat);
  const [longitudeFilter, setLongitudeFilter] = useState(userLocation.long);
  const [mileageFilter, setMileageFilter] = useState(userLocation.mileage);
  const [address, setAddress] = useState(userLocation.addressText);
  const [displayAddress, setDisplayAddress] = useState(
    userLocation.displayAddress
  );
  const [skillFilter, setSkillFilter] = useState<number>(0);
  const [accessFilter, setAccessFilter] = useState(AccessFilter.Any);
  const [keywordFilter, setKeywordFilter] = useState("");

  const groupsQueryResult = useGroups(
    !!userInfo.id,
    !!keywordFilter ? userInfo.latitude ?? defaultLatitude : latitudeFilter,
    !!keywordFilter ? userInfo.longitude ?? defaultLongitude : longitudeFilter,
    !!keywordFilter ? 20000 : mileageFilter,
    undefined,
    !!keywordFilter || skillFilter < 2 ? undefined : skillFilter,
    !!keywordFilter || accessFilter === AccessFilter.Any
      ? undefined
      : accessFilter,
    !!keywordFilter ? keywordFilter : undefined,
    userInfo.adminLevel > 90 ? true : undefined
  );

  useEffect(() => {
    document.title = "Groups -- Picklers";
  }, []);
  return (
    <ScreenContainer horizontalPadding={0}>
      <GroupsScreenLayout
        groupsQueryResult={groupsQueryResult}
        filterInfo={{
          latitudeFilter: latitudeFilter,
          setLatitudeFilter: setLatitudeFilter,
          longitudeFilter: longitudeFilter,
          setLongitudeFilter: setLongitudeFilter,
          mileageFilter: mileageFilter,
          setMileageFilter: setMileageFilter,
          address: address,
          setAddress: setAddress,
          displayAddress: displayAddress,
          setDisplayAddress: setDisplayAddress,
          userLocation: userLocation,
          skillFilter: skillFilter,
          setSkillFilter: setSkillFilter,
          accessFilter: accessFilter,
          setAccessFilter: setAccessFilter,
          keywordFilter: keywordFilter,
          setKeywordFilter: setKeywordFilter,
        }}
      />
    </ScreenContainer>
  );
};
