import React from "react";
import { useRouteError } from "react-router-dom";
import { ReactComponent as ErrorGeneral } from "../../assets/images/empty-state/error-general.svg";
import { ErrorLayout } from "../../components/layouts/ErrorLayout";
import { getErrorMessageFromError } from "../../utils/errors";

export const EventDetailsError = () => {
  const error: any = useRouteError();
  const message = getErrorMessageFromError(error);

  return (
    <ErrorLayout
      overallTitle="Event Details"
      title="An error occurred while loading this event's details."
      subTitle={message}
    >
      <ErrorGeneral />
    </ErrorLayout>
  );
};
