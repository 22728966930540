import React, { useContext, useEffect, useRef, useState } from "react";

import getSkillLevel from "../../../utils/skillUtil";
import { GroupFull } from "../../../types";
import { userIsOrganizer, userIsOwner } from "../../../utils";
import { userContext } from "../../../UserContext";
import { blankGroupImageURL } from "../../../constants/defaultImageURLs";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../../styles/container";
import { Emoji, H2, TextRegular, TextSmall } from "../../../styles/text";
import { COLORS, DARK_SECONDARY_TEXT } from "../../../styles/colors";
import { GeneralAttribute } from "../../../components/attributes/GeneralAttribute";
import "./GroupCard.css";
import { LINE_VERTICAL_SPACING } from "../../../styles/spacing";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigation } from "react-router-dom";
import { Access, GroupStatus } from "../../../types/enums";

type GroupCardProps = {
  showLabels: boolean;
  groupInfo: GroupFull;
  onPress: () => void;
};

export const GroupCard = (props: GroupCardProps) => {
  const userInfo = useContext(userContext);
  const textDivRef = useRef<HTMLDivElement>(null);
  const navigation = useNavigation();
  const [height, setHeight] = useState(120);
  const colors = COLORS["light"];
  const currentUserId = userInfo ? userInfo.id : "";
  const skillString = getSkillLevel(
    props.groupInfo.minSkill,
    props.groupInfo.maxSkill
  );

  useEffect(() => {
    if (!textDivRef.current) return;
    setHeight(textDivRef.current.offsetHeight);
  }, []);

  const showDeactivated = () => {
    return props.groupInfo.status !== GroupStatus.Active;
  };

  const showLocked = () => {
    return !showDeactivated() && props.groupInfo.access == "Private";
  };

  const showHidden = () => {
    return !showDeactivated() && props.groupInfo.access == "Hidden";
  };

  return (
    <button
      onClick={props.onPress}
      className="card-button"
      style={{ width: "100%", display: "grid" }}
      // style={{ width: "100%", position: "relative" }}
    >
      <HorizontalSpaceBetweenContainer
        style={{
          gridArea: "1/1",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "20px 16px 20px 16px",
            flex: 1,
            justifyContent: "space-between",
          }}
          ref={textDivRef}
        >
          {navigation.state === "loading" ? (
            <Skeleton count={0.3} height={18} />
          ) : (
            <HorizontalSpaceBetweenContainer>
              <HorizontalLeftAlignedContainer>
                <H2>{props.groupInfo.name}</H2>
              </HorizontalLeftAlignedContainer>

              {props.showLabels &&
              userIsOwner(currentUserId, props.groupInfo) ? (
                <div className="group-user-status-label">
                  <TextSmall color={colors.SECONDARY_TEXT}>Leader</TextSmall>
                </div>
              ) : (
                props.showLabels &&
                userIsOrganizer(currentUserId, props.groupInfo) && (
                  <div className="group-user-status-label">
                    <TextSmall color={colors.SECONDARY_TEXT}>
                      Organizer
                    </TextSmall>
                  </div>
                )
              )}
            </HorizontalSpaceBetweenContainer>
          )}
          <div style={{ height: LINE_VERTICAL_SPACING }} />
          {navigation.state === "loading" ? (
            <Skeleton count={0.5} height={14} />
          ) : (
            <HorizontalLeftAlignedContainer
              style={{ width: "100%", flexWrap: "wrap" }}
            >
              <GeneralAttribute
                text={`${props.groupInfo.memberCount.toString()} Members`}
              />
              <p className="dot-separator">·</p>
              <GeneralAttribute
                text={props.groupInfo.access}
                iconName={
                  props.groupInfo.access !== "Hidden"
                    ? props.groupInfo.access === "Private"
                      ? "lock-closed"
                      : "lock-open"
                    : undefined
                }
                emoji={
                  props.groupInfo.access === Access.Hidden ? "🛡️" : undefined
                }
              />
              <p className="dot-separator">·</p>
              <GeneralAttribute
                text={`${skillString} Skill Level`}
                iconName="flash"
              />
              {/* <TextSmall color="gray">
                  {props.groupInfo.memberCount.toString()} Members ·{" "}
                  {skillString !== "Any" ? `${skillString} Skill Level · ` : ""}
                  {props.groupInfo.address}
                </TextSmall> */}
            </HorizontalLeftAlignedContainer>
          )}
          <div style={{ height: LINE_VERTICAL_SPACING }} />
          {navigation.state === "loading" ? (
            <Skeleton count={0.4} height={14} />
          ) : (
            <HorizontalLeftAlignedContainer>
              <TextRegular color={DARK_SECONDARY_TEXT}>
                {props.groupInfo.displayAddress}
              </TextRegular>
            </HorizontalLeftAlignedContainer>
          )}
        </div>

        {navigation.state === "loading" ? (
          <div style={{ width: 200, height: height, marginTop: -3 }}>
            <Skeleton height="100%" borderRadius={0} />
          </div>
        ) : (
          <img
            src={
              props.groupInfo.imageUri && props.groupInfo.imageUri !== ""
                ? props.groupInfo.imageUri
                : blankGroupImageURL
            }
            alt=""
            width={200}
            height={height}
            style={{
              objectFit: "cover",
              borderRadius: "0px 8px 8px 0px",
            }}
          />
        )}
      </HorizontalSpaceBetweenContainer>
      {showDeactivated() && (
        <div
          style={{
            // position: "absolute",
            // top: 0,
            // left: 0,
            // bottom: 0,
            // right: 0,
            width: "100%",
            height: "100%",
            gridArea: "1/1",
            backgroundColor: "#ffffff90",
            // zIndex: 100,
          }}
        />
      )}
    </button>
  );
};
