import Skeleton from "react-loading-skeleton";
import { LocationFilterButton } from "../../../features/filters/LocationFilterButton";
import { FilterContainer } from "../../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_VERTICAL_SPACING,
} from "../../../styles/spacing";
import { GroupsFilterInfo } from "../GroupsScreen";
import { KeywordFilterButton } from "../../../features/filters/KeywordFilterButton";
import { useState } from "react";
import { getBorderRadius } from "../../../utils";
import { SkillFilterButton } from "../../../features/filters/SkillFilterButton";
import { AccessFilter } from "../../../types/enums";
import { GroupAccessFilterButton } from "../../../features/filters/GroupAccessFilterButton";

type GroupsFilterSectionProps = {
  filterInfo?: GroupsFilterInfo;
  disabled?: boolean;
};

export const GroupsFilterSection = (props: GroupsFilterSectionProps) => {
  const { filterInfo } = props;
  // const [searchIsOpen, setSearchIsOpen] = useState(false);

  return (
    <>
      <FilterContainer
        style={{
          marginTop: ITEM_VERTICAL_SPACING,
          marginBottom: LINE_VERTICAL_SPACING,
        }}
      >
        {filterInfo ? (
          <LocationFilterButton
            latitudeFilter={filterInfo.latitudeFilter}
            longitudeFilter={filterInfo.longitudeFilter}
            mileageFilter={filterInfo.mileageFilter}
            address={filterInfo.address}
            displayAddress={filterInfo.displayAddress}
            setLatitudeFilter={filterInfo.setLatitudeFilter}
            setLongitudeFilter={filterInfo.setLongitudeFilter}
            setMileageFilter={filterInfo.setMileageFilter}
            setAddress={filterInfo.setAddress}
            setDisplayAddress={filterInfo.setDisplayAddress}
            defaultLocation={filterInfo.userLocation}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        {filterInfo ? (
          <SkillFilterButton
            value={filterInfo.skillFilter}
            setValue={filterInfo.setSkillFilter}
            defaultValue={2.0}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
        <div style={{ width: ITEM_HORIZONTAL_SPACING }} />
        {filterInfo ? (
          <GroupAccessFilterButton
            value={filterInfo.accessFilter}
            setValue={filterInfo.setAccessFilter}
            defaultValue={AccessFilter.Any}
          />
        ) : (
          <div style={{ width: 150 }}>
            <Skeleton
              height={32}
              borderRadius={getBorderRadius("large", "rounded rect")}
            />
          </div>
        )}
      </FilterContainer>
    </>
  );
};
