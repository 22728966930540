import { Access, AccessFilter, VenueAccess, VenueStatus } from "../types/enums";
import { VenueTier } from "../types/enums";

export type VenueAccessValue = `${VenueAccess}`;
export type VenueAccessFilterValue = `${AccessFilter}`;

// All the necessary items to create a Venue
export interface VenueCreate {
  name: string;
  nickname: string;
  address: string;
  displayAddress: string;
  latitude: number;
  longitude: number;
  description: string;
  contactPhone: string;
  contactEmail: string;
  contactName: string;
  dedicatedCourts: number; // deprecated
  sharedCourts: number; // deprecated
  indoorCourts: boolean; // deprecated
  outdoorCourts: boolean; // deprecated
  numIndoorCourts: number;
  numOutdoorCourts: number;
  parking: boolean;
  restrooms: boolean;
  permanentNets: boolean;
  permanentLines: boolean;
  outdoorLighting: boolean;
  openHours: string;
  access: VenueAccess;
  website: string;
  verificationStatus: boolean;
  tier: VenueTier;
  owner: string;
  lastEditedBy: string;
  timeZone?: string;
}

// VenueUpdate is VenueCreate where all fields are optional
export interface VenueUpdate extends Partial<VenueCreate> {}

/*
 * VenueFull should match ReturnableVenue from the backend
 */
export interface VenueFull extends VenueCreate {
  imageUri: string;
  id: string;
  status: VenueStatus;
}

// a default venue for convenience
export const defaultVenueDetails: VenueFull = {
  id: "",
  name: "",
  nickname: "",
  address: "",
  displayAddress: "",
  description: "",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
  dedicatedCourts: 0,
  sharedCourts: 0,
  indoorCourts: false,
  outdoorCourts: false,
  numIndoorCourts: 0,
  numOutdoorCourts: 0,
  parking: false,
  restrooms: false,
  permanentNets: false,
  permanentLines: false,
  outdoorLighting: false,
  openHours: "",
  access: VenueAccess.Public,
  website: "",
  imageUri: "",
  verificationStatus: false,
  tier: VenueTier.Free,
  owner: "",
  latitude: 0,
  longitude: 0,
  lastEditedBy: "",
  status: VenueStatus.Active,
  timeZone: "America/New_York",
};
