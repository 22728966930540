import React, { useContext, useEffect, useState } from "react";
import { GroupFull, MemberFull, PeopleData } from "../../../types";
import { TextRegular } from "../../../styles/text";
import { DetailsActionButtonBar } from "../../../features/actionBars/DetailsActionButtonBar";
import { REDIRECT_URL } from "../../../env";
import { ShareButton } from "../../../features/ShareButton";
import { useFetcher, useNavigate, useNavigation } from "react-router-dom";
import {
  ContextMenuItem,
  NewCustomContextMenu,
} from "../../../features/CustomContextMenu/NewCustomContextMenu";
import { goToEditGroup, goToReport } from "../../../utils/navUtil";
import Drawer from "react-modern-drawer";
import { RecipientPicker } from "../../../features/TitledRecipientPicker/RecipientPicker";
import { sendGroupBotMessage } from "../../../hooks/queryMessages";
import { userContext } from "../../../UserContext";
import { confirmAlert } from "react-confirm-alert";
import { CustomAlertUI } from "../../../components/confirmDialogs/CustomAlertUI";
import {
  useGroupMembersAll,
  useUsersFriends,
} from "../../../hooks/queryMemberships";

type GroupDetailsActionButtonProps = {
  canEdit: boolean;
  canChangeStatus: boolean;
  canShare: boolean;
  groupDetails: GroupFull;
};
export const GroupDetailsActionButtons = (
  props: GroupDetailsActionButtonProps
) => {
  const { groupDetails, canEdit, canChangeStatus, canShare } = props;
  const userInfo = useContext(userContext);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const fetcher = useFetcher();
  const [showRecipientPicker, setShowRecipientPicker] = useState(false);
  const [recipients, setRecipients] = useState<PeopleData[]>([]);
  const { data: membersData } = useGroupMembersAll(canEdit, groupDetails.id);
  const ineligible: string[] = [];
  if (membersData) {
    membersData.forEach((membership: MemberFull) => {
      if (membership.status !== "applied") ineligible.push(membership.userId);
    });
  }
  const shareUri = `${REDIRECT_URL}group/view/${groupDetails.id}`;

  const canBeEdited = groupDetails.status === "active";
  const canBeDeactivated = groupDetails.status === "active";
  const canBeReactivated = groupDetails.status === "inactive";
  const canBeDeleted = groupDetails.status === "inactive";
  const returnUri = `/group/view/${groupDetails.id}/`;

  const toggleRecipientPickerDrawer = () => {
    if (navigation.state !== "loading")
      setShowRecipientPicker((isOpen) => {
        return !isOpen;
      });
  };

  const doEditGroup = () => {
    goToEditGroup(navigate, groupDetails.id);
  };
  // const doInviteAppUsers = () => {
  //   console.log("invite new appusers");
  // };

  const doReport = () => {
    const reportQueryString = `?targetType=group&targetId=${groupDetails.id}&targetName=${groupDetails.name}&returnUri=${returnUri}`;
    goToReport(navigate, reportQueryString);
  };

  const doQuickInvite = () => {
    setShowRecipientPicker(true);
  };

  const deactivateGroup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Deactivate this group?"}
            content={
              "All upcoming events will be canceled as a result of this action. Are you sure you want to deactivate this group?"
            }
            yesButton="Deactivate"
            noButton="Cancel"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "deactivate" },
                { method: "POST", action: "groupContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const reactivateGroup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Reactivate this group?"}
            content={"Are you sure you want to reactivate this group?"}
            yesButton="Reactivate"
            noButton="Cancel"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "reactivate" },
                { method: "POST", action: "groupContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };
  const deleteGroup = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CustomAlertUI
            title={"Delete this group?"}
            content={
              "Are you sure you want to delete this group?  This action will cancel all memberships and eliminate all traces of this group from the database.  This CANNOT be undone."
            }
            yesButton="Delete"
            noButton="Cancel"
            yesButtonClick={() => {
              fetcher.submit(
                { contextAction: "delete" },
                { method: "POST", action: "groupContextAction" }
              );
              onClose();
            }}
            noButtonClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
    });
  };

  const groupActionButtons: ContextMenuItem[] = [];

  if (canEdit && canBeEdited) {
    groupActionButtons.push({
      label: <TextRegular>Edit This Group</TextRegular>,
      value: "edit",
      action: doEditGroup,
    });
    // groupActionButtons.push({
    //   label: <TextRegular>Invite New App Users</TextRegular>,
    //   value: "invite",
    //   action: doInviteAppUsers,
    // });
    groupActionButtons.push({
      label: <TextRegular>Quick Invite</TextRegular>,
      value: "quickinvite",
      action: doQuickInvite,
    });
  }
  if (canChangeStatus && canBeDeactivated) {
    groupActionButtons.push({
      label: <TextRegular>Deactivate This Group</TextRegular>,
      value: "deactivate",
      action: deactivateGroup,
    });
  }
  if (canChangeStatus && canBeReactivated) {
    groupActionButtons.push({
      label: <TextRegular>Reactivate This Group</TextRegular>,
      value: "reactivate",
      action: reactivateGroup,
    });
  }
  if (canChangeStatus && canBeDeleted) {
    groupActionButtons.push({
      label: <TextRegular>Delete This Group</TextRegular>,
      value: "delete",
      action: deleteGroup,
    });
  }
  groupActionButtons.push({
    label: <TextRegular>Report This Group</TextRegular>,
    value: "report",
    action: doReport,
  });

  useEffect(() => {
    const doInvites = (recipients: PeopleData[]) => {
      const userIds: string[] = [];
      recipients.forEach((recipient) => {
        if (recipient.userId !== userInfo.id) {
          userIds.push(recipient.userId);
        }
      });
      const messageText = `${userInfo.firstName} ${userInfo.lastName} has invited you to join the group "${groupDetails.name}" [groupInvite groupId="${groupDetails.id}"]`;
      sendGroupBotMessage(messageText, userIds);
      setRecipients([]);
      alert(`${userIds.length} invitations to this group have been sent.`);
    };

    if (recipients.length > 0) {
      doInvites(recipients);
    }
  }, [recipients, userInfo, groupDetails]);

  const peopleQueryResult = useUsersFriends(userInfo.id);

  return (
    <DetailsActionButtonBar backButtonTitle="Back">
      {canShare && (
        <ShareButton
          shareDialogTitle={"Share This Group"}
          shareTitle={`${groupDetails.name} on Picklers`}
          shareMessage={`I'd like to share "${groupDetails.name}" with you on Picklers!`}
          shareUrl={shareUri}
        />
      )}
      <Drawer
        open={showRecipientPicker}
        onClose={() => toggleRecipientPickerDrawer()}
        direction="right"
        lockBackgroundScroll={true}
        size={500}
        zIndex={2000}
      >
        <RecipientPicker
          friendsQueryResult={peopleQueryResult}
          isOpen={showRecipientPicker}
          closePicker={() => setShowRecipientPicker(false)}
          pickedRecipients={recipients}
          setPickedRecipients={setRecipients}
          ineligible={ineligible}
        />
      </Drawer>
      <NewCustomContextMenu contextMenuItems={groupActionButtons} />
    </DetailsActionButtonBar>
  );
};
