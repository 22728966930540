import React, { useContext, useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import "react-modern-drawer/dist/index.css";

import { userContext } from "../../UserContext";
import { redirect, useSearchParams } from "react-router-dom";
import { QueryClient } from "react-query";
import { eventsQuery, useEvents } from "../../hooks/queryEvents";
import { ScreenContainer } from "../../features/ScreenContainer";
import "./EventsScreen.css";
import { EventsScreenLayout } from "./EventsScreenLayout";
import { useUsersRSVPs, usersRSVPsQuery } from "../../hooks/queryAttendees";
import { useUsersGroups, usersGroupsQuery } from "../../hooks/queryMemberships";
import { GroupFull } from "../../types";
import { CourtType, EventTypeFilter } from "../../types/enums";
import { LocationFilterData } from "../../types/locationTypes";
import { getDefaultLocationData } from "../../features/filters/constants";

export type MyEventsFilterInfo = {
  dateFilter: string;
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  groupFilter: GroupFull | undefined;
  setGroupFilter: React.Dispatch<React.SetStateAction<GroupFull | undefined>>;
};

export type DiscoverEventsFilterInfo = {
  latitudeFilter: number;
  setLatitudeFilter: React.Dispatch<React.SetStateAction<number>>;
  longitudeFilter: number;
  setLongitudeFilter: React.Dispatch<React.SetStateAction<number>>;
  mileageFilter: number;
  setMileageFilter: React.Dispatch<React.SetStateAction<number>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  displayAddress: string;
  setDisplayAddress: React.Dispatch<React.SetStateAction<string>>;
  dateFilter: string;
  setDateFilter: React.Dispatch<React.SetStateAction<string>>;
  skillFilter: number;
  setSkillFilter: React.Dispatch<React.SetStateAction<number>>;
  groupFilter: GroupFull | undefined;
  setGroupFilter: React.Dispatch<React.SetStateAction<GroupFull | undefined>>;
  courtTypeFilter: CourtType;
  setCourtTypeFilter: React.Dispatch<React.SetStateAction<CourtType>>;
  eventTypeFilter: EventTypeFilter;
  setEventTypeFilter: React.Dispatch<React.SetStateAction<EventTypeFilter>>;
  userLocation: LocationFilterData;
};

export const eventsLoader = async (
  queryClient: QueryClient,
  request: Request,
  latitude: number,
  longitude: number,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = eventsQuery(
      true,
      undefined,
      undefined,
      "Upcoming",
      latitude,
      longitude,
      60,
      0,
      undefined,
      undefined,
      currentUserId
    );
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query));

    const query2 = usersRSVPsQuery(true, currentUserId);
    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query2));

    const query3 = usersGroupsQuery(true, currentUserId, 1000, true, undefined);
    queryClient.getQueryData(query3.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query3));

    // const query4 = usersConflictsQuery(true, currentUserId);
    // queryClient.getQueryData(query4.queryKey) ??
    //   (await queryClient.fetchInfiniteQuery(query4));
  } else {
    return redirect("/");
  }

  return { status: "ok" };
};

export const EventsScreen = () => {
  const userInfo = useContext(userContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const tabNum = tab ? parseInt(tab) : 0;
  const [tabIndex, setTabIndex] = useState(tabNum);
  const [myEventsDateFilter, setMyEventsDateFilter] = useState("Upcoming");
  const [myEventsGroupFilter, setMyEventsGroupFilter] = useState<
    GroupFull | undefined
  >(undefined);
  const [discoverDateFilter, setDiscoverDateFilter] = useState("Upcoming");
  const [discoverGroupFilter, setDiscoverGroupFilter] = useState<
    GroupFull | undefined
  >(undefined);
  const [courtTypeFilter, setCourtTypeFilter] = useState<CourtType>(
    CourtType.Any
  );
  const [eventTypeFilter, setEventTypeFilter] = useState<EventTypeFilter>(
    EventTypeFilter.Any
  );
  const [skillFilter, setSkillFilter] = useState<number>(2.0);
  const userLocation: LocationFilterData = getDefaultLocationData(userInfo);
  const [latitudeFilter, setLatitudeFilter] = useState(userLocation.lat);
  const [longitudeFilter, setLongitudeFilter] = useState(userLocation.long);
  const [mileageFilter, setMileageFilter] = useState(userLocation.mileage);
  const [address, setAddress] = useState(userLocation.addressText);
  const [displayAddress, setDisplayAddress] = useState(
    userLocation.displayAddress
  );
  const myEventsFilterInfo: MyEventsFilterInfo = {
    dateFilter: myEventsDateFilter,
    setDateFilter: setMyEventsDateFilter,
    groupFilter: myEventsGroupFilter,
    setGroupFilter: setMyEventsGroupFilter,
  };

  const discoverFilterInfo: DiscoverEventsFilterInfo = {
    latitudeFilter: latitudeFilter,
    setLatitudeFilter: setLatitudeFilter,
    longitudeFilter: longitudeFilter,
    setLongitudeFilter: setLongitudeFilter,
    mileageFilter: mileageFilter,
    setMileageFilter: setMileageFilter,
    address: address,
    setAddress: setAddress,
    displayAddress: displayAddress,
    setDisplayAddress: setDisplayAddress,
    userLocation: userLocation,
    dateFilter: discoverDateFilter,
    setDateFilter: setDiscoverDateFilter,
    skillFilter: skillFilter,
    setSkillFilter: setSkillFilter,
    courtTypeFilter: courtTypeFilter,
    setCourtTypeFilter: setCourtTypeFilter,
    eventTypeFilter: eventTypeFilter,
    setEventTypeFilter: setEventTypeFilter,
    groupFilter: discoverGroupFilter,
    setGroupFilter: setDiscoverGroupFilter,
  };

  const onTabSelect = (newIndex: number) => {
    setSearchParams((params) => {
      params.set("tab", newIndex.toString());
      return params;
    });
    setTabIndex(newIndex);
  };

  const discoverEventsQueryResult = useEvents(
    !!userInfo.id,
    discoverGroupFilter ? discoverGroupFilter.id : undefined,
    undefined,
    discoverDateFilter,
    latitudeFilter,
    longitudeFilter,
    mileageFilter,
    skillFilter > 2 ? skillFilter : 0,
    courtTypeFilter === CourtType.Any ? undefined : courtTypeFilter,
    eventTypeFilter === EventTypeFilter.Any ? undefined : eventTypeFilter,
    userInfo.id
  );

  const useUsersRSVPsResult = useUsersRSVPs(
    !!userInfo.id,
    userInfo.id,
    myEventsDateFilter,
    myEventsGroupFilter ? myEventsGroupFilter.id : undefined
  );

  const useUsersGroupsResult = useUsersGroups(
    !!userInfo.id,
    userInfo.id,
    1000,
    true,
    undefined
  );

  useEffect(() => {
    switch (tabIndex) {
      case 0:
      default:
        document.title = "Discover Events -- Picklers";
        break;
      case 1:
        document.title = "My Events -- Picklers";
        break;
      case 2:
        document.title = "My Groups -- Picklers";
        break;
    }
  }, [tabIndex]);

  return (
    <ScreenContainer tabIndex={tabIndex} horizontalPadding={0}>
      <EventsScreenLayout
        tabIndex={tabIndex}
        onTabSelect={onTabSelect}
        myEventsFilterInfo={myEventsFilterInfo}
        discoverEventsFilterInfo={discoverFilterInfo}
        discoverEventsQueryResult={discoverEventsQueryResult}
        useUsersRSVPsResult={useUsersRSVPsResult}
        useUsersGroupsResult={useUsersGroupsResult}
      />
    </ScreenContainer>
  );
};
