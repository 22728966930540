import { PeopleData } from "./commonTypes";
import { Access, AccessFilter, GroupStatus, GroupTier } from "../types/enums";

export interface GroupUpdateAble {
  name: string;
  description: string;
  code: string;
  address: string;
  displayAddress: string;
  minSkill: number;
  maxSkill: number;
  access: GroupAccessValue;
  latitude: number;
  longitude: number;
}

// VenueUpdate is VenueCreate where all fields are optional
export interface GroupUpdate extends Partial<GroupUpdateAble> {}

export type GroupAccessValue = `${Access}`;
export type GroupAccessFilterValue = `${AccessFilter}`;
export type GroupStatusType = `${GroupStatus}`;
export type GroupTierType = `${GroupTier}`;

export interface GroupLastMessage {
  userId: string;
  firstName: string;
  lastName: string;
  imageUri: string;
  messageText: string;
  timePosted: number;
}

export interface GroupCreate extends GroupUpdateAble {
  ownerId: string;
}

/*
 * GroupFull should match ReturnableGroup from the backend
 */
export interface GroupFull extends GroupCreate {
  id: string;
  assistants: PeopleData[];
  imageUri: string;
  memberList: string[];
  memberCount: number;
  status: GroupStatusType;
  tier: GroupTier;
  distance?: number;
  lastMessage?: GroupLastMessage;
  timeZone?: string;
}

export const defaultGroupDetails: GroupFull = {
  id: "",
  status: GroupStatus.Active,
  tier: GroupTier.Free,
  ownerId: "",
  name: "",
  description: "",
  address: "",
  displayAddress: "",
  code: "",
  minSkill: 0,
  maxSkill: 10,
  access: Access.Public,
  memberList: [],
  memberCount: 0,
  imageUri: "",
  latitude: 0,
  longitude: 0,
  assistants: [],
  distance: 0,
  timeZone: "America/New_York",
};
