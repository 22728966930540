import React, { useContext, useEffect, useRef, useState } from "react";
import { AttendeeFull, EventFull, GroupFull } from "../../types";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  getBorderRadius,
  isBeforeToday,
  userIsHost,
  userIsOrganizer,
  userIsOwner,
} from "../../utils";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { TextRegular } from "../../styles/text";
import { AttendeesList } from "./components/AttendeesList";
import { userContext } from "../../UserContext";
import { useWindowHeight } from "@react-hook/window-size";
import {
  ITEM_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import "./AttendeesScreenLayout.css";
import { useNavigation } from "react-router-dom";
import { UseInfiniteQueryResult, UseQueryResult } from "react-query";
import { AttendeePicker } from "./components/AttendeePicker";
import { StickyActionBar } from "../../components/StickyActionBar";
import { DetailsActionButtonBar } from "../../features/actionBars/DetailsActionButtonBar";
import { NavButton } from "../../components/buttons/NavButton";
import Skeleton from "react-loading-skeleton";
import { LineVerticalSpace } from "../../components/spacing/LineVerticalSpace";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { AttendeeErrors } from "./AttendeesScreen";
import { useEventsConflicts } from "../../hooks/queryAttendees";

type AttendeesScreenLayoutProps = {
  groupQueryResult: UseQueryResult<GroupFull>;
  eventQueryResult: UseQueryResult<EventFull>;
  goingQueryResult: UseInfiniteQueryResult<AttendeeFull[]>;
  notGoingQueryResult: UseInfiniteQueryResult<AttendeeFull[]>;
  waitlistQueryResult: UseInfiniteQueryResult<AttendeeFull[]>;
  errors?: AttendeeErrors;
};

export const AttendeesScreenLayout = (props: AttendeesScreenLayoutProps) => {
  const {
    groupQueryResult,
    eventQueryResult,
    goingQueryResult,
    notGoingQueryResult,
    waitlistQueryResult,
    errors,
  } = props;
  const windowHeight = useWindowHeight();
  const navigation = useNavigation();
  const currentUserInfo = useContext(userContext);
  const currentUserId = currentUserInfo.id;

  const { data: groupDetails } = groupQueryResult;
  const { data: eventDetails } = eventQueryResult;
  const canSeeConflicts =
    (!!eventDetails &&
      !!groupDetails &&
      userIsHost(currentUserId, eventDetails)) ||
    userIsOrganizer(currentUserId, groupDetails);
  const { data: conflictData } = useEventsConflicts(
    canSeeConflicts,
    eventDetails ? eventDetails.id : ""
  );

  const isOld =
    eventDetails && eventDetails.date
      ? isBeforeToday(new Date(eventDetails.date))
      : true;
  const [birdsEyeViewOn, setBirdsEyeViewOn] = useState(false);
  const canHaveBirdsEyeView =
    currentUserInfo.adminLevel > 90 ||
    userIsHost(currentUserId, eventDetails) ||
    userIsOrganizer(currentUserId, groupDetails) ||
    userIsOwner(currentUserId, groupDetails);
  const toggleBirdsEyeViewOn = () => {
    if (canHaveBirdsEyeView) {
      setBirdsEyeViewOn(!birdsEyeViewOn);
    }
  };
  const [height, setHeight] = useState(500);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current) return;
    setHeight(divRef.current.offsetHeight);
  }, []);

  return (
    <>
      {errors?.generic && (
        <HorizontalContainer>
          <TextRegular color="red">{errors?.generic}</TextRegular>
        </HorizontalContainer>
      )}
      {eventDetails && groupDetails && (
        <Tabs
          style={{
            flex: 1,
            marginTop: -SECTION_VERTICAL_SPACING,
          }}
        >
          <StickyActionBar>
            <DetailsActionButtonBar backButtonTitle="Back" />
            <div ref={divRef}>
              <HorizontalSpaceBetweenContainer
                style={{
                  paddingLeft: ITEM_HORIZONTAL_SPACING,
                  paddingRight: ITEM_HORIZONTAL_SPACING,
                  paddingTop: SECTION_VERTICAL_SPACING,
                }}
              >
                <PrimaryLeftHeader>Attendees</PrimaryLeftHeader>

                <LineVerticalSpace />

                {canHaveBirdsEyeView && (
                  <HorizontalRightAlignedContainer>
                    <AttendeePicker
                      eventDetails={eventDetails}
                      groupDetails={groupDetails}
                    />
                    <HorizontalRightAlignedContainer>
                      <NavButton
                        iconName={
                          birdsEyeViewOn ? "eye-outline" : "eye-off-outline"
                        }
                        onClick={() => toggleBirdsEyeViewOn()}
                        loading={navigation.state === "loading"}
                      />
                    </HorizontalRightAlignedContainer>
                  </HorizontalRightAlignedContainer>
                )}
              </HorizontalSpaceBetweenContainer>

              {navigation.state === "loading" && (
                <HorizontalLeftAlignedContainer style={{ marginTop: 15 }}>
                  <Skeleton
                    height={55}
                    width={140}
                    style={{
                      marginLeft: ITEM_HORIZONTAL_SPACING,
                      marginRight: ITEM_HORIZONTAL_SPACING,
                    }}
                    borderRadius={getBorderRadius("medium", "rounded rect")}
                  />
                  <Skeleton
                    height={55}
                    width={140}
                    style={{ marginRight: ITEM_HORIZONTAL_SPACING }}
                    borderRadius={getBorderRadius("medium", "rounded rect")}
                  />
                  <Skeleton
                    height={55}
                    width={140}
                    borderRadius={getBorderRadius("medium", "rounded rect")}
                  />
                </HorizontalLeftAlignedContainer>
              )}
              <TabList
                className="attendeeTab-list"
                style={{
                  display: navigation.state === "loading" ? "none" : undefined,
                }}
              >
                <Tab>
                  <TextRegular>{`${isOld ? "Went" : "Going"} (${
                    eventDetails.attendeeList.length
                  })`}</TextRegular>
                </Tab>
                <Tab>
                  <TextRegular>{`${isOld ? "Waitlisted" : "Waitlist"} (${
                    eventDetails.waitList.length
                  })`}</TextRegular>
                </Tab>
                <Tab>
                  <TextRegular>{`${isOld ? "Didn't Go" : "Not Going"} (${
                    eventDetails.notgoingList.length
                  })`}</TextRegular>
                </Tab>
              </TabList>
            </div>
          </StickyActionBar>

          <TabPanel className="attendeeTab-panel">
            <AttendeesList
              attendeeQueryResult={goingQueryResult}
              eventDetails={eventDetails}
              groupDetails={groupDetails}
              birdsEyeViewOn={birdsEyeViewOn}
              infiniteScrollHeight={windowHeight - height - 76}
              conflictData={conflictData}
            />
          </TabPanel>
          <TabPanel>
            <AttendeesList
              attendeeQueryResult={waitlistQueryResult}
              eventDetails={eventDetails}
              groupDetails={groupDetails}
              birdsEyeViewOn={birdsEyeViewOn}
              infiniteScrollHeight={windowHeight - height - 76}
              conflictData={conflictData}
            />
          </TabPanel>
          <TabPanel>
            <AttendeesList
              attendeeQueryResult={notGoingQueryResult}
              eventDetails={eventDetails}
              groupDetails={groupDetails}
              birdsEyeViewOn={false}
              infiniteScrollHeight={windowHeight - height - 76}
              conflictData={conflictData}
            />
          </TabPanel>
        </Tabs>
      )}
    </>
  );
};
