import React from "react";
import { H1 } from "../../styles/text";
import { VenueFull } from "../../types";
import { VenuesListSection } from "./sections/VenuesListSection";
import { VenuesFilterInfo } from "./VenuesScreen";
import { VenuesFilterSection } from "./sections/VenuesFilterSection";
import { UseInfiniteQueryResult } from "react-query";
import { goToCreateVenue } from "../../utils/navUtil";
import { useNavigate, useNavigation } from "react-router-dom";
import { HEADER_VERTICAL_SPACING } from "../../styles/spacing";
import { NavButton } from "../../components/buttons/NavButton";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";

type VenuesScreenLayoutProps = {
  venuesQueryResult: UseInfiniteQueryResult<VenueFull[]>;
  filterInfo?: VenuesFilterInfo;
};

export const VenuesScreenLayout = (props: VenuesScreenLayoutProps) => {
  const { venuesQueryResult, filterInfo } = props;
  const navigate = useNavigate();
  const navigation = useNavigation();

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          paddingTop: HEADER_VERTICAL_SPACING,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <PrimaryLeftHeader>Venues</PrimaryLeftHeader>
          <NavButton
            iconName="add"
            onClick={() => goToCreateVenue(navigate)}
            disabled={!filterInfo}
            loading={navigation.state === "loading"}
          />
        </div>
        <VenuesFilterSection filterInfo={filterInfo} />
      </div>

      <VenuesListSection venueQueryResult={venuesQueryResult} />
    </>
  );
};
