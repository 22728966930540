import { Auth } from "aws-amplify";

export const axiosRequestInterceptor = async (config: any) => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session?.getAccessToken();
    const token = accessToken?.getJwtToken();
    if (token) {
      config.headers.Authorization = token;
    }
  } catch (e) {
    // we probably don't have a user, and thus we don't have a token, but we can still query by email
  }
  return config;
};
