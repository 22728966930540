import React from "react";
import { Form, useLocation, useNavigation } from "react-router-dom";
import {
  DropdownValueType,
  TitledDropdown,
} from "../../components/inputs/TitledDropdown";
import { TitledTextarea } from "../../components/inputs/TitledTextarea";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_VERTICAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { useContext, useState } from "react";
import { userContext } from "../../UserContext";
import { ReportErrors } from "./ReportIssueScreen";
import { ReportTargetType } from "../../types";
import { getReportTopics } from "./ReportTopics";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { PALE_GRAY } from "../../styles/colors";
import { getBorderRadius } from "../../utils";
import { Icon } from "../../styles/icons";
import { Divider } from "../../styles/divider";
import { PrimaryLeftHeader } from "../../components/text/PrimaryLeftHeader";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";
import { NormalText } from "../../components/text/NormalText";
import { LineHorizontalSpace } from "../../components/spacing/LineHorizontalSpace";
import Skeleton from "react-loading-skeleton";
import { SecondaryLeftHeader } from "../../components/text/SecondaryLeftHeader";

type ReportIssueScreenLayoutProps = {
  targetType?: string;
  targetId?: string;
  targetName?: string;
  targetDate?: number;
  parentName?: string;
  errors: ReportErrors;
  returnUri?: string;
};

export const ReportIssueScreenLayout = (
  props: ReportIssueScreenLayoutProps
) => {
  const {
    targetType,
    targetId,
    targetName,
    targetDate,
    parentName,
    errors,
    returnUri,
  } = props;
  const userInfo = useContext(userContext);
  const [reportDetails, setReportDetails] = useState("");
  const location = useLocation();
  const navigation = useNavigation();
  let actionUri = location.pathname + location.search;
  // alert("returnUri: " + returnUri);

  const reportTopicItems = getReportTopics(targetType as ReportTargetType);
  const [reportTopic, setReportTopic] = useState(
    reportTopicItems[0].value as DropdownValueType
  );
  const title =
    targetType === "support"
      ? "Report a problem"
      : `${targetType}: ${targetName}`;

  return (
    <>
      <div style={{ height: HEADER_VERTICAL_SPACING }} />
      <PrimaryLeftHeader>Contact us</PrimaryLeftHeader>

      <div
      // style={{
      //   backgroundColor: PALE_GRAY,
      //   padding: ITEM_VERTICAL_SPACING,
      //   borderRadius: getBorderRadius("medium", "rounded rect"),
      // }}
      >
        <ItemVerticalSpace />
        <HorizontalLeftAlignedContainer>
          <NormalText>
            We are always looking for ways to improve our platform. Let us know
            if you have any suggestions!
          </NormalText>
        </HorizontalLeftAlignedContainer>
        <ItemVerticalSpace />

        <HorizontalLeftAlignedContainer>
          {navigation.state === "loading" ? (
            <Skeleton width={22} height={22} />
          ) : (
            <Icon name="mail-outline" size={22} />
          )}
          <LineHorizontalSpace />
          <a href={"mailto:support@picklers.net"}>
            <NormalText>support@picklers.net</NormalText>
          </a>
        </HorizontalLeftAlignedContainer>
      </div>

      <Divider style={{ marginTop: SECTION_VERTICAL_SPACING }} />

      <Form
        action={actionUri}
        method="POST"
        encType="multipart/form-data" // do not lose this, else the image won't be propagated to our action
        style={{
          backgroundColor: PALE_GRAY,
          marginTop: SECTION_VERTICAL_SPACING,
          padding: ITEM_VERTICAL_SPACING,
          borderRadius: getBorderRadius("medium", "rounded rect"),
        }}
      >
        {errors?.generic && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.generic}</TextRegular>
          </HorizontalContainer>
        )}
        <SecondaryLeftHeader>{title}</SecondaryLeftHeader>

        <ItemVerticalSpace />

        <HorizontalLeftAlignedContainer>
          {errors?.reportTopic && (
            <TextRegular color="red">{errors?.reportTopic}</TextRegular>
          )}
          <TitledDropdown
            title="GENERAL TOPIC"
            formDataName="reporttopic"
            value={reportTopic}
            setValue={setReportTopic}
            items={reportTopicItems}
            placeholder="Select a Topic"
            required={true}
          />
        </HorizontalLeftAlignedContainer>

        <ItemVerticalSpace />

        <HorizontalLeftAlignedContainer>
          <TitledTextarea
            value={reportDetails}
            title="INCIDENT DETAILS"
            formDataName="reportdetails"
            setText={setReportDetails}
            placeholderText="Tell us about it.  Who? Where? Why? What?  Give as many details as possible."
            maxLength={10000}
            required={true}
          />
        </HorizontalLeftAlignedContainer>
        {errors?.reportDetails && (
          <HorizontalContainer>
            <TextRegular color="red">{errors?.reportDetails}</TextRegular>
          </HorizontalContainer>
        )}
        <ItemVerticalSpace />

        <input type="hidden" name="userid" value={userInfo.id} />
        <input type="hidden" name="targettype" value={targetType} />
        {targetId && <input type="hidden" name="targetid" value={targetId} />}
        {targetName && (
          <input type="hidden" name="targetname" value={targetName} />
        )}
        {returnUri && (
          <input type="hidden" name="returnuri" value={returnUri} />
        )}
        {parentName && (
          <input type="hidden" name="parentname" value={parentName} />
        )}
        {targetDate && (
          <input type="hidden" name="targetdate" value={targetDate} />
        )}
        <HorizontalLeftAlignedContainer>
          {navigation.state === "loading" ? (
            <Skeleton width={64} height={28} />
          ) : (
            <PrimaryButton title={"Send"} onClick={() => {}} />
          )}
        </HorizontalLeftAlignedContainer>
      </Form>
    </>
  );
};
