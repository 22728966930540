import React, { Dispatch, SetStateAction, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Modal from "react-modal";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { H2 } from "../../styles/text";
import { InfoOval } from "../../components/attributes/InfoOval";
import { FilterButton } from "../../components/buttons/FilterButton";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { COLORS } from "../../styles/colors";
import { TextButton } from "../../components/buttons/TextButton";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
} from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { customStyles } from "./constants";

type SkillFilterButtonProps = {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  defaultValue: number;
  disabled?: boolean;
};

export const SkillFilterButton = (props: SkillFilterButtonProps) => {
  const { value, setValue, defaultValue, disabled } = props;
  const colors = COLORS["light"];

  const [localValue, setLocalValue] = useState(defaultValue);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     borderRadius: getBorderRadius("medium", "rounded rect"),
  //   },
  //   overlay: {
  //     zIndex: 1000,
  //   },
  // };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    openModal();
  };

  const handleSelect = () => {
    setValue(localValue);
    closeModal();
    // clear location?
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleReset = () => {
    setLocalValue(defaultValue);
  };

  const onChange = (newValue: number | number[]) => {
    if (typeof newValue === "number") setLocalValue(newValue);
  };

  return (
    <>
      <FilterButton
        title={value < 2.4 ? "Skill Level" : value.toString()}
        onClick={handleClick}
        active={value >= 2.4}
        iconName="flash-outline"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
        appElement={document.getElementById("root") as HTMLElement}
      >
        <div
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <HorizontalSpaceBetweenContainer
            style={{ marginBottom: SECTION_VERTICAL_SPACING }}
          >
            <H2>Specify Skill Level</H2>
            <TextButton
              title="Reset"
              onClick={() => handleReset()}
              color={colors.PRIMARY_BRAND}
            />
          </HorizontalSpaceBetweenContainer>
          <HorizontalLeftAlignedContainer
            style={{ flex: 1, marginBottom: ITEM_VERTICAL_SPACING }}
          >
            <InfoOval
              title={localValue === 2 ? "Any" : localValue.toString()}
              iconName="flash-outline"
            />
          </HorizontalLeftAlignedContainer>

          <HorizontalLeftAlignedContainer
            style={{
              width: 300,
              paddingBottom: SECTION_VERTICAL_SPACING,
              paddingRight: LINE_HORIZONTAL_SPACING,
              paddingLeft: LINE_HORIZONTAL_SPACING,
            }}
          >
            <Slider
              defaultValue={5}
              value={localValue}
              onChange={onChange}
              min={2.0}
              max={5.0}
              step={0.5}
              trackStyle={[{ backgroundColor: colors.PRIMARY_BRAND }]}
              handleStyle={[{ borderColor: colors.PRIMARY_BRAND }]}
            />
          </HorizontalLeftAlignedContainer>
          <HorizontalContainer>
            <PrimaryButton
              flex={1}
              onClick={handleSelect}
              type="button"
              title="Apply"
            />
          </HorizontalContainer>
        </div>
      </Modal>
    </>
  );
};
