import React, { useContext, useEffect, useState } from "react";
import { redirect, useActionData, useParams } from "react-router-dom";
import { setVenueStatus, venueDetailsQuery } from "../../hooks/queryVenues";
import { VenueDetailsLayout } from "./VenueDetailsLayout";
import { QueryClient, useQuery } from "react-query";
import { VenueStatus } from "../../types/enums";
import { fdGetString } from "../../utils/formUtils";
import { userContext } from "../../UserContext";
import { ScreenContainer } from "../../features/ScreenContainer";
import { eventsQuery, useEvents } from "../../hooks/queryEvents";
import { logSentryError } from "../../utils/sentryUtil";

export type VenueDetailsParams = {
  venueId: string;
};

export type VenueDetailsErrors = {
  generic?: string;
  ok?: boolean;
};

export const venueDetailsLoader = async (
  params: VenueDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string,
  latitude: number,
  longitude: number
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));

  if (currentUserId) {
    const query = venueDetailsQuery(currentUserId, params?.venueId);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query));

    const query2 = eventsQuery(
      true,
      undefined,
      params?.venueId,
      "Upcoming",
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      currentUserId
    );
    queryClient.getQueryData(query2.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query2));
  } else {
    return redirect("/?returnUrl=" + encodeURIComponent(request.url));
  }

  return { ok: true };
};

export const venueContextAction = async (
  params: VenueDetailsParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const venueId = params.venueId;
  const contextAction = fdGetString(data, "contextAction");
  const defaultErrorMessage =
    "There was an error applying the action '" +
    contextAction +
    "' to this venue";
  try {
    const lastEditedBy = fdGetString(data, "lastEditedBy");

    switch (contextAction) {
      case "deactivate":
        await setVenueStatus(
          venueId,
          VenueStatus.Inactive,
          lastEditedBy ? lastEditedBy : ""
        );
        queryClient.invalidateQueries(["venues"]);
        break;
      case "reactivate":
        await setVenueStatus(
          venueId,
          VenueStatus.Active,
          lastEditedBy ? lastEditedBy : ""
        );
        queryClient.invalidateQueries(["venues"]);
        break;
    }
    return { ok: true };
  } catch (e: any) {
    logSentryError(
      "Error applying contextaction '" + contextAction + "' to a venue.",
      e,
      {
        tags: {
          userId: currentUserId,
          venueId: params?.venueId,
          screen: "VenueDetailsScreen",
          function: "venueContextAction",
        },
        level: "error",
      }
    );
    return { ok: false, generic: defaultErrorMessage };
  }
};

export const VenueDetailsScreen = () => {
  const params = useParams();
  const userInfo = useContext(userContext);
  const errors = useActionData() as VenueDetailsErrors;

  const venueId = params && params.venueId ? params.venueId : "";
  const [selectedImage, setSelectedImage] = useState("");

  const venueQueryResult = useQuery(venueDetailsQuery(userInfo.id, venueId));
  const eventsQueryResult = useEvents(
    true,
    undefined,
    venueId,
    "Upcoming",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  );

  useEffect(() => {
    if (venueQueryResult.isSuccess && venueQueryResult.data) {
      document.title = "Venue Details: " + venueQueryResult.data.name;
    }
  }, [venueQueryResult]);

  return (
    <ScreenContainer horizontalPadding={0}>
      <VenueDetailsLayout
        venueQueryResult={venueQueryResult}
        eventsQueryResult={eventsQueryResult}
        errors={errors}
      />
    </ScreenContainer>
  );
};
