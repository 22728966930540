import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { HorizontalContainer } from "../../styles/container";
import { useNavigation } from "react-router-dom";
import { TextRegular, TextSmall } from "../../styles/text";

type SmallTextProps = {
  width?: number;
  color?: string;
};

export const SmallText = (props: PropsWithChildren<SmallTextProps>) => {
  const { width, children } = props;
  const navigation = useNavigation();
  const skeletonWidth = width
    ? width
    : children
    ? children.toString().length * 12
    : 200;
  return (
    <>
      {navigation.state === "loading" ? (
        <Skeleton height={18} width={skeletonWidth} />
      ) : (
        <TextSmall>{children}</TextSmall>
      )}
    </>
  );
};
