import React from "react";
import {
  HorizontalLeftAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../styles/spacing";
import { H1 } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../buttons/NavButton";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { SecondaryButton } from "../buttons/SecondaryButton";
import { COLORS } from "../../styles/colors";

type CustomRecurringAlertUIProps = {
  title: string;
  content: string;
  yesButton?: string;
  partialButton?: string;
  noButton?: string;
  yesButtonClick: () => void;
  partialButtonClick: () => void;
  noButtonClick: () => void;
  onClose: () => void;
};

export const CustomRecurringAlertUI = (props: CustomRecurringAlertUIProps) => {
  const {
    title,
    content,
    yesButton,
    partialButton,
    noButton,
    yesButtonClick,
    partialButtonClick,
    noButtonClick,
    onClose,
  } = props;
  const colors = COLORS["light"];
  return (
    <div
      style={{
        width: 300,
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: colors.MEDIUM_GRAY,
        borderRadius: getBorderRadius("medium", "rounded rect"),
      }}
    >
      <HorizontalLeftAlignedContainer
        style={{ marginBottom: ITEM_VERTICAL_SPACING }}
      >
        <NavButton
          iconName="close"
          onClick={() => {
            onClose();
          }}
        />
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer
        style={{ marginBottom: ITEM_VERTICAL_SPACING }}
      >
        <H1>{title ?? "Please Confirm"}</H1>
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer
        style={{
          marginBottom: ITEM_VERTICAL_SPACING,
          paddingBottom: ITEM_VERTICAL_SPACING,
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: colors.LIGHT_GRAY,
        }}
      >
        {content}
      </HorizontalLeftAlignedContainer>
      <HorizontalSpaceBetweenContainer>
        <SecondaryButton
          onClick={noButtonClick}
          title={noButton ?? "Never Mind"}
          type="button"
        />
        <SecondaryButton
          onClick={partialButtonClick}
          title={partialButton ?? "Just One"}
          type="button"
        />
        <PrimaryButton
          onClick={yesButtonClick}
          title={yesButton ?? "Yes, All"}
          type="button"
        />
      </HorizontalSpaceBetweenContainer>
    </div>
  );
};
