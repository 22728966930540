import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Location, LocationPicker } from "./LocationPicker";
import { COLORS } from "../../styles/colors";
import { HorizontalSpaceBetweenContainer } from "../../styles/container";
import { TextRegular, TextSmall } from "../../styles/text";
import Drawer from "react-modern-drawer";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { NavButton } from "../../components/buttons/NavButton";
import { EditTitle } from "../../components/inputs/EditTitle";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { TitledInput } from "../../components/inputs/TitledInput";
import { ItemVerticalSpace } from "../../components/spacing/ItemVerticalSpace";

export type TitledLocationPickerProps = {
  title: string;
  location: Location;
  setLocation: Dispatch<SetStateAction<Location>>;
  showDisplayAddress?: boolean;
  citiesOnly?: boolean;
  prefix: string;
  required?: boolean;
  disabled?: boolean;
};

export const TitledLocationPicker = (props: TitledLocationPickerProps) => {
  const {
    title,
    location,
    setLocation,
    showDisplayAddress,
    citiesOnly,
    prefix,
    required,
    disabled,
  } = props;
  const colors = COLORS["light"];
  const [showLocationPicker, setShowLocationPicker] = useState(false);
  const [displayAddress, setDisplayAddress] = useState("");
  const navigation = useNavigation();

  const toggleLocationPickerDrawer = () => {
    document.body.style.overflow = !showLocationPicker ? "hidden" : "unset";

    if (navigation.state !== "loading")
      setShowLocationPicker((isOpen) => {
        return !isOpen;
      });
  };

  useEffect(() => {
    setDisplayAddress(location.displayAddress);
  }, [location.displayAddress]);

  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <Drawer
        open={showLocationPicker}
        onClose={() => toggleLocationPickerDrawer()}
        direction="right"
        className="location-picker"
        size={400}
        zIndex={2000}
      >
        {location && (
          <LocationPicker
            show={showLocationPicker}
            onClose={() => toggleLocationPickerDrawer()}
            location={location}
            setLocation={setLocation}
            citiesOnly={!!citiesOnly}
          />
        )}
      </Drawer>
      <EditTitle title={title} required={required} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <>
          <HorizontalSpaceBetweenContainer
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: colors.LIGHT_GRAY,
              borderRadius: getBorderRadius("small", "rounded rect"),
              flex: 1,
              paddingLeft: 8,
              paddingTop: 8,
              paddingBottom: 8,
              paddingRight: 8,
              backgroundColor: disabled ? colors.PALE_GRAY : undefined,
            }}
          >
            <TextRegular>
              {showDisplayAddress ? location.displayAddress : location.address}
            </TextRegular>
            <NavButton
              iconName="location"
              onClick={() => toggleLocationPickerDrawer()}
              color={colors.SECONDARY_TEXT}
              size={16}
              disabled={disabled}
              backgroundColor={disabled ? colors.PALE_GRAY : undefined}
              // loading={navigation.state === "loading"}
            />
            <input
              type="hidden"
              name={prefix + "address"}
              value={location.address}
            />
            {showDisplayAddress && (
              <input
                type="hidden"
                name={prefix + "displayaddress"}
                value={location.displayAddress}
              />
            )}
            <input
              type="hidden"
              name={prefix + "latitude"}
              value={location.lat}
            />
            <input
              type="hidden"
              name={prefix + "longitude"}
              value={location.lng}
            />
          </HorizontalSpaceBetweenContainer>
          {!showDisplayAddress && (
            <>
              <ItemVerticalSpace />
              <TitledInput
                title="DISPLAY ADDRESS"
                formDataName={prefix + "displayaddress"}
                value={displayAddress}
                setText={setDisplayAddress}
                maxLength={128}
                placeholderText={""}
                editable={!disabled}
                required={true}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
