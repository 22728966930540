import React, { Dispatch, SetStateAction } from "react";
import { COLORS } from "../../styles/colors";
import { HorizontalSpaceBetweenContainer } from "../../styles/container";
import { TextRegular } from "../../styles/text";
import Switch from "react-switch";
import { EditTitle } from "./EditTitle";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ItemHorizontalSpace } from "../spacing/ItemHorizontalSpace";
export type TitledSwitchProps = {
  title: string;
  description: string;
  formDataName: string;
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
};

export const TitledSwitch = (props: TitledSwitchProps) => {
  const { title, description, value, setValue, formDataName } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();

  const handleChange = (checked: boolean) => {
    setValue(checked);
  };
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <EditTitle title={title} required={false} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <HorizontalSpaceBetweenContainer>
          <TextRegular>{description}</TextRegular>
          <ItemHorizontalSpace />
          <Switch
            onColor={colors.PRIMARY_BRAND}
            offColor={colors.LIGHT_GRAY}
            onHandleColor={colors.BACKGROUND}
            offHandleColor={colors.PALE_GRAY}
            onChange={handleChange}
            checked={value}
          />
          <input type="hidden" name={formDataName} value={value.toString()} />
        </HorizontalSpaceBetweenContainer>
      )}
    </div>
  );
};
