import React, { PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { HorizontalContainer } from "../../styles/container";
import { useNavigation } from "react-router-dom";
import { TextRegular } from "../../styles/text";
import { COLORS } from "../../styles/colors";

type NormalTextProps = {
  width?: number;
  color?: string;
  fontWeight?: string | number;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
};

export const NormalText = (props: PropsWithChildren<NormalTextProps>) => {
  const { width, color, fontWeight, children, textAlign } = props;
  const colors = COLORS["light"];
  const navigation = useNavigation();
  const skeletonWidth = width
    ? width
    : children
    ? children.toString().length * 14
    : 200;
  return (
    <>
      {navigation.state === "loading" ? (
        <Skeleton height={20} width={skeletonWidth} />
      ) : (
        <TextRegular
          style={{
            textAlign: textAlign ? textAlign : "left",
            color: color ? color : colors.PRIMARY_TEXT_COLOR,
            fontWeight: fontWeight ? fontWeight : undefined,
          }}
        >
          {children}
        </TextRegular>
      )}
    </>
  );
};
