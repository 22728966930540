import { QueryClient } from "react-query";
import {
  redirect,
  useActionData,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  deleteMessage,
  messagesQuery,
  sendMessage,
  updateMessage,
  uploadMessageImageFile,
  useMessages,
} from "../../hooks/queryMessages";
import { MessageHistoryLayout } from "./MessageHistoryLayout";
import { TextRegular } from "../../styles/text";
import { fdGetBoolean, fdGetImage, fdGetString } from "../../utils/formUtils";
import { MessageCreate, MessageUpdate } from "../../types";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../UserContext";
import { ScreenContainer } from "../../features/ScreenContainer";
import { membershipQuery, useMembership } from "../../hooks/queryMemberships";
import {
  findLastReadItem,
  threadLastReadContext,
  updateThreadLastReadData,
} from "../Inbox/Components/ThreadLastReadContext";
import { logSentryError } from "../../utils/sentryUtil";
import { eventDetailsQuery, useEvent } from "../../hooks/queryEvents";

export type MessageHistoryParams = {
  messageType?: string;
  destinationId?: string;
};

export type MessageHistoryErrors = {
  generic?: string;
  content?: string;
  id?: string;
  resetForm?: boolean;
  ok?: boolean;
};
const extractMessageData = (data: FormData) => {
  return {
    messageType: fdGetString(data, "messagetype"),
    destinationId: fdGetString(data, "destinationid"),
    userId: fdGetString(data, "userid"),
    messageText: fdGetString(data, "messagetext"),
  } as MessageCreate;
};

const extractMessageUpdateData = (data: FormData) => {
  return {
    id: fdGetString(data, "messageid"),
    messageText: fdGetString(data, "messagetext"),
    pinned: fdGetBoolean(data, "pinned"),
  } as MessageUpdate;
};

const validateMessageData = (
  messageData: MessageCreate,
  messageImage: File | null
) => {
  const err: MessageHistoryErrors = {};

  if (!messageData.messageText && !messageImage) {
    // check if text or an image is there?
    err.content = "The message must have text or an image.";
  }
  return err;
};
export const messageHistoryAction =
  (queryClient: QueryClient) =>
  async ({
    params,
    request,
  }: {
    params: MessageHistoryParams;
    request: Request;
  }) => {
    if (!params.destinationId) return { ok: false };
    const data = await request.formData();
    const defaultErrorMessage =
      "There was an error while sending this message.";

    try {
      const messageData = extractMessageData(data);
      const messageImage = await fdGetImage(data, "messageimage");
      const validateResult = validateMessageData(messageData, messageImage);
      if (Object.keys(validateResult).length) {
        return { ok: false, ...validateResult };
      }

      const result = await sendMessage(messageData);

      if (messageImage && result.id) {
        await uploadMessageImageFile(result.id, messageImage);
      }
      queryClient.invalidateQueries([
        "messages",
        messageData.messageType,
        messageData.destinationId,
      ]);
      queryClient.invalidateQueries([
        messageData.messageType + "Threads",
        messageData.destinationId,
      ]);
    } catch (e) {
      return { generic: defaultErrorMessage, ok: false };
    }
    return { ok: true, resetForm: true };
  };

const validateMessageUpdateData = (messageData: MessageUpdate) => {
  const err: MessageHistoryErrors = {};

  if (!messageData.id) {
    // check if text or an image is there?
    err.id = "Message not found";
  }
  return err;
};

export const messageHistoryDeleteAction = async (
  params: MessageHistoryParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const messageId = fdGetString(data, "messageid");
  if (!messageId) return { ok: false, messageId: "No message id found" };

  try {
    await deleteMessage(messageId);
    queryClient.invalidateQueries([
      "messages",
      params?.messageType,
      params?.destinationId,
    ]);
    // todo: check these
    if (params?.messageType === "group") {
      queryClient.invalidateQueries(["groupThreads", currentUserId]);
      queryClient.invalidateQueries(["group", params?.destinationId]);
    } else if (params?.messageType === "event") {
      queryClient.invalidateQueries(["eventThreads", currentUserId]);
      queryClient.invalidateQueries(["event", params?.destinationId]);
    } else {
      queryClient.invalidateQueries(["dmThreads", currentUserId]);
    }
  } catch (e) {
    logSentryError("Error deleting a message.", e, {
      tags: {
        userId: currentUserId,
        groupId:
          params?.messageType == "group" ? params?.destinationId : undefined,
        eventId:
          params?.messageType == "event" ? params?.destinationId : undefined,
        dmThreadId:
          params?.messageType == "dm" ? params?.destinationId : undefined,
        screen: "MessageHistoryScreen",
        function: "messageHistoryDeleteAction",
      },
      level: "error",
    });
    return { ok: false, generic: "Error deleting this message" };
  }
  return { ok: true };
};

export const messageHistoryUpdateAction = async (
  params: MessageHistoryParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  const data = await request.formData();
  const messageUpdate = extractMessageUpdateData(data);
  const defaultErrorMessage = "There was an error while updating this message.";
  try {
    const validateResult = validateMessageUpdateData(messageUpdate);
    if (Object.keys(validateResult).length) {
      return {
        ok: false,
        generic: defaultErrorMessage,
        ...validateResult,
      };
    }

    // console.log("doing messageUpdate");
    await updateMessage(messageUpdate);
    queryClient.invalidateQueries([
      "messages",
      params?.messageType,
      params?.destinationId,
    ]);
    queryClient.invalidateQueries([
      "pinnedmessages",
      params?.messageType,
      params?.destinationId,
    ]);
  } catch (e) {
    logSentryError("Error updating a message.", e, {
      tags: {
        userId: currentUserId,
        screen: "MessageHistoryScreen",
        function: "messageHistoryUpdateAction",
      },
      level: "error",
    });
    return { generic: defaultErrorMessage, ok: false };
  }
  return { ok: true };
};

export const messageHistoryLoader = async (
  params: MessageHistoryParams,
  request: Request,
  queryClient: QueryClient,
  currentUserId: string
) => {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  if (!currentUserId) {
    return redirect("/login/?returnUrl=" + encodeURIComponent(request.url));
  }

  if (params && params.messageType && params.destinationId) {
    const messageType = params.messageType;
    const destinationId = params.destinationId;

    const query = messagesQuery(true, messageType, destinationId, true);
    queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchInfiniteQuery(query));

    if (messageType === "group") {
      const query2 = membershipQuery(true, destinationId, currentUserId);
      queryClient.getQueryData(query2.queryKey) ??
        (await queryClient.fetchQuery(query2));
    }

    if (messageType === "event") {
      const query3 = eventDetailsQuery(!!currentUserId, destinationId);
      queryClient.getQueryData(query3.queryKey) ??
        (await queryClient.fetchQuery(query3));
    }
  }

  return { ok: true };
};

export const MessageHistoryScreen = () => {
  const userInfo = useContext(userContext);
  const threadLastReadData = useContext(threadLastReadContext);
  const errors = useActionData() as MessageHistoryErrors;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const destinationId = params.destinationId ?? "";
  const messageType = params.messageType ?? "";
  const threadTitle = searchParams.get("title");

  const messagesQueryResult = useMessages(
    true,
    messageType,
    destinationId,
    true
  );

  const { data: eventDetails } = useEvent(
    messageType === "event" && !!destinationId && !!userInfo.id,
    destinationId
  );

  const enabled =
    (messageType === "group" && !!destinationId) ||
    (messageType === "event" && !!eventDetails);

  const groupId =
    messageType === "event" && eventDetails
      ? eventDetails.groupId
      : destinationId ?? destinationId ?? "";

  const membershipQueryResult = useMembership(enabled, groupId, userInfo.id);

  useEffect(() => {
    document.title = "Message History: " + threadTitle;
  }, [threadTitle]);

  useEffect(() => {
    if (
      messageType &&
      destinationId &&
      threadLastReadData &&
      messagesQueryResult.data
    ) {
      const firstPage = messagesQueryResult.data.pages[0];

      const lastReadItem = findLastReadItem(
        threadLastReadData,
        messageType,
        destinationId
      );
      if (
        !lastReadItem ||
        !firstPage ||
        !firstPage[0] ||
        lastReadItem.lastReadTime < firstPage[0].timePosted
      ) {
        const now = new Date();
        const newLastReadTime =
          firstPage.length > 0 ? firstPage[0].timePosted : now.getTime() - 1000;
        updateThreadLastReadData(
          userInfo.id,
          threadLastReadData,
          messageType,
          destinationId,
          newLastReadTime + 1000,
          lastReadItem ? lastReadItem.clearedTime : undefined,
          false
        );
      }
    }
  }, []);
  return (
    <>
      {(!destinationId || !messageType) && (
        <TextRegular>Error, no thread specified</TextRegular>
      )}
      <ScreenContainer horizontalPadding={0}>
        <MessageHistoryLayout
          messageType={messageType ?? ""}
          destinationId={destinationId ?? ""}
          threadTitle={threadTitle ?? "Conversation"}
          messagesQueryResult={messagesQueryResult}
          membershipQueryResult={membershipQueryResult}
          errors={errors}
        />
      </ScreenContainer>
    </>
  );
};
