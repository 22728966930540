import {
  DARK_RED,
  DARK_SECONDARY_TEXT,
  DARK_YELLOW,
  PALE_GRAY,
  PALE_RED,
  PALE_YELLOW,
} from "../../styles/colors";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../../styles/container";
import { Icon } from "../../styles/icons";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
} from "../../styles/spacing";
import { TextRegular } from "../../styles/text";
import { getBorderRadius } from "../../utils";
import IonIcon from "@reacticons/ionicons";
import { GeneralAttribute } from "../attributes/GeneralAttribute";

type BannerProps = {
  type: "error" | "warning" | "neutral";
  title: string;
  iconName: React.ComponentProps<typeof IonIcon>["name"];
  borderRadius?: number;
};

export const Banner = (props: BannerProps) => {
  const { type, title, iconName, borderRadius } = props;
  const textColor =
    type === "error"
      ? DARK_RED
      : type === "warning"
      ? DARK_YELLOW
      : DARK_SECONDARY_TEXT;
  const bgColor =
    type === "error" ? PALE_RED : type === "warning" ? PALE_YELLOW : PALE_GRAY;

  return (
    <HorizontalLeftAlignedContainer
      style={{
        backgroundColor: bgColor,
        borderRadius:
          borderRadius != null
            ? borderRadius
            : getBorderRadius("small", "rounded rect"),
        paddingLeft: ITEM_HORIZONTAL_SPACING,
        paddingRight: ITEM_HORIZONTAL_SPACING,
        paddingTop: ITEM_VERTICAL_SPACING,
        paddingBottom: ITEM_VERTICAL_SPACING,
      }}
    >
      <GeneralAttribute
        iconName={iconName}
        text={title}
        textColor={textColor}
      />
    </HorizontalLeftAlignedContainer>
  );
};
