import React, { useState } from "react";
import { COLORS } from "../../styles/colors";
import TouchableOpacity from "../general/TouchableOpacity";
import {
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
} from "../../styles/spacing";
import {
  HorizontalLeftAlignedContainer,
  HorizontalRightAlignedContainer,
  HorizontalSpaceBetweenContainer,
} from "../../styles/container";
import { TextRegular, TextSmall } from "../../styles/text";
import { Icon } from "../../styles/icons";
import Drawer from "react-modern-drawer";
import IonIcon from "@reacticons/ionicons";
import Skeleton from "react-loading-skeleton";
import { useNavigation } from "react-router-dom";
import { NewActivityIndicator } from "../general/NewActivityIndicator";
import { ItemVerticalSpace } from "../spacing/ItemVerticalSpace";
import { NormalText } from "../text/NormalText";
import { ItemHorizontalSpace } from "../spacing/ItemHorizontalSpace";

type ClickableDrawerRowProps = {
  iconName?: React.ComponentProps<typeof IonIcon>["name"];
  title: string;
  subTitle: string;
  notice?: string;
  showDrawer: boolean;
  toggleDrawer: () => void;
};

export const ClickableDrawerRow = (
  props: React.PropsWithChildren<ClickableDrawerRowProps>
) => {
  const colors = COLORS["light"];
  const { title, iconName } = props;
  const navigation = useNavigation();

  return (
    <HorizontalLeftAlignedContainer
      style={{
        width: "100%",
        borderTopWidth: 1,
        borderTopColor: colors.LIGHT_GRAY,
        borderTopStyle: "solid",
        paddingTop: 16,
        display: "flex",
      }}
    >
      <Drawer
        open={props.showDrawer}
        onClose={() => props.toggleDrawer()}
        direction="right"
        size={500}
        style={{ padding: 20 }}
        zIndex={2000}
      >
        {props.children}
      </Drawer>
      <TouchableOpacity
        onClick={
          navigation.state !== "loading" ? props.toggleDrawer : undefined
        }
        style={{
          width: "100%",
          backgroundColor: colors.WHITE,
          borderWidth: 0,
        }}
      >
        <HorizontalSpaceBetweenContainer>
          <HorizontalLeftAlignedContainer>
            {iconName && navigation.state !== "loading" && (
              <>
                <Icon name={iconName} size={24} />
                <ItemHorizontalSpace />
              </>
            )}
            <NormalText>{title}</NormalText>
          </HorizontalLeftAlignedContainer>
          <HorizontalRightAlignedContainer>
            {props.notice && <NewActivityIndicator />}
            <Icon name="chevron-forward" color={colors.SECONDARY_TEXT} />
          </HorizontalRightAlignedContainer>
        </HorizontalSpaceBetweenContainer>
        <ItemVerticalSpace />
      </TouchableOpacity>
    </HorizontalLeftAlignedContainer>
  );
};
