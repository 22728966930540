import React, { Dispatch, SetStateAction } from "react";
import { HorizontalLeftAlignedContainer } from "../../styles/container";
import { COLORS } from "../../styles/colors";
import { ITEM_HORIZONTAL_SPACING } from "../../styles/spacing";
import { getBorderRadius } from "../../utils";
import { EditTitle } from "./EditTitle";
import { useNavigation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export type TitledInputProps = {
  title: string;
  formDataName: string;
  value: string;
  setText: Dispatch<SetStateAction<string>>;
  maxLength: number;
  placeholderText: string;
  required?: boolean;
  editable?: boolean;
  password?: boolean;
  autoComplete?: string;
};
export const TitledInput = (props: TitledInputProps) => {
  const {
    title,
    formDataName,
    value,
    setText,
    maxLength,
    placeholderText,
    required,
    editable,
    password,
    autoComplete,
  } = props;
  const colors = COLORS["light"];
  const readOnly = editable === undefined || editable === false;
  const navigation = useNavigation();
  return (
    <div
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        // marginLeft: ITEM_HORIZONTAL_SPACING,
        // marginRight: ITEM_HORIZONTAL_SPACING,
      }}
    >
      <EditTitle title={title} required={required} />
      {navigation.state === "loading" ? (
        <Skeleton height={36} />
      ) : (
        <HorizontalLeftAlignedContainer>
          <input
            type={password ? "password" : "text"}
            name={formDataName}
            value={value}
            onChange={(e) => {
              setText(e.target.value);
            }}
            placeholder={placeholderText}
            maxLength={maxLength}
            readOnly={readOnly}
            autoComplete={autoComplete}
            style={{
              flex: 1,
              fontFamily: "Poppins",
              borderRadius: getBorderRadius("small", "rounded rect"),
              borderWidth: 1,
              borderColor: colors.LIGHT_GRAY,
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 8,
              paddingRight: 8,
              outline: "none",
              backgroundColor: !editable ? colors.PALE_GRAY : undefined,
            }}
          />
        </HorizontalLeftAlignedContainer>
      )}
    </div>
  );
};
