import React from "react";
import { QueryClient } from "react-query/types/core";
import { Navigate, Route } from "react-router-dom";
import {
  ReportIssueScreen,
  saveReportAction,
  saveReportLoader,
} from "../screens/ReportIssueScreen/ReportIssueScreen";
import { FAQScreen } from "../screens/Profile/FAQScreen";
import { PrivacyPolicyScreen } from "../screens/Profile/PrivacyPolicyScreen";
import { TermsOfServiceScreen } from "../screens/Profile/TermsOfServiceScreen";
import { AboutScreen } from "../screens/Profile/AboutScreen";
import { ReportIssueError } from "../screens/ReportIssueScreen/ReportIssueError";

type OtherRoutesProps = {
  queryClient: QueryClient;
  currentUserId: string;
};
export const useOtherRoutes = (props: OtherRoutesProps) => {
  const { queryClient, currentUserId } = props;
  return (
    <>
      <Route
        path="report"
        errorElement={<ReportIssueError />}
        Component={ReportIssueScreen}
        action={saveReportAction(queryClient, currentUserId)}
        loader={({ request }) => saveReportLoader(request, currentUserId)}
      />
      <Route path="profile/faq" Component={FAQScreen} />
      <Route path="profile/termsofservice" Component={TermsOfServiceScreen} />
      <Route path="profile/privacypolicy" Component={PrivacyPolicyScreen} />
      <Route path="about" Component={AboutScreen} />
      <Route path="about.html" element={<Navigate to="/about" replace />} />
      <Route
        path="privacy.html"
        element={<Navigate to="/profile/privacypolicy" replace />}
      />
      <Route path="contact.html" element={<Navigate to="/about" replace />} />
    </>
  );
};
